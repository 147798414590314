import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import ClinicList from "./ClinicList";
import ClinicDetailViewContainer from "./ClinicDetailView/ClinicDetailViewContainer";

const ClinicContainer = () => {

  return (
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<ClinicList/>} />
        {/* <Route path="/detail-view/:clinicId/:action" key={1}  element={<ClinicDetailViewContainer/>} /> */}
      </Routes>   
    </Wrap>
  );
};






export default ClinicContainer;
