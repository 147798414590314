import React, { Component, useState } from "react";
import Modal from "react-bootstrap/Modal";
import FormInputs from "../../components/UI/FormInputs";
import WithRoutify from "../../hoc/WithRoutify";
import { getLookupByKey, isObject, isSet } from "../../utils/commonUtils";
import { CREATED, LAB_ORDER, OK } from "../../utils/constant";
import TableGrid from "../../components/UI/TableGrid";
import LabOrderdService from "../../services/LabOrderdService";
import Buttons from "../../components/UI/Buttons";
import PERMISSIONS from "../../utils/AclPermissions";
import { acl_check } from "../../utils/aclUtils";
import withReduxState from "../../hoc/wReduxState";

class AddLabOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      validation: {},
      formDataInEdit: null,
      activeEditId: null,
      tableRecords: {
        tableHeaders: [
          { label: 'Lab Order', key: 'lab_order', inputType: 'SelectList',options:[],},
          { label: 'Device Identifier', key: 'device_identifier', inputType: 'TextInput' },
          { label: 'Test Code', key: 'test_code', inputType: 'TextInput' },
          { label: 'Message Override', key: 'message_override', inputType: 'Checkbox' },
          { label: 'Lab Message', key: 'lab_message', inputType: 'TextArea',style: { height: "200px",width:'100%' }},
          { label: 'Action', key: 'action', type: 'Actions' },
        ],
        tableRows: {
          type: "default",
          data: []
        },
      },
      labOrderOptions:[],
      isModalOpen:false,
    };
  }

  componentDidMount() {
    this.fetchLabOrderList();
    const fetchLookup=async()=>{
      const labOrder=await getLookupByKey(LAB_ORDER);
      this.setState({labOrderOptions:labOrder})
    }
    fetchLookup();
  }

  

  render() {
    const { isOpen, modelTitle,labData} = this.props;
    const { formData, validation, formDataInEdit, activeEditId, tableRecords,labOrderOptions} = this.state;
    let hasEditAccess = acl_check(this.props.profile.permissions,[PERMISSIONS.LAB.EDIT,PERMISSIONS.LAB.CREATE]);

    const inputFields=this.getInputFields();
    return (
      <>
        {/* {
          this.state.isModalOpen ?
            <AddModal
              hasEditAccess={hasEditAccess}
              labOrderOptions={labOrderOptions}
            />
            :
            ''
        } */}
      
        <Modal show={true} onHide={this.modelCloseHandler} size="xl" dialogClassName='hResponsive'
         keyboard={false}
        contentClassName='h-100' backdrop="static">
          <div className="sh_modalBox">
            <Modal.Header closeButton>
              <Modal.Title>
                <h6 className="font-weight-bold text-lg mb-0">{`Lab Order for  ${labData.name}`}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="sh_innerPortview row d-flex">
                <div class="col-lg-5 col-12">
                  <div class="sh_cardBox h-auto shadow-none border">
                    <div class="sh_cardBody p-3">
                      <div class="row">
                        {inputFields &&
                          inputFields.map((field, index) => (
                            <div className={`col-12`} key={index}>
                              <div className="form-group">
                                 
                                {(isSet(field.isLabelAppend, false) === false) ?
                                  <label className="mb-0 text-sm opacity-75 text-truncate">
                                    {field.label}
                                  </label>
                                  : <></>}

                                <FormInputs
                                  {...field}
                                  value={isSet(formData[field.name], "")}
                                  readOnly={!hasEditAccess}
                                  changeHandler={(value, e) => this.changeHandler(value, field.name, e)}
                                />
                                {(isSet(field.isLabelAppend, false) === true) ?
                                  <label className="form-check-label ml-4" htmlFor={field.name}>
                                    {field.label}
                                  </label>
                                  : <></>}
                                {isSet(validation[field.name], "") !== "" ? (
                                  <span className="text-danger m-1">
                                    {isSet(validation[field.name], "")}
                                  </span>
                                ) : (
                                    ""
                                  )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div class="sh_cardFooter justify-content-center">
                      <Buttons
                        type='Single'
                        className="btn btn-primary"
                        clickHandler={this.formSubmitHandler}
                        label={"Add"}
                        acl={PERMISSIONS.LAB.CREATE}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="sh_cardBox h-auto shadow-none border">
                    <div class="sh_cardHeader">
                      <div class="form-group d-flex mb-0 justify-content-between align-items-center">
                        <h6 class="text-md mb-0">{`Lab Orders List`}</h6>
                      </div>
                    </div>
                    <div class="sh_cardBody p-0 tabletextbox">
                      <TableGrid
                        {...tableRecords}
                        gridEditProps={{
                          formDataInEdit: formDataInEdit,
                          activeEditId: activeEditId,
                          onChangeFormDataInEdit: this.onChangeFormDataInEdit,
                        }} />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>

           {/* <Modal.Footer className="d-flex justify-content-center">
              <Buttons
                type='Single'
                className="btn btn-outline-secondary"
                clickHandler={this.modelCloseHandler}
                label='Cancel'
                acl={'any'} />
            </Modal.Footer>*/}
          </div>
        </Modal>
      </>
    );
  }

  changeHandler = (val, fieldName) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [fieldName]: val,
      },
      validation:{
        ...prevState.validation,
        [fieldName]:''
      }
      
    }));
  };

  formSubmitHandler = async () => {
    const { formData } = this.state;
    const {labData}=this.props
    const { Loader, Toast,activeLookup} = this.props;
    let updateValidation = { ...this.state.validation };
    Loader(true);
    let response = await LabOrderdService.saveLabOrder(null,labData.lab_id,formData);
    Loader(false);
    if (response.status && response.status == "ERROR") {
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(","),
          };
        }
      }
      if (Object.keys(updateValidation).length > 0) {
        this.setState({ validation: updateValidation });
        return;
      }
    }
    else{
        Toast.success("Lab Order Added Successfully");
    }
    this.fetchLabOrderList();
    this.setState({ formData: {} });
  };

  deleteHandler = (id) => {
    const { Toast,labData,Loader} = this.props;
    this.setState({ validation: '' });
    Loader(true);
      LabOrderdService.deleteLabOrder(labData.lab_id,id)
        .then((res) => {
          this.fetchLabOrderList();
          Toast.success('Lab Order Deleted Succesfully');
          Loader(true);
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          Loader(false);
        });
  }

    editHandler = (rowId, data) => {
      console.log('data',data);
      
      this.state.tableRecords.tableHeaders.forEach((itm,ind)=>{
        if(itm.key=='lab_order'){
          itm.options=this.state.labOrderOptions;
        }
      })
      if (data) {
        data.message_override=data.message_override=='Yes'?1:0;
      }
      this.setState({ formDataInEdit: data, activeEditId: rowId });
    };

    updateHandler = async (id) => {
      const {formDataInEdit} = this.state;
      const {labData,Loader}=this.props;
      this.setState({ validation: '' });
      Loader(true);
      let payload = {
        'lab_id': labData.lab_id,
        'device_identifier': formDataInEdit['device_identifier'],
        'test_code':formDataInEdit['test_code'],
        'lab_order':formDataInEdit['lab_order'],
        'message_override':formDataInEdit['message_override'],
        'lab_message':formDataInEdit['lab_message'],
      };
      let response = await LabOrderdService.updateLabOrder(labData.lab_id,id, payload);
      if (response.code === OK) {
        this.props.Toast.success("Lab Order Updated Successfully");
        await this.fetchLabOrderList();
        Loader(false);
      }
      else if (response.status=="ERROR"&&response.data) {
        if (isObject(response.data)) {
          let validaitons={};
          for (let key in response.data) {
           validaitons={...validaitons,[key]:response.data[key][0]};
          }
            this.setState((prevState) => ({
            formDataInEdit: {
              ...prevState.formDataInEdit,
              validation: validaitons,
            },
          }));
        }
        Loader(false);
      }
      Loader(false);
    };

    fetchLabOrderList= async () => {
      const { labData } = this.props;
      const laborderResponse = await LabOrderdService.getLabOrder(null,labData.lab_id)
      let tableData = [];
      laborderResponse.map((itm, index) => {
        tableData = [
          ...tableData,
          {
            labOrderIndex: index,
            isHeading: false,
            rowId: itm.lab_order_id,
            data: {
              lab_order: isSet(itm.lab_order,""),
              device_identifier:itm.device_identifier,
              test_code: itm.test_code,
              message_override:itm.message_override==1?'Yes':'No',
              lab_message:itm.lab_message,
              active: "Yes",
              action: [
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Edit",
                  title: "Edit",
                  type: "GridEdit",
                  acl: PERMISSIONS.LAB.EDIT,
                  clickHandler: (rowId, data) => this.editHandler(rowId, data),
                  updateHandler: () => this.updateHandler(itm.lab_order_id),
                  onChangeFormDataInEdit: (key, val) => this.onChangeFormDataInEdit(key, val),
                },
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Remove",
                  acl: PERMISSIONS.LAB.DELETE,
                  title: "Delete",
                  isDelete:true,
                  clickHandler: () => this.deleteHandler(itm.lab_order_id),
                },
              ],
            },
          },
        ];
      });
      this.setState({
        tableRecords: {
          ...this.state.tableRecords,
          tableRows: {
            type: "default",
            data: tableData,
          }
        }
      },()=>{this.editHandler(null, {}, null);});
    };

    modelCloseHandler = () => {
      const { addFormHandler } = this.props;
      addFormHandler();
      this.fetchLabOrderList();
    };

    onChangeFormDataInEdit = (key, val) => {
      this.setState((prevState) => ({
        formDataInEdit: {
          ...prevState.formDataInEdit,
          [key]: val,
          validation: {
            ...prevState.formDataInEdit.validation,
            [key]: '', // Set validation empty
          },
        },
      }));
    };
    getInputFields=()=>{
        const inputFields = [
            {
              type: "SelectList",
              options:this.state.labOrderOptions,
              label: "Lab Order",
              placeholder: "value",
              className: "form-control form-control-solid",
              name: "lab_order",
              required:true
            },
            {
              type: "TextInput",
              label: "Device Identifier",
              placeholder: "Device Identifier",
              className: "form-control form-control-solid",
              name: "device_identifier",
            },
            {
              type: "TextInput",
              label: "Test Code",
              placeholder: "Test Code",
              className: "form-control form-control-solid",
              name: "test_code",
            },
            {
              type: "Checkbox",
              label: "Message Override",
              placeholder: "Test Code",
              className: "form-check-input ml-0",
              name: "message_override",
              isLabelAppend:true,
            },
            {
              type: "TextArea",
              label: "Lab Message",
              placeholder: "Lab Message",
              className: "form-control form-control-solid",
              style: { height: "200px" },
              name: "lab_message",
            },
          ];
          return inputFields;
    }
}
// const AddModal = (props) => {
//   const { labOrderOptions, hasEditAccess ,activeEditId } = props;
//   const [formData, setFormData] = useState({});
//   const [validation, setValidation] = useState({});
//   const changeHandler = (val, fieldName) => {
//     setFormData({
//       ...formData,
//       [fieldName]: val
//     });
//     setValidation({
//       ...validation,
//       [fieldName]: ''
//     });
//   };
//   const formSubmitHandler=()=>{

//   }
//   const inputFields = [
//     {
//       type: "SelectList",
//       options: labOrderOptions,
//       label: "Lab Order",
//       placeholder: "value",
//       className: "form-control form-control-solid",
//       name: "lab_order",
//       required: true
//     },
//     {
//       type: "TextInput",
//       label: "Device Identifier",
//       placeholder: "Device Identifier",
//       className: "form-control form-control-solid",
//       name: "device_identifier",
//     },
//     {
//       type: "TextInput",
//       label: "Test Code",
//       placeholder: "Test Code",
//       className: "form-control form-control-solid",
//       name: "test_code",
//     },
//     {
//       type: "Checkbox",
//       label: "Message Override",
//       placeholder: "Test Code",
//       className: "form-check-input ml-0",
//       name: "message_override",
//       isLabelAppend: true,
//     },
//     {
//       type: "TextArea",
//       label: "Lab Message",
//       placeholder: "Lab Message",
//       className: "form-control form-control-solid",
//       name: "lab_message",
//     },
//   ];
//   return (
//     <>
//       <Modal
//         show={true}
//         onHide={() => { }}
//         keyboard={false}
//         contentClassName='h-100'
//         backdrop="static"
//         size="md">
//         <div className="sh_modalBox">
//           <Modal.Header closeButton>
//             <Modal.Title>
//               <h6 className="font-weight-bold text-lg mb-0">{`Lab Order for  `}</h6>
//             </Modal.Title>
//           </Modal.Header>
//           <Modal.Body>

//             {inputFields &&
//               inputFields.map((field, index) => (
//                 <div className={`col-12`} key={index}>
//                   <div className="form-group">

//                     {(isSet(field.isLabelAppend, false) === false) ?
//                       <label className="mb-0 text-sm opacity-75 text-truncate">
//                         {field.label}
//                       </label>
//                       : <></>}

//                     <FormInputs
//                       {...field}
//                       value={isSet(formData[field.name], "")}
//                       readOnly={!hasEditAccess}
//                       changeHandler={(value, e) => changeHandler(value, field.name, e)}
//                     />
//                     {(isSet(field.isLabelAppend, false) === true) ?
//                       <label className="form-check-label ml-4" htmlFor={field.name}>
//                         {field.label}
//                       </label>
//                       : <></>}
//                     {isSet(validation[field.name], "") !== "" ? (
//                       <span className="text-danger m-1">
//                         {isSet(validation[field.name], "")}
//                       </span>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 </div>
//               ))}
//           </Modal.Body>

//           <Modal.Footer className="d-flex justify-content-center">
//             <Buttons
//               type='Single'
//               className="btn btn-primary  "
//               clickHandler={formSubmitHandler}
//               label='Submit'
//               acl={'any'} />
//           </Modal.Footer>
//         </div>
//       </Modal>
//     </>
//   );
// }

export default withReduxState(WithRoutify(AddLabOrder));



