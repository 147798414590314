import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import FormInputs from "../../components/UI/FormInputs";
import WithRoutify from "../../hoc/WithRoutify";
import { isObject, isSet, convertUnderScoreStrings,stripHtmlTags } from "../../utils/commonUtils";
import ConsentFormService from "../../services/ConsentFormService";
import { CONSENT_ACKNOWLEDGEMENT_EN, CONSENT_ACKNOWLEDGEMENT_ES } from "../../utils/constant";
import OrganizationService from "../../services/OrganizationService";
import { useSelector } from "react-redux";
import { organizationList } from "../../store/OrganizationSlice";
import ClinicService from "../../services/ClinicService";
import Buttons from "../../components/UI/Buttons";
import DropDownService from "../../services/DropDownService";
import Button from "../../components/UI/Buttons/Button";
import TranslatorService from "../../services/TranslatorService";

const AddClinicConsentForm = (props) => {

  const { isOpen, isMultipleOrg, consentFormHandler, visitCategoryOptions, modelTitle, activeEditId } = props;
  const [inputFields, setInputFields] = useState([]);
  const [formData, setFormData] = useState({
    organization_uuid:  props.organizationId
    , acknowledgement_es: CONSENT_ACKNOWLEDGEMENT_ES
    , acknowledgement_en: CONSENT_ACKNOWLEDGEMENT_EN
  });
  const [validation, setValidation] = useState({});
  const organizationListOptions = useSelector(organizationList);
  const MAX_CONTENT_LENGTH = 1000000;
  const [clinicDetails, setClinicDetails] = useState(props.clinicDetails ? props.clinicDetails : [{ label: "-Select Clinic-", value: '' }]);
  const [spanishTranslateClicked,setSpanishTranslateClicked]=useState(false); //for warning message for before save spanish translate

  //remove spanish warning message 
  useEffect(() => {
    if (spanishTranslateClicked == true && formData.consent_form_content_es == null || formData.consent_form_content_es == '' || formData.consent_form_content_es == '<h1><br></h1>') {
      setSpanishTranslateClicked(false);
    }
  }, [formData.consent_form_content_es])
  // console.log("formdata",formData.consent_form_content_es);

  useEffect(() => {
    if (activeEditId) {
      fetchConsentFormById();
    }

  }, []);

  useEffect(() => {
    const fields = getInputFields();
    setInputFields(fields);
  }, [clinicDetails])

  useEffect(() => {
    if (isMultipleOrg && formData.organization_uuid) {
      fetchClinicList(formData.organization_uuid);
    }
  }, [formData.organization_uuid]);




  const fetchConsentFormById = async () => {
    let updatedFormData = { ...formData }
    let updatedValidation = { ...validation };
    if (activeEditId) {
      props.Loader(true);
      const response = await ConsentFormService.getConsentFormById(isSet(props.organizationId, null), activeEditId);
      props.Loader(false);
      if (response) {
        updatedFormData = { ...updatedFormData, ...response };
      }
      if (updatedFormData['status']) {
        updatedFormData['status'] = 'Active';
      } else {
        updatedFormData['status'] = 'Inactive';
      }
      if(!isSet(updatedFormData['clinic_id'])){
        updatedFormData['clinic_id']= 'All';
      }
      if (updatedFormData['includes_spanish_translation']) {
        setInputFields(prevInputFields => {
          // Add Spanish translation fields before the status field
          const spanishFields = getSpanishField();
          return [
            ...prevInputFields.slice(0, prevInputFields.findIndex(field => field.name === 'status')),
            ...spanishFields,
            ...prevInputFields.slice(prevInputFields.findIndex(field => field.name === 'status'))
          ];
        });
      }
      setFormData(updatedFormData);
      setValidation(updatedValidation);
    }
  };



  const fetchClinicList = async (organizationUUID, query = {}) => {
    props.Loader(true);
    const queryString = null;
    try {
      const clinicList = await DropDownService.getClinicList(organizationUUID, queryString);
      const updatedClinicDetails = [{ label: "-Select Clinic-", value: '' }];
      if(clinicList){
        if(clinicList.length > 0){
          updatedClinicDetails.push({
            label:"All",value:"All"
          });
        }
        clinicList.forEach(clinic => {
          updatedClinicDetails.push({
            label: convertUnderScoreStrings(clinic.name),
            value: clinic.clinic_id
          });
        });
      }
    
      setClinicDetails(updatedClinicDetails); // Update clinicDetails state directly

      props.Loader(false);
    } catch (e) {
      props.Loader(false);
    }
  };



  const changeHandler = async (val, fieldName, e) => {
    
      setFormData({
        ...formData,
        [fieldName]: val,
      });
    
    if (fieldName === "includes_spanish_translation") {
      if (val === 1) {
        const spanishFields = getSpanishField();
        // Insert Spanish translation fields before the status field
        const updatedInputFields = [
          ...inputFields.slice(0, inputFields.findIndex(field => field.name === 'status')),
          ...spanishFields,
          ...inputFields.slice(inputFields.findIndex(field => field.name === 'status'))
        ];
        setInputFields(updatedInputFields);
      } else {
        const updatedInputFields = inputFields.filter(
          (field) => field.name !== "consent_form_content_es" &&
            field.name !== "title_es" &&
            field.name !== "acknowledgement_es"
        );
        setInputFields(updatedInputFields);
      }
    }
    setValidation({ ...validation, [fieldName]: "" });

  };

  const formSubmitHandler = async () => {
    let updateValidation = { ...validation };
    if (isMultipleOrg) {
      if (isSet(formData.organization_uuid, false) == false || formData.organization_uuid === null) {
        setValidation({
          ...updateValidation,
          ['organization_uuid']: 'The Organization Field is Required',
        });
        return false
      }
    }
    // if (new Blob([formData.consent_form_content_en]).size > MAX_CONTENT_LENGTH) {
    //   setValidation({
    //     ...updateValidation,
    //     ['consent_form_content_en']: 'The content is too large. Please reduce its size.',
    //   });
    //   return false;
    // }       
    if (stripHtmlTags(formData.consent_form_content_en).length ==0 ) {
      setValidation({
        ...updateValidation,
        ['consent_form_content_en']: 'The consent form content en field is required.',
      });
      return false
    }
    if (formData.includes_spanish_translation === 1 && stripHtmlTags(formData.consent_form_content_es).length == 0 ) {
      setValidation({
        ...updateValidation,
        ['consent_form_content_es']: 'The consent form content es field is required.',
      });
      return false
    }
    const payload = formData;
    props.Loader(true);
    let data;
    if (activeEditId) {
      data = await ConsentFormService.updateConsentForm(isSet(formData.organization_uuid, null), activeEditId, payload);
    } else {
      data = await ConsentFormService.saveConsentForm(isSet(formData.organization_uuid, null), payload);

    }
    if (data.status && data.status === "ERROR") {
      if (isObject(data.data)) {
        for (let key in data.data) {
          updateValidation = {
            ...updateValidation,
            [key]: data.data[key].join(","),
          };
        }
      } else {
        props.Toast.error(data.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    } else {
      let message = `Consent Form ${activeEditId ? 'Updated' : 'Added'} Successfully`
      props.consentFormHandler(true);
      props.Toast.success(message);
    }
    props.Loader(false);

  };
  const translateEnglishToEnglish = async ()=>{
   
    props.Loader(true);
    const [content, title,acknowledgement] = await Promise.all([
      TranslatorService.translator(isSet(formData['consent_form_content_en'],'')),
      TranslatorService.translator(isSet(formData['title_en'],'')),
      TranslatorService.translator(isSet(formData['acknowledgement_en'],''))
  ]);
  props.Loader(false);
  setFormData({
    ...formData,
    consent_form_content_es:content,
    title_es:title,
    acknowledgement_es:acknowledgement,
  })
  setSpanishTranslateClicked(true);
  }



  return (
    <>
      <Modal
        show={isOpen}
        onHide={consentFormHandler}
        size='xl'
        dialogClassName='hResponsive'
        contentClassName='h-100'
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">
                {modelTitle}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {inputFields &&
                inputFields.map((field, index) => {
                  if (field == null) {
                    return false;
                  }

                  return (
                    <div className={field.col ? field.col : `col-6 `} key={index}>
                      <div className={isSet(field.formGroup, "form-group")}>
                        {field.type !== 'Checkbox' && (
                          <label className={field.labelClassName}>{field.label}</label>
                        )}
                        {field.type !== 'Checkbox' ? <FormInputs
                          changeHandler={(value, e) =>
                            changeHandler(value, field.name, e)
                          }
                          value={formData[field.name]}
                          {...field}
                        /> : <>
                          <FormInputs
                            changeHandler={(value, e) =>
                              changeHandler(value, field.name, e)
                            }
                            value={formData[field.name]}
                            {...field}
                          />
                          <label class="form-check-label text-md font-weight-bold" htmlFor={field.id}> Include Spanish Translation? </label>
                          {Boolean(formData[field.name])===true && 
                          <>
                          <Button clickHandler={()=>translateEnglishToEnglish()} acl="any" className="ml-2 badge bg-blue-soft text-light cursor-pointer custom-btn">{`Translate from English`}</Button>
                              {
                                isSet(spanishTranslateClicked, false) === true &&
                                <div class="alert alert-warning mt-2" role="alert">
                                  This Spanish translation may be mistake, please check before saving.
                                </div>
                              }
                          </>
                          }
                          <br /><br />
                          
                         
                        </>}
                        {isSet(validation[field.name], "") !==
                          "" ? (
                          <span className="text-danger m-1">
                            {isSet(validation[field.name], "")}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )
                })}
            </div>

          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              clickHandler={() => formSubmitHandler()}
              className="btn btn-primary"
              acl={`consent-${activeEditId ? 'edit' : 'create'}`}
              label={activeEditId ? "Update" : "Save"}
            />
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );

  function getInputFields() {
    const inputFields =
      [

        {
          type: "SelectList",
          options: clinicDetails,
          label: "Clinic List",
          placeholder: "Type",
          className: "form-control form-control-solid",
          name: "clinic_id",
          required: true,
        },
        {
          type: "SelectList",
          options: visitCategoryOptions,
          label: "Visit Category",
          className: "form-control form-control-solid",
          name: "visit_category",

          required: true,
        },
        {
          type: "TextInput",
          col: "col-6",
          label: "Title",
          placeholder: "Title",
          className: "form-control form-control-solid",
          name: "title_en",
          required: true,
        },

        {
          type: "Editor",
          col: "col-12",
          
          label: "Consent Content-English",
          className: "form-control form-control-solid",
          name: 'consent_form_content_en',
        },

        {
          type: "TextArea",
          col: "col-12",
          className: "form-control form-control-solid",
          name: "acknowledgement_en",
          required: true,
          // value:"I have read, understood, acknowledged, and fully agreed to the policies stated above."
        },

        {
          col: 'col-12',
          type: "Checkbox",
          id: "spanish",
          name: "spanish",
          label: "Include Spanish Translation? ",
          // isTranslate:true,
          // translateOptions:{
          //   buttonLabel:'Translate from English',
          //   changeHandler:(val)=>{console.log('tranlate',val)},
          // },
          formGroup: "form-group form-check form-check-solid",
          className: "form-check-input",
          name: 'includes_spanish_translation',
        },
        {
          type: "SelectList",
          options: [
            { label: '-Status-', value: '' },
            { label: 'Active', value: 'Active' },
            { label: 'In-Active', value: 'Inactive' },
          ],
          label: "Status",
          className: "form-control form-control-solid",
          name: 'status',
          required: true,
        },
      ];
    if (isMultipleOrg && !activeEditId) {
      inputFields.unshift(
        {
          type: "SelectList",
          col: "col-6",
          label: "Organization",
          options: organizationListOptions,
          placeholder: "",
          className: "form-control form-control-solid",
          name: "organization_uuid",
          required:true
        },
        {
          type: 'Blank',
          col: 'col-6',
          label: ''
        }

      );
    }

    return inputFields;
  }

  function getSpanishField() {
    return [
      {
        type: "TextInput",
        label: "Title",
        col: "col-6",
        placeholder: "Title",
        className: "form-control form-control-solid",
        name: "title_es",
        required: true,
      },
      {
        type: "Editor",
        col: "col-12",
        label: "Consent Content-Spanish",
        className: "form-control form-control-solid",
        name: 'consent_form_content_es',
      },
      {
        type: "TextArea",
        col: "col-12",
        className: "form-control form-control-solid",
        name: "acknowledgement_es",
        required: true,
        // value:"I have read, understood, acknowledged, and fully agreed to the policies stated above."
      },

    ]
  }

}

export default WithRoutify(AddClinicConsentForm);
