import React, { Component } from "react";
import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";
import AddInsurance from "./AddInsurance";
import InsuranceService from "../../services/InsuranceService";
import WithRoutify from "../../hoc/WithRoutify";
import { OK } from "../../utils/constant";
import { isObjectEmpty, toQueryString } from "../../utils/commonUtils";
import TableSkeleton from "../../hoc/TableSkeleton";

class InsuranceList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      {
        label: "Insurance Name",
        key: "name",
        type: "",
        inputType: "TextInput",
      },
      { label: "Active", key: "active" },
      { label: "Action", key: "action", type: "Actions" },
      // Add more columns as needed
    ];
    this.state = {
      filters: {
        search_query: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "insurance_name",
          sort_order: "asc",
          search: {},
        },
      },
      activeEditId: null,
      // Your state variables go here
      isModelOpen: false,
    };
  }

  componentDidMount() {
    this.fetchInsuranceList();
    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  render() {
    const { tableRecords, filters, isModelOpen,activeEditId} = this.state;
    return (
      <>
        {isModelOpen ? (
          <AddInsurance
            modelTitle={`${this.state.activeEditId ? "View and Update" : "Add"} Insurance`}
            isOpen={this.state.isModelOpen}
            fetchInsuranceList={this.fetchInsuranceList}
            addFormHandler={this.addFormHandler}
            activeEditId={activeEditId}
          />
        ) : (
          <></>
        )}
        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"insurance-create"}
          content={"Insurance"}
        >
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                <h6 class="text-lg f-500 mb-0"> Insurance List </h6>

                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div class="col-12 px-1 d-flex">
                      <div class="input-group input-group-joined input-group-solid">
                        <FormInputs
                          placeholder="Insurance"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>

                      <div class="btn-group pl-2">
                        <button
                          class="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          class="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                      </button>
                      </div>
                    </div>
                  </div>

                  <div className="addAction">
                    <div className="btn-group">
                      <Buttons
                        clickHandler={()=>{this.addFormHandler();this.setState({activeEditId:''})}}
                        className="btn btn-primary"
                        acl={"insurance-create"}
                        label={"Add"}
                      />
                      <button
                        className="btn btn-outline-secondary dropdown no-arrow"
                        data-bs-toggle="dropdown"
                      >
                        <span className="dropdown-toggle">
                          <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </span>
                        <div
                          className="dropdown-menu dropdown-menu-right shadow"
                          aria-labelledby="userDropdown"
                        >
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Group Edit
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Grid Edit
                          </a>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                hasPagination={true}
                fetchTableRecords={(filters) =>
                  this.fetchInsuranceList(filters)
                }
                {...tableRecords}
              />
            </div>
          </div>
        </TableSkeleton>
      </>
    );
  }
  submitFiltersHandler = () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      page:1,
      search: filters,
    };
    this.fetchInsuranceList(queryfilters);
  };
  clearFiltersHandler = () => {
    this.setState(
      {
        filters: {},
      },
      this.submitFiltersHandler
    );
  };
  fetchInsuranceList = async (query = {}) => {
    const { tableRecords } = this.state;
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });
    this.props.Loader(true);
    InsuranceService.getInsurance(queryString)
      .then((res) => {
        let responseData = res.data;
        let tableData = [];
        responseData.data.data.map((insurance, insuranceIndex) => {
          tableData = [
            ...tableData,
            {
              insuranceIndex: insuranceIndex,
              isHeading: false,
              rowId: insurance.insurance_company_id,
              data: {
                isActive: false,
                name: insurance.insurance_name,
                active:insurance.is_active==0?'No':'Yes',
                action: [
                  {
                    className:
                      "btn btn-icon btn-transparent-dark",
                    iconType: "Search",
                    title: "View",
                    type: "GridEdit",
                    acl: "insurance-edit",
                    clickHandler: (rowId, data) =>
                      this.editHandler(
                        rowId,
                        data,
                        insurance.insurance_company_id
                      ),
                  },
                  {
                    className:
                      "btn btn-icon btn-transparent-dark",
                    iconType: "Remove",
                    acl: "insurance-delete",
                    title: "Delete",
                    isDelete: true,
                    clickHandler: () =>
                      this.deleteHandler(insurance.insurance_company_id),
                  },
                ],
              },
            },
          ];
        });
        //setState
        this.setState((prevState) => ({
          tableRecords: {
            ...prevState.tableRecords,
            tableHeaders: prevState.tableRecords.tableHeaders,
            tableRows: {
              type: "default",
              data: tableData,
              status: 200,
            },
            pagination: {
              ...prevState.tableRecords.pagination,
              ...query,
              totalRecords: res.data.pagination.totalCount,
            },
          },
        }));
      })
      .catch((err) => {
        // console.log("err===>", err);
        this.setState({
          tableRecords: {
            ...tableRecords,
            tableRows: {
              ...tableRecords.tableRows,
              status: 200,
            },
          },
        });
      })
      .finally(() => {
        this.props.Loader(false);
      });
  };
  deleteHandler = async (id) => {
    InsuranceService.deleteInsurance(id)
      .then((res) => {
        // console.log("res==>", res);
        this.fetchInsuranceList();
        this.props.Toast.success("Insurance Deleted Successfully");
      })
      .catch((err) => {
        //
      })
      .finally(() => {
        //
      });
  };
  editHandler = (rowId, data) => {
    this.setState({
      activeEditId: rowId,
    },this.addFormHandler());
  };
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}
export default WithRoutify(InsuranceList);
