import { useEffect, useState } from "react";
import FormInputs from "../../../components/UI/FormInputs";
import ActivityConsentDeclationService from "../../../services/ActivityConsentDeclationService";
import { isObject, isSet } from "../../../utils/commonUtils";
import { createPortal } from "react-dom";
import WithRoutify from "../../../hoc/WithRoutify";
import Button from "../../../components/UI/Buttons/Button";
import TranslatorService from "../../../services/TranslatorService";

const ActivityConsent = (props) => {
    const { activityData, Loader, Toast,formData,setFormData } = props;
    // const [formData, setFormData] = useState({});
    const [inputFields, setInputFields] = useState([]);
    const [tableFooter, setTableFooter] = useState(null);
    const [validation, setValidation] = useState({});
    const [spanishTranslateClicked,setSpanishTranslateClicked]=useState(false); //for warning message for before save spanish translate

    useEffect(() => {
        setInputFields(getInputFields());
        const TargetContainer = document.querySelector('.sh_modalBox');
        setTableFooter(TargetContainer);
        fetchConsent();
    }, []);
    //remove spanish warning message 
    useEffect(()=>{
        if (spanishTranslateClicked==true&&formData.activity_consent_content_es==null||formData.activity_consent_content_es==''||formData.activity_consent_content_es=='<p><br></p>') {
            setSpanishTranslateClicked(false);
        }
    },[formData.activity_consent_content_es])
    console.log('formdata',formData.activity_consent_content_es);
    const fetchConsent = async () => {
        Loader(true);
        let updatedFormData = { ...formData }
        updatedFormData['activity_id'] = activityData.actitivity_id;
        let response = await ActivityConsentDeclationService.getActivitiesConsentById(activityData.actitivity_id);
        if (response) {
            updatedFormData = { ...updatedFormData, ...response };
        }
        //update include spanish
        if (updatedFormData['includes_spanish_translation']) {
            setInputFields(prevInputFields => [
                ...prevInputFields,
                ...getSpanishInputFields()
            ]);
        }
        setFormData(updatedFormData);
        Loader(false);
    }
    const changeHandler = (value, fieldName, dependencyValues) => {
        let updatedFormData = { ...formData };
        let updatedValidation = { ...validation };

        if (fieldName === 'includes_spanish_translation') {
            if (value === 1) {
                // Append Spanish input fields if the checkbox is checked
                setInputFields(prevInputFields => [
                    ...prevInputFields,
                    ...getSpanishInputFields() // Spread the Spanish fields array
                ]);
            } else {
                // Remove Spanish input fields if the checkbox is unchecked
                setInputFields(prevInputFields => prevInputFields.filter(field => field.section !== "3" && field.section !== "4"));
                updatedFormData['activity_consent_content_es'] = '';
                updatedFormData['authorization_message_es'] = '';
                updatedFormData['declination_message_es'] = ''
            }
        }
        updatedFormData[fieldName] = value
        updatedValidation[fieldName] = "";
        setValidation(updatedValidation);
        setFormData(updatedFormData);
    }
    const submitHandler = async () => {
        Loader(true);
        const response = await ActivityConsentDeclationService.updateActivitiesConsent(activityData.actitivity_id, formData);
        if (response?.status === "ERROR" && response?.data) {
            if (isObject(response.data)) {
                let validaitons = {};
                for (let key in response.data) {
                    validaitons = { ...validaitons, [key]: response.data[key] };
                }
                setValidation(validaitons);
            }
        } else {
            if(isSet(response.actitivity_id)){
                props.addFormHandler(response.actitivity_id,response,true,true);
            }else{
                setFormData(response);
            }
            Toast.success("Activity Consents Updated Successfully")
            setValidation({});
        }
        Loader(false);
    }
    const translateEnglishToEnglish = async ()=>{
        props.Loader(true);
        const [content, authorization,declination] = await Promise.all([
          TranslatorService.translator(isSet(formData['activity_consent_content_en'],'')),
          TranslatorService.translator(isSet(formData['authorization_message_en'],'')),
          TranslatorService.translator(isSet(formData['declination_message_en'],''))
      ]);
      props.Loader(false);
      setFormData({
        ...formData,
        activity_consent_content_es:content,
        authorization_message_es:authorization,
        declination_message_es:declination,
      })
      setSpanishTranslateClicked(true);
    }
    return (
        <>
            <div className="card mb-2">
                <a href="#collapseCardExample" className="d-block sh_accordionheader card-header" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardExample" style={{ position: 'sticky', top: '-20px', zIndex: '1' }}>
                    <h6 className="m-0 text-md "> Adding Activity Consents for <span className="badge bg-blue-soft text-blue">{activityData.activity_name}</span> </h6>
                </a>

                <div className="collapse show" id="collapseCardExample" data-bs-parent="#modal111">
                    <div className="card-body">
                        {
                            inputFields.map((section, sectionIndex) => (
                                <div className="row" key={sectionIndex}>
                                    {section.data.map((field, fieldIndex) => (
                                        <div className={`${field.col ? field.col : 'col-lg-4'}`} key={fieldIndex}>
                                            <div className={isSet(field.formGroup, "form-group")}>
                                                {field.type !== 'Checkbox' && (
                                                    <label className={field.labelClassName}>{field.label}</label>
                                                )}
                                                {field.type !== 'Checkbox' ?

                                                    <FormInputs
                                                        changeHandler={(value, e) =>
                                                            changeHandler(value, field.name, e)
                                                        }
                                                        value={formData[field.name]}
                                                        {...field}
                                                    />
                                                    :
                                                    <>
                                                        <FormInputs
                                                            changeHandler={(value, e) =>
                                                                changeHandler(value, field.name, e)
                                                            }
                                                            value={formData[field.name]}
                                                            {...field}
                                                        />
                                                        <label class="form-check-label text-md font-weight-bold ml-4" htmlFor={field.id}> Include Spanish Translation? </label> 
                                                        {Boolean(formData[field.name])===true && 
                                                        <>
                                                        <Button clickHandler={()=>translateEnglishToEnglish()} acl="any" className="ml-2 badge bg-blue-soft text-light cursor-pointer custom-btn">{`Translate from English`}</Button>
                                                        {
                                                            isSet(spanishTranslateClicked, false) === true &&
                                                            <div class="alert alert-warning mt-2" role="alert">
                                                                This Spanish translation may be mistake, please check before saving.
                                                        </div>
                                                        }
                                                           
                                                        </>
                                                        }
                                                    </>

                                                    // <>
                                                    //     <input class="form-check-input" type="checkbox" name="" id="" onChange={(e) => changeHandler(Boolean(e.target.checked) ? 1 : 0, 'includes_spanish_translation')} value={formData['includes_spanish_translation']} />
                                                    //     <label class="form-check-label text-md font-weight-bold" for=""> Include Spanish Translation? </label>
                                                    // </>
                                                }
                                                {isSet(validation[field.name], "") !==
                                                    "" ? (
                                                    <span className="text-danger m-1">
                                                        {isSet(validation[field.name], "")}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}

                                            </div>

                                        </div>
                                    ))}
                                </div>
                            ))
                        }


                    </div>

                </div>



            </div>
            {isSet(tableFooter, null) !== null ? (
                <div className="">
                    {createPortal(
                        <div class="d-flex justify-content-center modal-footer">
                            <button type="button" class="btn btn-primary" onClick={() => submitHandler()}>Submit</button>
                        </div>,
                        tableFooter
                    )}
                </div>
            ) : (
                // Handle the case where the target container is not found
                <></>
            )}
        </>
    );
    function getInputFields() {
        const inputFields =
            [
                {
                    section: "0",
                    data: [
                        {
                            col: 'col-12',
                            type: "Editor",
                            label: "Activity Consents Content",
                            className: "form-control form-control-solid",
                            name: 'activity_consent_content_en',
                        },

                    ]
                },
                {
                    section: "1",
                    data: [
                        {
                            type: "TextInput",
                            label: "Authorization Message",
                            className: "form-control form-control-solid",
                            name: 'authorization_message_en',
                            required: true,
                        },
                        {
                            type: "TextInput",
                            label: "Declination Message",
                            className: "form-control form-control-solid",
                            name: 'declination_message_en',
                            required: true,
                        },
                        {
                            type: "SelectList",
                            options: [
                                { label: '-Status-', value: '' },
                                { label: 'Active', value: 'Active' },
                                { label: 'In Active-', value: 'Inactive' },
                            ],
                            label: "Status",
                            className: "form-control form-control-solid",
                            name: 'status',
                            required: true,
                        },

                    ]
                },
                {
                    section: "2",
                    data: [
                        {
                            col: 'col-12',
                            type: "Checkbox",
                            label: "Include Spanish Translation? ",
                            labelClassName: "form-check-label text-md font-weight-bold ml-2",
                            formGroup: "form-group form-check form-check-solid pl-0",
                            className: "form-check-input ml-1",
                            id: 'includes_spanish_translation',
                            name: 'includes_spanish_translation',
                        },


                    ]
                }
            ];

        return inputFields;
    }

    function getSpanishInputFields() {
        return [
            {
                section: "3",
                data: [
                    {
                        col: 'col-12',
                        type: "Editor",
                        label: "Activity Consents Content",
                        className: "form-control form-control-solid",
                        name: 'activity_consent_content_es',
                    },

                ]
            },
            {
                section: "4",
                data: [
                    {
                        type: "TextInput",
                        label: "Authorization Message",
                        className: "form-control form-control-solid",
                        name: 'authorization_message_es',
                        required: true,
                    },
                    {
                        type: "TextInput",
                        label: "Declination Message",
                        className: "form-control form-control-solid",
                        name: 'declination_message_es',
                        required: true,
                    },
                ]
            }

        ]
    }

}
export default WithRoutify(ActivityConsent);