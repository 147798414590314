import { useState } from "react";
import { calculateAge, formatPhoneNumber, isSet } from "../../../utils/commonUtils";
import FormInputs from "../../../components/UI/FormInputs";

const ConsentForm = (props) => {
    const { onChangeHandler, patientFormData,formData,validation} = props;
    const { visit } = patientFormData;
    const consentForm=patientFormData.consent_form;
    const employee=patientFormData.employee;
    console.log('consntform',consentForm);
    const organizationLogo = patientFormData.organization.organization_logo;
    const inputFields = [
        {
            type: 'Checkbox',
            label: isSet(consentForm.acknowledgement_en, ''),
            col: 'col-12',
            isLabelAppend: true,
            className: "form-check-input",
            name: 'is_acknowledged',
            checked: formData.is_acknowledged === 1,
        },
        {
            label: "Printed Name",

            className: "form-control form-control-solid",
            name: "printed_name",
            col: "col-4",
            required: true,
            value: `${employee.first_name} ${employee.last_name}`, 
            readOnly: true 
        },
        {
            label: "Date of Birth",
            type: "TextInput",
            className: "form-control form-control-solid",
            name: "date_of_birth",
            col: "col-4",
            required: true,
            value: employee.dob, 
            readOnly: true 
        },
        {
            label: "Age",
            type: "Number",
            className: "form-control form-control-solid",
            name: "age",
            col: "col-4",
            required: true,
            value: calculateAge(employee.dob),
            readOnly: true
        },
        {
            label: "Email Address",
            type: "Email",
            className: "form-control form-control-solid",
            name: "email_address",
            col: "col-4",
            required: true,
            value: employee.email, 
            readOnly: true 
        },
        {
            label: "Address",
            type: "TextInput",
            className: "form-control form-control-solid",
            name: "address",
            col: "col-4",
            required: true,
            value: employee.address, 
            readOnly: true 
        },
        {
            label: "Phone",
            type: "Tel",
            className: "form-control form-control-solid",
            name: "phone",
            col: "col-4",
            required: true,
            value: formatPhoneNumber(employee.phone), 
            readOnly: true 
        },
        {
            label: "Patient Signature/Consent",
            type: "Signature",
            className: "form-control form-control-solid",
            name: "patient_signature",
            options: [],
            col: "col-6",
            value: formData.patient_signature,
            required: true
        },
        {
            label: 'Date',
            type: 'Date',
            name: 'consent_date',
            col: "col-6",
            value: formData.consent_date,
            required: true
        }
    ];

    return (
        <>
            <div className="tab-pane active show" role="tabpanel" id="ac">
                <div className="card mb-2">
                    <a href="#collapseCardExample" className="d-block sh_accordionheader card-header" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardExample" style={{ position: 'sticky', top: '-2px', zIndex: '1' }}>
                        <h6 className="m-0 text-md ">Clinic Consents form <span className="badge bg-blue-soft text-blue"> {props.visit_category} </span> </h6>
                    </a>
                    <div className="collapse show" id="collapseCardExample" data-bs-parent="#modal111">
                        <div className="card-body">
                            <div className="row">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-8 col-md-10">
                                            <div className="mb-4 p-3 text-center ">
                                                <img src={`data:image/jpeg;base64,${organizationLogo}`} alt="" style={{ maxWidth: '75%', maxHeight: '150px', margin: 'auto' }} />
                                            </div>
                                            <div className="mb-4 p-3 text-center ">
                                                <h6 className="font-weight-bold text-uppercase">{consentForm.title_en}</h6>
                                            </div>

                                        </div>
                                        <div className="mw-100 w-100">
                                            <HTMLContent htmlString={consentForm.consent_form_content_en} />
                                        </div>
                                    </div>
                                </div>
                                {inputFields &&
                                    inputFields.map((field, fieldIndex) => {
                                        if (isSet(field.isShow, true) === false) { return false }
                                        return (
                                            <div className={field.col} key={fieldIndex}>
                                                <div className={`form-group ${isSet(field.isLabelAppend, false) === true ? 'form-check form-check-solid' : ''}`} >
                                                    {(isSet(field.isLabelAppend, false) === false) ?
                                                        <label className="mb-0 text-sm opacity-75 text-truncate">
                                                            {field.label}
                                                        </label>
                                                        : <></>}

                                                    <FormInputs {...field} value={isSet(field.value, formData[field.name])} changeHandler={(value, e) => onChangeHandler(field.name,value)} />
                                                    {(isSet(field.isLabelAppend, false) === true) ?
                                                        <>
                                                            <label className="form-check-label" >
                                                                {field.label}
                                                            </label><br /><br />
                                                        </>

                                                        : <></>}
                                                    {isSet(validation[field.name], "") !== "" ? (
                                                        <span className="text-danger m-1">
                                                            {isSet(validation[field.name], "")}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const HTMLContent = ({ htmlString }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};
export default ConsentForm;
