import { useEffect, useState } from "react";
import VisitsService from "../../services/VisitsService";
import { isSet } from "../../utils/commonUtils";
import Buttons from "../../components/UI/Buttons";

const FutureAppointments = (props) => {
    const [appintments,setAppointments]=useState([]);
    // const {employee_id}=props;
    useEffect(()=>{
        const getFutureAppointments=async()=>{
            const response =await  VisitsService.getFutureDateAppointments();
            if(response!==false){
                setAppointments(response);  
            }     
        }
        getFutureAppointments();
    },[props.calendarRecords]);
    // console.log('appointments',appintments);
    
    return (
        <div className="sh_cardBox h-auto sh_widgets sh_labdetailwidget">
            {/* <div className="sh_cardHeader">
                <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                    <h6 className="text-md font-weight-bold mb-0">
                      <span className="px-2">
                        <Buttons
                        iconType='labdetails'
                        />
                        </span>
                        Future Appointments</h6>
                    {appintments.length>0 && <span className="badge badge-primary ml-2">{appintments.length}</span>}
                </div>
            </div>
            <div style={{ overflowX: 'auto' }}>
                <table className="table dataTable table-hover nowrap my-0 table-striped ">
                    <thead>
                        <tr>
                            <th scope="col">Patient Name               </th>
                            <th scope="col">Appointment Time</th>
                        </tr>
                    </thead>
                    <tbody data-rbd-droppable-id="waiting" data-rbd-droppable-context-id="2">
                       {
                        isSet(appintments,[]).map((itm,ind)=>{
                            return(
                                <>
                                 <tr key={ind} data-rbd-draggable-context-id="2" data-rbd-draggable-id="waiting-0" tabindex="0" role="button" aria-describedby="rbd-hidden-text-2-hidden-text-8" data-rbd-drag-handle-draggable-id="waiting-0" data-rbd-drag-handle-context-id="2" draggable="false">
                            <td>{itm.employee.first_name} {itm.employee.last_name}</td>
                            <td>{itm.visit_time}</td>
                        </tr>
                                </>
                            )
                        })
                       }
                    </tbody>

                </table>
            </div> */}
        </div>
    );
}
export default FutureAppointments;