export const getSystemFields=(type='')=>{
    switch (type) {
        case CSV_LAB_PANEL:
            return LabPanelSystemFields();    
        default:
            return [];
    }
};

export const LabPanelSystemFields=()=>{
    return [
        {label:'Lab ID',required:true,name:'random_lab_id'},
        {label:'Lab Order',required:true,name:'lab_order'},
        {label:'Result',required:false,name:'result'},
        {label:'Result Date',required:false,name:'result_date'},
        {label:'Reference Range',required:false,'name':'reference_range'},
        {label:'Units',required:false,name:'units'},
        {label:'Flag',required:false,name:'flag'},
        {label:'Lab Message',required:false,name:'lab_message'}
      ];
}
export const CSV_LAB_PANEL='lab panel'