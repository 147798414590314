import React, {useRef } from 'react';
import WithRoutify from '../../../hoc/WithRoutify';

const ImageUpload = (props) => {
  const {label,name,value,changeHandler,accept}=props;
  const imageExists =value.base64;
  const inputFile = useRef(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    if (file.size >= maxSizeInBytes) {
      props.Toast.error("Selected image exceeds maximum 2 MB size.");
      inputFile.current.value='';
      return; // Exit the function
    }
    changeHandler(e.target.files[0],{base64:value.base64});
  };

  const handleRemoveImage = () => {
    changeHandler(null, {base64:value.base64});
  };

  return (
    <div className="sh_cardBox">
      <div className="sh_cardBody">
        <div className="row">
          <div className="col-12 d-flex align-items-start justify-content-start">
            <div className="image-card">
              {imageExists ? (
                <div>
                  <img
                    src={`data:image/png;base64,${value.base64}`}
                    alt={label}
                    className="img-fluid"
                    style={{ maxWidth: "150px", maxHeight: "150px" }}
                  />
                </div>
              ) : null}
            </div>

            {!imageExists && (
              <div>
                <input
                  ref={inputFile}
                  type="file"
                  onChange={handleFileChange}
                  name={name}
                  accept={accept}
                  style={{ padding: '10px', fontSize: '.9rem' }}
                />
              </div>
            )}

            {imageExists && (
              <span
                title="remove"
                className="badge bg-red-soft text-red cursor-pointer rounded-circle ml-2"
                onClick={handleRemoveImage}
              >
                <i className="fa fa-times"></i>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
ImageUpload.defaultProps = {
  accept: "*/*" // Accept all file formats
};

export default WithRoutify(ImageUpload);