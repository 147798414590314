import { Link } from "react-router-dom"
import Icons from "../../UI/Icons"

export const Menu = () => {
    return (
        <>
            <aside>
                <ul class="navbar-nav sidebar sidebar-dark accordion toggled" id="accordionSidebar">
                    <a class="sidebar-brand " href="#" role="button">
                        <div class="sh_logoIcon">
                            <div class="sidebar-brand-icon">
                                <Link to="/">
                                    <Icons type={`Logo`} />
                                </Link>
                            </div>
                        </div>
                    </a>
                </ul>
            </aside>
        </>
    )
}