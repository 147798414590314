import React, { Component} from "react";
import Modal from "react-bootstrap/Modal";
import { isObject,isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import RolesService from "../../services/RoleService";
import WithRoutify from "../../hoc/WithRoutify";
import Buttons from "../../components/UI/Buttons";
import Permissions  from "./Permissions";
import ScreenDataConfig from "./ScreenDataConfig";
import withReduxState from "../../hoc/wReduxState";
import { isGlobalAdmin } from "../../utils/aclUtils";

const STEPS =["Role Details","Screen Permission Config","Screen Data Config",];
class AddRole extends Component {

  constructor(props){
    super(props); 
    const isMultipleOrg = isSet(props.profile.is_multiple_organization,false);
    const orgId = isSet(props.profile.organization_id,null)!==null?props.profile.organization_id:'';
    this.state = {
      isLoadDataSharing:{
        organization:true,
        clinic:true,
        company:true,
        lab:true,
      },
      currentStep:0,
      dataSharingData:{},
      dataConfig:{
        organization:{
          label:'Organization',
          options:[],
          isActive:isMultipleOrg?true:false,
          value:(isMultipleOrg)?'':orgId,
          visible:isMultipleOrg?true:false,
        },
        company:{
          label:'Company',
          isActive:isMultipleOrg?false:true,
          options:[],
          visible:true,
          value:''
        },
        clinic:{
          label:'Clinic',
          isActive:false,
          options:[],
          visible:true,
          value:''
        },
        lab:{
          label:'Lab',
          isActive:false,
          visible:true,
          options:[],
          value:''
        }
      },
      permissionData:[],
      validation: {},
      permissions:{},
      formData: {
        name: '',
        isAllOrganization:false,
        organizationUuid :orgId
      },
      isAllOrg:false,
    };
    
  }

  componentDidMount =()=> {
    const { activeEditId } = this.props;
    if (isSet(activeEditId,null) !== null) {
      this.fetchRolePermission(activeEditId);
    }
    else{
      if(isGlobalAdmin(this.props.profile.role)){
        this.setState({isAllOrg:true});
      }
    }
  }
  setCurrentStep = (way,stepNo)=>{
   
    if(stepNo===2){
      const isMultipleOrg = isSet(this.props.profile.is_multiple_organization,false);
      this.fetchDataSharingOption(isMultipleOrg?'organization':'company');
    }
    this.setState({currentStep:stepNo});
    this.fetchPermissioinList();
  }
  render(){
    const { isOpen, modelTitle,activeEditId,profile } =this.props;
    const {formData,validation,currentStep,dataConfig,permissions,isAllOrg} = this.state;
    const inputFields = [
        {
            type: "TextInput",
            label: "Role Name",
            placeholder: "Role",
            className: "form-control form-control-solid",
            name: "name",
            value:formData.name,
            max:50,
          }];
      if(this.state.isAllOrg){
          inputFields.push({
              type:'Checkbox',
              label:'Make it visible to all Organization',
              className:"form-check-input",
              name:'isAllOrganization',
              id:'isAllOrganization',
              value:formData.isAllOrganization
            });
      }
    const buttons ={
        type:'BtnList',
        buttonList:[
          {
            className:'btn btn-light btn-sm mr-1',
            clickHandler:(currentStep==0)?()=>{}:()=>this.setCurrentStep('prev',currentStep-1),
            label:'Previous',
            title:'Previous Step',
            disabled:(currentStep===0)?true:false,
            acl:['role-edit','role-create','any']
          },
          {
            className:'btn btn-primary btn-sm',
            title:'Next Step',
            disabled:(currentStep===0 && isSet(formData.name,null)===null)?true:false,
            clickHandler: () => {
              if (currentStep + 1 === STEPS.length || (Boolean(this.state.formData.isAllOrganization)===true && currentStep===1)) {
                this.formSubmitHandler();
              } else if (!(currentStep === 0 && isSet(formData.name, null) === null)) {
                this.setCurrentStep('next',currentStep + 1);
              }
            },
            label:(currentStep+1===STEPS.length || (Boolean(this.state.formData.isAllOrganization)===true && currentStep===1))?'Save':'Next',
            acl:['role-edit','role-create','any']
          }
        ]
      };
    return(
      <>
       <Modal 
        show={isOpen} 
        onHide={()=>this.props.toogleHandler(null,{})} 
        size="xl"
        dialogClassName="hResponsive"
        contentClassName="h-100"
        backdrop="static" 
        keyboard={false}
      >
        <div className="sh_modalBox">
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="text-lg mb-0 font-weight-bold">
              {(isSet(activeEditId,null)!==null)?`Edit ${modelTitle} `:`Add ${modelTitle}`}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
        <div className="sh_cardBody" style={{maxHeight:'calc(100% - 65px)'}}>
          <div className="sh_steps">
              <div className="step step-lg"> 
              {STEPS.map((step,stepIndex)=>{
                return(
                  <div className={`step-item ${currentStep===stepIndex?'active':''}`} key={stepIndex}> 
                    <a className="step-item-link" href="#!"> {step} </a> 
                  </div>
                )
              })}
              </div>

              <div className="sh_step1" style={{display:(currentStep===0)?'':'none'}}>
                <div className="card mt-3">
                  <div className="card-body">
                  <div className="row">
                    {inputFields &&
                      inputFields.map((field, index) => (
                        <div className="col-12" key={index}>
                          <div className={`form-group ${field.type==='Checkbox'?'form-check form-check-solid':''}`}>
                            {(field.type!=='Checkbox')?<label className="text-gray-900 text-md opacity-75"> {field.label} </label>:''}
                            
                            <FormInputs {...field} changeHandler={(val)=>this.changeHandler(field.name,val)} />
                            {(field.type==='Checkbox')?<label className="form-check-label" htmlFor={field.name}> {field.label} </label>:''}
                            {isSet(validation[field.name],'')!=='' ? <span className="text-danger m-1">{isSet(validation[field.name],'')}</span> : ''}
                          </div>
                        </div>
                      ))}
                  </div>
                  </div>
                </div>
              </div>
              

              <div className="sh_step2" style={{display:(currentStep===1)?'':'none'}}>
                <div className="card mt-3">
                  <Permissions roleId={null} setPermissions={this.setPermissions} isAllOrganization={formData.isAllOrganization} permissions={permissions}/>
                </div> 
              </div>
              <div className="sh_step3" style={{display:(currentStep===2)?'':'none'}}>
                  <ScreenDataConfig  
                    dataConfig={dataConfig} 
                    screen={'ROLE'}
                    isLoadDataSharing={this.state.isLoadDataSharing}
                    fetchDataSharingOption={this.fetchDataSharingOption} 
                    changeHandler={this.dataConfigChangeHandler}/>
              </div>
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div>
          {/* <Buttons type='Single' label="Cancel" className="btn btn-light btn-sm"  clickHandler={()=>this.props.toogleHandler(null,{})} acl={["role-create","role-edit"]} /> */}
          </div>
          <div>
          <Buttons {...buttons} />
          </div>
        </Modal.Footer>
        </div>
      </Modal>
      </>
    )
  }
  setPermissions = (permissions)=>{
    // permissions=[...new Set(permissions)]; //prevent from duplicate using set
    this.setState({permissions:permissions})
  }
  dataConfigChangeHandler = (key, bool, val) => {
    this.setState((prevState) => {
        const currentValues = isSet(prevState.dataConfig[key].value, '').toString().split(',').filter(value => value !== '');
        let updatedValues = [];
        const options = Object.values(isSet(prevState.dataConfig[key].options, [])).flat();
        let filteredOptions = options.filter((v) => v.value !== 'ALL');

        // Convert val to an array if it's not already an array
        const valuesToUpdate = Array.isArray(val) ? val : [val];

        if (bool) {
            // Add the values to the currentValues if bool is true and they're not already in the array
            updatedValues = currentValues.concat(valuesToUpdate.filter(value => !currentValues.includes(value)));
        } else {
            // Remove the values from the currentValues if bool is false
            updatedValues = currentValues.filter((value) => !valuesToUpdate.includes(value));
            if (!valuesToUpdate.includes('ALL') && updatedValues.length === 1 && updatedValues[0] === 'ALL') {
                updatedValues = [];
                filteredOptions.forEach((item) => {
                    if (!valuesToUpdate.includes(item.value)) {
                        updatedValues.push(item.value);
                    }
                });
            }
        }

        // Handle 'ALL' and full selection cases
        if (updatedValues.length === 1 && updatedValues[0] === 'ALL') {
            // Do nothing
        } else if (updatedValues.length === filteredOptions.length) {
            updatedValues = ['ALL'];
        } else if (updatedValues.includes('ALL')) {
            updatedValues.splice(updatedValues.indexOf('ALL'), 1);
        }
        
        return {
            dataConfig: {
                ...prevState.dataConfig,
                [key]: {
                    ...prevState.dataConfig[key],
                    value: (valuesToUpdate.includes('ALL') && bool) ? 'ALL' : updatedValues.join(','),
                },
            },
        };
    });
}
  fetchRolePermission =async (roleId)=>{
    // console.log('roleId',roleId);
    const {dataConfig} = this.state;
    let updateDataConfig = {...dataConfig};
    this.props.Loader(true);
    try{
      // this.props.Loader(true);
      const data =  await RolesService.getPermission(roleId);
      this.props.Loader(false);
      let updatePermission = data.permissions;
      for(const key in data.permissions){
        updatePermission = {
          ...updatePermission,
          [key]:Object.values(data.permissions[key]).flat()
        }
      }
      this.setState({
        isAllOrg:isSet(data.is_all_organization,false),
        formData:{
          ...this.state.formData,
          id:data.id,
          name:data.name,
          isAllOrganization:isSet(data.is_all_organization,false),
          // module:isSet(data.module,''),
          dataSharingId:isSet(data.data_sharing.data_sharing_uuid,false)
        },
        permissions:updatePermission,
        dataConfig:{
          ...updateDataConfig,
          organization:{
            ...updateDataConfig.organization,
            value:data.data_sharing.organization_uuids
          },
          clinic:{
            ...updateDataConfig.clinic,
            value:data.data_sharing.clinic_uuids
          },
          lab:{
            ...updateDataConfig.lab,
            value:data.data_sharing.lab_uuids
          },
          company:{
            ...updateDataConfig.company,
            value:data.data_sharing.company_uuids
          },
        },
        dataSharingData:data.data_sharing_data
      },()=>this.fetchDataSharingOption());
    }catch(e){
      // console.log('excep',e);
      this.props.Loader(false);
    }
  }
  fetchDataSharingOption =async (valKey='organization')=>{
    const {dataConfig,isLoadDataSharing} = this.state;
    let updateDataConfig = {...dataConfig};
    this.setState({
      isLoadDataSharing:{
        ...isLoadDataSharing,
        [valKey]:false
      }
    })
    try{
      // this.props.Loader(true);
      const orgIds = (isSet(dataConfig.organization.value,null)===null)?'':'ALL';
      // console.log('orgIds',orgIds);
      const payload = {           
        organization_uuids:(valKey==='organization')?'ALL':isSet(dataConfig.organization.value,'ALL'),
        clinic_uuids:(valKey==='clinic')?orgIds: isSet(dataConfig.clinic.value,'ALL'),
        lab_uuids: (valKey==='lab')?orgIds:isSet(dataConfig.lab.value,'ALL'),
        company_uuids: (valKey==='company')?orgIds:isSet(dataConfig.company.value,'ALL')
      };
      const dataList =  await RolesService.getDataSharingOptionsList(payload);
      // console.log('dataList',dataList);
      let options = null;
      for(let key in dataList){
        if(!isObject(dataList[key])){
             options = [{label:'All '+key,value:'ALL'}]; 
            dataList[key].forEach((item,itemIndex)=>{
              options.push({
                value:isSet(item.organization_uuid,isSet(item.lab_id,isSet(item.company_uuid,item.id))),
                label:isSet(item.name,item.clinic_name),
                index:itemIndex,  
              });
            });
        }else{
          options = {
            default:[{label:'All '+key,value:'ALL'}]
          };
          for(let orgKey in dataList[key]){
             let optionsArr = [];
            dataList[key][orgKey].forEach((item,itemIndex)=>{
              optionsArr.push({
                value:isSet(item.organization_uuid,isSet(item.lab_id,isSet(item.company_uuid,item.id))),
                label:isSet(item.name,isSet(item.clinic_name,isSet(item.company_name,item.lab_name))),
                index:itemIndex,
              });
            });
            options = {
              ...options,
              [orgKey]:optionsArr
            }
          }
        }
       
      
        if(valKey===key){
            const arr1 = isSet(isSet(updateDataConfig[valKey].value,'').toString().split(','),[]).filter(v => v !== '');
            const arr2 = Object.values(options).flat().map(obj => obj.value);
            const filteredArr = arr1.filter(value => arr2.includes(value));
          updateDataConfig = {
            ...updateDataConfig,
            [key]:{
              ...updateDataConfig[key],
              options:options,
              value:filteredArr.join(',')
            }
          }
        }
        
      }
     
      this.setState({
        dataConfig:{
          ...dataConfig,
          ...updateDataConfig
        },
        isLoadDataSharing:{
          ...isLoadDataSharing,
          [valKey]:true
        }
      });
      // this.props.Loader();
    }catch(e){
      this.setState({
        isLoadDataSharing:{
          ...isLoadDataSharing,
          [valKey]:true
        }
      });
      // this.props.Loader();
    }
  }

     changeHandler = (fieldName,val) => {
      // console.log('fieldName',fieldName,val);
      const {validation,formData}  = this.state;
      const updateValidation = {
        ...validation,
        [fieldName]:''
      };
      this.setState({formData:{
        ...formData,
          [fieldName]: val,
      },validation:updateValidation});
    };
     fetchPermissioinList = async ()=>{
      try{
        const data = await RolesService.getPermissionList();
        const permissionData = [];
        for(const key in data){
          let permissionObj = {
            label:key,
            data:data[key]
          };
          permissionData.push(permissionObj);
        }
        this.setState({permissionData:permissionData});
      }catch(e){
  
      }
  
    }

     formSubmitHandler = async () => {
      const {activeEditId} = this.props;
      const {validation,formData,dataConfig,permissions}  = this.state;
      let hasValidation=false;
      let validationMessage=[];
      let dataSharingObj = {};
      if(isSet(formData.dataSharingId,null)){
        dataSharingObj = {
          data_sharing_id:formData.dataSharingId
        }
      }
      let orgUUIDS      = dataConfig.organization.value;
      const orgUUIDSCnt = isSet(orgUUIDS,'').split(',').length;
      let payload = {
        is_all_organization:formData.isAllOrganization,
        //(orgUUIDSCnt===1 && orgUUIDS!=='ALL')?orgUUIDS:''
        organization_uuid:isSet(formData.organizationUuid,null),
        name:formData.name,
        ...dataSharingObj,
        data_sharing: {
          organization_uuids: dataConfig.organization.value,
          clinic_uuids: dataConfig.clinic.value,
          lab_uuids:dataConfig.lab.value,
          company_uuids:dataConfig.company.value,
      },
      permissions:Object.values(permissions).flat()
        // parent_id:parentRoleId,
      }
      if(isSet(dataConfig.organization.value,null)===null && Boolean(formData.isAllOrganization)!==true){
        this.props.Toast.error('Organization Data sharing config must be required');
        return false;        
      }
      
      if(Boolean(formData.isAllOrganization)===true){
        payload = {
          ...payload,
          data_sharing: {
            organization_uuids:'',
            clinic_uuids: 'ALL',
            lab_uuids:'ALL',
            company_uuids:'ALL',
          }
        }
      }
      //check permission length for any screen permission enabled
      if (payload.permissions.length==0) {
        validationMessage.push('Screen permission config must be required');
      }
      //check  company_uuids permission for any screen permission enabled
      if(payload.data_sharing.company_uuids==""||payload.data_sharing.company_uuids==null){
        let companyPermissionsIds=this.state.permissionData.filter(item=>item.label=='company');
        let isCompanyListPermissionEnabled=payload.permissions.includes(companyPermissionsIds[0].data.list);
        if(isCompanyListPermissionEnabled){
          validationMessage.push('Company Data sharing config must be required');
        }
      }
      //check clinic_uuids permission for any screen permission enabled
      if(payload.data_sharing.clinic_uuids==""||payload.data_sharing.clinic_uuids==null){
        let screenPermissionsIds=this.state.permissionData.filter(item=>item.label=='clinic');
        let isClinicListPermissionEnabled=payload.permissions.includes(screenPermissionsIds[0].data.list);
        if(isClinicListPermissionEnabled){
         validationMessage.push('Clinic Data sharing config must be required');
        }
      }
      //check lab_uuid permission for any screen permission enabled
      if(payload.data_sharing.lab_uuids==""||payload.data_sharing.lab_uuids==null){
        let screenPermissionsIds=this.state.permissionData.filter(item=>item.label=='lab');
        let isLabListPermissionEnabled=payload.permissions.includes(screenPermissionsIds[0].data.list);
        if(isLabListPermissionEnabled){
          validationMessage.push('Lab Data sharing config must be required')
        }
      }
      if (validationMessage.length > 0) {
        this.props.Toast.error(validationMessage);
        return false;
    }
    
      let updateValidation = {...validation};
      if (payload.name ==='') {
        this.setState({
          validation:{
            ...validation,
            name:" Role Name is Required"
          }
        });
        return false;
      }
      let data;
      this.props.Loader(true);
      if(isSet(activeEditId,null)!==null){
        data= await RolesService.updateRolePermission(activeEditId,payload);
      }
      else{
        data = await RolesService.saveRole(payload);
      }
      this.props.Loader(false);
       try {

         if (data.status && data.status === "ERROR") {
           if (isObject(data.data)) {
             for (let key in data.data) {
               updateValidation = {
                 ...updateValidation,
                 [key]: data.data[key].join(',')
               }
             }
           } else {
             this.props.Toast.error(data.data);
           }
           if (Object.keys(updateValidation).length > 0) { //
             this.setState({
               currentStep: 0,
               validation: updateValidation
             });
           }
         } else {
           this.props.toogleHandler(null, {});
           this.props.fetchRolesList();
           let message=`Role ${activeEditId?'Updated':'Added'} Successfully`
           this.props.Toast.success(message);
         }
       } catch (e) {

       }
  }
}

export default withReduxState(WithRoutify(AddRole));