import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { TaxIdFormatter, getBase64String, getLookupByKey, isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import OrganizationService from "../../services/OrganizationService";
import { CREATED, OK } from "../../utils/constant";
import WithRoutify from "../../hoc/WithRoutify";
import { useDispatch } from "react-redux";
import { fetchGlobalOrganization, timeZoneList } from "../../store/OrganizationSlice"
import { useSelector } from "react-redux";
import { activeProfileDetails } from "../../store/SessionSlice";
import DropDownService from "../../services/DropDownService";
import { withTranslation } from "react-i18next";

const AddOrganization = (props) => {
  const { isOpen, addFormHandler, modelTitle, activeEditId,Loader} = props;
  const [validation, setValidation] = useState({});
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const stateList = useSelector((state) => state.session.profileStateList);
  const profileDetails = useSelector(activeProfileDetails);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [isCopyFromOrganizationChecked, setIsCopyFromOrganizationChecked] = useState(false);
  const inputFields = getInputFields(formData);
  //intial render 
  useEffect(() => {
    const fetchOrganisation = async () => {
      if (activeEditId) {
        Loader(true);
        const response = await OrganizationService.getOrganizationById(activeEditId);
        Loader(false);
        setFormData(response);
      }
    };
    fetchTimeZone();
    fetchOrganisation();
  }, []);
  const fetchTimeZone=async()=>{
    const timeZoneRespone=await DropDownService.getTimeZoneDropdownList();
    setTimeZoneOptions(timeZoneRespone);
  }
  //change handler form input
  const changeHandler = async (val, fieldName, {addressParam={}}) => {
    let updatedFormData = { ...formData };
    let updateValidation = { ...validation };
    
    if(fieldName === 'copy_from_organization'){
      
      if (isSet(formData.address, false) == false) {
        setValidation({
          ...updateValidation,
          ["copy_from_organization"]: "Organization Address Required to Copy Address",
        });
        return false;
      }
      
      if(val === 1){
        updatedFormData["remit_address"] = isSet(formData.address, "");
        updateValidation["remit_address"] = "";
        updatedFormData["remit_city"] = isSet(formData.city, "");
        updateValidation["remit_city"] = "";
        updatedFormData["remit_state"] = isSet(formData.state, "");
        updateValidation["remit_state"] = "";
        updatedFormData["remit_zip_plus_4"] = isSet(formData.zip_plus_4, "");
        updateValidation["remit_zip_plus_4"] = "";
        updatedFormData["billing_phone"] = isSet(formData.phone_number, "");
        updateValidation["billing_phone"] = "";
      }else{
        updatedFormData["remit_address"] = "";
        updatedFormData["remit_city"] = "";
        updatedFormData["remit_state"] = "";
        updatedFormData["remit_zip_plus_4"] = "";
        updatedFormData["billing_phone"] = "";
      }
      setIsCopyFromOrganizationChecked(!isCopyFromOrganizationChecked);
    }
    
    if (fieldName == 'business_license' || fieldName == 'organization_logo') {
      if (val) {
        const { base64WithoutPrefix, fileName } = await getBase64String(val);
        let name = `${fieldName}_original_name`
        updatedFormData = { ...updatedFormData, [name]: fileName };
        val = base64WithoutPrefix;
      }
      else {
        updatedFormData[`${fieldName}_original_name`] = '';
      }

    }

    //auto fill address logic
    if (fieldName == "address"&& addressParam) {
      updateValidation = { ...updateValidation, ["address"]: "" };
      if (addressParam.street) {
        updatedFormData = {
          ...updatedFormData,
          street: addressParam.street,
        };
        updateValidation = { ...updateValidation, ["street"]: "" };
      }
      if (addressParam.city) {
        updatedFormData = {
          ...updatedFormData,
          city: addressParam.city,
        };
        updateValidation = { ...updateValidation, ["city"]: "" };
      }
      if (addressParam.state) {
        updatedFormData = {
          ...updatedFormData,
          state: addressParam.state,
        };
        updateValidation = { ...updateValidation, ["state"]: "" };
      }
      if (addressParam.county) {
        updatedFormData = {
          ...updatedFormData,
          county: addressParam.county,
        };
        updateValidation = { ...updateValidation, ["county"]: "" };
      }
      if (addressParam.zipcode) {
        updatedFormData = {
          ...updatedFormData,
          zip_plus_4: addressParam.zipcode,
        };
        updateValidation = { ...updateValidation, ["zip_plus_4"]: "" };
      }
    }
    //auto fill remit address logic
    if (fieldName == "remit_address"&&addressParam) {
      if (addressParam.city) {
        updatedFormData = {
          ...updatedFormData,
          remit_city: addressParam.city,
        };
        updateValidation = { ...updateValidation, ["remit_city"]: "" };
      }
      if (addressParam.state) {
        updatedFormData = {
          ...updatedFormData,
          remit_state: addressParam.state,
        };
        updateValidation = { ...updateValidation, ["remit_state"]: "" };
      }
      if (addressParam.zipcode) {
        updatedFormData = {
          ...updatedFormData,
          remit_zip_plus_4: addressParam.zipcode,
        };
        updateValidation = { ...updateValidation, ["remit_zip_plus_4"]: "" };
      }
    }

    if (fieldName === "federal_id") {
      val=TaxIdFormatter(val);
    }
    //update form data
    updatedFormData = { ...updatedFormData, [fieldName]: val };
    updateValidation = { ...updateValidation, [fieldName]: '' }
    setFormData(updatedFormData);
    setValidation(updateValidation);
  };

  //formSubmit
  const formSubmitHandler = async () => {
    //validation add submit goes here

    let updateValidation = { ...validation };
    props.Loader(true);
    const payload = formData;
    let res;
    if (activeEditId) {
      res = await OrganizationService.updateOrganization(activeEditId, payload);
    }
    else {
      res = await OrganizationService.saveOrganization(payload);
    }
    
    if (res.code == OK || res.code == CREATED) {
      props.fetchOrganizationList();
      addFormHandler();
      let message = `Organization ${activeEditId ? 'Updated' : 'Added'} Successfully`
      props.Toast.success(message);
      //reload props
      dispatch(fetchGlobalOrganization(profileDetails));
    } else if (res.status && res.status == "ERROR") {
      if (isObject(res.data)) {
        for (let key in res.data) {
          updateValidation = {
            ...updateValidation,
            [key]: res.data[key].join(","),
          };
        }
      } else {
        props.Toast.error(res.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
        collapseShowWhenError(updateValidation);
      }
    }
    props.Loader(false);
  };


  const collapseShowWhenError = (validationData)=>{
    inputFields.forEach((sec, secIndex) => {
      sec.data.forEach((itm) => {
        // console.log('itm',isSet(validation[itm.name],null));
        if (validationData[itm.name] !== undefined && validationData[itm.name] !== null) {
          const collapseElement = document.getElementById('collapseOrgCard_' + secIndex);
          if (collapseElement && !collapseElement.classList.contains('show')) {
            document.getElementById('collapseOrgCard_link_' + secIndex).click();
          }
        }
      });
    });
    
  }

  return (
    <>
      <Modal
        show={isOpen}
        onHide={addFormHandler}
        size="xl"
        dialogClassName="hResponsive "
        contentClassName="h-100"
        className=""
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className=" text-lg mb-0 font-weight-bold">{modelTitle}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="card-body tab-content p-0">
              {inputFields &&
                inputFields.map((section, sectionIndex) => {
                  return (
                    <>
                      <div class="card mb-2">
                        <a
                          href={`#collapseOrgCard_${sectionIndex}`}
                          id={`collapseOrgCard_link_${sectionIndex}`}
                          class="d-block sh_accordionheader card-header"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="true"
                          aria-controls="collapseCardExample"
                          style={{
                            position: "sticky",
                            top: "-15px",
                            zIndex: "1",
                          }}
                        >
                          <h6 class="m-0 text-md">
                            {section.title}
                          </h6>
                        </a>
                        <div
                          class="collapse show"
                          id={`collapseOrgCard_${sectionIndex}`}
                          data-bs-parent="#modal111"
                        >
                          <div class="card-body">
                            <div class="row">
                              {section.data.map((data, dataInd) => {
                                return (
                                  <>
                                    <div class={isSet(data.name) && data.name === 'copy_from_organization' ? "col-12" : "col-lg-4 col-6"}>
                                      <div class="form-group">
                                      {
                                        isSet(data.name) && data.name === 'copy_from_organization' ? 
                                        
                                        <label className="m-0 text-md">
                                          <FormInputs
                                            type="Checkbox"
                                            name='copy_from_organization'
                                            value={isCopyFromOrganizationChecked}
                                            changeHandler={(value, e) => changeHandler(value, 'copy_from_organization', e)}
                                            className="mr-1"
                                          />
                                          Copy same Address from Organization
                                          <div>{isSet(validation[data.name], "") !==
                                              "" ? (
                                              <span className="text-danger m-1">
                                                {isSet(validation[data.name], "")}
                                              </span>
                                            ) : (
                                              ""
                                            )}</div>
                                        </label>
                                        
                                        
                                      :
                                        <>
                                          <label class="text-sm text-truncate mb-0 opacity-75">
                                            {data.label}
                                          </label>
                                          <div
                                            key={dataInd}
                                          >
                                            <FormInputs
                                              {...data}
                                              className={
                                                "form-control form-control-solid"
                                              }
                                              changeHandler={(value, e) =>
                                                changeHandler(value, data.name, e)
                                              }
                                            />
                                            {isSet(validation[data.name], "") !==
                                              "" ? (
                                              <span className="text-danger m-1">
                                                {isSet(validation[data.name], "")}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </>  
                                      }
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            {/* <Timeline/> */}
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="primary"
              className=""
              onClick={() => formSubmitHandler()}
            >
              {activeEditId ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  function getInputFields() {
    const {t} = props;
    const inputFields = [
      {
        title: `${t("Organization")} - ${t('AddressAndContact')}`,
        data: [
          {
            type: "TextInput",
            label: t("Name"),
            name: "name",
            placeholder: t("Name"),
            className: "form-control form-control-solid",
            value: formData["name"] || "",
            isFirstLetterCaps: true,
            required: true,
            isFirstLetterCaps: true,
          },
          {
            type: "TextInput",
            label: t("Code"),
            name: "code",
            placeholder: "Code",
            value: formData["code"] || "",
            max: 5,
            allUpperCase: true,
            required: true,
          },
          {
            type: "Address",
            label: t("Address"),
            name: "address",
            placeholder: t("Address"),
            value: formData["address"] || "",
            isFirstLetterCaps: true,
            required: true,
          },
          {
            type: "TextInput",
            label: t("City"),
            name: "city",
            placeholder: t("City"),
            value: formData["city"] || "",
            isFirstLetterCaps: true,
            required: true,
          },
          {
            type: "SelectList",
            label: t("State"),
            options: stateList,
            name: "state",
            placeholder: t("State"),
            value: formData["state"] || "",
            required: true,
          },
          {
            type: "ZipCode",
            label: t("Zip4"),
            name: "zip_plus_4",
            placeholder:  t("Zip4"),
            value: formData["zip_plus_4"] || "",
            required: true,
            max: 10
          },
          {
            type: "TextInput",
            label: t("County"),
            name: "county",
            placeholder: t("County"),
            value: formData["county"] || "",
            required: true,
            isFirstLetterCaps: true,
          },
          {
            type: "Phone",
            label: t("Phone"),
            name: "phone_number",
            className: "form-control form-control-solid",
            placeholder: t("Phone"),
            value: formData["phone_number"] || "",
            required: true,
          },
          {
            type: "Phone",
            label: t("Fax"),
            name: "fax",
            placeholder: t("Fax"),
            value: formData["fax"] || "",
          },
          {
            type: "TextInput",
            label: t("Email"),
            name: "email",
            placeholder: t("Email"),
            value: formData["email"] || "",
            required: true,
          },
          {
            type:"SelectSearchList",
            options:timeZoneOptions,
            label:t("TimeZone"),
            name:'time_zone',
            placeholder: t("TimeZone"),
            value: formData["time_zone"] || "",
          }
        ],
      },
      {
        title: `${t("Organization")} - ${t('IDsCertificates')}`,
        data: [
          {
            type: "Number",
            label: t("FederalTaxID"),
            name: "federal_id",
            placeholder: t("FederalTaxID"),
            value: formData["federal_id"] || "",
            required: true,
            max: 10,
          },
          {
            type: "Number",
            label: t("GroupNPI"),
            name: "group_npi",
            placeholder: t("GroupNPI"),
            value: formData["group_npi"] || "",
            required: true,
            max: 10
          },
          {
            type: "TextInput",
            label: t("CLIA"),
            name: "clia",
            placeholder: t("CLIA"),
            value: formData["clia"] || "",
            max: 10
          },
          {
            type: "TextInput",
            label: t("MedicareGroupID"),
            name: "medicare_group_id",
            placeholder: t("MedicareGroupID"),
            value: formData["medicare_group_id"] || "",
            max: 6
          },
          {
            type: "Number",
            label: t("MedicaidGroupID"),
            name: "medicaid_group_id",
            placeholder: t("MedicaidGroupID"),
            value: formData["medicaid_group_id"] || "",
          },
          {
            type: "Number",
            label: t("DOLID"),
            name: "dol_id",
            placeholder: `${t("Organization")} ${t("DOLID")}`,
            value: formData["dol_id"] || "",
            max: 9,
          },
          {
            type: "TextInput",
            label: t("PayerOverride"),
            name: "payer_override",
            placeholder: t("PayerOverride"),
            value: formData["payer_override"] || "",
          },
          {
            type: "Number",
            label: t("VaccineRegID"),
            name: "vaccine_reg_id",
            placeholder: t("VaccineRegID"),
            value: formData["vaccine_reg_id"] || "",
          },
        ],
      },
      {
        title: `${t("Organization")} - ${t('Directors')}`,
        data: [
          {
            type: "TextInput",
            label: t("MedicalDirectorName"),
            name: "medical_director_name",
            placeholder: t("MedicalDirectorName"),
            value: formData["medical_director_name"] || "",
            isFirstLetterCaps: true,
          },
          {
            type: "TextInput",
            label: t("LabDirectorName"),
            name: "lab_director_name",
            placeholder: t("LabDirectorName"),
            value: formData["lab_director_name"] || "",
            isFirstLetterCaps: true,
          },
        ],
      },
      {
        title: `Organization - Billing Remit Address`,
        data: [
          {
            name: "copy_from_organization",
          },
          {
            type: "Address",
            label: t("RemitAddress"),
            name: "remit_address",
            placeholder: t("RemitAddress"),
            value: formData["remit_address"] || "",
            required: true,
            isFirstLetterCaps: true,
          },
          {
            type: "TextInput",
            label: t("RemitCity"),
            name: "remit_city",
            placeholder: t("RemitCity"),
            value: formData["remit_city"] || "",
            required: true,
            isFirstLetterCaps: true,
          },
          {
            type: "SelectList",
            label: t("RemitState"),
            options: stateList,
            name: "remit_state",
            placeholder: t("RemitState"),
            value: formData["remit_state"] || "",
            required: true,
          },
          {
            type: "ZipCode",
            label: t("RemitZip4"),
            name: "remit_zip_plus_4",
            placeholder: t("RemitZip4"),
            value: formData["remit_zip_plus_4"] || "",
            required: true,
            max: 10
          },
          {
            type: "Phone",
            label: t("BillingPhone"),
            name: "billing_phone",
            placeholder: t("BillingPhone"),
            value: formData["billing_phone"] || "",
            required: true,
          },
          {
            type: "Phone",
            label: t("BillingFax"),
            name: "billing_fax",
            placeholder: t("BillingFax"),
            value: formData["billing_fax"] || "",
          },
          {
            type: "TextInput",
            label: t("BillingEmails"),
            name: "billing_email",
            placeholder: t("BillingEmails"),
            value: formData["billing_email"] || "",
          },
          {
            type: "TextInput",
            label: t("RCMCompany"),
            name: "rcm_company",
            placeholder: t("RCMCompany"),
            value: formData["rcm_company"] || "",
            isFirstLetterCaps: true,
          },
        ],
      },
      {
        title: `${t("Organization")} - ${t('Images')}`,
        data: [
          {
            type: "ImageUpload",
            label: t("OrganizationLogo"),
            name: "organization_logo",
            placeholder: t("OrganizationAttachLogo"),
            value: {
              original_name: formData['organization_logo_original_name'],
              base64: formData['organization_logo']
            },
            accept: "image/png, image/gif, image/jpeg",
            required: true
          },
          {
            type: "ImageUpload",
            label: t("BusinessLicense"),
            name: "business_license",
            placeholder: "Organization Business License",
            value: {
              original_name: formData['business_license_original_name'],
              base64: formData['business_license']
            },
            accept: "image/png, image/gif, image/jpeg",
          },
        ],
      },
    ];
    return inputFields;
  }
};
export default WithRoutify(withTranslation()(AddOrganization));