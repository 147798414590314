import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import WithRouter from "../../hoc/WithRouter";
import WithRoutify from "../../hoc/WithRoutify";
import Employeeervice from "../../services/EmployeeService";
import { isObject, isSet, validateEmail } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";

const SendEmailNotification = (props) => {
    const { modleToogleHandler, Toast, module,sendNoticationHandler } = props;
    const [validation, setValidation] = useState({});
    const [formData, setFormData] = useState({
    });
    useEffect(() => {
    }, []);
    const inputFields = [
        {
            type: "",
            label: "Email",
            placeholder: "Email",
            className: "form-control form-control-solid",
            name: "email",
            value: formData.email,
            required: true
        },
    ];
    const changeHandler = (val, fieldName) => {
        setFormData({
            ...formData,
            [fieldName]: val,
        });
        setValidation({ ...validation, [fieldName]: '' });
    };
    const formSubmitHandler = async () => {
        if (!validateEmail(formData?.email)) {
            setValidation({email:'Enter valid email address'});
            return ;
        }
        Toast.success("Email Sent Successfully");
        sendNoticationHandler(formData?.email);
        modleToogleHandler();
    }
    return (
        <>
            <Modal
                show={true}
                onHide={modleToogleHandler}
                dialogClassName=''
                keyboard={false}
                contentClassName='h-100'
                backdrop="static"
                size="md">
                <div className="sh_modalBox1">
                    <Modal.Header closeButton >
                        <Modal.Title>
                            <h6 className="text-lg mb-0 font-weight-bold">
                                {'Send Report to Email'}
                            </h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                <p>
                                    <span style={{ color: 'red' }}>The report is too large to download directly. It will be sent to your email address</span>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            {inputFields &&
                                inputFields.map((field, index) => (
                                    <div className="col-12" key={index}>
                                        <div className="form-group">
                                            <label className="mb-0 text-md opacity-75"> {field.label} </label>
                                            <FormInputs  {...field} changeHandler={(value, e) => changeHandler(value, field.name)} />
                                            {isSet(validation[field.name], '') !== '' ? <span className="text-danger m-1">{isSet(validation[field.name], '')}</span> : ''}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="primary" className="" onClick={() => formSubmitHandler()}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
};
export default WithRouter(WithRoutify(SendEmailNotification));