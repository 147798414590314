const PERMISSIONS = {
  ACTIVITIES: {
    CREATE: 'activities-create',
    DELETE: 'activities-delete',
    EDIT: 'activities-edit',
    LIST: 'activities-list'
  },
  APPOINTMENT: {
    CREATE: 'appointment-create',
    DELETE: 'appointment-delete',
    EDIT: 'appointment-edit',
    LIST: 'appointment-list'
  },
  BASIC_PHYSICAL: {
    CREATE: 'basic-physical-create',
    DELETE: 'basic-physical-delete',
    EDIT: 'basic-physical-edit',
    LIST: 'basic-physical-list'
  },
  CLINIC: {
    CREATE: 'clinic-create',
    DELETE: 'clinic-delete',
    EDIT: 'clinic-edit',
    LIST: 'clinic-list'
  },
  CLINIC_DEPARTMENT: {
    CREATE: 'clinic-department-create',
    DELETE: 'clinic-department-delete',
    EDIT: 'clinic-department-edit',
    LIST: 'clinic-department-list'
  },
  COMPANY: {
    CREATE: 'company-create',
    DELETE: 'company-delete',
    EDIT: 'company-edit',
    LIST: 'company-list'
  },
  COMPANY_DIVISION: {
    CREATE: 'company-division-create',
    DELETE: 'company-division-delete',
    EDIT: 'company-division-edit',
    LIST: 'company-division-list'
  },
  CONSENT: {
    CREATE: 'consent-create',
    DELETE: 'consent-delete',
    EDIT: 'consent-edit',
    LIST: 'consent-list'
  },
  EMPLOYEE: {
    CREATE: 'employee-create',
    DELETE: 'employee-delete',
    EDIT: 'employee-edit',
    LIST: 'employee-list'
  },
  INSURANCE: {
    CREATE: 'insurance-create',
    DELETE: 'insurance-delete',
    EDIT: 'insurance-edit',
    LIST: 'insurance-list'
  },
  LAB: {
    CREATE: 'lab-create',
    DELETE: 'lab-delete',
    EDIT: 'lab-edit',
    LIST: 'lab-list'
  },
  LOOKUP: {
    CREATE: 'lookup-create',
    DELETE: 'lookup-delete',
    EDIT: 'lookup-edit',
    LIST: 'lookup-list'
  },
  ORGANIZATION: {
    CREATE: 'organization-create',
    DELETE: 'organization-delete',
    EDIT: 'organization-edit',
    LIST: 'organization-list'
  },
  PROVIDER: {
    CREATE: 'provider-create',
    DELETE: 'provider-delete',
    EDIT: 'provider-edit',
    LIST: 'provider-list'
  },
  RESULT: {
    CREATE: 'result-create',
    DELETE: 'result-delete',
    EDIT: 'result-edit',
    LIST: 'result-list'
  },
  ROLE: {
    CREATE: 'role-create',
    DELETE: 'role-delete',
    EDIT: 'role-edit',
    LIST: 'role-list'
  },
  USER: {
    CREATE: 'user-create',
    DELETE: 'user-delete',
    EDIT: 'user-edit',
    LIST: 'user-list'
  },
  VACCINE_ADMINISTRATION: {
    CREATE: 'vaccine-administration-create',
    DELETE: 'vaccine-administration-delete',
    EDIT: 'vaccine-administration-edit',
    LIST: 'vaccine-administration-list'
  },
  VISITS: {
    CREATE: 'visits-create',
    DELETE: 'visits-delete',
    EDIT: 'visits-edit',
    LIST: 'visits-list'
  },
  CPT: {
    CREATE: 'cpt-create',
    DELETE: 'cpt-delete',
    EDIT: 'cpt-edit',
    LIST: 'cpt-list'
  },
  CSV_IMPORT: {
    CREATE: 'csv-import-create',
    DELETE: 'csv-import-delete',
    EDIT: 'csv-import-edit',
    LIST: 'csv-import-list'
  }
};

export default PERMISSIONS;
