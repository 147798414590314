import React, { useEffect, useState } from "react";
import ConfigurationService from "../../services/ConfigurationService";
import { Button, Modal } from "react-bootstrap";
import SectionLoader from "../../components/UI/SectionLoader";
import FileManagerService from "../../services/FileManagerService";
import FormInputs from "../../components/UI/FormInputs";
import { isObject, isSet } from "../../utils/commonUtils";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import SftpSecurityModal from "./SftpSecrityModal";
import Icons from "../../components/UI/Icons";

const DirectoryView = (props) => {
    const [currentPath, setCurrentPath] = useState(null);
    const { id, addFormHandler } = props;
    const [file, setFile] = useState({});
    const [showPasswordModal, setShowPasswordModal] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [configData,setConfigData]=useState({});
    const [uploadModalOpen,setUploadModalOpen]=useState(false);
    const [uploadFilePath,setUploadFilePath]=useState(null);
    const [tableRecords, setTableRecords] = useState({ tableHeaders: getTableHeaders(), tableRows: { data: [] } });
    useEffect(() => {
        fetchConfigData();
    }, []);
    useEffect(() => {
        fetchDirectory(currentPath);
    }, [currentPath]);
    const fetchConfigData=async()=>{
        const response=await ConfigurationService.getSftpConfigById(id);
        setCurrentPath(response.home_path);
        setConfigData(response);        
    }

    const fetchDirectory = async (path) => {
        console.log('path', path);
        if (!path) {
            return false;
        }
        const payload = { id: id, path: path };
        setIsLoading(true);
        try {
            const response = await ConfigurationService.getDirectoriesById(payload);
            const data = response?.data || {};
            const tableData = data?.directories.map((item) => ({
                rowId: item.id,
                data: {
                    name: item.name,
                    type: item.type,
                    // path: item.path,
                    path: [
                        {
                            className: "text-sm",
                            acl: "any",
                            label: item.path,
                            clickHandler: () => item.type == 'directory' ? handleDirectoryClick(item.path) : downloadFile(item.path)
                        },
                    ],
                    action: [
                        {
                            className:
                                "btn btn-icon btn-transparent-dark",
                            iconType: "LoginIcon",
                            acl: item.type == 'directory' ? 'any' : '',
                            title: "Login",
                            clickHandler: () => {handleDirectoryClick(item.path)},
                        }, 
                        {
                            className:"btn btn-icon btn-transparent-dark",
                            iconType: "AddPlus",
                            acl: item.type == 'directory' ? 'any' : '',
                            title: "Upload File",
                            clickHandler: () => {setUploadModalOpen(true);setUploadFilePath(item.path)},
                        },
                        {
                            className:"fa fa-download pr-1",
                            acl: item.type == 'file' ? 'any' : '',
                            title: "Download File",
                            clickHandler: () => {downloadFile(item.path)},
                        },  
                    ]
                }
            }));
            setTableRecords({ tableHeaders: getTableHeaders(), tableRows: { data: tableData } });
            setBreadcrumb(data?.breadcrumbs || []);
            setCurrentPath(path);
        } catch (error) {
            console.error("Failed to fetch directory:", error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleDirectoryClick = (path) => {
        if (path !== currentPath) {
            setCurrentPath(path);
        }
    };
    const uploadFile = async () => {
        // setIsLoading(true)
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('id', id);
            formData.append('path', uploadFilePath);
            setUploadModalOpen(false);
            const response = await ConfigurationService.uploadSftpFile(formData);
            let updateValidation = {};
            if (response.status && response.status == "ERROR") {
                if (isObject(response.data)) {
                    for (let key in response.data) {
                        updateValidation = {
                            ...updateValidation,
                            [key]: response.data[key].join(","),
                        };
                    }
                } else {
                    props.Toast.error(response.data);
                }
                if (Object.keys(updateValidation).length > 0) {
                    //   setValidation(updateValidation);
                    return;
                }
            }
            setFile({});
            setIsLoading(false)
            setUploadModalOpen(false);
        } catch (error) {
            setIsLoading(false)
            console.error("Error submitting document:", error);
        }
    }
    const downloadFile = async (path) => {
        const payload = { id: id, path: path };
        setIsLoading(true);
        const response = await ConfigurationService.downloadSftpFile(payload);
        setIsLoading(false);
        FileManagerService.downloadBlob(response.data);
    }
    return (
        <>
            {
                showPasswordModal ?
                    <SftpSecurityModal
                        onClose={addFormHandler}
                        setShowPasswordModal={setShowPasswordModal}

                    />
                    :
                    <>
                    {
                        uploadModalOpen?
                        <UploadFile
                        setUploadModalOpen={setUploadModalOpen}
                        uploadFile={uploadFile}
                        setFile={setFile}
                        file={file}
                        />
                        :
                        ''
                    }
                    <Modal
                        show={true}
                        onHide={addFormHandler}
                        size="xl"
                        dialogClassName="hResponsive"
                        contentClassName="h-100"
                        backdrop="static"
                        keyboard={false}
                    >
                        <div className="sh_modalBox">
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <h6 className=" text-lg mb-0 font-weight-bold">{`${configData.name}`}</h6>
                                </Modal.Title>
                            </Modal.Header>
                                <Breadcrumb style={{ backgroundColor: '#f8f9fa'}}>
                                    {breadcrumb.map((crumb, index) => (
                                        <React.Fragment key={index}>
                                            {
                                                index==0?
                                                <Breadcrumb.Item
                                                onClick={() => handleDirectoryClick(crumb.path)}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#007bff',
                                                    fontWeight: 'bold',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                 <Icons type={"Company"} /> 
                                            </Breadcrumb.Item>
                                            :
                                            <Breadcrumb.Item
                                                onClick={() => handleDirectoryClick(crumb.path)}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#000',
                                                    fontWeight: 'bold',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                {crumb.label}
                                            </Breadcrumb.Item>
                                            }
                                            
                                        </React.Fragment>
                                    ))}
                                </Breadcrumb>
                            <Modal.Body className="pt-0">
                                    <div className="d-flex justify-content-between">
                                        <Buttons
                                        acl={"any"}
                                        clickHandler={()=>handleDirectoryClick(configData?.orders_path)}
                                            label={"Go to Order path"} />
                                        <Buttons
                                        acl={"any"}
                                        clickHandler={()=>handleDirectoryClick(configData?.result_path)}
                                            label={"Go to Result path"} />
                                    </div>
                                <div className="dir-view">
                                <SectionLoader isLoading={isLoading} />
                                <TableGrid {...tableRecords} />
                                </div>
  
                            </Modal.Body>
                            <Modal.Footer className="d-flex justify-content-center">
                                <Buttons
                                    type='Single'
                                    className="btn btn-secondary"
                                    clickHandler={() => addFormHandler()}
                                    label='Close'
                                    acl={'any'}
                                />
                            </Modal.Footer>
                        </div>

                    </Modal>
                    </>
            }
        </>
    );

};
const getTableHeaders = () => [
    { label: 'Name', key: 'name', type: '' },
    { label: 'Type', key: 'type', type: '' },
    { label: 'Path', key: 'path', type: 'Actions' },
    { label: 'Action', key: 'action', type: 'Actions' },
];
const UploadFile = (props) => {
    const {setUploadModalOpen,setFile,uploadFile,file}=props;
    const [validation,setValidation]=useState([]);
    return (
        <>
            <Modal
                show={true}
                keyboard={false}
                onHide={()=>setUploadModalOpen(false)}
                contentClassName='h-100'
                backdrop="static"
                size="md"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h6 className="text-lg mb-0 font-weight-bold">
                            Upload File
                        </h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label className="text-sm text-truncate mb-0 opacity-150">{"Document"}</label>
                    <FormInputs
                        type='FileUpload'
                        title="Field Document"
                        name='file'
                        className=''
                        value={isSet(file, '')}
                        changeHandler={(val) => {
                            setFile(val);
                            setValidation({});
                        }}

                    />
                    {isSet(validation['file'], '') !== '' ? <span className="text-danger m-1">
                        {isSet(validation['file'], '')}</span> : ''}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Buttons
                        type='Single'
                        className="btn btn-primary"
                        clickHandler={() => uploadFile()}
                        label='Upload'
                        acl={'any'}
                    />
                </Modal.Footer>
            </Modal>
            
        </>
    );
}
export default DirectoryView;


// {directories.length > 0 ? (
//     <ul className="list-group">
//         {directories.map((itm, ind) => (
//             <li
//                 key={ind}
//                 className="list-group-item list-group-item-action"
//             >
//                 {itm.type === 'directory' ? (
//                     <span
//                         onClick={() => handleDirectoryClick(itm.path)}
//                         style={{ cursor: 'pointer', textDecoration: 'underline' }}
//                     >
//                         {itm.name}
//                     </span>
//                 ) : (
//                     <span>
//                         {itm.name}{" "}
//                         <a
//                             href="#"
//                             onClick={(e) => {
//                                 e.preventDefault();
//                                 downloadFile(itm.path);
//                             }}
//                             style={{ textDecoration: 'underline', cursor: 'pointer' }}
//                         >
//                             Download
//                         </a>
//                     </span>
//                 )}
//             </li>
//         ))}
//     </ul>
// ) : (
//     !isLoading && <p>No directories found.</p>
// )}
