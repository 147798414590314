// src/services/userService.js
import http from './http';
import { wOrgIdMiddleware } from '../utils/commonUtils';
import { OK } from '../utils/constant';

const LabService = {
  getLabsList: wOrgIdMiddleware(async (url, query = {}) => {
    const queryString = new URLSearchParams(query).toString();
    //?${queryString}
    try {

      const response = await http.GET(`${url}labs?${queryString}`);
      
      if (response.code === 200 && response.data) {
        return response.data;
      }
      
      return null;
    } catch (error) {
      // console.error('Error fetching user:', error);
      throw error;
    }
  }, true),
  //Save user
  saveLabs: wOrgIdMiddleware(async (url = '', data) => {
    try {
      const response = await http.POST(`${url}labs`, data)
      // console.log('response',response);
      
      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return response;
      }
      
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),

  //Save user
  updateLabs: wOrgIdMiddleware(async (url, labsId, data) => {
    try {
      const response = await http.PUT(`${url}labs/${labsId}`, data);
      if (response.code === 200 && response.data) {
        return response.data;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }
  }),
  //Delete clinic
  deleteLab: wOrgIdMiddleware(async (url, labId) => {
    try {
      const response = await http.DELETE(`${url}labs/${labId}`);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }
  }),
  //get company
  getLabId: wOrgIdMiddleware(async (url, labId) => {
    try {
      // const orgId       = OrganizationService.getOrgId();
      const response = await http.GET(`${url}labs/${labId}`);
      
      if (response.code === OK) {
        return response.data;
      }
      
    } catch (error) {
      // console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }),

};

export default LabService;