import React, { Component } from "react";
import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";
import AddBasicPhysical from "./AddBasicPhysical";
import WithRoutify from "../../hoc/WithRoutify";
import BasicPhysicalService from "../../services/PhysicalService";
import LookupService from "../../services/LookupService";
import { OK, REMIND_IN } from "../../utils/constant";
import {
  isSet,
  convertToCurrency,
  isObject,
  toQueryString,
} from "../../utils/commonUtils";
// import { useNavigate } from 'react-router-dom';
import WithRouter from "../../hoc/WithRouter";
import TableSkeleton from "../../hoc/TableSkeleton";
import OrganizationService from "../../services/OrganizationService";
import withReduxState from "../../hoc/wReduxState";
// import ActivitiesService from '../../services/ActivitiesService';

class BasicPhysicalsList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      { label: "Physical", key: "physical_activity", inputType: "TextInput" ,sortName:'physical_activity' },
      { label: "Price", key: "price", inputType: "TextInput",sortName:'price'},
      {
        label: "Remind In",
        key: "remind_in",
        inputType: "SelectList",
        options: [],
      },
      { label: "Action", key: "action", type: "Actions" },
      // Add more columns as needed
    ];
    this.state = {
      filters: {},
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "physical_activity",
          sort_order: "asc",
          search: {},
        },
      },
      activeEditId: null,
      // Your state variables go here
      isModelOpen: false,
      options: {},
    };
  }

  componentDidMount() {
    this.fetchBasicPhysicalsList();
    this.fetchOptions();

    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  render() {
    const { tableRecords, filters, isModelOpen, options } = this.state;

    return (
      <>
        {isModelOpen ? (
          <AddBasicPhysical
            modelTitle={`${this.state.activeEditId?'View and Update':'Add'} Physical`}
            isOpen={this.state.isModelOpen}
            fetchBasicPhysicalsList={this.fetchBasicPhysicalsList}
            addFormHandler={this.addFormHandler}
            options={options}
            activeEditId={this.state.activeEditId}
          />
        ) : (
          <></>
        )}
        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"basic-physical-create"}
          content={"Basic Physical"}
        >
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                <h6 class="text-lg f-500 mb-0"> Basic Physical List </h6>

                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div className="col-12 d-flex">
                      <div class="input-group input-group-joined input-group-solid">
                        <FormInputs
                          placeholder="Physical"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>
                      <div class="btn-group pl-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="addAction">
                    <div className="btn-group">
                      <Buttons
                        clickHandler={()=>{this.addFormHandler();this.setState({activeEditId:''})}}
                        className="btn btn-primary"
                        acl={"basic-physical-create"}
                        label={"Add"}
                      />
                      <button
                        className="btn btn-outline-secondary dropdown no-arrow"
                        data-bs-toggle="dropdown"
                      >
                        <span className="dropdown-toggle">
                          <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </span>
                        <div
                          className="dropdown-menu dropdown-menu-right shadow"
                          aria-labelledby="userDropdown"
                        >
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Group Edit
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Grid Edit
                          </a>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0 ">
              <TableGrid
                hasPagination={true}
                fetchTableRecords={(filters) =>
                  this.fetchBasicPhysicalsList(filters)
                }
                {...tableRecords}
              />
            </div>
          </div>
        </TableSkeleton>
      </>
    );
  }
  submitFiltersHandler = () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      page:1,
      search: filters,
    };
    this.fetchBasicPhysicalsList(queryfilters);
  };
  clearFiltersHandler = () => {
    this.setState(
      {
        filters: {},
      },
      this.submitFiltersHandler
    );
  };
  fetchBasicPhysicalsList = async (query = {}) => {
    let orgId=  isSet(this.props.profile.role) && this.props.profile.role === 'global_admin' && this.props.profile.is_multiple_organization ? null : OrganizationService.getOrgId();
    const { tableRecords } = this.state;
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });

    this.props.Loader(true);
    await BasicPhysicalService.getBasicPhysical(orgId,queryString)

      .then((res) => {
        let responseData = res.data;
        let tableData = [];
        // console.log('responseData',responseData);
        responseData.data.map((physical, physicalIndex) => {
          tableData = [
            ...tableData,
            {
              physicalIndex: physicalIndex,
              isHeading: false,
              rowId: physical.physical_id,
              data: {
                isActive: false,
                physical_activity: physical.physical_activity,
                price: convertToCurrency(physical.price),
                remind_in: physical.remind_in,
                active: "Yes",
                action: [
                  {
                    className:
                    "btn btn-icon btn-transparent-dark",
                    acl:'any',
                    iconType: "Activities",
                    acl: "any",
                    title: "Activities",
                    href: `assign-activities/${physical.physical_id}/${encodeURIComponent(physical.physical_activity)}`,
                  },
                  {
                    className:
                      "btn btn-icon btn-transparent-dark",
                    iconType: "Search",
                    title: "View",
                    type: "GridEdit",
                    acl: "basic-physical-edit",
                    clickHandler: (rowId, data) =>
                      this.editHandler(rowId, data),
                  },
                  {
                    className:
                      "btn btn-icon btn-transparent-dark",
                    iconType: "Remove",
                    acl: "basic-physical-delete",
                    title: "Delete",
                    isDelete: true,
                    clickHandler: () =>
                      this.deleteHandler(physical.physical_id),
                  },
                ],
              },
            },
          ];
        });
        // console.log("TABle DATA",tableData);
        //update state
        this.setState((prevState) => ({
          tableRecords: {
            ...prevState.tableRecords,
            tableHeaders: prevState.tableRecords.tableHeaders,
            tableRows: {
              status: 200,
              type: "default",
              data: tableData,
            },
            pagination: {
              ...prevState.tableRecords.pagination,
              ...query,
              totalRecords: res.data.pagination.totalCount,
            },
          },
        }));
      })
      .catch((err) => {
        // console.log("err===>", err);
        this.setState({
          tableRecords: {
            ...tableRecords,
            tableRows: {
              ...tableRecords.tableRows,
              status: 200,
            },
          },
        });
      })
      .finally(() => {
        this.props.Loader(false);
      });
  };
  deleteHandler = async (id) => {
    let orgId=  isSet(this.props.profile.role) && this.props.profile.role === 'global_admin' && this.props.profile.is_multiple_organization ? null : OrganizationService.getOrgId();
    BasicPhysicalService.deleteBasicPhysical(orgId,id)
      .then((res) => {
        // console.log("res==>", res);
        //toast and show error logic
        this.fetchBasicPhysicalsList();
        this.props.Toast.success("Physical Deleted Successfully");
      })
      .catch((err) => {
        //
      })
      .finally(() => {
        //
      });
  };
  editHandler = (rowId, data) => {
    this.setState({
      activeEditId: rowId,
    },this.addFormHandler());
  };
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  fetchOptions = async () => {
    //array of key for fetch
    let keys = [REMIND_IN];
    let keysOptions = {};
    //fetch key value and store in keysOptions
    for (const key of keys) {
      let valueListResponse = await LookupService.getLookupByKey(key);
      let options = [{ label: "-Select-", value: "" }];
      valueListResponse.forEach((val) => {
        options.push({ label: val.value, value: val.value });
      });
      keysOptions = { ...keysOptions, [key]: options };
    }
    this.setState({ options: keysOptions });
  };
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}

export default WithRouter(withReduxState(WithRoutify(BasicPhysicalsList)));
