import React from 'react';

const ConsentView = (props) => {
    // console.log("props", props);
    const { type,clickHandler } = props;
    return (
        <>
            <div className="actionTable">
                {
                    type === 'ConsentEdit' ?
                        <a className="font-weight-bold" onClick={clickHandler} title="Activity Consent Completed" style={{ cursor: 'pointer', color: '#1d61f3' }}>
                            <svg version="1.1" id="Cosentcomplete" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64" xmlSpace="preserve">
                                <path fill="#008A00" d="M4.385,31.3h46.588c1.949,0,3.529-1.579,3.529-3.527c0-1.949-1.58-3.528-3.529-3.528H4.385 c-1.949,0-3.528,1.579-3.528,3.528C0.857,29.721,2.436,31.3,4.385,31.3z" />
                                <path fill="#008A00" d="M4.385,12.699h46.588c1.949,0,3.529-1.579,3.529-3.528c0-1.948-1.58-3.527-3.529-3.527H4.385 c-1.949,0-3.528,1.579-3.528,3.527C0.857,11.12,2.436,12.699,4.385,12.699z" />
                                <path fill="#008A00" d="M31.418,43.658H4.385c-1.949,0-3.528,1.579-3.528,3.528c0,1.948,1.579,3.528,3.528,3.528h27.034 c1.949,0,3.529-1.58,3.529-3.528C34.947,45.237,33.367,43.658,31.418,43.658z" />
                                <path opacity="0.8" fill="#008A00" d="M54.086,38.139l4.994,4.974L47.01,55.181l-4.973-4.973L54.086,38.139z M40.516,56.068c-0.024,0.178,0.036,0.355,0.163,0.48c0.124,0.129,0.303,0.188,0.479,0.163l4.193-0.479l-4.361-4.371L40.516,56.068zM60.803,38.397l-1.997-1.996c-0.818-0.822-2.148-0.826-2.971-0.007c-0.003,0.002-0.005,0.005-0.008,0.007l-0.379,0.379      l4.989,4.975l0.379-0.379c0.823-0.817,0.825-2.149,0.007-2.972c-0.002-0.002-0.005-0.005-0.007-0.007H60.803z" />
                            </svg>
                            
                        </a>
                        :
                        <a className="font-weight-bold" onClick={clickHandler} title="Activity Consent Incomplete" style={{cursor: "pointer"}} > 
                        <svg xmlns="http://www.w3.org/2000/svg" width={'14.874'} height={'15.7'} viewBox="0 0 14.874 15.7">
                            <g id="checklist" transform="translate(-3 -3)">
                            <path id="Path_1" data-name="Path 1" d="M18.024,4H12.24a1.24,1.24,0,0,0,0,2.479h5.784a1.24,1.24,0,0,0,0-2.479Z" transform="translate(-1.389 -0.174)" fill="#FA0202"/>
                            <path id="Path_2" data-name="Path 2" d="M18.024,11H12.24a1.24,1.24,0,1,0,0,2.479h5.784a1.24,1.24,0,1,0,0-2.479Z" transform="translate(-1.389 -1.389)" fill="#FA0202"/>
                            <path id="Path_3" data-name="Path 3" d="M18.024,18H12.24a1.24,1.24,0,0,0,0,2.479h5.784a1.24,1.24,0,0,0,0-2.479Z" transform="translate(-1.389 -2.605)" fill="#FA0202" opacity="0.35"/>
                            <path id="Path_4" data-name="Path 4" d="M5.479,14.132H4.653A1.652,1.652,0,0,1,3,12.479v-.826A1.652,1.652,0,0,1,4.653,10h.826a1.652,1.652,0,0,1,1.653,1.653v.826A1.652,1.652,0,0,1,5.479,14.132Z" transform="translate(0 -1.216)" fill="#FA0202"/>
                            <path id="Path_5" data-name="Path 5" d="M5.479,7.132H4.653A1.652,1.652,0,0,1,3,5.479V4.653A1.652,1.652,0,0,1,4.653,3h.826A1.652,1.652,0,0,1,7.132,4.653v.826A1.652,1.652,0,0,1,5.479,7.132Z" fill="#FA0202"/>
                            <path id="Path_6" data-name="Path 6" d="M5.479,21.132H4.653A1.652,1.652,0,0,1,3,19.479v-.826A1.652,1.652,0,0,1,4.653,17h.826a1.652,1.652,0,0,1,1.653,1.653v.826A1.652,1.652,0,0,1,5.479,21.132Z" transform="translate(0 -2.431)" fill="#FA0202" opacity="0.35"/>
                            </g>
                        </svg>
                        
                    </a>
                    
                }
            </div>
        </>
    );
};
ConsentView.defaultProps = {
    clickHandler:()=>{}
}
export default ConsentView;
