import React from "react";
import LogoIcon from "./LogoIcon";
import CompaniesIcon from "./CompaniesIcon";
import ScheduleIcon from "./AppointmentsIcon";
import WhiteboardIcon from "./WhiteBoardIcon";
import PatientVisitIcon from "./PatientVisit";
import LabIcon from "./LabIcon";
import PatientChatIcon from "./PatientChartIcon";
import ResultIcon from "./ResultIcon";
import SettingsIcon from "./SettingsIcon";
import EditIcon from "./EditIcon";
import RemoveIcon from "./RemoveIcon";
import UpdatetickIcon from "./UpdatetickIcon";
import CancelIcon from "./CancelIcon";
import Permission from "./PermissionIcon";
import ProfileIcon from "./ProfileIcon";
import ProfIcon from "./ProfIcon";
import GlobalIcon from "./GlobalIcon";
import AddPlusIcon from "./AddPlusIcon";
import LoginIcon from "./LoginIcon";
import OrgIcon from "./OrgIcon";
import ChangePasswordIcon from "./ChangePasswordIcon";
import LogoutIcon from "./LogoutIcon";
import SearchIcon from "./SearchIcon";
import FilterIcon from "./FilterIcon";
import BackIcon from "./BackIcon";
import AddIconDetailView from "./AddIconDetailView";
import EditIconDetailView from "./EditIconDetailView";
import DeleteIconDetailView from "./DeleteIconDetailView";
import ViewActivitiesIcon from "./ViewActivitiesIcon";
import PhysicalIcon from "./PhysicalIcon";
import ActivityChartIcon from "./ActivityChartIcon";
import WarningIcon from "./WarningIcon";
import PrintIcon from "./PrintIcon";
import ConsentView from "./ConsentView";
import CalenderPlusIcon from "./CalenderPlusIcon";
import UserGroupIcon from "./UserGroupIcon";
import AddPhysicalAndDrugIcon from "./AddPhysicalAndDrugIcon";
import DarkModeIcon from "./DarkModeIcon";
import DotRoundedIcon from "./DotRoundedIcon";
import CrossCloseIcon from "./CrossCloseIcon";
import LaborderIcon from "./LaborderIcon";
import NotificationIcon from "./NotificationIcon";
import CalenderIcon from "./CalenderIcon";
import StatusIcons from "./StatusIcons";
import QrCode from "./QrCode";
import TimeZoneIcon from "./TimeZoneIcon";
import CertificateIcon from "./CertificateIcon";
import CopyIcon from "./copyIcon";
import DashboardMessageIcon from "./dashboardmsg";


const Icons = (props) => {
  const { type } = props;
  switch (type) {
    case "Logo":
      return <LogoIcon {...props} />;
    case "Company":
      return <CompaniesIcon {...props} />;
    case "organization":
      return <OrgIcon {...props} />;
    case "Schedule":
      return <ScheduleIcon {...props} />;
    case "WhiteBoard":
      return <WhiteboardIcon {...props} />;
    case "PatientVisit":
      return <PatientVisitIcon {...props} />;
    case "Results":
      return <ResultIcon {...props} />;
    case "Labs":
      return <LabIcon {...props} />;
    case "PatientChart":
      return <PatientChatIcon {...props} />;
    case "Settings":
      return <SettingsIcon {...props} />;
    case "Edit":
      return <EditIcon {...props} />;
    case "Cancel":
      return <CancelIcon {...props} />;
    case "Remove":
      return <RemoveIcon {...props} />;
    case "Update":
      return <UpdatetickIcon {...props} />;
    case "Permission":
      return <Permission {...props} />;
    case "ProfileIcon":
      return <ProfileIcon {...props} />;
    case "profile2":
      return <ProfIcon {...props} />;
    case "GlobalIcon":
      return <GlobalIcon {...props} />;
    case "AddPlus":
      return <AddPlusIcon {...props} />;
    case "LoginIcon":
      return <LoginIcon {...props} />;
    case "changepassword":
      return <ChangePasswordIcon {...props} />;
    case "logout":
      return <LogoutIcon {...props} />;
    case "Search":
      return <SearchIcon {...props} />;
    case "Filter":
      return <FilterIcon {...props} />;
    case "Back":
      return <BackIcon {...props} />;
    case "AddIconDetailView":
      return <AddIconDetailView {...props} />;
    case "EditIconDetailView":
      return <EditIconDetailView {...props} />;
    case "DeleteIconDetailView":
      return <DeleteIconDetailView {...props} />;
    case "Activities":
      return <ViewActivitiesIcon {...props} />;
    case 'Physical':
      return <PhysicalIcon {...props} />
    case 'ActivityChart':
      return <ActivityChartIcon {...props} />
    case 'Warning':
      return <WarningIcon {...props} />
    case 'Print':
      return <PrintIcon {...props} />
    case 'ConsentEdit':
    case 'ConsentAdd':
      return <ConsentView {...props} />;
    case 'CalenderPlus':
      return <CalenderPlusIcon />
    case 'Calender':
      return <CalenderIcon />
    case 'UserGroup':
      return <UserGroupIcon />
    case 'AddPhysical':
      return <AddPhysicalAndDrugIcon />
    case 'DarkMode':
    case 'LightMode':
      return <DarkModeIcon {...props} />;
    case 'Dot':
      return <DotRoundedIcon {...props} />;
    case 'QrCode':
      return <QrCode {...props} />
    case 'Close':
      return <CrossCloseIcon {...props} />
    case 'Labs Order':
      return <LaborderIcon {...props} />
      case 'Copyicon':
        return <CopyIcon {...props} />
        case 'DashboardMessage':
          return <DashboardMessageIcon {...props} />
    case 'TimeZone':
      return <TimeZoneIcon {...props} />
    case 'Certificate':
      return <CertificateIcon {...props} />
    case 'Notification':
      return <NotificationIcon {...props} />
    case 'green':
    case 'red':
    case 'blue':
    case 'yellow':
    case 'patientdetails':
    case 'visitdetails':
    case 'companydetails':
    case 'labdetails':
    case 'Testdetails':
      return <StatusIcons {...props} />
    default:
      return <></>;
  }
};

export default Icons;
