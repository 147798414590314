import React from "react";
import { isSet,getCss, isArray } from "../../../../../../utils/commonUtils";
import Icons from "../../../../../../components/UI/Icons";

const returnResultData = (itm,formData)=>{
    let result = null;
    if(isArray(itm.keyVal)){
        result = (isSet(itm.keyVal, []).some((someItm) => {
            return isSet(formData[someItm], '-') === 'Yes';
        })===true)?'Failed':'Passed';
    }else{
        if (itm.type=='yesorno') {
            // console.log('formData[itm.keyVal]',formData[itm.keyVal]);
            result = isSet(formData[itm.keyVal],'No')==='No' ?"Normal":"Abnormal"
        }
        else{
            result = isSet(formData[itm.keyVal], '-');
        }
        
    }
    return result;
}
const ResultSummary = (props) => {
    const {formData,results} = props;

    return (
        <div className="sh_cardBox h-auto sh_testdetailwidget">
            <div className="sh_cardHeader">
                <div className="d-flex justify-content-between align-items-center">
                <h6 className="text-md font-weight-bold mb-0">
                <span className="px-2">
                <Icons type="Testdetails" />
                </span>
                 Test Result
                </h6>
                    {/* <button className="btn btn-xs text-xs text-info">View More</button> */}
                </div>
            </div>
            <div className="sh_cardBody px-3 py-1">
                <div className="row">
                    {results.map((item,itemIndex)=>{
                       const isFailed = isSet(item.data, []).some((itm) => {
                            let result = returnResultData(itm,formData);
                            return result === 'Failed' || result === 'Abnormal';
                          });
                        //   console.log('isFailed',item,isFailed);
                          const sortedItems = isSet(item.data, []).sort((a, b) => {
                            const resultA = isSet(formData[a.keyVal], '-');
                            const resultB = isSet(formData[b.keyVal], '-');
                            const order = {
                                'Abnormal': 1,
                                'Failed': 2,
                                'Passed': 3,
                                'Normal': 4,
                            };
                            // Get the sorting order for item a and item b
                            const orderA = order[resultA] || Number.MAX_SAFE_INTEGER;
                            const orderB = order[resultB] || Number.MAX_SAFE_INTEGER;
                            // Sort based on the defined order
                            return orderA - orderB;
                        });
                        return(
                            <div className="col-md-12" key={itemIndex}>
                                <div className="form-group mb-1">
                                    {/* <label className="d-block f-400 text-sm font-weight-bold text-truncate mb-0 opacity-70">{item.label}</label> */}
                                    <div className="row">
                                      <div className="col-12">
                                    {(isFailed===true?
                                        <span class="text-danger d-block f-400 text-sm font-weight-bold text-truncate1 mb-0 opacity-70 "> {item.abnormalMsg}</span>
                                        :<span class="text-success d-block f-400 text-sm font-weight-bold text-truncate1 mb-0 opacity-70"> {item.normalMsg}</span>
                                    )}
                                    </div>
                                    </div>
                                    {sortedItems.map((itm,itmIndex)=>{
                                        let result = returnResultData(itm,formData);
                                        const displayResult = (result==='Failed' || result==='Abnormal')?'Abnormal':'Normal';
                                        let otherArributes = {...getCss(itm.keyVal,displayResult,'LIGHT')};
                                        return(
                                          
                                            <div className="row statusBadge align-items-center justify-content-between mb-0 py-1" key={itmIndex}style={{borderBottom: '1px solid #f2f2f4',borderTop: '1px solid #f2f2f4' }} >
                                                <div className="col-4 pl-1">
                                                    <span className={`ml-0 badge ${otherArributes.appendClassName}`}>{displayResult}</span>
                                                </div>
                                                <div className="col-6 pl-1">
                                                    
                                                    <h6 className="font-weight-bold pl-1 text-sm text-truncate mb-0" title={itm.label} >{itm.label}</h6>
                                                </div>
                                           
                                            </div>
                                        ) 
                                    })}
                                   
                                    {/* <h6 className="font-weight-bold text-sm text-truncate" title={item.value}>{isSet(formData[item.keyVal],'-')}</h6> */}
                                </div>
                               
                            </div>
                        )
                    })}
                    
                    
                </div>
            </div>
        </div>
    )
}

export default ResultSummary;
