import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import EzReaderList from "./EzReaderList";




const EzReaderContainer=()=>{
    return(
        <>
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<EzReaderList/>} />
        
      </Routes>
      </Wrap>
      </>
    );
}
export default EzReaderContainer; 

