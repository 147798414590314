import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import WithRoutify from "../../../hoc/WithRoutify";
import CsvService from "../../../services/CsvService";
import FileManagerService from "../../../services/FileManagerService";
import UploadCsv from "./UploadCsv";
import Mappingfield from "./MappingField";
import withReduxState from "../../../hoc/wReduxState";
import { getOrganizationId, isSet } from "../../../utils/commonUtils";
import OrganizationService from "../../../services/OrganizationService";
import { getSystemFields } from "./SystemFields";
const CsvModal = (props) => {
  const {addFormHandler,Toast,fetchRecords,Loader } = props;
  const [csvfile, setCsvFile] = useState();
  const [activeTab, setActiveTab] = useState('upload_csv');
  const [csvHeaders,setCsvHeaders]=useState([]);
  const [mappingFormData,setMappingFormData]=useState({});
  const SystemFields=getSystemFields(mappingFormData.type);
  const tabs = [
    { key: 'upload_csv', Component: UploadCsv, title: 'Upload CSV' },
    { key: 'mapping_field', Component: Mappingfield, title: 'Fields Mapping' },
  ];
  const filechangeHandler = (file) => {
    setCsvFile(file);
  }
  const mappingFieldChangeHandler=(name,value)=>{
    setMappingFormData({...mappingFormData,[name]:value})
  }
  const DownloadCsv = async () => {
    const response = await CsvService.downloadSampleCsv(mappingFormData?.type);
    if (response.status && response.status == "ERROR") {
      Toast.error(response.data);
      return false;
    }
    FileManagerService.downloadCsv(response.data);
  }
  const formSubmitHandler = async () => {
    const payloadFormData = new FormData();
    payloadFormData.append('csv_file', csvfile);
    payloadFormData.append('type',mappingFormData.type);
    if (activeTab == 'upload_csv') {
      let validation=[];
      if (!csvfile) {
        validation.push(`CSV File is Required`)
      }
      if (!mappingFormData.type) {
        validation.push(` Type Field is Required`)
      }
      if (isSet(props.profile.is_multiple_organization, false) === true) {
        if (isSet(mappingFormData.organization_id, false) == false) {
          validation.push(`Organization Field is Required`)
        }
      }
      else{
        mappingFormData.organization_id=OrganizationService.getOrgId();
      }
      if (validation.length>0) {
        Toast.error(validation);
        return false;
      }
      Loader(true);
      const response = await CsvService.getCsvFileHeaders(payloadFormData);
      Loader(false);
      let csvHeaders=[{label:'-Select-',value:''}]
      response.forEach((header,index)=>{
        csvHeaders.push({label:header,value:header});
      });
      setCsvHeaders(csvHeaders);
      setActiveTab('mapping_field');
    }
    if (activeTab == 'mapping_field') {
      payloadFormData.append('mapping_form_data', JSON.stringify(mappingFormData));
      let validation=[];
      SystemFields.forEach(item=>{
        if (item.required==true) {
          if (!mappingFormData.hasOwnProperty(item.name)) {
            validation.push(`${item.label} Field Mapping Required`)
          }
        }
      });
      if (validation.length>0) {
        Toast.error(validation);
        return false;
      }
      Loader(true);
      CsvService.processCsvImports(payloadFormData).then(res=>{
        Toast.success("File imported successfully. It will reflects shortly!!!");
        fetchRecords();
        addFormHandler();
      })
      .finally(()=>{
        Loader(false);
      });
     
    }

  }
  const ActiveComponent = tabs.find(tab => tab.key === activeTab)?.Component;
  return (
    <>
      <Modal
        show={true}
        onHide={addFormHandler}
        size="xl"
        dialogClassName="hResponsive "
        contentClassName="h-100"
        className=""
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title style={{ margin: '0 auto' }}>
              <div className="formbold-steps">
                <ul className="justify-content-center">
                  {tabs.map((tab) => (
                    <li
                      key={tab.key}
                      className={`formbold-step-menu${tab.key === activeTab ? ' active' : ''}`}
                    >
                      <span>{tab.key === 'upload_csv' ? '1' : '2'}</span>
                      <h6 className="text-lg mb-0">{tab.title}</h6>
                    </li>
                  ))}
                </ul>
              </div>
            </Modal.Title>

          </Modal.Header>
          <Modal.Body>

            {ActiveComponent ?
              <ActiveComponent
                DownloadCsv={DownloadCsv}
                filechangeHandler={filechangeHandler}
                csvfile={csvfile}
                csvHeaders={csvHeaders}
                type={mappingFormData.type}
                mappingFormData={mappingFormData}
                SystemFields={SystemFields}
                mappingFieldChangeHandler={mappingFieldChangeHandler}

              /> : null}
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="primary"
              className=" "
              onClick={() => formSubmitHandler()}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default  withReduxState(WithRoutify(CsvModal));