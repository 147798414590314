// routes/index.js
import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import AuthService from '../services/AuthService';
import AuthRoutes from './authRoutes';
import UnAuthRoutes from './unAuthRoutes';
import Layouts from '../components/Layouts';
import { isSet } from '../utils/commonUtils';
import { acl_check } from '../utils/aclUtils';
import NotFound from './NotFoundPage';
import { useSelector } from 'react-redux';
import { activeProfileDetails } from '../store/SessionSlice';

const AppRoutes = (props) => {
  // const dispatch = useDispatch();

  const PrivateRoute = ({ children, acl,path }) => {
    const isAuthenticated = AuthService.isAuthenticated();
    let  hasPermission = true; 
    
    try{
      const profileDetails = useSelector(activeProfileDetails);
      const {permissions,is_multiple_organization} = profileDetails;
      
      hasPermission = true;//acl_check(profileDetails.permissions,isSet(acl,''));
      // console.log('profileDetails -11',profileDetails,acl,hasPermission);
      if(path==='/'){
        let redirectUrl = '/dashboard';
       if(isSet(is_multiple_organization,false)===true && acl_check(permissions,'organization-list')===true){
         redirectUrl = '/organisation';
       }else if(acl_check(permissions,'appointment-list')===true){
           redirectUrl = '/appointments';
       }else if(acl_check(permissions,'company-list')===true){
           redirectUrl = '/company';
       }else if(acl_check(permissions,'clinic-list')===true){
           redirectUrl = '/clinics';
       }else if(acl_check(permissions,'employee-list')===true){
           redirectUrl = '/employee';
       }else if(acl_check(permissions,'visits-list')===true){
           redirectUrl = '/visits';
       }else if(acl_check(permissions,'lab-list')===true){
           redirectUrl = '/labs';
       }else if(acl_check(permissions,'user-list')===true){
           redirectUrl = '/users';
       }else if(acl_check(permissions,'role-list')===true){
           redirectUrl = '/roles';
       }
      //  console.lof('redirectUrl',redirectUrl);
       return <Navigate to={redirectUrl} />;
      }
       
    }catch(e){

    }

    return (isAuthenticated && hasPermission) ? children :(!isAuthenticated)?<Navigate to="/login" />:<Navigate to="/unauthorized" />;
  };

  const PublicRoute = ({ children }) => {
    const isAuthenticated = AuthService.isAuthenticated();
    return isAuthenticated ? <Navigate to="/" /> : children;
  };

  const OtherRoute =  ({ children }) => {
    return  children;
  };
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {[...AuthRoutes, ...UnAuthRoutes].map((route, index) => {
          const RouteElement = route.element;
          const WrapRoute = route.routeType === 'Auth' ? PrivateRoute : route.routeType === 'Other' ?OtherRoute: PublicRoute;
          const layoutType = route.layoutType;
          const acl = route.acl || [];

          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact || false}
              element={
                <WrapRoute acl={acl} path={route.path}>
                  <Layouts type={layoutType}>
                    <RouteElement />
                  </Layouts>
                </WrapRoute>
              }
            />
          );
        })}
      <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default (AppRoutes);
