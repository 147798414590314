export const FieldValidationFocus = (inputFields,validation) => {
    inputFields=inputFields.filter(itm=>itm!=null);
    console.log('inputFieldscs',inputFields);

    const flatData = inputFields.flatMap(item => item.data.flat());
    console.log('flatData',flatData);

    let arr1 = flatData.map((i) => i.name);
    const sortedArray = Object.keys(validation).sort((a, b) => arr1.indexOf(a) - arr1.indexOf(b)); //sort error input      
    const firstErrorField = sortedArray[0]; //1st
    console.log('firstErrorField',firstErrorField);
    const firstErrorElement = document.getElementById(firstErrorField);
    if (firstErrorElement) {
        const collapseId = firstErrorElement.closest('.collapse').id;
        const accordionElement = document.getElementById(collapseId);
        if (accordionElement && !accordionElement.classList.contains('show')) {
            const accordionToggle = document.querySelector(`a[href="#${collapseId}"]`); //open accord if closed
            accordionElement.classList.add('show');
            accordionToggle?.setAttribute('aria-expanded', 'true');
        }
        firstErrorElement.focus(); //
    }
}
export const OnTabKeyPress = (event, nextTabId = null, prevTabId = null) => {
    if (event) {
        const asciiCode = event.keyCode || event.which;
        if (asciiCode === 9) {
            if (event.shiftKey && prevTabId) { // Shift + Tab
                event.preventDefault();
                const focusElement = document.getElementById(prevTabId);
                if (focusElement) {
                    focusElement.focus();
                }
            } else if (!event.shiftKey && nextTabId) { //Tab
                event.preventDefault();
                const focusElement = document.getElementById(nextTabId);
                if (focusElement) {
                    focusElement.focus();
                }
            }
        }
    }
}