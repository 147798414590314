import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import FormInputs from "../../../components/UI/FormInputs";
import withReduxState from "../../../hoc/wReduxState";
import { isSet } from "../../../utils/commonUtils";
import { organizationList } from "../../../store/OrganizationSlice";

const UploadCsv = (props) => {
    const { filechangeHandler, DownloadCsv, csvfile, mappingFieldChangeHandler, mappingFormData } = props;
    const organizationListOptions = useSelector(organizationList);
    const [isHover,setIsHover] = useState(false);
    const importTypeOptions = [
        { label: '-Select-', value: '' },
        { label: 'Lab Panel', value: 'lab panel' }
    ];

    const onDrop = useCallback((e) => {
        e.preventDefault();
        setIsHover(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            console.log("file",file);
            filechangeHandler(file);
        }
    }, [filechangeHandler]);

    const onChangeHandler = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            filechangeHandler(file);
        }
    };

    const handleTextClick = () => {
        document.getElementById('inputGroupFile').click();
    };

    const rightLabelStyle = {
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        // padding: '2px 5px 0px 5px',
        
    };

    const leftLabelStyle = {
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        // padding: '2px 5px 0px 5px',
    };
    const DownloadSample ={
        backgroundColor: '#42a1b8',
        border: '1px solid #42a1b8'
    }
    return (
        <>
            <div className="col-12">
                <div className="row">
                    {isSet(props.profile.is_multiple_organization, false) === true ?
                        <div className="col-6">
                            <div className="form-group">
                                <label className="mb-0 text-sm opacity-75 text-truncate">
                                    Select Organization
                                </label>
                                <FormInputs
                                    type="SelectList"
                                    className="form-control form-control-solid"
                                    options={organizationListOptions}
                                    name='organization_id'
                                    changeHandler={(val, e) => mappingFieldChangeHandler('organization_id', val)}
                                    value={mappingFormData['organization_id']}
                                />
                            </div>
                        </div>
                        : ''}
                    <div className="col-6">
                        <div className="form-group">
                            <label className="mb-0 text-sm opacity-75 text-truncate">
                                Type
                            </label>
                            <FormInputs
                                type="SelectList"
                                className="form-control form-control-solid"
                                options={importTypeOptions}
                                changeHandler={(val, e) => mappingFieldChangeHandler('type', val)}
                                value={mappingFormData['type']}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label className="mb-0 text-sm opacity-75 text-truncate">
                                 Sample File
                            </label>
                            <div class="btn-group d-block">
                                <a class="btn btn-primary" 
                                 htmlFor="downloadSampleFile"
                                 style={DownloadSample}
                                 onClick={DownloadCsv}><i class="fa fa-download pr-1" aria-hidden="true"></i>Download</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div
                    onDrop={onDrop}
                    onDragOver={(e) => {setIsHover(true);e.preventDefault()}}
                    onDragEnter={(e) => e.preventDefault()}
                    onDragLeave={(e) => {setIsHover(false);e.preventDefault()}}
                    className="p-3"
                    style={{
                        border:(isHover===true)?'2px dashed #000':'2px dashed #cdcdcd',//'2px dashed #cdcdcd',
                        scale:(isHover===true)?'1.035':'inherit',
                        textAlign: 'center',
                        cursor: 'pointer',
                        backgroundColor: '#fff'
                    }}
                >
                    <div className="input-group mb-3">
                        {/* <label
                            className="btn btn-primary"
                            htmlFor="downloadSampleFile"
                            style={leftLabelStyle}
                            onClick={DownloadCsv}
                        >
                            Download Sample File
                        </label> */}
                        <input
                            type="text"
                            className="form-control"
                            readOnly
                            placeholder={csvfile?.name || 'No File Selected'}
                            style={{ backgroundColor: "#e5e5e5" }}
                            onClick={handleTextClick}
                        />
                        <input
                            type="file"
                            id="inputGroupFile"
                            style={{ display: 'none' }}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={onChangeHandler}
                        />
                        <label className="btn btn-primary" htmlFor="inputGroupFile" style={rightLabelStyle}>
                            Choose CSV File
                        </label>
                    </div>
                  
                </div>
            </div>
        </>
    );
};

export default withReduxState(UploadCsv);
