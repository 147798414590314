import React, { Component } from 'react';
import ClinicService from '../../services/ClinicService';
import TableGrid from '../../components/UI/TableGrid';
import { ToastContext } from "../../context/ToaxtContext";
import FormInputs from '../../components/UI/FormInputs';
import moment from 'moment';
import { toQueryString } from '../../utils/commonUtils';
import Buttons from '../../components/UI/Buttons';
import WithRoutify from '../../hoc/WithRoutify';
// import { isGlobalAdmin } from '../../utils/aclUtils';
// import UserService from '../../services/UserService';
// import OrganizationService from '../../services/OrganizationService';
import withReduxState from '../../hoc/wReduxState';
import TableSkeleton from '../../hoc/TableSkeleton';
import AddProvider from './AddProvider';
import ProviderService from '../../services/ProviderService';



class ProviderList extends Component {
  static contextType = ToastContext;
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      { label: '', key: 'isActive', type: 'checkBox', changeHandler: this.checkBoxchangeHandler },
      { label: 'Provider Name', key: 'name', type: '', inputType: 'TextInput' },
      { label: 'Provider Type', key: 'type', type: '', inputType: 'TextInput' },
      { label: 'Provider Specialty', key: 'specialty', type: '', inputType: 'TextInput' },
      { label: 'Provider Email', key: 'email', type: '', inputType: 'TextInput' },
      { label: 'Action', key: 'action', type: 'Actions' },
    ];
    this.state = {
      filters: {
        search_query: ''
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: 'first_name',
          sort_order: 'asc',
          search: {},
        }
      },
      activeEditId: null,
      // Your state variables go here
      isModelOpen: false,
      organization_id: '',
      organizationOption: [],
      clinicListOption: [],
    }
  }

  componentDidMount() {

    this.fetchProviderList();
    this.fetchClinicList();
    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen })
  }

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    })
  }
  render() {
    const { tableRecords, filters, isModelOpen, clinicListOption } = this.state;
    //  console.log("tableRecords==>",this.state.tableRecords.tableRows);
    return (
      <>
        {isModelOpen ? (
          <AddProvider
            modelTitle={`${this.state.activeEditId ? 'View and Update' : 'Add'} Provider`}
            fetchProviderList={this.fetchProviderList}
            isOpen={this.state.isModelOpen}
            addFormHandler={this.addFormHandler}
            organizationOption={this.state.organizationOption}
            activeEditId={this.state.activeEditId}
            updateHandler={this.updateHandler}
          />
        ) : (
          <></>
        )}
        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"any"}
          content={"Provider"}
        >
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                <h6 class="text-lg f-500 mb-0"> Provider List </h6>
                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div className="col-12 px-1 d-flex">
                      <div className="input-group input-group-joined input-group-solid">
                        <FormInputs
                          placeholder="Provider"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="btn-group pl-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="addAction">
                    <div className="btn-group">
                      <Buttons
                        clickHandler={() => { this.addFormHandler(); this.setState({ activeEditId: '' }) }}
                        className="btn btn-primary"
                        acl={"provider-create"}
                        label={"Add"}
                      />
                      <button
                        className="btn btn-outline-secondary dropdown no-arrow"
                        data-bs-toggle="dropdown"
                      >
                        <span className="dropdown-toggle">
                          <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </span>
                        <div
                          className="dropdown-menu dropdown-menu-right shadow"
                          aria-labelledby="userDropdown"
                        >
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Group Edit
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Grid Edit
                          </a>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                hasPagination={true}
                fetchTableRecords={(filters) => this.fetchProviderList(filters)}
                {...tableRecords}
              />
            </div>
          </div>
        </TableSkeleton>
      </>
    );
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const queryfilters = {
      ...tableRecords.pagination,
      page:1,
      search:filters
    }
    await this.fetchProviderList(queryfilters);
  }
  clearFiltersHandler = () => {
    this.setState({
      filters: {
      }
    }, this.submitFiltersHandler);
  }
  fetchClinicList = async () => {
    ClinicService.getClinicList(null).then((response) => {
      let options = [{ label: "Clinics", value: "" }];
      response &&
        response.data.data.forEach((itm) => {
          options.push({ label: itm.name, value: itm.clinic_id });
        });
      this.setState((prevState) => ({
        clinicListOption: options,
      }));
    });
  };
  fetchProviderList = async (query = {}) => {
    const { tableRecords } = this.state;
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });
    try {
      this.props.Loader(true);
      const ProviderList = await ProviderService.getProviders(null,queryString);
      let tableData = [];
      ProviderList.data.forEach((provider, providerIndex) => {
        const usDateTimeString = moment.utc(provider.created_at).format('MMMM D, YYYY h:mm:ss A');
        tableData = [
          ...tableData,
          {
            providerIndex: providerIndex,
            isHeading: false,
            rowId: provider.provider_id,
            data: {
              isActive: false,
              name: provider.first_name + ' ' + provider.last_name,
              type: provider.type,
              specialty: provider.specialty,
              email: provider.email,
              active: 'Yes',
              action: [{
                className: 'btn btn-icon btn-transparent-dark',
                iconType: 'Search',
                title: 'Edit',
                type: 'GridEdit',
                acl: ['provider-edit'],
                clickHandler: (rowId, data) => this.editHandler(rowId, data),
              }, {
                className: 'btn btn-icon btn-transparent-dark',
                iconType: 'Remove',
                acl: 'provider-delete',
                title: 'Delete',
                isDelete: true,
                clickHandler: () => this.deleteHandler(provider.provider_id),
              }]
            }
          }
        ]
      });
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            type: "default",
            data: tableData,
            status: 200,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: ProviderList.pagination.total
          },
        },
      }));
      this.props.Loader();
    } catch (e) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200
          }
        }
      });
      this.props.Loader();
    }
  }
  deleteHandler = async (id) => {
    try {
      const data = await ProviderService.deleteProvider(id);
      this.props.Toast.success('Provider Removed successfully');
      this.fetchProviderList();
      //   }
    } catch (e) {

    }

  }
  editHandler = (rowId, data) => {
    this.setState({
      activeEditId: rowId,
    }, this.addFormHandler);
  }
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    })
  }
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click 
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}

export default withReduxState(WithRoutify(ProviderList));