import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import WithRoutify from "../../hoc/WithRoutify";
import BasicPhysicalService from "../../services/PhysicalService";
import withReduxState from "../../hoc/wReduxState";
import OrganizationService from "../../services/OrganizationService";

const AddBasicPhysical = (props) => {
  const { isOpen, addFormHandler, modelTitle, Toast, options, activeEditId, fetchBasicPhysicalsList ,Loader} = props;
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const inputFields = getInputFields();
  useEffect(() => {
    //intial render
    if (activeEditId) {
      Loader(true);
      BasicPhysicalService.getBasicPhysicalById(activeEditId)
        .then(response => {
          setFormData(response.data)
        })
        .finally(()=>Loader(false));
    }
  }, []);
  const changeHandler = (val, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: val,
    });
    setValidation({ ...validation, [fieldName]: "" });
  };
  const formSubmitHandler = async () => {
    let data;
    let orgId=  isSet(props.profile.role) && props.profile.role === 'global_admin' && props.profile.is_multiple_organization ? null : OrganizationService.getOrgId();
    let orgQueryString = "";
    if(isSet(orgId)){
      orgQueryString = "?organization_id="+orgId;
    }
    if (activeEditId) {
      data = await BasicPhysicalService.updateBasicPhysical(orgQueryString,activeEditId, formData);
    }
    else {
      data = await BasicPhysicalService.saveBasicPhysical(orgQueryString,formData);
    }
    let updateValidation = { ...validation };
    if (data.status && data.status === "ERROR") {
      if (isObject(data.data)) {
        for (let key in data.data) {
          updateValidation = {
            ...updateValidation,
            [key]: data.data[key].join(","),
          };
        }
      } else {
        props.Toast.error(data.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    } else {
      fetchBasicPhysicalsList();
      addFormHandler();
      let message = `Physical ${activeEditId ? "Updated" : "Added"} Successfully`
      Toast.success(message);
    }

  };
  return (
    <>
      <Modal
        show={isOpen}
        onHide={addFormHandler}
        keyboard={false}
        backdrop="static"
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">{modelTitle}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {inputFields &&
                inputFields.map((field, index) => (
                  <div className={`col-12`} key={index} >
                    <div className="form-group">
                      <label className="text-gray-900 text-md opacity-75">{field.label}</label>
                      <FormInputs
                        {...field}
                        changeHandler={(value, e) =>
                          changeHandler(value, field.name)
                        }
                      />
                      {isSet(validation[field.name], "") !== "" ? (
                        <span className="text-danger m-1">
                          {isSet(validation[field.name], "")}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="primary"
              className=""
              onClick={() => formSubmitHandler()}
            >
              {activeEditId ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  function getInputFields() {
    return [
      {
        type: "TextInput",
        label: "Physical",
        placeholder: "Physical",
        className: "form-control form-control-solid",
        name: "physical_activity",
        value: formData.physical_activity,
        required: true,
      },
      {
        type: "Number",
        label: "Price",
        placeholder: "Price",
        className: "form-control form-control-solid",
        name: "price",
        value: formData.price,
        decimalAllowed:true,
      },
      {
        type: "SelectList",
        label: "Remind In",
        options: options.remind_in || [],
        placeholder: "Remind In",
        className: "form-control form-control-solid",
        name: "remind_in",
        value: formData.remind_in,
      },
    ];
  }
};
export default withReduxState(WithRoutify(AddBasicPhysical));