import React, { useEffect, useState } from 'react';

const HeaderButtons = (props) => {
  const { tableRecords, checkBoxchangeHandler, headerButtons, setSelectedAllRecords, selectedAllRecords } = props;
  const totalCount = tableRecords?.pagination?.totalRecords;
  const { tableRows } = tableRecords;
  const [activeIds, setActiveIds] = useState([]);

  // Update active IDs when table records change
  useEffect(() => {
    const newActiveIds = tableRows.data
      .filter(item => item.data.isActive === 1)
      .map(item => item.rowId);
    setActiveIds(prevActiveIds => new Set([...prevActiveIds, ...newActiveIds]));
  }, [tableRecords]);

  useEffect(() => { //while go next page with after delete remove all checkbox
    if (!anyRecordSelected()) {
      const allCheckbox = document.getElementById('ALL');
      if (allCheckbox) {
        allCheckbox.checked = false;
      }
    }
  }, [totalCount]);

  // Re-render when the page changes
  useEffect(() => {}, [tableRecords?.pagination?.page]);

  // Check if any record on the current page is selected
  const anyRecordSelected = () => {
    return tableRows.data.some(item => item.data.isActive === 1 || item.data.isActive === true);
  };

  // Get count of selected records on the current page
  const getSelectedCount = () => {
    return tableRows.data.filter(item => item.data.isActive === 1 || item.data.isActive === true).length;
  };

  // Determine if all records across pages are selected
  const allRecordsSelected = () => {
    const selectedCount = getSelectedCount();
    // Compare selected count with the total number of records
    return selectedCount === totalCount;
  };

  return (
    <>
      {anyRecordSelected() ? (
        <>
          <div style={{ borderBottom: '1px solid #dfe3ee', textAlign: 'center' }}>
            <div id="pageSelect">
              {`Selected ${selectedAllRecords ? `All ${totalCount}` : `${getSelectedCount()} Entries`}`}
              {selectedAllRecords || allRecordsSelected() ? (
                <a
                  className="btn btn-link"
                  id=""
                  onClick={(e) => {
                    checkBoxchangeHandler(0, { e }, 'ALL');
                    setSelectedAllRecords(false);
                  }}
                >
                  <strong>Clear Selection</strong>
                </a>
              ) : (
                <a
                  className="btn btn-link"
                  id=""
                  onClick={(e) => {
                    checkBoxchangeHandler(1, { e }, 'ALL');
                    setSelectedAllRecords(true);
                  }}
                >
                  <strong>Select All Entries</strong>
                </a>
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default HeaderButtons;










{/* <div className={className}>
                        <span class="badge bg-blue-soft text-blue">{selectedCount}</span> Selected
                        <a class="text-info textUnderline text-small ml-1" id='' onClick={(e) => checkBoxchangeHandler(1, { e }, 'ALL')}>Select All </a>
                        <a class="text-info textUnderline text-small ml-1" id='' onClick={(e) => checkBoxchangeHandler(0, { e }, 'ALL')} >Unselect All </a>

                        <span className="" data-bs-toggle="dropdown">
                            <span><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 18 18" enable-background="new 0 0 18 18"><circle fill="#708090" cx="16.334" cy="9" r="1.666"></circle><circle fill="#708090" cx="9" cy="9" r="1.666"></circle><circle fill="#708090" cx="1.667" cy="9" r="1.666"></circle></svg></span>

                        </span>
                        {
                            selectedCount == "ALL" ?
                                <>

                                    <RadioButton
                                        label={""}
                                        className={"form-check"}
                                        options={[
                                            { label: 'All 50 records in this page are selected', value: 'All 50 records in this page are selected', className: 'form-check-label' },
                                            , { label: `Select all  ${totalCount} from list`, value: 'es', className: 'form-check-label' }
                                        ]}
                                        id='select_all'
                                    />
                                </>
                                : ''
                        }

                        <div className="dropdown-menu dropdown-menu-left shadow" aria-labelledby="">
                            {
                                headerButtons.map((itm, ind) => {
                                    return (
                                        <>
                                            <div className="dropdown-item" id={itm.label} onClick={() => buttonClickHandler(itm.clickHandler)} >
                                                {itm.label}
                                            </div>
                                        </>
                                    )

                                })
                            }
                        </div>
                    </div> */}