import React, { Component } from "react";
import AddVaccineAdministrator from "./AddVaccineAdministrator";
import FormInputs from "../../../components/UI/FormInputs";
import Buttons from "../../../components/UI/Buttons";
import TableGrid from "../../../components/UI/TableGrid";
import { isSet, toQueryString } from "../../../utils/commonUtils";
import WithRoutify from "../../../hoc/WithRoutify";
import withReduxState from "../../../hoc/wReduxState";
import VaccineAdministratorService from "../../../services/VaccineAdministratorService";
import TableSkeleton from "../../../hoc/TableSkeleton";
import PERMISSIONS from "../../../utils/AclPermissions";

class VaccineAdministratorList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      {
        label: "Person Administrating Vaccine",
        key: "person_administrating_vaccine",
        type: "TextInput",
      },
      {
        label: "Title",
        key: "title",
        type: "TextInput",
      },
      { label: "Action", key: "action", type: "Actions" },
      // Add more columns as needed
    ];
    this.state = {
      filters: {
        search_query: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "person_administrating_vaccine",
          sort_order: "asc",
          search: {},
        },
      },
      // formDataInEdit: {},
      activeEditId: null,
      // Your state variables go here
      isModelOpen: false,
      isVaccineModelOpen: false,
      activeActivityData: {},
    };
  }

  componentDidMount() {
    this.fetchVaccineAdministrator();
    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ activeEditId: null,isVaccineModelOpen: true });
  };

  
  render() {
    const { tableRecords, filters,  activeEditId, isVaccineModelOpen } = this.state;

    return (
      <>
        {isVaccineModelOpen ? (
          <AddVaccineAdministrator
            isOpen={isVaccineModelOpen}
            fetchVaccineAdministrator={this.fetchVaccineAdministrator}
            vaccineFormHandler={this.vaccineModelHandler}
            activeEditId={activeEditId}
            modelTitle={`${activeEditId ? 'Edit' : 'Add'} Vaccine Administration`}
            isMultipleOrg={this.props.profile.is_multiple_organization}
          />
        ) : (
          <></>
        )}

        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={PERMISSIONS.VACCINE_ADMINISTRATION.CREATE}
          content={"Vaccine Administration"}
        >
        <div className="sh_cardBox">
          <div className="sh_cardHeader">
            <div className="form-group d-flex mb-0 justify-content-between align-items-center">
              <h6 class="text-lg f-500 mb-0">Vaccine Administration List </h6>

              <div className="d-flex">
                <div className="form-group-fields row mr-2">
                  <div className="col-12 d-flex">
                    <div class="input-group input-group-joined input-group-solid">
                      <FormInputs
                        placeholder="Administrator"
                        value={filters["search_query"]}
                        changeHandler={(val) =>
                          this.onChangeFiltersHandler("search_query", val)
                        }
                        className="form-control"
                      />
                    </div>

                    <div class="btn-group pl-2">
                      <button
                        className="btn btn-primary"
                        onClick={() => this.submitFiltersHandler()}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-secondary"
                        onClick={() => this.clearFiltersHandler()}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>

                <div className="addAction">
                  <div className="btn-group">
                    <Buttons
                      clickHandler={() => { this.addFormHandler(); }}
                      className="btn btn-primary"
                      acl={PERMISSIONS.VACCINE_ADMINISTRATION.CREATE}
                      label={"Add"}
                    />
                    {/* <button
                      className="btn btn-outline-secondary dropdown no-arrow"
                      data-bs-toggle="dropdown"
                    >
                      <span className="dropdown-toggle">
                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                      </span>
                      <div
                        className="dropdown-menu dropdown-menu-right shadow"
                        aria-labelledby="userDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Group Edit
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Grid Edit
                        </a>
                      </div>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sh_cardBody p-0">
            <TableGrid
              hasPagination={true}
              fetchTableRecords={(filters) =>
                this.fetchVaccineAdministrator(filters)
              }
              {...tableRecords}
            />
          </div>
        </div>
        </TableSkeleton>
      </>
    );
  }
  
  submitFiltersHandler = () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      page:1,
      search: filters,
    };
    this.fetchVaccineAdministrator(queryfilters);
  };
  clearFiltersHandler = () => {
    this.setState(
      {
        filters: {},
      },
      this.submitFiltersHandler
    );
  };


  fetchVaccineAdministrator = async (query) => {
    const { tableRecords } = this.state;
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });
    try {
      this.props.Loader(true);
      const adminVaccineList = await VaccineAdministratorService.getVaccineAdministration(null, queryString);
      let tableData = [];
      adminVaccineList.data.data.map((vaccine, index) => {
          
        tableData = [
          ...tableData,
          {
            VaccineFormIndex: index,
            isHeading: false,
            rowId: vaccine.id,
            vaccine_administration_id: vaccine.id,
            data: {
              isActive: false,
              person_administrating_vaccine: vaccine.person_administrating_vaccine,
              title: vaccine.title,
              action: [
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Search",
                  title: "view",
                  type: "GridEdit",
                  acl:PERMISSIONS.VACCINE_ADMINISTRATION.EDIT,
                  clickHandler: (rowId) => this.editHandler(rowId),
                },
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Remove",
                  acl: PERMISSIONS.VACCINE_ADMINISTRATION.DELETE,
                  title: "Delete",
                  isDelete: true,
                  clickHandler: () =>
                    this.deleteHandler(vaccine.id),
                },
              ],
            },
          },
        ];
      });
      this.setState(
        (prevState) => ({
          tableRecords: {
            ...prevState.tableRecords,
            tableRows: {
              status: 200,
              type: "default",
              data: tableData,
            },
            pagination: {
              ...prevState.tableRecords.pagination,
              ...query,
              totalRecords: adminVaccineList.data.total
            },
          },
        }),
        () => {

        }
      );
      this.props.Loader();
    } catch (e) {
      this.props.Loader();
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200,
          },
        },
      });
    }
  };
  deleteHandler = async (id) => {
    VaccineAdministratorService.deleteVaccineAdministration(null, id)
      .then((res) => {
        this.fetchVaccineAdministrator();
        this.props.Toast.success("Vaccine Administration Deleted Successfully");
      })
      .catch((err) => {
        //
      })
      .finally(() => {
        //
      });
  };
  editHandler = (rowId) => {
    this.setState({
      activeEditId: rowId,
      // formDataInEdit: data,
      isVaccineModelOpen: true
    });
  };
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  checkBoxchangeHandler = (value, e) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
  vaccineModelHandler = (fetch = false) => {
    this.setState({
      isVaccineModelOpen: false,
    });
    if (fetch) {
      this.fetchVaccineAdministrator();
    }
  }
}
export default withReduxState(WithRoutify(VaccineAdministratorList));
