import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { isObject, isSet } from "../../../utils/commonUtils";
import FormInputs from "../../../components/UI/FormInputs";
import Buttons from "../../../components/UI/Buttons";
import WithRoutify from "../../../hoc/WithRoutify";
import VaccineAdministratorService from "../../../services/VaccineAdministratorService";
import { useSelector } from "react-redux";
import { organizationList } from "../../../store/OrganizationSlice";
import withReduxState from "../../../hoc/wReduxState";
import OrganizationService from "../../../services/OrganizationService";

const AddVaccineAdministrator = (props) => {
  
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({ organization_uuid: OrganizationService.getOrgId()});
  const { isOpen, vaccineFormHandler, isMultipleOrg, fetchCptList, modelTitle, activeEditId, Toast, Loader } = props;
  const organizationListOptions = useSelector(organizationList);

  useEffect(() => {
    if (activeEditId) {
      fetchVaccineAdministrationById();
    }

  }, []);

  let inputFields = [
    {
      type: "TextInput",
      label: "Person Administrating Vaccine",
      placeholder: "Person Administrating Vaccine",
      className: "form-control form-control-solid",
      name: "person_administrating_vaccine",
      required:true,
    },
    {
      type: "TextInput",
      label: "Title",
      placeholder: "Title",
      className: "form-control form-control-solid",
      name: "title",
    }
  ];
  if (isMultipleOrg && !activeEditId) {
    inputFields = [
      {
        type: "SelectList",
        col: "col-6",
        label: "Organization",
        options: organizationListOptions,
        placeholder: "",
        className: "form-control form-control-solid",
        name: "organization_uuid",
        required:true,
      },
      ...inputFields
    ];
  }


  const fetchVaccineAdministrationById = async () => {
    let updatedFormData = { ...formData }
    let updatedValidation = { ...validation };
    if (activeEditId) {
      props.Loader(true);

      const response = await VaccineAdministratorService.getVaccineAdministrationById(isSet(props.organizationId, null), activeEditId);
      props.Loader(false);
      if (response) {
        updatedFormData = { ...updatedFormData, ...response };
      }
      setFormData(updatedFormData);
      setValidation(updatedValidation);
    }
  };


  const changeHandler = async (val, fieldName, e) => {
    setFormData({
      ...formData,
      [fieldName]: val,
    });
    setValidation({ ...validation, [fieldName]: "" });
  };


  const formSubmitHandler = async () => {
    let updateValidation = { ...validation };
   
    props.Loader(true);
    const payload = {
      organization_uuid: formData.organization_uuid,
      title: formData.title,
      person_administrating_vaccine: formData.person_administrating_vaccine
    }
    
    let data;
    if (activeEditId) {
      data = await VaccineAdministratorService.updateVaccineAdministration(isSet(formData.organization_uuid, null), activeEditId, payload);
    } else {
      data = await VaccineAdministratorService.saveVaccineAdministration(isSet(formData.organization_uuid, null), payload);

    }
    if (data.status && data.status == "ERROR") {
      if (isObject(data.data)) {
        for (let key in data.data) {
          updateValidation = {
            ...updateValidation,
            [key]: data.data[key].join(',')
          }
        }
      } else {
        Toast.error(data.data);
      }


      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    } else {
      let message = `Vaccine Administration ${activeEditId ? 'Updated' : 'Added'} Successfully`
      vaccineFormHandler(true);
      Toast.success(message);
    }
    props.Loader(false);

  }
  return (
    <>
      <Modal
        show={isOpen}
        onHide={vaccineFormHandler}
        size="lg"
        dialogClassName="hResponsive"
        contentClassName="h-100"
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="font-weight-bold text-lg mb-0">
                {modelTitle}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {inputFields &&
                inputFields.map((field, index) => (
                  <div className={`col-12  `} key={index}>
                    <div className="form-group">
                      <label className="text-gray-900 text-md opacity-75"> {field.label} </label>
                      <FormInputs
                        changeHandler={(value, e) =>
                          changeHandler(value, field.name, e)
                        }
                        value={formData[field.name]}
                        {...field}
                      />
                      {isSet(validation[field.name], '') !== '' ? <span className="text-danger m-1">{isSet(validation[field.name], '')}</span> : ''}
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              clickHandler={() => formSubmitHandler()}
              className="btn btn-primary"
              acl={"any"}
              label={activeEditId ? "Update" : "Save"}
            />
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default withReduxState(WithRoutify(AddVaccineAdministrator));