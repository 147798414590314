import React from "react";
import ActivityConsentDeclationService from "../../../services/ActivityConsentDeclationService";
import TableGrid from "../../../components/UI/TableGrid";
import { isObject, isSet } from "../../../utils/commonUtils";
import SectionLoader from "../../../components/UI/SectionLoader";
import WithRoutify from "../../../hoc/WithRoutify";
import { createPortal } from "react-dom";
import { CREATED, UNPROCESSABLE_CONTENT } from "../../../utils/constant";
import Button from "../../../components/UI/Buttons/Button";
import TranslatorService from "../../../services/TranslatorService";
class ConsentDeclination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ConsentDeclinationList: { tableHeaders: [], tableRows: { data: [] } },
            formData: {...this.props.formData},
            formDataInEdit: {},
            activeEditId: null,
            validation: {},
            isLoading: false,
            tableFooter: null,
        };
    }

    

    componentDidMount() {
        this.fetchConsentDeclination();
        const TargetContainer = document.querySelector('.sh_modalBox');
        this.setState({ tableFooter: TargetContainer });
    }
    componentDidUpdate(prevProps) {
        // Check if the specific prop has changed
        if (this.props.activityData !== prevProps.activityData) {
          // Call the method when the prop has changed
          this.fetchConsentDeclination();
        }
      }

    fetchConsentDeclination = async () => {
        const { activityData } = this.props;
        try {
            this.setState({ isLoading: true });
            let response = await ActivityConsentDeclationService.getActivitiesConsentDeclinationById(activityData.actitivity_id);
            let tableData = [];
            tableData = response.data.map((item) => ({
                rowId: item.activity_declination_id,
                data: {
                    reason_for_declination_en: item.reason_for_declination_en,
                    reason_for_declination_es: item.reason_for_declination_es,
                    action: [
                        {
                            className: "btn  btn-icon btn-transparent-dark",
                            iconType: "Edit",
                            title: "Edit",
                            type: "GridEdit",
                            acl: "activities-edit",
                            clickHandler: (rowId, data) => this.editHandler(rowId, data),
                            updateHandler: (id) => this.updateHandler(id),
                        },
                        {
                            className: 'btn btn-icon btn-transparent-dark',
                            iconType: 'Remove',
                            acl: 'any',
                            title: 'Delete',
                            isDelete: true,
                            clickHandler: () => this.deleteHandler(item.activity_declination_id),
                        }
                    ]
                }
            }));
            this.setState({ isLoading: false });
            this.setState({ ConsentDeclinationList: { tableHeaders: this.getTableHeaders(), tableRows: { data: tableData } } });

        } catch (error) {
            this.setState({ isLoading: false });
            console.error("Error fetching additional documents:", error);
        }
    }

    getTableHeaders = () => {
        return [
            { label: 'Reason for Declination (EN)', key: 'reason_for_declination_en', type: '', inputType: "TextInput", },
            { label: 'Reason for Declination (ES)', key: 'reason_for_declination_es', type: '', inputType: "TextInput", },
            { label: 'Action', key: 'action', type: 'Actions' },
            // Add more columns as needed
        ];
    }

    changeHandler = (value, fieldName, dependencyValues = {}) => {
        let updatedFormData = { ...this.props.formData };
        let updateValidation = { ...this.state.validation };
        updatedFormData[fieldName] = value;
        updateValidation[fieldName] = "";
        this.setState({ formData: updatedFormData, validation: updateValidation });
        this.props.setFormData(updatedFormData);
    }

    submitHandler = async () => {
        const { activityData } = this.props;
        let formData = { ...this.state.formData };
        formData['activity_id'] = activityData.actitivity_id;
        this.props.Loader(true);
        let response = await ActivityConsentDeclationService.saveActivityConsentDeclination(formData);
        this.props.Loader(false);
        if (response?.code === UNPROCESSABLE_CONTENT && response?.data) {
            if (isObject(response.data)) {
                let validaitons = {};
                for (let key in response.data) {
                    validaitons = { ...validaitons, [key]: response.data[key] };
                }
                this.setState({ validation: validaitons });
            }
        } else {
            this.setState({ formData: { ...formData, ['reason_for_declination_en']: '', ['reason_for_declination_es']: '' }, validation: {} });
            if(isSet(response.actitivity_id)){
                this.props.addFormHandler(response.actitivity_id,response,true,true);
            }else{
                this.fetchConsentDeclination();
            }
            this.props.setFormData({...this.props.formData, reason_for_declination_en:"", reason_for_declination_es:""});
            this.props.Toast.success("Activity Consent Declination Added Successfully");
        }
    }

    editHandler = (id, data) => {
        this.setState({ activeEditId: id, formDataInEdit: data });
    }

    deleteHandler = async (id) => {
        let response = await ActivityConsentDeclationService.deleteActivityConsentDeclinationById(id);
        if(isSet(response.actitivity_id)){
            this.props.addFormHandler(response.actitivity_id,response,true,true);
        }else{
            this.fetchConsentDeclination();
        }
        this.props.Toast.success("Activity Consent Declination Deleted Successfully");
        
    }

    onChangeFormDataInEdit = async (key, val) => {
        let update = {
            ...this.state.formDataInEdit,
            [key]: val,
            validation: {
                ...this.state.formDataInEdit.validation,
                [key]: ''
            }
        };
        this.setState({ formDataInEdit: update });
    };

    updateHandler = async (id) => {
        let formDataInEdit = { ...this.state.formDataInEdit };
        let payload = {
            reason_for_declination_en: formDataInEdit.reason_for_declination_en,
            reason_for_declination_es: formDataInEdit.reason_for_declination_es
        }
        let response = await ActivityConsentDeclationService.upatateActivityConsentDeclinationById(id, payload);
        if (response.status === "ERROR" && response.data) {
            if (isObject(response.data)) {
                let validaitons = {};
                for (let key in response.data) {
                    validaitons = { ...validaitons, [key]: response.data[key] };
                }
                this.setState((prevState) => ({
                    formDataInEdit: {
                        ...prevState.formDataInEdit,
                        validation: validaitons,
                    },
                }));
            }
        } else {
            this.props.Toast.success("Activity Consent Declination Updated Successfully")
            if(isSet(response.actitivity_id)){
                this.props.addFormHandler(response.actitivity_id,response,true,true);
            }else{
                this.fetchConsentDeclination();
            }
            this.editHandler(null, {});
        }
    }
    translateEnglishToSpanish = async ()=>{
        this.props.Loader(true);
        const {formData} = this.state;
        const [declination] = await Promise.all([
          TranslatorService.translator(isSet(formData['reason_for_declination_en'],'')),
      ]);
      this.changeHandler(declination, 'reason_for_declination_es')
      this.props.Loader(false);
    
    }

    render() {
        const { isLoading, ConsentDeclinationList, formData, validation, formDataInEdit, activeEditId, tableFooter } = this.state;
        const {activityData}=this.props;
        return (
            <>
                <div className="sh_innerPortview">
                    <div className="row">
                        <div className="col-12 col-lg-5">
                            <div className="sh_cardBox h-auto shadow-none border">
                                <div className="sh_cardHeader">
                                    <h6 className="m-0 text-md "> Adding Consent Declination for <span className="badge bg-blue-soft text-blue"> {activityData.activity_name}</span> </h6>
                                </div>
                                <div className="sh_cardBody p-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="text-sm text-truncate mb-0 opacity-75">    Reason for Declination (EN) </label>
                                                <div className="d-flex">
                                                    <input type="text" className="form-control form-control-solid" onChange={(e) => this.changeHandler(e.target.value, 'reason_for_declination_en')} value={formData['reason_for_declination_en']} required />
                                                </div>
                                                {isSet(validation['reason_for_declination_en'], "") !==
                                                    "" ? (
                                                    <span className="text-danger m-1">
                                                        {isSet(validation['reason_for_declination_en'], "")}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="text-sm text-truncate mb-0 opacity-75"> Reason for Declination (ES)<Button clickHandler={()=>this.translateEnglishToSpanish()} acl="any" className="ml-2 badge bg-blue-soft text-light cursor-pointer custom-btn">{`Translate from English`}</Button> </label>
                                                <div className="d-flex">
                                                    <input type="text" className="form-control form-control-solid" onChange={(e) => this.changeHandler(e.target.value, 'reason_for_declination_es')} value={formData['reason_for_declination_es']} />
                                                </div>
                                                {isSet(validation['reason_for_declination_es'], "") !==
                                                    "" ? (
                                                    <span className="text-danger m-1">
                                                        {isSet(validation['reason_for_declination_es'], "")}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sh_cardFooter justify-content-center"><button className="btn btn-primary btn-sm text-sm" onClick={() => this.submitHandler()}> Add </button></div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-7">
                            <div className="sh_cardBox h-auto shadow-none border">
                                <div className="sh_cardHeader">
                                    <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                                        <h6 className="text-md mb-0"> Consent Declination List </h6>
                                    </div>
                                </div>
                                <div className="sh_cardBody p-0 tabletextbox">
                                    {/* <SectionLoader isLoading={isLoading} /> */}
                                    <TableGrid {...ConsentDeclinationList}
                                        gridEditProps={{
                                            formDataInEdit: formDataInEdit,
                                            activeEditId: activeEditId,
                                            onChangeFormDataInEdit: this.onChangeFormDataInEdit,
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isSet(tableFooter, null) !== null ? (
                    <div className="">
                        {createPortal(
                            <div class="d-flex justify-content-center modal-footer">
                                <button type="button" class="btn btn-primary" onClick={() => this.props.addFormHandler(null, null, true)}>Close</button>
                            </div>,
                            tableFooter
                        )}
                    </div>
                ) : (
                    // Handle the case where the target container is not found
                    <></>
                )}

            </>
        );
    }
}

export default WithRoutify(ConsentDeclination);
