import React, { Component } from 'react';
import AddAssignActivities from './AddAssignActivities';
import Buttons from '../../../components/UI/Buttons';
import WithRoutify from '../../../hoc/WithRoutify';
import WithRouter from '../../../hoc/WithRouter';
import TableGrid from '../../../components/UI/TableGrid';
import PERMISSIONS from '../../../utils/AclPermissions';

class LeftContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddFormModelOpen: false,
      
      
    };
  }
  componentDidMount = ()=>{
    // this.fetchActivityListOptions();
  
  }


  addFormHandler = () => {
    this.setState(prevState => ({
      isAddFormModelOpen: !prevState.isAddFormModelOpen
    }));
  };

  render() {
    const { isAddFormModelOpen, } = this.state;
    const {tableRecords,params,formDataInEdit,activityListOptions} = this.props;
    // console.log('this.perps',this.props);
    return (
      <>
        {isAddFormModelOpen ? (
          <AddAssignActivities
            modelTitle={"Add New Activities"}
            isOpen={isAddFormModelOpen}
            activityListOptions={activityListOptions}
            addFormHandler={this.addFormHandler}
            submitHandler={this.props.assignHandler}
            tableRecords={tableRecords}
          />
        ) : (
          ""
        )}
       
        <div className="sh_cardBox">
          <div className="sh_cardHeader" style={{ position: "relative" }}>
            <div className="form-group d-flex mb-0 justify-content-between align-items-center">
              <h6 className="text-lg f-500 mb-0">
                <span className="badge back_btn  mr-2 ">
                  <Buttons iconType={"Back"} label={"Back"} acl={this.props.isFromCompany == true ? PERMISSIONS.COMPANY.EDIT : PERMISSIONS.BASIC_PHYSICAL.EDIT} href={(this.props.isFromCompany === true) ? `/company/physical/${params.companyId}/${params.companyName}` : `/basic-physical`} />
                </span> Physical Activities - {decodeURIComponent(params.physicalName)}
              </h6>
              <div className="addAction d-flex justify-content-between align-items-center">
                <Buttons iconType={"AddIconDetailView"} acl={"company-edit"} clickHandler={this.addFormHandler} title={"Add Activity"}/>
              </div>
            </div>
          </div>
          <div className="sh_cardBody p-0 position-relative">
            <div className="card border-0 bg-transparent" style={{position:'absolute',top:'0px',right:'0px',left:'0px',bottom:'0px'}}>
              <div className="sh_innerPortview w-100  ">
                <div className="sh_cardBox shadow-none" style={{borderRadius:'0'}}>
                  <div className="sh_cardBody tabletextbox p-0 position-relative">
                    <TableGrid {...tableRecords}
                      collection={'physical'} sortingTableHandler={this.props.sortingTableHandler} gridEditProps={{
                        formDataInEdit: this.props.formDataInEdit,
                        activeEditId: this.props.activeEditId,
                        onChangeFormDataInEdit: this.props.onChangeFormDataInEdit,
                      }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
      </>
    );
  }

}

export default WithRouter(WithRoutify(LeftContent));
