import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import HL7Service from '../../services/HL7Service';
import Buttons from '../../components/UI/Buttons';

const MessageParser = (props) => {
    const [inputData, setInputData] = useState(''); 
    const [jsonData, setJsonData] = useState({}); 

    const parseJson = async() => {
        try {
            const payload={message:inputData}
           const response= await HL7Service.hl7MessageParse(payload);
           setJsonData(response);
           console.log('respomsne',response);
           
        } catch (error) {
            console.error('Invalid JSON:', error);
            setJsonData({ error: 'Invalid JSON' }); 
        }
    };

    return (
        <div className="container-fluid" style={{ height: '100vh' }}>
            <Buttons
                label={"Back"}
                iconType={"Back"}
                acl={"any"}
                href={"/hl7/log"}
            />
            <div className="row h-100">
                
                <div className="col-md-6 p-3 bg-light border-end">
                    <h5 className="text-center">Original Message</h5>
                    <textarea
                    style={{ height: '500px', resize: 'vertical' }} 
                        className="form-control"
                        value={inputData}
                        onChange={(e) => setInputData(e.target.value)}
                        rows={1000}
                        placeholder="Paste Hl7 message..."
                    />
                    <button onClick={parseJson} className="btn btn-primary mt-2 w-100">Parse</button>
                </div>

                <div className="col-md-6 p-3 overflow-auto bg-white">
                    <h5 className="text-center">Parsed Message</h5>
                    <ReactJson
                     src={jsonData}
                     theme={'Solarized'}
                     enableClipboard={false}
                     name={false}
                     style={{ overflowX: 'auto' }}
                     displayDataTypes={false}
                     displayObjectSize={false}
                    />
                </div>
            </div>
        </div>
    );
}

export default MessageParser;
