import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getLookupByKey, isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import ActivitiesService from "../../services/ActivitiesService";
import WithRoutify from "../../hoc/WithRoutify";
import { ACTIVITY_TYPE, BILLING_SERVICE_TYPE, EthinicityOptions, OK, REMIND_IN, genderOptions } from "../../utils/constant";
import CptService from "../../services/CptService";
import SectionLoader from "../../components/UI/SectionLoader";
import withReduxState from "../../hoc/wReduxState";
import OrganizationService from "../../services/OrganizationService";

const AddActivities = (props) => {
  const { isOpen, addFormHandler, modelTitle, Toast, activeEditId, fetchActivitiesList, Loader } = props;
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [inputFields, setInputFields] = useState([]);
  // const [isLoading,setIsLoading]=useState(false);
  
  useEffect(() => {
    const fetchAndUpdateFormData = async () => {
      const fields = await getInputFields();
      let updatedFormData = { ...formData };
      if (activeEditId) {
        Loader(true);
        const response = await ActivitiesService.getActivitiesById(activeEditId);
        Loader(false);
        updatedFormData = { ...response };
      }
      // Default value logic using isSet function
      updatedFormData['activity_cpt_code_one_qty'] = isSet(updatedFormData['activity_cpt_code_one_qty'], 1);
      updatedFormData['activity_cpt_code_two_qty'] = isSet(updatedFormData['activity_cpt_code_two_qty'], 1);
      updatedFormData['activity_cpt_code_three_qty'] = isSet(updatedFormData['activity_cpt_code_three_qty'], 1);
      updatedFormData['activity_cpt_code_four_qty'] = isSet(updatedFormData['activity_cpt_code_four_qty'], 1);


      // Update the state with the modified form data
      setFormData(updatedFormData);
      setInputFields(fields);

    };

    fetchAndUpdateFormData();
  }, []);

  const changeHandler = (val, fieldName) => {
    if (fieldName == 'activity_code') {
    }
    setFormData({
      ...formData,
      [fieldName]: val,
    });
    setValidation({ ...validation, [fieldName]: "" });
  };
  const formSubmitHandler = async () => {
    if (activeEditId) {
      updateHandler();
    }
    else {
      saveHandler();
    }

  };
  const saveHandler = async () => {
    let orgId=  isSet(props.profile.role) && props.profile.role === 'global_admin' && props.profile.is_multiple_organization ? null : OrganizationService.getOrgId();
    let data = await ActivitiesService.saveActivities(orgId,formData);
    let updateValidation = { ...validation };
    // console.log('saveActivities',data);
    if (data.status && data.status === "ERROR") {
      if (isObject(data.data)) {
        for (let key in data.data) {
          updateValidation = {
            ...updateValidation,
            [key]: data.data[key].join(","),
          };
        }
      } else {
        props.Toast.error(data.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    } else {
      props.fetchActivitiesList();
      props.addFormHandler();
      Toast.success("Activities Added Successfully");
    }

  }
  const updateHandler = async () => {
    let orgId=  isSet(props.profile.role) && props.profile.role === 'global_admin' && props.profile.is_multiple_organization ? null : OrganizationService.getOrgId();
    const { activity_code, activity_name, activity_type, activity_bundle, remind_in, activity_form_type, } = formData;
    const payload = { activity_code, activity_name, activity_type, activity_bundle, remind_in, activity_form_type, };
    ActivitiesService.updateActivities(orgId,activeEditId, formData)
      .then((res) => {
        if (res.code == OK) {
          fetchActivitiesList();
          addFormHandler();
          Toast.success("Activities updated Successfully");
        }
        else if (res.status == "ERROR" && res.data) {
          if (isObject(res.data)) {
            let validaitons = {};
            for (let key in res.data) {
              validaitons = { ...validaitons, [key]: res.data[key][0] };
            }
            setValidation(validaitons);
          }
        }
      })
      .catch((err) => {
        //
      })
      .finally(() => {
        //
      });
  }
  return (
    <>
      <Modal
        show={isOpen}
        onHide={addFormHandler}
        size="xl"
        dialogClassName="hResponsive "
        contentClassName="h-100"
        className=""
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">{modelTitle}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="card-body tab-content p-0">
              {inputFields &&
                inputFields.map((section, sectionIndex) => {
                  return (
                    <>
                      <div class="card mb-2">
                        <a
                          href={`#collapseCardExample_${sectionIndex}`}
                          class="d-block sh_accordionheader card-header"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="true"
                          aria-controls="collapseCardExample"
                          style={{
                            position: "sticky",
                            top: "-15px",
                            zIndex: "1",
                          }}
                        >
                          <h6 class="m-0 text-md">
                            {section.title}
                          </h6>
                        </a>
                        <div
                          class="collapse show"
                          id={`collapseCardExample_${sectionIndex}`}
                          data-bs-parent="#modal111"
                        >
                          <div class="card-body">
                            <div class="row">
                              {section.data && section.data.map((data, dataInd) => {
                                return (
                                  <>
                                    <div class="col-md-4 col-6">
                                      <div class="form-group">
                                        <label class="f-400 text-sm text-truncate mb-0">
                                          {data.label}
                                        </label>
                                        <div
                                          key={dataInd}
                                        >
                                          <FormInputs
                                            {...data}
                                            value={isSet(formData[data.name], '')}
                                            className={
                                              "form-control form-control-solid"
                                            }
                                            changeHandler={(value, e) =>
                                              changeHandler(value, data.name, e)
                                            }
                                          />
                                          {isSet(validation[data.name], "") !==
                                            "" ? (
                                            <span className="text-danger m-1">
                                              {isSet(validation[data.name], "")}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="primary"
              className=""
              onClick={() => formSubmitHandler()}
            >
              {activeEditId ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  async function getInputFields() {
    Loader(true);
    // setIsLoading(true)
    const ActivityOptions = await getLookupByKey(ACTIVITY_TYPE);
    const RemindInOptions = await getLookupByKey(REMIND_IN);
    const BillingServiceTypeOptions = await getLookupByKey(BILLING_SERVICE_TYPE);
    let cptCodeResponse = await CptService.getCptList();
    let CptCodeOptions = [{ label: '-Select-', value: '' }];
    cptCodeResponse.data.data.forEach((itm, ind) => {
      CptCodeOptions.push({ label: itm.code + (itm.description ? ' - ' + itm.description : ''), value: itm.cpt_id });
    });
    Loader(false);
    // setIsLoading(false)
    const inputFields = [
      {
        title: "Activity - General",
        data: [
          {
            type: "TextInput",
            label: "Activity Name",
            placeholder: "Activity Name",
            className: "form-control form-control-solid",
            name: "activity_name",
            required: true,
            isFirstLetterCaps: true,
          },
          {
            type: "TextInput",
            label: "Code",
            placeholder: "Code",
            className: "form-control form-control-solid",
            name: "activity_code",
            required: true,
          },
          {
            type: "TextInput",
            label: "Description",
            placeholder: "Description",
            className: "form-control form-control-solid",
            name: "activity_description",
            // required: true,
            isFirstLetterCaps: true,
          },
          {
            type: "SelectSearchList",
            options: ActivityOptions,
            label: "Type",
            placeholder: "Type",
            className: "form-control form-control-solid",
            name: "activity_type",
            required: true,
          },
          {
            type: "SelectList",
            options: RemindInOptions,
            label: "Repeat / Interval",
            placeholder: "Repeat / Interval",
            className: "form-control form-control-solid",
            name: "remind_in",
            // required: true,
          },
        ]


      },
      {
        title: "Activity - Codes",
        data: [
          {
            type: "TextInput",
            label: "LOINC Code",
            placeholder: "LOINC Code",
            className: "form-control form-control-solid",
            name: "activity_loinc_code",
          },
          {
            type: "TextInput",
            label: "CVX Code",
            placeholder: "CVX Code",
            className: "form-control form-control-solid",
            name: "activity_cvx_code",
          },
        ]

      },
      {
        title: "Activity - Restrictions",
        data: [
          {
            type: "Number",
            label: "Restrictions Age (years)",
            placeholder: "Restrictions Age (years)",
            className: "form-control form-control-solid",
            name: "activity_restrictions_age_min",
          },
          {
            type: "SelectList",
            options: genderOptions,
            label: "Restrictions Sex",
            placeholder: "Restrictions Sex",
            className: "form-control form-control-solid",
            name: "activity_restrictions_sex",
          },
          {
            type: "SelectList",
            options: EthinicityOptions,
            label: "Restrictions Ethnicity",
            placeholder: "Restrictions Ethnicity",
            className: "form-control form-control-solid",
            name: "activity_restrictions_ethnicity",
          },
          {
            type: "TextInput",
            label: "Restrictions Clinic Staff",
            placeholder: "Restrictions Clinic Staff",
            className: "form-control form-control-solid",
            name: "activity_restrictions_clinic_staff",
          },
        ]
      },
      {
        title: "Activity - Billing CPTs or No Charge",
        data: [
          {
            type: "SelectSearchList",
            options: CptCodeOptions,
            label: "CPT Code 1",
            placeholder: "CPT Code 1",
            className: "form-control form-control-solid",
            name: "activity_cpt_code_one",
          },
          {
            type: "Number",
            label: "CPT Code 1 Qty",
            placeholder: "CPT Code 1 Qty",
            className: "form-control form-control-solid",
            name: "activity_cpt_code_one_qty",
          },
          {
            type: "TextInput",
            label: "CPT Code 1 Modifier",
            placeholder: "CPT Code 1 Modifier",
            className: "form-control form-control-solid",
            name: "activity_cpt_code_one_modifier",
          },
          {
            type: "SelectSearchList",
            options: CptCodeOptions,
            label: "CPT Code 2",
            placeholder: "CPT Code 2",
            className: "form-control form-control-solid",
            name: "activity_cpt_code_two",
          },
          {
            type: "Number",
            label: "CPT Code 2 Qty",
            placeholder: "CPT Code 2 Qty",
            className: "form-control form-control-solid",
            name: "activity_cpt_code_two_qty",
          },
          {
            type: "TextInput",
            label: "CPT Code 2 Modifier",
            placeholder: "CPT Code 2 Modifier",
            className: "form-control form-control-solid",
            name: "activity_cpt_code_two_modifier",
          },
          {
            type: "SelectSearchList",
            options: CptCodeOptions,
            label: "CPT Code 3",
            placeholder: "CPT Code 3",
            className: "form-control form-control-solid",
            name: "activity_cpt_code_three",
          },
          {
            type: "Number",
            label: "CPT Code 3 Qty",
            placeholder: "CPT Code 3 Qty",
            className: "form-control form-control-solid",
            name: "activity_cpt_code_three_qty",
          },
          {
            type: "TextInput",
            label: "CPT Code 3 Modifier",
            placeholder: "CPT Code 3 Modifier",
            className: "form-control form-control-solid",
            name: "activity_cpt_code_three_modifier",
          },
          {
            type: "SelectSearchList",
            options: CptCodeOptions,
            label: "CPT Code 4",
            placeholder: "CPT Code 4",
            className: "form-control form-control-solid",
            name: "activity_cpt_code_four",
          },
          {
            type: "Number",
            label: "CPT Code 4 Qty",
            placeholder: "CPT Code 4 Qty",
            className: "form-control form-control-solid",
            name: "activity_cpt_code_four_qty",
          },
          {
            type: "TextInput",
            label: "CPT Code 4 Modifier",
            placeholder: "CPT Code 4 Modifier",
            className: "form-control form-control-solid",
            name: "activity_cpt_code_four_modifier",
          },
          {
            type: "YesOrNo",
            label: "No Charge",
            placeholder: "No Charge",
            className: "form-control form-control-solid",
            name: "activity_no_charge",
          },
        ]
      },
      {
        title: "Activity - Other",
        data: [
          {
            type: "SelectList",
            options: [
              { label: '-Select-', value: '' },
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ],
            label: "Appeal if Denied",
            placeholder: "Appeal if Denied",
            className: "form-control form-control-solid",
            name: "activity_no_appeal_if_denied",
            required: true,
          },
          {
            type: "SelectList",
            options: BillingServiceTypeOptions,
            label: "Billing Service Type",
            placeholder: "Billing Service Type",
            className: "form-control form-control-solid",
            name: "activity_billing_service_type",
            required: true,
          },
        ]

      },
    ];
    return inputFields;
  }
};
export default withReduxState(WithRoutify(AddActivities));
