import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { TaxIdFormatter, getBase64String, getOrganizationId, isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import ClinicService from "../../services/ClinicService";
import { Provider, useSelector } from "react-redux";
import withReduxState from "../../hoc/wReduxState";
import WithRoutify from "../../hoc/WithRoutify";
import { Button } from "react-bootstrap";
import OrganizationService from "../../services/OrganizationService";
import { organizationList } from "../../store/OrganizationSlice";
import DropDownService from "../../services/DropDownService";

const AddClinic = (props) => {
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const { isOpen, modelTitle, activeEditId, Toast, addFormHandler, fetchClinicList,Loader} = props;
  const stateList = useSelector((state) => state.session.profileStateList);
  const [lab, setLabs] = useState([]);
  const [provider, setProvider] = useState([]);
  const inputFields = getInputFields();
  const organizationListOptions = useSelector(organizationList);
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [copyFromOrganization,setCopyFromOrganinization]=useState(false);
  useEffect(() => {
    //intial render
    
    fetchClinic();
  }, []);
  const fetchClinic = async () => {
    let updateFormData={};
    if (activeEditId) {
      Loader(true);
      const response = await ClinicService.getClinic(activeEditId);
      // Loader(false);
      updateFormData={...response};
      let assigned_providers = response['providers'];
      let assigned_labs = response['labs'];
      let lab_mapping = isSet(assigned_labs, []).map(itm => itm.lab_id);
      let provider_mapping = isSet(assigned_providers, []).map(itm => itm.provider_id);
      updateFormData['assigned_providers'] = provider_mapping.join(',');
      updateFormData['assigned_labs'] = lab_mapping.join(',');
      updateFormData['organization'] = response.organization_uuid;
      // updateFormData['copy_from_organization'] = isCopyFromOrganizationChecked(updateFormData);        
    }
    if(isSet(props.profile.role) && props.profile.role !== 'global_admin'){
      updateFormData['organization']=props.profile.organization_id;
    }

    setFormData(updateFormData);
  }
  //global admin org opt changes
  useEffect(() => {
    fetchLab();
    fetchProviders();
  }, [formData.organization]);
  useEffect(()=>{
    let org_id = isSet(formData.organization, OrganizationService.getOrgId());

    OrganizationService.getOrganizationById(org_id)
    .then(res=>{
      setOrganizationDetails(res);
    })

  },[formData.organization]);
  //fetch lab 
  const fetchLab = async () => {
    let org_id = isSet(formData.organization, OrganizationService.getOrgId());
    let options = [];
    let lab = await DropDownService.getLabsList(org_id);
    if(lab){
      lab?.forEach((itm) => {
        options.push({ label: itm.name, value: itm.lab_id });
      });
    }
    
    setLabs(options);
  };
  

  //fetch provider
  const fetchProviders = async () => {
    let org_id = isSet(formData.organization, OrganizationService.getOrgId());
    let provider = await DropDownService.getProvidersDropdownList(org_id);
    let options = [];
    if (provider) {
      provider?.forEach((itm) => {
        options.push({ label: itm.first_name + ' ' + itm.last_name, value: itm.provider_id });
      });
    }
    setProvider(options);
    Loader(false);
  }
  //change handler
  const changeHandler = async (value, fieldName, {addressParam={},selectVal}) => {
    // console.log(value+' -------- '+fieldName +' --------- '+ selectVal);
    const updateFormData = { ...formData };
    const updateValidation = { ...validation };
    //copy from organization
    if (fieldName == "copy_from_organization"  ) {
      if(!isSet(formData.organization) ){
        if (isSet(formData.organization, false) == false) {
              setValidation({
                ...updateValidation,
                ["organization"]: "Organization Required to Copy Address",
              });
              return false;
            }
      }
      Loader(true);
      setTimeout(() => {
        Loader(false)
      }, 500);
      
      //if value true
      if (value) {
        //get org id
        // console.log('organization details--->',organizationDetails);
        updateFormData["address"] = isSet(organizationDetails.address, "");
        updateValidation["address"] = "";
        updateFormData["city"] = isSet(organizationDetails.city, "");
        updateValidation["city"] = "";
        updateFormData["state"] = isSet(organizationDetails.state, "");
        updateValidation["state"] = "";
        updateFormData["zip_plus_4"] = isSet(organizationDetails.zip_plus_4, "");
        updateValidation["zip_plus_4"] = "";
        updateFormData["county"] = isSet(organizationDetails.county, "");
        updateValidation["county"] = "";

        updateFormData["federal_tax_id"] = isSet(organizationDetails.federal_id, "");
        updateValidation["federal_tax_id"] = "";
        updateFormData["group_npi"] = isSet(organizationDetails.group_npi, "");
        updateValidation["group_npi"] = "";
        updateFormData["clia"] = isSet(organizationDetails.clia, "");
        updateValidation["clia"] = "";
        updateFormData["medicare_id"] = isSet(organizationDetails.medicare_group_id, "");
        updateValidation["medicare_id"] = "";
        updateFormData["medicaid_id"] = isSet(organizationDetails.medicaid_group_id, "");
        updateValidation["medicaid_id"] = "";
        updateFormData["dol_id"] = isSet(organizationDetails.dol_id, "");
        updateValidation["dol_id"] = "";
        
      }
      //else remove value
      else {
        
        updateFormData["address"] = "";
        updateFormData["city"] = "";
        updateFormData["state"] = "";
        updateFormData["zip_plus_4"] = "";
        updateFormData["county"] = "";
        updateFormData["federal_tax_id"] = "";  
        updateFormData["group_npi"] ="";  
        updateFormData["clia"] = "";
        updateFormData["medicare_id"] =  "";
        updateFormData["medicaid_id"] = "";
        updateFormData["dol_id"] =  "";
      }
      setCopyFromOrganinization(!copyFromOrganization);
    }
    //if feild is address dependency feild
    if (fieldName === "address") {
      if (isSet(addressParam.street, false)) {
        updateFormData["street"] = addressParam.street;
        updateValidation["street"] = "";
      }
      if (isSet(addressParam.state, false)) {
        updateFormData["state"] = addressParam.state;
        updateValidation["state"] = "";
      }
      if (isSet(addressParam.zipcode, false)) {
        updateFormData["zip_plus_4"] = addressParam.zipcode;
        updateValidation["zip_plus_4"] = "";
      }
      if (isSet(addressParam.city, false)) {
        updateFormData["city"] = addressParam.city;
        updateValidation["city"] = "";
      }
      if (isSet(addressParam.country, false)) {
        updateFormData["county"] = addressParam.country;
        updateValidation["county"] = "";
      }
    }
    //if file (convert to base64)
    if (fieldName === 'business_license' || fieldName === 'clia_certificate') {
      if (value) {
        const { base64WithoutPrefix, fileName } = await getBase64String(value);
        let name = `${fieldName}_original_name`
        updateFormData[name] = fileName;
        value = base64WithoutPrefix;
      }
      else {
        updateFormData[`${fieldName}_original_name`] = '';
      }

    }
    //assigned Clinic provider 
    if (fieldName == 'assigned_providers'||fieldName =='assigned_labs') {
      if (value) {
        let data;
        if (typeof formData[fieldName] === 'string' && formData[fieldName] != '') {
          data = formData[fieldName].split(',');
        } else {
          data = [];
        }
        data.push(selectVal);
        data = data.join(',');
        value = data;

      }
      else {
        let data = formData[fieldName].split(',');
        let index = data.indexOf(selectVal);
        if (index !== -1) {
          data.splice(index, 1);
        }
        value = data.join(',');
      }
    }
    if (fieldName=='federal_tax_id') {
      value=TaxIdFormatter(value);
    }
    updateFormData[fieldName] = value;
    updateValidation[fieldName] = "";
    setFormData(updateFormData);
    setValidation(updateValidation);
  };

  //submit form
  const formSubmitHandler = async () => {
    let updateValidation = { ...validation };
    updateValidation.code = "";
    const { activeEditId } = props;
    //check if global admin
    // if (
    //   isSet(props.profile.is_multiple_organization, false) === true &&
    //   organizationListOptions.length > 2 &&!activeEditId
    // ) {
    //   //check organization filled if no throwing validation
    //   if (isSet(formData.organization, false) == false) {
    //     setValidation({
    //       ...updateValidation,
    //       ["organization"]: "The Organization Field is Required",
    //     });
    //     return false;
    //   }
    // }
    
    let payload = { ...formData };
    payload.baseUrl = process.env.REACT_APP_BASEURL;
    //`${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    //update or save
    Loader(true);
    let data;
    if (activeEditId) {
      data = await ClinicService.updateClinic(props.activeEditId, payload);
    } else {
      data = await ClinicService.saveClinic(isSet(formData.organization, null), payload);
    }
    Loader(false);
    if (data.status && data.status == "ERROR") {
      if (isObject(data.data)) {
        for (let key in data.data) {
          updateValidation = {
            ...updateValidation,
            [key]: data.data[key].join(","),
          };
        }
      } else {
        Toast.error(data.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    } else {
      fetchClinicList();
      addFormHandler();
      Toast.success(`Clinic ${activeEditId ? 'Updated' : 'Added'} Successfully`);
    }
  };
  const isCopyFromOrganizationChecked=(formData)=>{
    const keyMapping = {
      'federal_tax_id': 'federal_id',
      'medicare_id': 'medicare_group_id',
      'medicaid_id': 'medicaid_group_id'
    };

  // Define the fields to check with the original formData keys
    const fieldsToCheck = [
      'address', 'city', 'state', 'zip_plus_4', 'county',
      'federal_tax_id', 'group_npi', 'clia', 'medicare_id',
      'medicaid_id', 'dol_id'
    ];
    // const fieldsToCheck = ['address', 'city', 'state', 'zip_plus_4', 'county','federal_tax_id','group_npi','clia','medicare_id','medicaid_id','dol_id'];
    // let isAllsame= isSet(activeEditId) ? fieldsToCheck.every(field => formData[field] == organizationDetails[field]) : false;
    let isAllsame = isSet(activeEditId) ? fieldsToCheck.every(field => {
      const orgDetailKey = keyMapping[field] || field;
      return formData[field] === organizationDetails[orgDetailKey];
    }) : false;
    return isAllsame;
  }
  return (
    <>
      <Modal
        show={isOpen}
        onHide={addFormHandler}
        size="xl"
        dialogClassName="hResponsive "
        contentClassName="h-100"
        className=""
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">{modelTitle}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card-body tab-content p-0">
              {
                isSet(props.profile.is_multiple_organization, false) === true &&
                organizationListOptions.length > 2 && !activeEditId?
                  (
                    <>
                      <div className="card mb-2">
                        <a
                          href={`#collapseCardExample_address`}
                          className="d-block sh_accordionheader card-header"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="true"
                          aria-controls="collapseCardExample"
                          style={{
                            position: "sticky",
                            top: "-15px",
                            zIndex: "1",
                          }}
                        >
                          <h6 className="m-0 text-md">{"Organization Details"}</h6>
                        </a>
                        <div
                          className="collapse show"
                          id={`collapseCardExample_address`}
                          data-bs-parent="#modal111"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-4 col-md-6 col-12">
                                <div className="form-group">
                                  <label className="f-400 text-sm text-truncate mb-0">
                                    Organization
                                  </label>
                                  <div>
                                    <FormInputs
                                      type="SelectList"
                                      label="Organization"
                                      options={organizationListOptions}
                                      placeholder=""
                                      className="form-control form-control-solid"
                                      name="organization"
                                      value={isSet(formData.organization)}
                                      required={true}
                                      changeHandler={(value, e) => changeHandler(value, 'organization', e)}
                                    />
                                    {isSet(validation['organization'], "") !== "" ? (<span className="text-danger m-1"> {validation['organization']}</span>) : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) :
                  ''
              }
              <div className="card mb-2">
                <a
                  href={`#collapseCardExample_address`}
                  className="d-block sh_accordionheader card-header"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="true"
                  aria-controls="collapseCardExample"
                  style={{
                    position: "sticky",
                    top: "-15px",
                    zIndex: "1",
                  }}
                >
                  <h6 className="m-0 text-md">{"Clinic - Address & Contact"}</h6>
                </a>
                <div
                  className="collapse show"
                  id={`collapseCardExample_address`}
                  data-bs-parent="#modal111"
                >
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="form-group">
                          <label class="f-400 text-sm text-truncate mb-0">
                            Code
                          </label>
                          <div>
                            <FormInputs
                              type="TextInput"
                              placeholder="Code"
                              className="form-control form-control-solid"
                              name="code"
                              value={formData["code"] || ""}
                              required={true}
                              max={5}
                              allUpperCase={true}
                              changeHandler={(value, e) => changeHandler(value, 'code', e)}
                            />
                            {isSet(validation['code'], "") !== "" ? (<span className="text-danger m-1"> {validation['code']}</span>)
                              :
                              ""
                            }
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="form-group">
                          <label class="f-400 text-sm text-truncate mb-0">
                            Name
                          </label>
                          <div>
                            <FormInputs
                              type="TextInput"
                              placeholder="Name"
                              className="form-control form-control-solid"
                              name="name"
                              value={formData['name'] || ""}
                              required={true}
                              isFirstLetterCaps={true}
                              changeHandler={(value, e) => changeHandler(value, 'name', e)}
                            />
                            {isSet(validation['name'], "") !== "" ? (<span className="text-danger m-1"> {validation['name']}</span>)
                              :
                              ""
                            }
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="form-group">
                          <label class="f-400 text-sm text-truncate mb-0">
                            Location
                          </label>
                          <div>
                            <FormInputs
                              type="TextInput"
                              placeholder="Location"
                              className="form-control form-control-solid"
                              name="location"
                              value={formData['location'] || ""}
                              required={true}
                              isFirstLetterCaps={true}
                              changeHandler={(value, e) => changeHandler(value, 'location', e)}
                            />
                            {isSet(validation['location'], "") !== "" ? (<span className="text-danger m-1"> {validation['location']}</span>)
                              :
                              ""
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <br/>
                        <label className="m-0 text-md">
                          <FormInputs
                            type="Checkbox"
                            name='copy_from_organization'
                            value={activeEditId ? isCopyFromOrganizationChecked(formData) : copyFromOrganization}
                            changeHandler={(value, e) => changeHandler(value, 'copy_from_organization', e)}
                            className="mr-1"
                          />
                          Copy same Address from Organization
                          <div></div>
                        </label>
                        <br/>
                      </div>

                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="form-group">
                          <label class="f-400 text-sm text-truncate mb-0">
                            Address
                          </label>
                          <div>
                            <div className="">
                              <FormInputs
                                type="Address"
                                placeholder="Address"
                                className="form-control form-control-solid"
                                name="address"
                                value={formData['address'] || ""}
                                required={true}
                                isFirstLetterCaps={true}
                                changeHandler={(value, e) => changeHandler(value, 'address', e)}
                                disabled={activeEditId ? isCopyFromOrganizationChecked(formData) : copyFromOrganization}
                              />
                              {isSet(validation['address'], "") !== "" ? (<span className="text-danger m-1"> {validation['address']}</span>)
                                :
                                ""
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="form-group">
                          <label class="f-400 text-sm text-truncate mb-0">
                            City
                          </label>
                          <div>
                            <FormInputs
                              type="TextInput"
                              placeholder="City"
                              className="form-control form-control-solid"
                              name="city"
                              value={formData['city'] || ""}
                              required={true}
                              isFirstLetterCaps={true}
                              changeHandler={(value, e) => changeHandler(value, 'city', e)}
                              disabled={activeEditId ? isCopyFromOrganizationChecked(formData) : copyFromOrganization}
                            />
                            {isSet(validation['city'], "") !== "" ? (<span className="text-danger m-1"> {validation['city']}</span>)
                              :
                              ""
                            }

                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="form-group">
                          <label class="f-400 text-sm text-truncate mb-0">
                            State
                          </label>
                          <div>
                            <FormInputs
                              type="SelectList"
                              label="State"
                              options={stateList}
                              placeholder="State"
                              className="form-control form-control-solid"
                              name="state"
                              value={formData["state"] || ""}
                              required={true}
                              changeHandler={(value, e) => changeHandler(value, 'state', e)}
                              disabled={activeEditId ? isCopyFromOrganizationChecked(formData) : copyFromOrganization}
                            />
                            {isSet(validation['state'], "") !== "" ? (<span className="text-danger m-1"> {validation['state']}</span>)
                              :
                              ""
                            }

                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="form-group">
                          <label class="f-400 text-sm text-truncate mb-0">
                            Zip+4
                          </label>
                          <div>
                            <FormInputs
                              type="ZipCode"
                              label="Zip+4"
                              placeholder="Zip+4"
                              className="form-control form-control-solid"
                              name="zip_plus_4"
                              value={formData['zip_plus_4'] || ""}
                              required={true}
                              max={9}
                              changeHandler={(value, e) => changeHandler(value, 'zip_plus_4', e)}
                              disabled={activeEditId ? isCopyFromOrganizationChecked(formData) : copyFromOrganization}
                            />
                            {isSet(validation['zip_plus_4'], "") !== "" ? (<span className="text-danger m-1"> {validation['zip_plus_4']}</span>)
                              :
                              ""
                            }

                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="form-group">
                          <label class="f-400 text-sm text-truncate mb-0">
                            County
                          </label>
                          <div>
                            <FormInputs
                              type="TextInput"
                              label="County"
                              placeholder="County"
                              className="form-control form-control-solid"
                              name="county"
                              value={formData['county'] || ""}
                              required={true}
                              isFirstLetterCaps={true}
                              changeHandler={(value, e) => changeHandler(value, 'county', e)}
                              disabled={activeEditId ? isCopyFromOrganizationChecked(formData) : copyFromOrganization}
                            />
                            {isSet(validation['county'], "") !== "" ? (<span className="text-danger m-1"> {validation['county']}</span>)
                              :
                              ""
                            }

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="form-group">
                          <label class="f-400 text-sm text-truncate mb-0">
                            Phone
                          </label>
                          <div>
                            <FormInputs
                              type="Phone"
                              label="Phone"
                              placeholder="Phone"
                              className="form-control form-control-solid"
                              name="phone"
                              value={formData['phone'] || ""}
                              required={true}
                              changeHandler={(value, e) => changeHandler(value, 'phone', e)}
                            />
                            {isSet(validation['phone'], "") !== "" ? (<span className="text-danger m-1"> {validation['phone']}</span>)
                              :
                              ""
                            }

                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-6 col-12">
                        <div class="form-group">
                          <label class="f-400 text-sm text-truncate mb-0">
                            Fax
                          </label>
                          <div>
                            <FormInputs
                              type="Phone"
                              label="Fax"
                              placeholder="Fax"
                              className="form-control form-control-solid"
                              name="fax"
                              value={formData['fax'] || ""}
                              max={10}
                              isFirstLetterCaps={true}
                              changeHandler={(value, e) => changeHandler(value, 'fax', e)}
                            />
                            {isSet(validation['fax'], "") !== "" ? (<span className="text-danger m-1"> {validation['fax']}</span>)
                              :
                              ""
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {inputFields &&
                inputFields.map((section, sectionIndex) => {
                  return (
                    <>
                      <div className="card mb-2">
                        <a
                          href={`#collapseCardExample_${sectionIndex}`}
                          className="d-block sh_accordionheader card-header"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="true"
                          aria-controls="collapseCardExample"
                          style={{
                            position: "sticky",
                            top: "-15px",
                            zIndex: "1",
                          }}
                        >
                          <h6 className="m-0 text-md">
                            {section.title}
                          </h6>
                        </a>
                        <div
                          className="collapse show"
                          id={`collapseCardExample_${sectionIndex}`}
                          data-bs-parent="#modal111"
                        >
                          <div className="card-body">
                            <div className="row">
                              {section.data.map((data, dataInd) => {
                                return (
                                  <>

                                    <div className={`${data.col ? data.col : "col-lg-4 col-md-6 col-12"}`}>
                                      <div class="form-group">
                                        <label class="f-400 text-sm text-truncate mb-0">
                                          {data.label}
                                        </label>
                                        <div className={`${data.type == "MultiSelectDropDown" ? 'd-flex row' : ''}`} key={dataInd}>
                                          <FormInputs
                                          value={formData[data.name]}
                                            {...data}
                                            changeHandler={(value, e) =>
                                              changeHandler(value, data.name, e)
                                            }
                                          />
                                          {isSet(validation[data.name], "") !==
                                            "" ? (
                                            <span className="text-danger m-1">
                                              {isSet(validation[data.name], "")}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="primary"
              className=""
              onClick={() => formSubmitHandler()}
            >
              {`${activeEditId ? 'Update' : 'Save'} `}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  function getInputFields() {
    const inputFields = [
      {
        title: "Clinic - ID's & Certificates",
        data: [
          {
            type: "Number",
            label: "Federal ID",
            placeholder: "Federal ID",
            className: "form-control form-control-solid",
            name: "federal_tax_id",
            required: true,
            max: 9,
            // disabled:activeEditId ? isCopyFromOrganizationChecked(formData) : copyFromOrganization,
          },
          {
            type: "Number",
            label: "Group NPI",
            placeholder: "Group NPI",
            className: "form-control form-control-solid",
            name: "group_npi",
            required: true,
            max: 10,
            // disabled:activeEditId ? isCopyFromOrganizationChecked(formData) : copyFromOrganization,
          },
          {
            type: "TextInput",
            label: "CLIA",
            placeholder: "CLIA",
            className: "form-control form-control-solid",
            name: "clia",
            max: 10,
            // disabled:activeEditId ? isCopyFromOrganizationChecked(formData) : copyFromOrganization,
          },
          {
            type: "TextInput",
            label: "Medicare ID",
            placeholder: "Medicare ID",
            className: "form-control form-control-solid",
            name: "medicare_id",
            max: 6,
            // disabled:activeEditId ? isCopyFromOrganizationChecked(formData) : copyFromOrganization,
          },
          {
            type: "TextInput",
            label: "Medicaid ID",
            placeholder: "Medicaid ID",
            className: "form-control form-control-solid",
            name: "medicaid_id",
            // disabled:activeEditId ? isCopyFromOrganizationChecked(formData) : copyFromOrganization,
          },
          {
            type: "TextInput",
            label: "DOL ID",
            placeholder: "DOL ID",
            className: "form-control form-control-solid",
            name: "dol_id",
            max: 9,
            // disabled:activeEditId ? isCopyFromOrganizationChecked(formData) : copyFromOrganization,
          },
        ],
      },
      {
        title: "Clinic - Notifications Emails",
        data: [
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) Emp Services",
            placeholder: "Notification Email(s) Emp Services",
            className: "form-control form-control-solid",
            name: "notification_emails_emp_services",
          },
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) Provider Charting",
            placeholder: "Notification Email(s) Provider Charting",
            className: "form-control form-control-solid",
            name: "notification_emails_provider_charting",
          },
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) Paid Invoices",
            placeholder: "Notification Email(s) Paid Invoices",
            className: "form-control form-control-solid",
            name: "notification_emails_paid_invoices",
          },
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) Management",
            placeholder: "Notification Email(s) Management",
            className: "form-control form-control-solid",
            name: "notification_emails_management",
          },
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) All Employees",
            placeholder: "Notification Email(s) All Employees",
            className: "form-control form-control-solid",
            name: "notification_emails_all_employees",
          },
        ],
      },
      {
        title: "Clinic - Assigned Labs",
        data: [
          {
            col: 'col-12',
            type: "MultiSelectDropDown",
            options: lab,
            label: "Assigned Labs",
            placeholder: "Assigned Labs",
            className: "form-control form-control-solid",
            name: "assigned_labs",
            ddKey:'assigned_labs',
          },
        ],
      },
      {
        title: "Clinic - Assigned Providers",
        data: [
          {
            col: 'col-12',
            type: "MultiSelectDropDown",
            options: provider,
            label: "Assigned Providers",
            placeholder: "Assigned Providers",
            className: "form-control form-control-solid",
            name: "assigned_providers",
            ddKey:'assigned_providers'
          },
        ],
      },
      {
        title: "Clinic - Images",
        data: [
          {
            type: "ImageUpload",
            label: "Business License",
            placeholder: "Business License",
            className: "form-control form-control-solid",
            name: "business_license",
            accept: "image/png, image/gif, image/jpeg",
            value: {
              original_name: formData['business_license_original_name'],
              base64: formData['business_license']
            },
          },
          {
            type: "ImageUpload",
            label: "CLIA Certificate",
            placeholder: "CLIA Certificate",
            className: "form-control form-control-solid",
            name: "clia_certificate",
            accept: "image/png, image/gif, image/jpeg",
            value: {
              original_name: formData['clia_certificate_original_name'],
              base64: formData['clia_certificate']
            },
          },
        ],
      },
    ];
    // if (
    //   isSet(props.profile.is_multiple_organization, false) === true &&
    //   props.organizationOption.length > 2
    // ) {
    //   inputFields.unshift({
    //     title: "Organization Details",
    //     data: [
    //       {
    //         type: "SelectList",
    //         label: "Organization",
    //         options: props.organizationOption,
    //         placeholder: "",
    //         className: "form-control form-control-solid",
    //         name: "organization",
    //         value: formData.organization,
    //         required: true,
    //       },
    //     ],
    //   });
    // }
    return inputFields;
  }
};
export default withReduxState(WithRoutify(AddClinic));
