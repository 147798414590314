import React, { Component } from "react";
import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";
import OrganizationService from "../../services/OrganizationService";
import {toQueryString, formatPhoneNumber, isSet } from "../../utils/commonUtils";
import AddOrganization from "./AddOrganization";
import WithRoutify from "../../hoc/WithRoutify";
// import { OK } from "../../utils/constant";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import { activeProfileDetails, setProfileDetails } from "../../store/SessionSlice";
import { connect } from 'react-redux';
import WithRouter from "../../hoc/WithRouter";
import { fetchGlobalOrganization } from "../../store/OrganizationSlice";
import TableSkeleton from "../../hoc/TableSkeleton";
import { withTranslation } from 'react-i18next';
// import { getCurrentLanguage } from "../../utils/i18nUtils";
import i18n from "../../i18n/config";
import { acl_check } from "../../utils/aclUtils";


class OrganizationList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    // const {t} = props;
    const {t} = props;
    const tableHeaders = [
      { label: "", key: "isActive", type: "checkBox",changeHandler:this.checkBoxchangeHandler},
      { label: t('Organization'), key: "name", inputType: "TextInput",sortName:'name'},
      { label: t("Email"), key: "email", inputType: "TextInput",sortName:'email' },
      { label: t("CellNumber"), key: "phone_number", inputType: "Phone",sortName:'phone_number'},
      { label: t("Address"), key: "address", inputType: "TextInput",sortName:'address'},
      { label: t("Street"), key: "street",  inputType: "TextInput",sortName:'street'},
      { label: t("City"), key: "city", inputType: "TextInput",sortName:'city'},
      { label: t("State"), key: "state", inputType: "TextInput",sortName:'state'},
      { label: t("Action"), key: "action", type: "Actions"},
      // Add more columns as needed
    ];
    this.state = {
      filters: {
        'search_query':''
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          status:1000,
          data: [],
        },
        pagination:{
          page:1,
          perPage:25,
          totalRecords:0,
          sort_by:'name',
          sort_order:'asc',
          search:{},
        }
      },
      formDataInEdit: {},
      activeEditId: null,
      // Your state variables go here
      isModelOpen: false,
    };
  }

  componentDidMount() {
    this.fetchOrganizationList();

    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  render() {
    const { tableRecords, filters, isModelOpen,activeEditId } = this.state;
    const {t} = this.props;
    return (
      <>
        {isModelOpen ? (
          <AddOrganization
            modelTitle={`${this.state.activeEditId ? t('ViewandUpdate') : t('Add')} ${t('Organization')}`}
            isOpen={this.state.isModelOpen}
            fetchOrganizationList={this.fetchOrganizationList}
            addFormHandler={this.addFormHandler}
            activeEditId={activeEditId}
          />
        ) : (
          <></>
        )}
        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"organization-create"}
          content={"Organization"}
        >
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group app-heading-tab d-flex mb-0 justify-content-between align-items-center">
                <h6 class="text-lg f-500 mb-0">{t('OrganizationList')} </h6>

                <div className="addAction d-flex">
                  <div className="form-group-fields mr-2 d-flex">
                    <div className="input-group input-group-joined input-group-solid mr-2">
                      <FormInputs
                        placeholder={t("Organizations")}
                        value={filters["search_query"]}
                        changeHandler={(val) =>
                          this.onChangeFiltersHandler("search_query", val)
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="btn-group">
                      <button
                        className="btn btn-primary"
                        onClick={() => this.submitFiltersHandler()}
                      >
                        {t('Search')}
                      </button>
                      <button
                        className="btn btn-outline-secondary"
                        onClick={() => this.clearFiltersHandler()}
                      >
                        {t('Clear')}
                      </button>
                    </div>
                  </div>

                  <div className="btn-group">
                    <Buttons
                      clickHandler={() => { this.addFormHandler(); this.setState({ activeEditId: '' }) }}
                      className="btn btn-primary"
                      acl={"organization-create"}
                      label={t("Add")}
                    />
                    <button
                      className="btn btn-outline-secondary dropdown no-arrow"
                      data-bs-toggle="dropdown"
                    >
                      <span className="dropdown-toggle">
                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                      </span>
                      <div
                        className="dropdown-menu dropdown-menu-right shadow"
                        aria-labelledby="userDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Group Edit
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Grid Edit
                        </a>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                addFormHandler={() => this.addFormHandler()}
                addFormAcl=""
                hasPagination={true}
                fetchTableRecords={(filters) =>
                  this.fetchOrganizationList(filters)
                }
                {...tableRecords}
              />
            </div>
          </div>
        </TableSkeleton>
      </>
    );
  }
  submitFiltersHandler = () => {
    const { filters } = this.state;
    const {pagination}=this.state.tableRecords
    const queryfilters = {
      ...pagination,
      page:1,
      search:filters,
    };
    this.fetchOrganizationList(queryfilters);
  };
  clearFiltersHandler = () => {
    this.setState({
      filters: {
        name: "",
      },
    },this.submitFiltersHandler);
  };
  fetchOrganizationList = async (query={}) => {
    const {tableRecords} = this.state;
    const queryString = toQueryString({...tableRecords.pagination,...query});
    this.props.Loader(true);
    OrganizationService.getOrganization(queryString)
      .then((res) => {
        let tableData = [];
        // const hyperLink={href:'/',title:' Organization detailed view'};
        res.data.data.forEach((org, orgIndex) => {
          tableData = [
            ...tableData,
            {
              orgIndex: orgIndex,
              isHeading: false,
              rowId: org.organization_uuid,
              data: {
                isActive: false,
                description: org.description,
                name: org.name,
                email:org.email,
                phone_number:formatPhoneNumber(org.phone_number),
                address:org.address,
                city:org.city,
                state:org.state,
                street:org.street,
                zip_code:org.zip_code,
                code:org.code,
                zip_plus_4:org.zip_plus_4,
                federal_id:org.federal_id,
                group_npi:org.group_npi,
                remit_address:org.remit_address,
                remit_city:org.remit_city,
                remit_state:org.remit_state,
                remit_zip_plus_4:org.remit_zip_plus_4,
                active: "Yes",
                action: [
                  {
                    className:
                      "btn btn-icon btn-transparent-dark",
                    iconType: "Search",
                    title: "View",
                    type: "GridEdit",
                    acl: "organization-edit",
                    clickHandler: (rowId, data) =>
                      this.editHandler(rowId, data),
                  },
                  {
                    className:
                      "btn btn-icon btn-transparent-dark",
                    iconType: "Remove",
                    acl: "organization-delete",
                    title: "Delete",
                    isDelete:true,
                    clickHandler: () =>
                      this.deleteHandler(org.organization_uuid),
                  }, 
                  {
                    className:
                      "btn btn-icon btn-transparent-dark",
                    iconType: "LoginIcon",
                    acl: "organization-list",
                    title: "Login",
                    clickHandler: () =>this.drillingLoginHandler(org.organization_uuid),
                  }, 
                ],
              },
            },
          ];
        });
       

        this.setState((prevState) => ({
            tableRecords: {
                ...prevState.tableRecords,
                tableHeaders: prevState.tableRecords.tableHeaders,
                tableRows: {
                    status:200,
                    type: "default",
                    data: tableData,
                },
                pagination: {
                    ...prevState.tableRecords.pagination,
                    ...query,
                    totalRecords:res?.data?.total
                },
            },
        }));
      })
      .catch((err) => {
        // console.log("err===>", err);
      })
      .finally(() => {
        this.props.Loader(false);
      });
  };

  drillingLoginHandler = async (id)=>{
    const {Loader,Toast,navigate} = this.props;
    Loader(true);
    try{
      const userData    = await AuthService.impersonateLogin(id);
      if(userData.status && userData.status=='ERROR'){
        // Loader(false);
        // setValidation(isSet(userData.data.error,'Authentication failed')+'  The information you have provided cannot be authenticated. Check your login information and try again');
      }
      const isAuthenticated = AuthService.isAuthenticated();
      if (isAuthenticated === true) {
        const accessToken = AuthService.getToken();
        const profileData = await UserService.getUserProfile();
        AuthService.storeProfileId(profileData.id);
        i18n.changeLanguage(profileData.preferred_language);
        AuthService.storePrefLanguage(profileData.preferred_language);
        // localStorage.setItem("apiPrefix",`organizations/${id}`);
        OrganizationService.storeOrgId(id);
        OrganizationService.storeTimeZone(id);
        this.props.setProfileDetails({ key: profileData.id, value: {...profileData,accessToken:accessToken} });
        
        Loader(false);
        Toast.success(profileData.name+' successfully logged-in')
        
        const {permissions,is_multiple_organization} = profileData;
        let redirectUrl = '/dashboard';
        if(isSet(is_multiple_organization,false)===true && acl_check(permissions,'organization-list')===true){
          redirectUrl = '/organisation';
        }else if(acl_check(permissions,'appointment-list')===true){
            redirectUrl = '/appointments';
        }else if(acl_check(permissions,'company-list')===true){
            redirectUrl = '/company';
        }else if(acl_check(permissions,'clinic-list')===true){
            redirectUrl = '/clinics';
        }else if(acl_check(permissions,'employee-list')===true){
            redirectUrl = '/employee';
        }else if(acl_check(permissions,'visits-list')===true){
            redirectUrl = '/visits';
        }else if(acl_check(permissions,'lab-list')===true){
            redirectUrl = '/labs';
        }else if(acl_check(permissions,'user-list')===true){
            redirectUrl = '/users';
        }else if(acl_check(permissions,'role-list')===true){
            redirectUrl = '/roles';
        }

        navigate(redirectUrl);
        
      }
    }catch(e){
      // showToast('error',"An error occurred");
      Loader(false);
    }
    
  }

  deleteHandler = async (orgId) => {
    this.props.Loader(true);

   await OrganizationService.deleteOrganization(orgId)
      .then((res) => {
        this.fetchOrganizationList();
        this.props.Toast.success("Organization Deleted Successfully");
        this.props.fetchGlobalOrganization(this.props.profileDetails);
      })
      .catch((err) => {
        //
        this.props.Loader(false);

      })
      .finally(() => {
        // this.props.setProfileDetails(uuidv4());
      });
  };
  editHandler = (rowId, data) => {
       this.setState({
      activeEditId: rowId,
      formDataInEdit: data,
    },this.addFormHandler());
  };
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click 
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });
  
    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}

const mapDispatchToProps = dispatch => ({
  setProfileDetails: payload => dispatch(setProfileDetails(payload)),
  fetchGlobalOrganization:payload=>dispatch(fetchGlobalOrganization(payload)),
});

const mapStateToProps = (state) => ({
  profileDetails: activeProfileDetails(state), 
});
export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(WithRoutify(withTranslation()(OrganizationList))));

