import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import InsuranceList from "../InsuranceContainer/InsuranceList";
import DivisionList from "./DivisionList";
import AddDivision2 from "./AddDivision2";

const DivisionContainer = () => {

  return (
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<DivisionList/>} />
      </Routes>
    </Wrap>
  );
};






export default DivisionContainer;
