import React, { Component } from "react";
import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";

import WithRoutify from "../../hoc/WithRoutify";
import ConsentFormService from "../../services/ConsentFormService";
import withReduxState from '../../hoc/wReduxState';
import {
  VISIT_CATEGORY,
} from "../../utils/constant";
import { convertUnderScoreStrings, isSet, toQueryString, getLookupByKey } from "../../utils/commonUtils";
import TableSkeleton from "../../hoc/TableSkeleton";
import ClinicService from "../../services/ClinicService";
import AddClinicConsentForm from "./AddClinicConsentForm";
import DropDownService from "../../services/DropDownService";
import ConsentFormHtmlView from "./ConsentFormHtmlView";

class ConsentFormList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      {
        label: "Clinic",
        key: "clinic_name",
        inputType: "TextInput",
        sortName: "clinic",
      },
      {
        label: "Visit Category",
        key: "visit_category",
        inputType: "TextInput",
        sortName: "visit_category",
      },
      {
        label: "Title",
        key: "title",
        inputType: "TextInput",
        sortName: "title",
      },
      {
        label: "Content",
        type: 'Actions',
        key: "content",
      },
      {
        label: "Is Spanish",
        key: "is_spanish",
        inputType: "checkBox",
      },
      {
        label: "Status",
        key: "status",
        inputType: "checkBox",
      },

      { label: "Action", key: "action", type: "Actions" },
      // Add more columns as needed
    ];
    this.state = {
      filters: {
        search_query: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "clinic_name",
          sort_order: "asc",
          search: {},
        },
      },
      isHtmlViewOpen: false,
      htmlViewContent: null,
      formDataInEdit: {},
      activeEditId: null,
      isConsentModelOpen: false,
    };
  }



  componentDidMount() {
    this.fetchOrganization();
    this.fetchClinicList();
    this.fetchVisitCategoryOptions(VISIT_CATEGORY);
    this.fetchClinicConsentFormList();
    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({
      isConsentModelOpen: !this.state.isConsentModelOpen,

    });
  };



  render() {
    const { tableRecords, filters, activeEditId, isConsentModelOpen,isHtmlViewOpen,htmlViewContent } = this.state;
    return (
      <>
        {
          isConsentModelOpen ?
            <AddClinicConsentForm
              isOpen={isConsentModelOpen}
              consentFormHandler={this.consentModelHandler}
              isClinicConsentForm={true}
              clinicDetails={this.state.clinicDetails}
              visitCategoryOptions={this.state.visitCategoryOptions}
              organizationId={this.state.organizationId}
              modelTitle={`${this.state.activeEditId ? 'Edit' : 'Add'} Consent Form`}
              activeEditId={activeEditId}
              isMultipleOrg={this.props.profile.is_multiple_organization}
            />
            :
            (isHtmlViewOpen ? <ConsentFormHtmlView 
                       htmlViewContent={htmlViewContent}
                       htmlViewModal={this.htmlViewModal}
                       isOpen={true}
                             /> 
            : '')
        }

        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"consent-create"}
          content={"Consent Forms"}
        >
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                <h6 class="text-lg f-500 mb-0"> Consent Form List </h6>

                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div className="col-12 d-flex">
                      <div class="input-group input-group-joined input-group-solid">
                        <FormInputs
                          placeholder="Search Consents"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>

                      <div class="btn-group pl-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="addAction">
                    <div className="btn-group">
                      <Buttons
                        clickHandler={() => { this.addFormHandler(); this.setState({ activeEditId: '' }) }}
                        className="btn btn-primary"
                        acl={"consent-create"}
                        label={"Add"}
                      />
                      <button
                        className="btn btn-outline-secondary dropdown no-arrow"
                        data-bs-toggle="dropdown"
                      >
                        <span className="dropdown-toggle">
                          <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </span>
                        <div
                          className="dropdown-menu dropdown-menu-right shadow"
                          aria-labelledby="userDropdown"
                        >
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Group Edit
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Grid Edit
                          </a>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                hasPagination={true}
                fetchTableRecords={(filters) =>
                  this.fetchClinicConsentFormList(filters)
                }
                {...tableRecords}
              />
            </div>
          </div>
        </TableSkeleton>
      </>
    );
  }
  submitFiltersHandler = () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      page:1,
      search: filters,
    };
    this.fetchClinicConsentFormList(queryfilters);
  };
  clearFiltersHandler = () => {
    this.setState(
      {
        filters: {},
      },
      this.submitFiltersHandler
    );
  };

  fetchVisitCategoryOptions = async (key) => {
    let visitCategoryOptions = await getLookupByKey(key);
    visitCategoryOptions.push({label:'Self Registration',value:'Self Registration'});
    this.setState((prevState) => {
      return { ...prevState, visitCategoryOptions }
    })
  }

  fetchClinicList = async (query = {}) => {
    const queryString = null;
    try {
      const clinicList = await DropDownService.getClinicList(isSet(this.props.profile.organization_id, null), queryString);
      let clinicDetails = [{ label: "-Select Clinic-", value: '' }];
      if(clinicList?.length > 0){
        clinicDetails.push({
          label:"All",value:"All"
        });
      }
      clinicList?.forEach(clinic => {
        clinicDetails.push({
          label: convertUnderScoreStrings(clinic.name),
          value: clinic.clinic_id
        });
      });


      this.setState((prevState) => {
        return { ...prevState, clinicDetails: clinicDetails }
      });

    } catch (e) {

    }
  }
  htmlViewModal = (consent_form_content_en="") => {
    this.setState((oldState) => ({
      isHtmlViewOpen: !oldState.isHtmlViewOpen,
      htmlViewContent: consent_form_content_en,
    }));
  };

  fetchOrganization = async () => {
    if (isSet(this.props.profile?.role, '') === "organization_admin") {

      this.setState((prevState) => {
        return {
          ...prevState, organizationId: this.props.profile.organization_id
        }
      });
    }
  }
  fetchClinicConsentFormList = async (query) => {
    const { tableRecords } = this.state;
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });
    try {
      this.props.Loader(true);
      const consentFormList = await ConsentFormService.getConsentFormList(isSet(this.props.profile.organization_id, null), queryString);
      let tableData = [];
      consentFormList.data.data.map((consentForms, index) => {
        tableData = [
          ...tableData,
          {
            consentFormIndex: index,
            isHeading: false,
            rowId: consentForms.consent_form_id,
            clinic_id: consentForms.clinic_id,
            consent_form_id: consentForms.consent_form_id,
            data: {
              isActive: false,
              clinic_name: consentForms.clinic_name  ? consentForms.clinic_name : 'All',
              visit_category: consentForms.visit_category,
              title: consentForms.title_en,
              content: [{
                acl:'any',
                label: "view content",
                clickHandler: () => this.htmlViewModal(consentForms.consent_form_content_en),
              },],
              is_spanish: consentForms.includes_spanish_translation ? 'Yes' : 'No',
              status: consentForms.status ? 'Yes' : 'No',
              action: [
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Search",
                  title: "view",
                  type: "GridEdit",
                  acl: "consent-edit",
                  clickHandler: (rowId, data) => this.editHandler(rowId, data),
                },
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Remove",
                  acl: "consent-delete",
                  title: "Delete",
                  isDelete: true,
                  clickHandler: () =>
                    this.deleteHandler(consentForms.consent_form_id),
                },
              ],
            },
          },
        ];
      });
      this.setState(
        (prevState) => ({
          tableRecords: {
            ...prevState.tableRecords,
            tableRows: {
              status: 200,
              type: "default",
              data: tableData,
            },
            pagination: {
              ...prevState.tableRecords.pagination,
              ...query,
              totalRecords: consentFormList.data.total
            },
          },
        }),
        () => {

        }
      );
      this.props.Loader();
    } catch (e) {
      this.props.Loader();
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200,
          },
        },
      });
    }
  };
  deleteHandler = async (id) => {
    ConsentFormService.deleteConsentForm(isSet(this.props.profile.organization_id, null), id)
      .then((res) => {
        this.fetchClinicConsentFormList();
        this.props.Toast.success("Consent Form Deleted Successfully");
      })
      .catch((err) => {
        //
      })
      .finally(() => {
        //
      });
  };
  editHandler = (rowId, data) => {
    this.setState({
      activeEditId: rowId,
      formDataInEdit: data,
    }, this.addFormHandler());
  };
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };

  checkBoxchangeHandler = (value, e) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
  consentModelHandler = (fetch = false) => {
    this.setState({
      isConsentModelOpen: !this.state.isConsentModelOpen,
      //   activeActivityData: data,
    });
    if (fetch) {
      this.fetchClinicConsentFormList();
    }
  }
}
export default withReduxState(WithRoutify(ConsentFormList));
