
import { TranslateClient,TranslateTextCommand } from '@aws-sdk/client-translate';

// Function to extract text and preserve HTML tags
const extractTextFromHtml = (html) => {
  const tags = [];
  const text = html.replace(/(<\/?[^>]+>)/g, (match) => {
    tags.push(match); // Store HTML tags
    return `<shc:t${tags.length - 1}>`; // Use custom placeholder format
  });
  return { text, tags };
};
// Function to insert preserved HTML tags back into translated text
const insertTagsIntoHtml = (translatedText, tags) => {
  return translatedText.replace(/<shc:t(\d+)>/g, (match, index) => {
    // console.log('Placeholder match:', match);  // Debugging: Log placeholder match
    // console.log('Index:', index);  // Debugging: Log index
    // console.log('Tags array:', tags);  // Debugging: Log tags array

    const tagIndex = Number(index);
    if (tagIndex >= 0 && tagIndex < tags.length) {
      return tags[tagIndex] || '';  // Replace placeholders with original tags
    }
    return match; // Return the placeholder if index is out of bounds
  });
};

const TranslatorService = {
    //Save user
    translator : async (value)=>{
        const client = new TranslateClient({
          region: process.env.REACT_APP_AWS_TRANSLATE_CLIENT_REGION, // e.g., 'us-east-1'
          credentials: {
            accessKeyId: process.env.REACT_APP_AWS_TRANSLATE_CLIENT_ACCESSKEY,
            secretAccessKey: process.env.REACT_APP_AWS_TRANSLATE_CLIENT_SECRETKEY,
          },
        });
        let returnData  ='';
        try {
          const {text, tags} = extractTextFromHtml(value);
          const command = new TranslateTextCommand({
            Text: text,//isSet(formData['consent_form_content_en'],''),
            SourceLanguageCode: 'en',
            TargetLanguageCode: 'es',
          });
          const response = await client.send(command);
          returnData = response.TranslatedText;
          const convertedData =  insertTagsIntoHtml(returnData,tags);
          returnData = convertedData;
         
        } catch (err) {
          // setError(err.message);
        }
        return returnData;
      }
    
    
  };
  
  export default TranslatorService;