import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeProfileDetails, removeProfile } from "../../../store/SessionSlice";
import AuthService from "../../../services/AuthService";
import WithRoutify from "../../../hoc/WithRoutify";
const FormCompletion = ({ Toast, Loader, ...props }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const profileDetails = useSelector(activeProfileDetails);
    const removeProfileAsync = (key) => {
        return async (dispatch, getState) => {
            const sessionState = getState().session.profileDetails;
            try {
                if (Object.keys(sessionState).length > 1) {
                    await AuthService.impersonateLogout();
                }
                dispatch(removeProfile({ key }));
            } catch (e) {

            }
            // Use getState() to check if the state has been updated
            const updatedState = getState().session.profileDetails;
            const keys = Object.keys(updatedState);
            try {
                // Asynchronous operations, such as AuthService.logout(), go here
                if (keys.length === 0) {
                    await AuthService.logout();
                    navigate('/login');
                } else {
                    Toast.success(profileDetails.name + ' logged-out successfully');
                    navigate("/organisation");
                }
            } catch (error) {
                await AuthService.logout();
                // Handle errors if necessary
            }
        };
    };
    const onlogoutHandler = () => {
        try {
            dispatch(removeProfileAsync(AuthService.getProfileId()));
        } catch (error) {
            // Handle errors if necessary
        }
    };
    return (
        <>
                    <div className="col-lg-6  d-flex flex-column justify-content-center mx-auto">
                <div className="row"><div class="col-md-7 mx-auto">
                    <div className="card  sucesscontainer border-0 my-2 ">
                        <div className="card-body ">
                            <div className="row">
                                <div className="col-lg-12">
                                <div class="text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 500 500" fill="none">
                                    <path d="M392.249 268.44L319.519 240.94C318.325 240.488 317.231 239.806 316.3 238.931C315.369 238.056 314.62 237.006 314.095 235.841C313.571 234.677 313.281 233.42 313.242 232.143C313.203 230.866 313.416 229.595 313.869 228.4L351.929 127.74C352.842 125.327 354.676 123.375 357.028 122.314C359.379 121.252 362.056 121.168 364.469 122.08L410.6 139.52L432.38 189.82L404.79 262.82C403.869 265.226 402.032 267.169 399.681 268.222C397.33 269.276 394.658 269.354 392.249 268.44Z" fill="#F5F5F5"/>
                                    <path d="M410.6 139.52L399.88 167.85C399.459 168.962 399.261 170.145 399.297 171.333C399.334 172.522 399.604 173.691 400.092 174.775C400.581 175.858 401.278 176.835 402.144 177.649C403.01 178.464 404.028 179.099 405.14 179.52L432.38 189.82L410.6 139.52Z" fill="#EBEBEB"/>
                                    <path d="M399.309 170.48C399.309 170.48 396.359 177.22 403.309 182.48C403.572 182.676 403.846 182.856 404.129 183.02C407.449 185.02 429.289 198.02 429.489 197.49L432.369 189.87L405.129 179.57C405.129 179.57 398.429 176.72 399.309 170.48Z" fill="#E0E0E0"/>
                                    <path d="M394.22 164.39C394.08 164.76 386.44 162.2 377.15 158.69C367.86 155.18 360.44 152.03 360.58 151.69C360.72 151.35 368.36 153.88 377.65 157.39C386.94 160.9 394.36 164 394.22 164.39Z" fill="#E0E0E0"/>
                                    <path d="M409.89 188.63C409.75 188.99 397.25 184.63 381.96 178.82C366.67 173.01 354.38 168.05 354.52 167.68C354.66 167.31 367.16 171.68 382.46 177.5C397.76 183.32 410 188.26 409.89 188.63Z" fill="#E0E0E0"/>
                                    <path d="M409.82 203.89C409.68 204.25 396.06 199.44 379.39 193.13C362.72 186.82 349.32 181.42 349.46 181.06C349.6 180.7 363.22 185.51 379.89 191.82C396.56 198.13 410 203.52 409.82 203.89Z" fill="#E0E0E0"/>
                                    <path d="M405.05 216.51C404.91 216.87 391.29 212.06 374.62 205.75C357.95 199.44 344.55 194 344.69 193.68C344.83 193.36 358.45 198.13 375.12 204.43C391.79 210.73 405.19 216.14 405.05 216.51Z" fill="#E0E0E0"/>
                                    <path d="M400.28 229.13C400.14 229.49 386.51 224.68 369.85 218.37C353.19 212.06 339.78 206.66 339.91 206.3C340.04 205.94 353.67 210.75 370.35 217.05C387.03 223.35 400.41 228.76 400.28 229.13Z" fill="#E0E0E0"/>
                                    <path d="M395.5 241.75C395.37 242.11 381.74 237.29 365.08 230.99C348.42 224.69 335 219.28 335.14 218.92C335.28 218.56 348.9 223.37 365.57 229.67C382.24 235.97 395.64 241.38 395.5 241.75Z" fill="#E0E0E0"/>
                                    <path d="M149.766 163.937L53.8854 197.514C50.5703 198.675 48.8239 202.304 49.9849 205.619L98.0913 342.989C99.2522 346.304 102.881 348.05 106.196 346.89L202.077 313.313C205.392 312.152 207.138 308.523 205.977 305.208L157.871 167.838C156.71 164.523 153.081 162.776 149.766 163.937Z" fill="#EBEBEB"/>
                                    <path d="M150.995 172.476L58.248 204.956L105.928 341.108L198.675 308.629L150.995 172.476Z" fill="#E0E0E0"/>
                                    <path d="M149.335 171.835L56.5879 204.315L103.359 337.872L196.106 305.393L149.335 171.835Z" fill="#FAFAFA"/>
                                    <path d="M196.089 305.39L149.199 171.88L149.369 171.96L56.6395 204.45L56.7295 204.27C74.2095 254.43 90.4795 300.76 103.469 337.83L103.319 337.76L196.1 305.39L103.389 338H103.279V337.89C90.2795 300.82 74.0595 254.49 56.4795 204.34V204.2H56.6095L149.35 171.74H149.479V171.86L196.089 305.39Z" fill="#E0E0E0"/>
                                    <path d="M163.2 316.91C163.2 316.91 183.85 299.49 187.43 281.35C185.358 284.226 182.725 286.652 179.69 288.482C176.655 290.312 173.28 291.509 169.77 292C169.77 292 169.36 311 163.2 316.91Z" fill="#E0E0E0"/>
                                    <path d="M88.284 234.074L83.4046 235.783C81.6219 236.407 80.6828 238.359 81.3071 240.141L83.0159 245.021C83.6402 246.803 85.5914 247.743 87.374 247.118L92.2535 245.41C94.0362 244.785 94.9752 242.834 94.3509 241.051L92.6422 236.172C92.0179 234.389 90.0667 233.45 88.284 234.074Z" fill="#EBEBEB"/>
                                    <path d="M87.6902 242.48C87.6562 242.498 87.6185 242.507 87.5802 242.507C87.5419 242.507 87.5042 242.498 87.4702 242.48L85.2302 241.18C85.1759 241.139 85.1387 241.079 85.1254 241.012C85.1122 240.945 85.1239 240.875 85.1583 240.816C85.1927 240.757 85.2474 240.713 85.3122 240.692C85.377 240.67 85.4474 240.673 85.5102 240.7L87.5102 241.84L89.8702 236.84C89.9046 236.773 89.9627 236.722 90.033 236.696C90.1033 236.67 90.1808 236.672 90.2502 236.7C90.2841 236.716 90.3144 236.739 90.3393 236.767C90.3643 236.794 90.3834 236.827 90.3955 236.863C90.4076 236.898 90.4125 236.935 90.4099 236.973C90.4073 237.01 90.3972 237.047 90.3802 237.08L87.8902 242.4C87.8645 242.427 87.8334 242.448 87.7989 242.462C87.7644 242.476 87.7273 242.482 87.6902 242.48Z" fill="white"/>
                                    <path d="M151.73 213.4C151.73 213.48 139.73 217.77 124.84 222.98C109.95 228.19 97.8398 232.35 97.8398 232.28C97.8398 232.21 109.84 227.91 124.74 222.7C139.64 217.49 151.71 213.33 151.73 213.4Z" fill="#E0E0E0"/>
                                    <path d="M123.45 232.62C123.45 232.62 123.14 232.76 122.58 232.98L120.16 233.88C118.16 234.62 115.28 235.63 112.16 236.73C109.04 237.83 106.16 238.81 104.16 239.5L101.71 240.31C101.413 240.419 101.109 240.506 100.8 240.57C101.079 240.424 101.37 240.3 101.67 240.2L104.09 239.31C106.09 238.56 108.97 237.55 112.09 236.45C115.21 235.35 118.09 234.38 120.09 233.68L122.54 232.87C122.835 232.761 123.14 232.677 123.45 232.62Z" fill="#E0E0E0"/>
                                    <path d="M153 217.08C153 217.15 141 221.44 126.1 226.65C111.2 231.86 99.0996 236.03 99.0996 235.95C99.0996 235.87 111.1 231.59 126.01 226.38C140.92 221.17 153 217 153 217.08Z" fill="#E0E0E0"/>
                                    <path d="M95.2498 253.972L90.3704 255.681C88.5877 256.305 87.6487 258.257 88.2729 260.039L89.9817 264.919C90.606 266.701 92.5572 267.64 94.3399 267.016L99.2193 265.307C101.002 264.683 101.941 262.732 101.317 260.949L99.608 256.07C98.9837 254.287 97.0325 253.348 95.2498 253.972Z" fill="#EBEBEB"/>
                                    <path d="M94.6595 262.38C94.6237 262.398 94.5844 262.407 94.5445 262.407C94.5046 262.407 94.4653 262.398 94.4295 262.38L92.1995 261.08C92.1672 261.062 92.1388 261.038 92.1161 261.009C92.0934 260.98 92.0768 260.947 92.0675 260.912C92.0581 260.876 92.0561 260.839 92.0616 260.802C92.0671 260.766 92.08 260.731 92.0995 260.7C92.1171 260.668 92.1411 260.639 92.1701 260.617C92.199 260.594 92.2323 260.577 92.2679 260.568C92.3035 260.559 92.3406 260.557 92.377 260.562C92.4134 260.568 92.4483 260.581 92.4795 260.6L94.4795 261.74L96.8395 256.69C96.872 256.624 96.9282 256.573 96.9969 256.547C97.0656 256.521 97.1415 256.522 97.2095 256.55C97.2771 256.581 97.3298 256.637 97.356 256.706C97.3823 256.775 97.3799 256.852 97.3495 256.92L94.8595 262.25C94.8373 262.285 94.808 262.314 94.7735 262.336C94.739 262.359 94.7001 262.374 94.6595 262.38Z" fill="white"/>
                                    <path d="M158.7 233.3C158.7 233.38 146.7 237.66 131.8 242.88C116.9 248.1 104.8 252.25 104.8 252.18C104.8 252.11 116.8 247.81 131.7 242.6C146.6 237.39 158.68 233.23 158.7 233.3Z" fill="#E0E0E0"/>
                                    <path d="M130.42 252.52C130.42 252.52 130.11 252.66 129.54 252.88L127.13 253.78C125.13 254.52 122.25 255.53 119.13 256.63C116.01 257.73 113.13 258.71 111.13 259.4L108.68 260.21C108.383 260.319 108.079 260.406 107.77 260.47C108.048 260.321 108.339 260.197 108.64 260.1L111.06 259.21C113.06 258.46 115.94 257.45 119.06 256.35C122.18 255.25 125.06 254.28 127.06 253.58L129.51 252.77C129.805 252.661 130.11 252.577 130.42 252.52Z" fill="#E0E0E0"/>
                                    <path d="M160 237C160 237.07 148 241.36 133.1 246.57C118.2 251.78 106.1 255.95 106.1 255.87C106.1 255.79 118.1 251.51 133 246.3C147.9 241.09 160 236.9 160 237Z" fill="#E0E0E0"/>
                                    <path d="M102.225 273.871L97.346 275.58C95.5633 276.204 94.6242 278.155 95.2485 279.938L96.9573 284.817C97.5816 286.6 99.5328 287.539 101.315 286.915L106.195 285.206C107.978 284.582 108.917 282.63 108.292 280.848L106.584 275.968C105.959 274.186 104.008 273.247 102.225 273.871Z" fill="#EBEBEB"/>
                                    <path d="M101.63 282.28C101.594 282.298 101.555 282.307 101.515 282.307C101.475 282.307 101.436 282.298 101.4 282.28L99.1602 281C99.1237 280.985 99.0909 280.961 99.064 280.932C99.0372 280.903 99.0169 280.868 99.0044 280.831C98.9919 280.793 98.9876 280.753 98.9918 280.714C98.9959 280.674 99.0084 280.636 99.0284 280.602C99.0484 280.567 99.0754 280.538 99.1078 280.515C99.1401 280.492 99.177 280.476 99.2159 280.468C99.2548 280.46 99.295 280.461 99.3336 280.47C99.3723 280.479 99.4086 280.496 99.4402 280.52L101.44 281.66L103.8 276.61C103.814 276.575 103.835 276.543 103.862 276.516C103.889 276.49 103.92 276.469 103.955 276.454C103.99 276.44 104.028 276.433 104.066 276.434C104.104 276.435 104.141 276.443 104.175 276.459C104.21 276.474 104.241 276.497 104.266 276.525C104.292 276.552 104.312 276.585 104.324 276.621C104.337 276.656 104.342 276.694 104.34 276.732C104.337 276.77 104.327 276.806 104.31 276.84L101.82 282.17C101.771 282.228 101.704 282.266 101.63 282.28Z" fill="white"/>
                                    <path d="M165.67 253.2C165.67 253.28 153.67 257.56 138.77 262.78C123.87 268 111.77 272.15 111.77 272.07C111.77 271.99 123.77 267.71 138.68 262.5C153.59 257.29 165.64 253.13 165.67 253.2Z" fill="#E0E0E0"/>
                                    <path d="M137.389 272.41C137.106 272.555 136.812 272.679 136.509 272.78L134.089 273.68C132.089 274.42 129.219 275.43 126.089 276.53C122.959 277.63 120.089 278.6 118.089 279.3L115.639 280.11C115.342 280.217 115.038 280.304 114.729 280.37C115.008 280.221 115.299 280.097 115.599 280L118.019 279.1C120.019 278.36 122.899 277.35 126.019 276.25C129.139 275.15 132.019 274.18 134.019 273.48L136.459 272.67C136.764 272.566 137.075 272.479 137.389 272.41Z" fill="#E0E0E0"/>
                                    <path d="M167 256.88C167 256.95 155 261.24 140.1 266.45C125.2 271.66 113.1 275.83 113.1 275.75C113.1 275.67 125.1 271.39 140 266.17C154.9 260.95 166.93 256.8 167 256.88Z" fill="#E0E0E0"/>
                                    <path d="M109.191 293.768L104.312 295.477C102.529 296.101 101.59 298.053 102.214 299.835L103.923 304.715C104.547 306.497 106.499 307.436 108.281 306.812L113.161 305.103C114.943 304.479 115.882 302.528 115.258 300.745L113.549 295.866C112.925 294.083 110.974 293.144 109.191 293.768Z" fill="#EBEBEB"/>
                                    <path d="M108.59 302.18C108.555 302.195 108.517 302.203 108.48 302.203C108.442 302.203 108.404 302.195 108.37 302.18L106.13 300.88C106.066 300.842 106.02 300.78 106.002 300.707C105.984 300.635 105.996 300.559 106.035 300.495C106.073 300.431 106.135 300.386 106.207 300.368C106.28 300.35 106.356 300.362 106.42 300.4L108.42 301.54L110.78 296.54C110.81 296.473 110.866 296.42 110.936 296.394C111.005 296.367 111.082 296.37 111.15 296.4C111.215 296.433 111.266 296.489 111.292 296.558C111.318 296.626 111.317 296.702 111.29 296.77L108.79 302.1C108.736 302.152 108.664 302.18 108.59 302.18Z" fill="white"/>
                                    <path d="M172.64 273.1C172.64 273.18 160.64 277.46 145.74 282.67C130.84 287.88 118.74 292.05 118.74 291.97C118.74 291.89 130.74 287.61 145.64 282.4C160.54 277.19 172.61 273 172.64 273.1Z" fill="#E0E0E0"/>
                                    <path d="M144.35 292.31C144.072 292.459 143.781 292.583 143.48 292.68L141.06 293.58C139.06 294.32 136.18 295.33 133.06 296.43C129.94 297.53 127.06 298.5 125.06 299.2L122.61 300.01C122.03 300.19 121.7 300.28 121.7 300.27C121.979 300.121 122.27 299.997 122.57 299.9L124.99 299C126.99 298.26 129.87 297.25 132.99 296.15C136.11 295.05 138.99 294.08 140.99 293.38L143.44 292.57C143.737 292.463 144.041 292.376 144.35 292.31Z" fill="#E0E0E0"/>
                                    <path d="M173.929 276.78C173.929 276.85 161.929 281.14 147.029 286.35C132.129 291.56 120.029 295.72 120.029 295.65C120.029 295.58 132.029 291.29 146.929 286.07C161.829 280.85 173.899 276.7 173.929 276.78Z" fill="#E0E0E0"/>
                                    <path d="M67.0898 191.3L69.5298 198.3C70.2123 200.249 71.6404 201.847 73.5005 202.744C75.3606 203.64 77.5005 203.761 79.4498 203.08L130.75 185.08C131.714 184.744 132.603 184.22 133.365 183.54C134.127 182.859 134.747 182.035 135.19 181.115C135.633 180.194 135.89 179.195 135.947 178.175C136.003 177.155 135.858 176.134 135.52 175.17L133.08 168.17L67.0898 191.3Z" fill="#E0E0E0"/>
                                    <path d="M100.77 181.82C99.407 182.299 97.9326 182.364 96.5332 182.005C95.1339 181.646 93.8724 180.88 92.9087 179.804C91.9449 178.728 91.3222 177.39 91.1193 175.959C90.9163 174.529 91.1424 173.07 91.7687 171.769C92.3951 170.467 93.3937 169.38 94.638 168.646C95.8823 167.912 97.3164 167.564 98.7588 167.645C100.201 167.727 101.587 168.235 102.741 169.104C103.894 169.974 104.764 171.166 105.24 172.53C105.876 174.355 105.763 176.357 104.925 178.099C104.087 179.84 102.593 181.179 100.77 181.82ZM96.8698 170.69C96.0293 170.983 95.2947 171.52 94.759 172.231C94.2233 172.942 93.9105 173.796 93.8602 174.684C93.81 175.573 94.0245 176.457 94.4766 177.224C94.9288 177.991 95.5982 178.606 96.4003 178.993C97.2023 179.379 98.1009 179.519 98.9824 179.394C99.8639 179.27 100.689 178.887 101.352 178.293C102.016 177.7 102.489 176.923 102.711 176.061C102.933 175.199 102.894 174.29 102.6 173.45C102.205 172.325 101.38 171.402 100.306 170.884C99.2315 170.367 97.9956 170.297 96.8698 170.69Z" fill="#E0E0E0"/>
                                    <path d="M141.77 200C141.77 200.13 128.29 205 111.56 210.85C94.8298 216.7 81.2298 221.35 81.1798 221.22C81.1298 221.09 94.6598 216.22 111.39 210.37C128.12 204.52 141.72 199.83 141.77 200Z" fill="#E0E0E0"/>
                                    <path d="M124.44 202.45C124.44 202.58 118.54 204.79 111.15 207.38C103.76 209.97 97.73 212 97.68 211.82C97.63 211.64 103.59 209.48 110.98 206.89C118.37 204.3 124.4 202.32 124.44 202.45Z" fill="#E0E0E0"/>
                                    <path d="M391 405.08C394.683 403.351 398.822 402.85 402.81 403.65C404.95 404 407.19 404.65 408.64 406.25C410.09 407.85 410.38 410.69 408.7 412.07C407.616 412.807 406.305 413.137 405 413C401.87 413 398.41 412.41 395.92 414.29C394.49 415.36 393.55 417.14 391.85 417.67C390.995 417.869 390.097 417.781 389.297 417.42C388.496 417.058 387.837 416.443 387.42 415.67C386.612 414.106 386.433 412.292 386.92 410.6C387.13 409.428 387.607 408.32 388.315 407.363C389.023 406.405 389.942 405.624 391 405.08Z" fill="#62AE47"/>
                                    <path d="M382.72 389.2C384.92 392.33 389.19 391.3 391.17 389.38C393.15 387.46 393.98 384.69 394.74 382.05C396.48 376 398.24 369.66 396.95 363.5C396.629 361.649 395.81 359.92 394.58 358.5C393.953 357.795 393.152 357.267 392.257 356.969C391.363 356.67 390.405 356.612 389.48 356.8C387.32 357.37 385.99 359.48 385.02 361.48C382.169 367.409 380.676 373.9 380.65 380.48C380.65 383.54 381 386.74 382.72 389.27" fill="#62AE47"/>
                                    <path d="M376.69 393.7C377.82 392.225 378.573 390.497 378.883 388.665C379.193 386.834 379.051 384.954 378.47 383.19C377.261 379.676 375.11 376.562 372.25 374.19C368.999 371.297 365.215 369.065 361.11 367.62C359.524 366.979 357.819 366.689 356.11 366.77C355.256 366.818 354.427 367.075 353.694 367.517C352.962 367.96 352.35 368.575 351.91 369.31C350.73 371.55 351.91 374.26 353.07 376.47C355.211 380.371 357.659 384.096 360.39 387.61C362.58 390.44 365.09 393.23 368.44 394.51C371.79 395.79 375.25 395.63 377.02 393.32" fill="#62AE47"/>
                                    <path d="M382.58 436.51C382.69 436.044 382.754 435.568 382.77 435.09C382.88 434.09 383 432.75 383.15 431.2C383.342 429.282 383.636 427.376 384.03 425.49C384.538 423.195 385.231 420.944 386.1 418.76C386.954 416.578 388.113 414.528 389.54 412.67C390.704 411.155 392.19 409.919 393.89 409.05C395.042 408.478 396.278 408.093 397.55 407.91C398.029 407.893 398.505 407.826 398.97 407.71C398.488 407.658 398.002 407.658 397.52 407.71C396.201 407.816 394.912 408.155 393.71 408.71C391.919 409.552 390.348 410.798 389.12 412.35C387.635 414.237 386.435 416.333 385.56 418.57C384.673 420.785 383.984 423.074 383.5 425.41C383.132 427.32 382.878 429.25 382.74 431.19C382.61 432.84 382.57 434.19 382.55 435.1C382.505 435.569 382.515 436.043 382.58 436.51Z" fill="#263238"/>
                                    <path d="M382.84 433.52C382.87 433.301 382.87 433.079 382.84 432.86V431C382.84 429.36 382.84 427 382.92 424C383.07 418.13 383.57 411.82 384.55 402.92C385.47 394.55 386.71 384.81 388.02 378.48C388.3 377.04 388.58 375.74 388.85 374.61C389.12 373.48 389.32 372.49 389.53 371.7C389.74 370.91 389.85 370.34 389.97 369.86C390.025 369.643 390.065 369.422 390.09 369.2C389.989 369.397 389.912 369.605 389.86 369.82C389.71 370.3 389.53 370.9 389.31 371.64C389.09 372.38 388.81 373.4 388.53 374.53C388.25 375.66 387.92 376.95 387.61 378.39C386.007 385.887 384.802 393.465 384 401.09C383 410.01 382.58 418.09 382.55 424.01C382.55 426.95 382.55 429.33 382.65 431.01C382.65 431.78 382.73 432.41 382.76 432.9C382.768 433.108 382.795 433.316 382.84 433.52Z" fill="#263238"/>
                                    <path d="M382.88 417.86C382.936 417.194 382.936 416.525 382.88 415.86C382.767 414.068 382.563 412.282 382.27 410.51C381.842 407.879 381.261 405.274 380.53 402.71C379.684 399.554 378.594 396.468 377.27 393.48C375.008 387.973 371.765 382.921 367.7 378.57C366.992 377.826 366.237 377.128 365.44 376.48C365.1 376.18 364.79 375.89 364.44 375.66L363.57 375.06C363.046 374.658 362.487 374.303 361.9 374C363.82 375.5 365.641 377.124 367.35 378.86C371.293 383.241 374.452 388.268 376.69 393.72C377.987 396.69 379.083 399.743 379.97 402.86C380.723 405.399 381.34 407.976 381.82 410.58C382.23 412.81 382.47 414.58 382.61 415.88C382.652 416.546 382.742 417.207 382.88 417.86Z" fill="#263238"/>
                                    <path d="M367.74 445.49L369.31 466.99H396.94L398.5 445.49H367.74Z" fill="#263238"/>
                                    <path d="M401.81 437.01H365.08V445.49H401.81V437.01Z" fill="#263238"/>
                                    <path d="M365.08 466.17V468.51H366.8H401.19V466.17H365.08Z" fill="#455A64"/>
                                    <path d="M368.49 472.65L366.8 468.51H399.88L397.9 472.65H368.49Z" fill="#455A64"/>
                                    <path d="M398 452.51C398 452.51 398 452.59 397.87 452.71L397.46 453.26L395.85 455.32L395.78 455.41L395.71 455.31L391.76 449.85H392L388.1 455.36L387.98 455.53L387.84 455.37L383.23 449.87H383.49L383.24 450.19L379.11 455.36L378.98 455.54L378.85 455.36C377.45 453.36 376.14 451.52 374.95 449.85H375.18L370.39 455.31L370.3 455.41L370.23 455.3C369.6 454.24 369.1 453.39 368.73 452.78L368.35 452.11C368.3 452.035 368.26 451.955 368.23 451.87C368.23 451.87 368.29 451.93 368.39 452.08L368.82 452.73L370.39 455.2H370.24L374.96 449.67L375.08 449.53L375.19 449.68L379.12 455.17H378.85L383 450L383.26 449.68L383.39 449.51L383.52 449.67L388.13 455.18H387.87L391.8 449.69L391.91 449.53L392.03 449.69C393.58 451.92 394.91 453.81 395.9 455.22H395.75L397.43 453.22L397.88 452.7L398 452.51Z" fill="#E0E0E0"/>
                                    <path d="M398.89 445.34C398.89 445.44 391.97 445.52 383.43 445.52C374.89 445.52 367.97 445.44 367.97 445.34C367.97 445.24 374.89 445.17 383.43 445.17C391.97 445.17 398.89 445.25 398.89 445.34Z" fill="#455A64"/>
                                    <path d="M400.29 468.75C400.29 468.84 392.76 468.92 383.49 468.92C374.22 468.92 366.69 468.84 366.69 468.75C366.69 468.66 374.21 468.57 383.49 468.57C392.77 468.57 400.29 468.65 400.29 468.75Z" fill="#263238"/>
                                    <path d="M127.8 400.15C127.72 400.24 127.09 399.86 126.39 399.3C125.69 398.74 125.18 398.21 125.26 398.11C125.34 398.01 125.96 398.4 126.66 398.96C127.36 399.52 127.87 400.05 127.8 400.15Z" fill="#263238"/>
                                    <path d="M147 371.51C147.273 368.842 147.052 366.148 146.35 363.56C145.51 361.01 143.55 358.68 140.93 358.13C137.93 357.51 134.87 359.4 133.19 361.96C131.51 364.52 130.94 367.62 130.44 370.63C127.029 391.428 125.772 412.523 126.69 433.58L138.93 435.3C144.26 413.33 146 394.12 147 371.51Z" fill="#455A64"/>
                                    <path d="M139.18 358L139.12 358.2L138.92 358.75L138.1 360.87C137.93 361.33 137.7 361.87 137.52 362.4L136.93 364.25L136.24 366.38L135.61 368.81C134.582 372.824 133.791 376.894 133.24 381C132.58 385.6 132.15 390.7 131.8 396.06C131.18 406.79 131.08 416.53 130.8 423.57C130.67 427.1 130.55 429.95 130.42 431.91C130.35 432.91 130.3 433.62 130.26 434.18C130.26 434.41 130.26 434.61 130.26 434.76C130.26 434.91 130.26 434.96 130.26 434.96C130.26 434.96 130.26 434.9 130.26 434.76C130.26 434.62 130.26 434.41 130.26 434.17C130.26 433.62 130.26 432.86 130.33 431.9C130.41 429.9 130.48 427.09 130.57 423.56C130.77 416.56 130.82 406.78 131.45 396.04C131.79 390.67 132.24 385.56 132.92 380.94C133.493 376.847 134.318 372.792 135.39 368.8L136.05 366.37C136.31 365.623 136.55 364.913 136.77 364.24L137.41 362.39C137.6 361.83 137.84 361.33 138.02 360.87L138.92 358.79C139.013 358.57 139.093 358.39 139.16 358.25C139.153 358.166 139.159 358.082 139.18 358Z" fill="#263238"/>
                                    <path d="M140.31 358.05C140.948 358.803 141.455 359.659 141.81 360.58C142.746 363.109 143.125 365.811 142.92 368.5C142.6 375.27 141.99 384.59 141.19 394.89C140.39 405.19 139.48 414.49 138.76 421.22C138.39 424.58 138.08 427.3 137.85 429.22C137.73 430.13 137.64 430.85 137.57 431.38L137.49 431.94C137.495 432.003 137.495 432.067 137.49 432.13C137.49 432.13 137.49 432.07 137.49 431.94C137.49 431.81 137.49 431.6 137.49 431.37C137.49 430.84 137.6 430.12 137.68 429.2C137.87 427.28 138.13 424.57 138.45 421.2C139.09 414.46 139.94 405.2 140.75 394.86C141.56 384.52 142.21 375.25 142.61 368.5C142.828 365.842 142.487 363.168 141.61 360.65C141.255 359.746 140.82 358.876 140.31 358.05Z" fill="#263238"/>
                                    <path d="M148.801 389.74C147.997 389.681 147.211 389.478 146.481 389.14C145.704 388.937 144.969 388.599 144.311 388.14C145.115 388.192 145.902 388.396 146.631 388.74C147.408 388.94 148.143 389.278 148.801 389.74Z" fill="#263238"/>
                                    <path d="M150.28 377.26C149.616 377.79 148.87 378.205 148.07 378.49C147.327 378.901 146.518 379.182 145.68 379.32C147.082 378.371 148.638 377.674 150.28 377.26Z" fill="#263238"/>
                                    <path d="M149.51 367.07C148.301 367.288 147.057 367.202 145.89 366.82C146.481 366.65 147.104 366.62 147.71 366.73C148.328 366.703 148.944 366.819 149.51 367.07Z" fill="#263238"/>
                                    <path d="M136.871 360.55C136.771 360.61 136.231 359.94 135.681 359.06C135.28 358.551 134.997 357.961 134.851 357.33C134.951 357.27 135.491 357.93 136.051 358.82C136.611 359.71 137.001 360.48 136.871 360.55Z" fill="#263238"/>
                                    <path d="M132.7 366.71C132.144 366.91 131.546 366.972 130.96 366.89C130.371 366.946 129.777 366.857 129.23 366.63C129.787 366.43 130.384 366.368 130.97 366.45C131.56 366.393 132.154 366.482 132.7 366.71Z" fill="#263238"/>
                                    <path d="M129.7 379C129.06 378.974 128.434 378.803 127.87 378.5C127.253 378.324 126.682 378.013 126.2 377.59C126.84 377.615 127.466 377.786 128.03 378.09C128.648 378.265 129.218 378.576 129.7 379Z" fill="#263238"/>
                                    <path d="M129.38 389.39C129.38 389.51 128.71 389.76 127.82 389.94C126.93 390.12 126.2 390.19 126.17 390.07C126.14 389.95 126.85 389.7 127.73 389.52C128.261 389.34 128.828 389.296 129.38 389.39Z" fill="#263238"/>
                                    <path d="M128 406.35C128 406.47 127.28 406.7 126.34 406.87C125.779 407.037 125.185 407.064 124.61 406.95C125.115 406.649 125.684 406.474 126.27 406.44C126.831 406.267 127.425 406.236 128 406.35Z" fill="#263238"/>
                                    <path d="M127 415.71C127.06 415.81 126.44 416.25 125.62 416.71C124.8 417.17 124.09 417.41 124.04 417.3C123.99 417.19 124.6 416.76 125.42 416.3C126.24 415.84 127 415.6 127 415.71Z" fill="#263238"/>
                                    <path d="M134.67 426.33C134.57 426.39 134.03 425.65 133.47 424.67C133.065 424.109 132.786 423.468 132.65 422.79C132.75 422.73 133.29 423.47 133.85 424.45C134.255 425.01 134.535 425.651 134.67 426.33Z" fill="#263238"/>
                                    <path d="M136.88 408.68C136.96 408.76 136.36 409.49 135.54 410.3C134.72 411.11 133.98 411.69 133.89 411.6C133.8 411.51 134.41 410.79 135.23 409.98C136.05 409.17 136.79 408.59 136.88 408.68Z" fill="#263238"/>
                                    <path d="M136.39 399.41C136.3 399.5 135.75 399.04 135.15 398.41C134.55 397.78 134.15 397.17 134.23 397.09C134.31 397.01 134.87 397.46 135.47 398.09C136.07 398.72 136.48 399.33 136.39 399.41Z" fill="#263238"/>
                                    <path d="M137.91 387.58C138 387.66 137.5 388.37 136.79 389.15C136.08 389.93 135.44 390.52 135.35 390.44C135.26 390.36 135.76 389.65 136.46 388.86C137.16 388.07 137.82 387.5 137.91 387.58Z" fill="#263238"/>
                                    <path d="M138.76 379C138.67 379.09 137.98 378.54 137.2 377.78C136.42 377.02 135.87 376.33 135.95 376.24C136.03 376.15 136.73 376.7 137.51 377.47C138.29 378.24 138.84 378.91 138.76 379Z" fill="#263238"/>
                                    <path d="M140.44 367.38C140.307 368.011 140.038 368.605 139.65 369.12C139.12 370.02 138.6 370.7 138.5 370.64C138.4 370.58 138.74 369.8 139.28 368.9C139.82 368 140.33 367.32 140.44 367.38Z" fill="#263238"/>
                                    <path d="M143.59 356.62C143.7 356.68 143.39 357.46 142.9 358.36C142.41 359.26 141.9 359.95 141.82 359.9C141.915 359.275 142.151 358.68 142.51 358.16C143 357.25 143.48 356.57 143.59 356.62Z" fill="#263238"/>
                                    <path d="M147.17 361.51C147.22 361.62 146.73 361.96 146.07 362.28C145.41 362.6 144.84 362.76 144.79 362.65C144.74 362.54 145.23 362.2 145.89 361.88C146.55 361.56 147.12 361.4 147.17 361.51Z" fill="#263238"/>
                                    <path d="M147.25 400.15C145.589 400.028 144.016 399.356 142.78 398.24C143.559 398.475 144.312 398.787 145.03 399.17C145.802 399.42 146.546 399.748 147.25 400.15Z" fill="#263238"/>
                                    <path d="M145.79 408.33C145.79 408.45 145.08 408.69 144.14 408.87C143.581 409.049 142.987 409.086 142.41 408.98C142.91 408.673 143.475 408.488 144.06 408.44C145 408.26 145.77 408.21 145.79 408.33Z" fill="#263238"/>
                                    <path d="M144 422.36C143.93 422.46 143 421.81 142.14 420.73C141.567 420.113 141.164 419.359 140.97 418.54C141.529 419.134 142.034 419.777 142.48 420.46C143.044 421.045 143.552 421.681 144 422.36Z" fill="#263238"/>
                                    <path d="M124.31 428.78C124.23 428.69 124.73 428.13 125.42 427.54C126.11 426.95 126.74 426.54 126.82 426.64C126.9 426.74 126.4 427.28 125.71 427.88C125.02 428.48 124.39 428.87 124.31 428.78Z" fill="#263238"/>
                                    <path d="M118.65 398.16C117.85 395.67 116.36 392.77 113.75 392.73C112.972 392.772 112.22 393.023 111.573 393.456C110.925 393.888 110.406 394.487 110.07 395.19C109.351 396.578 109.007 398.129 109.07 399.69C109.392 410.773 111.281 421.756 114.68 432.31H124.25C124.134 420.708 122.245 409.192 118.65 398.16Z" fill="#455A64"/>
                                    <path d="M101.36 382.49C100.887 380.157 99.6438 378.052 97.8304 376.51C96.017 374.969 93.7384 374.081 91.3602 373.99C87.1302 374.73 85.4902 378.89 84.9902 383.15C84.4902 387.41 85.8102 391.65 87.1202 395.74C91.3068 408.86 95.5002 421.983 99.7002 435.11L111.6 433.81C107.88 416.23 105.72 399.93 101.36 382.49Z" fill="#455A64"/>
                                    <path d="M113.75 392.73C113.441 393.155 113.17 393.607 112.94 394.08C112.447 395.432 112.292 396.884 112.49 398.31C112.67 400.13 112.88 402.31 113.19 404.71C113.5 407.11 113.93 409.71 114.47 412.49C115.55 417.99 116.77 422.91 117.54 426.49C117.93 428.28 118.23 429.74 118.41 430.75C118.49 431.22 118.56 431.6 118.61 431.92C118.66 432.24 118.67 432.33 118.61 432.33C118.558 432.204 118.518 432.073 118.49 431.94L118.21 430.79C117.95 429.72 117.61 428.29 117.21 426.56C116.36 422.98 115.09 418.07 114.01 412.56C113.47 409.8 113.01 407.16 112.76 404.74C112.6 403.54 112.5 402.38 112.4 401.31C112.3 400.24 112.2 399.23 112.15 398.31C111.979 396.842 112.186 395.355 112.75 393.99C112.988 393.502 113.329 393.072 113.75 392.73Z" fill="#263238"/>
                                    <path d="M91.5798 373.65C91.0495 374.254 90.568 374.9 90.1398 375.58C89.0445 377.481 88.4237 379.618 88.3298 381.81C88.28 385.06 88.7769 388.295 89.7998 391.38C90.7998 394.92 91.9898 398.81 93.1998 402.88C95.5698 411.04 97.7098 418.42 99.2798 423.81L101.08 430.17C101.28 430.89 101.43 431.46 101.55 431.9C101.615 432.098 101.659 432.302 101.68 432.51C101.588 432.324 101.514 432.13 101.46 431.93C101.33 431.49 101.14 430.93 100.92 430.21C100.45 428.68 99.7898 426.53 98.9898 423.9L92.7798 403C91.5798 398.91 90.4498 395 89.4098 391.48C88.3911 388.351 87.9208 385.069 88.0198 381.78C88.1486 379.546 88.8363 377.379 90.0198 375.48C90.4352 374.788 90.9623 374.17 91.5798 373.65Z" fill="#263238"/>
                                    <path d="M109.05 432.54C108.949 432.355 108.859 432.165 108.78 431.97C108.63 431.58 108.38 431.03 108.11 430.29C107.54 428.83 106.81 426.68 106.11 423.98C105.41 421.28 104.61 417.98 103.89 414.38C103.17 410.78 102.45 406.75 101.65 402.55C100.85 398.35 99.9101 394.4 98.8401 390.87C97.9118 387.731 96.7519 384.666 95.3701 381.7C94.4306 379.703 93.3582 377.772 92.1601 375.92C91.7501 375.26 91.3801 374.77 91.1601 374.43C91.0348 374.259 90.9244 374.079 90.8301 373.89C90.9767 374.038 91.1106 374.199 91.2301 374.37C91.4801 374.71 91.8701 375.17 92.3001 375.83C93.5648 377.653 94.6849 379.572 95.6501 381.57C97.0845 384.529 98.2783 387.599 99.2201 390.75C100.349 394.605 101.277 398.517 102 402.47C102.79 406.66 103.53 410.66 104.21 414.31C104.89 417.96 105.61 421.19 106.33 423.9C107.05 426.61 107.72 428.76 108.24 430.23C108.48 430.98 108.71 431.54 108.84 431.93C108.933 432.124 109.003 432.329 109.05 432.54Z" fill="#263238"/>
                                    <path d="M87.3801 393.78C87.3801 393.89 86.7601 394.15 85.9301 394.35C85.1001 394.55 84.4101 394.62 84.3901 394.5C84.3701 394.38 85.0001 394.13 85.8301 393.93C86.6601 393.73 87.3501 393.66 87.3801 393.78Z" fill="#263238"/>
                                    <path d="M86.1201 385.66C85.4701 385.843 84.7943 385.917 84.1201 385.88C83.4549 385.974 82.7785 385.953 82.1201 385.82C82.7429 385.523 83.4318 385.392 84.1201 385.44C84.7946 385.341 85.4831 385.417 86.1201 385.66Z" fill="#263238"/>
                                    <path d="M87.4605 378.24C87.3805 378.34 86.7305 377.92 86.0205 377.31C85.3105 376.7 84.7805 376.13 84.8605 376.04C84.9405 375.95 85.5805 376.36 86.3005 376.97C87.0205 377.58 87.5405 378.15 87.4605 378.24Z" fill="#263238"/>
                                    <path d="M91.0904 371.38C91.2104 371.38 91.1705 371.99 91.1605 372.68C91.1505 373.37 91.1605 373.96 91.0105 373.98C90.8605 374 90.7005 373.42 90.7205 372.67C90.7405 371.92 91.0004 371.35 91.0904 371.38Z" fill="#263238"/>
                                    <path d="M98.4998 374.61C98.5998 374.68 98.3098 375.27 97.8498 375.92C97.3898 376.57 96.9398 377.05 96.8498 376.98C96.7598 376.91 97.0398 376.33 97.4898 375.67C97.9398 375.01 98.3998 374.54 98.4998 374.61Z" fill="#263238"/>
                                    <path d="M103.31 383C103.31 383.12 102.74 383.47 101.94 383.8C101.14 384.13 100.44 384.3 100.4 384.19C100.36 384.08 100.97 383.72 101.77 383.4C102.57 383.08 103.27 382.86 103.31 383Z" fill="#263238"/>
                                    <path d="M106.23 391.57C105.701 392.029 105.046 392.318 104.35 392.4C103.683 392.616 102.97 392.64 102.29 392.47C102.932 392.215 103.604 392.047 104.29 391.97C104.918 391.758 105.57 391.624 106.23 391.57Z" fill="#263238"/>
                                    <path d="M92.8299 389.13C92.7199 389.13 92.8299 388.5 93.0199 387.8C93.2099 387.1 93.5099 386.57 93.6199 386.61C93.7299 386.65 93.6199 387.24 93.4299 387.94C93.2399 388.64 92.9999 389.17 92.8299 389.13Z" fill="#263238"/>
                                    <path d="M94.7002 399.93C94.7406 399.3 94.9254 398.687 95.2402 398.14C95.6502 397.2 96.0702 396.48 96.1802 396.53C96.1398 397.16 95.955 397.772 95.6402 398.32C95.2302 399.26 94.8102 400 94.7002 399.93Z" fill="#263238"/>
                                    <path d="M90.0799 402.89C89.4906 402.876 88.9098 402.747 88.3699 402.51C87.4599 402.22 86.7199 401.96 86.7499 401.84C87.3451 401.764 87.9499 401.85 88.4999 402.09C89.4299 402.38 90.1299 402.78 90.0799 402.89Z" fill="#263238"/>
                                    <path d="M92.7405 411.69C92.8105 411.79 92.3005 412.27 91.6105 412.77C90.9205 413.27 90.2805 413.59 90.2105 413.5C90.1405 413.41 90.6505 412.91 91.3505 412.41C92.0505 411.91 92.6705 411.59 92.7405 411.69Z" fill="#263238"/>
                                    <path d="M94.88 420.47C94.88 420.58 94.28 420.84 93.47 421.03C92.66 421.22 91.99 421.29 91.96 421.18C91.93 421.07 92.56 420.81 93.37 420.61C94.18 420.41 94.85 420.35 94.88 420.47Z" fill="#263238"/>
                                    <path d="M106.47 398.91C106.53 399.01 105.99 399.44 105.21 399.73C104.43 400.02 103.73 400.06 103.71 399.95C103.69 399.84 104.31 399.61 105.05 399.32C105.79 399.03 106.41 398.8 106.47 398.91Z" fill="#263238"/>
                                    <path d="M108.121 412.56C108.071 412.67 107.431 412.48 106.691 412.13C105.951 411.78 105.391 411.41 105.441 411.3C105.491 411.19 106.141 411.39 106.881 411.73C107.621 412.07 108.181 412.45 108.121 412.56Z" fill="#263238"/>
                                    <path d="M111.05 422.34C110.388 422.306 109.74 422.132 109.15 421.83C108.15 421.44 107.38 421.03 107.42 420.91C108.079 420.944 108.724 421.117 109.31 421.42C110.31 421.81 111.09 422.22 111.05 422.34Z" fill="#263238"/>
                                    <path d="M95.3499 430C95.2899 429.89 96.0699 429.37 96.9699 428.66C97.8699 427.95 98.5699 427.31 98.6599 427.4C98.7499 427.49 98.1799 428.26 97.2399 429C96.717 429.505 96.0617 429.851 95.3499 430Z" fill="#263238"/>
                                    <path d="M103.071 425C102.951 425 102.781 424.36 102.481 423.6C102.181 422.84 101.881 422.23 101.981 422.16C102.081 422.09 102.581 422.61 102.891 423.44C103.201 424.27 103.191 425 103.071 425Z" fill="#263238"/>
                                    <path d="M101 413.27C100.93 413.37 100.43 413.13 99.88 412.74C99.33 412.35 98.95 411.95 99.02 411.85C99.09 411.75 99.59 411.99 100.14 412.38C100.69 412.77 101.1 413.17 101 413.27Z" fill="#263238"/>
                                    <path d="M119 394.85C119.07 394.95 118.58 395.43 117.91 395.92C117.24 396.41 116.64 396.74 116.57 396.64C116.5 396.54 116.98 396.07 117.65 395.57C118.32 395.07 119 394.75 119 394.85Z" fill="#263238"/>
                                    <path d="M121.75 402.27C121.75 402.39 121 402.55 120.06 402.45C119.12 402.35 118.41 402.03 118.44 401.92C118.47 401.81 119.21 401.92 120.11 402.01C121.01 402.1 121.74 402.15 121.75 402.27Z" fill="#263238"/>
                                    <path d="M110.08 403.94C110.08 404.05 109.41 404.3 108.49 404.36C107.57 404.42 106.85 404.27 106.86 404.15C106.87 404.03 107.59 403.99 108.47 403.93C109.35 403.87 110.06 403.82 110.08 403.94Z" fill="#263238"/>
                                    <path d="M111.09 396.92C111.09 397.04 110.37 397.06 109.59 396.69C108.81 396.32 108.36 395.79 108.44 395.69C108.52 395.59 109.06 395.97 109.77 396.29C110.48 396.61 111.1 396.8 111.09 396.92Z" fill="#263238"/>
                                    <path d="M122.75 410.24C122.69 410.34 121.96 410 121.12 409.47C120.28 408.94 119.66 408.47 119.73 408.33C119.8 408.19 120.52 408.58 121.36 409.1C122.2 409.62 122.82 410.14 122.75 410.24Z" fill="#263238"/>
                                    <path d="M151.22 431.06H88.9697V442.09H151.22V431.06Z" fill="#FF725E"/>
                                    <path d="M93.6201 439.91L101.19 472.88H138.29L146.57 439.91H93.6201Z" fill="#FF725E"/>
                                    <path d="M151.19 442.11C151.19 442.23 137.19 442.33 119.83 442.33C102.47 442.33 88.4697 442.23 88.4697 442.11C88.4697 441.99 102.47 441.89 119.83 441.89C137.19 441.89 151.19 442 151.19 442.11Z" fill="#263238"/>
                                    <path d="M116.57 404.18C116.47 404.24 116.04 403.75 115.62 403.07C115.2 402.39 114.94 401.79 115.04 401.73C115.14 401.67 115.57 402.16 115.99 402.84C116.41 403.52 116.67 404.11 116.57 404.18Z" fill="#263238"/>
                                    <path d="M116.59 413.05C116.56 412.474 116.673 411.9 116.92 411.38C117.21 410.49 117.55 409.8 117.66 409.84C117.69 410.418 117.577 410.995 117.33 411.52C117 412.4 116.71 413.09 116.59 413.05Z" fill="#263238"/>
                                    <path d="M119.63 422.37C119.52 422.43 119.06 421.68 118.63 420.71C118.285 420.147 118.067 419.516 117.99 418.86C118.1 418.8 118.56 419.55 118.99 420.52C119.335 421.083 119.554 421.714 119.63 422.37Z" fill="#263238"/>
                                    <path d="M113.06 429.87C112.97 429.79 113.34 429.22 113.89 428.58C114.44 427.94 114.95 427.5 115.05 427.58C115.15 427.66 114.77 428.24 114.22 428.87C113.67 429.5 113.15 430 113.06 429.87Z" fill="#263238"/>
                                    <path d="M111.681 416.26C111.681 416.38 111.061 416.37 110.341 416.26C109.621 416.15 109.061 415.94 109.081 415.82C109.101 415.7 109.701 415.71 110.411 415.82C111.121 415.93 111.701 416.14 111.681 416.26Z" fill="#263238"/>
                                    <path d="M328.25 355.24H180.63V472.96H328.25V355.24Z" fill="#E0E0E0"/>
                                    <g opacity="0.3">
                                    <path d="M191.38 355.48C208.76 361.68 227.93 363.01 246.58 363.36C250.45 363.43 254.36 363.42 258.1 364.36C261.84 365.3 265.45 367.43 267.37 370.78C269.46 374.43 269.24 379 268.05 383.03C266.86 387.06 264.81 390.79 263.33 394.72C260.709 401.673 259.908 409.18 261 416.53C261.336 419.661 262.39 422.673 264.08 425.33C264.936 426.651 266.089 427.752 267.447 428.548C268.805 429.343 270.33 429.81 271.9 429.91C277.51 429.99 281.35 424.48 284.13 419.6C295.54 399.6 307.88 376.9 315.18 355.07L191.38 355.48Z" fill="black"/>
                                    </g>
                                    <path d="M230.93 472.33C230.78 472.33 230.67 446.24 230.67 414.06C230.67 381.88 230.78 355.78 230.93 355.78C231.08 355.78 231.19 381.87 231.19 414.06C231.19 446.25 231.07 472.33 230.93 472.33Z" fill="#455A64"/>
                                    <path d="M291.419 268.24C290.979 265.3 282.199 255.6 282.199 255.6C282.199 255.6 277.819 237.99 276.499 232.8C276.06 231.299 275.417 229.866 274.589 228.54C271.485 223.368 267.069 219.108 261.789 216.19L249.639 209.36L246.739 202.9L217.559 187.9L209.649 201.9C209.649 201.9 190.439 208 187.899 212.33C185.359 216.66 189.159 275.13 189.159 275.13C189.159 275.13 181.159 308.85 179.429 315.83C177.699 322.81 179.969 331.13 179.969 331.13L194.279 313.18C198.729 307.6 208.489 305.03 215.499 305.13L275.409 305.4L272.999 288.62L321.469 298C321.469 298 329.799 281.74 330.469 281.42C331.139 281.1 291.419 268.24 291.419 268.24Z" fill="#FF725E"/>
                                    <path d="M216.32 207.35C215.9 206.92 219.7 219.77 224.48 232.86C227.051 239.934 228.361 247.404 228.35 254.93V302.33L257.07 300.38L252.78 269.16C252.78 269.16 249.28 249.02 248.3 241.36C247.4 234.36 244.3 215.36 244.3 215.36C244.3 215.36 228.35 219.6 216.32 207.35Z" fill="white"/>
                                    <path d="M280.06 280C280.015 279.759 280.015 279.511 280.06 279.27C280.126 278.616 280.253 277.97 280.44 277.34C281.047 275.237 282.175 273.321 283.72 271.77C285.273 270.224 287.188 269.09 289.29 268.47C289.92 268.283 290.566 268.156 291.22 268.09C291.458 268.045 291.702 268.045 291.94 268.09C291.94 268.18 290.94 268.29 289.4 268.82C287.401 269.506 285.586 270.64 284.094 272.136C282.601 273.632 281.471 275.45 280.79 277.45C280.26 279 280.15 280 280.06 280Z" fill="#263238"/>
                                    <path d="M281.54 268.64C282.874 267.857 284.384 267.42 285.93 267.37C287.461 267.158 289.021 267.344 290.46 267.91C290.46 268.07 288.46 267.68 285.97 267.91C283.48 268.14 281.6 268.79 281.54 268.64Z" fill="#263238"/>
                                    <g opacity="0.3">
                                    <path d="M186.27 294.51L183.77 297.33C186.376 300.548 189.909 302.887 193.89 304.03C197.901 304.882 202 305.251 206.1 305.13C207.76 305.211 209.424 305.052 211.04 304.66C212.67 304.19 214.18 303.27 215.87 303.17C217.003 303.176 218.13 303.337 219.22 303.65C220.316 303.961 221.475 303.978 222.58 303.7C223.127 303.552 223.612 303.232 223.963 302.788C224.314 302.343 224.513 301.797 224.53 301.23C212.063 297.587 199.232 295.333 186.27 294.51Z" fill="black"/>
                                    </g>
                                    <path d="M268.27 298.13L261.27 290.65L246.72 287C246.72 287 233.44 291 233.12 291C232.8 291 221.56 287.92 221.56 287.92L217.63 302.34L237.88 303.73L261 302.8C261 302.8 262.21 301.68 261.49 300.91C260.77 300.14 265.36 302.21 265.9 302.23C266.44 302.25 267.48 302.67 268.2 301.81C268.69 301.17 269.35 299.28 268.27 298.13Z" fill="#FFBE9D"/>
                                    <path d="M261.49 300.86C260.704 300.712 259.934 300.491 259.19 300.2C257.79 299.76 255.84 299.2 253.67 298.6C251.5 298 249.5 297.6 248.05 297.35C247.256 297.247 246.476 297.062 245.72 296.8C246.521 296.765 247.323 296.826 248.11 296.98C250.022 297.247 251.919 297.621 253.79 298.1C255.663 298.576 257.507 299.163 259.31 299.86C260.076 300.099 260.809 300.435 261.49 300.86Z" fill="#EB996E"/>
                                    <path d="M268.27 298.13C267.179 297.854 266.105 297.521 265.05 297.13C263.05 296.47 260.32 295.65 257.25 294.8C254.18 293.95 251.46 292.98 249.39 292.86C248.275 292.803 247.157 292.846 246.05 292.99C246.321 292.841 246.615 292.74 246.92 292.69C247.737 292.513 248.575 292.446 249.41 292.49C251.55 292.49 254.41 293.4 257.41 294.3C260.41 295.2 263.23 296.03 265.19 296.79C266.19 297.16 266.94 297.5 267.48 297.74C267.761 297.83 268.027 297.962 268.27 298.13Z" fill="#EB996E"/>
                                    <path d="M187.9 212.33C187.9 212.33 177.45 219.6 174.9 230.06C172.35 240.52 161.34 283.27 163.6 289.66C165.86 296.05 209.8 302.33 209.8 302.33L215.01 280.33L192.69 274.22C192.69 274.22 192.98 253.87 193.26 245.44C193.54 237.01 187.9 212.33 187.9 212.33Z" fill="#FF725E"/>
                                    <path d="M214.729 283.64L221.919 285.41L217.589 301.99L210.529 300.86L214.729 283.64Z" fill="white"/>
                                    <path d="M163.44 287.4C163.44 287.4 163.44 287.77 163.55 288.4C163.793 289.367 164.327 290.236 165.08 290.89C166.99 292.67 170.64 294.15 175.26 294.98C177.37 295.68 179.78 296.23 182.35 296.81C184.92 297.39 187.7 297.98 190.62 298.57C196.47 299.77 202.94 301.03 209.8 302.31L209.5 302.5C209.99 300.5 210.5 298.31 211.05 296.12C212.28 291.12 213.48 286.2 214.61 281.57L214.79 281.87L195 276.26C192.268 275.11 189.311 274.59 186.35 274.74C184.1 274.865 181.889 275.387 179.82 276.28C178.505 276.849 177.249 277.546 176.07 278.36L175.16 279.02C175.061 279.1 174.953 279.171 174.84 279.23C174.84 279.23 174.93 279.13 175.12 278.97C175.31 278.81 175.6 278.56 176 278.26C177.161 277.399 178.412 276.665 179.73 276.07C181.812 275.126 184.048 274.567 186.33 274.42C189.343 274.228 192.36 274.727 195.15 275.88L215 281.44L215.24 281.5L215.18 281.74C214.05 286.37 212.86 291.25 211.64 296.3C211.1 298.48 210.58 300.64 210.08 302.67L210.02 302.91H209.78C202.92 301.62 196.46 300.34 190.61 299.12C187.69 298.51 184.92 297.9 182.34 297.3C179.76 296.7 177.34 296.13 175.22 295.4C170.54 294.51 166.89 292.97 164.96 291.1C164.195 290.412 163.669 289.498 163.46 288.49C163.388 288.131 163.381 287.762 163.44 287.4Z" fill="#263238"/>
                                    <path d="M193.91 275.71C193.85 275.78 193.1 275.09 191.76 274.2C188.251 271.85 184.102 270.639 179.88 270.73C178.26 270.73 177.27 270.95 177.25 270.86C178.088 270.557 178.969 270.392 179.86 270.37C184.199 270.056 188.506 271.308 192 273.9C192.732 274.394 193.378 275.006 193.91 275.71Z" fill="#263238"/>
                                    <path d="M196.84 239.91C196.876 240.356 196.876 240.804 196.84 241.25C196.84 242.12 196.75 243.36 196.63 244.9C196.38 247.97 195.93 252.2 195.12 256.84C194.487 260.81 193.561 264.727 192.35 268.56C192.11 269.29 191.85 269.95 191.64 270.56L191 272C190.812 272.411 190.581 272.8 190.31 273.16C190.435 272.731 190.602 272.316 190.81 271.92C190.98 271.49 191.16 271 191.37 270.46C191.58 269.92 191.78 269.22 192 268.46C193.093 264.613 193.964 260.707 194.61 256.76C195.41 252.14 195.92 247.93 196.26 244.87C196.41 243.42 196.54 242.21 196.64 241.25C196.653 240.797 196.72 240.347 196.84 239.91Z" fill="#263238"/>
                                    <path d="M222.32 228.58C222.32 228.58 222.22 228.31 221.98 227.84C221.624 227.174 221.104 226.61 220.47 226.2C220 225.878 219.463 225.668 218.9 225.584C218.337 225.501 217.762 225.548 217.22 225.72C216.532 225.956 215.918 226.366 215.437 226.912C214.957 227.457 214.627 228.118 214.48 228.83L214.34 229.54L214.03 228.88C212.86 226.37 211.842 223.792 210.98 221.16C209.452 216.776 208.687 212.163 208.72 207.52C208.72 206.63 208.84 205.83 208.89 205.12C208.94 204.41 209.1 203.82 209.21 203.34C209.315 202.836 209.462 202.341 209.65 201.86C209.309 203.729 209.118 205.621 209.08 207.52C209.162 212.111 209.969 216.661 211.47 221C212.332 223.612 213.334 226.176 214.47 228.68L214.02 228.74C214.192 227.954 214.569 227.227 215.111 226.632C215.653 226.037 216.343 225.595 217.11 225.35C217.705 225.171 218.334 225.132 218.947 225.238C219.56 225.344 220.139 225.591 220.64 225.96C221.289 226.417 221.801 227.043 222.12 227.77C222.207 227.962 222.271 228.163 222.31 228.37C222.33 228.5 222.34 228.58 222.32 228.58Z" fill="#263238"/>
                                    <path d="M246.7 230.83C246.593 230.44 246.516 230.042 246.47 229.64C246.251 228.538 246.181 227.411 246.26 226.29C246.485 224.565 247.344 222.986 248.67 221.86C249.49 221.185 250.518 220.814 251.58 220.81C252.159 220.803 252.732 220.922 253.261 221.159C253.789 221.395 254.259 221.744 254.64 222.18L254.2 222.44L253.25 220.05C251.38 215.34 249.72 211.05 248.54 207.96L247.17 204.27C246.98 203.829 246.835 203.37 246.74 202.9C246.986 203.313 247.194 203.748 247.36 204.2C247.76 205.2 248.27 206.37 248.89 207.83C250.16 210.9 251.89 215.15 253.74 219.83L254.68 222.23L255.29 223.77L254.29 222.48C253.96 222.097 253.55 221.791 253.088 221.584C252.627 221.376 252.126 221.273 251.62 221.28C250.668 221.274 249.743 221.595 249 222.19C247.75 223.235 246.917 224.693 246.65 226.3C246.542 227.395 246.563 228.499 246.71 229.59C246.758 230.002 246.754 230.419 246.7 230.83Z" fill="#263238"/>
                                    <path d="M265.25 233.31C265.39 233.31 267.65 248.49 270.3 267.26C272.95 286.03 274.97 301.26 274.83 301.26C274.69 301.26 272.43 286.08 269.78 267.26C267.13 248.44 265.11 233.33 265.25 233.31Z" fill="#263238"/>
                                    <g opacity="0.3">
                                    <path d="M211.279 221.32C211.401 225.209 212.245 229.041 213.769 232.62C214.859 231.27 216.069 229.85 217.769 229.45C219.829 228.96 221.919 230.23 223.409 231.79C223.151 230.231 222.535 228.752 221.609 227.47C221.147 226.84 220.546 226.326 219.852 225.967C219.158 225.608 218.39 225.414 217.609 225.4C216.831 225.419 216.081 225.696 215.477 226.189C214.874 226.681 214.453 227.361 214.279 228.12L211.409 221.39" fill="black"/>
                                    </g>
                                    <path d="M194.389 232.31C194.299 232.31 194.329 231.26 194.029 229.63C193.618 227.507 192.793 225.485 191.599 223.68C190.927 222.704 190.16 221.796 189.309 220.97C188.604 220.303 187.838 219.703 187.019 219.18C185.609 218.29 184.619 217.95 184.659 217.86C184.905 217.878 185.145 217.939 185.369 218.04C185.649 218.116 185.921 218.22 186.179 218.35C186.525 218.499 186.859 218.673 187.179 218.87C188.049 219.362 188.864 219.945 189.609 220.61C190.506 221.446 191.308 222.378 191.999 223.39C193.239 225.248 194.045 227.36 194.359 229.57C194.459 230.232 194.492 230.902 194.459 231.57C194.459 232.05 194.429 232.31 194.389 232.31Z" fill="#263238"/>
                                    <path d="M252.44 161C251.868 155.645 249.302 150.702 245.252 147.153C241.201 143.605 235.963 141.711 230.58 141.85H229.47C217.52 142.85 211.7 153.56 213.04 165.47L216.32 207.37L217.71 209.07C220.83 212.904 225.131 215.597 229.943 216.731C234.754 217.865 239.805 217.376 244.31 215.34L243.39 201.86C243.39 201.86 254.85 199.78 254.69 188.28C254.62 182.7 253.54 171.28 252.44 161Z" fill="#FFBE9D"/>
                                    <path d="M243.48 201.83C238.144 201.979 232.878 200.59 228.31 197.83C228.31 197.83 232.06 206.02 243.51 204.69L243.48 201.83Z" fill="#EB996E"/>
                                    <path d="M248.799 170.46C248.811 170.887 248.657 171.302 248.37 171.619C248.083 171.935 247.685 172.129 247.259 172.16C247.051 172.181 246.84 172.159 246.64 172.098C246.44 172.037 246.254 171.936 246.093 171.802C245.932 171.668 245.799 171.503 245.702 171.318C245.606 171.132 245.547 170.929 245.529 170.72C245.515 170.292 245.668 169.876 245.955 169.559C246.243 169.241 246.642 169.048 247.069 169.02C247.278 168.998 247.489 169.018 247.69 169.079C247.89 169.14 248.077 169.24 248.238 169.375C248.4 169.509 248.532 169.674 248.629 169.86C248.725 170.047 248.783 170.251 248.799 170.46Z" fill="#263238"/>
                                    <path d="M249.53 164.21C249.4 164.41 248.1 164.55 246.53 165.39C244.96 166.23 244.04 167.28 243.83 167.26C243.62 167.24 243.83 166.97 244.22 166.5C244.818 165.837 245.529 165.285 246.32 164.87C247.075 164.448 247.903 164.173 248.76 164.06C249.35 164 249.6 164.11 249.53 164.21Z" fill="#263238"/>
                                    <path d="M232.2 171.33C232.219 171.759 232.068 172.178 231.779 172.497C231.491 172.815 231.088 173.007 230.66 173.03C230.451 173.052 230.24 173.032 230.039 172.971C229.838 172.91 229.652 172.81 229.49 172.675C229.329 172.541 229.196 172.376 229.1 172.189C229.004 172.003 228.946 171.799 228.93 171.59C228.916 171.162 229.069 170.746 229.356 170.429C229.643 170.111 230.043 169.918 230.47 169.89C230.678 169.868 230.889 169.888 231.09 169.949C231.291 170.01 231.477 170.11 231.639 170.245C231.8 170.379 231.933 170.544 232.029 170.731C232.125 170.917 232.183 171.121 232.2 171.33Z" fill="#263238"/>
                                    <path d="M232 166.62C231.77 166.62 230.7 165.95 229 165.53C227.3 165.11 225.89 165.2 225.74 165.06C225.59 164.92 225.92 164.86 226.53 164.81C227.421 164.753 228.315 164.838 229.18 165.06C230.017 165.259 230.815 165.597 231.54 166.06C231.94 166.38 232.06 166.6 232 166.62Z" fill="#263238"/>
                                    <path d="M238.879 180.35C239.806 180.041 240.766 179.843 241.739 179.76C242.189 179.76 242.609 179.6 242.679 179.29C242.708 178.827 242.592 178.366 242.349 177.97L240.919 174.56C238.919 169.72 237.499 165.73 237.699 165.66C237.899 165.59 239.699 169.44 241.629 174.28C242.109 175.48 242.569 176.62 242.999 177.71C243.295 178.246 243.394 178.869 243.279 179.47C243.217 179.631 243.119 179.776 242.993 179.894C242.866 180.012 242.715 180.1 242.549 180.15C242.303 180.227 242.047 180.271 241.789 180.28C240.826 180.428 239.848 180.452 238.879 180.35Z" fill="#263238"/>
                                    <path d="M231.999 159.8C231.799 160.27 230.049 159.96 227.999 160.1C225.949 160.24 224.209 160.74 223.999 160.29C223.879 160.08 224.199 159.64 224.889 159.21C225.818 158.682 226.854 158.373 227.92 158.307C228.986 158.241 230.053 158.42 231.039 158.83C231.699 159.18 232.059 159.57 231.999 159.8Z" fill="#263238"/>
                                    <path d="M249.489 160.19C249.099 160.6 247.579 160.19 245.759 160.19C243.939 160.19 242.389 160.5 242.029 160.08C241.869 159.88 242.109 159.47 242.779 159.08C243.703 158.596 244.736 158.358 245.779 158.39C246.83 158.383 247.865 158.652 248.779 159.17C249.439 159.58 249.659 160 249.489 160.19Z" fill="#263238"/>
                                    <path d="M255.789 150.78C255.688 150.255 255.398 149.785 254.975 149.459C254.551 149.133 254.023 148.974 253.489 149.01C254.064 148.846 254.601 148.571 255.069 148.2C255.302 148.012 255.477 147.762 255.575 147.479C255.672 147.196 255.687 146.892 255.619 146.6C255.319 145.71 254.179 145.52 253.279 145.27C252.098 144.927 251.051 144.229 250.279 143.27C249.189 141.89 248.769 140.09 247.819 138.6C247.206 137.638 246.384 136.826 245.415 136.224C244.446 135.621 243.355 135.244 242.221 135.119C241.086 134.994 239.939 135.126 238.862 135.503C237.785 135.88 236.807 136.495 235.999 137.3C235.117 136.393 234.031 135.71 232.831 135.307C231.632 134.904 230.353 134.794 229.102 134.984C227.852 135.175 226.664 135.662 225.639 136.404C224.614 137.145 223.781 138.121 223.209 139.25C221.675 138.61 219.997 138.395 218.351 138.626C216.706 138.857 215.152 139.527 213.854 140.564C212.555 141.602 211.56 142.97 210.971 144.524C210.383 146.079 210.223 147.763 210.509 149.4L217.669 153.79C217.727 153.246 217.9 152.72 218.177 152.247C218.453 151.774 218.827 151.365 219.273 151.048C219.719 150.73 220.228 150.511 220.765 150.404C221.302 150.298 221.856 150.307 222.389 150.43C224.549 151.06 225.929 153.56 228.169 153.73C229.919 153.86 231.409 152.51 233.099 152.06C235.149 151.51 237.319 152.33 239.169 153.37C241.019 154.41 242.789 155.71 244.849 156.23C247.31 156.723 249.864 156.419 252.139 155.36C253.15 155.006 254.071 154.436 254.839 153.69C255.218 153.316 255.502 152.857 255.667 152.35C255.832 151.844 255.874 151.306 255.789 150.78Z" fill="#263238"/>
                                    <path d="M218.879 150.41L251.679 150.23C250.496 148.026 248.776 146.155 246.679 144.79C242.324 141.608 237.074 139.882 231.679 139.86C226.329 139.86 215.629 146.73 211.859 150.52C208.859 153.52 208.939 156.11 208.939 156.11C208.559 160.37 211.069 165.11 212.139 169.28C212.679 171.42 213.809 177.9 213.809 177.9C215.609 177.85 216.239 176.39 215.919 174.61L215.389 167.61C217.159 166.134 218.399 164.12 218.92 161.875C219.442 159.63 219.217 157.276 218.279 155.17C217.829 154.55 216.749 151.66 218.879 150.41Z" fill="#263238"/>
                                    <path d="M214.92 167.15C213.25 161.35 211.71 155.15 213.49 149.35H212.49L212.09 149.64C211.165 149.329 210.165 149.321 209.236 149.617C208.306 149.913 207.495 150.497 206.92 151.285C206.345 152.073 206.036 153.024 206.038 153.999C206.039 154.975 206.352 155.924 206.93 156.71C205.955 156.702 205.01 157.049 204.273 157.688C203.537 158.327 203.059 159.214 202.93 160.18C202.933 161.527 203.303 162.847 204 164C204.698 165.156 205.125 166.455 205.25 167.8C205.292 168.47 205.096 169.133 204.697 169.672C204.299 170.212 203.722 170.594 203.07 170.75C203.386 171.432 203.927 171.985 204.603 172.314C205.279 172.644 206.047 172.731 206.78 172.56C206.313 173.7 205.533 174.685 204.53 175.4C206.67 176.86 209.6 176.4 211.92 175.23C213.359 174.651 214.534 173.562 215.22 172.17C215.86 170.56 215.38 168.79 214.92 167.15Z" fill="#263238"/>
                                    <path d="M214.38 173.92C214.18 173.84 206.38 171.79 206.87 179.64C207.36 187.49 215.12 185.42 215.12 185.19C215.12 184.96 214.38 173.92 214.38 173.92Z" fill="#FFBE9D"/>
                                    <path d="M213.24 182.34C213.24 182.34 213.11 182.44 212.88 182.56C212.569 182.714 212.211 182.743 211.88 182.64C211.03 182.38 210.26 181.11 210.14 179.69C210.079 179.012 210.172 178.328 210.41 177.69C210.474 177.438 210.598 177.205 210.772 177.011C210.947 176.818 211.165 176.67 211.41 176.58C211.565 176.532 211.732 176.538 211.883 176.598C212.034 176.658 212.16 176.768 212.24 176.91C212.36 177.12 212.31 177.28 212.36 177.3C212.41 177.32 212.52 177.15 212.44 176.83C212.375 176.643 212.257 176.48 212.1 176.36C211.989 176.283 211.864 176.229 211.731 176.201C211.599 176.174 211.462 176.173 211.33 176.2C211.012 176.283 210.72 176.447 210.482 176.674C210.244 176.901 210.068 177.186 209.97 177.5C209.681 178.202 209.561 178.963 209.62 179.72C209.77 181.3 210.62 182.72 211.82 183C212.033 183.047 212.254 183.046 212.466 182.998C212.679 182.949 212.878 182.854 213.05 182.72C213.24 182.53 213.27 182.35 213.24 182.34Z" fill="#EB996E"/>
                                    <path d="M206.229 167.41C206.229 167.41 206.009 167.11 206.079 166.49C206.163 166.07 206.338 165.672 206.592 165.327C206.846 164.981 207.173 164.696 207.549 164.49C208.676 163.954 209.902 163.658 211.149 163.62C211.847 163.62 212.535 163.456 213.157 163.139C213.779 162.823 214.318 162.364 214.729 161.8C215.382 160.529 215.6 159.078 215.349 157.67C215.159 156.35 214.809 155.16 214.639 154.13C214.323 153.027 214.34 151.854 214.689 150.76C214.696 151.864 214.82 152.963 215.059 154.04C215.279 155.04 215.669 156.21 215.909 157.6C216.211 159.138 215.978 160.733 215.249 162.12C214.815 162.837 214.159 163.392 213.379 163.7C212.681 163.959 211.951 164.121 211.209 164.18C210.027 164.187 208.856 164.418 207.759 164.86C207.416 165.037 207.111 165.28 206.862 165.575C206.613 165.87 206.425 166.212 206.309 166.58C206.264 166.855 206.238 167.132 206.229 167.41Z" fill="#455A64"/>
                                    <path d="M204.33 164C204.33 164 204.16 163.78 204.09 163.29C204.009 162.599 204.146 161.9 204.48 161.29C204.71 160.857 205.026 160.476 205.41 160.17C205.859 159.834 206.373 159.595 206.92 159.47C207.451 159.357 207.956 159.147 208.41 158.85C208.611 158.671 208.769 158.448 208.871 158.198C208.973 157.949 209.017 157.679 209 157.41C209 156.82 208.93 156.19 208.95 155.56C208.958 154.959 209.025 154.359 209.15 153.77C209.352 152.776 209.735 151.827 210.28 150.97C210.454 150.656 210.703 150.39 211.005 150.196C211.307 150.003 211.652 149.887 212.01 149.86C212.53 149.86 212.75 150.1 212.73 150.12C212.71 150.14 212.47 150.03 212.03 150.12C211.729 150.185 211.448 150.317 211.207 150.508C210.965 150.699 210.772 150.943 210.64 151.22C210.189 152.059 209.872 152.963 209.7 153.9C209.603 154.455 209.553 155.017 209.55 155.58C209.55 156.16 209.64 156.78 209.61 157.46C209.625 157.816 209.557 158.171 209.414 158.497C209.27 158.823 209.053 159.111 208.78 159.34C208.264 159.686 207.682 159.921 207.07 160.03C206.615 160.108 206.181 160.282 205.798 160.541C205.415 160.8 205.092 161.137 204.85 161.53C204.439 162.285 204.258 163.144 204.33 164Z" fill="#455A64"/>
                                    <path d="M250.29 143.27C250.34 143.27 249.7 143.7 248.67 143.22C248.09 142.906 247.582 142.473 247.18 141.95C246.684 141.3 246.066 140.753 245.36 140.34C244.441 139.824 243.393 139.586 242.342 139.655C241.291 139.725 240.282 140.098 239.44 140.73C238.44 141.43 237.61 142.42 236.44 143.24C235.845 143.682 235.163 143.992 234.44 144.15C233.691 144.281 232.921 144.209 232.21 143.94C230.79 143.43 229.67 142.56 228.45 142.12C227.272 141.668 226.021 141.441 224.76 141.45C222.587 141.448 220.476 142.169 218.76 143.5C217.613 144.396 216.661 145.515 215.96 146.79C215.47 147.7 215.29 148.26 215.24 148.24C215.19 148.22 215.24 148.09 215.32 147.82C215.433 147.431 215.573 147.05 215.74 146.68C216.386 145.318 217.325 144.116 218.49 143.16C220.245 141.726 222.433 140.926 224.7 140.89C226.034 140.856 227.363 141.083 228.61 141.56C229.93 142.04 231.07 142.91 232.36 143.36C232.971 143.596 233.634 143.661 234.28 143.55C234.906 143.407 235.495 143.134 236.01 142.75C237.06 142 237.94 141.01 239.01 140.27C239.999 139.529 241.204 139.132 242.44 139.14C243.494 139.148 244.528 139.431 245.44 139.96C246.17 140.426 246.805 141.027 247.31 141.73C247.66 142.244 248.113 142.68 248.64 143.01C249.136 143.305 249.727 143.399 250.29 143.27Z" fill="#455A64"/>
                                    <path d="M234.52 182.41C234.77 182.41 235.05 184.03 236.69 184.98C238.33 185.93 240.08 185.45 240.14 185.68C240.2 185.91 239.79 186.08 239.04 186.22C238.06 186.402 237.047 186.232 236.18 185.74C235.349 185.26 234.734 184.48 234.46 183.56C234.28 182.87 234.4 182.42 234.52 182.41Z" fill="#263238"/>
                                    <path d="M337.999 338.66C334.955 336.044 331.004 334.73 326.999 335L320.239 356.47C325.189 356.69 330.129 359.24 334.909 357.93C336.818 357.341 338.519 356.219 339.811 354.695C341.102 353.171 341.931 351.31 342.199 349.33C342.407 347.345 342.134 345.339 341.403 343.482C340.672 341.625 339.505 339.971 337.999 338.66Z" fill="#FF725E"/>
                                    <path d="M327.08 334.51C327.08 334.34 327.81 334.2 329.08 334.21C330.969 334.245 332.839 334.6 334.61 335.26C337.315 336.314 339.657 338.131 341.35 340.49C342.384 341.889 343.147 343.47 343.6 345.15C344.089 346.989 344.144 348.916 343.76 350.78C343.387 352.669 342.545 354.433 341.31 355.91C340.127 357.255 338.629 358.286 336.95 358.91C334.221 359.875 331.279 360.065 328.45 359.46C326.629 359.103 324.851 358.55 323.15 357.81C321.96 357.3 321.33 356.92 321.39 356.75C321.45 356.58 322.17 356.63 323.39 356.84C324.61 357.05 326.46 357.44 328.66 357.64C331.155 357.917 333.679 357.573 336.01 356.64C337.288 356.1 338.419 355.263 339.31 354.2C340.244 353.037 340.882 351.664 341.17 350.2C341.444 348.749 341.424 347.257 341.109 345.814C340.795 344.371 340.193 343.006 339.34 341.8C337.962 339.713 336.067 338.018 333.84 336.88C332.261 336.135 330.609 335.559 328.91 335.16C327.76 334.9 327.06 334.69 327.08 334.51Z" fill="#F5F5F5"/>
                                    <path d="M264.45 415.06L249.75 456.25L300.9 474.8C302.38 469.8 279.47 450.4 279.47 450.4L289.97 424.4L264.45 415.06Z" fill="#FF725E"/>
                                    <g opacity="0.6">
                                    <path d="M264.459 442.45C263.913 442.421 263.374 442.594 262.948 442.938C262.522 443.281 262.237 443.77 262.149 444.31C262.1 444.854 262.262 445.395 262.6 445.823C262.939 446.251 263.429 446.533 263.969 446.61C264.552 446.641 265.126 446.453 265.577 446.083C266.028 445.713 266.325 445.188 266.409 444.61C266.409 443.52 265.329 442.27 264.249 442.42" fill="white"/>
                                    </g>
                                    <path d="M249.75 456.25L251.24 452.1L299.82 471.48C299.82 471.48 301.74 473.29 300.9 474.8L249.75 456.25Z" fill="white"/>
                                    <path d="M280.4 450.44C280.3 450.69 279.01 450.35 277.49 450.71C275.97 451.07 274.93 451.88 274.74 451.71C274.55 451.54 275.44 450.22 277.29 449.82C279.14 449.42 280.53 450.23 280.4 450.44Z" fill="#263238"/>
                                    <path d="M284.999 454.61C284.999 454.87 283.729 454.9 282.499 455.61C281.269 456.32 280.619 457.39 280.369 457.29C280.119 457.19 280.479 455.72 282.029 454.81C283.579 453.9 284.999 454.37 284.999 454.61Z" fill="#263238"/>
                                    <path d="M285.67 462.13C285.43 462.13 285.53 460.73 286.67 459.59C287.81 458.45 289.17 458.34 289.2 458.59C289.23 458.84 288.25 459.3 287.33 460.25C286.41 461.2 285.93 462.2 285.67 462.13Z" fill="#263238"/>
                                    <path d="M282.13 444.25C281.94 444.44 280.92 443.68 279.48 443.25C278.04 442.82 276.77 442.88 276.72 442.62C276.67 442.36 278.04 441.85 279.72 442.37C281.4 442.89 282.33 444.1 282.13 444.25Z" fill="#263238"/>
                                    <path d="M283.109 437.74C282.191 437.659 281.31 437.335 280.559 436.8C279.692 436.304 278.893 435.699 278.179 435C277.752 434.592 277.355 434.154 276.989 433.69C276.759 433.427 276.57 433.13 276.429 432.81C276.329 432.573 276.311 432.31 276.378 432.061C276.445 431.813 276.593 431.594 276.799 431.44C276.987 431.311 277.202 431.228 277.428 431.197C277.654 431.166 277.883 431.187 278.099 431.26C278.424 431.377 278.73 431.538 279.009 431.74C279.509 432.074 279.977 432.452 280.409 432.87C281.132 433.57 281.739 434.38 282.209 435.27C282.723 436.049 282.985 436.967 282.959 437.9C282.819 437.9 282.519 436.95 281.649 435.6C281.133 434.824 280.521 434.115 279.829 433.49C279.42 433.123 278.982 432.788 278.519 432.49C278.029 432.15 277.569 431.98 277.349 432.17C277.129 432.36 277.239 432.28 277.299 432.48C277.403 432.716 277.541 432.935 277.709 433.13C278.037 433.574 278.394 433.995 278.779 434.39C279.428 435.074 280.141 435.693 280.909 436.24C282.229 437.19 283.169 437.61 283.109 437.74Z" fill="#263238"/>
                                    <path d="M282.66 438C282.443 437.093 282.52 436.141 282.88 435.28C283.183 434.316 283.646 433.41 284.25 432.6C284.612 432.124 285.017 431.683 285.46 431.28C285.723 430.994 286.055 430.78 286.424 430.657C286.793 430.535 287.187 430.508 287.57 430.58C287.795 430.67 287.991 430.822 288.135 431.018C288.278 431.215 288.363 431.448 288.38 431.69C288.398 432.026 288.371 432.362 288.3 432.69C288.142 433.502 287.824 434.274 287.364 434.961C286.903 435.648 286.31 436.236 285.62 436.69C284.14 437.69 282.99 437.57 283 437.48C283.01 437.39 284 437.16 285.24 436.16C285.945 435.586 286.518 434.866 286.92 434.05C287.153 433.576 287.318 433.071 287.41 432.55C287.53 431.98 287.48 431.46 287.26 431.45C287.034 431.442 286.81 431.483 286.603 431.571C286.395 431.659 286.21 431.792 286.06 431.96C285.645 432.311 285.263 432.7 284.92 433.12C284.333 433.837 283.855 434.635 283.5 435.49C282.88 437 282.81 438 282.66 438Z" fill="#263238"/>
                                    <path d="M214.899 305.06C207.139 305.24 199.779 306.33 194.429 311.96C187.339 319.43 178.579 330.06 178.079 337.43C177.339 348.43 183.989 351.73 188.839 354.43C190.985 355.59 193.402 356.153 195.839 356.06L285.519 355.38L288.329 340.19L230.329 325.89L232.739 304.06L214.899 305.06Z" fill="#263238"/>
                                    <path d="M232.79 304.05L261 302.75C261 302.75 309.74 305.46 320.13 314.99C330.52 324.52 329.23 335.35 325.76 345.74C322.29 356.13 291.76 422.62 291.76 422.62C291.76 422.62 294.24 426.85 292.85 431.07C292.287 432.918 291.254 434.589 289.852 435.917C288.449 437.246 286.726 438.188 284.85 438.65L257.73 428.65C257.73 428.65 254.05 427.35 256.65 423.88C259.25 420.41 260.98 419.77 260.98 419.77V412.84C260.98 412.84 270.51 395.51 271.37 393.13C272.23 390.75 281.37 355.49 281.37 355.49L287.44 345.06C287.44 345.06 272.77 338.16 260.05 333.16L230.35 325.84L232.79 304.05Z" fill="#263238"/>
                                    <path d="M278.549 427.43C277.729 426.953 276.934 426.432 276.169 425.87C272.338 423.388 268.023 421.748 263.509 421.06C262.563 420.968 261.622 420.828 260.689 420.64C261.641 420.53 262.603 420.547 263.549 420.69C268.175 421.184 272.595 422.865 276.379 425.57C277.181 426.092 277.911 426.717 278.549 427.43Z" fill="#455A64"/>
                                    <path d="M292.1 422.67C291.509 422.185 290.981 421.628 290.53 421.01C289.61 419.94 288.39 418.42 287.12 416.68C285.85 414.94 284.75 413.33 284 412.14C283.553 411.518 283.177 410.847 282.88 410.14C283.417 410.683 283.893 411.284 284.3 411.93L287.54 416.37C288.75 418.01 289.86 419.49 290.82 420.78C291.314 421.362 291.743 421.995 292.1 422.67Z" fill="#455A64"/>
                                    <path d="M301.999 335.58C301.999 335.66 300.999 335.88 299.429 336.4C295.318 337.757 291.593 340.082 288.569 343.18C287.979 343.861 287.355 344.512 286.699 345.13C287.122 344.322 287.661 343.581 288.299 342.93C289.754 341.287 291.438 339.862 293.299 338.7C295.159 337.529 297.181 336.636 299.299 336.05C300.17 335.758 301.081 335.599 301.999 335.58Z" fill="#455A64"/>
                                    <path d="M274.3 382.66C274.311 382.46 274.348 382.262 274.41 382.07L274.83 380.42L276.45 374.37L282 354.46C283.13 350.57 284.09 346.87 285.18 343.51C286.331 340.591 288.07 337.939 290.29 335.72C291.755 334.217 293.378 332.876 295.13 331.72C295.76 331.32 296.25 331.02 296.6 330.84C296.768 330.734 296.945 330.644 297.13 330.57C297.13 330.57 296.45 331.03 295.25 331.88C293.556 333.09 291.982 334.46 290.55 335.97C288.409 338.18 286.735 340.799 285.63 343.67C284.63 346.98 283.63 350.73 282.5 354.6L276.81 374.46C276.07 376.95 275.46 378.98 275.02 380.46C274.81 381.12 274.65 381.66 274.51 382.08C274.466 382.282 274.396 382.477 274.3 382.66Z" fill="#455A64"/>
                                    <path d="M249.349 331.87C249.199 331.87 248.609 326.61 248.019 320.09C247.429 313.57 247.019 308.27 247.219 308.26C247.419 308.25 247.949 313.52 248.539 320.04C249.129 326.56 249.489 331.86 249.349 331.87Z" fill="#455A64"/>
                                    <path d="M202.39 346C202.31 346 201.88 345.19 200.99 343.94C199.841 342.314 198.463 340.863 196.9 339.63C195.324 338.414 193.583 337.427 191.73 336.7C190.3 336.14 189.37 335.93 189.39 335.84C190.232 335.88 191.062 336.052 191.85 336.35C195.761 337.64 199.101 340.249 201.3 343.73C201.785 344.422 202.154 345.189 202.39 346Z" fill="#455A64"/>
                                    <path d="M219.669 326.8C219.669 326.88 218.429 326.12 216.359 325.17C210.928 322.703 204.904 321.842 198.999 322.69C196.749 323.01 195.379 323.41 195.359 323.32C195.65 323.156 195.964 323.034 196.289 322.96C197.158 322.691 198.043 322.477 198.939 322.32C204.912 321.272 211.063 322.155 216.499 324.84C217.314 325.242 218.103 325.696 218.859 326.2C219.156 326.361 219.429 326.563 219.669 326.8Z" fill="#455A64"/>
                                    <path d="M287.229 340.16C286.989 340.148 286.75 340.108 286.519 340.04L284.519 339.61L277.219 338C271.049 336.62 262.509 334.76 253.039 332.89C243.569 331.02 234.969 329.47 228.739 328.4L221.349 327.13L219.349 326.78C219.113 326.752 218.881 326.695 218.659 326.61C218.897 326.616 219.134 326.637 219.369 326.67L221.369 326.93C223.119 327.17 225.649 327.55 228.779 328.04C235.029 329.04 243.649 330.5 253.129 332.38C262.609 334.26 271.129 336.17 277.289 337.64C280.359 338.38 282.849 339 284.559 339.45L286.559 339.97C286.788 340.013 287.012 340.076 287.229 340.16Z" fill="#455A64"/>
                                    <path d="M230.38 328.45C230.28 328.45 230.07 327.27 229.14 325.59C228.596 324.621 227.923 323.729 227.14 322.94C226.152 321.968 225.104 321.059 224 320.22C221.967 318.718 219.816 317.38 217.57 316.22C216.62 315.768 215.704 315.247 214.83 314.66C215.836 314.978 216.811 315.39 217.74 315.89C220.061 316.966 222.266 318.276 224.32 319.8C225.439 320.653 226.495 321.586 227.48 322.59C228.283 323.422 228.957 324.369 229.48 325.4C229.839 326.097 230.111 326.836 230.29 327.6C230.372 327.875 230.403 328.164 230.38 328.45Z" fill="#455A64"/>
                                    <path d="M330.27 342C330.38 342.12 328.88 343.63 327.71 345.94C326.54 348.25 326.16 350.33 326.01 350.3C326.006 348.684 326.434 347.096 327.25 345.7C327.913 344.222 328.955 342.946 330.27 342Z" fill="#263238"/>
                                    <g opacity="0.3">
                                    <path d="M266.08 239.54L272.5 284.76L272.82 285.48C274.326 269.847 272.013 254.082 266.08 239.54Z" fill="black"/>
                                    </g>
                                    <path d="M300.65 265.52L279.78 302L228.35 303.12L228.22 305.45L278.02 305.08L279.6 305.02L332.18 305.13L342.81 286.8L356.39 267.34C356.586 267.052 356.7 266.715 356.719 266.366C356.739 266.017 356.662 265.67 356.499 265.361C356.335 265.053 356.091 264.794 355.791 264.615C355.492 264.435 355.149 264.34 354.8 264.34L302.31 264.54C301.971 264.543 301.639 264.635 301.348 264.807C301.056 264.979 300.815 265.225 300.65 265.52Z" fill="#E0E0E0"/>
                                    <path d="M342.81 286.83C342.81 286.83 345.51 289.31 345.09 291.41C344.67 293.51 344.8 295.08 342.81 295.22C342.847 295.989 342.587 296.742 342.085 297.324C341.582 297.907 340.875 298.274 340.11 298.35C340.11 298.35 340.72 301.65 336.88 302.22C336.88 302.22 335.7 305.75 333.28 304.89C331.885 304.416 330.544 303.796 329.28 303.04L328.57 301.76C328.57 301.76 325 300.42 325.88 298.41C326.42 297.19 328.26 296.87 328.26 296.87C328.048 296.369 327.958 295.825 327.998 295.282C328.038 294.74 328.207 294.215 328.49 293.75C329.34 292.6 332.12 293.55 332.12 293.55C332.062 292.565 332.376 291.595 333 290.83C334 289.7 338.38 292.75 338.38 292.75L340.79 289.87L342.81 286.83Z" fill="#FFBE9D"/>
                                    <path d="M336.879 302.22C335.32 301.556 333.832 300.735 332.439 299.77C330.955 298.951 329.561 297.979 328.279 296.87C329.837 297.54 331.325 298.365 332.719 299.33C334.2 300.151 335.593 301.119 336.879 302.22Z" fill="#EB996E"/>
                                    <path d="M340.11 298.35C338.659 297.78 337.277 297.049 335.99 296.17C334.614 295.441 333.323 294.563 332.14 293.55C333.593 294.116 334.975 294.847 336.26 295.73C337.636 296.459 338.927 297.338 340.11 298.35Z" fill="#EB996E"/>
                                    <path d="M335.71 291.11C335.79 290.96 337.44 291.83 339.36 293.05C340.55 293.737 341.696 294.499 342.79 295.33C342.72 295.5 341 294.72 339.08 293.49C337.876 292.818 336.746 292.02 335.71 291.11Z" fill="#EB996E"/>
                                    <path d="M356.39 265.74C356.307 265.753 356.222 265.753 356.14 265.74H355.4H352.56L342.12 265.82L307.67 265.9H305.16C304.79 265.865 304.417 265.93 304.081 266.088C303.745 266.247 303.458 266.492 303.25 266.8C302.45 268.227 301.67 269.633 300.91 271.02L296.47 279.02L288.94 292.55C286.8 296.37 285.05 299.47 283.82 301.66C283.23 302.66 282.75 303.52 282.41 304.12L282.03 304.75L281.89 304.96C281.89 304.96 281.89 304.87 281.98 304.72L282.32 304.06L283.65 301.56L288.65 292.37L296.09 278.78C297.49 276.23 298.97 273.55 300.49 270.78L302.84 266.53C303.096 266.15 303.448 265.844 303.861 265.644C304.274 265.443 304.732 265.356 305.19 265.39H307.7L342.15 265.46L352.59 265.55L355.43 265.6H356.17C356.25 265.634 356.325 265.681 356.39 265.74Z" fill="#263238"/>
                                    <path d="M319.389 284.09C319.147 284.441 319.011 284.855 318.997 285.282C318.984 285.709 319.094 286.131 319.314 286.497C319.534 286.862 319.855 287.157 320.239 287.345C320.622 287.533 321.052 287.607 321.476 287.557C321.9 287.507 322.301 287.335 322.63 287.063C322.959 286.791 323.203 286.43 323.332 286.023C323.461 285.616 323.47 285.18 323.358 284.768C323.246 284.356 323.017 283.985 322.699 283.7C322.208 283.316 321.584 283.142 320.964 283.215C320.345 283.288 319.779 283.602 319.389 284.09Z" fill="white"/>
                                    <g opacity="0.3">
                                    <path d="M173.389 270C172.949 270.18 172.439 270.48 172.439 271C172.455 271.193 172.536 271.376 172.669 271.517C172.801 271.659 172.978 271.752 173.169 271.78C173.903 271.676 174.621 271.485 175.309 271.21C180.719 270.02 185.169 271.16 190.309 273.21L190.479 273.04C188.439 268.61 179.209 267.63 173.389 270Z" fill="black"/>
                                    </g>
                                    <path d="M452 473C452 473.14 361.55 473.26 250 473.26C138.45 473.26 48 473.1 48 473C48 472.9 138.43 472.74 250 472.74C361.57 472.74 452 472.81 452 473Z" fill="#263238"/>
                                    <path d="M145.42 154.21C145.59 154.38 178.8 142.97 178.8 142.97L166 113.67L136.47 125L152.73 134.34L145.42 154.21Z" fill="#455A64"/>
                                    <path d="M161 122.49V82.5998C161 82.5998 206.81 74.2198 239.82 74.7198C272.83 75.2198 306.82 80.6298 306.82 80.6298V118.93C306.82 118.93 203.36 109.69 161 122.49Z" fill="#455A64"/>
                                    <path d="M160.76 122.53L178.76 143.14V118.93C178.76 118.93 163.71 120.73 160.76 122.53Z" fill="#263238"/>
                                    <path d="M160.999 82.5898C161.149 82.5898 161.209 91.5898 161.149 102.59C161.089 113.59 160.909 122.59 160.769 122.59C160.629 122.59 160.559 113.65 160.619 102.59C160.679 91.5298 160.849 82.5898 160.999 82.5898Z" fill="#263238"/>
                                    <path d="M322.319 151.15C322.149 151.32 288.939 139.91 288.939 139.91L301.709 110.6L331.269 121.91L314.999 131.25L322.319 151.15Z" fill="#455A64"/>
                                    <path d="M307 119.47L289 140.08V117.62C295.035 117.826 301.049 118.444 307 119.47Z" fill="#263238"/>
                                    <path d="M306.799 80.6299C306.939 80.6299 307.099 89.3199 307.149 100.05C307.199 110.78 307.149 119.47 306.979 119.47C306.809 119.47 306.679 110.78 306.629 100.05C306.579 89.3199 306.649 80.6299 306.799 80.6299Z" fill="#263238"/>
                                    <path d="M186.83 100.09C186.735 98.9413 186.982 97.7905 187.538 96.7813C188.094 95.7721 188.935 94.9493 189.956 94.4157C190.978 93.8821 192.134 93.6614 193.28 93.7813C194.426 93.9012 195.511 94.3563 196.4 95.0897L195 96.7697C194.623 96.4257 194.175 96.1694 193.687 96.0189C193.2 95.8685 192.685 95.8278 192.18 95.8997C191.283 96.0243 190.473 96.5 189.927 97.222C189.382 97.944 189.145 98.8533 189.27 99.7497C189.394 100.646 189.87 101.456 190.592 102.002C191.314 102.548 192.223 102.784 193.12 102.66C193.628 102.593 194.115 102.413 194.544 102.132C194.974 101.852 195.334 101.478 195.6 101.04L197.36 102.27C196.708 103.218 195.79 103.953 194.722 104.383C193.654 104.813 192.484 104.918 191.356 104.685C190.229 104.452 189.195 103.892 188.385 103.075C187.574 102.257 187.023 101.219 186.8 100.09H186.83Z" fill="white"/>
                                    <path d="M197.74 98.59C197.553 97.4354 197.718 96.2516 198.212 95.1918C198.707 94.1319 199.508 93.2449 200.512 92.6456C201.516 92.0462 202.677 91.7622 203.845 91.8302C205.012 91.8982 206.132 92.3152 207.06 93.0271C207.988 93.739 208.681 94.713 209.049 95.8231C209.416 96.9331 209.443 98.1281 209.124 99.2532C208.805 100.378 208.155 101.382 207.259 102.134C206.364 102.885 205.263 103.351 204.1 103.47C203.359 103.588 202.601 103.554 201.874 103.37C201.147 103.186 200.465 102.855 199.87 102.398C199.274 101.942 198.779 101.368 198.412 100.714C198.046 100.059 197.817 99.3362 197.74 98.59ZM206.85 97.65C206.768 97.0011 206.498 96.3906 206.072 95.8945C205.646 95.3984 205.083 95.0387 204.454 94.8603C203.825 94.6818 203.157 94.6926 202.534 94.8912C201.911 95.0897 201.36 95.4673 200.95 95.9769C200.541 96.4864 200.29 97.1054 200.23 97.7565C200.169 98.4076 200.302 99.0621 200.611 99.6383C200.92 100.215 201.392 100.687 201.968 100.997C202.544 101.306 203.198 101.44 203.85 101.38C204.299 101.35 204.737 101.228 205.136 101.02C205.535 100.813 205.887 100.524 206.169 100.174C206.451 99.8232 206.657 99.4178 206.774 98.9834C206.891 98.5489 206.917 98.0949 206.85 97.65Z" fill="white"/>
                                    <path d="M221.31 101.92L220.88 95.5399L218.09 101L216.99 101.07L213.54 96.1499L213.95 102.39L211.64 102.54L211 91.9099L213 91.7799L217.4 98.1299L220.89 91.2799L222.89 91.1499L223.61 101.77L221.31 101.92Z" fill="white"/>
                                    <path d="M235.079 94.64C235.139 97.02 233.399 98.54 230.559 98.64L228.41 98.7L228.499 101.64L226.029 101.71L225.729 91L230.339 90.87C233.189 90.83 234.999 92.26 235.079 94.64ZM232.579 94.71C232.579 93.54 231.769 92.88 230.259 92.92L228.259 92.98L228.369 96.68L230.369 96.62C231.869 96.58 232.609 95.87 232.579 94.71Z" fill="white"/>
                                    <path d="M237 90.7695H239.46V99.4095H244.8V101.41H237V90.7695Z" fill="white"/>
                                    <path d="M254.35 99.7196V101.72L246.1 101.48L246.41 90.8296L254.41 91.0696L254.35 93.0696L248.75 92.8996L248.69 95.2196L253.63 95.3596L253.57 97.2796L248.63 97.1296L248.56 99.5996L254.35 99.7196Z" fill="white"/>
                                    <path d="M258.91 93.2996L255.51 93.0996L255.63 91.0996L264.89 91.6596L264.77 93.6596L261.37 93.4496L260.85 102.08L258.39 101.93L258.91 93.2996Z" fill="white"/>
                                    <path d="M273.86 101.18L273.68 103.18L265.46 102.42L266.46 91.8198L274.46 92.5598L274.28 94.5598L268.7 93.9998L268.49 96.3098L273.41 96.7598L273.24 98.6698L268.31 98.2198L268.09 100.67L273.86 101.18Z" fill="white"/>
                                    <path d="M276.939 92.7896L281.729 93.4296C285.179 93.8896 287.269 96.2796 286.849 99.4296C286.429 102.58 283.769 104.43 280.319 103.93L275.529 103.29L276.939 92.7896ZM280.469 101.96C280.905 102.029 281.349 102.011 281.778 101.906C282.206 101.802 282.609 101.612 282.962 101.349C283.316 101.086 283.613 100.754 283.837 100.374C284.06 99.9943 284.205 99.5734 284.263 99.1364C284.322 98.6994 284.292 98.2552 284.175 97.83C284.059 97.4048 283.858 97.0073 283.586 96.6608C283.313 96.3144 282.974 96.026 282.588 95.8128C282.202 95.5996 281.778 95.4659 281.339 95.4195L279.109 95.1196L278.239 101.66L280.469 101.96Z" fill="white"/>
                                    <path d="M327.289 84.2603L329.909 87.3303L337.289 84.2603L335.129 81.0303L327.289 84.2603Z" fill="#455A64"/>
                                    <path d="M323.91 69.6501C323.91 70.4095 323.684 71.152 323.262 71.7834C322.84 72.4149 322.241 72.9071 321.539 73.1978C320.837 73.4884 320.065 73.5644 319.32 73.4163C318.575 73.2681 317.891 72.9024 317.354 72.3653C316.817 71.8283 316.451 71.1441 316.303 70.3992C316.155 69.6543 316.231 68.8822 316.522 68.1806C316.812 67.4789 317.305 66.8792 317.936 66.4572C318.568 66.0353 319.31 65.8101 320.07 65.8101C321.087 65.8127 322.062 66.2181 322.782 66.9377C323.501 67.6573 323.907 68.6324 323.91 69.6501Z" fill="#62AE47"/>
                                    <path d="M237.89 126.93C237.888 127.311 237.773 127.682 237.559 127.997C237.346 128.312 237.043 128.557 236.691 128.7C236.338 128.842 235.951 128.877 235.578 128.8C235.205 128.722 234.864 128.535 234.598 128.263C234.332 127.991 234.152 127.647 234.082 127.272C234.013 126.898 234.055 126.512 234.206 126.162C234.356 125.812 234.607 125.515 234.926 125.308C235.246 125.102 235.619 124.994 236 125C236.506 125.008 236.988 125.215 237.342 125.577C237.695 125.938 237.892 126.424 237.89 126.93Z" fill="#62AE47"/>
                                    <path d="M148.44 98.07C148.44 98.3596 148.353 98.6427 148.192 98.8832C148.031 99.1237 147.801 99.3108 147.534 99.4207C147.266 99.5306 146.971 99.5583 146.687 99.5003C146.404 99.4423 146.143 99.3013 145.94 99.0951C145.737 98.8889 145.599 98.6269 145.545 98.3424C145.491 98.0579 145.523 97.7637 145.636 97.4973C145.75 97.2309 145.94 97.0043 146.183 96.8463C146.426 96.6883 146.71 96.606 147 96.61C147.383 96.6153 147.75 96.7714 148.019 97.0446C148.288 97.3179 148.44 97.6862 148.44 98.07Z" fill="#62AE47"/>
                                    <path d="M143.02 82.0003L147.32 82.2603L149.36 73.8003L145.34 73.9703L143.02 82.0003Z" fill="#455A64"/>
                                    <path d="M277.42 149.53L278.87 153.59L287.45 152.15L285.72 148.52L277.42 149.53Z" fill="#62AE47"/>
                                    <path d="M190.639 126.1L187.989 129.5L193.799 135.97L195.939 132.56L190.639 126.1Z" fill="#455A64"/>
                                    <path d="M180.37 46.23L190.52 56.58L204.81 51.18L205.38 54.73L199.19 67.4L204.9 76.86L204.42 79.69L190.59 77.36L182.41 86.33L180.49 84.88L178.8 73.47L165.9 66.75L165.33 64.14L178.32 60.59L180.37 46.23Z" fill="#62AE47"/>
                                    <g opacity="0.3">
                                    <path d="M180.37 46.23L190.52 56.58L204.81 51.18L205.38 54.73L199.19 67.4L204.9 76.86L204.42 79.69L190.59 77.36L182.41 86.33L180.49 84.88L178.8 73.47L165.9 66.75L165.33 64.14L178.32 60.59L180.37 46.23Z" fill="black"/>
                                    </g>
                                    <path d="M180.37 43.3198L190.52 53.6798L204.81 51.1798L198.1 64.0398L204.9 76.8598L190.59 74.4598L180.49 84.8798L178.36 70.5298L165.33 64.1398L178.32 57.6798L180.37 43.3198Z" fill="#62AE47"/>
                                    <path d="M285.62 46.23L275.46 56.58L261.17 51.18L260.6 54.73L266.79 67.4L261.08 76.86L261.56 79.69L275.39 77.36L283.57 86.33L285.49 84.88L287.18 73.47L300.08 66.75L300.65 64.14L287.66 60.59L285.62 46.23Z" fill="#62AE47"/>
                                    <g opacity="0.3">
                                    <path d="M285.62 46.23L275.46 56.58L261.17 51.18L260.6 54.73L266.79 67.4L261.08 76.86L261.56 79.69L275.39 77.36L283.57 86.33L285.49 84.88L287.18 73.47L300.08 66.75L300.65 64.14L287.66 60.59L285.62 46.23Z" fill="black"/>
                                    </g>
                                    <path d="M285.62 43.3198L275.46 53.6798L261.17 51.1798L267.88 64.0398L261.08 76.8598L275.39 74.4598L285.49 84.8798L287.62 70.5298L300.65 64.1398L287.66 57.6798L285.62 43.3198Z" fill="#62AE47"/>
                                    <path d="M232.66 28.0601L241.11 44.5701L260.6 43.4201L259.94 47.9601L247.56 61.0701L250.91 74.7801L249.26 78.0501L233.27 69.9401L219.88 77.7801L218.09 75.2701L220.37 60.7001L207.19 47.5901L207.49 44.2001L224.69 44.8101L232.66 28.0601Z" fill="#62AE47"/>
                                    <g opacity="0.3">
                                    <path d="M232.66 28.0601L241.11 44.5701L260.6 43.4201L259.94 47.9601L247.56 61.0701L250.91 74.7801L249.26 78.0501L233.27 69.9401L219.88 77.7801L218.09 75.2701L220.37 60.7001L207.19 47.5901L207.49 44.2001L224.69 44.8101L232.66 28.0601Z" fill="black"/>
                                    </g>
                                    <path d="M233.76 24.52L242.21 41.02L260.6 43.42L247.51 56.56L250.91 74.78L234.38 66.4L218.09 75.27L220.96 56.95L207.49 44.2L225.8 41.26L233.76 24.52Z" fill="#62AE47"/>
                                    <path d="M267.43 172.63L281.55 174.19C286.317 167.441 293.436 162.72 301.507 160.953C309.579 159.186 318.019 160.502 325.17 164.642C332.321 168.782 337.664 175.447 340.151 183.326C342.637 191.206 342.087 199.731 338.608 207.225C335.128 214.719 328.972 220.642 321.348 223.828C313.724 227.014 305.185 227.233 297.407 224.443C289.63 221.652 283.178 216.054 279.318 208.748C275.459 201.442 274.471 192.957 276.55 184.96L267.43 172.63Z" fill="#FAFAFA"/>
                                    <path d="M267.43 172.63C267.98 173.37 270.84 177.15 276.68 184.9H276.47C276.477 184.884 276.488 184.869 276.501 184.857C276.515 184.846 276.53 184.837 276.548 184.832C276.565 184.827 276.583 184.826 276.601 184.83C276.618 184.833 276.635 184.84 276.65 184.85C276.673 184.864 276.692 184.885 276.702 184.91C276.713 184.936 276.716 184.964 276.71 184.99C275.278 190.6 275.354 196.489 276.93 202.06C277.392 203.732 278.005 205.359 278.76 206.92C279.16 207.72 279.54 208.54 279.97 209.35L281.46 211.72C286.218 218.618 293.415 223.453 301.6 225.25C306.13 226.246 310.821 226.266 315.36 225.31C320.144 224.269 324.641 222.186 328.53 219.21C332.602 216.117 335.877 212.096 338.08 207.48C340.36 202.787 341.485 197.617 341.36 192.4C341.214 187.41 339.95 182.516 337.66 178.08C335.506 173.875 332.454 170.195 328.72 167.3C321.993 162.101 313.528 159.681 305.07 160.54C301.496 160.969 298.007 161.933 294.72 163.4C289.49 165.817 284.984 169.561 281.65 174.26C281.639 174.279 281.623 174.295 281.603 174.304C281.583 174.313 281.561 174.315 281.54 174.31L267.43 172.63L281.57 174.06L281.45 174.12C284.773 169.358 289.3 165.562 294.57 163.12C297.873 161.598 301.391 160.593 305 160.14C313.574 159.232 322.168 161.66 329 166.92C332.798 169.849 335.903 173.581 338.09 177.85C340.428 182.35 341.717 187.321 341.86 192.39C341.991 197.689 340.853 202.942 338.54 207.71C336.298 212.397 332.969 216.48 328.83 219.62C324.877 222.637 320.309 224.747 315.45 225.8C310.83 226.772 306.056 226.741 301.45 225.71C293.178 223.887 285.91 218.985 281.12 212L279.62 209.6C279.19 208.78 278.81 207.94 278.4 207.13C277.647 205.548 277.038 203.901 276.58 202.21C275.011 196.579 274.97 190.632 276.46 184.98L276.7 185.07C276.681 185.099 276.652 185.12 276.619 185.13C276.586 185.139 276.551 185.135 276.52 185.12L267.43 172.63Z" fill="#263238"/>
                                    <path d="M296.399 181.44C296.411 181.593 296.411 181.747 296.399 181.9C296.399 182.23 296.399 182.65 296.399 183.18C296.399 184.34 296.399 185.95 296.459 187.99C296.459 192.14 296.529 197.99 296.579 204.99L296.349 204.76H319.879C319.939 204.7 319.389 205.25 319.619 205.01V201.81C319.619 200.76 319.619 199.71 319.619 198.67V192.58C319.619 188.64 319.619 184.91 319.619 181.48L319.839 181.71L302.839 181.59L298.029 181.54H296.749H296.289H296.699H297.939L302.689 181.48L319.819 181.37H320.049V181.59C320.049 185.02 320.049 188.75 320.049 192.69V205C320.289 204.77 319.739 205.33 319.789 205.27H296.259H296.029V205C296.029 197.94 296.109 192.05 296.139 187.87C296.139 185.87 296.139 184.25 296.199 183.11C296.199 182.6 296.199 182.19 296.199 181.87C296.246 181.718 296.314 181.574 296.399 181.44Z" fill="#263238"/>
                                    <path d="M317.39 185.33C317.73 185.6 314.57 190.16 310.32 195.52C309.48 196.59 308.65 197.61 307.88 198.52L307.38 199.13L306.82 198.62C303.14 195.31 300.65 192.77 300.87 192.51C301.09 192.25 303.98 194.35 307.79 197.51L306.72 197.61C307.45 196.61 308.25 195.61 309.1 194.55C313.34 189.19 317.06 185.07 317.39 185.33Z" fill="#62AE47"/>
                               </svg>
                               <div className="d-flex">
                               <h6 class="text-lg f-500 mb-0" style={{ color: 'green' }} >Form Completed Successfully </h6>
                               </div>
                               
                   

                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    );
};

export default WithRoutify(FormCompletion);
