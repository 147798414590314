import React, { useEffect, useRef, useState,memo, useCallback } from 'react';
import { isSet,isObject } from '../../utils/commonUtils';
import { calendarWidgetVisitTypeBasedColor } from './calendarFunctions';
import Buttons from '../UI/Buttons';
import VisitsService from '../../services/VisitsService';
import FormInputs from '../UI/FormInputs';
import WithRoutify from '../../hoc/WithRoutify';
import Icons from '../UI/Icons';
import Button from '../UI/Buttons/Button';
import Acl from '../../hoc/Acl';
import PERMISSIONS from '../../utils/AclPermissions';
import withReduxState from '../../hoc/wReduxState';
import { acl_check } from '../../utils/aclUtils';

const VisitQuickViewPopup = ({ position, modalToogle, isShow, quickViewDetails, showQuickVisitView, ...props }) => {
    const data = quickViewDetails;
    const popupRef = useRef(null);
    const [validation , setValidation] = useState({});
    let hasEditAccess = acl_check(props.profile.permissions,PERMISSIONS.VISITS.EDIT);

    const [visitStatus, setVisitStatus] = useState(data?.visit_status);

    const handleClickOutside = useCallback((event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            modalToogle(); // Close the popup if clicked outside
        }
    }, [modalToogle]);

    useEffect(()=>{
       setValidation({});
    },[visitStatus]);

    useEffect(() => {
        // Attach the event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Detach the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const dateParts = data?.visit_date.split("/");
    const month = parseInt(dateParts[0], 10) - 1; // Months are zero-based in JavaScript Date
    const day = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);
    const date = new Date(year, month, day);

    // Format the date to the desired format
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    const headerButtons = [
        {
            className: "btn btn-icon btn-transparent-dark",
            iconType: "Edit",
            iconColor: '#5f6368',
            label: "",
            acl: "any",
            title: "Detail view",
            clickHandler: () => {
                modalToogle();
                props.viewVisitHandler(data.visit_id, data.snapClinicConsentForm, data.employee, data.snapVisitConsentForm);
            }
        },
        {
            className: "btn btn-icon btn-transparent-dark",
            iconType: "Close",
            label: "",
            acl: "any",
            title: "Cancel",
            clickHandler: modalToogle
        }
    ];

    const updateVisitStatusHandler = async () => {
        const { Toast, Loader } = props;
        let updateValidation = {...validation}
        const payload = {
            visit_status: visitStatus
        };
        Loader(true);
        try {
            const data = await VisitsService.updateVisitStatus(isSet(payload.organization, null), quickViewDetails.visit_id, payload);
            if (data.status && data.status === "ERROR") {
                if (isObject(data.data)) {
                  for (let key in data.data) {
                    updateValidation = {
                      ...updateValidation,
                      [key]: data.data[key].join(","),
                    };
                  }
                } else {
                  Toast.error(isSet(data.data,'Something went wrong..'));
                  Loader(false);
                }
                
                if (Object.keys(updateValidation).length > 0) {
                  setValidation(updateValidation);
                  Loader(false);
                  return;
                }
            } else {
                props.calendarActionHandler();
                Toast.success('Visits Updated Successfully');
                modalToogle(); 
                Loader(false);
            }
        } catch (e) {
            console.error(e);
            Loader(false);
        }
    };

    return (
        <div
            ref={popupRef}
            className='calendarBox'
            id="calendarQuickViewPopup"
            style={{
                left: position?.left,
                zIndex: 999,
                position: 'absolute',
                top: position?.top,
                display: isShow ? 'block' : 'none',
            }}
        >
            <div className="card border-0 shadow-lg" style={{ width: '340px' }}>
                <div className="card-header">
                    <div className="addAction d-flex justify-content-between align-items-center">
                        <div className="card-title mb-0 text-truncate w-75">
                            <h6 className="text-md mb-0 font-weight-bold text-truncate" title={`${data?.employee?.first_name} ${data?.employee?.last_name}`}>
                                {data?.employee?.first_name} {data?.employee?.last_name}
                            </h6>
                        </div>
                        <div>
                            <Buttons type='BtnList' buttonList={headerButtons} />
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-1">
                            <Icons type={'Dot'} className={calendarWidgetVisitTypeBasedColor(data?.visit_category, 'TEXT')} />
                        </div>
                        <div className="col-10">
                            <p className={`text-md font-weight-bold mb-1 ${calendarWidgetVisitTypeBasedColor(data?.visit_category, 'TEXT')}`}>
                                {data?.visit_category}
                            </p>
                            <p className="text-md mb-1">{formattedDate}</p>
                            <p className="text-md mb-1">{data?.visit_time}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-1" />
                        <div className="col-11">
                            <div className="form-group mt-3 mb-0">
                                <label className="text-sm text-truncate mb-0 opacity-75">
                                    Visit Status
                                </label>
                                <div>
                                    <FormInputs
                                        readOnly={!hasEditAccess}
                                        type='SelectList'
                                        className="form-control form-control-solid"
                                        name="visit_status"
                                        value={visitStatus}
                                        id="visit_status"
                                        options={props.visitStatusOption}
                                        changeHandler={setVisitStatus}
                                    />
                                    {isSet(validation['visit_status'], "") !== "" ? (
                                        <span className="text-danger m-1">
                                        {isSet(validation['visit_status'], "")}
                                        </span>
                                    ) : ("")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                    <Acl acl={PERMISSIONS.VISITS.EDIT}>
                    <div className="card-footer d-flex justify-content-center">
                    <Button 
                        clickHandler={updateVisitStatusHandler}
                        acl="any"
                        className="btn btn-primary text-sm" label="Update" title="Update Status" />
                          </div>
                    </Acl>
                    {/* <button onClick={updateVisitStatusHandler} className="btn btn-primary text-sm" type="button">Update</button> */}
              
            </div>
        </div>
    );
};

VisitQuickViewPopup.defaultProps = {
    position: {
        x: 0,
        y: 0
    }
};

export default withReduxState(WithRoutify(memo(VisitQuickViewPopup)));
