import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import FormInputs from "../../components/UI/FormInputs";
import WithRoutify from "../../hoc/WithRoutify";
import { isObject, isSet } from "../../utils/commonUtils";
import { CREATED, OK } from "../../utils/constant";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";
import LookupService from "../../services/LookupService";
import { SortableContainer,SortableElement,SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";

class AddLookup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      validation: {},
      formDataInEdit: null,
      activeEditId: null,
      isHoveringNumbers:false,
      isHoveringLetters:false,
      isDragging:false,
      draggingElmt:{},
      tableRecords: {
        tableHeaders: [
          {label:'',key:'dragging',type:'DragHandler'},
          { label: 'Key', key: 'key', inputType: '' },
          { label: 'Value', key: 'value', inputType: 'TextInput' },
          { label: 'Action', key: 'action', type: 'Actions' },
        ],
        tableRows: {
          type: "default",
          data: []
        },
      }
    };
  }

  componentDidMount() {
    this.fetchLookupList();
  }

  

  render() {
    const { isOpen, modelTitle,activeLookup } = this.props;
    const { formData, validation, formDataInEdit, activeEditId, tableRecords } = this.state;
    
    const inputFields = [
      {
        type: "TextInput",
        label: "Value",
        placeholder: "value",
        className: "form-control form-control-solid",
        name: "value",
        value: formData.value,
        required:true
      },
    ];
    return (
      <>
          

        <Modal show={isOpen} onHide={this.modelCloseHandler} size="xl" dialogClassName='hResponsive'
         keyboard={false}
        contentClassName='h-100' backdrop="static">
          <div className="sh_modalBox">
            <Modal.Header closeButton>
              <Modal.Title>
                <h6 className="text-lg mb-0 font-weight-bold">{`${modelTitle} of ${activeLookup}`}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="sh_innerPortview row d-flex">
                <div class="col-lg-5 col-12">
                  <div class="sh_cardBox h-auto shadow-none border">
                    <div class="sh_cardBody p-3">
                      <div class="row">
                        {inputFields &&
                          inputFields.map((field, index) => (
                            <div className={`col-12`} key={index}>
                              <div className="form-group">
                                <label className="text-gray-900 text-md opacity-75">
                                  {field.label}{" "}
                                </label>
                                <FormInputs
                                  {...field}
                                  changeHandler={(value,e)=>this.changeHandler(value,field.name)}
                                />
                                {isSet(validation[field.name], "") !== "" ? (
                                  <span className="text-danger m-1">
                                    {isSet(validation[field.name], "")}
                                  </span>
                                ) : (
                                    ""
                                  )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div class="sh_cardFooter justify-content-center">
                      <button
                        class="btn btn-primary btn-sm text-sm"
                        onClick={this.formSubmitHandler}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="sh_cardBox h-auto shadow-none border">
                    <div class="sh_cardHeader">
                      <div class="form-group d-flex mb-0 justify-content-between align-items-center">
                        <h6 class="text-md mb-0">{`Lookup Value List of ${activeLookup}`}</h6>
                      </div>
                    </div>
                    <SortableList onSortOver={this.onSortOver} onSortStart={this.onSortStart} helperClass="helperContainerClass" useDragHandle={true} updateBeforeSortStart={this.updateBeforeSortStart} onSortEnd={this.onSortEnd} style={{"z-index":10000000000000}}>
                      <div className=""
                        onMouseEnter={() => this.setIsHoveringNumbers(true)}
                        onMouseLeave={() => this.setIsHoveringNumbers(false)}

                      >
                        <div class="sh_cardBody ">
                          <TableGrid
                            {...tableRecords}
                            sortingTableHandler={this.sortingTableHandler}
                            gridEditProps={{
                              formDataInEdit: formDataInEdit,
                              activeEditId: activeEditId,
                              onChangeFormDataInEdit: this.onChangeFormDataInEdit,
                            }} />
                        </div>
                      </div>
                    </SortableList>
                  </div>
                </div>
              </div>
            </Modal.Body>

           {/* <Modal.Footer className="d-flex justify-content-center">
              <Buttons
                type='Single'
                className="btn btn-outline-secondary"
                clickHandler={this.modelCloseHandler}
                label='Cancel'
                acl={'any'} />
            </Modal.Footer>*/}
          </div>
        </Modal>
      

      </>
    );
  }

  changeHandler = (val, fieldName) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [fieldName]: val,
      },
      validation:{
        ...prevState.validation,
        [fieldName]:''
      }
      
    }));
  };
  setIsHoveringNumbers = (val)=>{
    this.setState({isHoveringNumbers:val});
  }
  setIsHoveringLetters = (val)=>{
    this.setState({isHoveringLetters:val});
  }
  onSortEnd = ({ oldIndex, newIndex,...props})=>{
    const {isHoveringNumbers,draggingElmt} = this.state;
    this.setState({isDragging:true},()=>{
      if(isHoveringNumbers===true){
          this.sortingTableHandler(oldIndex,newIndex);
      }
    });
  }
  updateBeforeSortStart = ()=>{
    this.setState({isDragging:true});
  }
  onSortStart = (e)=>{
    this.setState({isDragging:true});
  }
  onSortOver = (val)=>{
  }
  updatePositionHandler = async (key,mappingArr)=>{
    let payload={
      mappings:mappingArr
    }
    const updateResponse=await LookupService.updateLookupPosition(key,payload)
  }
  sortingTableHandler = (from, to) => {
    const { tableRows } = this.state.tableRecords;
    const { data } = tableRows;
    const newArray = [...data];
    this.setState(prevState => {
      const { tableRows } = prevState.tableRecords;
      const { data } = tableRows;
      const newArray = [...data];
      this.arrayMoveMutate(newArray, from, to);
      return {
        tableRecords: {
          ...prevState.tableRecords,
          tableRows: {
            ...tableRows,
            data: newArray
          }
        }
      };
    }, () => {
      // Perform any necessary operations after state has been updated
      const { tableRecords } = this.state;
      const mappingList = tableRecords?.tableRows?.data?.map((item, itemIndex) => ({
        id: item.rowId,
        display_position: itemIndex + 1,
        value:item.value
      }));
      this.updatePositionHandler(this.props.activeLookup,mappingList);
    });
   
  };
  arrayMoveMutate = (array, from, to) => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  };
  handleSortStart = (draggingData)=>{
    this.setState({draggingElmt:draggingData});
  }
  formSubmitHandler = async () => {
    const { formData } = this.state;
    const { Loader, Toast,activeLookup} = this.props;
    const payload = {
      key: activeLookup,
      value: formData.value,
    };
    let updateValidation = { ...this.state.validation };
    Loader(true);
    let response = await LookupService.saveLookup(payload);
    if (response.code == OK || response.code == CREATED) {
      Toast.success("Lookup Value Added Successfully");
    }
    if (response.status && response.status == "ERROR") {
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(","),
          };
        }
      }
      if (Object.keys(updateValidation).length > 0) {
        this.setState({ validation: updateValidation });
      }
    }
    this.fetchLookupList();
    this.setState({ formData: {} });
    Loader(false);
  };

  deleteHandler = (id) => {
    const { Toast } = this.props;
      LookupService.deleteLookup(id)
        .then((res) => {
          this.fetchLookupList();
          Toast.success('Lookup Value Deleted Successfully');
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
  }

    editHandler = (rowId, data) => {
      this.setState({ formDataInEdit: data, activeEditId: rowId });
    };

    updateHandlerc = async (id) => {
      const {formDataInEdit} = this.state;
      const {activeLookup}=this.props;
      let payload = {
        'key': activeLookup,
        'value': formDataInEdit.value,
      };
      let response = await LookupService.updateLookup(id, payload);
      if (response.code === OK) {
        this.props.Toast.success("Lookup Value updated Successfully");
        this.editHandler(null, {}, null);
        this.fetchLookupList();
      }
      else if (response.status=="ERROR"&&response.data) {
        if (isObject(response.data)) {
          let validaitons={};
          for (let key in response.data) {
           validaitons={...validaitons,[key]:response.data[key][0]};
          }
            this.setState((prevState) => ({
            formDataInEdit: {
              ...prevState.formDataInEdit,
              validation: validaitons,
            },
          }));
        }
      }
    };

    fetchLookupList= async () => {
      const { activeLookup } = this.props;
      const LookupListData = await LookupService.getLookupByKey(activeLookup);
      let tableData = [];
      LookupListData.map((lookup, lookupIndex) => {
        tableData = [
          ...tableData,
          {
            handlers:{onMouseDown:() => this.handleSortStart({rowId:lookup.id,type:'lookup'})},
            TrHoc:SortableItem,
            lookupIndex: lookupIndex,
            isHeading: false,
            rowId: lookup.id,
            data: {
              dragging:{},
              key: activeLookup,
              value: lookup.value,
              active: "Yes",
              action: [
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Edit",
                  title: "Edit",
                  type: "GridEdit",
                  acl: "any",
                  clickHandler: (rowId, data) => this.editHandler(rowId, data),
                  updateHandler: () => this.updateHandlerc(lookup.id),
                  onChangeFormDataInEdit: (key, val) => this.onChangeFormDataInEdit(key, val),
                },
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Remove",
                  acl: "any",
                  title: "Delete",
                  isDelete:true,
                  clickHandler: () => this.deleteHandler(lookup.id),
                },
              ],
            },
          },
        ];
      });
      this.setState({
        tableRecords: {
          ...this.state.tableRecords,
          tableRows: {
            type: "default",
            data: tableData,
          }
        }
      });
    };

    modelCloseHandler = () => {
      const { addFormHandler } = this.props;
      addFormHandler();
      this.fetchLookupList();
    };

    onChangeFormDataInEdit = (key, val) => {
      this.setState((prevState) => ({
        formDataInEdit: {
          ...prevState.formDataInEdit,
          [key]: val,
          validation: {
            ...prevState.formDataInEdit.validation,
            [key]: '', // Set validation empty
          },
        },
      }));
    };
    handleSortStart = (draggingData)=>{
      this.setState({draggingElmt:draggingData});
    }
}

export default WithRoutify(AddLookup);

const SortableList = SortableContainer((props) => {
  return(
   
      <div className="">
        {props.children}
      </div>
 
  )
})
  
  
  
const TrWrapper = styled.tr`
cursor: default;
.firstElement {
  display: flex;
  flex-direction: row;
}

&.helperContainerClass {
  width: auto;
  border: 1px solid #efefef;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;

  &:active {
    cursor: grabbing;
  }

  & > td {
    padding: 5px;
    text-align: left;
  }
}
`;

const SortableItem = SortableElement(props => {
  // console.log('props',props);
  return <TrWrapper key={props.indexKey} id={props.indexKey} onMouseDown={props.onMouseDown} style={{zIndex:"9999"}}>{props.children}</TrWrapper>;
});




