import React, { Component } from "react";
import TableGrid from "../../components/UI/TableGrid";
import {
  stripHtmlTags,
  toQueryString,
  USDateTimeString,
} from "../../utils/commonUtils";
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import WithRouter from "../../hoc/WithRouter";
import NotificationLogService from "../../services/NotificationLogService";
import MessageModal from "./MessageModal";
import Icons from "../../components/UI/Icons";
import Buttons from "../../components/UI/Buttons";
import FormInputs from "../../components/UI/FormInputs";

class NotificationList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      { label: "Status", key: "status", type: "",},
      { label: "Recipent", key: "recipient",type:""},
      { label: "Message", key: "message",type:"Actions"},
      { label: "Type", key: "type",type:""},
      { label: "Created At", key: "created_at",type:""},
      // { label: "Updated By", key: "type",type:""},
      //  more columns as needed
    ];
    this.state = {
      filters: {
        search_query: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          status: 1000,
          data: [],
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "created_at",
          sort_order: "asc",
          search: {},
        },
      },
      Tab: [
        { "label": "Phone", isActive: false, name: 'sms' },
        { "label": "Email", isActive: false, name: 'email' },
        { "label": "All", isActive: true, name: '' },
      ],
      activeMessageModal:{},
      messageModelOpen:false
    };
  }

  componentDidMount() {
    this.fetchNotification();
  }

  componentDidUpdate = (prevProps, prevState) => {
  };

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };
  render() {
    const { tableRecords,Tab,messageModelOpen,filters} = this.state;
    return (

      <>
        { 
          messageModelOpen?
          <MessageModal
          messageLog={this.state.activeMessageModal}
          addFormHandler={this.messageFormHandler}
          />
          :
          ''
        }
          <div className="sh_cardBox">
          <div className="sh_cardHeader pb-1">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                <div>
                  <div className="tabs_wrapForTable">
                    <ul className="nav nav-borders nav-tabs border-0" role="">
                      {Tab.map((itm, itmInd) => {
                        return (
                          <>
                            <li>
                              <a
                                className={`flex-sm-fill text-sm-center nav-link ${itm.isActive ? "active" : ""
                                  }`}
                                aria-current="page"
                                onClick={() => this.setActiveTab(itm)}
                              >
                                {itm.label}
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {/* <h6 class="text-lg f-500 mb-0">{` Employee / Patient Visits `} </h6> */}
              <div className="d-flex">
                <div className="form-group-fields row mr-2">
                  <div className="col-12 px-1 d-flex">
                    <div className="addAction">
                      <div className="btn-group">
                        <button
                          className="btn btn-primary mr-1"
                          onClick={() => {this.fetchNotification({ search: { day: 'today',type:this.getActiveTab()} });this.setState({ filters: {} });}}
                        >
                          Today
                        </button>
                      </div>
                      <div className="btn-group">
                        <FormInputs
                          type='Date'
                          placeholder="From Date"
                          value={filters["from_date"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("from_date", val)
                          }
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="btn-group pl-2">

                      <FormInputs
                        type='Date'
                        placeholder="To Date"
                        value={filters["to_date"]}
                        changeHandler={(val) =>
                          this.onChangeFiltersHandler("to_date", val)
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="btn-group pl-2">
                      <button
                        className="btn btn-primary "
                        onClick={() => this.submitFiltersHandler()}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-secondary"
                        onClick={() => this.clearFiltersHandler()}
                      >
                        Clear
                      </button>
                    </div>
                  </div>

                </div>


              </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                // hasPagination={true}
                // fetchTableRecords={(filters) =>
                //   this.fetchNotification(filters)
                // }
                // {...tableRecords}
                hasPagination={true}
                fetchTableRecords={this.fetchNotification}
                tableHeaders={tableRecords.tableHeaders}
                tableRows={tableRecords.tableRows}
                pagination={tableRecords.pagination}
              />
              
            </div>
          </div>
          </>
    );
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const queryfilters = {
      ...tableRecords.pagination,
      page:1,
      search: filters,
    };
    this.fetchNotification(queryfilters);
  };
  clearFiltersHandler = async () => {
    this.setState({
      filters: {},
    },this.submitFiltersHandler);
    
  };
  fetchNotification = async (query = {}) => {
    // console.log('tableData');
    const { tableRecords,Tab } = this.state;
    try {
      let queryString = toQueryString({
        ...tableRecords.pagination,
        ...query,
      });
      this.props.Loader(true);
      const response = await NotificationLogService.getNotificationLog(queryString);
      console.log('response',response);
      
      const tableData = response?.data?.data.map((item, index) => ({
        index: index,
        isHeading: false,
        rowId:item.audit_id,
        data: {
          isActive: false,
          status: {
            title: item?.status,
            value: <>
              <div className="d-flex justify-content-left ">
                <div className="d-flex Justify-content-end px-2" title={item.status}><Icons type={item.status == 'success' ? 'green' : 'red'} /></div>
              </div>
            </>
          },
          recipient: item.recipient,
          created_at: USDateTimeString(item.created_at),
          message: [{  
            title: 'message',
            label:stripHtmlTags(item.message).slice(0, 25) + "....",
            clickHandler: () => this.messageFormHandler(item),
            acl:"any",
          }],
          type: item.type && item.type.toUpperCase() || '--'
        },
      }));
      // console.log('tableData',response?.data);
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            type: "default",
            status: 200,
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: response?.data?.total,
          },
        },
      }));
    } catch (err) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200,
          },
        },
      });
      // console.error("Error fetching companies:", err);
    } finally {
      this.props.Loader(false);
    }
  };
  getActiveTab=()=>{
    let { Tab, filters } = this.state;
    let activeTab = Tab.find(itm => itm.isActive === true);    console.log('activetab',activeTab);
    return activeTab?.name
  }
  setActiveTab = (val) => {
    let { Tab, filters } = this.state;
    const { label, name, isActive } = val
    let updatedTab = Tab.map((item) => {
      if (item.label == label) {
        item.isActive = true
      }
      else {
        item.isActive = false;
      }
      return item;
    });
    this.setState((prevState) => ({
      Tab: updatedTab,
      filters: { ...prevState.filters, ['type']: name },
    }), () => this.submitFiltersHandler());
  };
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
  messageFormHandler=(messageLog={})=>{
    this.setState({
      messageModelOpen:!this.state.messageModelOpen,
      activeMessageModal:messageLog,
    });
  }
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    })
  }
 
}
export default WithRouter(withReduxState(WithRoutify(NotificationList)));
