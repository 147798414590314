import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import FormInputs from "../../components/UI/FormInputs";
import WithRoutify from "../../hoc/WithRoutify";
import { isObject, isSet } from "../../utils/commonUtils";
import DivisionService from "../../services/DivisionService";
import { CREATED, OK } from "../../utils/constant";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";

class AddDivision2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      validation: {},
      formDataInEdit: null,
      activeEditId: null,
      tableRecords: {
        tableHeaders: [
          { label: 'Division Name', key: 'division_name', inputType: '' },
          { label: 'Division 2', key: 'division2_name', inputType: 'TextInput' },
          { label: 'Action', key: 'action', type: 'Actions' },
        ],
        tableRows: {
          type: "default",
          data: []
        },
      }
    };
  }

  componentDidMount() {
    this.fetchDivisionList();
  }

  

  render() {
    const { isOpen, modelTitle,addFormHandler } = this.props;
    const { formData, validation, formDataInEdit, activeEditId, tableRecords } = this.state;
    
    const inputFields = [
      {
        type: "TextInput",
        label: "Division Name",
        placeholder: "Division Name",
        className: "form-control form-control-solid",
        name: "division_name",
        value: formData.division_name,
        required:true
      },
    ];
    return (
      <>
        <Modal 
         show={isOpen} 
         onHide={this.modelCloseHandler}
         size='xl'
        //  dialogClassName='hResponsive'
        //  contentClassName='h-100'
         backdrop="static"
         keyboard={false}
         >
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">{modelTitle}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="sh_innerPortview w-100 d-flex row">
              <div class="col-lg-5 col-12">
                <div class="sh_cardBox h-auto shadow-none border">
                  <div class="sh_cardBody p-3">
                    <div class="row">
                      {inputFields &&
                        inputFields.map((field, index) => (
                          <div className={`col-12 ${validation[field.name] ? "was-validated" : ""}`} key={index}>
                            <div className="form-group">
                              <label className="text-gray-900 text-md opacity-75">
                                {field.label}
                              </label>
                              <FormInputs
                                {...field}
                                changeHandler={(val,e)=>this.changeHandler(val,field.name)}
                              />
                              {isSet(validation[field.name], "") !== "" ? (
                                <span className="text-danger m-1">
                                  {isSet(validation[field.name], "")}
                                </span>
                              ) : (
                                  ""
                                )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div class="sh_cardFooter justify-content-center">
                    <button
                      class="btn btn-primary btn-sm text-sm"
                      onClick={this.formSubmitHandler}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 col-12">
                <div class="sh_cardBox h-auto shadow-none border">
                  <div class="sh_cardHeader">
                    <div class="form-group d-flex mb-0 justify-content-between align-items-center">
                      <h6 class="text-md mb-0">Division List</h6>
                    </div>
                  </div>
                  <div class="sh_cardBody p-0 tabletextbox">
                    <TableGrid
                      {...tableRecords}
                      gridEditProps={{
                        formDataInEdit: formDataInEdit,
                        activeEditId: activeEditId,
                        onChangeFormDataInEdit: this.onChangeFormDataInEdit,
                      }} />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              type='Single'
              className="btn btn-primary rounded-pill"
              clickHandler={this.modelCloseHandler}
              label='Submit'
              acl={'any'} />
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  changeHandler = (val, fieldName) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [fieldName]: val,
      },
      validation:{
        ...prevState.validation,
        [fieldName]:''
      }
    }));
  };

  formSubmitHandler = async () => {
    const { formData } = this.state;
    const { activeDivision, Loader, Toast } = this.props;
    const payload = {
      division_name: formData.division_name,
      company_id: activeDivision.company_id,
      parent_id: activeDivision.division_id,
    };
    let updateValidation = { ...this.state.validation };
    // Loader(true);
    let response = await DivisionService.saveDivision(payload);
    if (response.code == OK || response.code == CREATED) {
      Toast.success("division 2 Added Successfully");
    }
    if (response.status && response.status == "ERROR") {
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(","),
          };
        }
      }
      if (Object.keys(updateValidation).length > 0) {
        this.setState({ validation: updateValidation });
      }
    }
    this.fetchDivisionList();
    this.setState({ formData: {} });
    // Loader(false);
  };

  deleteHandler = async (id) => {
    const { Toast } = this.props;
      DivisionService.deleteDivision(null,id)
        .then((res) => {
          this.fetchDivisionList();
          Toast.success('Division Deleted Succesfully');
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
  }

    editHandler = (rowId, data) => {
      this.setState({ formDataInEdit: data, activeEditId: rowId });
    };

    updateHandlerc = async (divisionId) => {
      const {formDataInEdit} = this.state;
      const {activeDivision}=this.props;
      let payload = {
        'division_name': formDataInEdit.division2_name,
        'company_id': activeDivision.company_id,
        'parent_id': activeDivision.division_id,
      };
      let response = await DivisionService.updateDivision(divisionId, payload);
      if (response.code ===200 ) {
        this.props.Toast.success("Division updated Successfully");
        this.editHandler(null,{});
        this.fetchDivisionList();
      }
    };

    fetchDivisionList = async () => {
      const { activeDivision,Loader } = this.props;
      Loader(true);
      const divisionListData = await DivisionService.getDivisionByParentId(
        activeDivision.division_id
      );
      let tableData = [];
      divisionListData.map((division, divisionIndex) => {
        tableData = [
          ...tableData,
          {
            divisionIndex: divisionIndex,
            isHeading: false,
            rowId: division.division_id,
            data: {
              division_name: activeDivision.division_name,
              division2_name: division.division_name,
              active: "Yes",
              action: [
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Edit",
                  title: "Edit",
                  type: "GridEdit",
                  acl: "any",
                  clickHandler: (rowId, data) => this.editHandler(rowId, data),
                  updateHandler: () => this.updateHandlerc(division.division_id),
                  onChangeFormDataInEdit: (key, val) => this.onChangeFormDataInEdit(key, val),
                },
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Remove",
                  acl: "any",
                  title: "Delete",
                  clickHandler: () => this.deleteHandler(division.division_id),
                },
              ],
            },
          },
        ];
      });
      this.setState({
        tableRecords: {
          ...this.state.tableRecords,
          tableRows: {
            type: "default",
            data: tableData,
          }
        }
      });
      Loader(false);
    };

    modelCloseHandler = () => {
      const { addFormHandler } = this.props;
      addFormHandler();
      this.fetchDivisionList();
    };

    onChangeFormDataInEdit = (key, val) => {
      this.setState(prevState => ({
        formDataInEdit: {
          ...prevState.formDataInEdit,
          [key]: val,
        }
      }));
    };
}

export default WithRoutify(AddDivision2);



