import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import Buttons from "../../components/UI/Buttons";
import CptService from "../../services/CptService";
import CsvService from "../../services/CsvService";
import JsonViewModal from "../../components/Modal/JsonViewModal";


const EzReaderDetailView = (props) => {
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const  [showJsonViewer,setShowJsonViewer]=useState(false);
  const { isOpen, addFormHandler, activeEditId, Toast, Loader,fetchRecords } = props;
  console.log('edit',activeEditId);
  useEffect(() => {
    if (activeEditId) {
        fetchEzRecords();
    }
  }, []);
  const fetchEzRecords=async()=>{
    Loader(true);
    let response=await CsvService.getEzreaderById(activeEditId);
    Loader(false);
    console.log('response',response);
    setFormData(response);
  }
  const inputFields =getInputFields();
  const changeHandler = (val, fieldName, e) => {
    setFormData({
      ...formData,
      [fieldName]: val,
    });
    setValidation({ ...validation, [fieldName]: "" });
  };
  const formSubmitHandler = async (saveAndReprocess=false) => {
    let updateValidation = { ...validation };
    let payload={...formData};
    payload.re_process=saveAndReprocess;
    let data= await CsvService.updateEzRecordById(activeEditId,payload);
    if (data.status && data.status == "ERROR") {
      if (isObject(data.data)) {
        for (let key in data.data) {
          updateValidation = {
            ...updateValidation,
            [key]: data.data[key].join(',')
          }
        }
      } else {
        Toast.error(data.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    } else {
      fetchRecords();
      addFormHandler();
      let message = `EzReader ${activeEditId ? 'Updated ' : 'Added'} Sucessfully`
      Toast.success(message);
    }

  }
  const jsonModelToogleHanlder=()=>{
    setShowJsonViewer(!showJsonViewer);
  }
  return (
    <>
    {
      showJsonViewer?
      <JsonViewModal
      data={formData.json_data}
      modelToggleHandler={jsonModelToogleHanlder}
      />
      :
      ''
    }
 
      <Modal
        show={true}
        onHide={addFormHandler}
        size="xl"
        // fullscreen={'xxl-down'}
        dialogClassName="hResponsive"
        contentClassName="h-100"
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="font-weight-bold text-lg mb-0">
                EZ Reader Detail View
              </h6>
            </Modal.Title>
            <div className="btn-group ml-auto mr-2">
                  <Buttons
                    clickHandler={() =>
                      jsonModelToogleHanlder()
                      }
                    className="btn btn-primary"
                    acl={"any"}
                    label={"View API Request"}
                  />
            </div>            
          </Modal.Header>
          <Modal.Body>
          <div class="card-body tab-content p-0">
              {inputFields &&
                inputFields.map((section, sectionIndex) => {
                  return (
                    <>
                      <div class="card mb-2">
                        <a
                          href={`#collapseCardExample_${sectionIndex}`}
                          class="d-block sh_accordionheader  card-header"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="true"
                          aria-controls="collapseCardExample"
                          style={{
                            position: "sticky",
                            top: "-15px",
                            zIndex: "1",
                          }}
                        >
                          <h6 class="m-0 text-md">
                            {section.title}
                          </h6>
                        </a>
                        <div
                          class="collapse show"
                          id={`collapseCardExample_${sectionIndex}`}
                          data-bs-parent="#modal111"
                        >
                          <div class="card-body">
                            <div class="row">
                              {section.data && section.data.map((data, dataInd) => {
                                return (
                                  <>
                                    <div class={`${data.col ? data.col : 'col-6'}`}>
                                      <div class="form-group">
                                        <label class="f-400 text-sm text-truncate mb-0">
                                          {data.label}
                                        </label>
                                        <div
                                          className={`${data.type === "MultiSelectDropDown" ? 'd-flex' : ''}`}
                                          key={dataInd}
                                        >
                                          <FormInputs
                                            {...data}
                                            className={
                                              "form-control form-control-solid"
                                            }
                                            changeHandler={(value,obj) =>
                                              changeHandler(value, data.name, obj)
                                            }
                                            value={formData[data.name]}
                                            required={data.required}
                                          />
                                          {isSet(validation[data.name], "") !==
                                            "" ? (
                                            <span className="text-danger m-1">
                                              {isSet(validation[data.name], "")}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>            
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              clickHandler={() => formSubmitHandler()}
              className="btn btn-primary"
              acl={"any"}
              label={'Save'}
            />
            <Buttons
              clickHandler={() => formSubmitHandler(true)}
              className="btn btn-info"
              acl={"any"}
              label={'Save And Reprocess'}
            />
            {/*
          <Buttons
              clickHandler={() => addFormHandler()}
              className="btn btn-outline-secondary rounded-pill"
              acl={"any"}
              label={"Cancel"}
            />
            */}
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  function getInputFields() {
    // Generate the array of objects for EZ-Reader Details
    const ezReaderDetails = Array.from({ length: 18 }, (_, index) => {
      const itemNumber = index + 1;
      return [
        {
          type: "TextInput",
          label: `Item ${itemNumber}`,
          name: `Item ${itemNumber}`,
          placeholder: `Item ${itemNumber}`,
          className: "form-control form-control-solid",
          col:'col-6'
        },
        // {
        //   type: "TextInput",
        //   label: `Control ${itemNumber}`,
        //   name: `control${itemNumber}`,
        //   placeholder: `Control ${itemNumber}`,
        //   className: "form-control form-control-solid",
        // },
        // {
        //   type: "TextInput",
        //   label: `Test ${itemNumber}`,
        //   name: `test${itemNumber}`,
        //   placeholder: `Test ${itemNumber}`,
        //   col:'col-6',
        //   className: "form-control form-control-solid",
        // },
        {
          type: "TextInput",
          label: `Result ${itemNumber}`,
          name: `Result ${itemNumber}`,
          placeholder: `Result ${itemNumber}`,
          className: "form-control form-control-solid",
          col:'col-6',
        }
      ];
    }).flat(); // Use flat to combine all arrays into one
  
    // Add the EZ-Reader Details to the inputFields array
    const inputFields = [
      {
        title: "Details",
        data: [
          {
            type: "TextArea",
            label: "Decription",
            name: "failed_data",
            placeholder: "Name",
            className: "form-control form-control-solid",
            readOnly:true
          },
          {
            type: "",
            label: "DSN",
            name: "dsn",
            placeholder: "DSN",
            className: "form-control form-control-solid",
          },
          {
            type: "",
            label: "Product ID",
            name: "product_id",
            placeholder: "Name",
            className: "form-control form-control-solid",
          },
          {
            type: "",
            label: "Product LOT",
            name: "product_lot",
            placeholder: "Product LOT",
            className: "form-control form-control-solid",
          },
          {
            type: "",
            label: "Test Date",
            name: "test_date",
            placeholder: "Name",
            className: "form-control form-control-solid",
          },
          {
            type: "",
            label: "Test Time",
            name: "test_time",
            placeholder: "Test Time",
            className: "form-control form-control-solid",
          }, 
          {
            type: "Date",
            label: "Expiration Date",
            name: "expiration_date",
            placeholder: "Expiration Date",
            className: "form-control form-control-solid",
          },    
        ],
      },
      {
        title: "EZ-Reader Details",
        data: ezReaderDetails,
      },
    ];
  
    return inputFields;
  }  
};
export default withReduxState(WithRoutify(EzReaderDetailView));