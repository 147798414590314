import React, { Component } from "react";
import TableGrid from "../../components/UI/TableGrid";
import {
    stripHtmlTags,
    toQueryString,
    USDateTimeString,
} from "../../utils/commonUtils";
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import WithRouter from "../../hoc/WithRouter";
import Icons from "../../components/UI/Icons";
import FormInputs from "../../components/UI/FormInputs";
import HL7Service from "../../services/HL7Service";
import JsonViewModal from "../../components/Modal/JsonViewModal";
import ButtonLoader from "../../components/UI/ButtonLoader";
import HL7ReprocessModal from "./Hl7ReprocessModal";

class HL7LogList extends Component {
    constructor(props) {
        super(props);
        const tableHeaders = [
            {
                label: "",
                key: "isActive",
                type: "checkBox",
                changeHandler: this.checkBoxchangeHandler,
            },
            { label: "Status", key: "status", type: "", },
            { label: "type", key: "type", type: "" },
            { label: "Sftp Server", key: "sftp", type: "" },
            { label: "Message", key: "message", type: "Actions" },
            { label: "Duration", key: "duration", type: "" },
            { label: 'File', key: 'file', type: 'FileType' },
            { label: "Created At", key: "created_at", type: "" },
            { label: "Action", key: "action", type: "Actions" },
        ];
        this.state = {
            filters: {
                search_query: "",
            },
            tableRecords: {
                tableHeaders: tableHeaders,
                tableRows: {
                    status: 1000,
                    data: [],
                },
                pagination: {
                    page: 1,
                    perPage: 25,
                    totalRecords: 0,
                    sort_by: "created_at",
                    sort_order: "asc",
                    search: {},
                },
            },
            Tab: [
                { "label": "Outbox", isActive: false, name: 'order' },
                { "label": "Inbox", isActive: false, name: 'result' },
                { "label": "All", isActive: true, name: '' },
            ],
            fetchResultLoader: false,
            messageModalOpen: false,
            activeMessage: null,
            activeEditId:null,
            isModelOpen:false,
        };
    }

    componentDidMount() {
        this.fetchHl7Logs();
    }

    componentDidUpdate = (prevProps, prevState) => {
    };

    componentWillUnmount() {
        // Code to run before the component is unmounted
    }

    handleEvent = () => {
        // Handler for events (e.g., button click)
    };
    addFormHandler = (id=null) => {
        this.setState({ isModelOpen: !this.state.isModelOpen,activeEditId:id});
    };
    render() {
        const { tableRecords, Tab, filters, messageModalOpen, activeMessage, fetchResultLoader,isModelOpen } = this.state;
        return (
            <>
                {
                    messageModalOpen ?
                        <JsonViewModal
                            data={activeMessage}
                            modelToggleHandler={this.messageFormHandler}
                        />
                        :
                        ''
                }
                {
                    isModelOpen?
                    <HL7ReprocessModal
                    id={this.state.activeEditId}
                    addFormHandler={this.addFormHandler}
                    />
                    :
                    ''
                }
                <div className="sh_cardBox">
                    <div className="sh_cardHeader pb-1">
                        <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                            <div>
                                <div className="tabs_wrapForTable">
                                    <ul className="nav nav-borders nav-tabs border-0" role="">
                                        {Tab.map((itm, itmInd) => {
                                            return (
                                                <>
                                                    <li>
                                                        <a
                                                            className={`flex-sm-fill text-sm-center nav-link ${itm.isActive ? "active" : ""
                                                                }`}
                                                            aria-current="page"
                                                            onClick={() => this.setActiveTab(itm)}
                                                        >
                                                            {itm.label}
                                                        </a>
                                                    </li>
                                                </>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-group-fields row mr-2">
                                    <div className="col-12 px-1 d-flex">
                                        <div>
                                            <ButtonLoader isLoading={fetchResultLoader}
                                                className="btn btn-primary mr-1"
                                                clickHandler={() => this.fetchResults()}
                                                label={"Fetch Result"}
                                                acl={"any"}
                                            />
                                        </div>

                                        <div className="input-group input-group-joined input-group-solid">
                                            <FormInputs
                                                placeholder="Search"
                                                value={filters["search_query"]}
                                                changeHandler={(val) =>
                                                    this.onChangeFiltersHandler("search_query", val)
                                                }
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="btn-group pl-2">
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => this.submitFiltersHandler()}
                                            >
                                                Search
                                            </button>
                                            <button
                                                className="btn btn-outline-secondary"
                                                onClick={() => this.clearFiltersHandler()}
                                            >
                                                Clear
                                            </button>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="sh_cardBody p-0">
                        <TableGrid
                            hasPagination={true}
                            fetchTableRecords={this.fetchHl7Logs}
                            tableHeaders={tableRecords.tableHeaders}
                            tableRows={tableRecords.tableRows}
                            pagination={tableRecords.pagination}
                        />

                    </div>
                </div>
            </>
        );
    }
    submitFiltersHandler = async () => {
        const { filters, tableRecords } = this.state;
        const queryfilters = {
            ...tableRecords.pagination,
            page: 1,
            search: filters,
        };
        this.fetchHl7Logs(queryfilters);
    };
    clearFiltersHandler = async () => {
        this.setState({
            filters: {},
        }, this.submitFiltersHandler);

    };
    fetchHl7Logs = async (query = {}) => {
        const { tableRecords, Tab } = this.state;
        try {
            let queryString = toQueryString({
                ...tableRecords.pagination,
                ...query,
            });
            this.props.Loader(true);
            const response = await HL7Service.getHl7LogList(queryString);
            let passedStatus=['uploaded','received'];
            const tableData = response?.data?.data.map((item, index) => ({
                index: index,
                isHeading: false,
                rowId: item.audit_id,
                data: {
                    isActive: false,
                    status: {
                        title: item?.status,
                        value: <>
                            <div className="d-flex justify-content-left ">
                                <div className="d-flex Justify-content-end px-2" title={item.status}><Icons type={passedStatus.includes(item?.status)?'green' : 'red'} /></div>
                            </div>
                        </>
                    },
                    file: item.file ? item.file : '',
                    sftp: item?.config?.name,
                    duration: item?.duration,
                    received_at: item?.received_at,
                    type: item?.type,
                    created_at: USDateTimeString(item.created_at),
                    message: [{
                        title: 'message',
                        label: stripHtmlTags(item?.message).slice(0, 25) + "....",
                        clickHandler: () => this.messageFormHandler(item?.parsed_message),
                        acl: "any",
                    }],
                    type: item.type && item.type.toUpperCase() || '--',
                    action: [
                        {
                            className: "btn btn-icon btn-transparent-dark",
                            iconType: "Search",
                            title: "View",
                            acl:"any",
                            type: "GridEdit",
                            clickHandler: () => this.addFormHandler(item.id),                            
                          },
                    ]
                },
            }));
            // console.log('tableData',response?.data);
            this.setState((prevState) => ({
                tableRecords: {
                    ...prevState.tableRecords,
                    tableHeaders: prevState.tableRecords.tableHeaders,
                    tableRows: {
                        type: "default",
                        status: 200,
                        data: tableData,
                    },
                    pagination: {
                        ...prevState.tableRecords.pagination,
                        ...query,
                        totalRecords: response?.data?.total,
                    },
                },
            }));
        } catch (err) {
            this.setState({
                tableRecords: {
                    ...tableRecords,
                    tableRows: {
                        ...tableRecords.tableRows,
                        status: 200,
                    },
                },
            });
            // console.error("Error fetching companies:", err);
        } finally {
            this.props.Loader(false);
        }
    };
    fetchResults = async () => {
        const { Loader } = this.props;
        this.setState({ fetchResultLoader: true });
        const response = await HL7Service.pullHL7LabOrder();
        this.fetchHl7Logs();
        this.setState({ fetchResultLoader: false });
        Loader(false);
    }
    getActiveTab = () => {
        let { Tab, filters } = this.state;
        let activeTab = Tab.find(itm => itm.isActive === true); console.log('activetab', activeTab);
        return activeTab?.name
    }
    setActiveTab = (val) => {
        let { Tab, filters } = this.state;
        const { label, name, isActive } = val
        let updatedTab = Tab.map((item) => {
            if (item.label == label) {
                item.isActive = true
            }
            else {
                item.isActive = false;
            }
            return item;
        });
        this.setState((prevState) => ({
            Tab: updatedTab,
            filters: { ...prevState.filters, ['type']: name },
        }), () => this.submitFiltersHandler());
    };
    checkBoxchangeHandler = (value, { e }) => {
        const rowId = e.target.id;
        const { tableRows } = this.state.tableRecords;
        //update data based on check box click
        const updatedData = tableRows.data.map((item) => {
            if (item.rowId === rowId) {
                item.data.isActive = value;
            }
            return item;
        });

        this.setState((prevState) => ({
            tableRecords: {
                ...prevState.tableRecords,
                tableRows: {
                    data: updatedData,
                },
            },
        }));
    };
    messageFormHandler = (message = {}) => {
        this.setState({
            messageModalOpen: !this.state.messageModalOpen,
            activeMessage: message,
        });
    }
    onChangeFiltersHandler = (key, val) => {
        const { filters } = this.state;
        this.setState({
            filters: {
                ...filters,
                [key]: val
            }
        })
    }

}
export default WithRouter(withReduxState(WithRoutify(HL7LogList)));
