import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import CptList from "./CptList";


const CPTContainer=()=>{
    return(
        <>
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<CptList />} />
        
      </Routes>
      </Wrap>
      </>
    );
}
export default CPTContainer; 

