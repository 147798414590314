import React, { useState, useEffect } from "react";
import PasswordStrengthBar from "react-password-strength-bar";

const CustomPasswordStrengthBar = ({ password, onChangeHandler }) => {

  return (
    <PasswordStrengthBar 
      password={password}
      barColors={['#ddd', '#ff4d4d', '#ffd633', '#00b300', '#009900']}
      scoreWords={['Too Weak', 'Weak', 'Medium', 'Good', 'Strong']}
      minLength={8}
      onChangeScore={(score, feedback)=>onChangeHandler(score, feedback)}
    />
  );
};

export default CustomPasswordStrengthBar;


/*
https://www.npmjs.com/package/react-password-strength-bar
*/