import React, { Component } from 'react';
import PatientDetails from './PatientDetails';
import VisitDetails from './VisitDetails';
import RightContent from './RightContent';
import WithRouter from '../../../hoc/WithRouter';
import WithRoutify from '../../../hoc/WithRoutify';
import VisitsService from "../../../services/VisitsService";
import EmployeeService from "../../../services/EmployeeService";
import CompanyDetails from './CompanyDetails';
import { connect } from 'react-redux';
import LookupService from '../../../services/LookupService';
import MessageWidget from './MessageWidget';




class PatientDetailview extends Component {
    constructor(props) {
     super(props);
      this.state = {
        visitDetails:{},
        employeeId:null,
        employeeDetails:{},
        lookupsOptions:{},
        visitDetails:{},
      };
    }
    async componentDidMount() {
      // Code to run after the component has mounted
      this.fetchVisitList();
      this.fetchLookups();
    }
  
    componentDidUpdate(prevProps, prevState) {
      // Code to run after the component has updated
      if (prevState.employeeId!=this.state.employeeId) {
        this.fetchEmployeeDetails(this.state.employeeId)
      }
    }
    fetchLookups = async ()=>{
      const [chartResult, shippingService] = await Promise.all([
          LookupService.getLookupByKey('chart_result'),
          LookupService.getLookupByKey('shipping_service'),
        ]);
        let shippingServiceOptions = [{ label: '-None-', value: '' }];
        shippingService.forEach((item) => {
          shippingServiceOptions.push({
                label: item.value,
                value: item.value
            });
        });
        let chartResultOptions = [{ label: '-None-', value: '' }];
        chartResult.forEach((item) => {
          chartResultOptions.push({
                label: item.value,
                value: item.value
            });
        });
        this.setState({
          lookupsOptions:{
            shippingService:shippingServiceOptions,
            chartResult:chartResultOptions
          }
        })
      //   console.log('shippingService',shippingService,chartResult);
  }
    componentWillUnmount() {
      // Code to run before the component is unmounted
    }
  
    handleEvent = () => {
      // Handler for events (e.g., button click)
    };
    render() {
      const visit_id=this.props.params.visitId;
      const {employeeId,visitDetails,employeeDetails}=this.state;
      // console.log("visitID",visit_id);
      return (
        <>
          <div className="row">
            <div className="col-12 col-lg-3 pt-2">
              <PatientDetails
                visit_id={visit_id}
                employeeId={employeeId}
                employeeDetails={employeeDetails}
                fetchEmployeeDetails={this.fetchEmployeeDetails}
                />
              <VisitDetails
                visit_id={visit_id}
                employeeId={employeeId}
                visitDetails={visitDetails}
                fetchVisitList={this.fetchVisitList}
                />
               <CompanyDetails
                companyDetails={visitDetails.company}
               />
               {/* <MessageWidget /> */}
              
            </div>
            <RightContent
              visitDetails={visitDetails}
              visit_id={visit_id}
              employeeDetails={employeeDetails}
              companyDetails={visitDetails.company}
              stateList={this.props.stateList}
              lookupsOptions={this.state.lookupsOptions}
              fetchVisitDetails={this.fetchVisitList}
            />
            
          </div>
        </>
      );
    }
    fetchVisitList=async(visitId=this.props.params.visitId)=>{
      const { Loader } = this.props;
        Loader(true);
        try {
            const data = await VisitsService.getVisits(visitId);
            this.setState({visitDetails: {...data[0],...data},employeeId:data[0].employee_id});
            Loader(false);
        } catch (error) {
            Loader(false);
            console.error("Error fetching employee details:", error);
        }
        
    }
    fetchEmployeeDetails=async (employeeId)=>{
      const { Loader } = this.props;
        try {
          if (employeeId) {
            Loader(true);
            const data = await EmployeeService.getEmployee(employeeId);
            this.setState({ employeeDetails: data});
            Loader(false);
          }
            
        } catch (error) {
            Loader(false);
            console.error("Error fetching employee details:", error);
        }

    }
  }

  const mapStateToProps = state => {
    return {
      stateList: state.session.profileStateList
    };
  };
  
  // Connect the component to Redux store
  export default connect(mapStateToProps)(WithRouter(WithRoutify(PatientDetailview)));

//  export default WithRouter(WithRoutify(PatientDetailview));