import { useEffect, useState } from "react";
import Buttons from "../../../components/UI/Buttons";
import FormInputs from "../../../components/UI/FormInputs";
import { convertUnderScoreStrings, getBase64String, isObject, isObjectEmpty, isObjectValueEmpty, isSet } from "../../../utils/commonUtils";
import { useSelector } from "react-redux";
import CompanyService from "../../../services/CompanyService";
import ClinicService from "../../../services/ClinicService";
import withReduxState from "../../../hoc/wReduxState";
import OrganizationService from "../../../services/OrganizationService";
import WithRoutify from "../../../hoc/WithRoutify";
import { OK, SOMETHING_WENT_WRONG_ERROR_MESSAGE } from "../../../utils/constant";
import LabService from "../../../services/LabService";
import ProviderService from "../../../services/ProviderService";

const ClinicEditView = (props) => {
  const { clinicDetails, changeLayout, Loader } = props;
  const [formData, setFormData] = useState(clinicDetails);
  const [validation, setValidation] = useState({});
  const [lab, setLabs] = useState([]);
  const [provider, setProvider] = useState([]);
  const stateList = useSelector(state => state.session.profileStateList)
  const inputFields = getInputFields();
  useEffect(() => {
    let updatedData={...clinicDetails}
    if (Object.entries(updatedData).length !== 0) {
      let assigned_provider=updatedData['providers'];
      let mapping=assigned_provider.map(itm=>itm.provider_id);
      updatedData['assigned_providers']=mapping.join(',');
      setFormData(updatedData); 
    }
  }, [clinicDetails]);
  useEffect(() => {
    fetchLab();
    fetchProviders();
  }, []);
  //fetchLabList
  const fetchLab = async () => {
    let lab = await LabService.getLabsList(OrganizationService.getOrgId());
    let options = [{ label: '-Select', value: '' }];
    lab?.data?.data.forEach((itm) => {
      options.push({ label: itm.name, value: itm.lab_id });
    });
    setLabs(options);
  };
  const fetchProviders = async () => {
    let provider = await ProviderService.getProviders(OrganizationService.getOrgId());
    // console.log('provider',provider);
    // let options = [{ label: 'ALL', value: 'ALL' }];
    let options = [];
    provider?.data.forEach((itm) => {
      options.push({ label: itm.first_name + ' ' + itm.last_name, value: itm.provider_id });
    });
    setProvider(options);
  }
  //change handler for form data      
  const changeHandler = async (value, fieldName, {addressParam,selectVal}) => {
    console.log("FieldName", fieldName, "value", value, addressParam);
    let updatedFormData = { ...formData };
    let updateValidation = { ...validation };

    //image handle
    if (fieldName === 'business_license' || fieldName === 'clia_certificate') {
      if (value) {
        const { base64WithoutPrefix, fileName } = await getBase64String(value);
        let name = `${fieldName}_original_name`
        updatedFormData[name] = fileName;
        value = base64WithoutPrefix;
      }
      else {
        updatedFormData[`${fieldName}_original_name`] = '';
      }

    }
    // autoload logic for address 
    if (fieldName === 'address') {
      if (isSet(addressParam.street, false)) {
        updatedFormData['street'] = addressParam.street;
        updateValidation["street"] = "";
      }
      if (isSet(addressParam.state, false)) {
        updatedFormData['state'] = addressParam.state;
        updateValidation["state"] = "";
      }
      if (isSet(addressParam.zipcode, false)) {
        updatedFormData['zipcode'] = addressParam.zipcode;
        updateValidation["zipcode"] = "";
      }
      if (isSet(addressParam.country, false)) {
        updatedFormData['country'] = addressParam.country;
        updateValidation["country"] = "";
      }
      if (isSet(addressParam.city, false)) {
        updatedFormData['city'] = addressParam.city;
        updateValidation["city"] = "";
      }
    }
    //acounts payable address
    if (fieldName == 'accounts_payable_address') {
      if (isSet(addressParam.street, false)) {
        updatedFormData['accounts_payable_street'] = addressParam.street;
      }
      if (isSet(addressParam.state, false)) {
        updatedFormData['accounts_payable_state'] = addressParam.state;
      }
      if (isSet(addressParam.zipcode, false)) {
        updatedFormData['accounts_payable_zipcode'] = addressParam.zipcode;
      }
      if (isSet(addressParam.country, false)) {
        updatedFormData['accounts_payable_country'] = addressParam.country;
      }
      if (isSet(addressParam.city, false)) {
        updatedFormData['accounts_payable_city'] = addressParam.city;
      }
    }
    if (fieldName == 'documents') {
      if (value) {
        const { base64WithoutPrefix, fileName } = await getBase64String(value);
        updatedFormData['documents'] = base64WithoutPrefix;
        updatedFormData['documents_name'] = fileName;
      } else {
        //reset field 
        updatedFormData['documents'] = ''
        updatedFormData['documents_name'] = ''
      }
    }

    //assigned Clinic provider 
    if (fieldName == 'assigned_providers') {
      if (value) {
        let data;
        if (typeof formData.assigned_providers === 'string' && formData.assigned_providers != '') {
          data = formData.assigned_providers.split(',');
          console.log('data', data);
        } else {
          data = [];
        }
        data.push(selectVal);
        data = data.join(',');
        value = data;
      }
      else {
        let data = formData.assigned_providers.split(',');
        let index = data.indexOf(selectVal);
        if (index !== -1) {
          data.splice(index, 1);
        }
        value = data.join(',');
      }
    }

    updatedFormData[fieldName] = value;
    updateValidation[fieldName] = '';
    setFormData(updatedFormData);
    setValidation(updateValidation);
  };
  console.log(formData);

  const formSubmitHandler = () => {
    let updateValidation = { ...validation };
    const { Loader, Toast } = props
    Loader(true);
    console.log("formdata",formData);
    ClinicService.updateClinic(clinicDetails.clinic_id, formData)
      .then((updateResponse) => {
        if (updateResponse.status && updateResponse.status == "ERROR") {
          if (isObject(updateResponse.data)) {
            for (let key in updateResponse.data) {
              updateValidation = {
                ...updateValidation,
                [key]: updateResponse.data[key].join(","),
              };
            }
          } else {
            Toast.error(isSet(updateResponse.data, SOMETHING_WENT_WRONG_ERROR_MESSAGE));
          }
          if (Object.keys(updateValidation).length > 0) {
            setValidation(updateValidation);
          }
        }
        else {
          Toast.success("Organization Updated Successfully");
          props.changeLayout();
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        Loader();
      })
  };
  return (
    <>
      <div className="sh_cardBox">
        <div className="sh_cardHeader" style={{ position: "relative" }}>
          <div className="form-group d-flex mb-0 justify-content-between align-items-center">
            <h6 className="text-lg f-500 mb-0">
              <span className="badge back_btn  mr-2 ">
                <Buttons label={"Back"} iconType={"Back"} clickHandler={changeLayout} acl={'any'} />
              </span>
              {`Edit ${isSet(clinicDetails.name, '')}`}
            </h6>
          </div>
        </div>
        <div className="sh_cardBody p-0" style={{ position: "relative" }}>
          <div className="sh_innerPortview w-100 d-flex">
            <div className="w-100 p-3">
              {inputFields.map((item, ind) => {
                return (
                  <>
                    <div className="card mb-2">
                      {/* <!-- Card Header - Accordion --> */}
                      <a
                        href={`#collapseCardExample_${ind}`}
                        className="d-block sh_accordionheader card-header"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseCardExample"
                        style={{
                          position: "sticky",
                          top: "0px",
                          zIndex: "1",
                        }}
                      >
                        <h6 className="m-0 text-md font-weight-bold">
                          {item.title}
                        </h6>
                      </a>
                      {/* <!-- Card Content - Collapse --> */}
                      <div
                        className="collapse show"
                        id={`collapseCardExample_${ind}`}
                        data-bs-parent="#modal111"
                      >
                        <div className="card-body">
                          <div className="row">
                            {item.data.map((data, index) => {
                              return (
                                <>
                                  <div className={`${data.col ? data.col : "col-4"}`}>
                                    <div >
                                      <label className="f-500 text-md text-truncate">
                                        {data.label}
                                      </label>
                                      <div className={`${data.type == "MultiSelectDropDown" ? 'd-flex' : ''}`}>
                                        <FormInputs
                                          {...data}
                                          changeHandler={(value, e) =>
                                            changeHandler(value, data.name, e)
                                          }
                                        />
                                        {isSet(validation[data.name], "") !==
                                          "" ? (
                                          <span className="text-danger m-1">
                                            {isSet(validation[data.name], "")}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        <div className="sh_cardFooter justify-content-center">
          <Buttons
            clickHandler={() => formSubmitHandler()}
            className="btn btn-primary"
            acl={"any"}
            label={"Update"}
          />
        </div>
      </div>
    </>
  );
  function getInputFields() {
    const inputFields = [
      {
        title: "Clinic - Address & Contact",
        data: [
          {
            type: "TextInput",
            label: "Code",
            placeholder: "Code",
            className: "form-control form-control-solid",
            name: "code",
            value: formData['code'] || "",
            required: true,
            max: 5,
            allUpperCase: true
          },
          {
            type: "TextInput",
            label: "Name",
            placeholder: "Name",
            className: "form-control form-control-solid",
            name: "name",
            value: formData['name'] || "",
            required: true,
            isFirstLetterCaps: true,
          },
          {
            type: "TextInput",
            label: "Location",
            placeholder: "Location",
            className: "form-control form-control-solid",
            name: "location",
            value: formData['location'] || "",
            required: true,
            isFirstLetterCaps: true
          },
          {
            type: "Address",
            label: "Address",
            placeholder: "Address",
            className: "form-control form-control-solid",
            name: "address",
            value: formData['address'] || "",
            required: true,
            isFirstLetterCaps: true
          },
          {
            type: "TextInput",
            label: "City",
            placeholder: "City",
            className: "form-control form-control-solid",
            name: "city",
            value: formData['city'] || "",
            required: true,
            isFirstLetterCaps: true
          },
          {
            type: "SelectList",
            label: "State",
            options: stateList,
            placeholder: "State",
            className: "form-control form-control-solid",
            name: "state",
            value: formData['state'] || "",
            required: true,
          },
          {
            type: "Number",
            label: "Zip+4",
            placeholder: "Zip+4",
            className: "form-control form-control-solid",
            name: "zip_plus_4",
            value: formData['zip_plus_4'] || "",
            required: true,
            max: 9
          },
          {
            type: "TextInput",
            label: "Country",
            placeholder: "Country",
            className: "form-control form-control-solid",
            name: "country",
            value: formData['country'] || "",
            required: true,
            isFirstLetterCaps: true
          },
          {
            type: "Phone",
            label: "Phone",
            placeholder: "Phone",
            className: "form-control form-control-solid",
            name: "phone",
            value: formData['phone'] || "",
            required: true,
          },
          {
            type: "Number",
            label: "Fax",
            placeholder: "Fax",
            className: "form-control form-control-solid",
            name: "fax",
            value: formData['fax'] || "",
            max: 10,
          },
        ],
      },
      {
        title: "Clinic - ID's & Certificates",
        data: [
          {
            type: "Number",
            label: "Federal ID",
            placeholder: "Federal ID",
            className: "form-control form-control-solid",
            name: "federal_tax_id",
            value: formData['federal_tax_id'] || "",
            required: true,
            max: 9
          },
          {
            type: "Number",
            label: "Group NPI",
            placeholder: "Group NPI",
            className: "form-control form-control-solid",
            name: "group_npi",
            value: formData['group_npi'] || "",
            required: true,
            max: 10
          },
          {
            type: "TextInput",
            label: "CLIA",
            placeholder: "CLIA",
            className: "form-control form-control-solid",
            name: "clia",
            value: formData['clia'] || "",
            max: 10
          },
          {
            type: "TextInput",
            label: "Medicare ID",
            placeholder: "Medicare ID",
            className: "form-control form-control-solid",
            name: "medicare_id",
            value: formData['medicare_id'] || "",
            max: 6
          },
          {
            type: "TextInput",
            label: "Medicaid ID",
            placeholder: "Medicaid ID",
            className: "form-control form-control-solid",
            name: "medicaid_id",
            value: formData['medicaid_id'] || "",
          },
          {
            type: "TextInput",
            label: "DOL ID",
            placeholder: "DOL ID",
            className: "form-control form-control-solid",
            name: "dol_id",
            value: formData['dol_id'] || "",
            max: 9
          },
        ]

      },
      {
        title: "Clinic - Notifications Emails",
        data: [
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) Emp Services",
            placeholder: "Notification Email(s) Emp Services",
            className: "form-control form-control-solid",
            name: "notification_emails_emp_services",
            value: formData['notification_emails_emp_services'] || "",
          },
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) Provider Charting",
            placeholder: "Notification Email(s) Provider Charting",
            className: "form-control form-control-solid",
            name: "notification_emails_provider_charting",
            value: formData['notification_emails_provider_charting'] || "",
          },
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) Paid Invoices",
            placeholder: "Notification Email(s) Paid Invoices",
            className: "form-control form-control-solid",
            name: "notification_emails_paid_invoices",
            value: formData['notification_emails_paid_invoices'] || "",
          },
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) Management",
            placeholder: "Notification Email(s) Management",
            className: "form-control form-control-solid",
            name: "notification_emails_management",
            value: formData['notification_emails_management'] || "",
          },
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) All Employees",
            placeholder: "Notification Email(s) All Employees",
            className: "form-control form-control-solid",
            name: "notification_emails_all_employees",
            value: formData['notification_emails_all_employees'] || "",
          },
        ]


      },
      {
        title: "Clinic - Assigned Labs",
        data: [
          {
            type: "SelectList",
            options: lab,
            label: "Assigned Labs",
            placeholder: "Assigned Labs",
            className: "form-control form-control-solid",
            name: "assigned_lab_id",
            value: formData['assigned_lab_id'] || "",
          },
        ]


      },
      {
        title: "Clinic - Assigned Providers & Users",
        data: [
          {
            col: 'col-12',
            type: "MultiSelectDropDown",
            options: provider,
            label: "Assigned Providers",
            placeholder: "Assigned Providers",
            name: "assigned_providers",
            value: formData['assigned_providers'] || "",
          },
        ]
      },
      {
        title: "Clinic - Images",
        data: [
          {
            type: "FileUpload",
            label: "Business License",
            placeholder: "Business License",
            className: "form-control form-control-solid",
            name: "business_license",
            value: {
              original_name: formData['business_license_original_name'],
              base64: formData['business_license']
            },
          },
          {
            type: "FileUpload",
            label: "CLIA Certificate",
            placeholder: "CLIA Certificate",
            className: "form-control form-control-solid",
            name: "clia_certificate",
            value: {
              original_name: formData['clia_certificate_original_name'],
              base64: formData['clia_certificate']
            },
          },
        ]


      },
    ];
    return inputFields;
  }
};
export default WithRoutify(withReduxState(ClinicEditView));
