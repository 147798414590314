import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import FormInputs from "../../components/UI/FormInputs";
import WithRoutify from "../../hoc/WithRoutify";
import { isObject, isSet } from "../../utils/commonUtils";
import DivisionService from "../../services/DivisionService";
import { CREATED, OK } from "../../utils/constant";
import Buttons from "../../components/UI/Buttons";

const AddDivision = (props) => {
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const { isOpen, addFormHandler, modelTitle, companyList, activeEditId } =
    props;
  const inputFields = getInputFields();
  useEffect(() => {
    if (activeEditId) {
      DivisionService.getDivisionById(null, activeEditId).then((response) => {
        setFormData(response.data);
      });
    }
  }, []);

  const changeHandler = (val, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: val,
    });
    setValidation((prevValidation) => ({
      ...prevValidation,
      [fieldName]: "",
    }));
  };
  const formSubmitHandler = async () => {
    let updateValidation = { ...validation };
    props.Loader(true);
    let response = {}
    if (activeEditId) {
      response = await DivisionService.updateDivision(activeEditId, formData);
    }
    else {
      response = await DivisionService.saveDivision(formData);
    }
    if (response.code == OK || response.code == CREATED) {
      props.fetchDivisionList();
      props.addFormHandler();
      props.Toast.success("Division Added Successfully");
    }
    if (response.status && response.status == "ERROR") {
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(","),
          };
        }
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    }
    props.Loader(false);
  };
  return (
    <>
      <Modal
        show={isOpen}
        onHide={addFormHandler}
        dialogClassName="hResponsive"
        contentClassName="h-100"
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">{modelTitle}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {inputFields &&
                inputFields.map((field, index) => (
                  <div className={`col-12`} key={index} >
                    <div className="form-group">
                      <label className="text-gray-900 text-md opacity-75">{field.label}</label>
                      <FormInputs
                        {...field}
                        changeHandler={(val, e) => changeHandler(val, field.name)
                        }
                      />
                      {isSet(validation[field.name], "") !== "" ?
                        (<span className="text-danger m-1">{isSet(validation[field.name], "")}</span>)
                        :
                        ""
                      }
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              type="Single"
              className="btn btn-primary"
              clickHandler={() => formSubmitHandler()}
              label={activeEditId?"Update":"Save"}
              acl={"any"}
            />
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  function getInputFields() {
    const inputFields = [
      {
        type: "SelectList",
        label: "Company",
        options: companyList,
        placeholder: "",
        className: "form-control form-control-solid",
        name: "company_id",
        value: formData.company_id,
        required: true,
      },
      {
        type: "TextInput",
        label: "Division Name",
        placeholder: "Division Name",
        className: "form-control form-control-solid",
        name: "division_name",
        value: formData.division_name,
        required: true,
      },
    ];
    return inputFields;
  }
};

export default WithRoutify(AddDivision);
