import React, { Component } from "react";
import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";
import AddActivities from "./AddActivities";
import WithRoutify from "../../hoc/WithRoutify";
import ActivitiesService from "../../services/ActivitiesService";
import {
  ACTIVITY_FORM_TYPE,
  ACTIVITY_TYPE,
  OK,
  REMIND_IN,
} from "../../utils/constant";
import { isObject, isSet, toQueryString } from "../../utils/commonUtils";
import LookupService from "../../services/LookupService";
import TableSkeleton from "../../hoc/TableSkeleton";
import ConsentContainer from "./ConsentContainer/Index";
import withReduxState from "../../hoc/wReduxState";
import OrganizationService from "../../services/OrganizationService";

class ActivitiesList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      {
        label: "Code ",
        key: "activity_code",
        type: "",
        inputType: "TextInput",
        sortName: "activity_code",
      },
      {
        label: "Name",
        key: "activity_name",
        inputType: "TextInput",
        sortName: "activity_name",
      },
      {
        label: "Type",
        key: "activity_type",
        inputType: "SelectList",
        options: [],
        sortName: "activity_type",
      },
      // {
      //   label: "Bundle",
      //   key: "activity_bundle",
      //   inputType: "TextInput",
      //   sortName: "activity_bundle",
      // },
      {
        label: "Remind In",
        key: "remind_in",
        inputType: "SelectList",
        options: [],
        sortName: "remind_in",
      },
      // {
      //   label: "Form Type",
      //   key: "activity_form_type",
      //   inputType: "SelectList",
      //   options: [],
      //   sortName: "activity_form_type",
      // },
      { label: "Consent Declination", key: "consent", type: "Actions" },
      { label: "Action", key: "action", type: "Actions" },
      // Add more columns as needed
    ];
    this.state = {
      filters: {
        search_query: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "activity_name",
          sort_order: "asc",
          search: {},
        },
      },
      formDataInEdit: {},
      activeEditId: null,
      // Your state variables go here
      isModelOpen: false,
      isConsentModelOpen: false,
      activeActivityData: {},
    };
  }

  componentDidMount() {
    this.fetchActivitiesList();
    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  render() {
    const { tableRecords, filters, isModelOpen, activeEditId, isConsentModelOpen, activeActivityData } = this.state;
    return (
      <>
        {isModelOpen ? (
          <AddActivities
            modelTitle={`${this.state.activeEditId ? 'View and Update' : 'Add'} Activity`}
            isOpen={this.state.isModelOpen}
            fetchActivitiesList={this.fetchActivitiesList}
            addFormHandler={this.addFormHandler}
            activeEditId={activeEditId}
          />
        ) : (
          <></>
        )}
        {
          isConsentModelOpen ?
            <ConsentContainer
              activityData={activeActivityData}
              addFormHandler={this.consentModelHandler}
            />
            :
            ''
        }

        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"activities-create"}
          content={"Activities"}
        >
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                <h6 class="text-lg f-500 mb-0"> Activities List </h6>

                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div className="col-12 d-flex">
                      <div class="input-group input-group-joined input-group-solid">
                        <FormInputs
                          placeholder="Activities"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>

                      <div class="btn-group pl-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="addAction">
                    <div className="btn-group">
                      <Buttons
                        clickHandler={() => { this.addFormHandler(); this.setState({ activeEditId: '' }) }}
                        className="btn btn-primary"
                        acl={"activities-create"}
                        label={"Add"}
                      />
                      <button
                        className="btn btn-outline-secondary dropdown no-arrow"
                        data-bs-toggle="dropdown"
                      >
                        <span className="dropdown-toggle">
                          <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </span>
                        <div
                          className="dropdown-menu dropdown-menu-right shadow"
                          aria-labelledby="userDropdown"
                        >
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Group Edit
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Grid Edit
                          </a>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0 ">
              <TableGrid
                hasPagination={true}
                fetchTableRecords={(filters) =>
                  this.fetchActivitiesList(filters)
                }
                {...tableRecords}
              />
            </div>
          </div>
        </TableSkeleton>
      </>
    );
  }
  submitFiltersHandler = () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      page:1,
      search: filters,
    };
    this.fetchActivitiesList(queryfilters);
  };
  clearFiltersHandler = () => {
    this.setState(
      {
        filters: {},
      },
      this.submitFiltersHandler
    );
  };
  fetchActivitiesList = async (query={}) => {
    let orgId=  isSet(this.props.profile.role) && this.props.profile.role === 'global_admin' && this.props.profile.is_multiple_organization ? null : OrganizationService.getOrgId();
    const { tableRecords } = this.state;
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });
    try {
      this.props.Loader(true);
      const ActivitiesList = await ActivitiesService.getActivities(orgId,queryString);
      let tableData = [];
      ActivitiesList.data.data.map((activities, activitiesIndex) => {
        tableData = [
          ...tableData,
          {
            activitiesIndex: activitiesIndex,
            isHeading: false,
            rowId: activities.actitivity_id,
            data: {
              isActive: false,
              activity_id: activities.actitivity_id,
              activity_name: activities.activity_name,
              activity_code: activities.activity_code,
              cpt_code: activities.cpt_code,
              activity_type: activities.activity_type,
              activity_bundle: activities.activity_bundle,
              remind_in: isSet(activities.remind_in,'None'),
              // activity_form_type: isSet(activities.activity_form_type,'None'), -> commented for formtype not in needed field list
              created_by: activities.created_by,
              updated_at: activities.updated_at,
              created_at: activities.created_at,
              updated_at: activities.updated_at,
              // createdAt:usDateTimeString,//activities.created_at,
              //   active:'Yes',
              consent: [
                {
                  iconType: activities.consents ? "ConsentEdit" : "ConsentAdd",
                  title: "Edit Activity Consent & Consents Declination",
                  acl: "any",
                  clickHandler: () => this.consentModelHandler(activities.actitivity_id, activities),
                },
              ],
              action: [
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Search",
                  title: "View",
                  type: "GridEdit",
                  acl: "activities-edit",
                  clickHandler: (rowId, data) => this.editHandler(rowId, data),
                },
                {
                  className: "btn btn-icon btn-transparent-dark",
                  iconType: "Remove",
                  acl: "activities-delete",
                  title: "Delete",
                  isDelete: true,
                  clickHandler: () =>
                    this.deleteHandler(activities.actitivity_id),
                },
              ],
            },
          },
        ];
      });
      this.setState(
        (prevState) => ({
          tableRecords: {
            ...prevState.tableRecords,
            tableRows: {
              status: 200,
              type: "default",
              data: tableData,
            },
            pagination: {
              ...prevState.tableRecords.pagination,
              ...query,
              totalRecords:ActivitiesList.data.total
            },
          },
        }),
        () => {
          // console.log("state", this.state);
        }
      );
      this.props.Loader();
    } catch (e) {
      this.props.Loader();
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200,
          },
        },
      });
    }
  };
  deleteHandler = async (id) => {
    let orgId=  isSet(this.props.profile.role) && this.props.profile.role === 'global_admin' && this.props.profile.is_multiple_organization ? null : OrganizationService.getOrgId();
    ActivitiesService.deleteActivities(orgId,id)
      .then((res) => {
        // console.log("res==>", res);
        //toast and show error logic
        this.fetchActivitiesList();
        this.props.Toast.success("Activities Deleted Succesfully");
      })
      .catch((err) => {
        //
      })
      .finally(() => {
        //
      });
  };
  editHandler = (rowId, data) => {
    this.setState({
      activeEditId: rowId,
    });
    this.addFormHandler();
  };
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };

  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
  consentModelHandler = (activityId = '', data = {}, fetch = false,isConsentClose = false) => {
    this.setState({
      isConsentModelOpen: isConsentClose ? isConsentClose :  !this.state.isConsentModelOpen,
      activeActivityData: data,
    });
    if (fetch) {
      this.fetchActivitiesList();
    }
  }
}
export default withReduxState(WithRoutify(ActivitiesList));
