import { useEffect, useState } from "react";
import FormInputs from "../../components/UI/FormInputs";
import ClinicService from "../../services/ClinicService";
import Buttons from "../../components/UI/Buttons";
import { getLookupByKey, isSet } from "../../utils/commonUtils";
import ClinicDepartmentService from "../../services/ClinicDepartmentService";
import { VISIT_CATEGORY, VISIT_STATUS } from "../../utils/constant";
import DropDownService from "../../services/DropDownService";
import WithRoutify from "../../hoc/WithRoutify";

const EmployeeFilter = (props) => {
  const { filterData, closeHandler, submitHandler, onChangeHandler,Loader} = props;
  const [clinicOptions, setClinicOptions] = useState([]);
  const [companyOptions, setCompanyOption] = useState([]);
  const [visitStatusOptions, setvisitStatusOptions] = useState([]);
  const [visitCategoryOptions, setvisitCategoryOptions] = useState([]);

  const [clinicDepartmentOptions, setClinicDepartmentOptions] = useState([{ label: '-Clinic Department-', value: '' }]);
  const fetchClinicList = async (query = "") => {
    try {
      Loader(true);
      const clinicList = await DropDownService.getClinicList(null, query);
      let options = [{ label: "-Select Clinic-", value: "" }];
      clinicList.map((clinic, clinicIndex) => {
        options.push({
          label: clinic.name,
          value: clinic.clinic_id,
        });
      });
      setClinicOptions(options);
      Loader(false);
    } catch (e) { }
  };

  const fetchCompaniesList = async () => {
    Loader(true);
    const response=await DropDownService.getCompanies(null);
        let companiesOptions = [{ label: "-Select Company-", value: "" }];
        response.forEach((company) => {
          companiesOptions.push({
            label: company.name,
            value: company.company_uuid,
          });
        });
        setCompanyOption(companiesOptions);
    Loader(false);  
  };

  useEffect(() => {
    fetchCompaniesList();
    fetchClinicList();
    fetchLookup();
  }, []);
  //fetch lookup keys
  const fetchLookup = async () => {
    Loader(true);
    const visit_status = await getLookupByKey(VISIT_STATUS);
    setvisitStatusOptions(visit_status);
    const option = await getLookupByKey(VISIT_CATEGORY);
    setvisitCategoryOptions(option);
    Loader(false);
  }
  const inputFields = [
    {
      type: "TextInput",
      placeholder: "First Name",
      className: "form-control form-control-solid",
      name: "first_name",
      value: filterData.first_name,
    },
    {
      type: "TextInput",
      placeholder: "Last Name",
      className: "form-control form-control-solid",
      name: "last_name",
      value: filterData.last_name,
    },
    // {
    //   type: "TextInput",
    //   placeholder: "Patient ID",
    //   className: "form-control form-control-solid",
    //   name: "patient_id",
    //   value: filterData.patient_id,
    // },
    {
      type: "SelectList",
      options: companyOptions,
      placeholder: "company_id",
      className: "form-control form-control-solid",
      name: "company_id",
      value: filterData.company_id,
    },
    {
      type: "Date",
      placeholder: "Visit From",
      className: "form-control form-control-solid",
      name: "visit_from",
      value: filterData.visit_from,
    },
    {
      type: "Date",
      placeholder: "Visit To",
      className: "form-control form-control-solid",
      name: "visit_to",
      value: filterData.visit_to,
    },
    {
      type: "TextInput",
      className: "form-control form-control-solid",
      placeholder: "Sample ID",
      name: "sample_id",
      value: filterData.sample_id,
    },
    {
      type: "SelectList",
      options: visitCategoryOptions,
      placeholder: "",
      className: "form-control form-control-solid",
      name: "visit_category",
      value: filterData.visit_category,
    },
    {
      type: "SelectList",
      options: visitStatusOptions,
      placeholder: "",
      className: "form-control form-control-solid",
      name: "visit_status",
      value: filterData.visit_status,
    },
    // {
    //   type: "SelectList",
    //   options: ItentificationTypeOptions,
    //   className: "form-control form-control-solid",
    //   name: "identification_type",
    //   value: filterData.identification_type,
    // },
    // {
    //   type: "TextInput",
    //   className: "form-control form-control-solid",
    //   placeholder: "Identification Number",
    //   name: "identification_number",
    //   value: filterData.identification_number,
    // },
    {
      type: "SelectList",
      options: clinicOptions,
      className: "form-control form-control-solid",
      placeholder: "Clinic",
      name: "clinic_id",
      value: filterData.clinic_id,
    },
    {
      type: "SelectList",
      options: clinicDepartmentOptions,
      className: "form-control form-control-solid",
      placeholder: "Clinic",
      name: "clinic_location_id",
      value: filterData.clinic_location_id,
    },
    // {
    //   type: "TextInput",
    //   className: "form-control form-control-solid",
    //   placeholder: "Pre Registration Code",
    //   name: "pre_registration_code",
    //   value: filterData.pre_registration_code,
    // },
    // {
    //   type: "TextInput",
    //   className: "form-control form-control-solid",
    //   placeholder: "Drivers License",
    //   name: "drivers_license",
    //   value: filterData.drivers_license,
    // },
    {
      type: "TextInput",
      className: "form-control form-control-solid",
      placeholder: "Cup ID",
      name: "cup_id",
      value: filterData.cup_id,
    },
  ];
  const onChange = (fieldName, val) => {
    if (fieldName == 'clinic_id') {
      fetchClinicLocationList(val);
    }
    onChangeHandler(fieldName, val);

  }
  const fetchClinicLocationList = async (clinicId) => {
    try {
      Loader(true);
      const data = await ClinicDepartmentService.getClinicDepartmentByClinicId(null,clinicId);
      let clinicLocationInArr = [{ label: '-Clinic Department-', value: '' }];
      isSet(data, []).forEach((item, itemIndex) => {
        clinicLocationInArr.push({
          label: item.facility_name,
          value: item.id,
          key: itemIndex
        })
      });
      setClinicDepartmentOptions(clinicLocationInArr);
      Loader(false);
    } catch (e) {
      Loader(false);
    }
  }
  return (
    <>
      <div className="sidebar_Filter toggled" id="sidebar_FilterOpen">
        <div className="sh_filterBox">
          <div className="sh_filterHeader px-2 py-1">
            <div className="card-title mb-0">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0 text-md">Search by</h6>
                <Buttons
                  //iconType="Cancel"
                  className="btn-close text-sm"
                  acl={"any"}
                  clickHandler={() => closeHandler(false)}
                />
              </div>
            </div>
          </div>
          <div className="sh_filterBody">
            <div className="row">
              {inputFields.map((field, index) => (
                <div className="col-12" key={index}>
                  <div className="form-group mb-1">
                    <FormInputs
                      {...field}
                      changeHandler={(val, e) =>
                        onChange(field.name, val)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="sh_filterFooter">
            <Buttons
              clickHandler={closeHandler}
              className="btn btn-outline-secondary btn-sm text-sm"
              acl={"any"}
              label={"Clear"}
            />
            <Buttons
              clickHandler={submitHandler}
              className="btn btn-primary btn-sm text-sm"
              acl={"any"}
              label={"Apply"}
            />


          </div>
        </div>
      </div>
    </>
  );
};
export default WithRoutify(EmployeeFilter);
