
import React, { Component } from 'react';
import Buttons from '../../../../../components/UI/Buttons';
import TableGrid from '../../../../../components/UI/TableGrid';
import { LabResultCalcualtion, capitalizeName, isSet, toQueryString } from '../../../../../utils/commonUtils';
import withReduxState from '../../../../../hoc/wReduxState';
import WithRoutify from '../../../../../hoc/WithRoutify';
import Employeeervice from '../../../../../services/EmployeeService';
import AddLab from '../../../AddLab';
import PatientChartsService from '../../../../../services/PatientChartsService';
import FileManagerService from '../../../../../services/FileManagerService';
import AssignCompany from '../PhysicalTab/AssignCompany';
import DropDownService from '../../../../../services/DropDownService';
import Icons from '../../../../../components/UI/Icons';
import Button from '../../../../../components/UI/Buttons/Button';


class LaboratoryTab extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders =  [
      { label: '', key: 'isActive', type: 'checkBox', changeHandler: this.checkBoxchangeHandler },
      { label: 'Status', key: 'status',},
      { label: 'Lab ID', key: 'lab_id' },
      { label: 'Company', key: 'company' },
      // { label: 'Company ID', key: 'company_id' },
      { label: 'Patient', key: 'patient' },
      // { label: 'Patient ID', key: 'patient_id' },
      // { label: 'Location', key: 'location' },
      { label: 'Test Date', key: 'test_date' },
      { label: 'Time', key: 'time' },
      { label: 'Lab Order', key: 'lab_order' },
      { label: 'Result Date', key: 'result_date' },
      { label: 'Test Result', key: 'test_result' },
      // { label: 'Alternate ID', key: 'alternate_id' },
      { label: 'Action', key: 'action', type: 'Actions' },
    ];
    this.state = {
      filters: {
        search_query: ''
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: 'name',
          sort_order: 'asc',
          search: {},
        }
      },
      activeLabActivity: null,
      // Your state variables go here
      isModelOpen: false,
      addOrderModelOpen:false,
      isAssignCompany: !isSet(this.props.visitDetails.company),
      assignCompanyOptions:[],

    }
  }

  componentDidMount() {

    this.fetchLabsList();

    // this.fetchPhysicalList();
    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen })
  }

  assignTabToggleHandler = ()=>{ 
    this.setState({isAssignCompany:!this.state.isAssignCompany})
  }

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    })
  }
  render() {
    const {assignCompanyOptions,isAssignCompany,isModelOpen,activeEditId} = this.state;
    const {visitDetails}=this.props;
    console.log("props",visitDetails.visit_id);
    return (
      <>
        {isModelOpen ? (
          isAssignCompany ? <AssignCompany
          modelTitle={"Assign Company"}
          isOpen={this.state.isAssignCompany}
          addToogleHandler={this.addToogleHandler}
          assignToggleHandler={this.assignTabToggleHandler}
          fetchVisitDetails={()=>this.props.fetchVisitDetails()}
          options={assignCompanyOptions}
          employee_id={this.props.visitDetails.employee.employee_id}
    /> :
          <AddLab
            visitDetails={visitDetails}
            visitId={visitDetails.visit_id}
            activeEditId={activeEditId}
            modelTitle={`${this.state.activeEditId ? 'View and Update' : 'Add'} Lab`}
            isOpen={this.state.isModelOpen}
            fetchRecords={this.fetchLabsList}
            modalToogleHandler={this.addToogleHandler}
            organizationOption={this.state.organizationOption}
          />
        ) : (
          <></>
        )}
         
          <div className="sh_innerPortview ">
             
             <div className="sh_cardBox visitboxheight shadow-none border mb-0">
               <div className="sh_cardHeader">
                 <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                   <h6 className="text-sm mb-0">
                    {"Laboratory"}
                   </h6>
                   
                   <div className="statuscard ">
                        <div className="">
                        <Buttons
                          title={"started"}
                          iconType={"blue"}
                          acl={"any"}
                          />
                        </div>
                         <p className="mb-0">
                          Started
                        </p>
                        <div className="statuscard-date">--</div>
                     </div>
                     <div className="statuscard ">
                     <div className="">
                        <Buttons
                          title={"sent"}
                          iconType={"yellow"}
                          acl={"any"}
                          />
                        </div>
                         <p className="mb-0">
                          Sent
                        </p>
                        <div className="statuscard-date"> -- </div>
                     </div>
                     <div className="statuscard ">
                        <div className="">
                        <Buttons
                          title={"pending"}
                          iconType={"green"}
                          acl={"any"}
                          />
                        </div>
                         <p className="mb-0">
                          Pending
                        </p>
                        <div className="statuscard-date"></div>
                      </div>
                     <div className="statuscard ">
                     <div className="">
                        <Buttons
                          title={"failed"}
                          iconType={"red"}
                          acl={"any"}
                          />
                        </div>
                         <p className="mb-0">
                          Failed
                        </p>
                        <div className="statuscard-date"></div>

                     </div>
                     <Buttons
                        title={"Add Lab"}
                        className="btn btn-icon btn-transparent-dark mr-2"
                        acl={"any"}
                        iconType={"AddIconDetailView"}
                        clickHandler={() => { this.addToogleHandler(); this.setState({ activeEditId: '' }) }}
                      />
                 </div>
                 
                 {/* <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                  <h6 className="text-sm mb-0">
                    <span className="pr-1"><Icons type="green" /><span className="ml-1">Passed</span></span>
                    <span className="px-1"> <Icons type="red" /><span className="ml-1">Failed</span></span>
                    <span className="px-1"><Icons type="blue" /><span className="ml-1">Not Started</span></span>
                  </h6>
                  </div> */}
               </div>
               <div
                 className="sh_cardBody p-0"
                 style={{ height: "calc(100% - 120px)" }}>
                 <TableGrid {...this.state.tableRecords}/>
               </div>
             </div>
            
         </div>
      </>
    );
  }
  addToogleHandler=()=>{
    this.setState({isModelOpen:!this.state.isModelOpen})
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      search: filters,
    }
    this.fetchLabsList(queryfilters);
  }
  clearFiltersHandler = () => {
    this.setState({
      filters: {}
    }, this.submitFiltersHandler);
  }
  fetchPhysicalList = ()=>{
    const {visitDetails,tabId} = this.props;
  let tableRows = [];
  isSet(visitDetails[tabId],[]).forEach((item,itemIndex)=>{
    const status  = item.status;
    let statusClr = 'blue';
     if(status.toLowerCase()==='passed'){
      statusClr = 'green';
    } else  if(status.toLowerCase()==='failed'){
      statusClr = 'red';
    }
    tableRows = [
      ...tableRows,
      {
        physicalIndex: itemIndex,
        isHeading: false,
        rowId: item.physical_id,
        data: {
          isActive: false,
          activity_name: item?.activity?.activity_name,
          status:{
            title:status,
            value:<span class={`badge bg-${statusClr}-soft text-${statusClr}`}>{capitalizeName(item.status.toLowerCase())}</span>
          },
          activity_type:item?.activity?.activity_type,
          action: [
              {
                className:
                  "btn btn-icon btn-transparent-dark",
                iconType: "ActivityChart",
                acl: "any",
                title:'Chart',
                clickHandler:()=>this.editHandler(item)
                // href:`assign-activities/${item.mapping_id}`,
               
              },
            {
              className:
                "btn btn-icon btn-transparent-dark",
              iconType: "Remove",
              acl: "any",
              title: "Delete",
              isDelete:true,
              clickHandler: () =>this.deleteHandler(item.mapping_id),
            }, 
          ],
        },
      },
    ];
    this.setState({
      tableRecords: {
        ...this.state.tableRecords,
        tableRows: {
          type: "default",
          data: tableRows,
        },
      },
    });
  })
  }
  fetchLabsList = async (query = {}) => {
    if(this.state.isAssignCompany){
      this.getAssignCompanyOptionsForSelfRegister();
    }
    const { tableRecords } = this.state;
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });
    queryString.hasPagination=false;
    try {
      this.props.Loader(true);
      const labsList =  await Employeeervice.getEmployeeVisitLab(this.props.visitDetails.visit_id, queryString);;
      let tableData = [];
      // const hasMoreRecord = labsList.pagination.current_page < labsList.pagination.total_pages;      
      labsList.forEach((item, index) => {
        const {status,color} =LabResultCalcualtion(item.test_result);
        // console.log('status',color,status,index);
        tableData = [
          ...tableData,
          {
            labsIndex: index,
            isHeading: false,
            rowId: item.id,
            data: {
              isActive: false,
              status:{
                title:'Status',
                value:
                <>
                <div className="d-flex justify-content-left">
                <div className="d-flex Justify-content-end px-2" title={status}><Icons type={color} /></div>
                <div class={`badge bg-${color}-soft text-${color}`}>{capitalizeName(status.toLowerCase())}</div>
                </div>
                </>              
                },
              lab_id: item.random_lab_id || '--',
              company: item.visit?.company?.name || '--',
              company_id: item.visit?.company?.system_id || '--',
              patient: item?.visit?.employee?.first_name && item?.visit?.employee.last_name ? item.visit.employee.first_name +" "+ item.visit.employee.last_name : '--',
              patient_id: item?.patient_id || '--',
              location: item?.clinic_department?.facility_name || '--',
              test_date: item.test_date || '--',
              time: item.test_time || '--',
              lab_order: item.lab_order?.lab_order || '--',
              result_date: item.result_date || '--',
              test_result: item.test_result || '--',
              alternate_id: item.alternate_id || '--',
              active: 'Yes',
              action: [
                {
                className: 'btn btn-icon btn-transparent-dark',
                iconType: 'Search',
                title: 'View',
                type: 'GridEdit',
                acl: 'any',
                clickHandler: (rowId, data) => this.editHandler(item.id),
                updateHandler: () => this.updateHandler(''),
              },
              {
                className: 'btn btn-icon btn-transparent-dark pl-1 pr-1',
                title: 'Print',
                acl:'any',
                children:(
                  <>
                      <span className="dropdown dropdown-toggle position-static noArrow" data-bs-toggle="dropdown">
                       <svg fill="#000000" height="16" width="16" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enable-background="new 0 0 64 64">	<path d="M57.7881012,14.03125H52.5v-8.0625c0-2.2091999-1.7909012-4-4-4h-33c-2.2091999,0-4,1.7908001-4,4v8.0625H6.2119002		C2.7871001,14.03125,0,16.8183498,0,20.2431507V46.513649c0,3.4248009,2.7871001,6.2119026,6.2119002,6.2119026h2.3798995		c0.5527,0,1-0.4472008,1-1c0-0.5527-0.4473-1-1-1H6.2119002C3.8896,50.7255516,2,48.8359489,2,46.513649V20.2431507		c0-2.3223,1.8896-4.2119007,4.2119002-4.2119007h51.5762024C60.1102982,16.03125,62,17.9208508,62,20.2431507V46.513649		c0,2.3223-1.8897018,4.2119026-4.2118988,4.2119026H56c-0.5527992,0-1,0.4473-1,1c0,0.5527992,0.4472008,1,1,1h1.7881012		C61.2128983,52.7255516,64,49.9384499,64,46.513649V20.2431507C64,16.8183498,61.2128983,14.03125,57.7881012,14.03125z		 M13.5,5.96875c0-1.1027999,0.8971996-2,2-2h33c1.1027985,0,2,0.8972001,2,2v8h-37V5.96875z"/>	<path d="M44,45.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,47.0302505,20,47.0302505h24		c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,45.0322495,44,45.0322495z"/>	<path d="M44,52.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,54.0302505,20,54.0302505h24		c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,52.0322495,44,52.0322495z"/>	<circle cx="7.9590998" cy="21.8405495" r="2"/>	<circle cx="14.2856998" cy="21.8405495" r="2"/>	<circle cx="20.6121998" cy="21.8405495" r="2"/>	<path d="M11,62.03125h42v-26H11V62.03125z M13.4036999,38.4349518h37.1925964v21.1925964H13.4036999V38.4349518z"/></svg>
                      </span>
                      <div className="dropdown-menu dropdown-menu-left shadow" aria-labelledby="userDropdown">
                          <div className="dropdown-item" href="#" onClick={()=>this.printlabel(item,"print_label_1x3")}>
                            Print Lab Label 1 ⅛ X 3 ½
                          </div>
                          <div className="dropdown-item" href="#" onClick={()=>this.printlabel(item,"print_label_2x3")}>
                            Print Lab Label 2 X 3.8
                          </div>
                          <div className="dropdown-item" href="#" onClick={()=>this.printlabel(item,"lab_report")}>
                            Print Lab Report
                          </div>
                          <div className="dropdown-item" href="#" onClick={()=>this.printlabel(item,"lab_requisition")}>
                            Print Lab Requisition
                          </div>
                        </div>
                  </>
                )
               }
            ]
            }
          }
        ]
      });
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            status: 200,
            type: "default",
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords:0
          },
        },
      }),()=>{
        // if (hasMoreRecord) {
        //   // this.loadMoreLabs();
        // }
      });
      this.props.Loader();  
    } catch (e) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200
          }
        }
      });
      this.props.Loader();
    }
  }

  printlabel=async(data,type,labelName='lab')=>{
    const queryfilters = {
      label_type:type,
      visit_id:data.visit_id,
  }
  if (type=='lab_report') {
    data = await PatientChartsService.getLaboratoryReport(null,queryfilters,data.id);
  }
  else if(type=='lab_requisition'){
    data = await PatientChartsService.getLabRequisition(null,queryfilters,data.id);
  }
  else{
    data = await PatientChartsService.getPrintLabel(null,queryfilters,data.id);
  }
  const pdfData = {
    file_content:data,
    original_name:`${labelName}`,
    mime_type:'application/pdf'
    
}
FileManagerService.printPdf(pdfData);



  }
  deleteHandler = async (labId,visit_id) => {
    const { Loader, Toast } = this.props;
    try {

      await Employeeervice.deleteEmployeeVisitLabById(visit_id,labId)
      .then((response) => {
          Toast.success("Lab Deleted Successfully");
        })
        .catch(err => {})
        .finally(() => {
          this.fetchLabsList();
        });
    } catch (e) {

    }

  }
  editHandler = async(rowId) => {
      this.setState({
        activeEditId: rowId,
    },this.addToogleHandler());
  }

  getAssignCompanyOptionsForSelfRegister= async()=>{
    
    const {visitDetails} = this.props;
    const {employee} =visitDetails;
    const clinic_id = employee.clinic_id;
    const assignCompanyOptions = await DropDownService.getAssignCompanyOptionsForSelfRegister(clinic_id);
    let options=[{label:'-Select Company-',value:''}];
      assignCompanyOptions.forEach(val => {
        options.push({label:val.name,value:val.company_uuid});
      });
    //activity bundle
    this.setState({assignCompanyOptions:options})
  }
 
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click 
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
  loadMoreLabs = () => {
    const { pagination } = this.state.tableRecords;
    if (pagination) {
      // Fetch the next set of records by incrementing the page number
      this.fetchLabsList({ page: pagination.page + 1 });
    }
  };
}

export default withReduxState(WithRoutify(LaboratoryTab));