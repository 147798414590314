import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import { useToast } from '../../context/ToaxtContext';
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import Buttons from "../../components/UI/Buttons";
import CptService from "../../services/CptService";


const AddCpt = (props) => {
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const { isOpen, addFormHandler, fetchCptList, modelTitle, activeEditId, Toast, Loader } = props;
  useEffect(() => {
    if (activeEditId) {
      //fetch 
      CptService.getCptById(activeEditId)
        .then((response) => {
          setFormData({ ...formData, ...response })
        })

    }
  }, []);

  const inputFields = [
    {
      type: "TextInput",
      label: "CPT Code",
      placeholder: "code",
      className: "form-control form-control-solid",
      name: "code",
      required: true,
    },
    {
      type: "TextArea",
      label: "Description",
      placeholder: "Description",
      className: "form-control form-control-solid",
      name: "description",
      value: formData.description,
    }

  ];
  const changeHandler = (val, fieldName, e) => {
    setFormData({
      ...formData,
      [fieldName]: val,
    });
    setValidation({ ...validation, [fieldName]: "" });
  };
  const formSubmitHandler = async () => {

    const payload = {
      code: formData.code,
      description: formData.description
    }
    let updateValidation = { ...validation };
    let data;
    if (activeEditId) {
      data = await CptService.updateCptById(activeEditId, payload)
    }
    else {
      data = await CptService.saveCpt(payload);
    }
    if (data.status && data.status == "ERROR") {
      if (isObject(data.data)) {
        for (let key in data.data) {
          updateValidation = {
            ...updateValidation,
            [key]: data.data[key].join(',')
          }
        }
      } else {
        Toast.error(data.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    } else {
      fetchCptList();
      addFormHandler();
      let message = `CPT Code ${activeEditId ? 'Update' : 'Added'} Sucessfully`
      Toast.success(message);
    }

  }
  return (
    <>
      <Modal
        show={isOpen}
        onHide={addFormHandler}
        size="lg"
        dialogClassName="hResponsive"
        contentClassName="h-100"
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="font-weight-bold text-lg mb-0">
                {modelTitle}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {inputFields &&
                inputFields.map((field, index) => (
                  <div className={`col-12  `} key={index}>
                    <div className="form-group">
                      <label className="text-gray-900 text-md opacity-75"> {field.label} </label>
                      <FormInputs {...field} changeHandler={(value, e) => changeHandler(value, field.name, e)} value={formData[field.name]} />
                      {isSet(validation[field.name], '') !== '' ? <span className="text-danger m-1">{isSet(validation[field.name], '')}</span> : ''}
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              clickHandler={() => formSubmitHandler()}
              className="btn btn-primary"
              acl={"any"}
              label={activeEditId ? "Update" : "Save"}
            />
            {/*
          <Buttons
              clickHandler={() => addFormHandler()}
              className="btn btn-outline-secondary rounded-pill"
              acl={"any"}
              label={"Cancel"}
            />
            */}
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default withReduxState(WithRoutify(AddCpt));