import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import ActivitiesList from "./ActivitiesList";

const ActivitiesContainer = () => {

  return (
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<ActivitiesList/>} />
      </Routes>
  
     
        
    </Wrap>
  );
};






export default ActivitiesContainer;
