import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { fetchTimeInterval, getCurrentDate, getCurrentTime, getLookupByKey, getSpanish, isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import withReduxState from "../../hoc/wReduxState";
import WithRoutify from "../../hoc/WithRoutify";
import Buttons from "../../components/UI/Buttons";
import VisitsService from "../../services/VisitsService";
import LabOrderdService from "../../services/LabOrderdService";
import Employeeervice from "../../services/EmployeeService";
import ClinicDepartmentService from "../../services/ClinicDepartmentService";
import { LAB_TEST_RESULT, REASON_FOR_LAB, VACCINATION_STATUS } from "../../utils/constant";
import PatientChartsService from "../../services/PatientChartsService";
import OrganizationService from "../../services/OrganizationService";
import { data } from "jquery";
import { acl_check } from "../../utils/aclUtils";
import PERMISSIONS from "../../utils/AclPermissions";
import Icons from "../../components/UI/Icons";
import DropDownService from "../../services/DropDownService";
import LookupService from "../../services/LookupService";
import FileManagerService from "../../services/FileManagerService";
import LabPanel from "./EmployeeVisitDetailView/RightContent/ActivityChart/ChartsFields/LabPanel";


const AddLab = (props) => {
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const { isOpen, modalToogleHandler, modelTitle, visitId, Loader, Toast, activeEditId, fetchRecords } = props;
  const [visitDetails, setVisitDetails] = useState({});
  const [labOptions, setLabOptions] = useState([]);
  const [labOrderOptions, setLabOrderOptions] = useState([]);
  const [clinicDepatmentOptions, setClinicDepatmentOptions] = useState([]);
  const [reasonForLabOptions, setreasonForLabOptions] = useState([]);
  const [vaccinationStatusOptions, setvaccinationStatusOptions] = useState([]);
  const [labTestResultOptions, setlabTestResultOptions] = useState([]);
  const [showPrintLabelModal,setShowPrintLabelModal]=useState(false);
  const [savedLabId,setsavedLabId]=useState(null);
  const inputFields = getInputFields();
  let hasEditAccess = acl_check(props.profile.permissions, 'result-edit');
  let hasCreateAccess = acl_check(props.profile.permissions, 'result-create');
  console.log('mapping_id',props.mapping_id);
  //intial render
  useEffect(() => {
    
    fetchIntialDatas();
  }, []);
  const fetchIntialDatas = async () => {
    Loader(true);
    let updateFormData = { ...formData };

    if (isSet(props.visitDetails) && isSet(props.visitDetails[0]) && isSet(props.visitDetails[0].company)) {
      setVisitDetails(props.visitDetails[0]);
      await fetchClinicDepartment(props.visitDetails[0]?.employee?.clinic_id);      
    } else {
      await fetchVisitDetails();
    }
    // await fetchLabs();
    await fetchLookup()
    //if edit
    if (activeEditId) {

      let response = await Employeeervice.getEmployeeVisitLabById(visitId, activeEditId);
      updateFormData = { ...updateFormData, ...response };
      
    }
    if (props.mapping_id) {
      updateFormData['mapping_id'] = isSet(props.mapping_id, '');
    }
    updateFormData['test_date'] = updateFormData['test_date'] ? updateFormData['test_date'] : getCurrentDate();
    updateFormData['test_time'] = updateFormData['test_time'] ? updateFormData['test_time'] : getCurrentTime();
    updateFormData['received_time'] = updateFormData['received_time'] ? updateFormData['received_time'] : getCurrentTime();
    updateFormData['status'] = updateFormData['status'] ? updateFormData['status'] : "PENDING";
    setFormData(updateFormData);
    Loader(false);
  }

  console.log('formData',formData);

  //lab-order-fetch
  useEffect(() => {
    const fetchLabOrder = async () => {
      let labOrderOptions = [{ label: "-Lab Order-", value: "" }];
      if (formData.lab_id) {
        const response = await LabOrderdService.getLabOrder(null, formData.lab_id);
        isSet(response, []).forEach((itm, ind) => {
          labOrderOptions.push({ label: itm.lab_order, value: itm.lab_order_id });
        });
      }
      setLabOrderOptions(labOrderOptions);

    }
    fetchLabOrder();

  }, [formData.lab_id]);

  //fetch details
  const fetchVisitDetails = async () => {
    try {
      const visitDetails = await VisitsService.getVisits(visitId);
      setVisitDetails(visitDetails[0])
      await fetchClinicDepartment(visitDetails[0]?.employee?.clinic_id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  }
  useEffect(()=>{
    if (visitDetails?.employee) {
      fetchLabs();
    }
  },[visitDetails]);

  //fetch lab
  const fetchLabs = async () => {
    try {
      // const data = await LabService.getLabsList(null);
      const query = { clinic_id: visitDetails.employee.clinic_id };
      const data = await DropDownService.getLabsforClinic(null,query);

      let labOptions = [{ label: "-Lab Choice-", value: "" }];

      // isSet(data.data.data, []).forEach((itm, ind) => {
      //   labOptions.push({ label: itm.name, value: itm.lab_id });
      // });
      if (Array.isArray(data) && data.length > 0) {
        data.forEach((itm) => {
          labOptions.push({ label: itm.name, value: itm.lab_id });
        });
      } 
      setLabOptions(labOptions);
    } catch (error) {
      console.error("Error fetching labOptions:", error);
    }
  }

  //fetch clinic department
  const fetchClinicDepartment = async (clinic_id) => {
    
    let data = [];
    if(isSet(clinic_id)){
      data = await ClinicDepartmentService.getClinicDepartmentByClinicId(null,clinic_id);
    }
    let options = [{ label: "-Clinic Department-", value: "" }];
    isSet(data, []).forEach((itm, ind) => {
      options.push({ label: itm.facility_name, value: itm.id });
      setClinicDepatmentOptions(options);

    });
  }
  //fetch lookup keys
  const fetchLookup = async () => {
    const reasonForLab = await getReasonForLab(REASON_FOR_LAB);
    setreasonForLabOptions(reasonForLab);
    const vaciantionStatus = await getLookupByKey(VACCINATION_STATUS);
    setvaccinationStatusOptions(vaciantionStatus);
    const labTestResult = await getLookupByKey(LAB_TEST_RESULT);
    setlabTestResultOptions(labTestResult);
  }

  const getReasonForLab = async (key) => {
    let lookupOptions = [];
    try {
      const data = await LookupService.getLookupByKey(key)
      data.forEach((item) => {
        lookupOptions.push({
          label: item.value,
          value: item.value
        });
      });
  
      return lookupOptions;
    } catch (e) {
      //catch execption if need
      console.error('error in getLookupByKey', e);
      return lookupOptions;
    }
  }

  //


  //change handler
  const changeHandler = (value, fieldName, dependencyValue) => {
  
    const updateValidation = { ...validation };
    if(fieldName === 'test_result' && value !== 'RECEIVED'){
      updateValidation['received_date'] = '';
      setValidation(updateValidation);
    }
    const updateFormData = { ...formData };
    
    updateFormData[fieldName] = value;
    updateValidation[fieldName] = '';
    setFormData(updateFormData);
    setValidation(updateValidation);

  }

  //submit handler
  const formSubmitHandler = async () => {
    Loader(true);
    let updateValidation = { ...validation };
    // if(isSet(formData.test_date)){

    //   var parts = formData.test_date.split('/');
    //   var day = parseInt(parts[1]);
    //   var month = parseInt(parts[0]) - 1; // month is zero-based in JavaScript Date object
    //   var year = parseInt(parts[2]);
      
    //   // Creating a Date object from the provided date
    //   var testDate = new Date(year, month, day);

    //   // Validating against the year 1900
    //   if (testDate < new Date(1900, 0, 1) || formData.test_date === 'Invalid Date') {
    //     setValidation({
    //       ...updateValidation,
    //       ['test_date']: 'Valid Test Date field is required.'
    //     });
    //     return false;
    //   } 
    // }
    
    if(isSet(formData.received_date) && formData.received_date !== 'Invalid Date'){
      var parts = formData.received_date.split('/');
      var day = parseInt(parts[1]);
      var month = parseInt(parts[0]) - 1; // month is zero-based in JavaScript Date object
      var year = parseInt(parts[2]);
      
      // Creating a Date object from the provided date
      var receivedDate = new Date(year, month, day);

      // Validating against the year 1900
      if (receivedDate < new Date(1900, 0, 1) ) {
        setValidation({
          ...updateValidation,
          ['received_date']: 'Valid Received Date field is required.'
        });
        Loader(false);
        return false;
      } 
    }

    if(activeEditId){
      if(formData.test_result === 'RECEIVED' && (!isSet(formData.received_date)  || formData.received_date === 'Invalid Date')){
        setValidation({
          ...updateValidation,
          ['received_date']: 'Valid Received Date field is required.'
        });
        Loader(false);
        return false;
      }
    }
    
    let response;
    let payload = { ...formData };
    payload.company_id = visitDetails?.company?.company_uuid;
    payload.visit_id = visitId;
    payload.organization_uuid = OrganizationService.getOrgId();
    if (props.fromChart) {
      if (activeEditId) {
        response = await PatientChartsService.updatePatientCharts(isSet(formData.organization, null), payload.company_id, props.mapping_id, payload)
      }
      else {
        response = await PatientChartsService.savePatientCharts(isSet(formData.organization, null), visitDetails?.company?.company_uuid, props.mapping_id, payload);
      }
    }
    else {
      if (activeEditId) {
        response = await Employeeervice.updateEmployeeVisitLabById(visitId, activeEditId, payload);
      }
      else {
        response = await Employeeervice.saveEmployeeVisitLab(visitId, payload)
      }
    }

    if (response.status && response.status == "ERROR") {
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(',')
          }
        }
      } else {
        Toast.error(response.data);
      }


      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    } else {
      let message=`${activeEditId?"Lab Updated Successfully":"Lab Added Successfully"}`;
      props.Toast.success(message);
      labelPrintHandler(response.id);
    }
    Loader(false);
  }
  const labelPrintHandler = (savedLabId) => {
    if (savedLabId && !activeEditId) {
      setShowPrintLabelModal(true);
      setsavedLabId(savedLabId);
    }
    else {
      modalToogleHandler();
      fetchRecords();
    }
  }
  return (
    <>
      {
        showPrintLabelModal ?
          <PrintLabelModal
            labelPrintHandler={labelPrintHandler}
            visitId={visitId}
            savedLabId={savedLabId}
          />
          :
          ''
      }
      <Modal
        show={isOpen}
        size="xl"
        onHide={modalToogleHandler}
        dialogClassName="hResponsive"
        contentClassName="h-100"
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">
                {activeEditId ? "View and Update Lab" : "Add Lab"}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="sh_innerPortview w-100 d-flex">
              <div className="row">
              <div className="col-lg-4 col-12 p-2">
                <div className="sh_cardBox h-auto sh_patientdetailwidget">
                  <div className="sh_cardHeader">
                    <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                    <h6 className="text-md font-weight-bold mb-0">
                      <span className="px-1">
                        <Icons type="patientdetails" />
                      </span>
                      Patient Details </h6>
                    </div>
                  </div>
                  <div className="sh_cardBody py-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                               <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">Company</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title={visitDetails?.company?.name}>{visitDetails?.company?.name}</h6>
                        </div>
                      </div>
                      </div>
                    </div>
                        <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                                <label className="f-400 text-sm text-truncate mb-0 opacity-75">Patient</label>
                              </div> <div className="col-6 pt-1">
                                <h6 className="font-weight-bold text-sm text-truncate" title={visitDetails?.employee?.first_name + ' ' + visitDetails?.employee?.last_name || '--'}>{visitDetails?.employee?.first_name + ' ' + visitDetails?.employee?.last_name || '--'}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">Patient ID</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title={visitDetails?.employee?.patient_id || '--'}>{visitDetails?.employee?.patient_id || '--'}</h6>
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">Booking ID</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title={visitDetails?.employee?.booking_id || '--'}>{visitDetails?.employee?.booking_id || '--'}</h6>
                        </div>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">System ID</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title={visitDetails?.employee?.system_id || '--'}>{visitDetails?.employee?.system_id || '--'}</h6>
                        </div>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">EMR Patient ID</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title={'--'}>{'--'}</h6>
                        </div>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">Race</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title={visitDetails?.employee?.race || '--'}>{visitDetails?.employee?.race || '--'}</h6>
                        </div>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                          <label className="f-400 text-sm text-truncate mb-0 opacity-75">Address</label>
                          </div> <div className="col-6 pt-1">
                          <h6 className="font-weight-bold text-sm text-truncate" title={visitDetails?.employee?.address || '--'}>{visitDetails?.employee?.address || '--'}</h6>
                        </div>
                        </div>
                      </div>
                      </div>
                    </div>

                  </div>

                </div>
                  {activeEditId ?
                    <div className="sh_cardBox h-auto sh_labdetailwidget">
                      <div className="sh_cardHeader">
                        <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                          <h6 className="text-md font-weight-bold mb-0">
                            <span className="px-1">
                            <Icons type="visitdetails" />
                            </span>
                            Visit Details
                          </h6>
                        </div>
                      </div>
                      <div className="sh_cardBody py-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <div className="row" style={{ borderBottom: '1px solid #f2f2f4' }}>
                                <div className="col-6 pt-1">
                                  <label className="f-400 text-sm text-truncate mb-0 opacity-75">Visit Date & Time</label>
                                </div> <div className="col-6 pt-1">
                                  <h6 className="font-weight-bold text-sm text-truncate" title={`${visitDetails.visit_date} saodak
                                  & ${visitDetails.visit_time}`}>{visitDetails.visit_date}& {visitDetails.visit_time}</h6>
                                </div>
                              </div>
                              <div className="row" style={{ borderBottom: '1px solid #f2f2f4' }}>
                                <div className="col-6 pt-1">
                                  <label className="f-400 text-sm text-truncate mb-0 opacity-75">Visit Category</label>
                                </div> <div className="col-6 pt-1">
                                  <h6 className="font-weight-bold text-sm text-truncate" title={visitDetails?.visit_category}>{visitDetails.visit_category}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>
                    :
                    ''
                  }
                {activeEditId ?
                  <div className="sh_cardBox h-auto sh_labdetailwidget">
                    <div className="sh_cardHeader">
                      <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                        <h6 className="text-md font-weight-bold mb-0">
                                <span className="px-1">
                                <Icons type="labdetails" />
                                </span>
                                Lab Details 
                            </h6>
                      </div>
                    </div>
                    <div className="sh_cardBody py-0">
                      <div className="row">
                      <div className="col-md-12">
                          <div className="form-group mb-0">
                            <div className="row"  style={{borderBottom: '1px solid #f2f2f4'}}>
                              <div className="col-6 pt-1">
                            <label className="f-400 text-sm text-truncate mb-0 opacity-75">Lab ID</label>
                           </div> <div className="col-6 pt-1">
                            <h6 className="font-weight-bold text-sm text-truncate" title="Company">{formData.random_lab_id}</h6>
                          </div>
                          </div>
                        </div>
                        </div>
                      </div>

                    </div>

                  </div>
                  :
                  ''
                }
              </div>
              <div className="col-lg-8 col-12">
                <div className="card">
                  <div className="card-body">
                  <div className="card-body tab-content p-0">
                    {inputFields &&
                    inputFields.map((section, sectionIndex) => {
                      return (
                      <>
                      <div className="card mb-2">
                        <a
                          href={`#collapseCardExample_${sectionIndex}`}
                          className="d-block sh_accordionheader card-header"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="true"
                          aria-controls="collapseCardExample"
                          style={{
                            position: "sticky",
                            top: "-15px",
                            zIndex: "1",
                          }}
                        >
                          <h6 className="m-0 text-md">
                            {section.title}
                          </h6>
                        </a>
                        <div
                          className="collapse show"
                          id={`collapseCardExample_${sectionIndex}`}
                          data-bs-parent="#modal111"
                        >
                          <div className="card-body">
                            <div className="row">
                              {section.data && section.data.map((data, dataInd) => {
                                return data && (
                                  <>
                                    <div className={`${data.col ? data.col : 'col-lg-4 col-12'}`}>
                                      <div className="form-group">
                                        <label className="text-sm text-truncate mb-0 opacity-75">
                                          {data.label}
                                        </label>
                                        <div className={`${data.type === "MultiSelectDropDown" ? '1d-flex 1row' : ''}`}
                                           key={dataInd}
                                         >
                                          <FormInputs
                                            {...data}
                                            value={isSet(formData[data.name], "")}
                                            className={
                                              "form-control form-control-solid"
                                            }
                                            readOnly={!(hasEditAccess && hasCreateAccess)}
                                            changeHandler={(value, e) =>
                                              changeHandler(value, data.name, {e})
                                            }
                                          />
                                          {isSet(validation[data.name], "") !==
                                            "" ? (
                                            <span className="text-danger m-1">
                                              {isSet(validation[data.name], "")}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
                
                <ChildLabOrder
                formData={formData}
                visitDetails={visitDetails}
                fetchRecords={fetchIntialDatas}
                activeEditId={activeEditId}
                />
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>

          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            {
             <Buttons
              type='Single'
              className="btn btn-primary"
              clickHandler={() => formSubmitHandler()}
              label={ activeEditId ? "Update" : "Save"}
              acl={activeEditId?PERMISSIONS.RESULT.EDIT:PERMISSIONS.RESULT.CREATE} />
            }
           
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  function getInputFields() {
    const inputFields = [
      {
        title:  "Lab Order",
        data:[
          {
            type: "Patient ID",
            label: "Patient ID",
            placeholder: "Patient ID",
            className: "form-control form-control-solid",
            name: "patient_id",
          },
          {
            type: "SelectList",
            options: labOptions,
            label: "Lab Choice",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "lab_id",
            required: true,
          },
          {
            type: "SelectList",
            options: labOrderOptions,
            label: "Lab Order",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "lab_order_id",
            required: true,
          },
          {
            type: "SelectList",
            options: clinicDepatmentOptions,
            label: "Clinic Department",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "clinic_department_id",
            // required: true,
          },
          {
            type: "MultiSelectDropDown",
            options: reasonForLabOptions,
            label: "Reason For Lab (ICD-10)",
            name: "reason_for_lab",
            ddKey:'reason_for_lab',
            id: "reason_for_lab",
            isToogle:true
          },
          {
            type: "SelectList",
            options: vaccinationStatusOptions,
            label: "Vaccination Status",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "vaccination_status",
          },
          {
            type: "Date",
            label: "Test Date",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "test_date",
            required: true,
          },
          {
            type: "SelectSearchList",
            label: "Test Time",
            placeholder: "Test Time",
            isAsync: true,
            options: [{ 'label': getCurrentTime(), value: getCurrentTime() }],
            loadCallBack: (val, callback) => fetchTimeInterval(val, callback),
            className: "form-control form-control-solid",
            name: "test_time",
            required: true,
          },

        ],
      },
      {
        title:"Lab Result",
        data:[
          {
            type: "TextArea",
            label: "Notes",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "notes",
          },
          {
            type: "Date",
            label: "Result Date",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "result_date",
          },
          {
            type: "SelectList",
            options: labTestResultOptions,
            label: "Test Result",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "test_result",
          },
          activeEditId ?
            {
              type: "Date",
              label: "Received Date",
              placeholder: "",
              className: "form-control form-control-solid",
              name: "received_date",
              required:formData.test_result === 'RECEIVED'
            } : '',
          activeEditId ?
          {
            type: "SelectSearchList",
            label: "Received Time",
            placeholder: "Received Time",
            isAsync: true,
            options: [{ 'label': getCurrentTime(), value: getCurrentTime() }],
            loadCallBack: (val, callback) => fetchTimeInterval(val, callback),
            className: "form-control form-control-solid",
            name: "received_time",
            required: true,
          } : '',
          activeEditId ?
            {
              type: "TextInput",
              label: "Sample ID",
              placeholder: "Sample ID",
              className: "form-control form-control-solid",
              name: "sample_id",
            } : '',
          activeEditId ?
            {
              type: "TextInput",
              label: "Accession",
              placeholder: "Accession",
              className: "form-control form-control-solid",
              name: "accession",
            } : '',
          {
            type: "TextInput",
            label: "Alternate ID",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "alternate_id",
          },
          activeEditId ?
            {
              type: "TextInput",
              label: "Result Value",
              placeholder: "Result Value",
              className: "form-control form-control-solid",
              name: "result_value",
            } : '',
          activeEditId ?
            {
              type: "TextInput",
              label: "Units",
              placeholder: "Units",
              className: "form-control form-control-solid",
              name: "units",
            } : '',
          activeEditId ?
            {
              type: "TextInput",
              label: "Flag",
              placeholder: "Flag",
              className: "form-control form-control-solid",
              name: "flag",
            } : '',
          activeEditId ?
            {
              type: "TextInput",
              label: "Reference Range",
              placeholder: "Reference Range",
              className: "form-control form-control-solid",
              name: "reference_range",
            } : '',
          activeEditId ?
            {
              type: "TextArea",
              label: "Lab Message",
              placeholder: "Lab Message",
              className: "form-control form-control-solid",
              name: "lab_message",
            } : '',
          {
            type: "TextInput",
            label: "Test Range",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "test_range",
          },
          {
            type: "TextArea",
            label: "Generic Message",
            placeholder: "",
            className: "form-control form-control-solid",
            name: "generic_message",
          },
        ]
      }
      
    ];
    return inputFields;
  }
};
const ChildLabOrder = (props) => {
  const { formData, visitDetails,fetchRecords,activeEditId } = props;
  const updatedFormData = {
    ...formData,
    parent_id: formData.id,
  };
  return (
    <>
      {
        activeEditId ?
          <LabPanel
            formData={updatedFormData}
            fetchRecords={fetchRecords}
          />
          :
          ''
      }

      {/* {
        formData?.child_lab && formData.child_lab != null && formData.child_lab.length > 0 && (
          <div className="card mb-2">
            <a
              href={`#collapseCardExample_child`}
              className="d-block sh_accordionheader card-header"
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="true"
              aria-controls="collapseCardExample_child"
              style={{
                position: "sticky",
                top: "-15px",
                zIndex: "1",
              }}
            >
              <h6 className="m-0 text-md">{"Child Lab Order"}</h6>
            </a>
            <div
              className="collapse show"
              id={`collapseCardExample_child`}
              data-bs-parent="#modal111"
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="card-body p-0">
                      <table className="table dataTable table-hover nowrap my-0 table-striped" style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th>Lab Ordered</th>
                            <th>Result</th>
                            <th>Reference Range</th>
                            <th>Units</th>
                            <th>Flag</th>
                            <th>Lab Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData?.child_lab.map((item, ind) => (
                            <tr key={ind}>
                              <td>{item.lab_order}</td>
                              <td>{item.result}</td>
                              <td>{item.reference_range}</td>
                              <td>{item.units}</td>
                              <td>{item.flag}</td>
                              <td>{item.lab_message}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      } */}
    </>
  )
}
const PrintLabelModal = (props) => {
  const { labelPrintHandler, savedLabId, visitId } = props;
  const printLabel = async (type, labelName = 'lab') => {
    const queryfilters = {
      activity_name: 'Lab',
      label_type: type,
      visit_id: visitId,
    }
    if (type == 'lab_report') {
      data = await PatientChartsService.getLaboratoryReport(null, queryfilters, savedLabId);
    }
    else if (type == 'lab_requisition') {
      data = await PatientChartsService.getLabRequisition(null, queryfilters, savedLabId);
    }
    else {
      data = await PatientChartsService.getPrintLabel(null, queryfilters, savedLabId);
    }
    const pdfData = {
      file_content: data,
      original_name: `${labelName}`,
      mime_type: 'application/pdf'

    }
    FileManagerService.printPdf(pdfData);
    labelPrintHandler(null);
  }
  return (
    <>
      <Modal
        show={true}
        onHide={() => labelPrintHandler(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="text-lg mb-0 font-weight-bold">
              Print Label or Requisition
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Would you like to print any of the followings ?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <div className="btn-group mr-1">
            <a className="btn btn-xs btn-secondary ">
              <i className="fas fa-print fa-sm"></i> Label
            </a>
            <button
              className="btn btn-xs  btn-outline-secondary dropdown no-arrow"
              data-bs-toggle="dropdown"
              aria-expanded="false" >
              <span className="dropdown-toggle">
                <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
              </span>
              <div
                className="dropdown-menu dropdown-menu-right shadow"
                aria-labelledby="userDropdown"
              >
                <a
                  className="dropdown-item"
                  href="!#"
                  onClick={() =>
                    printLabel('print_label_2x3')
                  }
                >
                  2 x 3.8
                </a>
                <a
                  className="dropdown-item"
                  href="!#"
                  onClick={() =>
                    printLabel('print_label_1x3')
                  }
                >
                  1⅛ x 3½
                </a>
              </div>
            </button>
          </div>
          <Buttons
            type='Single'
            className="btn btn-primary"
            label={"Requisition"}
            acl={"any"}
            clickHandler={() =>
              printLabel('lab_requisition')
            }


          />
          <Buttons
            type='Single'
            className="btn btn-xs btn-secondary"
            label={"None"}
            clickHandler={() => labelPrintHandler(null)}
            acl={"any"}

          />

        </Modal.Footer>
      </Modal>
    </>
  );
}
export default withReduxState(WithRoutify(AddLab));
