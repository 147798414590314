import { useEffect, useState } from "react";
import Buttons from "../../../components/UI/Buttons";
import { isSet } from "../../../utils/commonUtils";
import { useSelector } from "react-redux";

const ClinicDetailView = (props) => {
  const { clinicDetails } = props;
  const [formData, setFormData] = useState({});
  const stateList = useSelector((state) => state.session.profileStateList);
  const inputFields = getInputFields();
  useEffect(() => {
    setFormData(clinicDetails);
  }, [clinicDetails]);
  return (
    <>
      <div className="sh_innerPortview">
        <div class="sh_cardBox">
          <div class="sh_cardHeader" style={{ position: "relative" }}>
            <div class="form-group d-flex mb-0 justify-content-between align-items-center">
              <h6 class="text-lg f-500 mb-0">
                <span class="badge back_btn  mr-2 ">
                  <Buttons
                    label={"Back"}
                    iconType={"Back"}
                    acl={"any"}
                    href={"/clinics"}
                  />
                </span>
                Detail View of {clinicDetails.name}
              </h6>

              <div class="addAction d-flex">
                <Buttons
                  iconType="Edit"
                  acl={"any"}
                  className="btn btn-icon btn-transparent-dark"
                  clickHandler={() => props.changeLayout()}
                />
              </div>
            </div>
          </div>
          <div
            class="sh_cardBody p-0"
            style={{ position: "relative", background: "#fff" }}
          >
            <div class="sh_innerPortview w-100 d-flex">
              <div class="w-100 p-3">
                {inputFields &&
                  inputFields.map((item, itmInd) => {
                    return (
                      <>
                        <div class="sh_cardBox h-auto shadow-none border   mb-3">
                          <div class="sh_cardHeader">
                            <div class="form-group d-flex mb-0 justify-content-between align-items-center">
                              <h6 class="f-400 text-md mb-0">{item.title}</h6>
                            </div>
                          </div>

                          <div class="sh_cardBody">
                            <div class="row">
                              {item.data &&
                                item.data.map((data, dataInd) => {
                                  return (
                                    <>
                                      <div class="col-md-4 col-12">
                                        <div class="form-group">
                                          <label class="f-400 text-sm text-truncate mb-0 opacity-75">
                                            {data.label}
                                          </label>
                                          <h6 class="font-weight-bold text-sm text-truncate">
                                            {isSet(data.value, "--")}
                                          </h6>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  function getInputFields() {
    const inputFields = [
      {
        title: "Clinic - Address & Contact",
        data: [
          {
            type: "TextInput",
            label: "Code",
            placeholder: "Code",
            className: "form-control form-control-solid",
            name: "code",
            value: formData['code'] || "",
            required: true,
            max: 5,
            allUpperCase: true
          },
          {
            type: "TextInput",
            label: "Name",
            placeholder: "Name",
            className: "form-control form-control-solid",
            name: "name",
            value: formData['name'] || "",
            required: true,
            isFirstLetterCaps: true,
          },
          {
            type: "TextInput",
            label: "Location",
            placeholder: "Location",
            className: "form-control form-control-solid",
            name: "location",
            value: formData['location'] || "",
            required: true,
            isFirstLetterCaps: true
          },
          {
            type: "Address",
            label: "Address",
            placeholder: "Address",
            className: "form-control form-control-solid",
            name: "address",
            value: formData['address'] || "",
            required: true,
            isFirstLetterCaps: true
          },
          {
            type: "TextInput",
            label: "City",
            placeholder: "City",
            className: "form-control form-control-solid",
            name: "city",
            value: formData['city'] || "",
            required: true,
            isFirstLetterCaps: true
          },
          {
            type: "SelectList",
            label: "State",
            options: stateList,
            placeholder: "State",
            className: "form-control form-control-solid",
            name: "state",
            value: formData['state'] || "",
            required: true,
          },
          {
            type: "Number",
            label: "Zip+4",
            placeholder: "Zip+4",
            className: "form-control form-control-solid",
            name: "zip_plus_4",
            value: formData['zip_plus_4'] || "",
            required: true,
            max: 9
          },
          {
            type: "TextInput",
            label: "Country",
            placeholder: "Country",
            className: "form-control form-control-solid",
            name: "county",
            value: formData['county'] || "",
            required: true,
            isFirstLetterCaps: true
          },
          {
            type: "Phone",
            label: "Phone",
            placeholder: "Phone",
            className: "form-control form-control-solid",
            name: "phone",
            value: formData['phone'] || "",
            required: true,
          },
          {
            type: "Number",
            label: "Fax",
            placeholder: "Fax",
            className: "form-control form-control-solid",
            name: "fax",
            value: formData['fax'] || "",
            max: 10,
          },
        ],
      },
      {
        title: "Clinic - ID's & Certificates",
        data: [
          {
            type: "Number",
            label: "Federal ID",
            placeholder: "Federal ID",
            className: "form-control form-control-solid",
            name: "federal_tax_id",
            value: formData['federal_tax_id'] || "",
            required: true,
            max: 9
          },
          {
            type: "Number",
            label: "Group NPI",
            placeholder: "Group NPI",
            className: "form-control form-control-solid",
            name: "group_npi",
            value: formData['group_npi'] || "",
            required: true,
            max: 10
          },
          {
            type: "TextInput",
            label: "CLIA",
            placeholder: "CLIA",
            className: "form-control form-control-solid",
            name: "clia",
            value: formData['clia'] || "",
            max: 10
          },
          {
            type: "TextInput",
            label: "Medicare ID",
            placeholder: "Medicare ID",
            className: "form-control form-control-solid",
            name: "medicare_id",
            value: formData['medicare_id'] || "",
            max: 6
          },
          {
            type: "TextInput",
            label: "Medicaid ID",
            placeholder: "Medicaid ID",
            className: "form-control form-control-solid",
            name: "medicaid_id",
            value: formData['medicaid_id'] || "",
          },
          {
            type: "TextInput",
            label: "DOL ID",
            placeholder: "DOL ID",
            className: "form-control form-control-solid",
            name: "dol_id",
            value: formData['dol_id'] || "",
            max: 9
          },
        ]

      },
      {
        title: "Clinic - Notifications Emails",
        data: [
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) Emp Services",
            placeholder: "Notification Email(s) Emp Services",
            className: "form-control form-control-solid",
            name: "notification_emails_emp_services",
            value: formData['notification_emails_emp_services'] || "",
          },
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) Provider Charting",
            placeholder: "Notification Email(s) Provider Charting",
            className: "form-control form-control-solid",
            name: "notification_emails_provider_charting",
            value: formData['notification_emails_provider_charting'] || "",
          },
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) Paid Invoices",
            placeholder: "Notification Email(s) Paid Invoices",
            className: "form-control form-control-solid",
            name: "notification_emails_paid_invoices",
            value: formData['notification_emails_paid_invoices'] || "",
          },
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) Management",
            placeholder: "Notification Email(s) Management",
            className: "form-control form-control-solid",
            name: "notification_emails_management",
            value: formData['notification_emails_management'] || "",
          },
          {
            type: "Tag",
            tagInputType:'Email',
            label: "Notification Email(s) All Employees",
            placeholder: "Notification Email(s) All Employees",
            className: "form-control form-control-solid",
            name: "notification_emails_all_employees",
            value: formData['notification_emails_all_employees'] || "",
          },
        ]


      },
      {
        title: "Clinic - Assigned Labs",
        data: [
          {
            type: "TextInput",
            label: "Assigned Labs",
            placeholder: "Assigned Labs",
            className: "form-control form-control-solid",
            name: "assigned_lab_id",
            value: formData?.lab?.name || "",
          },
        ]


      },
      {
        title: "Clinic - Assigned Providers",
        data: [
          {
            type: "TextInput",
            label: "Assigned Providers",
            placeholder: "Assigned Providers",
            className: "form-control form-control-solid",
            name: "assigned_providers",
            value: formData['assigned_providers'] || "",
          },
        ]
      },
      {
        title: "Clinic - Images",
        data: [
          {
            type: "FileUpload",
            label: "Business License",
            placeholder: "Business License",
            className: "form-control form-control-solid",
            name: "business_license",
            value: formData['business_license_orginal_name'] || "",
          },
          {
            type: "FileUpload",
            label: "CLIA Certificate",
            placeholder: "CLIA Certificate",
            className: "form-control form-control-solid",
            name: "clia_certificate",
            value: formData['clia_certificate_orginal_name'] || "",
          },
        ]


      },
    ];

    return inputFields;
  }
};
export default ClinicDetailView;
