import React, { Component } from "react";
import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";
import AddDivision from "./AddDivision";
import WithRoutify from "../../hoc/WithRoutify";
import DivisionService from "../../services/DivisionService";
import CompanyService from "../../services/CompanyService";
import { OK } from "../../utils/constant";
import AddDivision2 from "./AddDivision2";
import {
  getOrganizationId,
  isObject,
  isSet,
  toQueryString,
} from "../../utils/commonUtils";
import TableSkeleton from "../../hoc/TableSkeleton";
import DropDownService from "../../services/DropDownService";

class DivisionList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      {
        label: "Company Name",
        key: "company_id",
        inputType: "SelectList",
        options: [],
      },
      { label: "Division Name", key: "division_name", inputType: "TextInput" },
      { label: "Division 2", key: "parent_id", type: "Actions" },
      { label: "Action", key: "action", type: "Actions" },
      // Add more columns as needed
    ];
    this.state = {
      filters: {
        search_query: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "name",
          sort_order: "asc",
          search: {},
        },
      },
      formDataInEdit: {},
      activeEditId: null,
      // Your state variables go here
      isModelOpen: false,
      companyList: [],
      division2ModelOpen: false,
      activeDivision: "",
    };
  }

  componentDidMount() {
    this.fetchDivisionList();
    this.fetchCompanyList();

    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  render() {
    const {
      tableRecords,
      filters,
      isModelOpen,
      companyList,
      division2ModelOpen,
      activeDivision,
      activeEditId
    } = this.state;
    return (
      <>
        {isModelOpen ? (
          <AddDivision
            modelTitle={`${this.state.activeEditId?'Update':'Add'} Division`}
            isOpen={isModelOpen}
            fetchDivisionList={this.fetchDivisionList}
            addFormHandler={this.addFormHandler}
            companyList={companyList}
            activeEditId={activeEditId}
          />
        ) : (
          <></>
        )}
        {division2ModelOpen ? (
          <AddDivision2
            modelTitle={`Division 2 of ${activeDivision.division_name}`}
            isOpen={division2ModelOpen}
            activeDivision={activeDivision}
            fetchDivisionList={this.fetchDivisionList}
            addFormHandler={this.addDivision2FormHandler}
            companyList={companyList}
          />
        ) : (
          <></>
        )}
        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"company-division-create"}
          content={"Division"}
        >
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                <h6 class="text-lg f-500 mb-0"> Company Division List </h6>

                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div class="col-12 px-1 d-flex">
                      <div class="input-group input-group-joined input-group-solid">
                        <FormInputs
                          placeholder="Division"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>

                      <div class="btn-group pl-2">
                        <button
                          class="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          class="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="addAction">
                    <div className="btn-group">
                      <Buttons
                        clickHandler={()=>{this.addFormHandler();this.setState({activeEditId:''})}}
                        className="btn btn-primary"
                        acl={"company-division-create"}
                        label={"Add"}
                      />
                      <button
                        className="btn btn-outline-secondary dropdown no-arrow"
                        data-bs-toggle="dropdown"
                      >
                        <span className="dropdown-toggle">
                          <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </span>
                        <div
                          className="dropdown-menu dropdown-menu-right shadow"
                          aria-labelledby="userDropdown"
                        > 
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Group Edit
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Grid Edit
                          </a>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>

                {/*<div className="form-group-fields row">
                            <div className="col-md-4 px-1">
                                  <FormInputs 
                                    placeholder="Division"
                                    value={filters["division_name"]}
                                    changeHandler={(val)=>this.onChangeFiltersHandler('division_name',val)}
                                    className="form-control"/>
                                </div>
                                <button className="btn btn-primary rounded-pill mr-2" onClick={()=>this.submitFiltersHandler()}>
                                  Search
                                </button>
                                <button className="btn btn-outline-secondary rounded-pill" onClick={()=>this.clearFiltersHandler()}>
                                  Clear
                                </button>
                            </div>*/}
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                hasPagination={true}
                fetchTableRecords={(filters) => this.fetchDivisionList(filters)}
                {...tableRecords}
              />
            </div>
          </div>
        </TableSkeleton>
      </>
    );
  }
  submitFiltersHandler = () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      page:1,
      search: filters,
    };
    this.fetchDivisionList(queryfilters);
  };
  clearFiltersHandler = () => {
    this.setState(
      {
        filters: {},
      },
      this.submitFiltersHandler
    );
  };
  fetchCompanyList = async () => {
    let companyListResponse = await DropDownService.getCompanies(null);
    let companyListOptions = [{ label: "-Select Company", value: "" }];
    companyListResponse &&
    companyListResponse?.forEach((company, companyInd) => {
        companyListOptions.push({
          label: company.name,
          value: company.company_uuid,
        });
      });
    this.setState({ companyList: companyListOptions });
  };
  fetchDivisionList = async (query = {}) => {
    const { tableRecords } = this.state;
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });
    this.props.Loader(true);
    DivisionService.getDivision(getOrganizationId(), queryString)
      .then((res) => {
        let responseData = res.data;
        let tableData = [];
        responseData.data.forEach((division, divisionIndex) => {
          tableData = [
            ...tableData,
            {
              divisionIndex: divisionIndex,
              isHeading: false,
              rowId: division.division_id,
              data: {
                isActive: false,
                company_id:division.company.name,
                division_name: division.division_name,
                parent_id: [
                  {
                    className: "text-sm",
                    // iconType: "Edit",
                    acl: "company-division-edit",
                    label: "Division 2",
                    clickHandler: (rowId, data) =>
                      this.detailViewDivision2(division),
                  },
                ],
                active: "Yes",
                action: [
                  {
                    className:
                      "btn btn-icon btn-transparent-dark",
                    iconType: "Search",
                    title: "View",
                    type: "GridEdit",
                    acl: "company-division-edit",
                    clickHandler: (rowId, data) =>
                      this.editHandler(rowId, data, division.division_id),
                  },
                  {
                    className:
                      "btn btn-icon btn-transparent-dark",
                    iconType: "Remove",
                    acl: "company-division-delete",
                    title: "Delete",
                    isDelete: true,
                    clickHandler: () =>
                      this.deleteHandler(division.division_id),
                  },
                ],
              },
            },
          ];
        });
        //update pagination
        const updatePagination = (prevStatePagination, newPagination) => ({
          page: newPagination.page || prevStatePagination.page,
          perPage: newPagination.perPage || prevStatePagination.perPage,
          totalRecords: responseData.pagination.totalCount,
          sort_by: newPagination.sort_by || prevStatePagination.sort_by,
          sort_order:
            newPagination.sort_order || prevStatePagination.sort_order,
        });
        //update state
        this.setState((prevState) => ({
          tableRecords: {
            ...prevState.tableRecords,
            tableHeaders: prevState.tableRecords.tableHeaders,
            tableRows: {
              type: "default",
              data: tableData,
              status: 200,
            },
            pagination: {
              ...prevState.tableRecords.pagination,
              ...query,
              totalRecords: responseData.pagination.totalCount,
            },
          },
        }));
      })
      .catch((err) => {
        // console.log("err===>", err);
        this.setState({
          tableRecords: {
            ...tableRecords,
            tableRows: {
              ...tableRecords.tableRows,
              status: 200,
            },
          },
        });
      })
      .finally(() => {
        this.props.Loader(false);
      });
  };
  deleteHandler = async (divisionId) => {
    DivisionService.deleteDivision(null,divisionId)
      .then((res) => {
        // console.log("res==>", res);
        this.fetchDivisionList();
        this.props.Toast.success("Division Deleted Succesfully");
      })
      .catch((err) => {
        //
      })
      .finally(() => {
        //
      });
  };
  editHandler = (rowId, data, company_id) => {
    this.setState({
      activeEditId: rowId,
    },this.addFormHandler());
  };
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  onChangeFormDataInEdit = (key, val) => {
    this.setState((prevState) => ({
      formDataInEdit: {
        ...prevState.formDataInEdit,
        [key]: val,
        validation: {
          ...prevState.formDataInEdit.validation,
          [key]: "", // Set validation empty
        },
      },
    }));
  };
  updateHandler = async (divisionId) => {
    let { formDataInEdit } = this.state;
    let payload = {
      division_name: formDataInEdit.division_name,
      company_id: formDataInEdit.company_id,
    };
    let response = await DivisionService.updateDivision(divisionId, payload);
    if (response.status == OK) {
      this.editHandler(null, {}, null);
      this.fetchDivisionList();
      this.props.Toast.success("Division updated Successfully");
    } else if (response.status == "ERROR" && response.data) {
      if (isObject(response.data)) {
        let validaitons = {};
        for (let key in response.data) {
          validaitons = { ...validaitons, [key]: response.data[key][0] };
        }
        this.setState((prevState) => ({
          formDataInEdit: {
            ...prevState.formDataInEdit,
            validation: validaitons,
          },
        }));
      }
    }
  };
  detailViewDivision2 = (division) => {
    this.setState({ activeDivision: division }, this.addDivision2FormHandler());
  };
  addDivision2FormHandler = () => {
    this.setState({ division2ModelOpen: !this.state.division2ModelOpen });
  };
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}
export default WithRoutify(DivisionList);
