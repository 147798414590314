import { Modal } from "react-bootstrap";
import FormInputs from "../../components/UI/FormInputs";
import { isSet } from "../../utils/commonUtils";
import { useState } from "react";
import Buttons from "../../components/UI/Buttons";
import HL7Service from "../../services/HL7Service";

const HL7ReprocessModal=(props)=>{
    const {id ,addFormHandler}=props;
    console.log("id",id);
    const [validation, setValidation] = useState({});
    const [file, setFile] = useState(null);
    
    const submitHandler=async()=>{
        if (!file) {
            setValidation({...validation,['file']:"File Field is required"});
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('log_id', id);
        const response =await HL7Service.hl7ReprocessFile(formData);
        console.log('response',response);
        
    }
    return(
        <Modal
        show={true}
        keyboard={false}
        onHide={()=>addFormHandler()}
        contentClassName='h-100'
        backdrop="static"
        size="md"
    >
        <Modal.Header closeButton>
            <Modal.Title>
                <h6 className="text-lg mb-0 font-weight-bold">
                    Upload Reprocess Hl7 File
                </h6>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <label className="text-sm text-truncate mb-0 opacity-150">{"File"}</label>
            <FormInputs
                type='FileUpload'
                title="Field Document"
                name='file'
                className=''
                value={isSet(file, '')}
                changeHandler={(val) => {
                    setFile(val);
                    setValidation({});
                }}

            />
            {isSet(validation['file'], '') !== '' ? <span className="text-danger m-1">
                {isSet(validation['file'], '')}</span> : ''}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
            <Buttons
                type='Single'
                className="btn btn-primary"
                clickHandler={() => submitHandler()}
                label='Upload'
                acl={'any'}
            />
        </Modal.Footer>
    </Modal>
    );
}
export default HL7ReprocessModal;