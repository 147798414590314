import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
const UserSearchFilter = (props) => {
  const { filterData, closeHandler, submitHandler, rolesForFilter, fetchRoles } = props;
  const inputFields = [
    {

      type: "SelectSearchList",
      options: rolesForFilter,
      value: filterData["role_name"],
      name: "role_name",
      isAsync: true,
      loadCallBack: (val, callback) => {
        fetchRoles(val, callback)
      },
      isCacheNeeded: false,
      reload: false
    },


    {
      type: "TextInput",
      placeholder: "First Name",
      className: "form-control form-control-solid",
      name: "first_name",
      value: filterData.first_name,
    },
    {
      type: "TextInput",
      placeholder: "Last Name",
      className: "form-control form-control-solid",
      name: "last_name",
      value: filterData.last_name,
    },
    {
      type: "TextInput",
      placeholder: "Email",
      value: filterData["email"],
      name: "email",
      className: "form-control"
    }
    , {
      type: "Phone",
      name: "phone",
      placeholder: "Cell Number",
      value: filterData["phone"],
      className: "form-control"
    }

  ];
  return (
    <>
      <div className="sidebar_Filter toggled" id="sidebar_FilterOpen">
        <div className="sh_filterBox">
          <div className="sh_filterHeader px-2 py-1">
            <div className="card-title mb-0">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0 text-md">Search by</h6>
                <Buttons
                  //iconType="Cancel"
                  className="btn-close text-sm"
                  acl={"any"}
                  clickHandler={() => closeHandler(false)}
                />
              </div>
            </div>
          </div>
          <div className="sh_filterBody">
            <div className="row">
              {inputFields.map((field, index) => (
                <div className="col-12" key={index}>
                  <div className="form-group mb-1">
                    <FormInputs
                      {...field}
                      changeHandler={(val, e) =>
                        props.onChangeHandler(field.name, val)
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="sh_filterFooter">
            <Buttons
              clickHandler={closeHandler}
              className="btn btn-outline-secondary btn-sm text-sm"
              acl={"any"}
              label={"Clear"}
            />
            <Buttons
              clickHandler={submitHandler}
              className="btn btn-primary btn-sm text-sm"
              acl={"any"}
              label={"Apply"}
            />


          </div>
        </div>
      </div>
    </>
  );
};
export default UserSearchFilter;
