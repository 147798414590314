import React from 'react';
import { connect } from 'react-redux';
import { isSet } from '../utils/commonUtils';
import AuthService from '../services/AuthService';

const withReduxState = (WrappedComponent) => {
 
  const mapStateToProps = (state) => {
    const profileId = isSet(AuthService.getProfileId(),null);
    return {
      profile: (profileId !== null) ? state.session.profileDetails[profileId] || {} : {},
      reloadKeyProps:state.common.reloadKeyProps,
      // Add other state properties as needed
    };
  };
  const ConnectedComponent = connect(mapStateToProps)(WrappedComponent);

  return (props) => <ConnectedComponent {...props} />;
};

export default withReduxState;