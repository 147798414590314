import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import LabService from "../../services/LabService";
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import Buttons from "../../components/UI/Buttons";
import { useSelector } from "react-redux";
import { organizationList } from "../../store/OrganizationSlice";
import OrganizationService from "../../services/OrganizationService";
import DropDownService from "../../services/DropDownService";

const AddLab = (props) => {
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({})
  const { isOpen, addFormHandler, modelTitle, Toast, activeEditId } = props;
  const organizationListOptions = useSelector(organizationList);
  const [clinic, setClinicOptions] = useState([]);
  useEffect(() => {
    const updateFormdata = async () => {
      if (activeEditId) {
        const labResponse = await LabService.getLabId(activeEditId);
        let updateFormData={...labResponse};
        let assigned_clinics = labResponse['clinics'];
        let clinic_mapping = isSet(assigned_clinics, []).map(itm => itm.clinic_id);
        updateFormData['assigned_clinics'] = clinic_mapping.join(',');
        setFormData(updateFormData); 
      }
    }
    updateFormdata();

  }, []);

  useEffect(() => {
    fetchClinic();
  }, [formData.organization]);

    //fetch clinic
    const fetchClinic = async () => {
      let opt = [];
      props.Loader(true);
      let clinicResponse = await DropDownService.getClinicList(isSet(formData.organization,null));
      clinicResponse&&clinicResponse?.forEach((itm, ind) => {
        opt.push({ label: itm.name, value: itm.clinic_id })
      });
      setClinicOptions(opt);
      props.Loader(false);
    }
  const inputFields = [
    {
      type: "TextInput",
      label: "Lab",
      placeholder: "Lab Name",
      className: "form-control form-control-solid",
      name: "name",
      value: formData.name,
      required: true,
    },
    {
      type: "Address",
      label: "Lab Address",
      placeholder: "Lab Address",
      className: "form-control form-control-solid",
      name: "lab_address",
      value: formData.lab_address,
    },
    {
      type: "Phone",
      label: "Lab Phone",
      placeholder: "Lab Phone",
      className: "form-control form-control-solid",
      name: "lab_phone",
      value: formData.lab_phone,
    },
    {
      type: "TextInput",
      label: "Lab CLIA",
      placeholder: "Lab CLIA",
      className: "form-control form-control-solid",
      name: "lab_clia",
      value: formData.lab_clia,
      max: 10,
    },
    {
      type:'TextInput',
      label:"Lab State License",
      placeholder: "Lab State License",
      className: "form-control form-control-solid",
      name:'state_license',
      value: formData.state_license,
    },
    {
      type: "TextInput",
      label: "Lab Director Name",
      placeholder: "Lab Director Name",
      className: "form-control form-control-solid",
      name: "lab_director_name",
      value: formData.lab_director_name,
    },
    {
      type: "TextInput",
      label: "Lab Manager Name",
      placeholder: "Lab Manager Name",
      className: "form-control form-control-solid",
      name: "lab_manager_name",
      value: formData.lab_manager_name,
    },
    {
      type: "TextInput",
      label: "Lab Web Address",
      placeholder: "Lab Web Address",
      className: "form-control form-control-solid",
      name: "lab_web_address",
      value: formData.lab_web_address,
    },
    {
      type: "TextInput",
      label: "Lab Portal Username",
      placeholder: "Lab Portal Username",
      className: "form-control form-control-solid",
      name: "lab_portal_username",
      value: formData.lab_portal_username,
    },
    {
      type: "password",
      label: "Lab Portal Password",
      placeholder: "Lab Portal Password",
      className: "form-control form-control-solid",
      name: "lab_portal_password",
      value: formData.lab_portal_password,
    },
    {
      type: "MultiSelectDropDown",
      label: "Assign Clinic",
      options:clinic,
      placeholder: "",
      className: "form-control form-control-solid",
      name: "assigned_clinics",
      value: formData.assigned_clinics,
      required: true,
    },
    {
      type: "TextArea",
      label: "Description",
      placeholder: "Description",
      className: "form-control form-control-solid",
      name: "description",
      value: formData.description,
    }

  ];
  if (isSet(props.profile.is_multiple_organization, false) === true && !activeEditId && organizationListOptions.length > 2) {
    inputFields.unshift(
      {
        type: "SelectList",
        label: "Organization",
        options: organizationListOptions,
        placeholder: "",
        className: "form-control form-control-solid",
        name: "organization",
        required: true,
        value: formData.organization,
      }
    );
  }
   const changeHandler = (value, fieldName, {addressParam,selectVal}) => {
    let updateFormData = { ...formData };
    let updateValidation = { ...validation };
    if (fieldName == 'assigned_clinics') {
      if (value) {
        let data;
        if (typeof formData[fieldName] === 'string' && formData[fieldName] != '') {
          data = formData[fieldName].split(',');
        } else {
          data = [];
        }
        data.push(selectVal);
        data = data.join(',');
        value = data;

      }
      else {
        let data = formData[fieldName].split(',');
        let index = data.indexOf(selectVal);
        if (index !== -1) {
          data.splice(index, 1);
        }
        value = data.join(',');
      }
    }
    updateFormData[fieldName] = value;
    updateValidation[fieldName] = "";
    setFormData(updateFormData);
    setValidation(updateValidation);
  };
  const formSubmitHandler = async () => {
    props.Loader(true);
    let updateValidation = { ...validation };
    //check if global admin 
    if (isSet(props.profile.is_multiple_organization, false) === true && organizationListOptions.length > 2) {
      //check organization filled if no throwing validation
      if (isSet(formData.organization, false) == false && !activeEditId) {
        setValidation({
          ...updateValidation,
          ['organization']: 'The Organization Field is Required',
        });
        props.Loader(false);
        return false
      }
    }
    let data;
    if (activeEditId) {
      data = await LabService.updateLabs(activeEditId, formData);
    }
    else {
      data = await LabService.saveLabs(isSet(formData.organization, null), formData);
    }
    if (data.status && data.status == "ERROR") {
      if (isObject(data.data)) {
        for (let key in data.data) {
          updateValidation = {
            ...updateValidation,
            [key]: data.data[key].join(',')
          }
        }
      } else {
        Toast.error(data.data);
      }


      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    } else {
      props.fetchLabsList();
      props.addFormHandler();
      let messages=`Lab ${activeEditId ? 'Updated' : 'Added'} Successfully`;
      Toast.success(messages);
    }
    props.Loader(false);

  }
  return (
    <>
      <Modal
        show={isOpen}
        onHide={addFormHandler}
        size="lg"
        dialogClassName="hResponsive"
        contentClassName="h-100"
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="font-weight-bold text-lg mb-0">
                {modelTitle}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {inputFields &&
                inputFields.map((field, index) => (
                  <div className={field.type == "MultiSelectDropDown" || field.name == "description" ? "col-lg-12" : "col-lg-6"} key={index}>
                    <div className="form-group">
                      <label className="text-gray-900 text-md opacity-75"> {field.label} </label>
                      <div className={`${field.type == "MultiSelectDropDown" ? 'd-flex row pr-lg-0 pr-2' : ''}`}>
                      <FormInputs {...field} changeHandler={(val, e) => changeHandler(val, field.name, e)} />
                      {isSet(validation[field.name], '') !== '' ? <span className="text-danger m-1">
                        {isSet(validation[field.name], '')}</span> : ''}
                        </div>
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              clickHandler={() => formSubmitHandler()}
              className="btn btn-primary"
              acl={"any"}
              label={activeEditId ? "Update" : "Save"}
            />
            {/*
          <Buttons
              clickHandler={() => addFormHandler()}
              className="btn btn-outline-secondary rounded-pill"
              acl={"any"}
              label={"Cancel"}
            />
            */}
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default withReduxState(WithRoutify(AddLab));