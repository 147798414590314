import React from 'react';
import { isSet } from '../../utils/commonUtils';
import { _t } from '../../utils/i18nUtils';
import FormInputs from '../../components/UI/FormInputs';
import { genderOptions } from '../../utils/constant';
import ReCAPTCHA from 'react-google-recaptcha';







const StepOne = (props) => {


  const { changeHandler, formData, validation,clinicOptions,clinicLocationOptions,fetchClinicOptions,isVisible,isClinicSelfRegistration } = props;



  const inputFields =
    [
      // {
      //   type: "TextInput",
      //   label: _t('PreRegistrationCode'),
      //   placeholder: _t('PreRegistrationCode'),
      //   className: "form-control form-control-solid",
      //   name: "pre_registration_code",
      //   isFirstLetterCaps: true,
      //   col: 'col-lg-4 col-6',
      // },
      {
        type: "SelectSearchList",
        options: clinicOptions,
        label: _t('ClinicList'),
        placeholder: "Type",
        className: "form-control form-control-solid",
        name: "clinic_id",
        required: true,
        isAsync:true,
        loadCallBack: (val, callback) => fetchClinicOptions(val,callback),
        col: 'col-lg-4 col-md-6 col-12',
        isCacheNeeded:false,
        readOnly:isClinicSelfRegistration,
      },
      // {
      //   type: "SelectList",
      //   options: clinicLocationOptions,
      //   label: _t('ClinicLocation'),
      //   className: "form-control form-control-solid",
      //   name: "clinic_department_id",
      //   required: true,
      //   col: 'col-lg-4 col-6'
      // },
      {
        type: "TextInput",
        label: _t('FirstName'),
        placeholder: _t('FirstNamePh'),
        className: "form-control form-control-solid",
        name: "first_name",
        isFirstLetterCaps: true,
        required: true,
        col: 'col-lg-4 col-md-6 col-12'
      },

      {
        type: "TextInput",
        label: _t('LastName'),
        placeholder: _t('LastNamePh'),
        className: "form-control form-control-solid",
        name: "last_name",
        isFirstLetterCaps: true,
        required: true,
        col: 'col-lg-4 col-md-6 col-12'
      },
      {
        type: "SelectList",
        label: "Gender",
        placeholder: "Gender",
        className: "form-control form-control-solid",
        name: "gender",
        options: genderOptions,
        value: formData.gender,
        col:'col-lg-4 col-md-6 col-12',
        required:true
      },
      {
        type: "Date",
        label: _t('DateOfBirth'),
        placeholder: _t('DateOfBirth'),
        className: "form-control form-control-solid",
        name: "dob",
        required: true,
        col: 'col-lg-4 col-md-6 col-12',
        disableFuture:true
      },
      {
        type: "Age",
        label: _t('Age'),
        placeholder: _t('Age'),
        readOnly: true,
        className: "form-control form-control-solid",
        name: "dob",
        required: true,
        col: 'col-lg-4 col-md-6 col-12',
        isNotValidate:true
      },
      {
        type: "TextInput",
        label: _t('PatientEmail'),
        placeholder: _t('PatientEmail'),
        className: "form-control form-control-solid",
        name: "email",
        col: "col-lg-4 col-md-6 col-12",
        required:true
        // value: formData.email,
      },
      {
        type: "Phone",
        label: _t('CellPhone'),
        placeholder: _t('CellPhonePh'),
        className: "form-control form-control-solid",
        name: "phone",
        required: true,
        col: 'col-lg-4 col-md-6 col-12',
        disableFuture:true,
      },
      {
        type:'Blank',
        className: "form-control form-control-solid",
        
      },
      {
        type: "Checkbox",
        label:" I agree to receive SMS notifications for OTP verification, appointment reminders, result updates, and other important notifications from Snap HealthCare.",
        className: "form-check-input ml-0",
        name: "notification_consent",
        content:"",
        col: 'col-lg-12 col-md-12 col-12',
        isNotValidate:true
      },
    ];
  return (
    <div className={`formbold-form-step-1 ${isVisible ? 'active' : ''}`}>

      <div className="row">
        {inputFields &&
          inputFields.map((field, index) => (
            <div className={field.col} key={index}>
              <div className="form-group">
                {
                  field.type == 'Checkbox' ?
                    <>
                      <FormInputs
                        changeHandler={(value, e) =>
                          changeHandler(value, field.name, e)
                        }
                        value={formData?.[field.name]}
                        {...field}
                      />
                      <label className="form-check-label ml-4" htmlFor={field.name} style={{ display: 'inline-list-item' }}> {field.label} </label>
                      {field.content ?
                        <>
                          <br />
                          <div className='' style={{ display: 'flex' }}>
                            {field.content}
                          </div>
                        </>
                        : ''
                      }

                    </>
                    :
                    <>
                      <label className="text-sm text-truncate mb-0 opacity-75"> {field.label} </label>
                      <FormInputs
                        changeHandler={(value, e) =>
                          changeHandler(value, field.name, e)
                        }
                        value={formData?.[field.name]}
                        {...field}
                      />
                    </>
                }
                {isSet(field.isNotValidate) ? <></>  :  (isSet(validation[field.name], '') !== '' ? <span className="text-danger m-1">{isSet(validation[field.name], '')}</span> : '')}
              </div>
            </div>
          ))}
        {/* <ReCAPTCHA
          sitekey={process.env.REACT_CAPTCHA_KEY}
          onChange={(token)=>changeHandler(token, 'captcha', {})}
          badge="inline" 
          /> */}
      </div>


    </div>
  );

};


export default StepOne;