import React, { Component } from 'react';
import ClinicService from '../../services/ClinicService';
import TableGrid from '../../components/UI/TableGrid';
import AddClinic from './AddClinic';
import { ToastContext } from "../../context/ToaxtContext";
import FormInputs from '../../components/UI/FormInputs';
// import moment from 'moment';
import { formatPhoneNumberUS, formatZipCode, isSet, toQueryString } from '../../utils/commonUtils';
import Buttons from '../../components/UI/Buttons';
import WithRoutify from '../../hoc/WithRoutify';
// import { isGlobalAdmin } from '../../utils/aclUtils';
// import UserService from '../../services/UserService';
// import OrganizationService from '../../services/OrganizationService';
import withReduxState from '../../hoc/wReduxState';
import TableSkeleton from '../../hoc/TableSkeleton';
import WithRouter from '../../hoc/WithRouter';
import QRCodeGenerator from './QrCode';
import AddSalesRep from './AddSalesRep';
// import { OK } from '../../utils/constant';



class ClinicList extends Component {
  static contextType = ToastContext;
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      { label: '', key: 'isActive', type: 'checkBox', changeHandler: this.checkBoxchangeHandler },
      { label: 'Registration Link', key: 'clinic_link',type:''},
      { label: 'Clinic Name', key: 'name', type: '', inputType: 'TextInput',sortName:'name' },
      { label: 'City', key: 'city', inputType: 'TextInput' ,sortName:'city'},
      { label: 'State ', key: 'state', inputType: 'TextInput' ,sortName:'state'},
      { label: 'Zip +4 ', key: 'zip_plus_4', inputType: 'TextInput',sortName:'zip_plus_4'},
      { label: 'Cell Number', key: 'phone', inputType: 'TextInput',sortName:'phone' },
      { label: 'County', key: 'county', inputType: 'TextInput',sortName:'county'},
      { label: 'Action', key: 'action', type: 'Actions' },
      
      // Add more columns as needed
    ];
    this.state = {
      filters: {
        search_query: ''
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: 'name',
          sort_order: 'asc',
          search: {},
        }
      },
      isQrCodeModal:false,
      qrCodeDetails:{},
      activeEditId: null,
      // Your state variables go here
      isModelOpen: false,
      salesRepModelOpen:false,
      organization_id: '',
      organizationOption: [],
    }
  }

  componentDidMount() {

    this.fetchClinicList();
    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen })
  }
  salesRepModalToogleHandler=()=>{
    this.setState({ salesRepModelOpen:!this.state.salesRepModelOpen })
  }

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    })
  }
  toogleQrCodeModal = (qrCodeObj={})=>{
    this.setState({
      isQrCodeModal:!this.state.isQrCodeModal,
      qrCodeDetails:qrCodeObj
    })
  }
  render() {
    const { tableRecords, filters, isModelOpen ,qrCodeDetails,isQrCodeModal,activeEditId,salesRepModelOpen} = this.state; 
    
    return (
      <>
        {isQrCodeModal===true && 
          <QRCodeGenerator 
            qrCodeDetails={qrCodeDetails}
            modleToogleHandler={this.toogleQrCodeModal} />
        }
        {isModelOpen ? (
          <AddClinic
            modelTitle={`${this.state.activeEditId ? 'View and Update' : 'Add'} Clinic`}
            fetchClinicList={this.fetchClinicList}
            isOpen={this.state.isModelOpen}
            addFormHandler={this.addFormHandler}
            activeEditId={activeEditId}
          />
        ) : (
          <></>
        )}
        {
          salesRepModelOpen?
          <AddSalesRep 
            toogleHandler={this.salesRepModalToogleHandler} />

          :
          ''
        }
        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"clinic-create"}
          content={"Clinic"}
        >
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                <h6 class="text-lg f-500 mb-0"> Clinic List </h6>

                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div className="col-12 px-1 d-flex">
                      <div className="input-group input-group-joined input-group-solid">
                        
                        <FormInputs
                          placeholder="Clinic"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="btn-group pl-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="addAction">
                    <div className="">
                      <Buttons
                        clickHandler={() => { this.salesRepModalToogleHandler(); this.setState({ activeEditId: '' }) }}
                        className="btn btn-primary mr-2"
                        acl={"clinic-create"}
                        label={"Add Sales Rep"}
                      />
                      <Buttons
                        clickHandler={() => { this.addFormHandler(); this.setState({ activeEditId: '' }) }}
                        className="btn btn-primary "
                        acl={"clinic-create"}
                        label={"Add Clinic"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                hasPagination={true}
                fetchTableRecords={(filters) => this.fetchClinicList(filters)}
                {...tableRecords}
              />
            </div>
          </div>
        </TableSkeleton>
      </>
    );
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      page:1,
      search: filters,
    }
    await this.fetchClinicList(queryfilters);
  }
  clearFiltersHandler = () => {
    this.setState({
      filters: {
      }
    }, this.submitFiltersHandler);
  }
  fetchClinicList = async (query = {}) => {
    
    const { tableRecords } = this.state;
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });
    try {
      this.props.Loader(true);
      const clinicList = await ClinicService.getClinicList(null, queryString);
      console.log('clinicList',clinicList);
      let tableData = [];
      clinicList.data.data.forEach((clinic, clinicIndex) => {
        const hyperLink = { href: `${clinic.clinic_link}` } 
        tableData = [
          ...tableData,
          {
            clinicIndex: clinicIndex,
            isHeading: false,
            rowId: clinic.clinic_id,
            data: {
              isActive: false,
              name: clinic.name,
              clinic_link:{
                value: clinic.clinic_link,
                ...hyperLink
              },
              street: clinic.street,
              city: clinic.city,
              state: clinic.state,
              zip_plus_4:formatZipCode(clinic.zip_plus_4),
              phone: formatPhoneNumberUS(clinic.phone),
              county: clinic.county,
              created_at: clinic.created_at,
              updated_at: clinic.updated_at,
              // createdAt:usDateTimeString,//clinic.created_at,
              active: 'Yes',
              action: [
                {
                  className: "btn btn-datatable btn-icon btn-transparent-dark",
                  iconType: "QrCode",
                  label: "",
                  acl: "any",
                  title: "QR Code",
                  clickHandler:()=>this.toogleQrCodeModal({clinicName:clinic.name,link:clinic.clinic_link})
                  // href: `detail-view/${clinic.clinic_id}/detail`,
                },
                {
                  className: 'btn btn-icon btn-transparent-dark',
                  iconType: 'Search',
                  title: 'View',
                  type: 'GridEdit',
                  acl: 'clinic-edit',
                  clickHandler: (rowId, data) => this.editHandler(rowId, data),
                }, {
                  className: 'btn btn-icon btn-transparent-dark',
                  iconType: 'Remove',
                  acl: 'clinic-delete',
                  title: 'Delete',
                  isDelete: true,
                  clickHandler: () => this.deleteHandler(clinic.clinic_id),
                }]
            }
          }
        ]
      });
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            type: "default",
            data: tableData,
            status: 200,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: clinicList.data.total
          },
        },
      }));
      this.props.Loader();
    } catch (e) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200
          }
        }
      });
      this.props.Loader();
    }
  }
  //celete clinic handler
  deleteHandler = async (clinicId) => {
    const {Toast}=this.props;
    try {
      const data = await ClinicService.deleteClinic(clinicId);
      if(isSet(data)&&data.status=="ERROR" &&data.data){
        Toast.error(data.data);
      }else{
          Toast.success('Clinic Deleted Successfully');
          this.fetchClinicList();
      }
     
      
      //   }
    } catch (e) {

    }

  }
  editHandler = (rowId, data) => {
    this.setState({
      activeEditId: rowId,
    }, this.addFormHandler);
  }
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    })
  }
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click 
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}

export default WithRouter(withReduxState(WithRoutify(ClinicList)));