import React, { Component } from "react";
import { isSet,capitalizeFirstLetter,validateInputField, toQueryString } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import TableGrid from "../../components/UI/TableGrid";
import UserAdd from './UserAdd';
import RolesService from "../../services/RoleService";
import UserService from "../../services/UserService";
import WithRoutify from "../../hoc/WithRoutify";
import Buttons from "../../components/UI/Buttons";
import GroupEditForm from "./GroupEditForm";
import { SOMETHING_WENT_WRONG_ERROR_MESSAGE } from "../../utils/constant";
import TableSkeleton from "../../hoc/TableSkeleton";
import withReduxState from "../../hoc/wReduxState";
import ReactDOM from "react-dom";
import UserSearchFilter from "./UserSearchFilter";
class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      rolesForFilter:[],
      filters:{
        role_name:'',
        user_name:'',
        email:'',
        phone:'',
      },
      isAddFormModelOpen: false,
      isGroupEditFormModelOpen: false,
      tableRecords: {
        tableHeaders:[
          { label: '', key: 'isActive', type: 'checkBox',changeHandler:this.formListOnchangeHandler},
          { label: 'Role', key: 'role', inputType: 'SelectList',options:[]},
          { label: 'First Name', key: 'first_name',inputType:'TextInput',sortName:'first_name'},
          { label: 'Last Name', key: 'last_name',inputType:'TextInput',sortName:'last_name'},
          { label: 'DOB', key: 'dob', inputType:'Date',sortName:'d'},
          { label: 'Email', key: 'email',inputType:'TextInput',sortName:'email'},
          { label: 'Cell Number', key: 'phone',sortName:'phone_number'},
          { label: 'Active', key: 'active', inputType: 'Checkbox' },
          { label: 'Action', key: 'action', type: 'Actions'},
        ],
        tableRows: {
          data: [],
          status:1000,
        },
        pagination:{
          page:1,
          perPage:25,
          totalRecords:0,
          sort_by:'',
          sort_order:'asc',
          search:{},
        }
      },
      formDataInEdit:{},
      activeEditId:null,
      groupEditFormData:{
        first_name:'',
        last_name:'',
        dob:'',
        bulkEmailResend:false,
      },
      activeGroupEditData:[], 
      filterOpen: false,

    };
  }

  componentDidMount() {
    this.fetchUserList();
    this.fetchRoles();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.tableRecords!==this.state.tableRecords) {
      const updatedData=this.state.tableRecords.tableRows.data.filter((item) =>{
        return !item.hasOwnProperty("isHeading") && item.data.isActive === true;
        // if (!item.hasOwnProperty("isHeading")&&item.data.isActive===true) {
        //     return item;
        //   }
      });
      this.setState({activeGroupEditData:updatedData})
    }
  }
  render() {
    const {roles,tableRecords,isAddFormModelOpen,rolesForFilter,filters,activeEditId} = this.state;
    return (
      <>
        {isAddFormModelOpen ? (
          <UserAdd
            isOpen={this.state.isAddFormModelOpen}
            addFormHandler={this.addFormHandler}
            roles={roles}
            fetchUserList={this.fetchUserList}
            updateUserHandler={this.updateUserHandler}
            activeEditId={activeEditId}
            modelTitle={" User"}
          />
        ) : (
          <></>
        )}
        <GroupEditForm
          isOpen={this.state.isGroupEditFormModelOpen}
          onChangeHandler={this.groupEditChangeHandler}
          groupEditFormData={this.state.groupEditFormData}
          formHandler={this.groupEditFormHandler}
          submitHandler={this.groupEditSubmitHandler}
          modelTitle={"Group Edit"}
          activeData={this.state.activeGroupEditData}
        />
        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"user-create"}
          content={"Users"}
        >

        <div className="sh_cardBox">
          <div className="sh_cardHeader">
            <div className="form-group d-flex mb-0 justify-content-between align-items-center">
              <h6 class="text-lg f-500 mb-0"> User List </h6>
              {/* ... your form fields */}
                <div className="addAction d-flex">
                  <div className="form-group-fields row mr-2">
                    <Buttons
                      clickHandler={this.filterOnClick}
                      className="btn btn-outline-secondary mr-2"
                      acl={"any"}
                      label={"Search by "}
                      iconType={"Filter"}
                    ></Buttons>
                  </div>
                  <div className="input-group input-group-joined input-group-solid">
                        <FormInputs
                          placeholder="User"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>
                  <div className="btn-group pl-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                      </div>
                  <div className="btn-group ">
                    <Buttons
                      clickHandler={this.addFormHandler}
                      className="btn btn-primary"
                      acl={"user-create"}
                      label={"Add User  "}
                    />
                  </div>
                </div>
            </div>
          </div>

          <div className="sh_cardBody p-0">
            <TableGrid
              hasPagination={true}
              fetchTableRecords={(filters) => this.fetchUserList(filters)}
              {...tableRecords}
              gridEditProps={{
                formDataInEdit: this.state.formDataInEdit,
                activeEditId: null, //this.state.activeEditId,
                onChangeFormDataInEdit: this.onChangeFormDataInEdit,
              }}
            />
          </div>
        </div>
        </TableSkeleton>
        {this.state.filterOpen ? (
          <>
            {ReactDOM.createPortal(
              <UserSearchFilter
                onChangeHandler={this.onChangeFiltersHandler}
              filterData={this.state.filters}
                closeHandler={this.filterCloseHandler}
                submitHandler={this.submitFiltersHandler}
                rolesForFilter={rolesForFilter}
                fetchRoles={this.fetchRoles}
              />,
              document.getElementById("sh_mainportal_view") // Render the component into the portal container
            )}
          </>
        ) : (
          ""
        )}
      </>
    );
  }
  formListOnchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click 
    const updatedData = tableRows.data.map((item) => {
      if (item.data.roleName === rowId || item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });
  
    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
  
  editUserHandler = (rowId=null)=>{
    this.setState({
      activeEditId:rowId,
      isAddFormModelOpen:!this.state.isAddFormModelOpen
    }); 
  }
  updateUserHandler = async (userId,payload) => {
    
    const {Toast,Loader} = this.props;
    Loader(true);
    try {
      const data = await UserService.updateUser(userId, payload);
      if (data.code === 500) {
        Toast.error(isSet(data.data, 'Something went wrong..'));
      } else {
        Toast.success('User updated successfully');
        this.editUserHandler(null);
        Loader(false);
      }
    } catch (e) {
      // console.log('e', e);
      Loader(false);
    }
  
    this.fetchUserList();
  };
  editHandler = (rowId, data, roleName) => {
    //prevent rowId null
    if (rowId==null) {
       this.setState({ activeEditId: '',
       formDataInEdit:{}
       })
      return false;
    }
    // Find role_id by name
        const role_id = this.state.roles.find((role) => role.label === capitalizeFirstLetter(roleName.replace(/_/g, ' ')))?.value || '';
    // Update the form data with the role_id
        const updatedFormInData = {
          ...data,
          role: role_id,
        };
    // Set the state with the updated data
         this.setState({
          activeEditId:rowId,
          formDataInEdit:updatedFormInData
        }); 
      }
      
  onChangeFiltersHandler =(key,val)=>{
    const {filters} = this.state;
    this.setState({
      filters:{
        ...filters,
        [key]:val
      }
    })
  }
  onChangeFormDataInEdit = (key, val) => {
    // console.log('onChangeFormDataInEdit', key, val);
    this.setState((prevState) => ({
      formDataInEdit: {
        ...prevState.formDataInEdit,
        [key]: val,
        validation: {
          ...prevState.formDataInEdit.validation,
          [key]: '', // Set validation empty
        },
      },
    }));
  };
  
  updateHandler = async (userId) => {
    const { formDataInEdit } = this.state;
    const {Toast} = this.props;
    const { first_name, last_name, email, dob, active, role } = formDataInEdit;
    let payload = {
      assigned_role: role,
      first_name: first_name,
      last_name: last_name,
      email: email,
      dob: dob,
      active_status: active === true || isSet(active, '').toString().toLowerCase() === 'yes' ? true : false,
    };
  
    // Use an object to define the validation rules for each field
    const validationRules = {
      email: { type: 'email', isRequired: true, message: 'Invalid email' },
      dob: { type: 'dob', isRequired: false, message: 'Invalid DOB' },
      assigned_role: { type: 'text', isRequired: true, message: 'Role is required' },
      first_name: { type: 'text', isRequired: true, message: 'First name is required' },
      last_name: { type: 'text', isRequired: true, message: 'Last name is required' },
    };
  
    let validationErrors = {};
  
    // Validate each field based on the defined rules
    Object.keys(validationRules).forEach((field) => {
      const { type, isRequired, message } = validationRules[field];
      if (!validateInputField(payload[field], type, isRequired)) {
        validationErrors[field] = message;
      }
    });
  
    // Update state with validation errors
    this.setState((prevState) => ({
      formDataInEdit: {
        ...prevState.formDataInEdit,
        validation: validationErrors,
      },
    }));
    // console.log("validation error",validationErrors);
  
    // Return if there are validation errors
    if (Object.keys(validationErrors).length > 0) {
      return;
    }
  
    try {
      const data = await UserService.updateUser(userId, payload);
      if (data.code === 500) {
        Toast.error(isSet(data.data, 'Something went wrong..'));
      } else {
        Toast.success('User updated successfully');
        this.editHandler(null, {}, null);
      }
    } catch (e) {
      // console.log('e', e);
    }
  
    this.fetchUserList();
  };
  filterCloseHandler = (isClear=true) => {
    if (isClear) {
      this.setState({
        filters: {
          ...this.state.filters,
          'role_name': '',
          'first_name': '',
          'last_name': '',
          'email': '',
          'phone': '',   
        },
      },this.submitFiltersHandler);
    }
    else{
      this.setState({
        filterOpen: false,
      },()=>{
        if (document.body.classList.contains('filterOpened')) {
          document.body.classList.remove('filterOpened');
          // menuToggleElement.removeClass('active');
        } else {
          document.body.classList.add('filterOpened');
        }
      });
    }
    
  };
  filterOnClick = () => {
    this.setState({
      filterOpen: !this.state.filterOpen,
    },()=>{
      if (document.body.classList.contains('filterOpened')) {
        document.body.classList.remove('filterOpened');
        // menuToggleElement.removeClass('active');
      } else {
        document.body.classList.add('filterOpened');
      }
    });
  };
  submitFiltersHandler = ()=>{
    const {filters}  =this.state;
    const {tableRecords}=this.state
    // const {name,role,email,phone} = filters;
    const queryfilters = {
      ...tableRecords.pagination,
      page:1,
      search:filters
    }
    this.fetchUserList(queryfilters)
  }
  clearFiltersHandler = ()=>{
    this.setState({
      filters: {
        ...this.state.filters,
        search_query:'',    
      },
    },this.submitFiltersHandler);
  };
  fetchUserList = async (query={}) => {
    // console.log('query',JSON.stringify(query));
    const {Loader} = this.props;
    const {tableRecords} = this.state;
    const queryString = toQueryString({...tableRecords.pagination,...query});
    Loader(true);
    try {
      const users = await UserService.getUserList(queryString);
      // console.log(users);
      Loader(false);
      let tableData = [];
      for (const role in users.data) {
        tableData = [...tableData];
        let cells = [];
        // console.log('roless',role);
        users.data[isSet(role,'others')].forEach((user) => {
          let isReadonly = false;
          if (this.props.profile.id===user.id) {
            isReadonly=true; 
          }

          cells.push({
            rowId:user.id,
            data:{
              role:'',
              roleName:role,
              isActive: false,
              first_name: user.first_name,
              last_name: user.last_name,
              dob: user.dob,
              email: user.email,
              phone: isSet(user.phone_number,'-'),
              active: (Boolean(user.active_status)===true)?'Yes':'No',
              action: [{
                className:'btn btn-icon btn-transparent-dark',
                iconType:'Edit',
                acl:`${isReadonly===true?'':'user-edit'}`,
                clickHandler:()=>this.editUserHandler(user.id)
              },
              {
                className:'btn btn-icon btn-transparent-dark',
                iconType:'Remove',
                acl:`${isReadonly===true?'':'user-delete'}`,
                isDelete:true,
                clickHandler:()=>this.deleteHandler(user.id)
              }],
            }
            
          })
        });
        tableData = [
          ...tableData,
          { 
            isHeading: true, 
            rowId: role,
            data: 
                {   
                  isActive: false, 
                    'role': {
                      value:capitalizeFirstLetter(role.replace(/_/g, ' ')),
                      attributes:{colspan: (tableRecords.tableHeaders.length) 
                      }, 
                    
                    }
                }
          },
          ...cells
        ]
      }
      // console.log('tableData',tableData);
    this.setState((prevState) => ({
      tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
              type: "default",
              data: tableData,
              status:200,
          },
          pagination: {
              ...prevState.tableRecords.pagination,
              ...query,
              totalRecords:users.pagination.total_count
          },
      },
  }));
    } catch (error) {
      Loader(false);
      this.setState({
        tableRecords:{
          ...tableRecords,
          tableRows:{
            ...tableRecords.tableRows,
            status:200
          }
        }
      });
      console.error('Error fetching user list:', error);
    }
  };

  fetchRoles = async (val ,callback) => {
    const rolesResponse = await RolesService.getRolesList({},val);
    let roles = [{
      label:'-Roles-',
      value:''
    }];
    let rolesForFilter = [{
      label:'-Roles-',
      value:''
    }];
    rolesResponse.data.forEach((itm) => {
      let obj = { label: capitalizeFirstLetter(itm.name).replace(/_/g, ' '), value: itm.uuid };
      roles.push(obj);

      let objName = { label: itm.name, value: itm.name };
      rolesForFilter.push(objName);
    });
       this.setState((prevState) => ({
            roles,
            rolesForFilter,
            tableRecords: {
              ...prevState.tableRecords,
              //update roles in rolesList-option header
              tableHeaders: prevState.tableRecords.tableHeaders.map((header) =>
                header.key === 'role' ? { ...header, options: roles } : header
              ),
            },
          })); 
          if(isSet(callback)){
            callback(rolesForFilter); 
          }
    
        }

  addFormHandler = () => {
    this.setState({ isAddFormModelOpen: !this.state.isAddFormModelOpen,activeEditId:null });
  };
  deleteHandler = async (userId)=>{
    const {Toast} = this.props;
      try{
          await UserService.deleteUser(userId);
          Toast.success('User Deleted Successfully');
          this.fetchUserList();
      }catch(e){
        this.fetchUserList();
      }
    
  }
  groupEditFormHandler=()=>{
    this.setState({isGroupEditFormModelOpen:!this.state.isGroupEditFormModelOpen});
  }
  groupEditChangeHandler=(value,event)=>{
    // console.log("groupEditChangeHandler",value,event);
    let fieldName=event.target?.name;
    let updateFormData= { ...this.state.groupEditFormData };
    fieldName=fieldName===undefined?'dob':fieldName
    updateFormData[fieldName] =value;
    this.setState({ groupEditFormData: updateFormData });
  }
  groupEditSubmitHandler=async()=>{
    const {Toast} = this.props;
    let {groupEditFormData,activeGroupEditData}=this.state;
    // console.log("changed Data",activeGroupEditData);
    //Submit logic goes here ===>
    let userIds=[];
    let body={};
    activeGroupEditData.forEach(item=>{
      userIds.push(item.rowId);
    })
    body.first_name=groupEditFormData.first_name;
    body.last_name=groupEditFormData.last_name;
    body.dob=groupEditFormData.dob;
    body.user_ids=userIds;
// console.log("body",body);
    // let body={"user_ids":user_ids,"data":data}
    let bulkUpdateResponse=await UserService.updateBulkUser(body);
    // console.log("bulkUpdateResponse",bulkUpdateResponse);
    if (bulkUpdateResponse?.code===200) {
      Toast.success(bulkUpdateResponse.data)
    }
    else{
      Toast.error(SOMETHING_WENT_WRONG_ERROR_MESSAGE);
    }
    this.groupEditFormHandler();

  }   
}
export default withReduxState(WithRoutify(UserList));
