import { OK, UNPROCESSABLE_CONTENT } from '../utils/constant';
import http from './http';


const InsuranceService = {

  saveInsurance: async (data) => {
    try {
      const response = await http.POST(`insurance-companies`, data);
      return response;
    } catch (error) {
      console.error('Error fetching Insurance:', error);
      if (error.status && error.status === UNPROCESSABLE_CONTENT) {
        return error.data
      }
      // throw error;
    }
  },
  //get Employee
  getInsurance: async (query = {}) => {
    try {
      const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`insurance-companies?${queryString}`);
      if (response.code === OK) {
        return response;
      }
      else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching Insurance:', error);
    }
  },
  //get Insurance By Id
  getInsuranceById: async (id) => {
    try {
      const response = await http.GET(`insurance-companies/${id}`);
      if (response.code === OK) {
        return response;
      }
      else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching Insurance:', error);
    }
  },
  //update Employee
  updateInsurance: async (id, data) => {
    try {
      const response = await http.PUT(`insurance-companies/${id}`, data);
      console.log('response', response);
      return response;
    } catch (error) {
      if (error.status && error.status === UNPROCESSABLE_CONTENT) {
        return error.data
      }
      console.error('Error fetching Insurace:', error);
    }
  },
  //delete Employee
  deleteInsurance: async (Id) => {
    try {
      const response = await http.DELETE(`insurance-companies/${Id}`);
      if (response.status && response.data) {
        const resData = response.data;
        if ((resData.code === 200 || resData.code === 201) && resData.data) {
          return resData.data;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching Employee:', error);
    }
  },
};
export default InsuranceService;