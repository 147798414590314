import React, { useEffect, useRef, useState } from "react";
import PhysicalTab from "./PhysicalTab";
import Buttons from "../../../../components/UI/Buttons";
import WithRouter from "../../../../hoc/WithRouter";
import { isSet } from "../../../../utils/commonUtils";
import UploadPhysical from "./UploadPhysical";
import SplitedTab from "./SplitedTab";
import SpecialInstructionTab from "./SpecialInstructionTab";
import LaboratoryTab from "./LaboratoryTab";
import VaccinesTab from "./VaccinesTab";
import SwipeableViews from 'react-swipeable-views';
import DropDownService from "../../../../services/DropDownService";
import FullScreenMode from "../../../../components/UI/FullScreenMode";
import { isLaboratoryVisitCategory } from "../../../../utils/visitUtils";

const RightContent = ({ 
  params, 
  visitDetails, 
  fetchVisitDetails, 
  employeeDetails, 
  companyDetails, 
  lookupsOptions, 
  stateList 
}) => {
  const scrollerRef = useRef(null);
  const [inactiveLeft, setInactiveLeft] = useState(true);
  const [selectedTab, setSelectedTab] = useState('');
  const [tabHeaders,setTabHeaders] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);
  const [physicalOption,setPhysicalOption]=useState([]);
  const [visitcategory,setVisitCategory]=useState('');

  useEffect(()=>{
   if(Object.entries(visitDetails).length>0 && visitDetails.visit_category!==visitcategory){
    setVisitCategory(visitDetails.visit_category); //to prevent un wanted render 
    const tabs = isLaboratoryVisitCategory(visitDetails.visit_category) ?
    [
      { label: "Laboratory", key: "laboratory", component: LaboratoryTab },
      { label: "Vaccines", key: "vaccines", component: VaccinesTab },
      { label: "Special Instructions", key: "", component: SpecialInstructionTab },
    ] :
    [
      { label: "Physical", key: "", component: PhysicalTab },
      { label: "Drugs/Alcohol", key: "drugsOrAlcohol", component: SplitedTab },
      { label: "Laboratory", key: "laboratory", component: SplitedTab },
      { label: "Vaccines", key: "vaccines", component: SplitedTab },
      { label: "Tests/Screenings", key: "testsOrScreenings", component: SplitedTab },
      { label: "Radiology", key: "radiology", component: SplitedTab },
      { label: "Provider Exam", key: "providerExam", component: SplitedTab },
      { label: "Special Instructions", key: "", component: SpecialInstructionTab },
      { label: "Documents", key: "", component: UploadPhysical }
    ];
    if(isLaboratoryVisitCategory(visitDetails.visit_category)){
      setSelectedTab('Laboratory');
    }else{
      setSelectedTab('Physical');
    }
    setTabHeaders(tabs);
   }
   
  },[visitDetails,visitcategory]);
  useEffect(()=>{
    fetchPhysicalOptions();
  },[]);
  const fetchPhysicalOptions=async()=>{
    let activitiesResponse=await DropDownService.getActivityDropdownList(null);
    // console.log('activitiesResponse',activitiesResponse);
      let options=[{label:'-Select-',value:''}];
      activitiesResponse.forEach(val => {
        options.push({label:val.activity_name,value:val.actitivity_id});
      });
    setPhysicalOption(options);
  }

  useEffect(() => {
    const checkScroller = () => {
      setInactiveLeft(scrollerRef.current.scrollLeft === 0);
    };

    const handleScroll = () => {
      setInactiveLeft(scrollerRef.current.scrollLeft === 0);
    };

    checkScroller();
    if (scrollerRef.current) {
      scrollerRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollerRef.current) {
        scrollerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleRightClick = () => {
    scrollerRef.current.scrollBy({
      left: 200,
      behavior: "smooth"
    });
  };

  const handleLeftClick = () => {
    scrollerRef.current.scrollBy({
      left: -200,
      behavior: "smooth"
    });
  };

  const handleTabClick = (label, index) => {
    setSelectedTab(label);
    setSelectedValue(index);
  };

  const handleChangeIndex = (index) => {
    setSelectedValue(index);
    setSelectedTab(tabHeaders[index].label);
  };

  // const SelectedComponent = tabHeaders[selectedValue]?.component;
  const SelectedComponent = tabHeaders.find((header) => header&&header.label === selectedTab);


  return (
    <div className="col-lg-9 col-12">
      <div className="sh_cardBox h-auto sh_visit">
        <div className="sh_cardHeader" style={{ position: "relative" , padding: "6px"}}>
          <div className="form-group d-flex mb-0 justify-content-between align-items-center">
            <h6 className="text-lg f-500 mb-0">
              <span className="badge back_btn  mr-2 ">
                <Buttons
                  label={"Back"}
                  iconType={"Back"}
                  acl={"any"}
                  href={
                    isSet(params.patientId, null) !== null
                      ? `/employee/visit-history/${params.patientId}/${params.patientDetails}`
                      : (isSet(params.backTo, null)==='appointments')? "/appointments":"/visits"
                  }
                />
              </span>
            </h6>
            <TabNavigation
              handleLeftClick={handleLeftClick}
              handleRightClick={handleRightClick}
              tabHeaders={tabHeaders}
              selectedTab={selectedTab}
              handleTabClick={handleTabClick}
              scrollerRef={scrollerRef}
              inactiveLeft={inactiveLeft}
              selectedValue={selectedValue}
            />
            <FullScreenMode />
          </div>
        </div>
        <div className="sh_cardBody  statusBadge"style={{ position: "relative" }}>
          {/* {
            selectedComponent ? (
              <selectedComponent.component
                visitDetails={visitDetails}
                fetchVisitDetails={fetchVisitDetails}
                employeeDetails={employeeDetails}
                companyDetails={companyDetails}
                lookupsOptions={lookupsOptions}
                stateList={stateList}
                tabName={selectedTab}
                tabId={selectedComponent.key}
              />
            ) : null
          } */}
          {/* <ActiveComponent /> */}
          <SwipeableViews
            axis={'x'}
            // enableMouseEvents
            index={selectedValue}
            onChangeIndex={handleChangeIndex}
            springConfig={{
              duration: '0.6s', // Duration of the swipe animation
              easeFunction: 'ease-out', // Easing function for the animation
              delay: '0s', // Delay before starting the animation
            }}
          >
            {tabHeaders.map((header, index) => (
              <div
                key={index}
                role="tabpanel"
                title={header.label}
                hidden={selectedValue !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
              >
                {
            SelectedComponent ? (
              <SelectedComponent.component
                visitDetails={visitDetails}
                fetchVisitDetails={fetchVisitDetails}
                employeeDetails={employeeDetails}
                companyDetails={companyDetails}
                lookupsOptions={lookupsOptions}
                stateList={stateList}
                tabName={selectedTab}
                tabId={SelectedComponent.key}
                physicalOption={physicalOption}
              />
            ) : null
          }
              </div>
            ))}
          </SwipeableViews>
            
        </div>
      </div>
    </div>
  );
};

const TabNavigation = ({
  handleLeftClick,
  handleRightClick,
  tabHeaders,
  selectedTab,
  handleTabClick,
  scrollerRef,
  inactiveLeft,
}) => {
  return (
    <div className="tabs-wrapper">
      <span
        className={`left scroller-btn ${inactiveLeft ? "inactive" : ""}`}
        onClick={handleLeftClick}
      >
        <i className="fa fa-chevron-left" aria-hidden="true"></i>
      </span>
      <ul className="nav nav-borders nav-tabs border-0" ref={scrollerRef}>
        {tabHeaders.map((header, headerInd) => header&&(
          <li key={headerInd}>
            <a
              data-bs-toggle="tab"
              className={`nav-link ${selectedTab === header.label ? "active" : ""}`}
              onClick={() => {
                handleTabClick(header.label,headerInd);
              }}
            >
              {header.label}
            </a>
          </li>
        ))}
      </ul>
      <span className="right scroller-btn active" onClick={handleRightClick}>
        <i className="fa fa-chevron-right" aria-hidden="true"></i>
      </span>
    </div>
  );
};

export default WithRouter(RightContent);
