import React, { Component } from 'react';
import FormInputs from '../../components/UI/FormInputs';
import Buttons from '../../components/UI/Buttons';
import TableGrid from '../../components/UI/TableGrid';
import WithRoutify from '../../hoc/WithRoutify';
import LookupService from '../../services/LookupService';
import CompanyService from '../../services/CompanyService';
import { OK } from '../../utils/constant';
import AddLookup from './AddLookup';
import { convertUnderScoreStrings, isObjectEmpty } from '../../utils/commonUtils';

class LookupList extends Component{
        constructor(props) {
          super(props);
          // Initialize state or bind methods if needed
          const tableHeaders =  [
              { label: '',key:'isActive',type:'checkBox',changeHandler:this.checkBoxchangeHandler},
              { label: 'key',key:'key',inputType:'SelectList',options:[]},
              { label: 'Value',key:'value',inputType:'TextInput',type:'Actions'},
              // Add more columns as needed
            ];
          this.state = {
            filters:{
            },
              tableRecords:{
                  tableHeaders:tableHeaders,
                  tableRows:{
                      data:[]
                  },
                  pagination:{
                    page:1,
                    perPage:25,
                    totalRecords:0,
                    sort_by:'key',
                    sort_order:'asc',
                  }
              },
              formDataInEdit:{},
              activeEditId:null,
            // Your state variables go here
            isModelOpen:false,
            companyList:[],
            lookupModelOpen:false,
            activeLookup:'',
        }
      }
      
        componentDidMount() {
          
          this.fetchLookupList();
          this.fetchCompanyList();
          
          // Code to run after the component has mounted
        }
      
        componentDidUpdate(prevProps, prevState) {
          // Code to run after the component has updated
        }
      
        componentWillUnmount() {
          // Code to run before the component is unmounted
        }
      
        handleEvent = () => {
          // Handler for events (e.g., button click)
        };
        addFormHandler=()=>{
          this.setState({lookupModelOpen:!this.state.lookupModelOpen})
        }
      
        onChangeFiltersHandler =(key,val)=>{
          const {filters} = this.state;
          this.setState({
            filters:{
              ...filters,
              [key]:val
            }
          })
        }
        render() {
          const {tableRecords,filters,activeLookup,lookupModelOpen} = this.state;
          return (
            <>
              {lookupModelOpen ? (
                <AddLookup
                  modelTitle={"Add Lookup Value"}
                  isOpen={lookupModelOpen}
                  activeLookup={activeLookup}
                  s
                  fetchLookupList={this.fetchLookupList}
                  addFormHandler={this.addFormHandler}
                />
              ) : (
                <></>
              )}

              <div className="sh_cardBox">
                <div className="sh_cardHeader">
                  <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                    <h6 class="text-lg f-500 mb-0"> Company Lookup List </h6>
                    <div className="form-group-fields row">
                      <div className="col-12 d-flex">
                        <div class="input-group input-group-joined input-group-solid">
                          <FormInputs
                            placeholder="Lookup"
                            value={filters["search_query"]}
                            changeHandler={(val) =>
                              this.onChangeFiltersHandler("search_query", val)
                            }
                            className="form-control"
                          />
                        </div>
                        <div class="btn-group pl-2">
                          <button
                            className="btn btn-primary "
                            onClick={() => this.submitFiltersHandler()}
                          >
                            Search
                          </button>
                          <button
                            className="btn btn-outline-secondary"
                            onClick={() => this.clearFiltersHandler()}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className="addAction">
                              <div className="btn-group">
                                <Buttons
                                  clickHandler={this.addFormHandler}
                                  className="btn btn-primary" 
                                  acl={'lab-create'}
                                  label={'Add'}
                                />
                                <button
                                  className="btn btn-outline-secondary dropdown no-arrow"
                                  data-bs-toggle="dropdown"
                                >
                                  <span className="dropdown-toggle">
                                    <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                  </span>
                                  <div
                                    className="dropdown-menu dropdown-menu-right shadow"
                                    aria-labelledby="userDropdown"
                                  >
                                    <a className="dropdown-item" href="#">
                                      <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                      Group Edit
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                      Grid Edit
                                    </a>
                                  </div>
                                </button>
                              </div>
                            </div> */}
                  </div>
                </div>

                <div className="sh_cardBody p-0">
                  <TableGrid
                    hasPagination={true}
                    fetchTableRecords={(filters) =>
                      this.fetchLookupList(filters)
                    }
                    {...tableRecords}
                    gridEditProps={{
                      formDataInEdit: this.state.formDataInEdit,
                      activeEditId: this.state.activeEditId,
                      onChangeFormDataInEdit: this.onChangeFormDataInEdit,
                    }}
                  />
                </div>
              </div>
            </>
          );
        }
        submitFiltersHandler = ()=>{
          const {filters,tableRecords}  =this.state;
          const queryfilters = {
           ...filters,...tableRecords.pagination
          }
          this.fetchLookupList(queryfilters)
        }
        clearFiltersHandler = async ()=>{
          await this.setState({
            filters:{
            }
          });
          this.submitFiltersHandler();
        }
        fetchCompanyList=async()=>{
            let comapanyList=await CompanyService.getCompanies(null);
            let companyListOptions=[{label:'-Select Company',value:''}];
            comapanyList&&comapanyList.data.data.forEach((company,companyInd)=>{
                companyListOptions.push({label:company.name,value:company.company_uuid });
            });
            this.setState({companyList:companyListOptions});
        }
        fetchLookupList =async (query={})=>{
          if (isObjectEmpty(query)) {
            query={...this.state.tableRecords.pagination}
          }
            this.props.Loader(true);
            LookupService.getLookup(query)
            .then((res) => {
              let responseData=res.data;
              let tableData = [];
              console.log(responseData);
              responseData.data.map((Lookup, LookupIndex) => {
                tableData = [
                  ...tableData,
                  {
                    LookupIndex: LookupIndex,
                    isHeading: false,
                    rowId:LookupIndex,// convertUnderScoreStrings(Lookup),
                    data: {
                      isActive: false,
                      key:convertUnderScoreStrings(Lookup),
                      value: 
                      [
                        {
                          className:"text-sm",
                          acl: "lookup-edit",
                          label:`${Lookup} value`,
                          clickHandler: (rowId, data) =>
                            this.detailViewLookup(Lookup),
                          },
                      ],
                      active: "Yes",
                      action: [
                        {
                          className:
                            "btn btn-icon btn-transparent-dark",
                          iconType: "Edit",
                          title: "Edit",
                          type: "GridEdit",
                          acl: "lookup-edit",
                          clickHandler: (rowId, data) =>
                            this.editHandler(rowId, data,Lookup.Lookup_id),
                          updateHandler: () =>
                            this.updateHandler(Lookup.Lookup_id),
                          onChangeFormDataInEdit: (key, val) =>
                            this.onChangeFormDataInEdit(key, val),
                        },
                        {
                          className:
                            "btn btn-icon btn-transparent-dark",
                          iconType: "Remove",
                          acl: "lookup-delete",
                          title: "Delete",
                          isDelete:true,
                          clickHandler: () =>
                            this.deleteHandler(Lookup.Lookup_id),
                        }, 
                      ],
                    },
                  },
                ];
              });
              const updatePagination = (prevStatePagination, newPagination) => ({
                page: newPagination.page || prevStatePagination.page,
                perPage: newPagination.perPage || prevStatePagination.perPage,
                totalRecords: responseData.pagination.totalCount, 
                sort_by: newPagination.sort_by || prevStatePagination.sort_by,
                sort_order: newPagination.sort_order || prevStatePagination.sort_order,
            });
              this.setState((prevState) => ({
                tableRecords: {
                    ...prevState.tableRecords,
                    tableHeaders: prevState.tableRecords.tableHeaders,
                    tableRows: {
                        type: "default",
                        data: tableData,
                    },
                    pagination: {
                        ...prevState.tableRecords.pagination,
                        ...updatePagination(prevState.tableRecords.pagination, query),
                    },
                },
            }));
            })
            .catch((err) => {
              // console.log("err===>", err);
            })
            .finally(() => {
              this.props.Loader(false);
            });
        }
        deleteHandler = async (LookupId)=>{
            LookupService.deleteLookup(LookupId)
            .then((res) => {
              // console.log("res==>", res);
              this.fetchLookupList();
              this.props.Toast.success('Lookup Deleted Succesfully');
            })
            .catch((err) => {
              //
            })
            .finally(() => {
              //
            });
        }
        editHandler = (rowId,data,company_id)=>{
          const updatedTableHeaders = this.state.tableRecords.tableHeaders.map(header => {
            if (header.key === "company_id") {
              header.options = this.state.companyList.map(item => ({ value: item.value, label: item.label }));
              data.company_id=company_id;
            }
            return header;
          });
          this.setState({
            activeEditId:rowId,
            formDataInEdit:data
          });
          
        }
        onChangeFiltersHandler =(key,val)=>{
          const {filters} = this.state;
          this.setState({
            filters:{
              ...filters,
              [key]:val
            }
          })
        }
        onChangeFormDataInEdit = (key,val)=>{
          this.setState({
            formDataInEdit:{
              ...this.state.formDataInEdit,
              [key]:val
            }
          })
        }
        updateHandler = async (LookupId)=>{
          let {formDataInEdit}=this.state;
          let payload={
            'Lookup_name':formDataInEdit.Lookup_name,
            'company_id':formDataInEdit.company_id
          }
          let response=await LookupService.updateLookup(LookupId,payload);
          if (response.status==OK) {
            this.editHandler(null, {}, null);
            this.fetchLookupList();
            this.props.Toast.success("Lookup updated Successfully");
          }
        }
        detailViewLookup=(Lookup)=>{
          this.setState({activeLookup:Lookup},
            this.addLookupFormHandler()
            );
          
        }
        addLookupFormHandler=()=>{
          this.setState({lookupModelOpen:!this.state.lookupModelOpen});
        }
        checkBoxchangeHandler = (value, {e}) => {
          const rowId = e.target.id;
          const { tableRows } = this.state.tableRecords;
          //update data based on check box click 
          const updatedData = tableRows.data.map((item) => {
            if (item.rowId == rowId) {
              item.data.isActive = value;
            }
            return item;
          });
          this.setState((prevState) => ({
            tableRecords: {
              ...prevState.tableRecords,
              tableRows: {
                data: updatedData,
              },
            },
          }));
        };
      }
export default WithRoutify(LookupList);