import React from "react";
import { Button, Modal } from 'react-bootstrap';
import WithRoutify from "../../../hoc/WithRoutify";
const ErrorLogModal = (props) => {
    const { addFormHandler, errorLog } = props
    const { error_log } = errorLog;
    const{failed_rows}=errorLog;
    const parse_failed_row=JSON.parse(failed_rows)
    console.log('failedRows',parse_failed_row);
    const parsedLog = JSON.parse(error_log);
    console.log('parsedLog',parsedLog);
    return (
        <>
            <Modal
                show={true}
                onHide={addFormHandler}
                // size="xl"
                dialogClassName="hResponsive "
                contentClassName="h-100"
                className=""
                backdrop="static"
                keyboard={false}
            >
                <div className="sh_modalBox">
                    <Modal.Header closeButton>
                        <Modal.Title style={{ margin: '0 auto' }}>
                            <h6 className="text-lg mb-0 font-weight-bold">{`Error Log`}</h6>
                        </Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        {
                            parsedLog.length>0?
                            parsedLog.map((item) => {
                                return (
                                    <>
                                        <p>{item}</p><br />
                                    </>
                                )
                            })
                            :
                            'No Error Log Found'
                        }
                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-center">
                        <Button
                            variant="primary"
                            className=" "
                            onClick={() => addFormHandler()}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
};
export default WithRoutify(ErrorLogModal);