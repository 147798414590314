import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { isObject,isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import InsuranceService from "../../services/InsuranceService";
import { CREATED, OK } from "../../utils/constant";
import WithRoutify from "../../hoc/WithRoutify";

const AddInsurance = (props) => {
  const { isOpen, addFormHandler,modelTitle,activeEditId,Toast,Loader,fetchInsuranceList} = props;
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const inputFields = getInputFields();
  useEffect(() => {
    if (activeEditId) {
      InsuranceService.getInsuranceById(activeEditId)
        .then((res) => {
          setFormData(res.data);
        })
        .catch();
    }
  }, []);

  const changeHandler = (val, {e}) => {
    let fieldName = e.target.name;
    setFormData({
      ...formData,
      [fieldName]: val,
    });
    setValidation({...validation,[fieldName]:''})
  };
  const formSubmitHandler=async()=>{
    let updateValidation = { ...validation };
    let response;
    const payload={
      insurance_name:formData.insurance_name,
      is_active:formData.is_active,
    }
    if (activeEditId) {
      response = await InsuranceService.updateInsurance(activeEditId, payload);
    }
    else{
      response = await InsuranceService.saveInsurance(payload)
    }
    Loader(true);
    if (isSet(response,'')&&response.status && response.status == "ERROR") {
      console.log(response.data);
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(","),
          };
        }
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    }
    else{
      fetchInsuranceList();
      addFormHandler();
      let message=`Insurance ${activeEditId?'Updated':'Added'} Successfully`;
      Toast.success(message);
    }
    Loader(false);
  }
  return (
    <>
      <Modal
        show={isOpen}
        onHide={addFormHandler}
        dialogClassName="hResponsive"
        contentClassName="h-100"
        keyboard={false}
        backdrop="static"
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">{modelTitle}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {inputFields &&
                inputFields.map((field, index) =>field&& (
                  <div className={`col-12`}key={index}>
                    <div className={`form-group ${isSet(field.isLabelAppend, false) === true ? 'form-check form-check-solid pl-0' : ''}`}>
                      {
                        field.isLabelAppend == true ? // render based on condition checkbox
                          <>
                            <FormInputs {...field} changeHandler={changeHandler} />
                            <label className="text-gray-900 text-md opacity-75 pl-2">
                              {field.label}
                            </label>
                          </>
                          :
                          <>
                            <label className="text-gray-900 text-md opacity-75 pr-2">
                              {field.label}
                            </label>
                            <FormInputs {...field} changeHandler={changeHandler} />
                          </>
                      }
                      {isSet(validation[field.name], "") !== "" ? (
                        <span className="text-danger m-1">
                          {isSet(validation[field.name], "")}
                        </span>
                        ) : ("")}
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="primary"
              className=" "
              onClick={() => formSubmitHandler()}
            >
            {activeEditId?"Update":"Save"}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  function getInputFields() {
    const inputFields = [
      {
        type: "TextInput",
        label: "Insurance",
        placeholder: "Insurance Name",
        className: "form-control form-control-solid",
        name: "insurance_name",
        value: formData.insurance_name,
        required: true,
      },
      activeEditId?{
        type: "Checkbox",
        isLabelAppend: true,
        label: "Active",
        className: "form-check-input",
        placeholder: "",
        className: "",
        id: 'active',
        name: "is_active",
        value: formData.is_active,
      }
      :'',
    ];
    return inputFields
  }
};
export default WithRoutify(AddInsurance);