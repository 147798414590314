import React, { Component } from 'react';
import LabsService from '../../services/LabService';
import TableGrid from '../../components/UI/TableGrid';
import AddLabs from './AddLab';
import { ToastContext } from "../../context/ToaxtContext";
import FormInputs from '../../components/UI/FormInputs';
import moment from 'moment';
import { convertUnderScoreStrings, isObject, toQueryString } from '../../utils/commonUtils';
import Buttons from '../../components/UI/Buttons';
// import UserService from '../../services/UserService';
import { isGlobalAdmin } from '../../utils/aclUtils';
import OrganizationService from '../../services/OrganizationService';
import WithRoutify from '../../hoc/WithRoutify';
import { OK } from '../../utils/constant';
import withReduxState from '../../hoc/wReduxState';
import TableSkeleton from '../../hoc/TableSkeleton';
import AddLabOrder from './AddLabOrder';


class LabsList extends Component {
  static contextType = ToastContext;
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      { label: '', key: 'isActive', type: 'checkBox', changeHandler: this.checkBoxchangeHandler },
      { label: 'Labs', key: 'name', type: '', inputType: 'TextInput' },
      { label: 'Description', key: 'description', inputType: 'TextArea' },
      { label: 'Lab Order', key: 'labOrder', type:'Actions',},
      { label: 'Action', key: 'action', type: 'Actions' },
      // Add more columns as needed
    ];
    this.state = {
      filters: {
        search_query: ''
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: [],
          status: 1000,
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: 'name',
          sort_order: 'asc',
          search: {},
        }
      },
      formDataInEdit: {},
      activeEditId: null,
      // Your state variables go here
      isModelOpen: false,
      labOrderModelOpen:false,
      activeLabOrderData:null,
    }
  }

  componentDidMount() {

    this.fetchLabsList();
    this.fetchOrganization();


    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen })
  }

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    })
  }
  render() {
    const { tableRecords, filters, isModelOpen, activeEditId,labOrderModelOpen,activeLabOrderData} = this.state;
    return (
      <>
        {isModelOpen ? (
          <AddLabs
            activeEditId={activeEditId}
            modelTitle={`${this.state.activeEditId ? 'View and Update' : 'Add'} Lab`}
            isOpen={this.state.isModelOpen}
            fetchLabsList={this.fetchLabsList}
            addFormHandler={this.addFormHandler}
            organizationOption={this.state.organizationOption}
          />
        ) : (
          <></>
        )}
        {
          labOrderModelOpen?
          <AddLabOrder
          labData={activeLabOrderData}
          isOpen={labOrderModelOpen}
          addFormHandler={this.labOrderModelHandler}
          />
          :
          ''
        }
        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"lab-create"}
          content={"Labs"}
        >
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                <h6 class="text-xl f-500 mb-0"> Lab List </h6>
                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div className="col-12 px-1 d-flex">
                      <div className="input-group input-group-joined input-group-solid">
                        <FormInputs
                          placeholder="Labs"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="btn-group pl-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="addAction">
                    <div className="btn-group">
                      <Buttons
                        clickHandler={() => { this.addFormHandler(); this.setState({ activeEditId: '' }) }}
                        className="btn btn-primary"
                        acl={"lab-create"}
                        label={"Add"}
                      />
                      <button
                        className="btn btn-outline-secondary dropdown no-arrow"
                        data-bs-toggle="dropdown"
                      >
                        <span className="dropdown-toggle">
                          <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </span>
                        <div
                          className="dropdown-menu dropdown-menu-right shadow"
                          aria-labelledby="userDropdown"
                        >
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Group Edit
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Grid Edit
                          </a>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                hasPagination={true}
                fetchTableRecords={(filters) => this.fetchLabsList(filters)}
                {...tableRecords}
              />
            </div>
          </div>
        </TableSkeleton>
      </>
    );
  }
  fetchOrganization = async () => {
    if (this.props.profile.is_multiple_organization === true) {
      let org = await OrganizationService.getOrganization();
      let orgOpt = [{ label: "-Select Organization-", value: '' }];
      org.data.datas.forEach(itm => {
        orgOpt.push({ label: convertUnderScoreStrings(itm.name), value: itm.organization_uuid });
      });
      this.setState({ organizationOption: orgOpt });
    }
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      page:1,
      search: filters,
    }
    this.fetchLabsList(queryfilters);
  }
  clearFiltersHandler = () => {
    this.setState({
      filters: {}
    }, this.submitFiltersHandler);
  }
  fetchLabsList = async (query = {}) => {
    const { tableRecords } = this.state;
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });
    try {
      this.props.Loader(true);
      const labsList = await LabsService.getLabsList(null, queryString);
      console.log('lab list',labsList);
      let tableData = [];
      labsList.data.data.forEach((labs, labsIndex) => {
        const usDateTimeString = moment.utc(labs.created_at).format('MMMM D, YYYY h:mm:ss A');


        tableData = [
          ...tableData,
          {
            labsIndex: labsIndex,
            isHeading: false,
            rowId: labs.lab_id,
            data: {
              isActive: false,
              description: labs.description,
              name: labs.name,
              createdAt: usDateTimeString,//labs.created_at,
              active: 'Yes',
              labOrder:[
                {
                  className: 'text-sm',
                  // iconType:"Labs",
                  label:"Lab Order",
                  acl: 'any',
                  title: 'Lab Order',
                  clickHandler: () => {this.labOrderModelHandler(labs)}
                },
              ],
              action: [{
                className: 'btn btn-icon btn-transparent-dark',
                iconType: 'Search',
                title: 'View',
                type: 'GridEdit',
                acl: 'lab-edit',
                clickHandler: (rowId, data) => this.editHandler(rowId, data),
                updateHandler: () => this.updateHandler(labs.lab_id),
                onChangeFormDataInEdit: (key, val) => this.onChangeFormDataInEdit(key, val)
              }, {
                className: 'btn btn-icon btn-transparent-dark',
                iconType: 'Remove',
                acl: 'lab-delete',
                title: 'Delete',
                isDelete: true,
                clickHandler: () => this.deleteHandler(labs.lab_id)
              }]
            }
          }
        ]
      });
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            status: 200,
            type: "default",
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: labsList.data.total
          },
        },
      }));
      this.props.Loader();
    } catch (e) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200
          }
        }
      });
      this.props.Loader();
    }
  }
  fetchOrganization = async () => {
    // let userProfile=await UserService.getProfile();
    // console.log("userProfile",userProfile);
    if (isGlobalAdmin(this.props.profile.role)) {
      let org = await OrganizationService.getOrganization();
      let orgOpt = [{ label: "-Select Organization-", value: '' }];
      org.data.data.forEach(itm => {
        orgOpt.push({ label: convertUnderScoreStrings(itm.name), value: itm.organization_uuid });
      });
      this.setState({ organizationOption: orgOpt });
    }
  }
  deleteHandler = async (labId) => {
    const { Loader, Toast } = this.props;
    try {

      await LabsService.deleteLab(labId)
        .then((response) => {
          Toast.success("Lab Deleted Successfully");
        })
        .catch(err => {

        })
        .finally(() => {
          this.fetchLabsList();
        });
    } catch (e) {

    }

  }
  editHandler = (rowId, data) => {

    this.setState({
      activeEditId: rowId,
    }, this.addFormHandler());
  }
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    })
  }
  onChangeFormDataInEdit = (key, val) => {
    this.setState((prevState) => ({
      formDataInEdit: {
        ...prevState.formDataInEdit,
        [key]: val,
        validation: {
          ...prevState.formDataInEdit.validation,
          [key]: '', // Set validation empty
        },
      },
    }));
  }
  updateHandler = async (labsId) => {
    const { formDataInEdit } = this.state;
    const { name, description } = formDataInEdit;
    let payload = {
      name: name,
      description: description
    }
    try {
      const res = await LabsService.updateLabs(labsId, payload);
      // this.editHandler(null, {}, null);
      if (res.code === OK) {
        this.props.Toast.success('Lab Updated Successfully');
        this.editHandler(null, {}, null);
      }
      else if (res.status == "ERROR" && res.data) {
        if (isObject(res.data)) {
          let validaitons = {};
          for (let key in res.data) {
            validaitons = { ...validaitons, [key]: res.data[key][0] };
          }
          this.setState((prevState) => ({
            formDataInEdit: {
              ...prevState.formDataInEdit,
              validation: validaitons,
            },
          }));
        }
      }

    } catch (e) {

    }
    this.fetchLabsList();
  }
  labOrderModelHandler=(lab)=>{
    this.setState({labOrderModelOpen:!this.state.labOrderModelOpen,activeLabOrderData:lab})
  }
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click 
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}

export default withReduxState(WithRoutify(LabsList));