import React from "react";
import { isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";


const ScreenDataConfig = ({dataConfig,changeHandler,validationMsg,...props})=>{
    return(
       <>
       <div className="card mt-3 shadow_new border-0">
          <div className="card-header p-2">
         
            <div className="d-flex align-items-center justify-content-between">
           
              <ul className="nav nav-pills nav-secondary nav-pills-no-bd" id="pills-tab-without-border" role="tablist">
                {Object.entries(dataConfig).map(([key, value]) => {
                  if(isSet(dataConfig[key].visible,false)===false){return false;}
                  // console.log('key',key);
                return(
                    <li className="nav-item" key={key}  onClick={()=>props.fetchDataSharingOption(key)}>
                    <a className={`nav-link ${dataConfig[key].isActive===true?'active':''}`} id="pills-home-tab-nobd" data-bs-toggle="pill" href={"#pills-nobd-"+key} role="tab" aria-controls="pills-home-nobd" aria-selected="true">{dataConfig[key].label} </a>
                  </li>
                )
                })}
              </ul>
              {(props.screen=='USER')?
                 <a style={{'text-decoration':'underline',cursor:'pointer'}} onClick={()=>props.fetchRolePermission(props.assigned_role,false)} label={'Reset to default role settings'} acl={['any']}>Reset to default role settings</a>
              :<></>}
             
            </div>
          </div>

          <div className="card-body">
          
          {isSet(validationMsg, '') !== '' ? <span className="text-danger m-1">{isSet(validationMsg, '')}</span> : ''}
            <div className="tab-content" id="pills-without-border-tabContent">
            {Object.entries(dataConfig).map(([key, value]) => {
              
              return(
              <div  key={key} className={`tab-pane fade ${dataConfig[key].isActive===true?'show active':''}`} id={"pills-nobd-"+key} role="tabpanel" aria-labelledby="pills-home-tab-nobd">
                
                <div className="  ">
                 {/* <div className="card-header">
                     
                      <h6 className="mb-0"> {dataConfig[key].label} List </h6>
                      
                
                  </div> */}
                  <div className="card-body p-0">
                    <div className="d-flex row">
                      <FormInputs 
                        type='MultiSelectDropDown' 
                        isLoaded={props.isLoadDataSharing[key]}
                        changeHandler={(bool,{selectVal})=>changeHandler(key,bool,selectVal)} 
                        ddKey={key} 
                        {...dataConfig[key]} />
                    </div>
                  </div>
                </div>
              </div>
              )
            })}
            </div>
          </div>
       </div>
        
        </>
         
                    
    )
}

ScreenDataConfig.defaultProps = {
  validationMsg:''
}
export default ScreenDataConfig;