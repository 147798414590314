// src/services/userService.js
import { CREATED, OK, UNPROCESSABLE_CONTENT } from '../utils/constant';
import http from './http';
// import { useDispatch } from 'react-redux';
// import {setProfileDetails} from '../store/ProfileSlice';


const RolesService = {
  //GET PROFILE
  getPermissionList: async () => {
      try {
        //api/permissions/by-screen
        const response = await http.GET(`permissions/by-screen`);
          if(response.code===OK && response.data){
            return response.data;
          }
        return null;
      } catch (error) {
        // console.error('Error fetching user:', error);
        throw error;
      }
    },
    getPermission: async (roleId) => {
      try {
        //api/permissions/by-screen
        const response = await http.GET(`roles/${roleId}`);
          if(response.code===OK && response.data){
            // UserService.storeProfile(resData.data);
            // console.log('resData.data',resData.data);
            return response.data;
          }
        return null;
      } catch (error) {
        // console.error('Error fetching user:', error);
        throw error;
      }
    },
    getRolesList: async (query={},val='') => {
      const queryString = new URLSearchParams(query).toString();
      try {
        const response = await http.GET(`roles?${queryString}&role_name=${val}`);
        if(response.code===OK && response.data){
            return response.data;
          }
        return null;
      } catch (error) {
        // console.error('Error fetching user:', error);
        throw error;
      }
    },
    //Save user
    saveRole:async (data)=>{
      try {
        const response = await http.POST(`roles`,data)
            if((response.code===OK || response.code===CREATED) && response.data){
              return response.data;
            }else{
              return response;
            }
      } catch (error) {
        console.error('Error fetching user:', error);
        if(error.status===UNPROCESSABLE_CONTENT){
          return error;
        }
        
        // throw error;
      }  
    },
    //Delete clinic
    deleteRole:async (roleId)=>{
      try {
        const response = await http.DELETE(`roles/${roleId}`);
        return response;
      } catch (error) {
        if(error.status===UNPROCESSABLE_CONTENT){
          return error.data;
        }
        console.error('Error fetching user:', error);
        throw error;
      }  
    },
     //Save user
     updateRolePermission:async (roleId,data)=>{
      try {
        const response = await http.PUT(`roles/${roleId}`,data);
        if(response.code===OK && response.data){
            return response.data;
          }else{
            return response;
          }
      } catch (error) {
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        console.error('Error fetching user:', error);
        throw error;
      }  
    },
    getRoleByAsc: async (query={}) => {
      // const queryString = new URLSearchParams(query).toString();
      try {
        
        const response = await http.GET(`roles-by-asc`);
          if(response.code===OK && response.data){
            return response.data;
          }
        return null;
      } catch (error) {
        // console.error('Error fetching user:', error);
        throw error;
      }
    },
    getDataSharingOptionsList: async (data)=>{
      // http://snap.local/api/get-datasharing-data
      try {
        const response = await http.POST(`get-datasharing-data`,data);
            if((response.code===OK || response.code===CREATED) && response.data){
              return response.data;
            }else{
              return response;
            }
      } catch (error) {
        console.error('Error fetching user:', error);
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        
        // throw error;
      }  
    }
    
  };
  
  export default RolesService;