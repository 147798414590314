import React, { Component } from 'react';
import RolesService from '../../services/RoleService';
import TableGrid from '../../components/UI/TableGrid';
import AddRole from './AddRole';
import { ToastContext  } from "../../context/ToaxtContext";
import FormInputs from '../../components/UI/FormInputs';
import moment from 'moment';
import { capitalizeFirstLetter, isObject, isObjectEmpty, isSet, toQueryString } from '../../utils/commonUtils';
import Buttons from '../../components/UI/Buttons';
// import Card from '../../components/UI/Card';
import WithRoutify from '../../hoc/WithRoutify';
import { isGlobalAdmin } from '../../utils/aclUtils';
import withReduxState from '../../hoc/wReduxState';
import { UNPROCESSABLE_CONTENT } from '../../utils/constant';
import TableSkeleton from '../../hoc/TableSkeleton';



class RolesList extends Component {
  static contextType = ToastContext;
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders =  [
        { label: '',key:'isActive',type:'checkBox',changeHandler:this.checkBoxchangeHandler},
        { label: 'Role',key:'name',type:'',inputType:'TextInput',sortName:'name'},
        // { label: 'Description',key:'description',inputType:'TextArea'},
        { label: 'Created At',key:'createdAt',sortName:'created_at'},
        { label: 'Active',key:'active'},
        { label: 'Action',key:'action',type:'Actions'},
        // Add more columns as needed
      ];
    this.state = {
      filters:{
        search_query:''
      },
        tableRecords:{
            tableHeaders:tableHeaders,
            tableRows:{
                data:[],
                status:1000,
            },
            pagination:{
              page:1,
              perPage:25,
              totalRecords:0,
              sort_by:'name',
              sort_order:'asc',
              search:{},
            }
        },
        formDataInEdit:{},
        activeEditId:null,
      // Your state variables go here
      isModelOpen:false,
  }
}

  componentDidMount() {
    
    this.fetchRolesList();
   
    // Code to run after the component has mounted
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler=()=>{
    this.editHandler(null,{})
    // this.setState({isModelOpen:!this.state.isModelOpen})
  }
  render() {
    const {tableRecords,filters,isModelOpen,activeEditId} = this.state;
    
    return (
      <>
        {isModelOpen === true ? (
          <AddRole
            modelTitle={" Roles"}
            isOpen={isModelOpen}
            activeEditId={activeEditId}
            updateHandler={this.updateHandler}
            fetchRolesList={this.fetchRolesList}
            toogleHandler={this.editHandler}
          />
        ) : (
          <></>
        )}
        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"role-create"}
          content={"Role"}
        >
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="row">
                <div className="col-12">
                  <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                    <h6 className="text-xl f-500 mb-0"> Roles & Permission </h6>

                    <div className="addAction d-flex">
                      <div className="form-group-fields d-flex mr-2">
                        <div className="input-group  input-group-joined input-group-solid">
                          {/* <span class="input-group-text">
                               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"> <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"></path></svg>
                            </span> */}
                          <FormInputs
                            placeholder="Role"
                            value={filters["search_query"]}
                            changeHandler={(val) =>
                              this.onChangeFiltersHandler("search_query", val)
                            }
                            className="form-control"
                          />
                        </div>
                        <div className="btn-group pl-2">
                          <button
                            className="btn btn-primary"
                            onClick={() => this.submitFiltersHandler()}
                          >
                            Search
                          </button>
                          <button
                            className="btn btn-outline-secondary"
                            onClick={() => this.clearFiltersHandler()}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                      <div className="btn-group">
                        <Buttons
                          clickHandler={this.addFormHandler}
                          className="btn btn-primary"
                          acl={"role-create"}
                          label={"Add"}
                        />
                        <button
                          className="btn btn-outline-secondary dropdown no-arrow"
                          data-bs-toggle="dropdown"
                        >
                          <span className="dropdown-toggle">
                            <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                          </span>
                          <div
                            className="dropdown-menu dropdown-menu-right shadow"
                            aria-labelledby="userDropdown"
                          >
                            <a className="dropdown-item" href="#">
                              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                              Group Edit
                            </a>
                            <a className="dropdown-item" href="#">
                              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                              Grid Edit
                            </a>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sh_cardBody p-0">
              <TableGrid
                hasPagination={true}
                fetchTableRecords={(filters) => this.fetchRolesList(filters)}
                {...tableRecords}
                gridEditProps={{
                  formDataInEdit: this.state.formDataInEdit,
                  activeEditId: null, //this.state.activeEditId,
                  onChangeFormDataInEdit: this.onChangeFormDataInEdit,
                }}
              />
            </div>
          </div>
        </TableSkeleton>
      </>
    );
  }
  submitFiltersHandler = ()=>{
    const {filters,tableRecords}  =this.state;
    const queryfilters = {
      ...tableRecords.pagination,
      page:1,
      search:filters
    }
    this.fetchRolesList(queryfilters)
  }
  clearFiltersHandler = ()=>{
    this.setState({
     filters:{
     }
   },this.submitFiltersHandler);
 }
  fetchRolesList =async (query={})=>{
    const {Loader} = this.props;
    const {tableRecords} = this.state;
    const queryString = toQueryString({...tableRecords.pagination,...query});
    Loader(true);
    try{
       const rolesList =  await RolesService.getRolesList(queryString);
       Loader(false);
       let tableData = [];
       rolesList.data.map((role,roleIndex)=>{
        const usDateTimeString = moment.utc(role.created_at).format('MMMM D, YYYY h:mm:ss A');

        const isGlobal = isGlobalAdmin(role.name);  
        // isGlobalAdmin
        let isReadonly = false;
        if(!isGlobalAdmin(this.props.profile.role) && Boolean(role.is_all_organization)===true){
          isReadonly = true;
        }
        if (this.props.profile.role === role.name) {
          isReadonly = true;
        }
        tableData = [
            ...tableData, 
            {   
                roleIndex:roleIndex,
                isHeading:false,
                rowId:role.id,
                data:{
                    isActive:false,
                    name:role.name,
                    createdAt:usDateTimeString,//role.created_at,
                    active:'Yes',
                    action:[{
                      className:'btn btn-icon btn-transparent-dark',
                      iconType:'Edit',
                      title:'Edit',
                      // type:'GridEdit',
                      
                      acl:(isGlobal===true || isReadonly===true)?'':'role-edit',
                      clickHandler:()=>this.editHandler(role.id,{}),
                      // updateHandler:()=>this.updateHandler(role.uuid),
                      // onChangeFormDataInEdit:(key,val)=>this.onChangeFormDataInEdit(key,val)
                    },{ 
                      className:'btn btn-icon btn-transparent-dark',
                      iconType:'Remove',
                      acl:(isGlobal===true || isReadonly===true)?'':'role-delete',
                      title:'Delete',
                      isDelete:true,
                      clickHandler:()=>this.deleteHandler(role.id),
                    },
                    // { 
                    //   className:'btn btn-datatable btn-icon btn-transparent-dark',
                    //   iconType:'Permission',
                    //   title:'Role Permission',
                    //   href:`/roles/permissions/${btoa(role.id+'##'+role.name)}`,
                    //   acl:'role-edit',
                    //   // clickHandler:()=>{}
                    // }
                  ]
                }
            }
        ]
       });
       const updatePagination = (prevStatePagination, newPagination) => ({
        page: newPagination.page || prevStatePagination.page,
        perPage: newPagination.perPage || prevStatePagination.perPage,
        totalRecords: rolesList.pagination.totalCount,
        sort_by: newPagination.sort_by || prevStatePagination.sort_by,
        sort_order: newPagination.sort_order || prevStatePagination.sort_order,
      });
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders:prevState.tableRecords.tableHeaders,
          tableRows: {
            type: "default",
            data: tableData,
            status:200,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords:rolesList.pagination.totalCount
        },
        },
      }));
    }catch(e){
      this.setState({
        tableRecords:{
          ...tableRecords,
          tableRows:{
            ...tableRecords.tableRows,
            status:200
          }
        }
      });
      Loader(false);
    }
  }
  deleteHandler = async (roleId)=>{
        try{
          const {Loader,Toast} = this.props;
            const data = await RolesService.deleteRole(roleId);
            console.log('data',data);
            if (data&&data.code==UNPROCESSABLE_CONTENT&&data.data) {
              Toast.error(data.data);
            }
            else{
              Toast.success('Role Deleted Successfully');
              this.fetchRolesList();
            }
          }catch(e){
            this.fetchRolesList();
          }    
  }
  editHandler = (roleId,data)=>{
    // this.setState({
    //   activeEditId:rowId,
    //   formDataInEdit:data
    // }); 
    // alert('roleId'+roleId)
    this.setState(prevState => ({
      formDataInEdit: data,
      activeEditId: roleId,
      isModelOpen: !prevState.isModelOpen
    }));
  }
  onChangeFiltersHandler =(key,val)=>{
    const {filters} = this.state;
    this.setState({
      filters:{
        ...filters,
        [key]:val
      }
    })
  }
  onChangeFormDataInEdit = (key,val)=>{
    this.setState({
      formDataInEdit:{
        ...this.state.formDataInEdit,
        [key]:val
      }
    })
  }
  updateHandler = async (roleId,payload)=>{
    // return false;
    const {Loader,Toast} = this.props;
    Loader(true);
    try{
      const data = await RolesService.updateRolePermission(roleId,payload);
      Loader(false);
      if(data.code===500){
        Toast.error(isSet(data.data,'Something went wrong..'));
      }else{
        Toast.success('Role updated successfully');
        this.editHandler(null,{});
      }
      // console.log('data',data);
      
    }catch(e){
      Loader(false);
    } 
    this.fetchRolesList();
  }
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click 
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });
  
    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}

export default withReduxState(WithRoutify(RolesList));