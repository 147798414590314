import React, { useEffect, useState} from "react";
import Buttons from "../../components/UI/Buttons";
// import { useToast } from '../../context/ToaxtContext';
// import CryptoJS from 'crypto-js';

// import Selectlist  from "../../components/UI/FormInputs/SelectList";
// import { useNavigate } from 'react-router-dom';
// import UserService from "../../services/UserService";
// import TableGrid from "../../components/UI/TableGrid";
// import RolesService from "../../services/RoleService";

const DashboardContainer = () => {  
  // const { showToast } = useToast();
  // const handleShowToast = () => {
  //   showToast('error', 'This is a success message');
  // };

  // showToast('success', 'This is a success message');
  // var ciphertext = CryptoJS.AES.encrypt('haiwelcome', 'SNAP@GENE').toString();
  // console.log('ciphertext1',ciphertext);

  // var bytes = CryptoJS.AES.decrypt(ciphertext, 'SNAP@GENE');
  // var decryptedData =bytes.toString(CryptoJS.enc.Utf8);

  // console.log('decryptedData',decryptedData);
  
  return (
    <>
      <div className="sh_cardBox border-0">
        <div className="sh_cardBody py-0">
          <div className="row">
            <div className="col-md-3 col-4">
              <div className="sh_cardBox sh_widgets sh_dash_invoiceht " >
                <div className="sh_cardHeader border-0">
                 
                  <h6 className="text-lg text-secondary mb-0 font-weight-bold">
                    Open Invoices
                  </h6>
                </div>
                <div className="sh_cardBody py-0 ">
                  <div className="d-flex justify-content-between py-2">
                  <div class="dashboard_progress-bar today" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                  <div className="text-align-left">
                    <h6 className="text-xl font-weight-bold mb-0">
                      $0.00
                    </h6>
                    <span className="text-secondary text-xs">Current</span>
                  </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                  <div class="dashboard_progress-bar month" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                  <div className="text-align-left">
                    <h6 className="text-xl font-weight-bold mb-0">
                    $0.00
                    </h6>
                    <span className="text-secondary text-xs">30+Days</span>
                  </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                  <div class="dashboard_progress-bar year" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                  <div className="text-align-left">
                    <h6 className="text-xl font-weight-bold mb-0">
                    $0.00
                    </h6>
                    <span className="text-secondary text-xs">60+Days</span>
                  </div>
                  </div>

                <div className="dashboardtable py-0">
                        <div className="row ">
                          <div className="col-4  font-weight-bold"><a>Invoice</a></div>
                          <div className="col-4  font-weight-bold"><a>Invoice Date</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>Amount Due</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                          <div className="col-4 px-0 font-weight-bold"><a>
                            0000</a></div>
                            <div className="col-4 px-0 font-weight-bold"><a>
                            --</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>$0.00</a></div>
                        </div>
                        <div className="row dashstatusbg ">
                        <div className="col-4 px-0 font-weight-bold"><a>
                            0000</a></div>
                            <div className="col-4 px-0 font-weight-bold"><a>
                            --</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>$0.00</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                        <div className="col-4 px-0 font-weight-bold"><a>
                            0000</a></div>
                            <div className="col-4 px-0 font-weight-bold"><a>
                            --</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>$0.00</a></div>
                        </div>
                        <div className="row dashstatusbg ">
                        <div className="col-4 px-0 font-weight-bold"><a>
                            0000</a></div>
                            <div className="col-4 px-0 font-weight-bold"><a>
                            --</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>$0.00</a></div>
                        </div>
                    </div>
                </div>
                <div className="sh_cardFooter border-0 py-0 ">
                  <button className="btn dashboardbtn  btn-danger"> Pay Now</button>
                  <button className="btn dashboardbtn btn-info"> Contracted Rates</button>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-8">
              <div className="row">
                <div className="col-md-4 col-4 py-2">
                  <div className="sh_cardBox sh_widgets " >
                    <div className="sh_cardHeader border-0">
                      <h6 className="text-lg text-secondary mb-0 font-weight-bold">
                        Physicals
                      </h6>
                    </div>
                    <div className="sh_cardBody py-0 dashboard_card">
                        <div className="row ">
                          <div className="col-8  font-weight-bold"><a>Status</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>Count</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                          <div className="col-8 px-0 font-weight-bold"><a>
                            <Buttons
                            iconType="yellow"
                            className="px-2"
                            />
                            Pending</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg ">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="blue"
                            className="px-2"
                            />
                            Completed</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="green"
                            className="px-2"
                            />
                            Passed</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="red"
                            className="px-2"
                            />
                            Failed</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                    </div>
                    <div className="sh_cardFooter dashboardfooter border-0 pt-0 justify-content-center">
                    <button className="btn dashboardbtn  btn-info px-4"> Review All</button>

                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-4 py-2">
                  <div className="sh_cardBox sh_widgets " >
                    <div className="sh_cardHeader border-0">
                      <h6 className="text-lg text-secondary mb-0 font-weight-bold">
                        Drug Screens
                      </h6>
                    </div>
                    <div className="sh_cardBody py-0 dashboard_card">
                        <div className="row ">
                          <div className="col-8  font-weight-bold"><a>Status</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>Count</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                          <div className="col-8 px-0 font-weight-bold"><a>
                            <Buttons
                            iconType="green"
                            className="px-2"
                            />
                            Positive</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg ">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="red"
                            className="px-2"
                            />
                            Negative</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="yellow"
                            className="px-2"
                            />
                            Pending</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="yellow"
                            className="px-2"
                            />
                            Missing</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                    </div>
                    <div className="sh_cardFooter dashboardfooter border-0 pt-0 justify-content-center">
                    <button className="btn dashboardbtn  btn-info px-4"> Review All</button>

                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-4 py-2">
                  <div className="sh_cardBox sh_widgets " >
                    <div className="sh_cardHeader border-0">
                      <h6 className="text-lg text-secondary mb-0 font-weight-bold">
                        Labs
                      </h6>
                    </div>
                    <div className="sh_cardBody py-0 dashboard_card">
                        <div className="row ">
                          <div className="col-8  font-weight-bold"><a>Status</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>Count</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                          <div className="col-8 px-0 font-weight-bold"><a>
                            <Buttons
                            iconType="green"
                            className="px-2"
                            />
                            Positive</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg ">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="red"
                            className="px-2"
                            />
                            Negative</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="yellow"
                            className="px-2"
                            />
                            Pending</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="yellow"
                            className="px-2"
                            />
                            Missing</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                    </div>
                    <div className="sh_cardFooter dashboardfooter border-0 pt-0 justify-content-center">
                    <button className="btn dashboardbtn  btn-info px-4"> Review All</button>

                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-4 py-2">
                  <div className="sh_cardBox sh_widgets " >
                    <div className="sh_cardHeader border-0">
                      <h6 className="text-lg text-secondary mb-0 font-weight-bold">
                        Missing Claim Numbers
                      </h6>
                    </div>
                    <div className="sh_cardBody py-0 dashboard_card">
                        <div className="row ">
                          <div className="col-8  font-weight-bold"><a>Claim Age</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>Count</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                          <div className="col-8 px-0 font-weight-bold"><a>
                            <Buttons
                            iconType="red"
                            className="px-2"
                            />
                            Total Missing Claims</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg ">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="red"
                            className="px-2"
                            />
                            Completed</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="green"
                            className="px-2"
                            />
                            Passed</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg">
                          <div className="col-8 px-0 font-weight-bold"><a>
                          <Buttons
                            iconType="red"
                            className="px-2"
                            />
                            Failed</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                    </div>
                    <div className="sh_cardFooter dashboardfooter border-0 pt-0 justify-content-center">
                    <button className="btn dashboardbtn  btn-danger px-4"> Review (0)</button>

                    </div>
                  </div>
                </div>                
                <div className="col-md-4 col-4 py-2">
                  <div className="sh_cardBox sh_widgets " >
                    <div className="sh_cardHeader border-0">
                      <h6 className="text-lg text-secondary mb-0 font-weight-bold">
                        Work Restrictions
                      </h6>
                    </div>
                    <div className="sh_cardBody py-0 dashboard_card">
                        <div className="row ">
                          <div className="col-4  font-weight-bold"><a>Last Name</a></div>
                          <div className="col-4  font-weight-bold"><a>First Name</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>Employer</a></div>
                        </div>
                        <div className="row dashstatusbg first">
                          <div className="col-4 px-0 font-weight-bold"><a>
                            --</a></div>
                            <div className="col-4 px-0 font-weight-bold"><a>
                            --</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                        <div className="row dashstatusbg ">
                          <div className="col-4 px-0 font-weight-bold"><a>
                            --</a></div>
                            <div className="col-4 px-0 font-weight-bold"><a>
                            --</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a>00</a></div>
                        </div>
                    </div>
                    <div className="sh_cardFooter dashboardfooter border-0 pt-0 justify-content-center">
                    <button className="btn dashboardbtn  btn-danger px-4"> Review All</button>

                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-4 py-2">
                  <div className="sh_cardBox sh_widgets " >
                    <div className="sh_cardHeader border-0">
                      <h6 className="text-lg text-secondary mb-0 font-weight-bold">
                        Worker's Comp Carrier
                      </h6>
                    </div>
                    <div className="sh_cardBody py-0 dashboard_card">
                        <div className="row dashstatusbg first pl-2">
                          <div className="col-8 px-0 font-weight-bold"><a>
                            Traveler's Insurance</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a></a></div>
                        </div>
                        <div className="row dashstatusbg pl-2">
                          <div className="col-8 px-0 font-weight-bold"><a>
                            Policy#</a></div>
                          <div className="col-4 font-weight-bold ratescount">
                            <Buttons
                             iconType="Copyicon"
                             acl="any"
                            />
                          </div>
                        </div>
                        <div className="row dashstatusbg first pl-2">
                          <div className="col-8 px-0 font-weight-bold"><a>
                            Phone:</a></div>
                          <div className="col-4 font-weight-bold ratescount"><a></a></div>
                        </div>
                        <div className="row dashstatusbg pl-2">
                          <div className="col-8 px-0 font-weight-bold"><a>
                            Adjuster:</a></div>
                          <div className="col-4 font-weight-bold ratescount">
                            <Buttons
                             iconType="DashboardMessage"
                             acl="any"
                            />
                          </div>
                        </div>
                    </div>
                    <div className="sh_cardFooter dashboardfooter border-0 pt-0 justify-content-center">
                    <button className="btn dashboardbtn  btn-info px-4"> Update Ins.Info</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>        

    </>
  );
};

export default DashboardContainer;


