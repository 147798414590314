import { useState } from "react";
import { Modal } from "react-bootstrap";
import { components } from "react-select";
import ActivityConsent from "./ActivityConsent";
import ConsentDeclination from "./ConsentDeclination";

const ConsentContainer = (props) => {
    const { activityData, addFormHandler } = props;
    const MAIN_TABS = [
        { label: 'Activity Consents', component: ActivityConsent },
        { label: 'Consent Declination', component: ConsentDeclination }
    ];
    const [formData, setFormData] = useState({});
    const [ActiveTab, setActiveTab] = useState(MAIN_TABS[0]);


    return (
        <>
            <Modal
                show={true}
                onHide={() => addFormHandler(null, null, true)}
                size="xl"
                dialogClassName="hResponsive"
                contentClassName="h-100"
                keyboard={false}
                backdrop="static"            >
                <div className="sh_modalBox">
                    <Modal.Header closeButton className="p-2">
                        <div className="tabs-wrapper">
                            <ul className="nav nav-borders nav-tabs border-0" >
                                {MAIN_TABS.map((tab, tabIndex) => (
                                    <li>
                                        <a className={`${ActiveTab.label == tab.label ? 'active ' : ''} nav-link`} onClick={() => setActiveTab(tab)} key={tabIndex}>{tab.label}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Modal.Header>
                    <div className="modal-body tab-content">
                        <ActiveTab.component activityData={activityData} setFormData={setFormData} formData={formData} addFormHandler={addFormHandler} />
                    </div>
                    {/* <div className="d-flex justify-content-center modal-footer">
                        <button type="button" className="btn btn-primary">Submit</button>
                    </div> */}
                </div>
            </Modal>
        </>

    );

}
export default ConsentContainer;