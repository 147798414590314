import React from 'react';

const StatusIcons = (props) => {
    const { type, size = 16 } = props;

    const renderIcon = () => {
        switch(type) {
            case 'green':
                return (
                    // <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width={size} fill="green">
                    //     <path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                    // </svg>    
                    <i className='fa fa-circle ' style={{fontSize:'14px',color:'#007500'}} aria-hidden="true"></i>

                );
            case 'red':
                return (
                    // <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width={size} fill="#EA3323">
                    //     <path d="M479.79-288q15.21 0 25.71-10.29t10.5-25.5q0-15.21-10.29-25.71t-25.5-10.5q-15.21 0-25.71 10.29t-10.5 25.5q0 15.21 10.29 25.71t25.5 10.5ZM444-432h72v-240h-72v240Zm36.28 336Q401-96 331-126t-122.5-82.5Q156-261 126-330.96t-30-149.5Q96-560 126-629.5q30-69.5 82.5-122T330.96-834q69.96-30 149.5-30t149.04 30q69.5 30 122 82.5T834-629.28q30 69.73 30 149Q864-401 834-331t-82.5 122.5Q699-156 629.28-126q-69.73 30-149 30Zm-.28-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/>
                    // </svg> 
                 <i className='fas fa-circle' style={{fontSize:'14px',color:'#FF0000'}} aria-hidden="true"></i>
                );
                case 'yellow':
                    return(
                        <i className='fas fa-circle' style={{fontSize:'14px',color:'#FDA636'}} aria-hidden="true"></i>
                    );
                case 'blue':
                    return (
                        // <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width={size} fill="#2854C5">
                        //     <path d="M480-240q20 0 34-14t14-34q0-20-14-34t-34-14q-20 0-34 14t-14 34q0 20 14 34t34 14Zm-36-153h73q0-37 6.5-52.5T555-485q35-34 48.5-58t13.5-53q0-55-37.5-89.5T484-720q-51 0-88.5 27T343-620l65 27q9-28 28.5-43.5T482-652q28 0 46 16t18 42q0 23-15.5 41T496-518q-35 32-43.5 52.5T444-393Zm36 297q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-72q130 0 221-91t91-221q0-130-91-221t-221-91q-130 0-221 91t-91 221q0 130 91 221t221 91Zm0-312Z"/>
                        // </svg> 
                        <i className='fas fa-circle' style={{fontSize:'14px',color:'#017BFF'}} aria-hidden="true"></i>
                    );
                    case 'patientdetails':
                    return (
                        // <svg xmlns="http://www.w3.org/2000/svg" height={'18px'} width={'18px'}  fill='#6698e2' viewBox="0 -960 960 960"  ><path d="M624-384q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM384-144v-63q0-28 14.5-51t38.5-35q43-21 90-32t97-11q50 0 97 11t90 32q24 12 38.5 35t14.5 51v63H384Zm73-72h334q-30-23-72-35.5T624-264q-53 0-95 12.5T457-216Zm167-240q20.4 0 34.2-13.8Q672-483.6 672-504q0-20.4-13.8-34.2Q644.4-552 624-552q-20.4 0-34.2 13.8Q576-524.4 576-504q0 20.4 13.8 34.2Q603.6-456 624-456Zm0-48Zm0 288ZM144-396v-72h288v72H144Zm0-300v-72h432v72H144Zm293 150H144v-72h326q-12 16-20.11 33.78Q441.77-566.44 437-546Z"/>
                        // </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={'14.874'} height={'15.7'} viewBox="0 0 14.874 15.7">
                            <g id="checklist" transform="translate(-3 -3)">
                            <path id="Path_1" data-name="Path 1" d="M18.024,4H12.24a1.24,1.24,0,0,0,0,2.479h5.784a1.24,1.24,0,0,0,0-2.479Z" transform="translate(-1.389 -0.174)" fill="#0623c1"/>
                            <path id="Path_2" data-name="Path 2" d="M18.024,11H12.24a1.24,1.24,0,1,0,0,2.479h5.784a1.24,1.24,0,1,0,0-2.479Z" transform="translate(-1.389 -1.389)" fill="#0623c1"/>
                            <path id="Path_3" data-name="Path 3" d="M18.024,18H12.24a1.24,1.24,0,0,0,0,2.479h5.784a1.24,1.24,0,0,0,0-2.479Z" transform="translate(-1.389 -2.605)" fill="#0623c1" opacity="0.35"/>
                            <path id="Path_4" data-name="Path 4" d="M5.479,14.132H4.653A1.652,1.652,0,0,1,3,12.479v-.826A1.652,1.652,0,0,1,4.653,10h.826a1.652,1.652,0,0,1,1.653,1.653v.826A1.652,1.652,0,0,1,5.479,14.132Z" transform="translate(0 -1.216)" fill="#0623c1"/>
                            <path id="Path_5" data-name="Path 5" d="M5.479,7.132H4.653A1.652,1.652,0,0,1,3,5.479V4.653A1.652,1.652,0,0,1,4.653,3h.826A1.652,1.652,0,0,1,7.132,4.653v.826A1.652,1.652,0,0,1,5.479,7.132Z" fill="#0623c1"/>
                            <path id="Path_6" data-name="Path 6" d="M5.479,21.132H4.653A1.652,1.652,0,0,1,3,19.479v-.826A1.652,1.652,0,0,1,4.653,17h.826a1.652,1.652,0,0,1,1.653,1.653v.826A1.652,1.652,0,0,1,5.479,21.132Z" transform="translate(0 -2.431)" fill="#0623c1" opacity="0.35"/>
                            </g>
                        </svg>
                    );
                    case 'visitdetails':
                    return (
                    //     <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" height={'14px'} fill='#fdaf3d' width={'14px'} data-name="Layer 1" viewBox="0 0 24 24">
                    //     <polygon points="21.414 5 17 5 17 .586 21.414 5"/>
                    //     <path d="M15,7V0H5c-1.657,0-3,1.343-3,3V24H22V7h-7Zm-5.5-2c1.105,0,2,.895,2,2s-.895,2-2,2-2-.895-2-2,.895-2,2-2Zm-3.5,7c0-1.103,.897-2,2-2h3c1.103,0,2,.897,2,2v1h-2v-1h-3v1h-2v-1Zm12,5H6v-2h12v2Zm0,4H6v-2h12v2Z"/>
                    //   </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width={'14.874'} height={'15.7'} viewBox="0 0 14.874 15.7">
                        <g id="checklist" transform="translate(-3 -3)">
                        <path id="Path_1" data-name="Path 1" d="M18.024,4H12.24a1.24,1.24,0,0,0,0,2.479h5.784a1.24,1.24,0,0,0,0-2.479Z" transform="translate(-1.389 -0.174)" fill="#fda635"/>
                        <path id="Path_2" data-name="Path 2" d="M18.024,11H12.24a1.24,1.24,0,1,0,0,2.479h5.784a1.24,1.24,0,1,0,0-2.479Z" transform="translate(-1.389 -1.389)" fill="#fda635"/>
                        <path id="Path_3" data-name="Path 3" d="M18.024,18H12.24a1.24,1.24,0,0,0,0,2.479h5.784a1.24,1.24,0,0,0,0-2.479Z" transform="translate(-1.389 -2.605)" fill="#fda635" opacity="0.35"/>
                        <path id="Path_4" data-name="Path 4" d="M5.479,14.132H4.653A1.652,1.652,0,0,1,3,12.479v-.826A1.652,1.652,0,0,1,4.653,10h.826a1.652,1.652,0,0,1,1.653,1.653v.826A1.652,1.652,0,0,1,5.479,14.132Z" transform="translate(0 -1.216)" fill="#fda635"/>
                        <path id="Path_5" data-name="Path 5" d="M5.479,7.132H4.653A1.652,1.652,0,0,1,3,5.479V4.653A1.652,1.652,0,0,1,4.653,3h.826A1.652,1.652,0,0,1,7.132,4.653v.826A1.652,1.652,0,0,1,5.479,7.132Z" fill="#fda635"/>
                        <path id="Path_6" data-name="Path 6" d="M5.479,21.132H4.653A1.652,1.652,0,0,1,3,19.479v-.826A1.652,1.652,0,0,1,4.653,17h.826a1.652,1.652,0,0,1,1.653,1.653v.826A1.652,1.652,0,0,1,5.479,21.132Z" transform="translate(0 -2.431)" fill="#fda635" opacity="0.35"/>
                        </g>
                    </svg>
                    );
                    case 'companydetails':
                    return (
                    //     <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" height={'14px'} width={'14px'} fill='#6bb69b' data-name="Layer 1" viewBox="0 0 24 24">
                    //     <path d="m16.5,15.5c0-1.379,1.121-2.5,2.5-2.5s2.5,1.121,2.5,2.5-1.121,2.5-2.5,2.5-2.5-1.121-2.5-2.5Zm7.5,6.5v2h-10v-2c0-1.654,1.346-3,3-3h4c1.654,0,3,1.346,3,3Zm-12,2H0V3C0,1.346,1.346,0,3,0h10c1.654,0,3,1.346,3,3v9.17c-.914.824-1.5,2.005-1.5,3.33,0,.7.174,1.354.46,1.945-1.741.783-2.96,2.526-2.96,4.555v2Zm-3-17h3v-2h-3v2Zm0,4h3v-2h-3v2Zm0,4h3v-2h-3v2Zm0,4h3v-2h-3v2Zm-2-2h-3v2h3v-2Zm0-4h-3v2h3v-2Zm0-4h-3v2h3v-2Zm0-4h-3v2h3v-2Z"/>
                    //   </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width={'14.874'} height={'15.7'} viewBox="0 0 14.874 15.7">
                        <g id="checklist" transform="translate(-3 -3)">
                            <path id="Path_1" data-name="Path 1" d="M18.024,4H12.24a1.24,1.24,0,0,0,0,2.479h5.784a1.24,1.24,0,0,0,0-2.479Z" transform="translate(-1.389 -0.174)" fill="#60ad90"/>
                            <path id="Path_2" data-name="Path 2" d="M18.024,11H12.24a1.24,1.24,0,1,0,0,2.479h5.784a1.24,1.24,0,1,0,0-2.479Z" transform="translate(-1.389 -1.389)" fill="#60ad90"/>
                            <path id="Path_3" data-name="Path 3" d="M18.024,18H12.24a1.24,1.24,0,0,0,0,2.479h5.784a1.24,1.24,0,0,0,0-2.479Z" transform="translate(-1.389 -2.605)" fill="#60ad90" opacity="0.35"/>
                            <path id="Path_4" data-name="Path 4" d="M5.479,14.132H4.653A1.652,1.652,0,0,1,3,12.479v-.826A1.652,1.652,0,0,1,4.653,10h.826a1.652,1.652,0,0,1,1.653,1.653v.826A1.652,1.652,0,0,1,5.479,14.132Z" transform="translate(0 -1.216)" fill="#60ad90"/>
                            <path id="Path_5" data-name="Path 5" d="M5.479,7.132H4.653A1.652,1.652,0,0,1,3,5.479V4.653A1.652,1.652,0,0,1,4.653,3h.826A1.652,1.652,0,0,1,7.132,4.653v.826A1.652,1.652,0,0,1,5.479,7.132Z" fill="#60ad90"/>
                            <path id="Path_6" data-name="Path 6" d="M5.479,21.132H4.653A1.652,1.652,0,0,1,3,19.479v-.826A1.652,1.652,0,0,1,4.653,17h.826a1.652,1.652,0,0,1,1.653,1.653v.826A1.652,1.652,0,0,1,5.479,21.132Z" transform="translate(0 -2.431)" fill="#60ad90" opacity="0.35"/>
                        </g>
                        </svg>
                    );
                    case 'labdetails':
                    return (
                        // <svg xmlns="http://www.w3.org/2000/svg" height={'14px'} fill='#e2d466' width={'14px'} id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24">
                        // <path d="M22.5,18c0-.46-.089-.895-.218-1.313l1.416-.816-.998-1.733-1.411,.813c-.605-.652-1.393-1.125-2.289-1.33v-1.621h-2v1.621c-.896,.205-1.685,.678-2.289,1.33l-1.411-.813-.998,1.733,1.416,.816c-.129,.418-.218,.853-.218,1.313s.089,.895,.218,1.313l-1.416,.816,.998,1.733,1.411-.813c.605,.652,1.393,1.125,2.289,1.33v1.621h2v-1.621c.896-.205,1.685-.678,2.289-1.33l1.411,.813,.998-1.733-1.416-.816c.129-.418,.218-.853,.218-1.313Zm-4.5,1.5c-.827,0-1.5-.673-1.5-1.5s.673-1.5,1.5-1.5,1.5,.673,1.5,1.5-.673,1.5-1.5,1.5ZM7,9.04V2h-2V0h12V2h-2v7.04c.29,.253,.731,.652,1.251,1.158-2.581,.576-4.69,2.397-5.664,4.802H1.758c1.746-2.728,4.296-5.131,5.242-5.96Zm5.721,14.96H3.044c-.117-.042-3.044-1.108-3.044-4.143,0-.951,.258-1.884,.678-2.857H10.069c-.041,.328-.069,.661-.069,1,0,2.393,1.056,4.534,2.721,6Z"/>
                        // </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width={'14.874'} height={'15.7'} viewBox="0 0 14.874 15.7">
                        <g id="checklist" transform="translate(-3 -3)">
                            <path id="Path_1" data-name="Path 1" d="M18.024,4H12.24a1.24,1.24,0,0,0,0,2.479h5.784a1.24,1.24,0,0,0,0-2.479Z" transform="translate(-1.389 -0.174)" fill="#737582"/>
                            <path id="Path_2" data-name="Path 2" d="M18.024,11H12.24a1.24,1.24,0,1,0,0,2.479h5.784a1.24,1.24,0,1,0,0-2.479Z" transform="translate(-1.389 -1.389)" fill="#737582"/>
                            <path id="Path_3" data-name="Path 3" d="M18.024,18H12.24a1.24,1.24,0,0,0,0,2.479h5.784a1.24,1.24,0,0,0,0-2.479Z" transform="translate(-1.389 -2.605)" fill="#737582" opacity="0.35"/>
                            <path id="Path_4" data-name="Path 4" d="M5.479,14.132H4.653A1.652,1.652,0,0,1,3,12.479v-.826A1.652,1.652,0,0,1,4.653,10h.826a1.652,1.652,0,0,1,1.653,1.653v.826A1.652,1.652,0,0,1,5.479,14.132Z" transform="translate(0 -1.216)" fill="#737582"/>
                            <path id="Path_5" data-name="Path 5" d="M5.479,7.132H4.653A1.652,1.652,0,0,1,3,5.479V4.653A1.652,1.652,0,0,1,4.653,3h.826A1.652,1.652,0,0,1,7.132,4.653v.826A1.652,1.652,0,0,1,5.479,7.132Z" fill="#737582"/>
                            <path id="Path_6" data-name="Path 6" d="M5.479,21.132H4.653A1.652,1.652,0,0,1,3,19.479v-.826A1.652,1.652,0,0,1,4.653,17h.826a1.652,1.652,0,0,1,1.653,1.653v.826A1.652,1.652,0,0,1,5.479,21.132Z" transform="translate(0 -2.431)" fill="#737582" opacity="0.35"/>
                        </g>
                        </svg>
                    );
                    case 'Testdetails':
                        return (
                            <i className='fas fa-file-medical' style={{fontSize:'14px',color:'#009fff'}} aria-hidden="true"></i>
                        );
            default:
                return null;
        }
    };

    return (
        <>
            {renderIcon()}
        </>
    );
};

export default StatusIcons;
