import React, { Component } from "react";
import Calendar from "../../components/Calendar";
import Buttons from "../../components/UI/Buttons";
// import FormInputs from "../../components/UI/FormInputs";
import VisitsService from "../../services/VisitsService";
import LookupService from "../../services/LookupService";
import CompanyService from "../../services/CompanyService";
// import { organizationList } from "../../store/OrganizationSlice";
import OrganizationService from "../../services/OrganizationService";
import { convertUnderScoreStrings, isSet } from "../../utils/commonUtils";
import { VISIT_CATEGORY, VISIT_STATUS } from "../../utils/constant";
import AddEmployee from "../EmployeeContainer/AddEmployee";
import AddVisits from "../EmployeeVisitContainer/AddVisits";
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import VisitDetails from "../EmployeeVisitContainer/VisitDetails";
import CalendarWidget from "./CalendarWidget";
import DischargeAndWaiting from "./DischargeAndWaiting";
import Acl from "../../hoc/Acl";
import PERMISSIONS from "../../utils/AclPermissions";
import FutureAppointments from "./FutureAppointments";

class Appointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarViewType:'WEEK',
      calendarParam: {from_date:'',to_date:''},
      currentDay: undefined,
      filters: {},
      isCalendarWidgetDate:null,
      isModelOpen:false,
      isAddPatientModelOpen:false,
      calendarRecords:[],
      visitCategoryOptions:[],
      companiesOptions:[],
      visitStatusOption:[],
      organizationList:[],
      visitsAutoFillData:{},
      visitCategoryOptions:[],
      isVisitDetailViewOpen: false,
      visitId: null,
      snapClinicConsentForm:null,
      snapEmployeeDetails:null,
      snapVisitConsentForm:null,
    };
  }

  componentDidMount = ()=>{
    const {calendarParam} = this.state;
    this.fetchAppointmentHistory(true);

    this.fetchVisitCategory();
    this.fetchCompaniesList();
    // if(isSet(this.props.profile) && !isSet(this.props.profile.role[0] === 'company_employee') ){
      
    // }
    this.fetchOrganization();
    this.fetchVisitStatus();

  }
  fetchVisitList=async(visitId=this.props.params.visitId)=>{
    const { Loader } = this.props;
      Loader(true);
      try {
          const data = await VisitsService.getVisits(visitId);
          this.setState({visitDetails: {...data[0],...data},employeeId:data[0].employee_id});
          Loader(false);
      } catch (error) {
          Loader(false);
          console.error("Error fetching employee details:", error);
      }
      
  }
  fetchVisitStatus = async ()=>{
    try{
      const data = await LookupService.getLookupByKey(VISIT_STATUS);
      let visitStatusArr = [{ label: '-Visit Status-', value: '' }];
          data.forEach((item) => {
            visitStatusArr.push({
                    label: item.value,
                    value: item.value
                });
            });
        this.setState({visitStatusOption:visitStatusArr});
    }catch(e){
    }
  }
  fetchOrganization = async () => {
    if (this.props.profile.is_multiple_organization === true) {
      let org = await OrganizationService.getOrganization();
      let orgOpt = [{ label: "-Select Organization-", value: "" }];
      isSet(org.data.data,[]).forEach((itm) => {
        orgOpt.push({
          label: convertUnderScoreStrings(itm.name),
          value: itm.organization_uuid,
        });
      });
      this.setState({ organizationOption: orgOpt });
    }
  };
  fetchAppointmentHistory = async (isLoader=false)=>{
    const {calendarParam} = this.state;
    const {Loader} = this.props;
    const queryString = {
      visit_from:calendarParam.from_date,
      visit_to:calendarParam.to_date
    };
    // if (isSet(this.props.profile) && isSet(this.props.profile.role[0] === 'company_employee')) {
    //   queryString.employee_id = this.props.profile.employee_id;
    // }
    if(isLoader===true){
      Loader(true);
    }
    try{
      const data = await VisitsService.getVisitsHistory(queryString);
      Loader(false);
      this.setState({calendarRecords:data});
      // console.log('AppoitmentHistory',data);
    }catch(e){
      Loader(false);
    }
  }
  calendarActionHandler = async (
    calendarViewType = "WEEK",
    calendarParam = {},
    currentDay = ""
  ) => {
    if (currentDay === "") {
      currentDay = new Date();
    }
    
    // console.log('calendarViewType',calendarViewType);
    this.setState({
      calendarParam: calendarParam,
      currentDay: currentDay,
      calendarViewType:calendarViewType,
    },()=>this.fetchAppointmentHistory());
    // await this.changeGridViewHandler('CALENDAR',{},true,calendarViewType,calendarParam,currentDay)
  };

  addVisitToogleHandler = (autoFillData={}) => {
    // console.log('autoFillData',autoFillData);
    this.setState({ isModelOpen: !this.state.isModelOpen,
      visitsAutoFillData:autoFillData
     });
  };
  addNewEmployeeToogleHandler = (bool=false,nextStep=null,autoFillData) => {
    console.log('nextStep',nextStep,bool,nextStep,autoFillData);
    if(nextStep==='ADD_VISIT'){
      this.setState({
        // visitsAutoFillData:autoFillData, 
        isAddPatientModelOpen: bool ,
      },()=>this.addVisitToogleHandler(autoFillData));
      
    }else{
      this.setState({ 
        isAddPatientModelOpen: bool ,
        isModelOpen:false
      });
    }
  };
    fetchVisitCategory = async ()=>{
      try{
        const data = await LookupService.getLookupByKey(VISIT_CATEGORY);
        let visitCategoryArr = [{ label: '-Visit Category-', value: '' }];
            data.forEach((item) => {
              visitCategoryArr.push({
                      label: item.value,
                      value: item.value
                  });
              });
          this.setState({visitCategoryOptions:visitCategoryArr},()=>{});
      }catch(e){
        // this.fetchVisitsList();
      }
    }
    fetchCompaniesList = async (query = "") => {
      // this.props.Loader(true);
      CompanyService.getCompanies(query)
        .then((res) => {
          let companiesOptions = [{ label: "-Select Company-", value: "" }];
          res.data.data.forEach((company) => {
            companiesOptions.push({
              label: company.name,
              value: company.company_uuid,
            });
          });
          this.setState({
            companiesOptions: companiesOptions,
          });
        })
        .catch((err) => {
          console.log("err===>", err);
        })
        .finally(() => {
          this.props.Loader(false);
        });
    };
    visitDetailViewModal = (visitId=null,clinicConsentForm=null,snapEmployeeDetails=null,snapVisitConsentForm=null,fetch=false) => {
      if(fetch){
        this.fetchAppointmentHistory();
      }
      this.setState((oldState) => ({
        isVisitDetailViewOpen: !oldState.isVisitDetailViewOpen,
        visitId: visitId,
        snapClinicConsentForm:clinicConsentForm,
        snapEmployeeDetails:snapEmployeeDetails,
        snapVisitConsentForm:snapVisitConsentForm,
      }));
    };
  setCurrentDate = (val,calendarViewType)=>{
    this.setState({currentDay:val,calendarViewType:calendarViewType,isCalendarWidgetDate:val});
  }
  render() {
    const { calendarParam, filters,isAddPatientModelOpen,visitsAutoFillData,visitCategoryOptions,isModelOpen } = this.state;
    const {isVisitDetailViewOpen,visitStatusOption,visitId,snapClinicConsentForm,snapEmployeeDetails,snapVisitConsentForm,visitDetails} = this.state;
    return (
      <>
      {isAddPatientModelOpen ? (
          <AddEmployee
            modelTitle={"Add Employee"}
            // isFromVisitHistory={this.props.isFromVisitHistory}
            fetchEmployeeList={this.fetchAppointmentHistory}
            isOpen={isAddPatientModelOpen}
            autoFillData={visitsAutoFillData}
            modalToogleHandler={this.addNewEmployeeToogleHandler}
            companiesOptions={this.state.companiesOptions}
            organizationOption={this.state.organizationOption}
          />
        ) : isModelOpen ? (
          <AddVisits
            modelTitle={"Add Visits"}
            fetchVisitsList={this.fetchAppointmentHistory}
            isOpen={isModelOpen}
            autoFillData={visitsAutoFillData}
            headerButton={ 
              <Acl acl={PERMISSIONS.EMPLOYEE.CREATE}><Buttons
              clickHandler={() =>this.addNewEmployeeToogleHandler(true)}
              className="btn btn-primary"
              label={"Add Patient"}
              acl={PERMISSIONS.EMPLOYEE.CREATE}
            /></Acl>}
            modalToogleHandler={this.addVisitToogleHandler}
            visitCategoryOptions={visitCategoryOptions}
            companiesOptions={this.state.companiesOptions}
            organizationOption={this.state.organizationOption}
          />
        ) :<></>}

        {isVisitDetailViewOpen ? (
          <VisitDetails
          isOpen={isVisitDetailViewOpen}
          visitId={visitId}
          modalToogleHandler={this.visitDetailViewModal}
          fetchDetails={this.fetchAppointmentHistory}
          snapClinicConsentForm={snapClinicConsentForm}
          snapEmployeeDetails={snapEmployeeDetails}
          visitStatusOption={visitStatusOption}
          snapVisitConsentForm={snapVisitConsentForm}
          showConsentButtons={(snapClinicConsentForm!=null || snapVisitConsentForm!=null) ? true : false}
        />
        ) : (
          ""
        )}
      <div className="row h-100 " >
        <div className="col-12 col-lg-3 pt-2"style={{height:'calc(100vh - 95px)',overflowY:"auto", scrollbarWidth: 'thin'}}>
            <CalendarWidget currentDay={this.state.currentDay} calendarViewType={this.state.calendarViewType} setCurrentDate={this.setCurrentDate}/>
            <DischargeAndWaiting 
              fetchDetails={this.fetchAppointmentHistory}
              calendarRecords={this.state.calendarRecords}
              currentDay={this.state.currentDay} calendarParam={calendarParam}/>
              <FutureAppointments
              calendarRecords={this.state.calendarRecords}
              />
        </div>
        <div className="col-lg-9 col-12" style={{height:'100calc(100vh - 95px)vh'}}>
          <div className="sh_cardBox sh-calendarht border-0">
            <Calendar
              calendarParam={calendarParam}
              currentDay={this.state.currentDay}
              calendarViewType={this.state.calendarViewType}
              addVisitToogleHandler={this.addVisitToogleHandler}
              viewVisitHandler={this.visitDetailViewModal}
              calendarRecords={this.state.calendarRecords}
              visitStatusOption={visitStatusOption}
              isCalendarWidgetDate={this.state.isCalendarWidgetDate}
              updateTableDataGrid={{}}
              updateTableDataGridActivites={() => {}}
              selectedFields={[]}
              isLoaded={false}
              randId={1}
              showMoreDetailsDataHandler={() => {}}
              showActivitesHandler={() => {}}
              calendarActionHandler={this.calendarActionHandler}
            />
          </div>
        </div>
      </div>
      
      </>
    );
  }


 
}

export default WithRoutify( withReduxState(Appointments));
