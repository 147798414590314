// src/services/userService.js
import http from './http';
import { wOrgIdMiddleware } from '../utils/commonUtils';

const VaccineService = {
    getVaccineByVisitId: wOrgIdMiddleware(async (url,visitId,query={}) => {
      const queryString = new URLSearchParams(query).toString();
      try {
        
        const response = await http.GET(`${url}vaccines-by-visit/${visitId}?${queryString}`);
        
          if(response.code===200 && response.data){
            return response.data;
          }
        
        return null;
      } catch (error) {
        console.error('Error fetching user:', error);
        // throw error;
      }
    }),
    getVaccineId: wOrgIdMiddleware(async (url,id) => {
      try {
        const response = await http.GET(`${url}vaccines/${id}`);
        
          if(response.code===200 && response.data){
            return response.data;
          }
        
        return null;
      } catch (error) {
        console.error('Error fetching user:', error);
        // throw error;
      }
    }),
    //Save Vaccine
    saveVaccine:wOrgIdMiddleware(async (url='',data,)=>{
      try {
        const response = await http.POST(`${url}vaccines`,data)
        // console.log('response',response);
          
            if((response.code===200 || response.code===201) && response.data){
              return response.data;
            }else{
              return response;
            }
          
      } catch (error) {
        console.error('Error fetching user:', error);
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        
        // throw error;
      }  
    },true),
     updateVaccineById:wOrgIdMiddleware(async (url,visitId,data)=>{
      try {
        const response = await http.PUT(`${url}vaccines/${visitId}`,data);
        
          if(response.code===200 && response.data){
            // UserService.storeProfile(resData.data);
           
            return response.data;
          }else{
            return null;
          }
        
      } catch (error) {
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        console.error('Error fetching user:', error);
        throw error;
      }  
    }),
    //deleteVaccination
    deleteVaccination:wOrgIdMiddleware(async (url='',id)=>{
        try {
          const response = await http.DELETE(`${url}vaccines/${id}`);
          
            if((response.code===200 || response.code===201)  && response.data){
              return response.data;
            }else{
              return null;
            }
          
        } catch (error) {
          if(error.status){
            const resData = error.data;
            if(resData.code && resData.data){
                return resData;
            }
          }
          console.error('Error fetching user:', error);
          throw error;
        }  
      }),  
  }
  
  export default VaccineService;