import React, { useEffect, useState } from "react";
import FormInputs from "../../../../../../components/UI/FormInputs";
import EmployeeService from "../../../../../../services/EmployeeService";
import { isSet } from "../../../../../../utils/commonUtils";
import TableGrid from "../../../../../../components/UI/TableGrid";
import WithRoutify from "../../../../../../hoc/WithRoutify";
import SectionLoader from "../../../../../../components/UI/SectionLoader";
import PatientChartsService from "../../../../../../services/PatientChartsService";
import FileUpload from "../../../../../../components/UI/FormInputs/FileUpload";
import { isObject } from "../../../../../../utils/commonUtils";
import Button from "../../../../../../components/UI/Buttons/Button";
import PERMISSIONS from "../../../../../../utils/AclPermissions";
// import LookupService from "../../../../../../services/LookupService";
// import { DOCUMENT_TYPE } from "../../../../../../utils/constant";

const ChartDocuments = (props) => {
    const [uploadedFile,    setUploadedFile] = useState(null);
    const [isLoading,setLoaded] = useState(false);
    const [documentsList, setDocumentsList] = useState({ tableHeaders: [], tableRows: { data: [] } });
    const {employeeDetails,visitDetails,readOnly}=props;
    const [validation, setValidation] = useState({});
    // const [documentFormData,setDocumentFormData]=useState({});
    // const [lookupData,setLookupData]=useState({})
    useEffect(()=>{
        fetchAdditionalDocumentList();
    },[]);

    useEffect(()=>{
        setValidation({});
    },[uploadedFile])

    const fetchAdditionalDocumentList = async () => {
        try {
            const additionalDocuments =await PatientChartsService.getPatientChart(employeeDetails.company.company_uuid,props.mappingId);
            const tableData = additionalDocuments.documents.map((item) => ({
                rowId: item.additional_document_id,
                data: {
                    name: item.snap_file_manager,
                    // document_type:item.document_type,
                    updatedAt: item.snap_file_manager.updated_at,
                    action: [{
                        className: 'btn btn-icon btn-transparent-dark',
                        iconType: 'Remove',
                        acl: 'employee-delete',
                        title: 'Delete',
                        isDelete:true,
                        clickHandler: () => deleteHandler(item.additional_document_id),
                    }]
                }
            }));

            setDocumentsList({ tableHeaders: getTableHeaders(), tableRows: { data: tableData } });
        } catch (error) {
            console.error("Error fetching additional documents:", error);
        }
    }
    // const fetchLookupsData = async () => {
    //     try {
    //         const [documentTypeData] = await Promise.all([
    //             LookupService.getLookupByKey(DOCUMENT_TYPE),
    //         ]);
    //         let documenTypeOptions = [{ label: '-None-', value: '' }];
    //         documentTypeData.forEach((item) => {
    //             documenTypeOptions.push({
    //                 label: item.value,
    //                 value: item.value
    //             });
    //         });
    //         console.log('document_type_data',documenTypeOptions);
    //         setLookupData({...lookupData,['document_type']:documenTypeOptions});
    //     } catch (e) {

    //     }
    // }

    const getTableHeaders = () => [
        { label: 'File Name', key: 'name', type: 'FileType' },
        // { label: 'Type', key: 'document_type', type: 'SelectList' },
        { label: 'Updated Date', key: 'updatedAt', type: '' },
        { label: 'Action', key: 'action', type: 'Actions' },
        // Add more columns as needed
    ];

    const submiHandler = async () => {
        let updateValidation = { ...validation };
        setLoaded(true);
        try {
            const formData = new FormData();
            formData.append('file', uploadedFile);
            formData.append('employee_id', employeeDetails.employee_id);
            formData.append('visit_id', visitDetails.visit_id);
            formData.append('visit_mapping_id', props.mappingId);
            formData.append('company_id',visitDetails?.company?.company_uuid);
            formData.append('organization_uuid',visitDetails?.company?.organization_uuid);
            formData.append('document_type','CHART_DOCUMENT')
            const data = await EmployeeService.addAdditionalDocument(formData);
            if (data.status && data.status == "ERROR") {
                if (isObject(data.data)) {
                  for (let key in data.data) {
                    updateValidation = {
                      ...updateValidation,
                      [key]: data.data[key].join(',')
                    }
                  }
                } else {
                  props.Toast.error(data.data);
                }
    
                if (Object.keys(updateValidation).length > 0) {
                  setValidation(updateValidation);
                }
            } else {
                setUploadedFile('');
                fetchAdditionalDocumentList();
                setValidation({});
                let messages=`Document Added Successfully`;
                props.Toast.success(messages);
            }
            setLoaded(false);
            
        } catch (error) {
            console.error("Error submitting document:", error);
            setLoaded(false);
        }
    }
    // const inputFields = [
    //     {
    //         type: 'SelectList',
    //         options:lookupData.document_type ||[],
    //         label: 'Document Type',
    //         className: 'form-control form-control-solid',
    //         name: 'document_type'
    //     },
    // ];

    const deleteHandler = async (additionalDocId) => {
        
            setLoaded(true);
            try {
                await EmployeeService.deleteAdditionalDocument(additionalDocId);
                fetchAdditionalDocumentList();
                props.Toast.success('Document Deleted Successfully');
                setLoaded(false);
            } catch (error) {
                setLoaded(false);
                console.error("Error deleting document:", error);
            }
            setValidation({});
    }

    useEffect(() => {
        fetchAdditionalDocumentList();
    }, [props.formData.additional_documents]);
    // useEffect(()=>{
    //     fetchLookupsData();
    // },[]);

    // const changeHandler=(val,fieldName)=>{
    // setDocumentFormData(prevState => ({
    //           ...prevState.labformData, [fieldName]: val }
    //     ));
    // }

    return (
        <div className="sh_innerPortview">
            <div className="row">
                <div className="col-12 col-lg-4">
                <div className="sh_cardBox h-auto shadow-none border">
                    <div className="sh_cardBody p-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                <label className="text-sm text-truncate mb-0 opacity-75">{"Document"}</label>
                                    <FormInputs
                                        type='FileUpload'
                                        title="Field Document"
                                        name='file'
                                        className=''
                                        // readOnly={readOnly}
                                        value={isSet(uploadedFile,'')}
                                        changeHandler={(val) => setUploadedFile(val)}
                                    />
                                    {isSet(validation['file'], '') !== '' ? <span className="text-danger m-1">
                        {isSet(validation['file'], '')}</span> : ''}
                                </div>
                            </div>
                            {/* {inputFields.map((item, index) => (
                                    <div className="col-12" key={index}>
                                        <div className="form-group">
                                            <label className="text-sm text-truncate mb-0 opacity-75">{item.label}</label>
                                            <FormInputs
                                                {...item}
                                                value={isSet(documentFormData[item.name], '')}
                                                changeHandler={(val) => changeHandler(val, item.name)}
                                            />
                                        </div>
                                    </div>
                                ))} */}
                        </div>
                    </div>
                    <div className="sh_cardFooter justify-content-center">
                        <Button className="btn btn-primary btn-sm text-sm" acl="visits-edit" clickHandler={()=>{submiHandler()}}> Add </Button>
                    </div>
                </div>
                </div>
                <div className="col-12 col-lg-8">
                
                <div className="sh_cardBox h-auto shadow-none border">
                    <div className="sh_cardHeader">
                        <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                            <h6 className="text-md mb-0">Document List </h6>
                        </div>
                    </div>
                    <div className="sh_cardBody p-0">
                        <SectionLoader isLoading={isLoading}/>
                        <TableGrid {...documentsList} />
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default WithRoutify(ChartDocuments);
