import { Route, Routes } from "react-router-dom";
import Wrap from "../../../hoc/Wrap";
import VaccineAdministratorList from "./VaccineAdministratorList";

const VaccineAdministrationContainer = () => {

  return (
    <Wrap>  
      <Routes>
      <Route path="/" key={0}  element={<VaccineAdministratorList/>} />
      </Routes>
  
     
        
    </Wrap>
  );
};






export default VaccineAdministrationContainer;
