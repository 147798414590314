import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import LabList from "./LabList";
const LabContainer = () => {

  return (
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<LabList/>} />
      </Routes>     
        
    </Wrap>
  );
};






export default LabContainer;
