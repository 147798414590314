import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import ProviderList from "./ProviderList";

const ProviderContainer = () => {

  return (
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<ProviderList/>} />
      </Routes>
  
     
        
    </Wrap>
  );
};
export default ProviderContainer;
