import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/AuthService";
import { activeProfileDetails, removeProfile } from "../../../store/SessionSlice";
import { _t } from "../../../utils/i18nUtils";
import Icons from "../../UI/Icons";
import { useDispatch, useSelector } from 'react-redux';
import WithRoutify from "../../../hoc/WithRoutify";
import { Input } from "@mui/material";

const Header = ({ Toast, Loader, ...props }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const profileDetails = useSelector(activeProfileDetails);
    const removeProfileAsync = (key) => {
        return async (dispatch, getState) => {
            const sessionState = getState().session.profileDetails;
            try {
                if (Object.keys(sessionState).length > 1) {
                    await AuthService.impersonateLogout();
                }
                dispatch(removeProfile({ key }));
            } catch (e) {

            }
            // Use getState() to check if the state has been updated
            const updatedState = getState().session.profileDetails;
            const keys = Object.keys(updatedState);
            try {
                // Asynchronous operations, such as AuthService.logout(), go here
                if (keys.length === 0) {
                    await AuthService.logout();
                    navigate('/login');
                } else {
                    Toast.success(profileDetails.name + ' logged-out successfully');
                    navigate("/organisation");
                }
            } catch (error) {
                await AuthService.logout();
                // Handle errors if necessary
            }
        };
    };
    const onlogoutHandler = () => {
        try {
            dispatch(removeProfileAsync(AuthService.getProfileId()));
        } catch (error) {
            // Handle errors if necessary
        }
    };
    return (
        <>
            <nav className="navbar navbar-expand navbar-light topbar mb-4 fixed-top">
            {/* <ul className="form-group ml-auto mb-0">
                  <>
                  <input className="form-control globalesearch form-control-solid "
                  placeholder="Search"
                  type="search" />
                  </>
                </ul> */}
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                    </li>
                    <li className="nav-item ">
                        <a className="nav-link " href="#!" id="userDropdown" role="button" aria-haspopup="true" aria-expanded="false">
                            <a className="" href="#!" >

                                <Icons type={'logout'} />
                                <span className="pl-2" onClick={() => onlogoutHandler()}> {_t('Log out')} </span>
                            </a>
                        </a>

                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                        </div>
                    </li>
                </ul>
            </nav>
        </>
    );
}
export default WithRoutify(Header);