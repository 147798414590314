import React, { Component } from "react";
import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";
import CompanyService from "../../services/CompanyService";
import {

  isObject,
  isSet,
  toQueryString,
} from "../../utils/commonUtils";
import WithRoutify from "../../hoc/WithRoutify";
import { OK } from "../../utils/constant";
import withReduxState from "../../hoc/wReduxState";
import WithRouter from "../../hoc/WithRouter";
import TableSkeleton from "../../hoc/TableSkeleton";
import AddCpt from "./AddCpt";
import CptService from "../../services/CptService";
import PERMISSIONS from "../../utils/AclPermissions";

class CptList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      { label: "code", key: "code", type: "", inputType: "TextInput", sortName: "code", },
      { label: "description", key: "description", type: "", inputType: "TextInput" },
      { label: "Action", key: "action", type: "Actions" },
      //  more columns as needed
    ];
    this.state = {
      filters: {
        search_query: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          status: 1000,
          data: [],
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "code",
          sort_order: "asc",
          search: {},
        },
      },
      formDataInEdit: {},
      activeEditId: null,
      organizationOption: [],
      clinicOptions: [],
      // Your state variables go here
      isModelOpen: false,
      organization_id: "",
    };
  }

  componentDidMount() {
    // let userProfile

    const fetchList = async () => {
      await this.fetchCptList();
    };
    fetchList();
    // Code to run after the component has mounted
  }

  componentDidUpdate = (prevProps, prevState) => {
    // console.log('logs',this.props);
    // Code to run after the component has updated
  };

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  render() {
    const { tableRecords, filters, isModelOpen, activeEditId } = this.state;
    return (

      <>
        {isModelOpen ? (
          <AddCpt
            modelTitle={`${this.state.activeEditId ? 'Update' : 'Add'} CPT`}
            isOpen={this.state.isModelOpen}
            fetchCptList={this.fetchCptList}
            addFormHandler={this.addFormHandler}
            activeEditId={activeEditId}
          />
        ) : (
          <></>
        )}
        <TableSkeleton
          tableRecords={tableRecords}
          addFormHandler={this.addFormHandler}
          acl={"any"}
          content={"CPT"}
        >
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                <h6 class="text-lg f-500 mb-0">CPT List </h6>
                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div className="col-12 px-1 d-flex">
                      <div className="input-group input-group-joined input-group-solid">
                        <FormInputs
                          placeholder="CPT"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="btn-group pl-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="addAction">
                    <div className="btn-group">
                      <Buttons
                        clickHandler={() => { this.addFormHandler(); this.setState({ activeEditId: '' }) }}
                        className="btn btn-primary"
                        acl={PERMISSIONS.CPT.CREATE}
                        label={"Add"}
                      />
                      <button
                        className="btn btn-outline-secondary dropdown no-arrow"
                        data-bs-toggle="dropdown"
                      >
                        <span className="dropdown-toggle">
                          <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </span>
                        <div
                          className="dropdown-menu dropdown-menu-right shadow"
                          aria-labelledby="userDropdown"
                        >
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Group Edit
                          </a>
                          <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Grid Edit
                          </a>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                addFormHandler={() => this.addFormHandler()}
                hasPagination={true}
                fetchTableRecords={(filters) =>
                  this.fetchCptList(filters)
                }
                {...tableRecords}
              />
            </div>
          </div>
        </TableSkeleton>
      </>
    );
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const queryfilters = {
      ...tableRecords.pagination,
      page:1,
      search: filters,
    };
    this.fetchCptList(queryfilters);
  };
  clearFiltersHandler = async () => {
    await this.setState({
      filters: {},
    });
    this.submitFiltersHandler();
  };
  fetchCptList = async (query = {}) => {
    const { tableRecords } = this.state;
    try {
      const queryString = toQueryString({
        ...tableRecords.pagination,
        ...query,
      });
      this.props.Loader(true);
      const response = await CptService.getCptList(queryString);
      console.log('response', response);

      const tableData = response.data.data.map((cpt, companyIndex) => ({
        companyIndex: companyIndex,
        isHeading: false,
        rowId: cpt.cpt_id,
        data: {
          isActive: false,
          code: cpt.code,
          description: cpt.description,
          active: "Yes",
          action: [
            {
              className: "btn btn-icon btn-transparent-dark",
              iconType: "Search",
              title: "View",
              type: "GridEdit",
              acl: PERMISSIONS.CPT.EDIT,
              clickHandler: (rowId, data) =>
                this.editHandler(rowId, data),
            },
            {
              className: "btn btn-icon btn-transparent-dark",
              iconType: "Remove",
              acl: PERMISSIONS.CPT.DELETE,
              title: "Delete",
              isDelete: true,
              clickHandler: () => this.deleteHandler(cpt.cpt_id),
            },
          ],
        },
      }));
      // console.log('tableData',response?.data);
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            type: "default",
            status: 200,
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: response?.data?.total,
          },
        },
      }));
    } catch (err) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200,
          },
        },
      });
      console.log("DataIsse");
      // console.error("Error fetching companies:", err);
    } finally {
      this.props.Loader(false);
    }
  };

  deleteHandler = async (id) => {
    CptService.deleteCpt(id)
      .then((res) => {
        this.fetchCptList();
        this.props.Toast.success("CPT Deleted Succesfully");
      })
      .catch((err) => {
        //
      })
      .finally(() => {
        //
      });
  };
  editHandler = (rowId, data,) => {
    this.setState({
      activeEditId: rowId,
    }, this.addFormHandler())
  };
  checkBoxchangeHandler = (value, e) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}
export default WithRouter(withReduxState(WithRoutify(CptList)));
