import React from "react";
import { formatPhoneNumberUS, isSet } from "../../../../../../utils/commonUtils";
import Icons from "../../../../../../components/UI/Icons";

const CompanyDetails = (props) => {
    const {companyDetails} = props;
    const details = [{
        label:'Name',
        value:companyDetails.name,
    },
    {
        label:'Location',
        value:companyDetails.address,
    }
    ,
    {
        label:'Phone',
        value:formatPhoneNumberUS(companyDetails.phone),
    },
    {
        label:'Results to Employee',
        value:companyDetails.send_physical_results_to_employees?"Yes":"No",
    }
    // ,{
    //     label:'Medical Doctor',
    //     value:companyDetails.clinic.medical_doctor,
    // }
]

    return (
        <div className="sh_cardBox h-auto sh_companydetailwidget ">
            <div className="sh_cardHeader">
                <div className="d-flex justify-content-between align-items-center">
                <h6 className="text-md mb-0">
                    <span className="px-1">
                    <Icons type="companydetails" />
                    </span>
                    Company Details
              </h6>
                    {/* <button className="btn btn-xs text-xs text-info">View More</button> */}
                </div>
            </div>
            <div className="sh_cardBody px-3 py-1">
                <div className="row">
                    {details.map((item,itemIndex)=>{
                        return(
                            <div className="col-md-12" key={itemIndex}>
                                <div className="form-group">
                                    <div className="row" style={{borderBottom: '1px solid #f2f2f4'}}>
                                        <div className="col-6">
                                    <label className="f-400 text-sm text-truncate mb-0 opacity-70">{item.label}</label>
                                     </div>
                                     <div className="col-6">
                                    <h6 className="font-weight-bold text-sm text-truncate" title={item.value}>{isSet(item.value,'-')}</h6>
                                </div>
                                </div>
                                </div>
                            </div>
                        )
                    })}
                    
                    
                </div>
            </div>
        </div>
    )
}

export default CompanyDetails;
