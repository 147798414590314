import React from "react";
import { Button, Modal } from 'react-bootstrap';
import WithRoutify from "../../hoc/WithRoutify";
import {stripHtmlTags } from "../../utils/commonUtils";
import { Link } from "react-router-dom";
import Icons from "../../components/UI/Icons";

const MessageModal = (props) => {
  const { addFormHandler, messageLog } = props;
  const { message } = messageLog;
  console.log('message',message);
  

  return (
    <>
      <Modal
        show={true}
        onHide={addFormHandler}
        dialogClassName="hResponsive"
        size="lg"
        contentClassName="h-100"
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title style={{ margin: '0 auto' }}>
              <h6 className="text-lg mb-0 font-weight-bold">Message</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {message ? (
              <>
                {/* <div className="sh_logoIcon">
                  <div className="sidebar-brand-icon">
                    <Link to="/">
                      <Icons type={`Logo`} />
                    </Link>

                  </div>
                </div> */}
                <div className="row" >
                  <div className="col-12" style={{ overflowWrap: "break-word" }}>
                     <div dangerouslySetInnerHTML={{ __html: message }} />
                  </div>
                </div>
              </>
            ) : (
              <p>No Message Found</p>
            )}
            {/* {message ? (
              <p>{message}</p>
            ) : (
              <p>No Message Found</p>
            )} */}
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="primary"
              onClick={() => addFormHandler()}
            >
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default WithRoutify(MessageModal);
