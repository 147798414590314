import { Route, Routes } from 'react-router-dom';
import Wrap from '../../hoc/Wrap';
import BilllerLabsReport from './BilllerLabsReport';
// import PhysicalAndDrugScreen from './PhysicalAndDrugScreen/PhysicalAndDrugScreen';

const ReportsContainer = () => {

  return (
    <Wrap>
      <Routes>
        <Route path="/" key={0} element={<BilllerLabsReport />} />
        {/* <Route path="/physical-drug-screens" key={0} element={<PhysicalAndDrugScreen />} /> */}

      </Routes>



    </Wrap>
  );
};






export default ReportsContainer;