// src/services/userService.js
import http from './http';
import { OK } from '../utils/constant';
import { wOrgIdMiddleware } from '../utils/commonUtils';
// import { useDispatch } from 'react-redux';
// import {setProfileDetails} from '../store/ProfileSlice';

const VisitsService = {
    getVisitsList: wOrgIdMiddleware(async (url,query={}) => {
      const queryString = new URLSearchParams(query).toString();
      //?${queryString}
      try {
        
        const response = await http.GET(`${url}employee-visits?${queryString}`);
        
          if(response.code===200 && response.data){
            return response.data;
          }
        
        return null;
      } catch (error) {
        console.error('Error fetching user:', error);
        // throw error;
      }
    }),
    getVisits:wOrgIdMiddleware(async(url,visitId)=>{
      try {
        // const orgId       = OrganizationService.getOrgId();
        const response    = await http.GET(`${url}employee-visits/${visitId}`);
          if (response.code===OK && response.data) {
            return response.data;
          }
        } catch (error) {
          // console.error('Error fetching company:', error);
          if(error.status){
            const resData = error.data;
            if(resData.code && resData.data){
                return resData;
            }
          }
          
          // throw error;
        } 
  }),
  getActivityByActivityIds:wOrgIdMiddleware(async(url,activityIds=[],VisitsId)=>{
    try {
      // const orgId = OrganizationService.getOrgId();
      const response = await http.POST(`${url}employee-visits-by-activity-id/${VisitsId}`,activityIds);
      
          if (response.code===OK) {
            return response.data;
          }
        
      } catch (error) {
        // console.error('Error fetching company:', error);
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        
        // throw error;
      } 
  }),
  
    //Save user
    saveVisits:wOrgIdMiddleware(async (url='',data,headers={})=>{
      try {
        const response = await http.POST(`${url}employee-visits`,data,headers)
        // console.log('response',response);
          
            if((response.code===200 || response.code===201) && response.data){
              return response.data;
            }else{
              return response;
            }
          
      } catch (error) {
        console.error('Error fetching user:', error);
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        
        // throw error;
      }  
    },true),

     //Save user
     updateVisits:wOrgIdMiddleware(async (url,visitId,data)=>{
      try {
        const response = await http.PUT(`${url}employee-visits/${visitId}`,data);
        return response;
      } catch (error) {
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        console.error('Error fetching user:', error);
        throw error;
      }  
    }),
    //Delete Visits
    deleteVisits:wOrgIdMiddleware(async (url='',  visitId)=>{
        try {
          const response = await http.DELETE(`${url}employee-visits/${visitId}`);
          
            if((response?.code===200 || response?.code===201)  && response?.data){
              return response.data;
            }else{
              return response;
            }
        } catch (error) {
          if(error.status){
            const resData = error.data;
            if(resData.code && resData.data){
                return resData;
            }
          }
          console.error('Error fetching user:', error);
          throw error;
        }  
      }),
      addPhysicalAttachements:async (data,headers={
        'Content-Type': 'multipart/form-data'
      })=>{
        try {
          
          const response = await http.POST(`employee-visits-documents`,data,headers)
          // console.log('response',response);
            
              if((response.code===200 || response.code===201) && response.data){
                return response.data;
              }else{
                return response;
              }
            
        } catch (error) {
          console.error('Error fetching user:', error);
          if(error.status){
            const resData = error.data;
            if(resData.code && resData.data){
                return resData;
            }
          }
          
          // throw error;
        }  
      },
      getPhysicalAttachementList: async (visitId) => {
        // const queryString = new URLSearchParams(query).toString();
        // console.log('queryString',queryString);
        //?${queryString}
        try {
          const response = await http.GET(`employee-visits-documents-by-visits/${visitId}`);
          
            return response
          
        } catch (error) {
          console.error('Error fetching user:', error);
          // throw error;
        }
      },
      deletePhysicalAttachement:async (AttachmentId)=>{
        try {
         await http.DELETE(`employee-visits-documents/${AttachmentId}`);
          
        } catch (error) {
          if(error.status){
            const resData = error.data;
            if(resData.code && resData.data){
                return resData;
            }
          }
          console.error('Error fetching user:', error);
          throw error;
        }  
      },
       //Save user
    saveActivityInChartVisits:wOrgIdMiddleware(async (url='',data,headers={})=>{
      try {
        const response = await http.POST(`${url}add-activity-visit`,data,headers)
        // console.log('response',response);
          
            if((response.code===200 || response.code===201) && response.data){
              return response.data;
            }else{
              return response;
            }
          
      } catch (error) {
        console.error('Error fetching user:', error);
        if(error.status){
          const resData = error.data; 
          if(resData.code && resData.data){ 
            //delete-activity-visit
              return resData;
          }
        }
        
        // throw error;
      }  
    },true),
     //Delete Visits
     deleteActivityInChartInVisits:wOrgIdMiddleware(async (url='',  mappingId)=>{
      try {
        const response = await http.DELETE(`${url}delete-activity-visit/${mappingId}`);
        return response;    
      } catch (error) {
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        console.error('Error fetching user:', error);
        throw error;
      }  
    }),
    getVisitsHistory:wOrgIdMiddleware(async(url,query={})=>{
      const queryString = new URLSearchParams(query).toString();
      try {
        // const orgId       = OrganizationService.getOrgId();
        const response    = await http.GET(`${url}appointment-history?`+queryString);
        
            if (response.code===OK) {
              return response.data;
            }
          
        } catch (error) {
          // console.error('Error fetching company:', error);
          if(error.status){
            const resData = error.data;
            if(resData.code && resData.data){
                return resData;
            }
          }
          
          // throw error;
        } 
  }),
  getWaitingAndDischargeHistory:wOrgIdMiddleware(async(url,query={})=>{
    const queryString = new URLSearchParams(query).toString();
    try {
      const response    = await http.GET(`${url}get-currentdate-visits?`+queryString);
      
          if (response.code===OK) {
            return response.data;
          }
        
      } catch (error) {
        // console.error('Error fetching company:', error);
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        // throw error;
      } 
  }),
  getFutureDateAppointments:wOrgIdMiddleware(async(url,query={})=>{
    const queryString = new URLSearchParams(query).toString();
    try {
      const response    = await http.GET(`${url}get-future-appointments?`+queryString);
      
          if (response.code===OK) {
            return response.data;
          }
        
      } catch (error) {
        // console.error('Error fetching company:', error);
        if(error.status){
          const resData = error.data;
          if(resData.code && resData.data){
              return resData;
          }
        }
        // throw error;
      } 
  }),
   //Save user
   updateVisitStatus:wOrgIdMiddleware(async (url,visitId,data)=>{
    try {
      const response = await http.PUT(`${url}update-visit-status/${visitId}`,data);
      
        if(response.code===200 && response.data){
          // UserService.storeProfile(resData.data);
         
          return response.data;
        }else{
          return response;
        }
    } catch (error) {
      if(error.status){
        const resData = error.data;
        if(resData.code && resData.data){
            return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }  
  },true),
  createFollowUp:wOrgIdMiddleware(async (url,data)=>{
    try {
      const response = await http.POST(`${url}employee-visits-followup/`,data);
      return response;
      
    } catch (error) {
      if(error.status){
        const resData = error.data;
        if(resData.code && resData.data){
            return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }  
  }),
  savePhysicalActivityForVisit:wOrgIdMiddleware(async (url,data)=>{
    try {
      const response = await http.POST(`${url}add-physical-activities`,data);
      
        if(response.code===200 && response.data){
          // UserService.storeProfile(resData.data);
         
          return response.data;
        }else{
          return response;
        }
      
    } catch (error) {
      if(error.status){
        const resData = error.data;
        if(resData.code && resData.data){
            return resData;
        }
      }
      console.error('Error fetching user:', error);
      throw error;
    }  
  }),
 
  getVisitTimeLine: wOrgIdMiddleware(async (url,visit_id={}) => {
    try {
      const response = await http.GET(`${url}get-visit-timeline/${visit_id}`);
     
        if(response.code===200 && response.data){
          return response.data;
        }
      
      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      // throw error;
    }
  }),
  getEmailPdfResult:async(data)=>{
    const response=await http.POST(`/email-pdf-result`,data);
    return response;
  } 
    
  }
  
  export default VisitsService;