// src/services/userService.js
import http from './http';
import { wOrgIdMiddleware } from '../utils/commonUtils';
import { CREATED, OK } from '../utils/constant';

const ClinicService = {

  //get all clinics based on organization 
  getClinicList: wOrgIdMiddleware(async (url, query = {}) => {
    const queryString = new URLSearchParams(query).toString();
    try {
      const response = await http.GET(`${url}clinics?${queryString}`);
      if (response.code === 200 && response.data) {
        return response.data;
      }
      else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching Clinic:', error);
    }
  }, true),

  //Save Clinic
  saveClinic: async (url , data = {}) => {
    try {
      const response = await http.POST(`organizations/${url}/clinics`, data)
      if ((response.code === OK || response.code === CREATED) && response.data) {
        return response.data;
      }
      else {
        return response;
      }
    } catch (error) {
      console.error('Error fetching Clinic:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
    }
  },

  //Update clinic By Id
  updateClinic: wOrgIdMiddleware(async (url, clinicId, data) => {
    try {
      const response = await http.PUT(`${url}clinics/${clinicId}`, data);
      if (response.code === OK && response.data) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching clinic:', error);
    }
  }),
  //Delete clinic by id
  deleteClinic: wOrgIdMiddleware(async (url = '', clinicId) => {
    try {
      const response = await http.DELETE(`${url}clinics/${clinicId}`);
      console.log("clinic deltee responde",response);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching Clinic:', error);
    }
  }),
  //get clinioc by id 
  getClinic: wOrgIdMiddleware(async (url, clinicId) => {
    try {
      const response = await http.GET(`${url}clinics/${clinicId}`);
      if (response.code === OK) {
        return response.data;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
    }
  }),
  saveSalesRep: wOrgIdMiddleware(async (url,data) => {
    try {
      const response = await http.POST(`${url}sales_rep`,data);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
    }
  }),

  updateSalesRep: wOrgIdMiddleware(async (url, id, data) => {
    try {
      const response = await http.PUT(`${url}sales_rep/${id}`, data);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching clinic:', error);
    }
  }),
  getSalesRep: wOrgIdMiddleware(async (url, id, data) => {
    try {
      const response = await http.GET(`${url}sales_rep`);
      return response?.data;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching clinic:', error);
    }
  },true),
  deleteSalesRep: wOrgIdMiddleware(async (url = '', id) => {
    try {
      const response = await http.DELETE(`${url}sales_rep/${id}`);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching Clinic:', error);
    }
  }),
};

export default ClinicService;