import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import FormInputs from "../../components/UI/FormInputs";
import WithRoutify from "../../hoc/WithRoutify";
import { isObject, isSet } from "../../utils/commonUtils";
import TableGrid from "../../components/UI/TableGrid";
import Buttons from "../../components/UI/Buttons";
import PERMISSIONS from "../../utils/AclPermissions";
import withReduxState from "../../hoc/wReduxState";
import DropDownService from "../../services/DropDownService";
import { CONFLICT } from "../../utils/constant";
import ClinicService from "../../services/ClinicService";

class AddSalesRep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      validation: {},
      formDataInEdit: null,
      activeEditId: null,
      tableRecords: {
        tableHeaders: [
          { label: 'Sales Rep', key: 'sales_rep', inputType: 'TextInput' },
          { label: 'Clinic', key: 'clinic_id', inputType: 'SelectList' },
          { label: 'Action', key: 'action', type: 'Actions' },
        ],
        tableRows: {
          type: "default",
          data: []
        },
      },
      clinicOptions: []
    };
  }

  componentDidMount() {
    this.fetchSalesRepList();
    this.fetchClinicOptions();
  }



  render() {
    const { } = this.props;
    const { formData, validation, formDataInEdit, activeEditId, tableRecords } = this.state;
    const inputFields = this.getInputFields();
    return (
      <>
        <Modal show={true} onHide={this.modelCloseHandler} size="xl" dialogClassName='hResponsive'
          keyboard={false}
          contentClassName='h-100' backdrop="static">
          <div className="sh_modalBox">
            <Modal.Header closeButton>
              <Modal.Title>
                <h6 className="font-weight-bold text-lg mb-0">{`Add Sales Rep`}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="sh_innerPortview row d-flex">
                <div class="col-lg-5 col-12">
                  <div class="sh_cardBox h-auto shadow-none border">
                    <div class="sh_cardBody p-3">
                      <div class="row">
                        {inputFields &&
                          inputFields.map((field, index) => (
                            <div className={`col-12`} key={index}>
                              <div className="form-group">
                                <label className="text-gray-900 text-md opacity-75">
                                  {field.label}{" "}
                                </label>
                                <FormInputs
                                  {...field}
                                  value={isSet(formData[field.name], "")}
                                  changeHandler={(value, e) => this.changeHandler(value, field.name, e)}
                                />
                                {isSet(validation[field.name], "") !== "" ? (
                                  <span className="text-danger m-1">
                                    {isSet(validation[field.name], "")}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div class="sh_cardFooter justify-content-center">
                      <Buttons
                        type='Single'
                        className="btn btn-primary"
                        clickHandler={this.formSubmitHandler}
                        label={"Add"}
                        acl={"any"}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-7 col-12">
                  <div class="sh_cardBox h-auto shadow-none border">
                    <div class="sh_cardHeader">
                      <div class="form-group d-flex mb-0 justify-content-between align-items-center">
                        <h6 class="text-md mb-0">{`Sales Rep List`}</h6>
                      </div>
                    </div>
                    <div class="sh_cardBody p-0 tabletextbox">
                      <TableGrid
                        {...tableRecords}
                        gridEditProps={{
                          formDataInEdit: formDataInEdit,
                          activeEditId: activeEditId,
                          onChangeFormDataInEdit: this.onChangeFormDataInEdit,
                        }} />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </>
    );
  }

  changeHandler = (val, fieldName) => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [fieldName]: val,
      },
      validation: {
        ...prevState.validation,
        [fieldName]: ''
      }

    }));
  };

  formSubmitHandler = async () => {
    const { formData } = this.state;
    const { Loader, Toast } = this.props;
    let updateValidation = { ...this.state.validation };
    Loader(true);
    let response = await ClinicService.saveSalesRep(formData);
    if (response.status && response.status == "ERROR") {
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(","),
          };
        }
      }
      if (Object.keys(updateValidation).length > 0) {
        this.setState({ validation: updateValidation });
      }
    }
    else {
      Toast.success("Sales Rep Added Successfully");
    }
    this.fetchSalesRepList();
    this.setState({ formData: {} });
    Loader(false);
  };

  deleteHandler = async(id) => {
    const { Toast, Loader } = this.props;
    this.setState({ validation: '' });
    Loader(true);
    Loader(false);
    const response= await ClinicService.deleteSalesRep(id);
    if (response && response.code == CONFLICT && response.data) {
      Toast.error(response.data);
    }
    else {
      Toast.success("Sales Rep Deleted Successfully");
      this.fetchSalesRepList();
    }


  }
  
  editHandler = (rowId, data) => {
    
    const { clinicOptions } = this.state;
    this.state.tableRecords.tableHeaders.forEach((itm, ind) => {
      if (itm.key == 'clinic_id') {
        itm.options = this.state.clinicOptions;
      }
    })
    this.setState({ formDataInEdit: data, activeEditId: rowId });
  };

  updateHandler = async () => {
    const { formDataInEdit } = this.state;
    const { labData, Loader } = this.props;
    this.setState({ validation: '' });
    Loader(true);
    let payload = {
      ...formDataInEdit
    };
    let response = await ClinicService.updateSalesRep(this.state.activeEditId,payload)
    Loader(false);
    if (response.status == "ERROR" && response.data) {
      if (isObject(response.data)) {
        let validaitons = {};
        for (let key in response.data) {
          validaitons = { ...validaitons, [key]: response.data[key][0] };
        }
        this.setState((prevState) => ({
          formDataInEdit: {
            ...prevState.formDataInEdit,
            validation: validaitons,
          },
        }));
      }

    }
    this.props.Toast.success("Sales Rep Updated Successfully");
    this.editHandler(null, {});
    await this.fetchSalesRepList();
  };

  fetchSalesRepList = async () => {
    const response = await ClinicService.getSalesRep();
    let tableData = [];
    response.map((itm, index) => {
      tableData = [
        ...tableData,
        {
          index: index,
          isHeading: false,
          rowId: itm.id,
          data: {
            sales_rep:itm.sales_rep	,
            clinic_id: itm?.clinics?.name,
            active: "Yes",
            action: [
              {
                className: "btn btn-icon btn-transparent-dark",
                iconType: "Edit",
                title: "Edit",
                type: "GridEdit",
                acl: PERMISSIONS.CLINIC.EDIT,
                clickHandler: (rowId, data) => this.editHandler(itm.id, {...data,clinic_id:itm.clinic_id}),
                updateHandler: () => this.updateHandler(),
                onChangeFormDataInEdit: (key, val) => this.onChangeFormDataInEdit(key, val),
              },
              {
                className: "btn btn-icon btn-transparent-dark",
                iconType: "Remove",
                acl: PERMISSIONS.CLINIC.DELETE,
                title: "Delete",
                isDelete: true,
                clickHandler: () => this.deleteHandler(itm.id),
              },
            ],
          },
        },
      ];
    });
    this.setState({
      tableRecords: {
        ...this.state.tableRecords,
        tableRows: {
          type: "default",
          data: tableData,
        }
      }
    }, () => { this.editHandler(null, {}, null); });
  };
  fetchClinicOptions = async () => {
    const response = await DropDownService.getClinicList(null);
    let clinicOptions = [{ label: '-Select-', value: '' }];
    // clinicOptions.push({ label: '-ALL-', value: 'ALL' });
    response.forEach((itm, ind) => {
      clinicOptions.push({ label: itm.name, value: itm.clinic_id });
    });
    this.setState({ clinicOptions: clinicOptions })
  };
  modelCloseHandler = () => {
    const { toogleHandler } = this.props;
    toogleHandler();
  };

  onChangeFormDataInEdit = (key, val) => {
    this.setState((prevState) => ({
      formDataInEdit: {
        ...prevState.formDataInEdit,
        [key]: val,
        validation: {
          ...prevState.formDataInEdit.validation,
          [key]: '', // Set validation empty
        },
      },
    }));
  };
  getInputFields = () => {
     const { formDataInEdit } = this.state;
    const inputFields = [
      {
        type: "SelectList",
        options: formDataInEdit?.clinicOptions || this.state.clinicOptions,
        label: "Clinic",
        placeholder: "value",
        className: "form-control form-control-solid",
        name: "clinic_id",
      },
      {
        type: "TextInput",
        label: "Sales Rep",
        placeholder: "Sales Rep",
        className: "form-control form-control-solid",
        name: "sales_rep",
      },
    ];
    return inputFields;
  }
}

export default withReduxState(WithRoutify(AddSalesRep));



