import React, { Component } from "react";
import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";
import {
  toQueryString,
} from "../../utils/commonUtils";
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import WithRouter from "../../hoc/WithRouter";
import AddConfiguration from "./AddConfiguration";
import PERMISSIONS from "../../utils/AclPermissions";
import ConfigurationService from "../../services/ConfigurationService";
import DirectoryView from "./DirectoryView";

class ConfigurationList extends Component {
  constructor(props) {
    super(props);
    const tableHeaders = [
      {
        label: "",
        name:"ALL", 
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      { label: "Name", key: "name", type: "", inputType: "TextInput", },
      { label: "Description", key: "description", type: "", inputType: "TextInput" },
      { label: "Action", key: "action", type: "Actions" },
    ];
    this.state = {
      filters: {
        search_query: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          status: 1000,
          data: [],
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "name",
          sort_order: "asc",
          search: {},
        },
      },
      formDataInEdit: {},
      activeEditId: null,
      organizationOption: [],
      clinicOptions: [],
      isModelOpen: false,
      organization_id: "",
      TreeModalOpen:false
    };
  }

  componentDidMount() {
    this.fetchConfigurationtList();
  }

  componentDidUpdate = (prevProps, prevState) => {
    // console.log('logs',this.props);
    // Code to run after the component has updated
  };

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  render() {
    const { tableRecords, filters, isModelOpen, activeEditId ,TreeModalOpen} = this.state;
    console.log(TreeModalOpen,';TreeModalOpen');
    
    return (

      <>
        {isModelOpen ? (
          <AddConfiguration
            isOpen={this.state.isModelOpen}
            fetchRecord={this.fetchConfigurationtList}
            addFormHandler={this.addFormHandler}
            activeEditId={activeEditId}
          />
        ) : (
          <></>
        )}
        {
          TreeModalOpen?
          <DirectoryView
          id={activeEditId}
          addFormHandler={this.directoryViewModalHandler}
          />
          :
          <></>
        }
       
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                <h6 class="text-lg f-500 mb-0">SFTP Configuration List</h6>
                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div className="col-12 px-1 d-flex">
                      <div className="input-group input-group-joined input-group-solid">
                        <FormInputs
                          placeholder="Search"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        />
                      </div>
                      <div className="btn-group pl-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="addAction">
                    <div className="btn-group">
                      <Buttons
                        clickHandler={() => { this.addFormHandler(); this.setState({ activeEditId: '' }) }}
                        className="btn btn-primary"
                        acl={PERMISSIONS.CPT.CREATE}
                        label={"Add"}
                      />
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                addFormHandler={() => this.addFormHandler()}
                hasPagination={true}
                fetchTableRecords={(filters) =>
                  this.fetchConfigurationtList(filters)
                }
                {...tableRecords}
              />
            </div>
          </div>
      </>
    );
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const queryfilters = {
      ...tableRecords.pagination,
      page:1,
      search: filters,
    };
    this.fetchConfigurationtList(queryfilters);
  };
  clearFiltersHandler = async () => {
    await this.setState({
      filters: {},
    });
    this.submitFiltersHandler();
  };
  fetchConfigurationtList = async (query = {}) => {
    const { tableRecords } = this.state;
    try {
      const queryString = toQueryString({
        ...tableRecords.pagination,
        ...query,
      });
      this.props.Loader(true);
      const response = await ConfigurationService.getSftpConfigList(queryString);
      console.log('response', response);

      const tableData = response.data.data.map((item,itemIndex) => ({
        itemIndex: itemIndex,
        isHeading: false,
        rowId: item.id,
        data: {
          isActive: false,
          name: item.name,
          description: item.description,
          active: "Yes",
          action: [
            {
              className: "btn btn-icon btn-transparent-dark",
              iconType: "Search",
              title: "View",
              acl: "any",
              clickHandler: (rowId, data) =>
                this.directoryViewModalHandler(item.id)
            },
            {
              className: "btn btn-icon btn-transparent-dark",
              iconType: "Edit",
              title: "View",
              acl: "any",
              clickHandler: (rowId, data) =>
                this.editHandler(item.id),
            },
            {
              className: "btn btn-icon btn-transparent-dark",
              iconType: "Remove",
              acl: "any",
              title: "Delete",
              isDelete: true,
              clickHandler: () => this.deleteHandler(item.id),
            },
          ],
        },
      }));
      // console.log('tableData',response?.data);
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            type: "default",
            status: 200,
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: response?.data?.total,
          },
        },
      }));
    } catch (err) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200,
          },
        },
      });
      console.log("DataIsse");
      // console.error("Error fetching companies:", err);
    } finally {
      this.props.Loader(false);
    }
  };

  deleteHandler = async (id) => {
    ConfigurationService.deleteSftpConfiguration(id)
      .then((res) => {
        this.props.Toast.success("Configuration Deleted Succesfully");
      })
      .catch((err) => {
      })
      .finally(() => {
        this.fetchConfigurationtList();
      });
  };
  editHandler = (rowId, data,) => {
    this.setState({
      activeEditId: rowId,
    }, this.addFormHandler())
  };
  configureConnection=async(id)=>{
    const payload={
      id:id
    };
    let response= await ConfigurationService.configureConnectionById(payload);
    console.log('response',response);
    
  }
  directoryViewModalHandler=(id)=>{
    this.setState({TreeModalOpen:!this.state.TreeModalOpen,activeEditId:id});
  }
  checkBoxchangeHandler = (value, { e },rowId='') => {
    if (!rowId) {
      rowId = e.target.id;
    }    
    const { tableRows } = this.state.tableRecords;
    let updatedData;
    if (rowId=='ALL') {
       updatedData = tableRows.data.map((item) => {
          item.data.isActive = value;
        return item;
      });
      this.setState({
        selectedAllRecords: false,
    });
    }
    else{
       updatedData = tableRows.data.map((item) => {
        if (item.rowId === rowId) {
          item.data.isActive = value;
        }
        return item;
      });
    }
    const allSelected = updatedData.every(item => item.data.isActive ==1);
    const allCheckbox = document.getElementById('ALL');
    if (allCheckbox) {
      allCheckbox.checked = allSelected;
    }
    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}
export default WithRouter(withReduxState(WithRoutify(ConfigurationList)));
