import Modal from "react-bootstrap/Modal";


const ConsentFormHtmlView = (props) => {
  const { isOpen,htmlViewContent,htmlViewModal } = props;
  

  return (
    <>
        <Modal show={isOpen}
        onHide={htmlViewModal}
        dialogClassName='hResponsive'
        keyboard={false}
        contentClassName='h-100'
        backdrop="static"
        size="lg">
            <div className="sh_modalBox">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h6 className="text-lg mb-0 font-weight-bold">
                            Consent Content
                        </h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tab-pane active show" role="tabpanel" id="ac">
                        <div className="card mb-2">
                            {/* <a href="#collapseCardExample" className="d-block card-header" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardExample" style={{ position: 'sticky', top: '-20px', zIndex: '1' }}>
                                <h6 className="m-0 text-md ">Clinic Consents for <span className="badge bg-blue-soft text-blue"> {props.visit_category} </span> </h6>
                            </a> */}
                            <div className="collapse show" id="collapseCardExample" data-bs-parent="#modal111">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row justify-content-center">
                                                 <div className="mw-100 w-100">
                                                        <HTMLContent htmlString={htmlViewContent} />
                                                 </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>

                
            </div>
        </Modal>
    </>
);

 

}

export default ConsentFormHtmlView;
const HTMLContent = ({ htmlString }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};
