import { useEffect, useState } from "react";
import WithRouter from "../../../hoc/WithRouter";
import ClinicService from "../../../services/ClinicService";
import ClinicEditView from "./ClinicEditView";
import ClinicDetailView from "./ClinicDetailView";
import WithRoutify from "../../../hoc/WithRoutify";
const ClinicDetailViewContainer = (props) => {
  const { params } = props;
  const [editView, setEditView] = useState(params.action == 'edit' ? true : false); //intial default load
  const [clinicDetails, setClinicDetails] = useState({});
  useEffect(() => {
    fetchCompanyDetails();
  }, [editView]);
  const fetchCompanyDetails = async () => {
    // const { Loader } = props;
    if (params.clinicId) {
      props.Loader(true);
      const response = await ClinicService.getClinic(params.clinicId);
      setClinicDetails(response);
      props.Loader(false);
    }
  }
  return (
    <>
      {editView ? (
        <ClinicEditView
          params={params}
          changeLayout={() => setEditView(!editView)}
          clinicDetails={clinicDetails} />
      ) : (
        <ClinicDetailView
          changeLayout={() => setEditView(!editView)}
          params={params}
          clinicDetails={clinicDetails} />
      )}
    </>
  );
};
export default WithRoutify(WithRouter(ClinicDetailViewContainer));
