import http from './http';
const ConfigurationService = {
  getSftpConfigList: async (query = {}) => {
    const queryString = new URLSearchParams(query).toString();
    try {
      const response = await http.GET(`sftp?${queryString}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching :', error);
      // throw error;
    }
  },
  getSftpConfigById: async (id) => {
    try {
      const response = await http.GET(`sftp/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching :', error);
      // throw error;
    }
  },
  //Save sftp configs
  saveSftpConfiguration: async (data) => {
    try {
      const response = await http.POST(`sftp`, data);
      return response;
    } catch (error) {
      console.error('Error fetching :', error);
    }
  },
  updateSftpConfiguration: async (id,data) => {
    try {
      const response = await http.PUT(`sftp/${id}`, data);
      return response;
    } catch (error) {
      console.error('Error fetching :', error);
    }
  },
  deleteSftpConfiguration: async (id) => {
    try {
      const response = await http.DELETE(`sftp/${id}`);
      return response;
    } catch (error) {
      console.error('Error fetching :', error);
      throw error;
    }
  },
  getDirectoriesById: async (payload) => {
    try {
      const response = await http.POST(`sftp-directory`,payload);
      return response;
    } catch (error) {
      console.error('Error fetching :', error);
      throw error;
    }
  },
  downloadSftpFile: async (payload) => {
    try {
      const response = await http.POST(`sftp-download`,payload);
      return response;
    } catch (error) {
      console.error('Error fetching :', error);
      throw error;
    }
  },
  uploadSftpFile: async (payload) => {
    const headers = {
      'Content-Type': 'multipart/form-data'
    }
    try {
      const response = await http.POST(`sftp-upload`,payload,headers);
      return response;
    } catch (error) {
      console.error('Error fetching :', error);
      throw error;
    }
  },
  
};

export default ConfigurationService;