import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import NotificationList from "./NotificationList";




const NotificationContainer=()=>{
    return(
        <>
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<NotificationList/>} />
        
      </Routes>
      </Wrap>
      </>
    );
}
export default NotificationContainer; 

