import React, { useEffect, useRef, useState } from "react";
import FormInputs from "../FormInputs";
import {  isEmptyString, isObject, isSet } from "../../../utils/commonUtils";
import Wrap from "../../../hoc/Wrap";


const MultiSelectDropDown = ({options,value,ddKey,isLoaded,isToogle,...props})=>{
    // console.log('ddkey',ddKey,props);
    const [filterOptions,setFiltearOptions]=useState([]);
    const searchRef = useRef(null);
    useEffect(()=>{
    },[]);

    useEffect(()=>{
        //render options changes
        if (searchRef.current.value) {
          searchHandler(searchRef.current.value)  
        }
        else{
            setFiltearOptions(options);
        }
        
    },[options]);

    let values = [];
    try{
         values   = isSet(value,'').toString().split(',').filter(v => v !== '');
    }catch(e){
        // console.log('e',value);
    }
    // console.log('ddKey',ddKey); 

    const changeHandler = (bool, val) => {
        if (isToogle === true) {
            if (bool === true) {
                values = val === 'ALL' ? Object.values(options).flat().map(itm => itm.value) : [...values, val];
            } else {
                values = val === 'ALL' ? [] : values.filter(item => Array.isArray(val) ? !val.includes(item) && item != 'ALL' : item !== val && item != 'ALL');
            }
            let option_1=Object.values(options).flat();            
            if (option_1.find(item=>item.value=="ALL")) { //has all check
                let option_length=Object.values(options).flat().filter(itm=>itm.value!='ALL').length; //filter all length 
                let value_1=[...new Set(values.flat())];
                let value_length=value_1.length;                
                if (option_length==value_length) {
                    values= Object.values(options).flat().map(itm => itm.value);
                }
            }
            props.changeHandler(values.join(','));
        } else {
            props.changeHandler(bool, { selectVal: val });
        }
    };
    // const changeHandler = (bool, val) => {
    //     if (isToogle === true) {
    //       if (bool === true) {
    //         values = val === 'ALL' ? Object.values(options).flat().map(itm => itm.value) : [...values, val];
    //       } else {
    //         values = val === 'ALL' ? [] : values.filter(item => Array.isArray(val) ? !val.includes(item) : item !== val);
    //       }
    //       props.changeHandler(values.join(','));
    //     } else {
    //       props.changeHandler(bool, {selectVal:val});
    //     }
    // };
    // const changeHandler = (bool,val)=>{
    //     if(isToogle===true){
    //         if(bool===true){
    //             if(val==='ALL'){
    //                 Object.values(options).flat().forEach((itm)=>{
    //                     values.push(itm.value);
    //                 });    
    //             }else{
    //                 values = [
    //                     ...values,
    //                     val
    //                 ];
    //             }
               
    //         }else{
    //             if(val==='ALL'){ 
    //                 values = [];
    //             }else{
    //                 const values = [...values].filter((item) => {
    //                     if (Array.isArray(val)) {
    //                       // If val is an array, check if the item is included in val
    //                       return !val.includes(item);
    //                     } else {
    //                       // If val is a single value, directly compare it with the item
    //                       return item !== val;
    //                     }
    //                   });
    //             }
               
    //         }
    //         props.changeHandler(values);
    //     }else{
    //         props.changeHandler(bool,val);
    //     }
        
    // }
    
  
    const searchHandler=(val='')=>{
        let updatedOptions=[];
        //check if empty or not if empty set default options
        if (isEmptyString(val)) {
            updatedOptions=options
        }
        else{
            //option.label.toLowerCase() for equal ignore case comparison 
            if (Array.isArray(options)) {
                updatedOptions = options.filter(option => option.label.toLowerCase().includes(val.toLowerCase()));

            }
            else if (typeof options === 'object' && options !== null) { //if options are object
                updatedOptions = Object.keys(options).reduce((acc, key) => {
                  const filteredOptions = options[key].filter(option =>
                    option.label.toLowerCase().includes(val.toLowerCase())
                  );
                  if (filteredOptions.length > 0) {
                    acc[key] = filteredOptions;
                  }
                  return acc;
                }, {});
              }
        }           
        setFiltearOptions(updatedOptions)        
    }
    const dropdownClass = (isToogle===true)?'dropdown-menu dropdown-menu-left ':'card m-1 p-0 col-lg-5 col-12  dropdown-menu-left';
    
    return(
        <>
        
            {(isToogle===true) &&
                <span className="custom-select cursor-pointer text-sm text-truncate" id={isSet(props.id,ddKey+'_display')} data-bs-toggle="dropdown" aria-expanded="false"> {(values.indexOf('ALL')!==-1)?'All':(values.indexOf('NONE')!==-1)?0:values.length} items selected...</span> 
            }

                <div className={`mb-0 ${dropdownClass} dropnotHide`}  id={(isToogle===true)?"dropdown-menu":'dropdownmenu'} style={{width:(isToogle==true?'400px':'inherit')}}> 
                    
                
                    <div className="card-header p-2">

                    {(values.indexOf('ALL')!==-1)?'All':(values.indexOf('NONE')!==-1)?0:values.length} items selected...
                    <input   ref={searchRef} type="text" id={isToogle===true?ddKey+'_search':isSet(props.id,ddKey+'_search')} className="form-control form-control-sm" onChange={(e)=>{searchHandler(e.target.value)}}placeholder="Search here...." />
                    </div>
                    <div className="card-body p-0 ">
                        {(isLoaded===false)?
                            <div className="card_loaderOuter">
                                <div className="spinner-border spinner-border-sm text-dark" role="status">
                                <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        :<></>}
                        
                        <div className="card-list fixedHeight_modal position-relative">
                            {isObject(filterOptions)?
                                Object.entries(filterOptions).map(([key,arr],groupIndex)=>{
                                    const isDisabled = false;
                                    const itsGroupArr = arr.map(item => item.value);
                                    const isAllExisits =itsGroupArr.every(value => values.includes(value));
                                    const bool =  (values.includes('ALL') || isAllExisits);
                                    return(
                                        <Wrap>
                                        {key!=='default'?
                                        <div className="listHeader">
                                             <div className="form-check mx-2 " key={groupIndex}>
                                                
                                                <FormInputs 
                                                    type='Checkbox' 
                                                    name={props.label} 
                                                    disabled={isDisabled} 
                                                    className="form-check-input" 
                                                    id={"flexCheckDefault_group_"+groupIndex+''} 
                                                    value={bool} 
                                                    changeHandler={()=>changeHandler(!bool,itsGroupArr)} />
                                               
                                                    <label className="text-md mb-0" htmlFor={"flexCheckDefault_group_"+groupIndex+''}>{key}</label> 
                                             </div>
                                             </div>
                                        :<></>}
                                        
                                        <MultiSelectList 
                                            options={arr}
                                            values={values} 
                                            ddKey={ddKey+'_'+groupIndex}
                                            label={props.label} 
                                            changeHandler={changeHandler} />
                                        </Wrap>
                                    )
                                })
                            :
                            <MultiSelectList 
                                options={filterOptions}
                                values={values} 
                                ddKey={ddKey}
                                label={props.label} 
                                changeHandler={changeHandler} />}
                           
                        </div>
                    </div>
               
                </div>
    {(isToogle!==true) &&
        <div className="col-lg-6 col-12 multiselectorcontainer border rounded p-2 ml-2">
        <div className="componenetInner"> 
        {Object.values(options).flat().map((item,itemIndex)=>{
            if(values.indexOf(item.value)!==-1){
                return(
                    <div className="badge bg-blue-soft text-blue" key={itemIndex}>
                    <span className="text-truncate multiSelectTag"> {item.label} </span>
                    <span data-role="remove" onClick={()=>changeHandler(false,item.value)}>&times;</span>
                    </div>
                )
            }
        })}             
        </div>  
      </div>
    }
      
   </>
    )
}
const MultiSelectList = ({options,values,ddKey,label,changeHandler})=>{

    return  options.map((item,itemIndex)=>{
        // let  bool       = (values.indexOf(item.value)!==-1)?true:false;
        const isDisabled = false;
        const bool = (values.indexOf('ALL')!==-1)?true:(values.indexOf(item.value)!==-1)?true:false;
        return(
        <div className="form-check mx-2" key={itemIndex}>
            <FormInputs 
                type='Checkbox' 
                name={label} 
                disabled={isDisabled} 
                className="form-check-input" 
                id={"flexCheckDefault_"+ddKey+'_'+itemIndex} 
                value={bool} 
                changeHandler={()=>changeHandler(!bool,item.value)} />
            <label className="text-md mb-0" htmlFor={"flexCheckDefault_"+ddKey+'_'+itemIndex} onClick={(e)=>{e.stopPropagation()}}> 
                {item.label} 
            </label>
        </div> 
        )
    })
}
MultiSelectDropDown.defaulfProps={
    isToogle:false,
    ddKey:'default'
}

export default MultiSelectDropDown;