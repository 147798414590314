import { useState } from "react";
import FormInputs from "../../components/UI/FormInputs";
import CustomPasswordStrengthBar from "../../components/AuthComponent/CustomPasswordStrengthBar";
import { Button } from "react-bootstrap";
import UserService from "../../services/UserService";
import { OK } from "../../utils/constant";
import WithRoutify from "../../hoc/WithRoutify";
import { isObject } from "../../utils/commonUtils";

const ChangePassword = (props) => {
  const {Toast,Loader}=props;
  const [formData, setFormData] = useState({
    current_password: "",
    password: "",
    confirm_password: "",
  });
  const [validation,setValidation]=useState({
    password:'',
    confirm_password:'',
    current_password:'',
  });
  //change handler
  const changeHandler = (val, {e}) => {
    let fieldName = e.target.name;
    setFormData({ ...formData, [fieldName]: val });
    setValidation({...validation,[fieldName]:''})
  };

//UPDATE OR SUBMIT HANDLER
  const updateHandler = async () => {

    //payload
    const payload = {
      email: UserService.getProfile().email,
      current_password: formData.current_password,
      password: formData.password,
      confirm_password: formData.confirm_password
    }
    //loader
    Loader(true);
    const response = await UserService.changePassword(payload);
    let updateValidation = {};
    if (response.status && response.status == "ERROR") {
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(","),
          };
        }
      } else {
        props.Toast.error(response.data);
        setValidation({})
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    }
    else {
      Toast.success('Password Updated Successfully');
      setValidation({});
    }
    Loader(false);
  }
  return (
    <>
      <div className="sh_cardBox w-50" style={{margin:'auto'}}> 
        <div className="sh_cardHeader">
          <h5 className="text-gray-900">
            Password Recovery 
          </h5>
        </div>
          <div className="sh_cardBody">
            <div className="text-lg mb-3 text-muted">For security purposes, please enter your current password first.</div>
            <div class="col-12">
               
              <label className="text-gray-900 text-md opacity-75">Current Password</label>
              <FormInputs
                className="form-control  "
                type="Password"
                name="current_password"
                autocomplete='new-password'
                value={formData.current_password}
                changeHandler={changeHandler}
                required={true}
              />
              {validation['current_password']?<span className="text-danger m-1">{validation['current_password']}</span>:""}<br/>
              <label className="text-gray-900 text-md opacity-75"> Password</label>

              <FormInputs
                className="form-control  "
                type="Password"
                name="password"
                value={formData.password}
                changeHandler={changeHandler}
                required={true}
              />
              {validation['password']?<span className="text-danger m-1">{validation['password']}</span>:""}<br/>
              {
                formData.password!==""?
                <CustomPasswordStrengthBar
                password={formData.password}
                onChangeHandler={() => {}}
              />
              :
              ''
              }
             
              <label className="text-gray-900 text-md opacity-75">Confirm Password</label>

              <FormInputs
                className="form-control  "
                type="Password"
                name="confirm_password"
                value={formData.confirm_password}
                changeHandler={changeHandler}
                required={true}
              />
              {validation['confirm_password']?<span className="text-danger m-1">{validation['confirm_password']}</span>:""}
              {
                formData.confirm_password!==""?
                <CustomPasswordStrengthBar
                password={formData.confirm_password}
                onChangeHandler={() => {}}
              />
              :
              ''
              }
            </div>
        
        </div>
        <div class="sh_cardFooter justify-content-center">
            <Button onClick={()=>updateHandler()}>Update Password</Button>
        </div>
      </div>
    </>
  );
};
export default WithRoutify(ChangePassword);
