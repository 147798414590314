import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import OrganizationList from "./OrganizationList";

const organizationContainer=()=>{
    return(
        <>
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<OrganizationList />} />
        {/* <Route path="/detail-view/:organizationId/:action" key={1} element={<OrganizationDetailViewContainer/>}/> */}
      </Routes>
      </Wrap>
      </>
    );
}
export default organizationContainer;