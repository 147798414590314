import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { isObject,isSet } from "../../../../../utils/commonUtils";
import FormInputs from "../../../../../components/UI/FormInputs";
// import { useLocation, useNavigate } from "react-router-dom";
// import LabService from "../../services/LabService";
// import { useToast } from '../../context/ToaxtContext';
// import LookupService from "../../../../services/LookupService";
// import ActivitiesService from "../../../../../services/ActivitiesService";
import WithRoutify from "../../../../../hoc/WithRoutify";
import VisitsService from "../../../../../services/VisitsService";
import WithRouter from "../../../../../hoc/WithRouter";

const AddActivities=(props)=>{
  const { isOpen,addToogleHandler, modelTitle,Toast,options,params} =props;
  const [validation,setValidation] = useState({});
  const [formData,setFormData] = useState({
        visit_id: params.visitId,
        activity_id: '',
      })
      
        const inputFields = [
            
              {
                type: "SelectSearchList",
                label: "Activity",
                placeholder: "Activity Name",
                className: "form-control form-control-solid",
                name: "activity_id",
                options:options,
                value:formData.activity_id,
                required:true
              },
        ];
        const changeHandler = (val, fieldName) => {
          setFormData({
              ...formData,
              [fieldName]: val,
            });
            setValidation({...validation,[fieldName]:''});
        };
        const formSubmitHandler = async () => {
            let data=await VisitsService.saveActivityInChartVisits(isSet(formData.organization,null),formData);
            let updateValidation = {...validation};
            // console.log('saveActivities',data);
            if(data.status && data.status==="ERROR"){
              if (isObject(data.data)) {
                for (let key in data.data.errors) {
                  updateValidation = {
                    ...updateValidation,
                    [key]: data.data.errors[key].join(","),
                  };
                }
              } else {
                props.Toast.error(data.data);
              }
              if (Object.keys(updateValidation).length > 0) {
                setValidation(updateValidation);
              }
            }else{
              props.fetchVisitDetails();
              props.addToogleHandler();
              Toast.success('Activities Added Successfully');
            }
        }
      return (
        <>
          <Modal show={isOpen} onHide={addToogleHandler} dialogClassName='' keyboard={false}
           contentClassName='h-100'
           backdrop="static" size="md">
           <div className="sh_modalBox1">
              <Modal.Header closeButton>
                <Modal.Title>
                  <h6 className="text-lg mb-0 font-weight-bold">
                    {modelTitle}
                  </h6>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  {inputFields &&
                    inputFields.map((field, index) => (
                      <div className="col-12" key={index}>
                        <div className="form-group">
                          <label className="mb-0 text-md opacity-75"> {field.label} </label>
                          <FormInputs {...field} changeHandler={(value,e)=>changeHandler(value,field.name)} />
                          {isSet(validation[field.name],'')!=='' ? <span className="text-danger m-1">{isSet(validation[field.name],'')}</span> : ''}
                        </div>
                      </div>
                    ))}
                </div>
              </Modal.Body>
      
              <Modal.Footer className="d-flex justify-content-center">
                <Button variant="primary" className="" onClick={()=>formSubmitHandler()}>
                  Submit
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        </>
      );
    };
export default WithRouter(WithRoutify(AddActivities));