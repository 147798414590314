import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import HL7LogList from "./HL7LogList";
import MessageParser from "./MessageParser";

const HL7Container = () => {

  return (
    <Wrap>  
      <Routes>
        <Route path="/log" key={0}  element={<HL7LogList/>} />
        <Route path="/parse" key={0}  element={<MessageParser/>} />
      </Routes>
    </Wrap>
  );
};






export default HL7Container;
