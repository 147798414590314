import React, { Component } from 'react';
import FormInputs from '../../components/UI/FormInputs';
import TableGrid from '../../components/UI/TableGrid';
import WithRoutify from '../../hoc/WithRoutify';
import { calculateAge, capitalizeName, formatPhoneNumberUS, LabResultCalcualtion, toQueryString } from '../../utils/commonUtils';
import Buttons from '../../components/UI/Buttons';
import Employeeervice from '../../services/EmployeeService';
import CsvService from '../../services/CsvService';
import FileManagerService from '../../services/FileManagerService';
import { OK } from '../../utils/constant';
import SendEmailNotification from './SendEmailNotification';

class BillerLabsReport extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      { label: '', key: 'isActive', type: 'checkBox', changeHandler: this.checkBoxchangeHandler ,name:"ALL",},
      { label: 'Lab ID', key: 'lab_id', type: '' },
      { label: 'Status', key: 'status', type: '' },
      { label: 'Specimen Collection Date/Time', key: 'specimen_date_time', type: '' },
      { label: 'Result Date', key: 'result_date', type: '' },
      { label: 'Lab Order', key: 'lab_order', type: '' },
      { label: 'Test Result', key: 'test_result', type: '' },
      { label: 'EMM CONFIRMED TEST RESULT',key:'child_lab',type:''},
      { label: 'Alternate ID', key: 'alternate_id', type: '' },
      { label: 'Spec Accession', key: 'accession', type: '' },
      { label: 'Lab Performed By', key: 'lab_performedby', type: '' },
      { label: 'Patient Name', key: 'patient_name', type: '' },
      { label: 'Patient ID', key: 'patient_id', type: '' },
      { label: 'DOB', key: 'patient_dob', type: '' },
      { label: 'Age', key: 'patient_age', type: '' },
      { label: 'Phone', key: 'patient_phone', type: '' },
      { label: 'Sex', key: 'patient_sex', type: '' },
      { label: 'Race', key: 'patient_race', type: '' },
      { label: 'Full Address', key: 'patient_address', type: '' },
      { label: 'Company', key: 'company', type: '' },
      { label: 'Company ID', key: 'company_id', type: '' },
      { label: 'Division',key:'division',type:''},
      { label: 'Location', key: 'location', type: '' },
      { label: 'Ordering Clinic', key: 'clinic', type: '' },
      { label: 'Medical Doctor',key:'medical_doctor',type:''},
      { label: 'Diagnosis',key:'diagnosis',type:''}
    ];
    this.state = {
      filters: {
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          data: []
        },
        selectedRows:0,
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: 'random_lab_id',
          sort_order: 'desc',
        }
      },
      selectedAllRecords:false,
      notificationModal:false,
    }
  }

  componentDidMount() {

    this.fetchBillerLabsReport();
  }

  componentDidUpdate(prevProps, prevState) {
    // Code to run after the component has updated
  }

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  render() {
    const { tableRecords, filters,notificationModal,selectedAllRecords} = this.state;
    return (
      <>
        {
          notificationModal ?
            <SendEmailNotification
              sendNoticationHandler={this.sendNoticationHandler}
              modleToogleHandler={this.sendNotificationModaltoogleHandler}
            />
            :
            <></>
        }
   

        <div className="sh_cardBox">
          <div className="sh_cardHeader">
            <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
              <h6 class="text-lg f-500 mb-0"> Biller Report </h6>
              <div className="form-group-fields row">
                <div className="col-12 d-flex">
                  <div class="input-group input-group-joined input-group-solid">

                    <FormInputs
                      type='Date'
                      placeholder="From Date"
                      value={filters["test_date_from"]}
                      changeHandler={(val) =>
                        this.onChangeFiltersHandler("test_date_from", val)
                      }
                      className="form-control"
                    />
                  </div>
                  <div class="input-group input-group-joined input-group-solid ml-2">


                    <FormInputs
                      type='Date'
                      placeholder="To Date"
                      value={filters["test_date_to"]}
                      changeHandler={(val) =>
                        this.onChangeFiltersHandler("test_date_to", val)
                      }
                      className="form-control"
                    />
                  </div>

                  <div className="btn-group pl-2">
                    <button
                      className="btn btn-primary "
                      onClick={() => this.submitFiltersHandler()}
                    >
                      Search
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={() => this.clearFiltersHandler()}
                    >
                      Clear
                    </button>
                  </div>
                  <div className="btn-group pl-2">
                    <Buttons
                      clickHandler={() => {this.downloadCSV()}}
                      className="btn btn-primary"
                      acl={tableRecords.pagination.totalRecords!=0? "any":''}
                      label={"Download CSV"}
                    />

                  </div>
                </div>
              </div>

            </div>
          </div>
          
            <TableGrid
              hasPagination={true}
              fetchTableRecords={(filters) =>
                this.fetchBillerLabsReport(filters)
              }
              hasHeaderAction={true} //headerProps
              headersProps={{
                tableRecords:tableRecords,
                headerButtons:this.getHeaderButtons(),
                checkBoxchangeHandler:this.checkBoxchangeHandler,
                selectedAllRecords:selectedAllRecords,
                setSelectedAllRecords:(bool) => this.setState({ selectedAllRecords: bool })

              }}
              {...tableRecords}
              gridEditProps={{
                formDataInEdit: this.state.formDataInEdit,
                activeEditId: this.state.activeEditId,
                onChangeFormDataInEdit: this.onChangeFormDataInEdit,
              }}
            />
        </div>
      </>
    );
  }
  fetchBillerLabsReport = async (query = {}) => {
    const { tableRecords ,selectedAllRecords} = this.state;
    const {tableRows }=tableRecords;    
    const queryString = toQueryString({ ...tableRecords.pagination, ...query });    
    try {
      this.props.Loader(true);
      const labsList = await Employeeervice.getAllEmployeeVisitLab(queryString);
      let tableData = [];
      labsList.data.data.forEach((item, index) => {
        const { status, color } = LabResultCalcualtion(item.test_result);
        console.log(selectedAllRecords,'selectedAllRecords');
        
        // const existingRow = tableRows.data.find(itm => itm.rowId === item.id) ||false;
        const isActiveRow = selectedAllRecords || false;
        tableData = [
          ...tableData,
          {
            labsIndex: index,
            isHeading: false,
            rowId: item.id,
            data: {
              isActive: isActiveRow,
              status: {
                title: 'Status',
                value: <span className={`badge bg-${color}-soft text-${color}`}>{capitalizeName(status.toLowerCase())}</span>
              },
              lab_id: item.random_lab_id || '--',
              company: item.visit?.company?.name || '--',
              company_id:item.visit?.company?.system_id || '--',
              clinic: item.visit?.employee?.clinic?.name || '--',
              division:item.visit?.employee?.division?.division_name || '--',
              child_lab:item.child_lab.map(itm=>itm.lab_order).join(',') ||'--',
              medical_doctor:item?.organization?.medical_director_name || '--',

              patient_name: item?.visit?.employee?.first_name && item?.visit?.employee.last_name ? item.visit.employee.first_name + " " + item.visit.employee.last_name : '--',
              patient_id: item?.patient_id || '--',
              patient_dob: item?.visit?.employee.dob || '--',
              patient_age: calculateAge(item?.visit?.employee.dob) + ' yrs' || '--',
              patient_phone: formatPhoneNumberUS(item?.visit?.employee.phone) || '--',
              patient_sex: item?.visit?.employee.gender || '--',
              patient_race: item?.visit?.employee.race || '--',
              patient_address: item?.visit?.employee.address || '--',

              location: item?.clinic_department?.facility_name || '--',
              specimen_date_time: `${item.test_date}   ${item.test_time}` || '--',
              lab_order: item.lab_order?.lab_order || '--',
              lab_performedby: item.lab?.name || '--',
              accession: item.accession || '--',
              diagnosis: item.reason_for_lab || '--',

              result_date: item.result_date || '--',
              test_result: item.test_result || '--',
              alternate_id: item.alternate_id || '--',
              active: 'Yes',
            }
          }
        ]
      });
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            status: 200,
            type: "default",
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: labsList.data.total
          },
        },
      }));
      this.props.Loader();
    } catch (e) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200
          }
        }
      });
      this.props.Loader();
    }
  }
  downloadCSV=async()=>{
    const {filters,tableRecords,selectedAllRecords}=this.state;
    const { tableRows } =tableRecords;
    //loop through table daata tO get active id
    const activeIds = tableRows.data
      .filter((item) => item.data && item.data.isActive === 1)
      .map((item) => item.rowId)
      .join(',');
    const maxExceedCount = 10;
      if (!activeIds || activeIds.split(',').length>maxExceedCount) {
        let total_record_count=tableRecords.pagination.totalRecords;
        if (total_record_count>maxExceedCount) { //if toTal record more than 10 send email not download 
          this.sendNotificationModaltoogleHandler();
          return false;
        }
      }
      if(selectedAllRecords){
        const {filters,tableRecords,selectedAllRecords}=this.state;
        const { tableRows } =tableRecords;
        let total_record_count=tableRecords.pagination.totalRecords;
            if (total_record_count>10) { //if toTal record more than 10 send email not download 
              this.sendNotificationModaltoogleHandler();
              return false;
            }
      }
    const payload={
      type:'biller_lab_report',
      test_date_from:filters.test_date_from,
      test_date_to:filters.test_date_to,
      lab_ids :selectedAllRecords==true?"":activeIds,
    }
    const response=await CsvService.downloadCsvReport(payload);
    if (response&&response.code&&response.code==OK&&response.data) {
      FileManagerService.downloadCsv(response.data,'lab-report');
    }
    else{
      this.props.Toast.error("Invalid CSV data for process")
    }
    
    
  }
  sendNoticationHandler=async(email)=>{
    const {filters,tableRecords,selectedAllRecords}=this.state;
    const { tableRows } =tableRecords;
    const activeIds = tableRows.data
      .filter((item) => item.data && item.data.isActive === 1)
      .map((item) => item.rowId)
      .join(',');
    // const {filters,tableRecords}=this.state;
    // const { tableRows } =tableRecords;
    const payload={
      type:'biller_lab_report',
      test_date_from:filters.test_date_from,
      test_date_to:filters.test_date_to,
      notification_email:email,
      lab_ids :selectedAllRecords==true?"":activeIds,
    }
    const response=await CsvService.downloadCsvReport(payload);
  }
  sendNotificationModaltoogleHandler=()=>{
    this.setState({ notificationModal: !this.state.notificationModal });
  }
  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val
      }
    })
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const { pagination } = tableRecords;
    const queryfilters = {
      ...pagination,
      page:1,
      search: filters,
    }
    this.fetchBillerLabsReport(queryfilters);
  }
  clearFiltersHandler = () => {
    this.setState({
      filters: {}
    }, this.submitFiltersHandler);
  }
  getHeaderButtons=()=>{
    const HeaderButtons=[
      {
        label:'Download Csv',
        clickHandler:()=>this.downloadCSV(),
      },
      {
        label:'Delete',
        clickHandler:()=>{},
      }
    ];
    return HeaderButtons;
  }
  checkBoxchangeHandler = (value, { e },rowId='') => {
    if (!rowId) {
      rowId = e.target.id;
    }    
    const { tableRows } = this.state.tableRecords;
    let updatedData;
    if (rowId=='ALL') {
       updatedData = tableRows.data.map((item) => {
          item.data.isActive = value;
        return item;
      });
      this.setState({
        selectedAllRecords: false,
    });
    }
    else{
       updatedData = tableRows.data.map((item) => {
        if (item.rowId === rowId) {
          item.data.isActive = value;
        }
        return item;
      });
    }
    const allSelected = updatedData.every(item => item.data.isActive ==1);
    const allCheckbox = document.getElementById('ALL');
    if (allCheckbox) {
      allCheckbox.checked = allSelected;
    }
    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
}
export default WithRoutify(BillerLabsReport);