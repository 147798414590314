import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getLookupByKey, isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import { useSelector } from "react-redux";
import withReduxState from "../../hoc/wReduxState";
import WithRoutify from "../../hoc/WithRoutify";
import { Button } from "react-bootstrap";
import OrganizationService from "../../services/OrganizationService";
import ProviderService from "../../services/ProviderService";
import { SPECIALTY } from "../../utils/constant";
import { organizationList } from "../../store/OrganizationSlice";
import DropDownService from "../../services/DropDownService";

const AddProvider = (props) => {
  const [validation, setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const { isOpen, addFormHandler, modelTitle, activeEditId, fetchProviderList,Toast } = props;
  // const stateList = useSelector((state) => state.session.profileStateList);
  const [clinic, setClinicOptions] = useState([]);
  const [specialtyOptions, setSpecialityOptions] = useState([]);
  const organizationListOptions = useSelector(organizationList);
  const inputFields = getInputFields();
  const isMultipleOrg=props.profile.is_multiple_organization;
  //initial render
  useEffect(() => {
    const updateFormdata = async () => {
      const specialtyOptions = await getLookupByKey(SPECIALTY);
      setSpecialityOptions(specialtyOptions);
    }
    if (props.activeEditId) {
      fetchProvider();
    }
    updateFormdata();
  }, []);
  //update based on org
  useEffect(() => {
    fetchClinic();
  }, [formData.organization_uuid]);

  //fetch clinic
  const fetchClinic = async () => {
    let opt = [];
    props.Loader(true);
    let clinicResponse = await DropDownService.getClinicList(isSet(formData.organization_uuid, OrganizationService.getOrgId()));
    clinicResponse&&clinicResponse?.forEach((itm) => {
      opt.push({ label: itm.name, value: itm.clinic_id })
    });
    setClinicOptions(opt);
    props.Loader(false);
  }
  ///fetch clinic by for clinic update
  const fetchProvider = async () => {
    let provider = await ProviderService.getProviderById(props.activeEditId);
    let updateData = provider;
    let assigned_clinic = provider['clinics'];
    let mapping = isSet(assigned_clinic, []).map(itm => itm.clinic_id);
    updateData['assigned_clinics'] = mapping.join(',');
    setFormData(updateData);
  };
  //change handler
  const changeHandler = (value, fieldName,obj={}) => {
    const {selectVal} = obj;
    const updateFormData = { ...formData };
    const updateValidation = { ...validation }; 
    //if dol_exam_eligible is yes need to show DOL Registry ID field 
    if (fieldName === 'dol_exam_eligible') {
      updateValidation['dol_registry_id']='';
    }
    //assigned Clinic provider 
    if (fieldName === 'assigned_clinics') {
      if (value) {
        let data;
        if (typeof formData.assigned_clinics === 'string' && formData.assigned_clinics !== '') {
          data = formData.assigned_clinics.split(',');
        } else {
          data = [];
        }
        data.push(selectVal);
        data = data.join(',');
        value = data;
      }
      else {
        let data = formData.assigned_clinics.split(',');
        let index = data.indexOf(selectVal);
        if (index !== -1) {
          data.splice(index, 1);
        }
        value = data.join(',');
      }

    }
    updateFormData[fieldName] = value;
    updateValidation[fieldName] = '';
    setFormData(updateFormData);
    setValidation(updateValidation);
  };

  //submit form handler for update and save 
  const formSubmitHandler = async () => {
    let updateValidation = { ...validation };
    //check if global admin
    //check organization filled if no throwing validation
    if (isMultipleOrg && organizationListOptions.length > 2) {
      if (!formData.organization_uuid) {
        setValidation({
          ...updateValidation,
          organization_uuid: "The Organization Field is Required",
        });
        return false;
      }
    }
    let response;
    //update or save
    if (activeEditId) {
      response = await ProviderService.updateProvider(props.activeEditId, formData);
    }
    else {
      response = await ProviderService.saveProvider(isSet(formData.organization_uuid, null), formData);
    }
    if (response.status && response.status === "ERROR") {
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(","),
          };
        }
      } else {
        Toast.error(response.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
    } else {
      fetchProviderList();
      addFormHandler();
      const message=`Provider ${activeEditId?'Updated':'Added'} Successfully`;
      Toast.success(message);
    }
  };
  return (
    <>
      <Modal
        show={isOpen}
        onHide={addFormHandler}
        size="xl"
        dialogClassName="hResponsive "
        contentClassName="h-100"
        className=""
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">{modelTitle}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="card-body tab-content p-0">
              {inputFields &&
                inputFields.map((section, sectionIndex) => {
                  return (
                    <>
                      <div class="card mb-2">
                        <a
                          href={`#collapseCardExample_${sectionIndex}`}
                          class="d-block sh_accordionheader  card-header"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="true"
                          aria-controls="collapseCardExample"
                          style={{
                            position: "sticky",
                            top: "-15px",
                            zIndex: "1",
                          }}
                        >
                          <h6 class="m-0 text-md">
                            {section.title}
                          </h6>
                        </a>
                        <div
                          class="collapse show"
                          id={`collapseCardExample_${sectionIndex}`}
                          data-bs-parent="#modal111"
                        >
                          <div class="card-body">
                            <div class="row">
                              {section.data && section.data.map((data, dataInd) => {
                                return (
                                  <>
                                    <div class={`${data.col ? data.col : 'col-md-4 col-6'}`}>
                                      <div class="form-group">
                                        <label class="f-400 text-sm text-truncate mb-0">
                                          {data.label}
                                        </label>
                                        <div
                                          className={`${data.type === "MultiSelectDropDown" ? 'd-flex' : ''}`}
                                          key={dataInd}
                                        >
                                          <FormInputs
                                            {...data}
                                            className={
                                              "form-control form-control-solid"
                                            }
                                            changeHandler={(value,obj) =>
                                              changeHandler(value, data.name, obj)
                                            }
                                            value={formData[data.name]}
                                            required={data.required}
                                          />
                                          {isSet(validation[data.name], "") !==
                                            "" ? (
                                            <span className="text-danger m-1">
                                              {isSet(validation[data.name], "")}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="primary"
              className=""
              onClick={() => formSubmitHandler()}
            >
              {activeEditId ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  function getInputFields() {
    const inputFields = [
      {
        title: "Provider - General",
        data: [
          {
            type: "TextInput",
            label: "First Name",
            placeholder: "First Name",
            className: "form-control form-control-solid",
            name: "first_name",
            required: true,
            isFirstLetterCaps: true
          },
          {
            type: "TextInput",
            label: "Last Name",
            placeholder: "Last Name",
            className: "form-control form-control-solid",
            name: "last_name",
            required: true,
            isFirstLetterCaps: true
          },
          {
            type: "TextInput",
            label: "Type",
            placeholder: "Type",
            className: "form-control form-control-solid",
            name: "type",
            required: true,
            isFirstLetterCaps: true
          },
          {
            type: "SelectList",
            options: specialtyOptions,
            label: "Specialty",
            placeholder: "Specialty",
            className: "form-control form-control-solid",
            name: "specialty",
            required: true,
          },
          {
            type: "TextInput",
            label: "Email",
            placeholder: "Email",
            className: "form-control form-control-solid",
            name: "email",
          },
        ],
      },
      {
        title: "Provider - ID & Certificates",
        data: [
          {
            type: "Number",
            label: "License",
            placeholder: "License",
            className: "form-control form-control-solid",
            name: "license",
            required: true,
          },
          {
            type: "TextInput",
            label: "DEA",
            placeholder: "DEA",
            className: "form-control form-control-solid",
            name: "dea",
            max: 9
          },
          {
            type: "Number",
            label: "NPI",
            placeholder: "NPI",
            className: "form-control form-control-solid",
            name: "npi",
            required: true,
            max: 10
          },
          {
            type: "TextInput",
            label: "Taxonomy",
            placeholder: "Taxonomy",
            className: "form-control form-control-solid",
            name: "taxonomy",
            required: true,
            max: 10
          },
          {
            type: "TextInput",
            label: "Medicare ID",
            placeholder: "Medicare ID",
            className: "form-control form-control-solid",
            name: "medicare_id",
            max: 6
          },
          {
            type: "TextInput",
            label: "Medicaid ID",
            placeholder: "Medicaid ID",
            className: "form-control form-control-solid",
            name: "medicaid_id",
          },
          {
            type: "SelectList",
            options:[
              {
                label:"-Select-",
                value:'',
              },
              {
                label:"Yes",
                value:1
              },
              {
                label:"No",
                value:0
              }
            ],
            label: "DOL Exam Eligible",
            placeholder: "DOL Exam Eligible",
            className: "form-control form-control-solid",
            name: "dol_exam_eligible",
            required: true,
          },
          {
            type: "TextInput",
            label: "DOL Registry ID",
            placeholder: "DOL Registry ID",
            className: "form-control form-control-solid",
            name: "dol_registry_id",
            required:formData.dol_exam_eligible==="1"?true:false
          },
          {
            type: "Date",
            label: "DOL Registry Expires",
            placeholder: "DOL Registry Expires",
            className: "form-control form-control-solid",
            name: "dol_registry_expires",
            value: formData["dol_registry_expires"] || "",
          },
          {
            type: "TextInput",
            label: "RX Identification",
            placeholder: "RX Identification",
            className: "form-control form-control-solid",
            name: "rx_identification",
          },
          {
            type: "TextInput",
            label: "RX Specialty Code",
            placeholder: "RX Specialty Code",
            className: "form-control form-control-solid",
            name: "rx_specialty_code",
          },
          {
            type: "YesOrNo",
            label: "Is MRO",
            placeholder: "Is MRO",
            className: "form-control form-control-solid",
            name: "is_mro",
          },
        ],
      },
      {
        title: "Provider - Billing",
        data: [
          {
            type: "SelectList",
            options:[
              {
                label:"-Select-",
                value:'',
              },
              {
                label:"Yes",
                value:1
              },
              {
                label:"No",
                value:0
              }
            ],
            label: "Billing Eligible",
            placeholder: "Billing Eligible",
            className: "form-control form-control-solid",
            name: "billing_eligible",
            value: formData["billing_eligible"] || "",
            required:true,
          },
          {
            type: "TextInput",
            label: "Billing Claims Override",
            placeholder: "Billing Claims Override",
            className: "form-control form-control-solid",
            name: "billing_claims_override",
          },
        ],
      },
      {
        title: "Provider - Assigned Clinics",
        data: [
          {
            type: "MultiSelectDropDown",
            col: 'col-12',
            options: clinic,
            label: "Assigned Clinics",
            placeholder: "Assigned Clinics",
            className: "form-control form-control-solid",
            name: "assigned_clinics",
            required: true,
          },
          {
            type: "Date",
            label: "Unavailable with End Date",
            placeholder: "Unavailable with End Date",
            className: "form-control form-control-solid",
            name: "unavailable_until",
          },
        ],
      },
    ];
    if (isSet(props.profile.is_multiple_organization, false) === true &&
      organizationListOptions.length > 2 && !activeEditId) {
      inputFields.unshift(
        {
          title: "Organization Details",
          data: [
            {
              type: "SelectList",
              label: "Organization",
              options: organizationListOptions,
              placeholder: "",
              className: "form-control form-control-solid",
              name: "organization_uuid",
              required:true
            },
          ],
        },
      );
    }
    return inputFields;
  }
};
export default withReduxState(WithRoutify(AddProvider));
