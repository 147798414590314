import { OK } from '../utils/constant';
import http from './http';
import { wOrgIdMiddleware } from '../utils/commonUtils';


const ConsentFormService = {
  //Save
  saveConsentForm: wOrgIdMiddleware(async (url = '', data) => {
    try {
      const response = await http.POST(`${url}consent_form_for_clinics`, data);
      
      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return response;
      }
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),
  getConsentFormList: wOrgIdMiddleware(async (url = '', query) => {
    const queryString = new URLSearchParams(query).toString();
    try {
      const response = await http.GET(`${url}consent_form_for_clinics?${queryString}`);
      
      if (response.code === OK) {
        return response.data;
      }
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),
  getConsentFormById: wOrgIdMiddleware(async (url = '', id) => {
    try {
      const response = await http.GET(`${url}consent_form_for_clinics/${id}`);
      
      if (response.code === OK) {
        return response.data;
      }
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      // throw error;
    }
  }, true),
  getVisitConsentPDF: wOrgIdMiddleware(async(url='',visit_id,visit_category,employee_id) => {
    try {
      const response = await http.GET(`${url}print_consent_form_for_visit/${visit_id}/${visit_category}/${employee_id}`);
      if (response.code===OK) {
          return response.data;
      }
        
    } catch (error) {
      console.error('Error fetching user:', error);
      if(error.status){
        const resData = error.data;
        if(resData.code && resData.data){
            return resData;
        }
      }
      
      throw error;
    } 
  },true),
  saveConsentFormToVisit: wOrgIdMiddleware(async (url = '', data) => {
    try {
      const response = await http.POST(`${url}consent_form_for_visit`, data);

      if ((response.code === 200 || response.code === 201) && response.data) {
        return response.data;
      } else {
        return response;
      }
      
    } catch (error) {
      console.error("Error fetching user:", error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

      // throw error;
    }
  }, true),

  

  updateConsentForm: wOrgIdMiddleware(async (url = '', id, data) => {
    try {
      const response = await http.PUT(`${url}consent_form_for_clinics/${id}`, data);
      if (response.code===OK) {
        return response.data;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error("Error fetching user:", error);
    }
  }, true),
  deleteConsentForm: wOrgIdMiddleware(async (url = '', id) => {
    try {
      const response = await http.DELETE(`${url}consent_form_for_clinics/${id}`);
      return response;
      
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error("Error fetching user:", error);
      throw error;
    }
  }, true),
};

export default ConsentFormService;
