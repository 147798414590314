import http from './http';
import { wOrgIdMiddleware } from '../utils/commonUtils';

const NotificationLogService = {
    
    getNotificationLog:(async(query)=>{
        const queryString = new URLSearchParams(query).toString();
        const response = await http.GET(`notification-log?${queryString}`);
        return response.data;  
    }),
};

export default NotificationLogService;