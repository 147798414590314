import { useEffect, useMemo, useState } from "react";
// import { useParams } from 'react-router-dom';
import { isSet } from "../../utils/commonUtils";
import RolesService from "../../services/RoleService";
// import { Tables } from "./Tables";
// import Buttons from "../../components/UI/Buttons";
import FormInputs from "../../components/UI/FormInputs";
import WithRoutify from "../../hoc/WithRoutify";
import { capitalizeFirstLetter } from "../../utils/commonUtils";
import { isGlobalAdmin } from "../../utils/aclUtils";
import withReduxState from "../../hoc/wReduxState";
import { list } from "postcss";
// import FormInputs from "../../components/UI/FormInputs";

const Permissions = WithRoutify((props) => {
  const { roleId } ='';// useParams();
  const access = ['list','create','edit','delete'];
  const [permissonBody,setPermissionBody] = useState([]);
 

  useEffect(()=>{
    if(roleId!==''){
      // fetchPermission();
    }
    fetchPermissioinList();
  },[props.isAllOrganization]);

  const fetchPermissioinList = async ()=>{
    try{
      const data = await RolesService.getPermissionList();
      const permissionData = [];
      for(const key in data){
        let permissionObj = {
          label:key,
          data:data[key]
        };
        if(key !== "organization"){
          permissionData.push(permissionObj);
        }else if(props.isAllOrganization === false || props.isAllOrganization=== 0){
          permissionData.push(permissionObj);
        }
        
      }
      setPermissionBody(permissionData);
    }catch(e){

    }

  }
 
  const [permisssionHeading, setPermisssionHeading] = useState([
    // { label: "Screen" },
    { label: "Read", enabled: false },
    { label: "Create", enabled: false },
    { label: "Edit", enabled: false },
    { label: "Delete", enabled: false },
  ]);

  // const isGlobal = isGlobalAdmin(isSet(roleDetails.name,''));

  const rowCheckToogle = (label,bool)=>{
    let updatePermissions = {...props.permissions};
    permissonBody.forEach(item => {
      if(item.label===label){
        updatePermissions = {
          ...updatePermissions,
          [label]:(Boolean(bool)===true)?Object.values(item.data).flat():[]
        }
      }
    });
    props.setPermissions(updatePermissions);
  }
  const enableAllPermission = (type, bool) => {
    
    type === 'Read' ? (type = 'list') : (type = type.toLowerCase());
    let updatePermissions = {...props.permissions};
   
      permissonBody.forEach(item => {
        const label = item.label;
        const operations = item.data;
        // Iterate over each operation in the item's data
        for (const operation in operations) {
            if (operations.hasOwnProperty(operation)) {
                const value = operations[operation];
                if (Boolean(bool) && (type === operation || (type !== 'list' && (operation==='list' || type==operation)))) {
                  updatePermissions = {
                    ...updatePermissions,
                    [label]:[...isSet(updatePermissions[label],[]),value]
                  }
                    
                }else if(Boolean(bool)===false && type === operation){
                  if (operation=='list') {
                    updatePermissions = {}
                  }
                  else{
                    updatePermissions  = {
                      ...updatePermissions,
                      [label]:isSet(updatePermissions[label], []).filter(id => id !== value)
                    }
                  }
                  
                }
            }
        }
    });
    props.setPermissions(updatePermissions);
  };
 const allCreateChecked = (label)=>{
    let labelBasedArr = [];
    const selectedValues =  Object.values(props.permissions).flat();
    permissonBody.forEach((per)=>{
      if (props.profile.is_multiple_organization || per.label !== 'organization') {
        const currLabel = (label==='Read')?'list':label;
        labelBasedArr.push(per.data[currLabel.toLowerCase()]);
      }
     
    });
    const allValuesExist = (labelBasedArr.length>0)?labelBasedArr.every(value => selectedValues.includes(value)):false;
    return allValuesExist;
 };
  return (
    <>
       <div className="card-body">
                  <table
                    width="100%"
                    cellspacing="0"
                    className="table table-striped table-hover table-bordered mb-0 dataTable"
                  >
                    <thead className="permissionheader">
                      <tr>
                        <th>Screen</th>
                        {permisssionHeading.map((itm) => {
                          const isChecked = allCreateChecked(itm.label,props.permissions);
                          return (
                            <>
                            <th>{itm.label}
                            {itm.label!='Screen'?
                            <div className="form-check">
                              <FormInputs
                              type="Checkbox"
                              className="form-check-input"
                              changeHandler={(event)=>enableAllPermission(itm.label,event)}
                              value={isChecked}
                              />
                            </div>
                            :''
                            }
                            </th>
                            </>
                          );
                        })}
                        
                      </tr>
                    </thead>
                    <tbody>
                      {permissonBody.map((item,itemIndex)=>{
                        if((isSet(props.profile.is_multiple_organization,false)===false || props.isAllOrganization===true) && item.label==='organization'){
                          return false;
                        }
                        const isRowChecked = Object.values(item.data).flat().every(value => isSet(props.permissions[item.label],[]).includes(value));
                        return(
                          <tr key={itemIndex}>
                            {/* <td>{capitalizeFirstLetter(item.label.toString().replace(/-/g, " "))}</td> */}
                            <td onClick={()=>rowCheckToogle(item.label,!isRowChecked)}>{capitalizeFirstLetter(item.label.toString().replace(/-/g, " "))}</td>
                            
                            {access.map((itm,itmIndex)=>{
                              const value = isSet(item.data[itm],false);
                              return(
                                <td key={`key${itmIndex}_${itemIndex}`}>
                                <div className="form-check">
                                    <FormInputs 
                                        className="form-check-input"
                                        id={`checkbox_${itmIndex}_${itemIndex}`}
                                        type="Checkbox"
                                        // disabled={false}
                                        name={item.label}
                                        value={(isSet(props.permissions[item.label],[]).indexOf(value)!==-1)?true:false}
                                        changeHandler={(bool)=>{
                                          let readPermission=isSet(item.data['list'],'');
                                          let createPermission=isSet(item.data['create'],'');
                                          let editPermission=isSet(item.data['edit'],'');
                                          let deletePermission=isSet(item.data['delete'],'');
                                          if (Boolean(bool)) {
                                            // Add the value to the permissions array
                                              // Check if readPermission is not already in props.permissions, then add it
                                              if (!isSet(props.permissions[item.label],[]).includes(readPermission)) {
                                                
                                                props.setPermissions({
                                                  ...props.permissions,
                                                  [item.label]:[...isSet(props.permissions[item.label],[]),readPermission, value]
                                                });
                                                }else{
                                                  props.setPermissions({
                                                    ...props.permissions,
                                                    [item.label]:[...isSet(props.permissions[item.label],[]), value]
                                                  });
                                                }
                                                // const PermissionIds = permissonBody.map((screen) => screen.data[itm]);
                                                // const CheckedPermissionIds = isSet(props.permissions[item.label],[]).filter((id) => PermissionIds.includes(id));
                                               
                                          }
                                          else {
                                            // Remove the value from the permissions array
                                            if (value==readPermission) {
                                              props.setPermissions({
                                                ...props.permissions,
                                                [item.label]:[...isSet(props.permissions[item.label],[]).filter((item) => item !== deletePermission &&item!==editPermission && item!==createPermission && item!==value)]
                                              });
                                            }
                                            else{
                                              props.setPermissions({
                                                ...props.permissions,
                                                [item.label]:[...isSet(props.permissions[item.label],[]).filter((item) => item !== value)]
                                              });
                                              // props.setPermissions(props.permissions.filter((item) => item !== value));
                                            }
                                      }
                                    }}
                                    />
                                     </div>
                                  </td>
                              )
                            })}
                            
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
      </div>
    </>
  );
});

export default  withReduxState(Permissions);