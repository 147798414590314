import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userTime: moment().tz(this.props.timeZone ? this.props.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone).toDate(),
    };
    this.radius = this.props.size / 2;
    this.drawingContext = null;
    this.draw24hour = this.props.timeFormat.toLowerCase().trim() === '24hour';
    this.drawRoman = !this.draw24hour && this.props.hourFormat.toLowerCase().trim() === 'roman';
  }

  componentDidMount() {
    this.getDrawingContext();
    this.timerId = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  getDrawingContext() {
    const canvas = this.refs['clockUserTimeCanvas'];
    if (canvas) {
      const devicePixelRatio = window.devicePixelRatio || 1;
      canvas.width = this.props.size * devicePixelRatio;
      canvas.height = this.props.size * devicePixelRatio;
      canvas.style.width = `${this.props.size}px`;
      canvas.style.height = `${this.props.size}px`;

      this.drawingContext = canvas.getContext('2d');
      this.drawingContext.scale(devicePixelRatio, devicePixelRatio);
      this.drawingContext.translate(this.radius, this.radius);
      this.radius *= 0.9;
    }
  }

  tick() {
    const userTimeClock = new Date().toLocaleString("en-US", { timeZone: this.props.timeZone ? this.props.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone });
    this.setState({
      userTime: new Date(userTimeClock),
    });

    const radius = this.radius;
    let ctx = this.drawingContext;
    if (ctx) {
      this.drawFace(ctx, radius);
      this.drawNumbers(ctx, radius);
      this.drawTicks(ctx, radius);
      this.drawTime(ctx, radius);
    }
  }

  drawFace(ctx, radius) {
    ctx.beginPath();
    ctx.arc(0, 0, radius, 0, 2 * Math.PI);
    ctx.fillStyle = 'white';
    ctx.fill();

    const grad = ctx.createRadialGradient(0, 0, radius * 0.95, 0, 0, radius * 1.05);
    grad.addColorStop(0, '#ECEFF1');
    grad.addColorStop(0.5, 'white');
    grad.addColorStop(1, '#ECEFF1');
    ctx.strokeStyle = grad;
    ctx.lineWidth = radius * 0.1;
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(0, 0, radius * 0.05, 0, 2 * Math.PI);
    ctx.fillStyle = '#ECEFF1';
    ctx.fill();
  }

  drawNumbers(ctx, radius) {
    const romans = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'];
    const fontBig = radius * 0.15 + 'px Courier New';
    const fontSmall = radius * 0.075 + 'px Courier New';
    let ang, num;
    const fillText = this.props.timeZone ? this.props.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;
  
    const devicePixelRatio = window.devicePixelRatio || 1;
  
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
  
    for (num = 1; num < 13; num++) {
      ang = num * Math.PI / 6;
      ctx.rotate(ang);
      ctx.translate(0, -radius * 0.78);
      ctx.rotate(-ang);
      ctx.font = fontBig;
      ctx.fillStyle = 'black';
      ctx.fillText(this.drawRoman ? romans[num - 1] : num.toString(), 0, 0);
      ctx.rotate(ang);
      ctx.translate(0, radius * 0.78);
      ctx.rotate(-ang);
  
      // Draw inner numerals for 24-hour time format
      if (this.draw24hour) {
        ctx.rotate(ang);
        ctx.translate(0, -radius * 0.60);
        ctx.rotate(-ang);
        ctx.font = fontSmall;
        ctx.fillStyle = 'red';
        ctx.fillText((num + 12).toString(), 0, 0);
        ctx.rotate(ang);
        ctx.translate(0, radius * 0.60);
        ctx.rotate(-ang);
      }
    }
  
    // Write author text
    // ctx.font = `${fontBig}`;
    // ctx.fillStyle = '#3D3B3D';
    // ctx.translate(0, radius * 0.30);
    // ctx.scale(1 / devicePixelRatio, 1 / devicePixelRatio); // Adjust scaling for text rendering
    // ctx.fillText(fillText, 0, 0);
    // ctx.scale(devicePixelRatio, devicePixelRatio); // Reset scaling
    // ctx.translate(0, -radius * 0.30);
  }
  

  drawTicks(ctx, radius) {
    for (let numTicks = 0; numTicks < 60; numTicks++) {
      const tickAng = (numTicks * Math.PI / 30);
      const tickX = radius * Math.sin(tickAng);
      const tickY = -radius * Math.cos(tickAng);

      ctx.beginPath();
      ctx.lineWidth = radius * 0.010;
      ctx.moveTo(tickX, tickY);
      if (numTicks % 5 === 0) {
        ctx.lineTo(tickX * 0.88, tickY * 0.88);
      } else {
        ctx.lineTo(tickX * 0.92, tickY * 0.92);
      }
      ctx.stroke();
    }
  }

  drawTime(ctx, radius) {
    const now = this.state.userTime;
    let hour = now.getHours();
    let minute = now.getMinutes();
    let second = now.getSeconds();

    // hour
    hour %= 12;
    hour = (hour * Math.PI / 6) + (minute * Math.PI / (6 * 60)) + (second * Math.PI / (360 * 60));
    this.drawHand(ctx, hour, radius * 0.5, radius * 0.05);

    // minute
    minute = (minute * Math.PI / 30) + (second * Math.PI / (30 * 60));
    this.drawHand(ctx, minute, radius * 0.8, radius * 0.05);

    // second
    second = (second * Math.PI / 30);
    this.drawHand(ctx, second, radius * 0.9, radius * 0.02, '#034ea1');
  }

  drawHand(ctx, position, length, width, color) {
    color = color || 'black';
    ctx.beginPath();
    ctx.lineWidth = width;
    ctx.lineCap = 'round';
    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.moveTo(0, 0);
    ctx.rotate(position);
    ctx.lineTo(0, -length);
    ctx.stroke();
    ctx.rotate(-position);
  }

  render() {
    return (
      <div className="Clock" style={{ width: `${this.props.size}px` }}>
        <canvas
          width={this.props.size}
          height={this.props.size}
          ref={'clockUserTimeCanvas'}
        />
      </div>
    );
  }
}

Clock.defaultProps = {
  size: 200, // size in pixels => size is length & width
  timeFormat: 'standard', // {standard | 24hour} => if '24hour', hourFormat must be 'standard'
  hourFormat: 'standard' // {standard | roman}
};

Clock.propTypes = {
  size: PropTypes.number,
  timeFormat: PropTypes.string,
  hourFormat: PropTypes.string,
  clockFor: PropTypes.string.isRequired,
  fillText: PropTypes.string
};

export default Clock;