import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import LookupList from "./LookupList";

const LookupContainer = () => {

  return (
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<LookupList/>} />
      </Routes>
    </Wrap>
  );
};

export default LookupContainer;