import React, { Component } from "react";
import FormInputs from "../../components/UI/FormInputs";
import Buttons from "../../components/UI/Buttons";
import TableGrid from "../../components/UI/TableGrid";
import CompanyService from "../../services/CompanyService";
import {

  capitalizeFirstLetter,
  isObject,
  isSet,
  toQueryString,
} from "../../utils/commonUtils";
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import WithRouter from "../../hoc/WithRouter";
import CptService from "../../services/CptService";
import CsvModal from "./CsvImport/CsvModal";
import CsvService from "../../services/CsvService";
import ErrorLogModal from "./CsvImport/ErrorLogModal";
import PERMISSIONS from "../../utils/AclPermissions";

class CsvImportList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      { label: "Status", key: "status", type: "ProgressBar",},
      { label: "Message", key: "message",type:""},
      { label: "Error Log", key: "error_log",type:"HyperLink"},
      { label: "Type", key: "type",type:""},
      { label: "File", key: "file",type:"FileType"},
      //  more columns as needed
    ];
    this.state = {
      filters: {
        search_query: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          status: 1000,
          data: [],
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "code",
          sort_order: "asc",
          search: {},
        },
      },
      activeErrorLog:{},
      errorLogModelOpen:false
    };
  }

  componentDidMount() {
    this.fetchCsvLog();
  }

  componentDidUpdate = (prevProps, prevState) => {
    // console.log('logs',this.props);
    // Code to run after the component has updated
  };

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  render() {
    const { tableRecords, filters, isModelOpen, activeEditId ,errorLogModelOpen } = this.state;
    return (

      <>
        {isModelOpen ? (
          <CsvModal
            isOpen={this.state.isModelOpen}
            fetchCsvLog={this.fetchCsvLog}
            addFormHandler={this.addFormHandler}
            activeEditId={activeEditId}
            fetchRecords={this.fetchCsvLog}
          />
        ) : (
          <></>
        )}
        {
          errorLogModelOpen?
          <ErrorLogModal
          errorLog={this.state.activeErrorLog}
          addFormHandler={this.errorLogFormHandler}
          />
          :
          ''
        }
          <div className="sh_cardBox">
            <div className="sh_cardHeader">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center formheader">
                <h6 class="text-lg f-500 mb-0">Import Log List</h6>
                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                    <div className="col-12 px-1 d-flex">
                      <div className="input-group input-group-joined input-group-solid">
                        {/* <FormInputs
                          placeholder="CPT"
                          value={filters["search_query"]}
                          changeHandler={(val) =>
                            this.onChangeFiltersHandler("search_query", val)
                          }
                          className="form-control"
                        /> */}
                      </div>
                      {/* <div className="btn-group pl-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => this.submitFiltersHandler()}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => this.clearFiltersHandler()}
                        >
                          Clear
                        </button>
                      </div> */}
                    </div>
                  </div>
                  <div className="addAction">
                    <div className="btn-group">
                      <Buttons
                        clickHandler={() => { this.addFormHandler(); this.setState({ activeEditId: '' }) }}
                        className="btn btn-primary"
                        acl={PERMISSIONS.CSV_IMPORT.CREATE}
                        label={"Import CSV"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                // addFormHandler={() => this.addFormHandler()}
                // // hasPagination={true}
                // fetchTableRecords={(filters) =>
                //   this.fetchCsvLog(filters)
                // }
                {...tableRecords}
              />
            </div>
          </div>
          </>
    );
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const queryfilters = {
      ...tableRecords.pagination,
      page:1,
      search: filters,
    };
    this.fetchCsvLog(queryfilters);
  };
  clearFiltersHandler = async () => {
    await this.setState({
      filters: {},
    });
    this.submitFiltersHandler();
  };
  fetchCsvLog = async (query = {}) => {
    const { tableRecords } = this.state;
    try {
      const queryString = toQueryString({
        ...tableRecords.pagination,
        ...query,
      });
      this.props.Loader(true);
      const response = await CsvService.getCsvLog(queryString);
      console.log('response', response);

      const tableData = response.map((log, index) => ({
        index: index,
        isHeading: false,
        rowId: log.id,
        data: {
          isActive: false,
          status:{status:log.status,percentage:log.processing_percentage},
          message:log.message,
          error_log:{
            value: log.error_log ,
            clickHandler: () => this.errorLogFormHandler(log),
          },
          import_duration:log.import_duration,
          type: capitalizeFirstLetter(log.type),
          file:log.filemanager
        },
      }));
      // console.log('tableData',response?.data);
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            type: "default",
            status: 200,
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: response?.data?.total,
          },
        },
      }));
    } catch (err) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200,
          },
        },
      });
      console.log("DataIsse");
      // console.error("Error fetching companies:", err);
    } finally {
      this.props.Loader(false);
    }
  };

  deleteHandler = async (id) => {
    CptService.deleteCpt(id)
      .then((res) => {
        this.fetchCsvLog();
        this.props.Toast.success("CPT Deleted Succesfully");
      })
      .catch((err) => {
        //
      })
      .finally(() => {
        //
      });
  };
  editHandler = (rowId, data,) => {
    this.setState({
      activeEditId: rowId,
    }, this.addFormHandler())
  };
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
  errorLogFormHandler=(errorLog={})=>{
    this.setState({
      errorLogModelOpen:!this.state.errorLogModelOpen,
      activeErrorLog:errorLog,
    });
  }
}
export default WithRouter(withReduxState(WithRoutify(CsvImportList)));
