import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import InsuranceList from "./InsuranceList";

const InsuranceContainer = () => {

  return (
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<InsuranceList/>} />
      </Routes>
    </Wrap>
  );
};






export default InsuranceContainer;
