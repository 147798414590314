import { useEffect, useState } from "react";
import FormInputs from "../../components/UI/FormInputs";
import UserService from "../../services/UserService";
import { Link } from "react-router-dom";
import { OK, SOMETHING_WENT_WRONG_ERROR_MESSAGE } from "../../utils/constant";
import WithRoutify from "../../hoc/WithRoutify";
import { formatPhoneNumberUS, getBase64String, isObject, isSet } from "../../utils/commonUtils";
import { useDispatch } from "react-redux";
import { activeProfileDetails, setProfileDetails } from "../../store/SessionSlice";
import { useSelector } from "react-redux";
import withReduxState from "../../hoc/wReduxState";


const Profile = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [validation,setValidation] = useState({});
  const [formData, setFormData] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    props.Loader(true);
    UserService.getUserById(props.profile.id)
      .then((res) => {
        setFormData(res);
      })
      .catch()
      .finally(()=>{
        props.Loader(false);
      });
  }, [isEdit]);
  useEffect(()=>{
    if (isEdit==false) {
      setProfileImage(props.profile.profile_picture);
      setValidation({});
    }
  },[isEdit])
  //form input field
  let inputFields = [
    {
      label: "First Name",
      type: "TextInput",
      className: "form-control",
      value: formData.first_name,
      name: "first_name",
      placeholder:"First Name",
      required:true,
    },
    {
      label: "Last Name",
      type: "TextInput",
      className: "form-control",
      name: "last_name",
      placeholder:"Last Name",
      value: formData.last_name,
      required:true,
    },
    {
      label: "DOB",
      type: "Date",
      className: "form-control",
      placeholder:"Date of Birth",
      name: "dob",
      value: formData.dob,
      required:true,
      disableFuture:true
    },
    {
      label: "Email",
      type: "TextInput",
      className: "form-control",
      value: formData.email,
      placeholder:"Email",
      name: "email",
      required:true,
    },
    {
      label: "Phone",
      type: "Phone",
      className: "form-control",
      name: "phone_number",
      placeholder:"Phone Number",
      value: formData.phone_number,
      required:true,
      displayValue:formatPhoneNumberUS(formData.phone_number),
    },
    {
      label: "Preferred Language",
      type: "RadioGroup",
      options:[
        {label: 'English', value: 'en', className:'form-check form-check-inline d-inline-flex pl-0'},
        ,{label:'Spanish',value:'es'}
        ],
      className: "form-control",
      name: "preferred_language",
      value: formData.preferred_language,
      id:'preferred_language',
      // col:'col-sm-6 f-400 text-sm  mb-0 opacity-75',
      // labelWrap:true,
      // labelStyle:{  color: '#231B68'} ,
      required:true,
      displayValue:formData.preferred_language=='en'?"English":"Spanish",
    },
  ];
  //edit form change handler
  const changeHandler = (fieldName,val) => {
    setValidation({...validation,[fieldName]:''});
    setFormData({ ...formData, [fieldName]: val });
  };


  //submit(or)update handler
  const updateHandler = () => {
    const payload={
      ...formData,
      ['profile_picture']:profileImage
    };
    props.Loader(true);
    UserService.updateUserProfile(props.profile.id, payload).then((res) => {
      if(res.status && res.status=="ERROR"){
        let updateValidation = {...validation};
        if(isObject(res.data)){
          for (let key in res.data) {
            updateValidation = {
              ...updateValidation,
              [key]:res.data[key].join(',')
            }
          }
        }else{
          props.Toast.error(SOMETHING_WENT_WRONG_ERROR_MESSAGE);
        }
        if(Object.keys(updateValidation).length>0){
          setValidation(updateValidation);
        }
      }
      else{
        props.Toast.success("User Updated Successfully");
        setIsEdit(false);
        let name=`${res.first_name} ${res.last_name}`;
        let profile_picture=res.profile_picture;
        dispatch(setProfileDetails({key:props.profile.id,value:{...props.profile,'name':name,'profile_picture':profile_picture}}));
      }
    })
    .catch(err=>{
        //
    })
    .finally(()=>{
       props.Loader(false);
    })
  };
  const handleImageUpload = async(val) => {
    const file = val.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    if (file.size >= maxSizeInBytes) {
      console.error("Selected image exceeds maximum size.");
      props.Toast.error("Selected image exceeds maximum size.");
      return; // Exit the function
    }
    const { base64WithoutPrefix, fileName } = await getBase64String(file);
    setIsEdit(true);
    setProfileImage(`data:image/png;base64,${base64WithoutPrefix}`);
  };
  const removeProfilePicture = () => {
    setProfileImage(null); // Clear the profile image state
    setIsEdit(true); // Set edit mode to true to trigger updates if needed
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };
  return (
    <>
       
        <div className="sh_innerPortview">
          <div className="row h-100">
            <div className="col-12 col-lg-3">
              <div className="sh_cardBox h-auto">
                <div className="sh_cardHeader">
                  <h6 className="text-gray-900 mb-0">Profile Picture</h6>
                </div>
                <div className="sh_cardBody text-center">
                  <img
                    className="img-account-profile rounded-circle mb-2"
                    src={profileImage?profileImage:"assets/img/User_profile.svg"}
                    alt=""
                  />
                  <div className="small font-italic text-muted mb-4">
                    JPG or PNG no larger than 2 MB
                  </div>
                  
                  <div className="d-flex align-items-center justify-content-center">
                  <button className="btn btn-light btn-sm mr-2" type="button" onClick={triggerFileInput}>
                    Upload new image
                  </button>
                  {profileImage && (
                    <button className="btn btn-danger btn-sm mr-2" type="button" onClick={removeProfilePicture}>
                      Remove
                    </button>
                  )}
                </div>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  accept="image/jpeg, image/png"
                  onChange={handleImageUpload}
                />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-9">
              <div className="sh_cardBox">
                <div className="sh_cardHeader">
                  <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                    <h6 className="text-gray-900 mb-0">Profile Details</h6>
                    <div className="addAction d-flex justify-content-between align-items-center">
                      {!isEdit ? (
                        <Link
                          className="btn btn-icon btn-transparent-dark"
                          title="Edit Profile"
                          onClick={() => setIsEdit(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 16 16"
                            fill="#0061f2"
                            id="edit"
                            type="Edit"
                          >
                            <path
                              fill="#0061f2"
                              d="M12.2417871,6.58543288 L6.27024769,12.5583865 C5.94985063,12.8787836 5.54840094,13.1060806 5.1088198,13.2159758 L2.81782051,13.7887257 C2.45163027,13.8802732 2.11993389,13.5485768 2.21148144,13.1823866 L2.78423127,10.8913873 C2.89412655,10.4518062 3.12142351,10.0503565 3.44182056,9.72995942 L9.41336001,3.75700576 L12.2417871,6.58543288 Z M13.6567078,2.3434993 C14.4377564,3.12454789 14.4377564,4.39087785 13.6567078,5.17192643 L12.9488939,5.8783261 L10.1204668,3.04989898 L10.8282807,2.3434993 C11.6093293,1.56245072 12.8756592,1.56245072 13.6567078,2.3434993 Z"
                            ></path>
                          </svg>
                        </Link>
                      ) : null}

                      {isEdit ? (
                    <>
                     
                      <button onClick={(event) => {
                          setIsEdit(false);
                        }} type="button" class="btn-close" aria-label="Close" title="Cancel"></button>
                    </>
                  ) : (
                    ""
                  )}


                     
                    </div>
                  </div>
                </div>

                <div className="sh_cardBody">
                  <div className="row mx-5">
                    {inputFields &&
                      inputFields.map((field, index) => (
                        <div className={`col-md-6`} key={index}>
                          <div className="form-group">
                            <div>
                            <label className="f-400 text-sm text-truncate mb-0 opacity-75">
                              {field.label}
                            </label>
                            </div>
                            {isEdit ? (
                              <FormInputs
                                {...field}
                                changeHandler={(val) =>
                                  changeHandler(field.name, val)
                                }
                              />
                            ) : (
                              <h6 className="font-weight-bold text-sm text-truncate">
                                {
                                 isSet(field.displayValue,isSet(field.value,'--'))     
                                }
                                
                              </h6>
                            )}
                            {isSet(validation[field.name],'')!=='' ? <span className="text-danger m-1">{isSet(validation[field.name],'')}</span> : ''}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                  {isEdit ? (
                    <>
                    <div className="sh_cardFooter justify-content-center">
                      
                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        onClick={()=>updateHandler()}
                      >
                        Update
                      </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
              </div>
            </div>
          </div>
        </div>
       
    </>
  );
};
export default  withReduxState(WithRoutify(Profile));
