// routes.js
import Dashboard from "../containers/DashboardContainer";
import User from "../containers/UserContainer";
import Roles from "../containers/RolesContainer";
import Unauthorized from "./Unauthorized";
import ClinicContainer from "../containers/ClinicContainer";
import { ClinicsLocationContainer } from "../containers/ClinicLocationContainer/index";
import organisationContainer from "../containers/OrganisationContainer";
import Profile from "../containers/ProfileContainer/Profile";
import ChangePassword from "../containers/ProfileContainer/ChangePassword";
import CompanyContainer from "../containers/CompanyContainer";
import EmployeeContainer from "../containers/EmployeeContainer";
import Appointments from "../containers/AppointmentsContainer";
import InsuranceContainer from "../containers/InsuranceContainer";
import DivisionContainer from "../containers/DivisionContainer";
import LookupContainer from "../containers/LookupContainer";
import ActivitiesContainer from "../containers/ActivitiesContainer";
import BasicPhysicalContainer from "../containers/BasicPhysicalContainer";
import EmployeeVisitContainer from "../containers/EmployeeVisitContainer";
import ProviderContainer from "../containers/ProviderContainer";
import CPTContainer from "../containers/CPTContainer";
import LaboratoryContainer from "../containers/LaboratoryContainer";
import LabContainer from "../containers/LabContainer";
import ConsentFormContainer from "../containers/ConsentFormContainer";
import VaccineAdministrationContainer from "../containers/ActivitiesContainer/VaccineAdministrationContainer";
import ResultContainer from "../containers/ResultContainer";
import ReportsContainer from "../containers/ReportsContainer";
import CSVContainer from "../containers/CSVContainer";
import EzReaderContainer from "../containers/EzReaderContainer";
import NotificationContainer from "../containers/NotificationContainer";
import ConfigurationContainer from "../containers/ConfigurationContainer";
import HL7Container from "../containers/HL7Container";

const defaultConfig = {
  layoutType: "App",
  routeType: "Auth",
};
const PublicRoutes = [
  {
    path: "/",
    exact: true,
    element: Dashboard,
    acl: ["any"],
    ...defaultConfig,
    // Add more properties as needed
  },
  {
    path: "/unauthorized",
    exact: true,
    element: Unauthorized,
    acl: ["any"],
    ...defaultConfig,
    // Add more properties as needed
  },
  {
    path: "/dashboard",
    exact: true,
    element: Dashboard,
    ...defaultConfig,
    acl: ["any"],
    // Add more properties as needed
  },
  {
    path: "/users/*",
    exact: true,
    element: User,
    acl: ["user-list", "user-edit"],
    ...defaultConfig,
  },
  {
    path: "/roles/*",
    exact: true,
    element: Roles,
    acl: ["role-list", "role-edit"],
    ...defaultConfig,
  },
  {
    path: "/labs/*",
    exact: true,
    element: LabContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "/clinics/*",
    exact: true,
    element: ClinicContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
    // Add more public routes as needed
  },
  {
    path: "/providers/*",
    exact: true,
    element: ProviderContainer,
    acl: ["provider-list"],
    ...defaultConfig,
  },
  {
    path: "/clincs-department/*",
    exact: true,
    element: ClinicsLocationContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "/organisation/*",
    exact: true,
    element: organisationContainer,
    acl: ["organization-list"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "/profile",
    exact: true,
    element: Profile,
      acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "/change-password",
    exact: true,
    element: ChangePassword,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/company/*",
    exact: true,
    element: CompanyContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/employee/*",
    exact: true,
    element: EmployeeContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/visits/*",
    exact: true,
    element: EmployeeVisitContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/white-board/*",
    exact: true,
    element: EmployeeVisitContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/appointments/*",
    exact: true,
    element: Appointments,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/insurance/*",
    exact: true,
    element: InsuranceContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/division/*",
    exact: true,
    element: DivisionContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/lookup/*",
    exact: true,
    element: LookupContainer,
      acl: ["any"],
    ...defaultConfig,
  },
 
  {
    path: "/activities/*",
    exact: true,
    element: ActivitiesContainer,
      acl: ["any"],
    ...defaultConfig,
  },

  {
    path: "/consentForm/*",
    exact: true,
    element: ConsentFormContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  
  {
    path: "/basic-physical/*",
    exact: true,
    element: BasicPhysicalContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/cpt-code/*",
    exact: true,
    element: CPTContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/laboratory/*",
    exact: true,
    element: LaboratoryContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "/vaccine-administration/*",
    exact: true,
    element: VaccineAdministrationContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "result/*",
    exact: true,
    element: ResultContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "reports/*",
    exact: true,
    element:ReportsContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "csv-import/*",
    exact: true,
    element: CSVContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "failed-ez-reader/*",
    exact: true,
    element: EzReaderContainer,
    acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "notification-log/*",
    exact: true,
    element: NotificationContainer,
    acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "configure/*",
    exact: true,
    element: ConfigurationContainer,
    acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "hl7/*",
    exact: true,
    element: HL7Container,
    acl: ["any"],
    ...defaultConfig,
  },
  
  
];

export default PublicRoutes;
