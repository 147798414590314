export const isLaboratoryVisitCategory=(visit_category='')=>{
    return visit_category==='Employer Services - Labs' ||visit_category==='Urgent Care - Labs Only';
}
export const isPhysicalVisitCategory=(visit_category='')=>{
    return visit_category==='Employer Services - Physicals';
}
export const getStatusAndColor = (resultStatus = 'pending') => {
    let statusClr = 'blue';
    let status = resultStatus;

    // Adjust status based on specific resultStatus values
    if (resultStatus === 'Completed/Administrated') {
        status = 'passed';
    } else if (resultStatus === 'Ordered') {
        status = 'pending';
    } else if (resultStatus === 'The subject Passed the fit test') {
        status = 'passed';
    } else if (resultStatus === 'The subject Failed the fit test') {
        status = 'failed';
    }

    // Determine color based on the updated status
    if (status.toLowerCase() === 'passed') {
        statusClr = 'green';
    } else if (status.toLowerCase() === 'failed') {
        statusClr = 'red';
    }

    return { statusClr, status };
};
export const getActiveIds = (tableRecords) => {
    const { tableRows } = tableRecords;
    const activeIds = tableRows.data
        .filter((item) => item.data && item.data.isActive === 1)
        .map((item) => item.rowId)
        .join(',');
    return activeIds;
}
export const anyCheckboxSelected = (tableRecords) => {
    const { tableRows } = tableRecords;
    if (!tableRows || !Array.isArray(tableRows.data)) {
      return false;
    }

    return tableRows.data.some(item => item.data?.isActive === 1 || item.data?.isActive === true);
  }