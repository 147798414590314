import FormInputs from "../../../components/UI/FormInputs";

const Mappingfield = (props) => {
    const {type,csvHeaders,mappingFieldChangeHandler,mappingFormData,SystemFields}=props
    return (<>
      <div class="card-body tab-content p-0">
        <div class="sh_cardBox">
          <div class="sh_cardHeader">
            <div class="form-group app-heading-tab d-flex mb-0 justify-content-between align-items-center">
              <h6 class="text-lg f-500 mb-0">Field Mapping List </h6>
            </div>
          </div>
          <div class="sh_cardBody p-0">
  
            <table class="table table-hover dataTable nowrap my-0 table-striped table-borderless" width="100%" cellspacing="0" >
              <thead>
                <tr>
                  <th class="datatable-ascending"><a href="#!" >System Fields</a></th>
                  <th class="datatable-ascending"><a href="#!" >Mapping Fields</a></th>
                </tr>
              </thead>
              <tbody>
                {
                  SystemFields && SystemFields.map((field, fieldInd) => {
                    return (
                      <>
                        <tr >
                          <td class="forTable_textEllipsis" title={field.label}>{field.label}</td>
                          <td class="forTable_textEllipsis">
                            <FormInputs type='SelectList'
                              options={csvHeaders}
                              changeHandler={(val,e)=>mappingFieldChangeHandler(field.name,val)}
                              name={field.name}
                              required={field.required==true}
                              value={mappingFormData[field.name]}
                            />
                          </td>
                        </tr>
                      </>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>);
  }
  export default Mappingfield;