import FormInputs from "../../../components/UI/FormInputs";
import { isSet } from "../../../utils/commonUtils";

const CompanySplitedFields = (props) => {
  const { inputFields, validation, formData, changeHandler } = props;
  return (
    <>
      {inputFields &&
        inputFields.map((section, sectionIndex) => {
          return section && (
            <>
              <div className="card mb-2">
                <a
                  href={`#collapseCardExample_${sectionIndex}`}
                  className="d-block sh_accordionheader card-header"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="true"
                  aria-controls="collapseCardExample"
                  style={{
                    position: "sticky",
                    top: "-15px",
                    zIndex: "1",
                  }}
                >
                  <h6 className="m-0 text-md">
                    {section.title}
                  </h6>
                </a>

                <div
                  className="collapse show"
                  id={`collapseCardExample_${sectionIndex}`}
                  data-bs-parent="#modal111"
                >
                  <div className="card-body">
                    <div className="row">
                      {section.data && section.data.map((data, dataInd) => {
                        return data && (
                          <>
                            <div className={isSet(data.col, "col-lg-4 col-6")}>

                              <div className={`form-group ${isSet(data.isLabelAppend, false) === true ? 'form-check form-check-solid' : ''}`} >
                                {(isSet(data.isLabelAppend, false) === false) &&
                                  <label className="mb-0 text-sm opacity-75 text-truncate">
                                    {data.label}
                                  </label>}


                                <div
                                  className={data.type == 'MultiSelectDropDown' ? 'd-flex' : ''}
                                  key={dataInd}
                                >
                                  <FormInputs
                                    {...data}
                                    id={isSet(data.id, data.name)}
                                    value={isSet(formData[data.name], "")}
                                    className={isSet(data.className, "form-control form-control-solid")

                                    }
                                    changeHandler={(value, e) =>
                                      changeHandler(value, data.name, e)
                                    }
                                  />
                                  {(isSet(data.isLabelAppend, false) === true) &&
                                    <label className="form-check-label" htmlFor={data.name}>
                                      {data.label}
                                    </label>}

                                </div>
                                {isSet(validation[data.name], "") !==
                                  "" ? (
                                  <span className="text-danger m-1">
                                    {isSet(validation[data.name], "")}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </>
  );
}
export default CompanySplitedFields