import React, { Component } from 'react';
import FormInputs from '../../components/UI/FormInputs';
import Buttons from '../../components/UI/Buttons';
import TableGrid from '../../components/UI/TableGrid';
import CompanyService from '../../services/CompanyService';
import { convertToCurrency, convertUnderScoreStrings, getOrganizationId, isObject, toQueryString } from '../../utils/commonUtils';
import WithRoutify from '../../hoc/WithRoutify';
import { OK } from '../../utils/constant';
// import { isGlobalAdmin } from '../../utils/aclUtils';
import withReduxState from '../../hoc/wReduxState';
import WithRouter from '../../hoc/WithRouter';
import ClinicService from '../../services/ClinicService';
import OrganizationService from '../../services/OrganizationService';
import AddPhysical from './AddPhysical';
import AddPhysicalFromTemplate from './AddPhysicalFromTemplate';

class CompanyPhysical extends Component{
        constructor(props) {
          super(props);
          // Initialize state or bind methods if needed
          const tableHeaders =  [
              { label: '',key:'isActive',type:'checkBox',changeHandler:this.checkBoxchangeHandler},
              { label: 'Physical',key:'physical_name',type:'',inputType:'TextInput'},
              { label: 'Bundle Price',key:'bundle_price',inputType:'TexInput'},
              { label: 'Itemize Pricing',key:'itemize_pricing',inputType: "Checkbox"},
              { label: 'Reporting Email',key:'reporting_email',inputType: "TexInput"},
              { label: 'Drug Screen',key:'drug_screen',inputType: "Checkbox"},
              { label: 'Remind In',key:'reminder'},
            
              { label: 'Action',key:'action',type:'Actions'},
              // Add more columns as needed
            ];
          this.state = {
            filters:{
            },
              tableRecords:{
                  tableHeaders:tableHeaders,
                  tableRows:{
                      data:[]
                  },
                pagination: {
                  page: 1,
                  perPage: 25,
                  totalRecords: 0,
                  sort_by: "physical_name",
                  sort_order: "asc",
                  search: {},
                },
              },
              activeEditId:null,
              organizationOption:[],
              clinicOptions:[],
            // Your state variables go here
            isModelOpen:false,
            organization_id:''
        }
      }
      
        componentDidMount() {
          // let userProfile
          this.fetchPhysicalList(); 
          this.fetchOrganization();   
        //   this.fetchClinicList();   
          // Code to run after the component has mounted
        }
      
        componentDidUpdate = (prevProps, prevState)=> {
          // console.log('logs',this.props);
          // Code to run after the component has updated
        }
      
        componentWillUnmount() {
          // Code to run before the component is unmounted
        }
      
        handleEvent = () => {
          // Handler for events (e.g., button click)
        };
        addFormHandler=()=>{
          this.setState({isModelOpen:!this.state.isModelOpen})
        }

        addFromTemplateHandler=()=>{
            this.setState({isFromTemplateModelOpen:!this.state.isFromTemplateModelOpen})
          }
      
        onChangeFiltersHandler =(key,val)=>{
          const {filters} = this.state;
          this.setState({
            filters:{
              ...filters,
              [key]:val
            }
          })
        }
        render() {
          const {tableRecords,filters,isModelOpen,isFromTemplateModelOpen} = this.state;
          const {params} = this.props;
          return (
            <>
            {(isModelOpen)?
             <AddPhysical
              modelTitle={"Add Physical"}
              isOpen={this.state.isModelOpen}
              fetchPhysicalList={this.fetchPhysicalList}
              addFormHandler={this.addFormHandler}
              clinicOptions={this.state.clinicOptions}
              organizationOption={this.state.organizationOption}
              activeEditId={this.state.activeEditId}
              companyId={params.companyId}
            />
            :(isFromTemplateModelOpen)?
                <AddPhysicalFromTemplate
                modelTitle={"Add Physical From Template"}
                isOpen={this.state.isFromTemplateModelOpen}
                fetchPhysicalList={this.fetchPhysicalList}
                addFormHandler={this.addFromTemplateHandler}
                clinicOptions={this.state.clinicOptions}
                organizationOption={this.state.organizationOption}
            />:
           <></>}
        
                  <div className="sh_cardBox">
                    <div className="sh_cardHeader"> 
                          <div className="form-group d-flex mb-0 row justify-content-between align-items-center">

                            <h6 class="text-lg f-500 mb-0 ml-2">
                                <span className="badge back_btn  mr-2 ">
                                    <Buttons
                                    label={"Back"}
                                    iconType={"Back"}
                                    acl={"any"}
                                    href={'/company'} />
                                </span>
                                Physicals - {params.companyName}
                            </h6>
                            <div className="d-flex">
                            <div className="form-group-fields mr-2">
                              <div className="col-12 px-1 d-flex">
                                <div className="input-group input-group-joined input-group-solid"> 
                                  <FormInputs 
                                    placeholder="Physical"
                                    value={filters["search_query"]}
                                    changeHandler={(val)=>this.onChangeFiltersHandler('search_query',val)}
                                    className="form-control"/>
                                  
                                </div>
                                <div className="btn-group pl-2">
                                    <button className="btn btn-primary" onClick={()=>this.submitFiltersHandler()}>
                                      Search
                                    </button>
                                    <button className="btn btn-outline-secondary" onClick={()=>this.clearFiltersHandler()}>
                                      Clear
                                    </button>
                                  </div>
                              </div>
                                
                            </div>
                            <div class="addAction">                          
                           
                              <div className="btn-group">
                                <Buttons
                                  clickHandler={() => { this.addFormHandler(); this.setState({ activeEditId: '' }) }}
                                  className="btn btn-primary mr-1" 

                                  acl={'company-create'}
                                  label={'Add Physical'}
                                />
                                <Buttons
                                  clickHandler={()=>this.addFromTemplateHandler()}
                                  className="btn btn-primary mr-1" 
                                  acl={'company-create'}
                                  label={'Add from Templates'}
                                />
                               
                              </div>
                            </div>
                          </div>
                        </div>
                         
                    </div>
      
                    <div className="sh_cardBody p-0">
                      
                        <TableGrid
                          fetchTableRecords={(filters) =>
                            this.fetchPhysicalList(filters)
                          }
                          hasPagination={true} 
                            {...tableRecords}
                        />
                       
                    </div>
                  </div>
                 
            </>
          );
        }
        fetchOrganization=async()=>{
          if (this.props.profile.is_multiple_organization===true) {
            let org=await OrganizationService.getOrganization();
            let orgOpt=[{label:"-Select Organization-",value:''}];
            org.data.data.forEach(itm=>{
              orgOpt.push({label:convertUnderScoreStrings(itm.name),value:itm.organization_uuid});
            });
            this.setState({organizationOption:orgOpt});
          }
        }
     
        submitFiltersHandler =async ()=>{
          const { filters, tableRecords } = this.state;
          const queryfilters = {
            ...tableRecords.pagination,
            page:1,
            search: filters,
          };
            this.fetchPhysicalList(queryfilters)
        }
        clearFiltersHandler = async ()=>{
          await this.setState({
            filters:{
            }
          });
          this.submitFiltersHandler();
        }
        fetchPhysicalList =async (query={})=>{
          this.props.Loader(true);
          const { tableRecords } = this.state;
          const queryString = toQueryString({
            ...tableRecords.pagination,
            ...query,
          });
          const {params} = this.props;
            await CompanyService.getCompaniesPhysical(params.companyId,queryString)
            .then((res) => {
              let tableData = [];
              res?.data?.data.map((company, companyIndex) => {
                tableData = [
                  ...tableData,
                  {
                    companyIndex: companyIndex,
                    isHeading: false,
                    rowId: company.company_physical_id,
                    data: {
                      isActive: false,
                      physical_name: company.physical_name,
                      bundle_price:convertToCurrency(company.bundle_price),
                      reporting_email: company.reporting_email,
                      drug_screen:company.drug_screen==1?"Yes":"No",
                      itemize_pricing:company.itemize_pricing==1?"Yes":"No",
                      reminder:company.reminder,
                      action: [
                        {
                          className:
                            "btn btn-icon btn-transparent-dark",
                          iconType: "Activities",
                          acl: "any",
                          title: "Physical",
                          href:`/company/physical/${params.companyId}/${params.companyName}/activities/${company.company_physical_id}/${encodeURIComponent(company.physical_name)}`
                        }, 
                        {
                          className:
                            "btn btn-icon btn-transparent-dark",
                          iconType: "Edit",
                          title: "Edit",
                          type: "GridEdit",
                          acl: "company-edit",
                          clickHandler: (rowId, data) =>
                            this.editHandler(rowId, data),
                          updateHandler: () =>
                            this.updateHandler(company.company_physical_id),
                          onChangeFormDataInEdit: (key, val) =>
                            this.onChangeFormDataInEdit(key, val),
                        },
                        {
                          className:
                            "btn btn-icon btn-transparent-dark",
                          iconType: "Remove",
                          acl: "company-delete",
                          title: "Delete",
                          isDelete:true,
                          clickHandler: () =>
                            this.deleteHandler(company.company_physical_id),
                        }, 
                      ],
                    },
                  },
                ];
              });
              this.setState({
                tableRecords: {
                  ...this.state.tableRecords,
                  tableRows: {
                    type: "default",
                    data: tableData,
                  },
                  pagination: {
                    ...this.state.tableRecords.pagination,
                    ...query,
                    totalRecords: res?.data?.total,
                  },
                },
              });
            })
            .catch((err) => {
              // console.log("err===>", err);
            })
            .finally(() => {
              this.props.Loader(false);
            });
        }
        deleteHandler = async (physicalId)=>{
            const {params} = this.props;
            this.props.Loader(true);
            CompanyService.deleteCompaniesPhysical(params.companyId,physicalId)
          .then(() => {
            // console.log("res==>", res);
            //toast and show error logic
            this.fetchPhysicalList();
            this.props.Toast.success('Physical Deleted Successfully');
          })
          .catch((err) => {
            this.props.Loader(false);
          })
          .finally(() => {
            this.props.Loader(false);
          });  
              
        }
        editHandler = (rowId,data,clinic_id)=>{
          this.setState({
            activeEditId:rowId,
          },this.addFormHandler()); 
          
        }
        onChangeFormDataInEdit = (key,val)=>{
          this.setState((prevState) => ({
            formDataInEdit: {
              ...prevState.formDataInEdit,
              [key]: val,
              validation: {
                ...prevState.formDataInEdit.validation,
                [key]: '', // Set validation empty
              },
            },
          }));
        }
        updateHandler = async (physicalId)=>{
          const { reporting_email,reminder, itemize_pricing,drug_screen,bundle_price,physical_name} = this.state.formDataInEdit;
          const {params} = this.props;
          const payload = { reporting_email, reminder, physical_name,itemize_pricing,drug_screen,bundle_price};
            CompanyService.updateCompaniesPhysical(params.companyId,physicalId, payload)
              .then((res) => {
                if (res.code == OK) {
                  //toast add logic
                  this.editHandler(null, {}, null);
                  this.fetchPhysicalList();
                  this.props.Toast.success("Physical updated Successfully");
                }
                else if (res.status=="ERROR"&&res.data) {
                  if (isObject(res.data)) {
                    let validaitons={};
                    for (let key in res.data) {
                     validaitons={...validaitons,[key]:res.data[key][0]};
                    }
                      this.setState((prevState) => ({
                      formDataInEdit: {
                        ...prevState.formDataInEdit,
                        validation: validaitons,
                      },
                    }));
                  }
                }
              })
              .catch((err) => {
                //
              })
              .finally(() => {
                //
              });
        }
        checkBoxchangeHandler = (value, {e}) => {
          const rowId = e.target.id;
          const { tableRows } = this.state.tableRecords;
          //update data based on check box click 
          const updatedData = tableRows.data.map((item) => {
            if (item.rowId === rowId) {
              item.data.isActive = value;
            }
            return item;
          });
        
          this.setState((prevState) => ({
            tableRecords: {
              ...prevState.tableRecords,
              tableRows: {
                data: updatedData,
              },
            },
          }));
        };
      }
export default WithRouter(withReduxState(WithRoutify(CompanyPhysical)));