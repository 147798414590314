import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import ConfigurationList from "./ConfigurationList";


const ConfigurationContainer=()=>{
    return(
        <>
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<ConfigurationList />} />
        
      </Routes>
      </Wrap>
      </>
    );
}
export default ConfigurationContainer; 

