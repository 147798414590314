import Modal from "react-bootstrap/Modal";
import FormInputs from "../../components/UI/FormInputs";
import { isSet, validateInputField } from "../../utils/commonUtils";
import { useEffect, useState } from "react";

const GroupEditForm = (props) => {
  let {groupEditFormData}=props;
  const [validation,setValidation]=useState({});
  
  const inputFields = [ 
    {
      type: "TextInput",
      label: "First Name",
      placeholder: "First Name",
      className: "form-control form-control-solid",
      name:"first_name",
      value: groupEditFormData.first_name,
      required:true,
    },
    {
      type: "TextInput",
      label: "Last Name",
      placeholder: "Last Name",
      className: "form-control form-control-solid",
      name:"last_name",
      value: groupEditFormData.last_name,
      required:true,

    },
    {
      type: "Date",
      label: "Date of Birth",
      placeholder: "",
      className: "form-control form-control-solid",
      name:"dob",
      value: groupEditFormData.dob,
      },
      {
        type: "Checkbox",
        label: "Bulk Email Resending Resend Email? ..  .",
        placeholder: "",
        className: "form-check",
        name:"bulkEmailResend",
        value:groupEditFormData['bulkEmailResend'],
        },
  ];
  const onSubmit = async () => {
    let hasValidationErrors = false;
    await Promise.all(
      inputFields.map(async (itm) => {
        if (itm.required) {
          let isValid = await validateInputField(itm.value, itm.type, true);
          if (!isValid) {
            setValidation({
              ...validation,
              [itm.name]: `${itm.label} is Required`,
            });
            hasValidationErrors = true;
          }
        }
      })
    );
    if (!hasValidationErrors) {
      setValidation({})
      props.submitHandler();
    }
  };
  
  const onchange=(val,e)=>{
    setValidation({...validation,[e.target?.name]:''});
    props.onChangeHandler(val,e);
  }
  // console.log("validation",validation);
  // console.log('inputFields==>',inputFields);
  useEffect(() => {
    if (props.activeData && props.activeData.length === 1) {
      const { first_name, last_name, dob } = props.activeData[0].data;
      groupEditFormData.first_name = first_name;
      groupEditFormData.last_name = last_name;
    } else {
      groupEditFormData.first_name = '';
      groupEditFormData.last_name = '';
    }
  }, [props.activeData]);
  return (
    <>
      <Modal show={props.isOpen} onHide={props.formHandler}                                                         >
        <Modal.Header closeButton>
          <Modal.Title>
          <h6 className="modal-title text-gray-900">
              {props.modelTitle}
              <h6>Editing {props.activeData.length} Record(s)</h6>
          </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row ">
            {inputFields &&
              inputFields.map((field, index) => {
                const className = (isSet(validation[field.name]) ?'was-validated':'')
                
                return(
                  <div className={`col-12 ${className}`} key={index}>
                    <div className="form-group">
                      <label className="text-gray-900 text-md opacity-75"> {field.label} </label>
                      <FormInputs
                       {...field} changeHandler={onchange} />
                      {isSet(validation[field.name],'')!=='' ? <span className="text-danger m-1">{isSet(validation[field.name],'')}</span> : ''}
                    </div>
                  </div>
                )
              })}
          </div>
          </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
        <button className="btn btn-primary rounded-pill" onClick={()=>props.formHandler()}>
            Cancel
          </button>
          <button className="btn btn-primary rounded-pill" onClick={()=>onSubmit()}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default GroupEditForm;
