import { Modal } from "react-bootstrap";
import FormInputs from "../../components/UI/FormInputs";
import { useState } from "react";
import Buttons from "../../components/UI/Buttons";
import { isSet } from "../../utils/commonUtils";

const SftpSecurityModal = (props) => {
    const { isOpen, onClose ,setShowPasswordModal } = props;
    const [validation, setValidation] = useState({});
    const [formData, setFormData] = useState({});
    const changeHandler = (val, fieldName, e) => {
        setFormData({
            ...formData,
            [fieldName]: val,
        });
        setValidation({ ...validation, [fieldName]: "" });
    };
    const Authenticate = () => {
        if (formData?.password&&formData?.password=="snap@123") {
            setShowPasswordModal(false); 
            return;
        }
        setValidation({...validation,['password']:"Invalid Password"});
    }
    return (
        <>
            <Modal
                show={true}
                onHide={onClose}
                dialogClassName="hResponsive modal-sm"
                keyboard={false}
                backdrop="static"
            >
                <div className="sh_modalBox">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h6 className="text-lg mb-0 font-weight-bold">{"Enter Password"}</h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={`col-12`} key={0}>
                            <div className="form-group">
                                <label className="text-gray-900 text-md opacity-75">
                                    Password
                                </label>
                                <FormInputs
                                    changeHandler={(val, e) => changeHandler(val, 'password', e)}
                                    value={formData.password}
                                />
                                {isSet(validation['password'], "") !== "" ? (
                                    <span className="text-danger m-1">
                                        {isSet(validation['password'], "")}
                                    </span>
                                ) : ("")}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <Buttons
                            type='Single'
                            className="btn btn-primary"
                            clickHandler={() => (Authenticate())}
                            label='Enter'
                            acl='any' />
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
}
export default SftpSecurityModal;