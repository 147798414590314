import Modal from "react-bootstrap/Modal";
import FormInputs from "../../components/UI/FormInputs";
import { convertUnderScoreStrings, getOrganizationId, isObject, isSet } from "../../utils/commonUtils";
import { useEffect, useState } from "react";
import { CREATED, OK } from "../../utils/constant";
import WithRoutify from "../../hoc/WithRoutify";
import { useSelector } from "react-redux";
import Buttons from "../../components/UI/Buttons";
import withReduxState from "../../hoc/wReduxState";
import OrganizationService from "../../services/OrganizationService";
import Clinicervice from "../../services/ClinicService";
import ClinicDepartmentService from "../../services/ClinicDepartmentService";
import DropDownService from "../../services/DropDownService";
import { organizationList } from "../../store/OrganizationSlice";

const AddClinicDepartment = (props) => {
  const { isOpen, addFormHandler, modelTitle, activeEditId, Toast, fetchClinicDepartment } = props;
  const [clinicsListOption, setClinicsListOptions] = useState([{ label: '-Select Clinic-', value: '' }]);
  const [validation, setValidation] = useState({});
  const stateList = useSelector(state => state.session.profileStateList)
  const [formData, setFormData] = useState({});
  const inputFields = getInputFields();
  const organizationListOptions = useSelector(organizationList);  
  useEffect(() => {
    if (activeEditId) {
      ClinicDepartmentService.getClinicDepartmentByDepartmentId(activeEditId)
        .then((res) => {
          let updateData = res;
          updateData = { ...updateData, ['organization']: OrganizationService.getOrgId() }
          setFormData(updateData);
        });
    }
    fetchClinicList();
  }, []);
  useEffect(() => {
    fetchClinicList();
  }, [formData.organization])
  const changeHandler = async (value, fieldName , {addressParam={}} ) => {
    
    let updatedFormData = { ...formData };
    let updateValidation = { ...validation };
    if (fieldName == "street_address"&& addressParam) {
      // updateValidation = { ...updateValidation, ["address"]: "" };
      if (addressParam.street) {
        updatedFormData = {
          ...updatedFormData,
          street_address: addressParam.street,
        };
        // updateValidation = { ...updateValidation, ["street"]: "" };
      }
      if (addressParam.city) {
        updatedFormData = {
          ...updatedFormData,
          city: addressParam.city,
        };
        // updateValidation = { ...updateValidation, ["city"]: "" };
      }
      if (addressParam.state) {
        updatedFormData = {
          ...updatedFormData,
          state: addressParam.state,
        };
        // updateValidation = { ...updateValidation, ["state"]: "" };
      }
      if (addressParam.zipcode) {
        updatedFormData = {
          ...updatedFormData,
          zip_code: addressParam.zipcode,
        };
        // updateValidation = { ...updateValidation, ["zip_code"]: "" };
      }
    }
    updatedFormData[fieldName] = value;
    updateValidation[fieldName] = '';
    setFormData(updatedFormData);
    setValidation(updateValidation);
  };

  const formSubmitHandler = async () => {
    const { Loader, Toast } = props;
    let updateValidation = { ...validation };
    //check if global admin 
    if (isSet(props.profile.is_multiple_organization, false) === true) {
      //check organization filled if no throwing validation
      if (isSet(formData.organization, false) == false && organizationListOptions.length > 2 && !activeEditId) {
        setValidation({ ...updateValidation, ['organization']: 'The Organization Field is Required' });
        return false
      }
    }
    Loader(true);
    let response;
    let org_id = isSet(formData.organization, null);
    //update or save check
    if (activeEditId) {
      response = await ClinicDepartmentService.updateClinicDepartment(activeEditId, formData)
    }
    else {
      response = await ClinicDepartmentService.saveClinicDepartment(org_id, formData);
    }
    Loader(false);
    if (response.code === OK || response.code === CREATED) {
      addFormHandler();
      fetchClinicDepartment();
      let messages = `Clinic Department ${activeEditId ? 'Updated' : 'Added'} Successfully`;
      Toast.success(messages);
    }
    else {
      for (let key in response.data) {
        updateValidation = {
          ...updateValidation,
          [key]: response.data[key].join(',')
        }
      }
      setValidation(updateValidation)
    }
  };
  if (isSet(props.profile.is_multiple_organization, false) === true && organizationListOptions.length > 2 &&!activeEditId) {
    inputFields[0].data.unshift(
      {
        type: "SelectList",
        label: "Organization",
        options: organizationListOptions,
        placeholder: "",
        className: "form-control form-control-solid",
        name: "organization",
        value: formData.organization,
        required:true
      }
    );
  }
  const fetchClinicList = async () => {
    let org_id = isSet(formData['organization'], null);
    //chek if global admin 
    if (isSet(props.profile.is_multiple_organization, false) === true) {
      if (org_id == null) {
        setClinicsListOptions([{ label: '-Select Clinic-', value: '' }])
      }
      else {
        let res = await DropDownService.getClinicList(org_id, null);
        let options = [{ label: '-Select Clinic-', value: '' }];
        res && isSet(res, []).forEach((itm) => {
          options.push({ label: itm.name, value: itm.clinic_id });
        });
        setClinicsListOptions(options);
      }
    }
    else {
      let res = await DropDownService.getClinicList(org_id, null);
      let options = [{ label: '-Select Clinic-', value: '' }];
      res && isSet(res, []).forEach((itm) => {
        options.push({ label: itm.name, value: itm.clinic_id });
      });
      setClinicsListOptions(options);
    }
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={addFormHandler}
        size="lg"
        contentClassName="h-100"
        dialogClassName="hResponsive"
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">{modelTitle}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {inputFields &&
                inputFields.map((sec, index) => (
                  <div className='col-12'>
                    <h6 className="text-md border-bottom font-weight-bold">{sec.title}</h6>
                    <div className="row">
                      {sec.data.map((field, fieldIndex) => {
                        const col = isSet(field.col, 'col-md-4 col-6');
                        return (
                          <div className={`${col}`} key={index}>
                            <div className="form-group">
                              <label className="text-gray-900 text-md opacity-75">
                                {field.label}
                              </label>
                              <FormInputs {...field} changeHandler={(value, e) => changeHandler(value, field.name,e)} />
                              {isSet(validation[field.name], "") !== "" ? (
                                <span className="text-danger m-1">
                                  {isSet(validation[field.name], "")}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        )

                      })}
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              clickHandler={() => formSubmitHandler()}
              className="btn btn-primary"
              acl={"any"}
              label={activeEditId ? "Update" : "Save"}
            />
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  function getInputFields() {
    const inputFields = [
      {
        title: 'Clinic Department Details',
        data: [
          {
            type: "SelectList",
            label: "Clinic",
            placeholder: "",
            options: clinicsListOption,
            className: "form-control form-control-solid",
            name: "clinic_id",
            value: formData.clinic_id,
            required: true,
          },
          {
            type: "TextInput",
            label: "Facility Name",
            placeholder: "Facility Name",
            className: "form-control form-control-solid",
            name: "facility_name",
            value: formData.facility_name,
            required: true,

          },
          {
            type: "TextInput",
            label: "Medical Doctor NPI",
            placeholder: "Medical Doctor NPI",
            className: "form-control form-control-solid",
            name: "medical_doctor_npi",
            value: formData.medical_doctor_npi,
            // required: true,
          },
          {
            type: "TextInput",
            label: "Clinic code",
            placeholder: "Clinic Code",
            className: "form-control form-control-solid",
            name: "clinic_code",
            value: formData.clinic_code,
            required: true,

          },
          {
            type: "TextInput",
            label: "CLIA",
            placeholder: "CLIA",
            className: "form-control form-control-solid",
            name: "clia",
            value: formData.clia,
            required: true,

          },
        ]
      }, {
        title: 'Address Details',
        data: [
          {
            type: "Address",
            label: "Street Address",
            placeholder: "Street Address",
            className: "form-control form-control-solid",
            name: "street_address",
            value: formData.street_address,
            // required: true,

          },
          {
            type: "TextInput",
            label: "City",
            placeholder: "City",
            className: "form-control form-control-solid",
            name: "city",
            value: formData.city,
          },
          {
            type: "SelectList",
            label: "State",
            options: stateList,
            placeholder: "State",
            className: "form-control form-control-solid",
            name: "state",
            value: formData.state,
            required: false,

          },
          {
            type: "TextInput",
            label: "Zip Code",
            placeholder: "Zip Code",
            className: "form-control form-control-solid",
            name: "zip_code",
            value: formData.zip_code,
          },
        ]
      }
    ];
    return inputFields;
  }
};


export default withReduxState(WithRoutify(AddClinicDepartment));
