import React, { useRef,useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { saveAs } from 'file-saver';



const QRCodeGenerator = ({qrCodeDetails,modleToogleHandler}) => {
    const canvasRef = useRef(null);
    const {link,clinicName} = qrCodeDetails;

    // useEffect(() => {
    //     const canvas = canvasRef.current;
    //     const ctx = canvas.getContext('2d');
    //     // Drawing a simple rectangle
    //     ctx.fillStyle = 'blue';
    //     ctx.fillRect(10, 10, 150, 100);
    //   }, []);
    
      const downloadQRCode = () => {
        const svg = canvasRef.current.querySelector('svg');
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();

        img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((blob) => {
            saveAs(blob, 'qrcode.png');
        });
        };

        img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
      };

  return (
    <Modal show={true} onHide={modleToogleHandler}   dialogClassName='' keyboard={false}
    contentClassName='h-100'
    backdrop="static" size="md">
    <div className="sh_modalBox1">
        <Modal.Header closeButton>
            <Modal.Title>
                <h6 className="text-md mb-0 font-weight-bold">
                    {'Pre-Registration QR Code for '+clinicName}
                </h6>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="d-flex flex-column align-items-center mt-1">
               <div ref={canvasRef} className="m-3">
                    <QRCode value={link} />
                </div>
                <Button variant="primary" onClick={downloadQRCode}>
                    Download
                </Button>
            </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
            {/* <Button variant="primary" className="" onClick={() => modleToogleHandler()}>
                Close
            </Button> */}
        </Modal.Footer>
    </div>
</Modal>
  );
};

export default QRCodeGenerator;