import { Route, Routes } from "react-router-dom";
import Wrap from "../../hoc/Wrap";
import BasicPhysicalsList from "./BasicPhysicalList";
import PhysicalActivities from "./PhysicalActivities";

const BasicPhysicalContainer = () => {
  

  return (
    <Wrap>  
      <Routes>
        <Route path="/" key={0}  element={<BasicPhysicalsList/>} />
        <Route  path={`assign-activities/:physicalId/:physicalName`} key={1}  element={<PhysicalActivities />} />
      </Routes>
    </Wrap>
  );
};
export default BasicPhysicalContainer;






