import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import FormInputs from "../../components/UI/FormInputs";
import UserService from "../../services/UserService";
import { convertUnderScoreStrings, isObject, isSet } from "../../utils/commonUtils";
import RolesService from "../../services/RoleService";
import WithRoutify from "../../hoc/WithRoutify";
import Buttons from "../../components/UI/Buttons";
import ScreenDataConfig from "../RolesContainer/ScreenDataConfig";
import withReduxState from "../../hoc/wReduxState";

const AddFormModal = (props) => {
  const { isOpen, addFormHandler, modelTitle, Loader, Toast } = props;
  const [validation, setValidation] = useState({});
  const [roles, setRoles] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [rolePermission,setRolePermission] = useState({});
  const [isAllOrganization,setIsAllOrganization] = useState(false);
  const [dataSharingAccess,setDataSharingAccess] = useState('SHOW'); //HIDE, READONLY,DISABLED
  const [isLoaderDataSharing, setLoaderDataSharing] = useState({
    organization: true,
    clinic: true,
    company: true,
    lab: true,
  });
  const [organizationList, setOrganizationList] = useState(null);
  const [isOnloadDataConfig, setOnLoadDataConfig] = useState(true);
  const isMultipleOrg = isSet(props.profile.is_multiple_organization, false);
  const [dataConfig, setDataConfig] = useState({
    organization: {
      label: 'Organization',
      options: [],
      options: [],
      isActive: isMultipleOrg ? true : false,
      value: '',
      dbvalue: '',
      visible: isMultipleOrg ? true : false,
    },
    company: {
      label: 'Company',
      isActive: isMultipleOrg ? false : true,
      options: [],
      dbvalue: '',
      visible: true,
      value: 'ALL'
    },
    clinic: {
      label: 'Clinic',
      isActive: false,
      options: [],
      dbvalue: '',
      visible: true,
      value: 'ALL'
    },
    lab: {
      label: 'Lab',
      isActive: false,
      visible: true,
      options: [],
      dbvalue: '',
      value: 'ALL'
    }
  })
  const [formData, setFormData] = useState({
    assigned_role: '',
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    dob: '',
    data_sharing_id: '',
    roleDataSharingId: '',
    organization_uuid: '',
  })
  useEffect(() => {
    fetchRoleList();
  }, []);
  useEffect(() => {
    if (isSet(props.activeEditId, null) !== null) {
      fetchUserDetails(props.activeEditId);
    }
  }, [props.activeEditId]);

  useEffect(() => {
    if (isOnloadDataConfig === false) {
      const isMultipleOrg = isSet(props.profile.is_multiple_organization, false);
      fetchDataSharingOption(isMultipleOrg ? 'organization' : 'company');
      setOnLoadDataConfig(true);
    }
  }, [dataConfig, isOnloadDataConfig]);
  const fetchUserDetails = async (userId) => {
    props.Loader(true);
    try {

      const data = await UserService.getUserById(userId);
      const createdBySystem = data.is_system_created;
      // console.log('data',data);
      if(Boolean(createdBySystem)===true){
        setDataSharingAccess('HIDE');
      }
      let createdByGlobalAdmin = false;
      let roleDataSharingId = null;
      let appendFormData  = {};
      let updatedBy = null;
      let isMultipleOrganizationSelected = false;
      try {
        const dataRole = await RolesService.getPermission(data.assigned_role);
        setIsAllOrganization(isSet(dataRole?.is_all_organization,false));
        // console.log('dataRole',dataRole);
        updatedBy = dataRole.updated_by;
        createdByGlobalAdmin   = dataRole.created_by_global_admin;
        const orgUUIDs = dataRole?.data_sharing.organization_uuids;
        isMultipleOrganizationSelected = (orgUUIDs.split(',').length>1 || orgUUIDs==='ALL')?true:false;

        if(Boolean(createdByGlobalAdmin)===false && isMultipleOrg===true && isMultipleOrganizationSelected===false){
          appendFormData = {
            ...appendFormData,
            organization_uuid:isSet(data.organization_uuid, data.data_sharing.organization_uuids)
          }
        }
        roleDataSharingId = isSet(dataRole.data_sharing.data_sharing_uuid, null); 
      } catch (e) {

      }
      
      setFormData({
        assigned_role: data.assigned_role,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        phone_number: data.phone_number,
        dob: data.dob,
        data_sharing_id: isSet(data.data_sharing_id, data.data_sharing.data_sharing_uuid),
        roleDataSharingId: roleDataSharingId,
        organization_uuid: data.organization_uuid,
        isAllOrganization:data.is_all_organization,
        is_system_created:data.is_system_created,
        ...appendFormData
      });
      console.log('Boolean(createdByGlobalAdmin)===true',createdByGlobalAdmin,Boolean(createdByGlobalAdmin),isMultipleOrg)
      setDataConfig({
        ...dataConfig,
        organization: {
          ...dataConfig.organization,
          value: isSet(data.organization_uuid, data.data_sharing.organization_uuids),
          dbvalue: isSet(data.organization_uuid, data.data_sharing.organization_uuids),
          visible:(Boolean(isMultipleOrg)===true && (isMultipleOrganizationSelected===true || Boolean(createdByGlobalAdmin)===true || isSet(updatedBy,null)===null) )?true: false ,
          isActive:(Boolean(isMultipleOrg)===true && (isMultipleOrganizationSelected===true || Boolean(createdByGlobalAdmin)===true || isSet(updatedBy,null)===null) )?true: false ,
        },
        clinic: {
          ...dataConfig.clinic,
          value: data.data_sharing.clinic_uuids,
          dbvalue: data.data_sharing.clinic_uuids,
        },
        lab: {
          ...dataConfig.lab,
          value: data.data_sharing.lab_uuids,
          dbvalue: data.data_sharing.lab_uuids,
        },
        company: {
          ...dataConfig.company,
          value: data.data_sharing.company_uuids,
          dbvalue: data.data_sharing.company_uuids,
          isActive:(Boolean(isMultipleOrg)===true && (isMultipleOrganizationSelected===true || Boolean(createdByGlobalAdmin)===true || isSet(updatedBy,null)===null) )?false: true ,
        },
      });
      setOnLoadDataConfig(false);
      props.Loader(false);
    } catch (e) {
      console.log('excep', e);
      props.Loader(false);
    }
  }
  const inputFields = [
    {
      type: "SelectList",
      label: "Role",
      options: rolesList,
      placeholder: "",
      required:true,
      className: "form-control form-control-solid",
      name: "assigned_role",
      value: formData.assigned_role,
      disabled: isSet(formData.is_system_created, false),
    },
    {
      type: "TextInput",
      label: "Email",
      placeholder: "Email",
      className: "form-control form-control-solid",
      name: "email",
      required: true,
      value: formData.email,
    },
    {
      type: "TextInput",
      label: "First Name",
      required: true,
      placeholder: "First Name",
      className: "form-control form-control-solid",
      name: "first_name",
      value: formData.first_name,
    },
    {
      type: "TextInput",
      label: "Last Name",
      placeholder: "Last Name",
      className: "form-control form-control-solid",
      name: "last_name",
      required: true,
      value: formData.last_name,

    },
    {
      type: "Phone",
      label: "Cell Number",
      placeholder: "Cell Number",
      className: "form-control form-control-solid",
      name: "phone_number",
      value: formData.phone_number,
      required:true
    },
    {
      type: "Date",
      label: "Date of Birth",
      placeholder: "",
      className: "form-control form-control-solid",
      name: "dob",
      value: formData.dob,
      required:true,
      disableFuture:true
    },
    {
      type: "Checkbox",
      isLabelAppend: true,
      label: props.activeEditId?"Change Password":"Set Default Password",
      placeholder: "Password",
      className: "form-check-input",
      name: "change_password",
      value: formData.change_password,
    },
    formData.change_password==1?
    {
      type: "Password",
      label: "Password",
      placeholder: "Password",
      className: "form-control form-control-solid",
      name: "password",
      value: formData.password,
    }
    :
    null
  ];


  if (isSet(organizationList, null) !== null) {
    let selectedRole = rolesList.find(itm => itm.value === formData.assigned_role);
    if (selectedRole && selectedRole.label === 'Organization Admin') {
      inputFields.push({
        type: "SelectList",
        label: "Organization",
        options: organizationList,
        placeholder: "",
        className: "form-control form-control-solid",
        name: 'organization_uuid',
        value: formData.organization_uuid,
      });
    }


  }
  const changeHandler = (val, fieldName) => {
    let updateValidation = { ...validation };
    if (isSet(validation[fieldName], '') !== '') {
      setValidation({
        ...updateValidation,
        [fieldName]: ''
      });
    }
    setFormData({
      ...formData,
      [fieldName]: val,
    });
    if (fieldName == 'assigned_role') {
      //get module name 
      fetchRolePermission(val);
      // const moduleName = roles.find((itm) =>itm?.name === val)?.module;
      // if (moduleName&&!companyList?.hasOwnProperty(moduleName)) {
      //        fetchModuleList(moduleName,val);          
      // }
      // setModule(moduleName);
    }
  };
  const fetchRolePermission = async (roleId, isChangeOrgVal = true) => {
    // let updateDataConfig = {...dataConfig};
    // this.props.Loader(true);
    try {
      setLoaderDataSharing((prevFormData) => {
        const updatedFormData = {};
        // Iterate over the keys of prevFormData
        Object.keys(prevFormData).forEach((key) => {
            // Set the value of each key to false
            updatedFormData[key] = false;
        });
        return updatedFormData;
    });
      // this.props.Loader(true);
      const data = await RolesService.getPermission(roleId);
      let appendFormData = {};
      // console.log('appendFormData',data);
      const createdByGlobalAdmin   = data.created_by_global_admin;
      const orgUUIDs = data?.data_sharing.organization_uuids;
      const isMultipleOrganizationSelected = (orgUUIDs.split(',').length>1 || orgUUIDs==='ALL')?true:false;
      // console.log('orgUUIDs.split',orgUUIDs.split(','),isMultipleOrganizationSelected)
        if(Boolean(createdByGlobalAdmin)===false && isMultipleOrg===true && isMultipleOrganizationSelected===false){
          appendFormData = {
            ...appendFormData,
            organization_uuid:isSet(data.organization_uuid, data.data_sharing.organization_uuids)
          }
        }
        // console.log('data.data_sharing.organization_uuids',data.data_sharing.organization_uuids);
      setIsAllOrganization(isSet(data?.is_all_organization,false));
      const dataSharingUuid = data.data_sharing.data_sharing_uuid;
      if (formData.roleDataSharingId !== dataSharingUuid) {

        setFormData((prevFormData) => ({
          ...prevFormData,
          ...appendFormData,
          data_sharing_id: dataSharingUuid,
        }));
        setRolePermission(data?.permissions||{});
      }else{
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...appendFormData
        }));
      }
      // console.log('data',data);
      // setFormData({...formData,data_sharing_id:dataSharingUuid});
      // Update updateDataConfig
      setDataConfig((prevUpdateDataConfig) => ({
        ...prevUpdateDataConfig,
        organization: {
          ...prevUpdateDataConfig.organization,
          value: isSet(data.data_sharing.organization_uuids, isSet(props.profile.organization_id, null)),
          visible:(Boolean(isMultipleOrg)===true && (isMultipleOrganizationSelected===true || Boolean(createdByGlobalAdmin)===true || isSet(data.updated_by,null)===null) )?true: false ,
          isActive:(Boolean(isMultipleOrg)===true && (isMultipleOrganizationSelected===true || Boolean(createdByGlobalAdmin)===true || isSet(data.updated_by,null)===null) )?true: false ,
          dbvalue: (isChangeOrgVal === false) ? prevUpdateDataConfig.organization.dbvalue : isSet(data.data_sharing.organization_uuids, isSet(props.profile.organization_id, null)),
        },
        clinic: {
          ...prevUpdateDataConfig.clinic,
          value: data.data_sharing.clinic_uuids,
          dbvalue: (isChangeOrgVal === false) ? prevUpdateDataConfig.clinic.dbvalue : data.data_sharing.clinic_uuids,
        },
        lab: {
          ...prevUpdateDataConfig.lab,
          value: data.data_sharing.lab_uuids,
          dbvalue: (isChangeOrgVal === false) ? prevUpdateDataConfig.lab.dbvalue : data.data_sharing.lab_uuids,
        },
        company: {
          ...prevUpdateDataConfig.company,
          value: data.data_sharing.company_uuids,
          isActive:(Boolean(isMultipleOrg)===true && (isMultipleOrganizationSelected===true || Boolean(createdByGlobalAdmin)===true || isSet(data.updated_by,null)===null) )?false: true ,
          dbvalue: (isChangeOrgVal === false) ? prevUpdateDataConfig.company.dbvalue : data.data_sharing.company_uuids,
        },
      }));
      setOnLoadDataConfig(false);
      //,()=>this.fetchDataSharingOption()
    } catch (e) {
      console.log('excep', e);
      // this.props.Loader(false);
    }
  }
  const dataConfigChangeHandler = (key, bool, val) => {
    const currentValues = isSet(dataConfig[key].value, '').toString().split(',').filter(value => value !== '');
   
    let updatedValues;
    setValidation({...validation,['dataConfig']:''});
    const options = Object.values(isSet(dataConfig[key].options, [])).flat();
    // const options =  isSet(dataConfig[key].options,[]);
    let filteredOptions = options.filter((v) => v.value !== 'ALL');
    const valuesToUpdate = Array.isArray(val) ? val : [val];
    
    // console.log('isAllOrganization',isAllOrganization,orgValues.length,val);
    // console.log('orgValues',orgValues);
    // if(formData.isAllOrganization===true){
    //   console.log('ssss');
    // }
    if (bool) {
      // Add the values to the currentValues if bool is true and they're not already in the array
      updatedValues = currentValues.concat(valuesToUpdate.filter(value => !currentValues.includes(value)));
    } else {
      // Remove the values from the currentValues if bool is false
      updatedValues = currentValues.filter((value) => !valuesToUpdate.includes(value));
      if (!valuesToUpdate.includes('ALL') && updatedValues.length === 1 && updatedValues[0] === 'ALL') {
        updatedValues = [];
        filteredOptions.forEach((item) => {
          if (!valuesToUpdate.includes(item.value)) {
            updatedValues.push(item.value);
          }
        });
      }
    }
    // console.log('currentValues11',key,bool,val,updatedValues);
    if (updatedValues.length === 1 && updatedValues[0] === 'ALL') {
      // Do nothing
    } else if (updatedValues.length === filteredOptions.length) {
      // If the length of arr is equal to optionsLength, replace arr with ['ALL']
      updatedValues = ['ALL'];
    } else if (updatedValues.includes('ALL')) {
      // If 'ALL' exists in arr, remove it
      updatedValues.splice(updatedValues.indexOf('ALL'), 1);
    }
    const dataConfigTemp = {
      ...dataConfig,
      [key]: {
        ...dataConfig[key],
        value: (valuesToUpdate.includes('ALL') && bool) ? 'ALL' : updatedValues.join(','),
      }
    };
    const orgValues = isSet(dataConfigTemp['organization'].value, '').toString().split(',').filter(value => value !== '');
    if(Boolean(isAllOrganization)===true && bool===true && (orgValues.length>1 || (key === 'organization' && val==='ALL'))){
      setValidation({...validation,['dataConfig']:'This role is permitted for only one organization'});
      return false;
    }
    setDataConfig(dataConfigTemp);
  }
  // Function to check if any dbvalue !== value
  const isAnyValueUpdated = () => {
    for (const key in dataConfig) {
      if (dataConfig[key].dbvalue !== dataConfig[key].value) {
        return true; // At least one dbvalue is not equal to value
      }
    }
    return false; // All dbvalues are equal to values
  };
  const formSubmitHandler = async () => {
    let validationMessage=[];
    let updateValidation = { ...validation };

    let payload = {
      ...formData,
    }
    if(isSet(formData.dob)){
      var parts = formData.dob.split('/');
      var day = parseInt(parts[1]);
      var month = parseInt(parts[0]) - 1; // month is zero-based in JavaScript Date object
      var year = parseInt(parts[2]);
      
      // Creating a Date object from the provided date
      var dobDate = new Date(year, month, day);

      // Getting the current date
      var currentDate = new Date();

      // Validating against future date
      if (dobDate > currentDate) {
        setValidation({
          ...updateValidation,
          ['dob']: 'Date cannot be a future date'
        });
        return false;
      }

      // Validating against the year 1900
      if (dobDate < new Date(1900, 0, 1)) {
        setValidation({
          ...updateValidation,
          ['dob']: 'Please Enter Valid Date'
        });
        return false;
      } 
    }
    // console.log('isSet(payload.roleDataSharingId,null)',payload);
    console.log(isSet(payload.roleDataSharingId, null), 'data', isSet(payload.data_sharing_id, null));
    if ((isSet(payload.roleDataSharingId, null) == null || (isSet(payload.roleDataSharingId, null) === isSet(payload.data_sharing_id, null))) && isAnyValueUpdated()) {
      delete payload.data_sharing_id;
    }
    if (isSet(payload.roleDataSharingId, null) === null || (isSet(payload.roleDataSharingId, null) !== isSet(payload.data_sharing_id, null))) {
      // if(isAnyValueUpdated() && isSet(payload.roleDataSharingId,null)==null){
      //   delete payload.data_sharing_id;
      // }
      if (isSet(formData.assigned_role, null) !== null && isSet(dataConfig.organization.value, null) === null) {
        Toast.error('Data sharing config must be required');
        return false;
      }
      if (isAnyValueUpdated()) {
        payload = {
          ...payload,
          data_sharing: {
            organization_uuids: dataConfig.organization.value,
            clinic_uuids: dataConfig.clinic.value,
            lab_uuids: dataConfig.lab.value,
            company_uuids: dataConfig.company.value,
          }
        }
      }
    }

    if (isSet(props.profile.organization_id, null) !== null) {
      payload = {
        ...payload,
        organization_uuid: props.profile.organization_id
      }
    }
    if(Boolean(isAllOrganization)===true){
      payload = {
        ...payload,
        organization_uuid: dataConfig.organization.value
      }
    }
    if (dataConfig.company.value == "" || dataConfig.company.value == null) {
      let hasCompanyListPermission = rolePermission?.company?.list || false;
      if (hasCompanyListPermission) {
        validationMessage.push('Company Data sharing config must be required');
      }
    }
    if (dataConfig.clinic.value == "" || dataConfig.clinic.value == null) {
      let hasClinicListPermission = rolePermission?.clinic?.list || false;
      if (hasClinicListPermission) {
        validationMessage.push('Clinic Data sharing config must be required');
      }
    }
    if (dataConfig.lab.value == "" || dataConfig.lab.value == null) {
      let hasLabListPermission = rolePermission?.lab?.list || false;
      if (hasLabListPermission) {
        validationMessage.push('Lab Data sharing config must be required');
      }
    }
    if (validationMessage.length > 0) {
      Toast.error(validationMessage);
      validationMessage=[];
      return false;
    }
    // console.log('payload',payload);
    // return false;
    if (isSet(props.activeEditId, null) !== null) {
      // props.updateUserHandler(props.activeEditId,payload);
      Loader(true);
      const data = await UserService.updateUser(props.activeEditId, payload);
      Loader(false);
      if (data.status && data.status == "ERROR") {

        if (isObject(data.data)) {
          for (let key in data.data) {
            updateValidation = {
              ...updateValidation,
              [key]: data.data[key].join(',')
            }
          }
        } else {
          Toast.error(data.data);
        }
        if (Object.keys(updateValidation).length > 0) {
          setValidation(updateValidation);
        }
      } else {
        props.fetchUserList();
        props.addFormHandler();
        Toast.success('User Updated  Successfully');
      }
    } else {
      Loader(true);
      try {
        const data = await UserService.saveUser(payload);
        // console.log('data', data);
        Loader(false);
        if (data.status && data.status == "ERROR") {

          if (isObject(data.data)) {
            for (let key in data.data) {
              updateValidation = {
                ...updateValidation,
                [key]: data.data[key].join(',')
              }
            }
          } else {
            Toast.error(data.data);
          }
          if (Object.keys(updateValidation).length > 0) {
            setValidation(updateValidation);
          }
        } else {
          props.fetchUserList();
          props.addFormHandler();
          Toast.success('User Added Successfully');
        }
      } catch (e) {
        // console.log('e',e);
        Loader(false);
      }
    }


  }
  const fetchDataSharingOption = async (valKey = 'organization') => {
    setValidation({...validation,['dataConfig']:''});
    setLoaderDataSharing((prevFormData) => {
        
      const updatedFormData = {};
      // Iterate over the keys of prevFormData
      Object.keys(prevFormData).forEach((key) => {
          // Set the value of each key to false
          updatedFormData[key] = false;
      });
      return updatedFormData;
  });
    let updateDataConfig = { ...dataConfig };
    try {
      const orgIds = (isSet(dataConfig.organization.value,null)===null)?'':'ALL';
      const payload = {
        organization_uuids: (valKey === 'organization') ? 'ALL' : isSet(dataConfig.organization.value, 'ALL'),
        clinic_uuids: (valKey === 'clinic') ? orgIds : isSet(dataConfig.clinic.value, 'ALL'),
        lab_uuids: (valKey === 'lab') ? orgIds : isSet(dataConfig.lab.value, 'ALL'),
        company_uuids: (valKey === 'company') ? orgIds : isSet(dataConfig.company.value, 'ALL')
      };

      const dataList = await RolesService.getDataSharingOptionsList(payload);
      let options = null;
      for (let key in dataList) {
        if (!isObject(dataList[key])) {
          options = [{ label: 'All ' + key, value: 'ALL' }];
          dataList[key].forEach((item, itemIndex) => {
            options.push({
              value: isSet(item.organization_uuid, isSet(item.lab_id, isSet(item.company_uuid, item.id))),
              label: isSet(item.name, item.clinic_name),
              index: itemIndex,
            });
          });
        } else {
          options = {
            default: [{ label: 'All ' + key, value: 'ALL' }]
          };
          for (let orgKey in dataList[key]) {
            let optionsArr = [];
            dataList[key][orgKey].forEach((item, itemIndex) => {
              optionsArr.push({
                value: isSet(item.organization_uuid, isSet(item.lab_id, isSet(item.company_uuid, item.id))),
                label: isSet(item.name, isSet(item.clinic_name, isSet(item.company_name, item.lab_name))),
                index: itemIndex,
              });
            });
            options = {
              ...options,
              [orgKey]: optionsArr
            }
          }
        }

         setLoaderDataSharing((prevFormData) => {
          const updatedFormData = {};
          // Iterate over the keys of prevFormData
          Object.keys(prevFormData).forEach((key) => {
              // Set the value of each key to false
              updatedFormData[key] = true;
          });
          return updatedFormData;
      });


        if (valKey === key) {
          const arr1 = isSet(isSet(updateDataConfig[valKey].value, '').toString().split(','), []).filter(v => v !== '');
          const arr2 = Object.values(options).flat().map(obj => obj.value);
          const filteredArr = arr1.filter(value => arr2.includes(value));
          updateDataConfig = {
            ...updateDataConfig,
            [key]: {
              ...updateDataConfig[key],
              options: options,
              value: filteredArr.join(',')
            }
          }
        }

      }
      setDataConfig({
        ...dataConfig,
        ...updateDataConfig
      });
      
      // this.props.Loader();
    } catch (e) {
      setLoaderDataSharing((prevFormData) => {
        const updatedFormData = {};
        // Iterate over the keys of prevFormData
        Object.keys(prevFormData).forEach((key) => {
            // Set the value of each key to false
            updatedFormData[key] = true;
        });
        return updatedFormData;
    });
      console.log('excep', e);
      
    }
  }


  const fetchRoleList = async () => {
    const response = await RolesService.getRoleByAsc();
    let rolesOption = [];
    rolesOption.push({ "label": "-Roles-", "value": '' })
    response.map((itm) => {
      rolesOption.push({ "label": convertUnderScoreStrings(itm.name), "value": itm.id })
    });
    setRolesList(rolesOption);
    setRoles(response);

  }
  return (
    <>
      <Modal
        show={isOpen}
        onHide={addFormHandler}
        size='xl'
        dialogClassName='hResponsive'
        contentClassName='h-100'
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">
                {(isSet(props.activeEditId, null) !== null) ? `Edit ${modelTitle} ` : `Add ${modelTitle}`}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {inputFields &&
                inputFields.map((field, index) => {
                  if (field == null) {
                    return false;
                  }
                  const className = isSet(validation[field.name], '') ? 'was-validated' : '';

                  return (
                    <div className={`col-6`} key={index}>
                      <div className={`form-group ${isSet(field.isLabelAppend, false) === true ? 'form-check form-check-solid' : ''}`} >
                        {(isSet(field.isLabelAppend&&field.isLabelAppend, false) === false) ?
                          <label className="mb-0 text-sm opacity-75 text-truncate">
                            {field.label}
                          </label>
                          : <></>}
                        <FormInputs {...field} changeHandler={(val) => changeHandler(val, field.name)} />
                        {(isSet(field.isLabelAppend&&field.isLabelAppend, false) === true) ?
                                <label className="form-check-label" htmlFor={field.name}>
                                  {field.label}
                                </label>
                                : <></>}
                        {isSet(validation[field.name], '') !== '' ? <span className="text-danger m-1">{isSet(validation[field.name], '')}</span> : ''}
                      </div>
                    </div>
                  )
                })}
            </div>
            {(dataSharingAccess==='SHOW' && isSet(formData.assigned_role, null) !== null) ?
              <div className="row">
                <div className="col-12">
                  
                  <ScreenDataConfig
                    dataConfig={dataConfig}
                    screen={'USER'}
                    validationMsg={isSet(validation['dataConfig'], '')}
                    isLoadDataSharing={isLoaderDataSharing}
                    assigned_role={formData.assigned_role}
                    fetchRolePermission={fetchRolePermission}
                    fetchDataSharingOption={fetchDataSharingOption}
                    changeHandler={dataConfigChangeHandler} />
                </div>
              </div>
              : <></>}

          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              type='Single'
              className="btn btn-primary"
              clickHandler={() => formSubmitHandler()}
              label='Submit'
              acl='user-create' />
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};
export default withReduxState(WithRoutify(AddFormModal));