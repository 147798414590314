import React, { Component } from "react";
import TableGrid from "../../components/UI/TableGrid";
import {
  isSet,
  toQueryString,
  USDateTimeString,
} from "../../utils/commonUtils";
import WithRoutify from "../../hoc/WithRoutify";
import withReduxState from "../../hoc/wReduxState";
import WithRouter from "../../hoc/WithRouter";
import CptService from "../../services/CptService";
import CsvService from "../../services/CsvService";
import EzReaderDetailView from "./EzReaderDetailView";
import Icons from "../../components/UI/Icons";

class EzReaderList extends Component {
  constructor(props) {
    super(props);
    // Initialize state or bind methods if needed
    const tableHeaders = [
      {
        label: "",
        key: "isActive",
        type: "checkBox",
        changeHandler: this.checkBoxchangeHandler,
      },
      { label: "Status", key: "status", type: "",},
      { label: "Invalid Description", key: "failed_data", type: "",},
      { label: "Picture", key: "file_id",type:"FileType"},
      { label: "DSN", key: "dsn",type:""},
      { label: "Product ID", key: "product_id",type:""},
      { label: "Product LOT", key: "product_lot",type:""},
      { label: "Expiation Date", key: "expiration_date",type:""},
      { label: "Test Date", key: "test_date",type:""},
      { label: "Test Time", key: "test_time",type:""},
      { label: "Received Date", key: "test_date",type:""},
      { label: "Updated At", key: "updated_at",type:""},
      { label: "Actions", key: "action",type:"Actions"},
      //  more columns as needed
    ];
    this.state = {
      isModelOpen:false,
      activeEditId:null,
      filters: {
        search_query: "",
      },
      tableRecords: {
        tableHeaders: tableHeaders,
        tableRows: {
          status: 1000,
          data: [],
        },
        pagination: {
          page: 1,
          perPage: 25,
          totalRecords: 0,
          sort_by: "created_at",
          sort_order: "asc",
          search: {},
        },
      },
      Tab: [
        { "label": "Failed", isActive: false, name: 'failed' },
        { "label": "All", isActive: true, name: '' },
      ],
    };
  }

  componentDidMount() {
    this.fetchEzreaderList();
  }

  componentDidUpdate = (prevProps, prevState) => {
    // console.log('logs',this.props);
    // Code to run after the component has updated
  };

  componentWillUnmount() {
    // Code to run before the component is unmounted
  }

  handleEvent = () => {
    // Handler for events (e.g., button click)
  };
  addFormHandler = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  onChangeFiltersHandler = (key, val) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        [key]: val,
      },
    });
  };
  render() {
    const { tableRecords, filters, isModelOpen, activeEditId ,errorLogModelOpen,Tab} = this.state;
    return (

      <>
        {isModelOpen ? (
          <EzReaderDetailView
            fetchEzreaderList={this.fetchEzreaderList}
            addFormHandler={this.addFormHandler}
            activeEditId={activeEditId}
            fetchRecords={this.fetchEzreaderList}
          />
        ) : (
          <></>
        )}
          <div className="sh_cardBox">
          <div className="sh_cardHeader pb-1">
              <div className="form-group d-flex mb-0 justify-content-between align-items-center">
                <div>
                  <div className="tabs_wrapForTable">
                    <ul className="nav nav-borders nav-tabs border-0" role="">
                      {Tab.map((itm, itmInd) => {
                        return (
                          <>
                            <li>
                              <a
                                className={`flex-sm-fill text-sm-center nav-link ${itm.isActive ? "active" : ""
                                  }`}
                                aria-current="page"
                                onClick={() => this.setActiveTab(itm)}
                              >
                                {itm.label}
                              </a>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {/* <h6 class="text-lg f-500 mb-0">{` Employee / Patient Visits `} </h6> */}
                <div className="d-flex">
                  <div className="form-group-fields row mr-2">
                  </div>
                  <div className="addAction">
                  </div>

                </div>
              </div>
            </div>

            <div className="sh_cardBody p-0">
              <TableGrid
                addFormHandler={() => this.addFormHandler()}
                hasPagination={true}
                fetchTableRecords={(filters) =>
                  this.fetchEzreaderList(filters)
                }
                {...tableRecords}
              />
            </div>
          </div>
          </>
    );
  }
  submitFiltersHandler = async () => {
    const { filters, tableRecords } = this.state;
    const queryfilters = {
      ...tableRecords.pagination,
      page:1,
      search: filters,
    };
    this.fetchEzreaderList(queryfilters);
  };
  clearFiltersHandler = async () => {
    await this.setState({
      filters: {},
    });
    this.submitFiltersHandler();
  };
  fetchEzreaderList = async (query = {}) => {
    const { tableRecords,Tab } = this.state;
    try {
      let queryString = toQueryString({
        ...tableRecords.pagination,
        ...query,
      });
      this.props.Loader(true);
      const response = await CsvService.getEzlist(queryString);
      const tableData = response.data.data.map((item, index) => {
        const hyperLink = isSet(item.cup_id, null !== null) && !this.props.profile.is_multiple_organization?
          { href: '/visits/visit-details/' + item.cup_id?.visit_id, title: 'Patient Visit detailed view' } :
          {};
          console.log('hyperlink',hyperLink);
          
        return {
          index: index,
          isHeading: false,
          rowId: item.id,
          data: {
            isActive: false,
            status: {
              title: item.is_failed ? 'Error' : 'Success',
              value: (
                <>
                  <div className="d-flex justify-content-left">
                    <div className="d-flex justify-content-end px-2" title={item.is_failed ? 'Error' : 'Success'}>
                      <Icons type={item.is_failed ? 'red' : 'green'} />
                    </div>
                  </div>
                </>
              ),
            },
            failed_data: item.failed_data || '--',
            file_id: item.filemanager,
            dsn: {
              value:item.dsn,
              ...hyperLink
            },
            product_id: item.product_id,
            product_lot: item.product_lot,
            expiration_date: item.expiration_date,
            test_date: item.test_date,
            test_time: item.test_time,
            updated_at: USDateTimeString(item.updated_at),
            action: [
              {
                className: "btn btn-icon btn-transparent-dark",
                iconType: "Search",
                title: "View",
                type: "GridEdit",
                clickHandler: () => { this.editHandler(item.id) },
                acl: 'any'
              },
            ],
          },
        };
      });
    
      // console.log('tableData',response?.data);
      this.setState((prevState) => ({
        tableRecords: {
          ...prevState.tableRecords,
          tableHeaders: prevState.tableRecords.tableHeaders,
          tableRows: {
            type: "default",
            status: 200,
            data: tableData,
          },
          pagination: {
            ...prevState.tableRecords.pagination,
            ...query,
            totalRecords: response?.data?.total,
          },
        },
      }));
    } catch (err) {
      this.setState({
        tableRecords: {
          ...tableRecords,
          tableRows: {
            ...tableRecords.tableRows,
            status: 200,
          },
        },
      });
      console.log("DataIsse");
      // console.error("Error fetching companies:", err);
    } finally {
      this.props.Loader(false);
    }
  };

  deleteHandler = async (id) => {
    CptService.deleteCpt(id)
      .then((res) => {
        this.fetchEzreaderList();
        this.props.Toast.success("CPT Deleted Succesfully");
      })
      .catch((err) => {
        //
      })
      .finally(() => {
        //
      });
  };
  editHandler = (rowId) => {
    this.setState({
      activeEditId: rowId,
    }, this.addFormHandler())
  };
  setActiveTab = (val) => {
    let { Tab, filters } = this.state;
    const { label, name, isActive } = val
    let updatedTab = Tab.map((item) => {
      if (item.label == label) {
        item.isActive = true
      }
      else {
        item.isActive = false;
      }
      return item;
    });
    this.setState((prevState) => ({
      Tab: updatedTab,
      filters: { ...prevState.filters, ['status']: name },
    }), () => this.submitFiltersHandler());
  };
  checkBoxchangeHandler = (value, {e}) => {
    const rowId = e.target.id;
    const { tableRows } = this.state.tableRecords;
    //update data based on check box click
    const updatedData = tableRows.data.map((item) => {
      if (item.rowId === rowId) {
        item.data.isActive = value;
      }
      return item;
    });

    this.setState((prevState) => ({
      tableRecords: {
        ...prevState.tableRecords,
        tableRows: {
          data: updatedData,
        },
      },
    }));
  };
 
}
export default WithRouter(withReduxState(WithRoutify(EzReaderList)));
