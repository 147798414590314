import { Route, Routes } from 'react-router-dom';
import Wrap from '../../hoc/Wrap';
import VaccineResult from './VaccineResult';
import PhysicalAndDrugScreen from './PhysicalAndDrugScreen/PhysicalAndDrugScreen';

const ResultContainer = () => {

  return (
    <Wrap>
      <Routes>
        <Route path="/vaccine" key={0} element={<VaccineResult />} />
        <Route path="/physical-drug-screens" key={0} element={<PhysicalAndDrugScreen />} />

      </Routes>



    </Wrap>
  );
};






export default ResultContainer;