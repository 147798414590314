import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { base64Encode, getBase64String, isObject, isSet } from "../../utils/commonUtils";
import FormInputs from "../../components/UI/FormInputs";
import WithRoutify from "../../hoc/WithRoutify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { activeProfileDetails } from "../../store/SessionSlice";
import { withTranslation } from "react-i18next";
import ConfigurationService from "../../services/ConfigurationService";
import { organizationList } from "../../store/OrganizationSlice";

const AddConfiguration = (props) => {
  const { isOpen, addFormHandler, fetchRecord, activeEditId, Loader, Toast } = props;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [validation, setValidation] = useState({});
  const profileDetails = useSelector(activeProfileDetails);
  let organizationListOptions = [...useSelector(organizationList)];
  organizationListOptions.splice(1, 0, { label: "All", value: "ALL" });
  const inputFields = getInputFields(formData);
  //intial render 
  useEffect(() => {
    if (activeEditId) {
      fetchConfigDetails();
    }

  }, []);
  const fetchConfigDetails = async () => {
    const response = await ConfigurationService.getSftpConfigById(activeEditId);
    console.log('fetchConfigDetails response', response);

    setFormData(response);
  }
  //change handler form input
  const changeHandler = async (val, fieldName, { }) => {
    let updatedFormData = { ...formData };
    let updateValidation = { ...validation };
    if (fieldName == 'key_file') {
      if (val) {
        const { base64WithPrefix, fileName } = await getBase64String(val);
        val = base64WithPrefix;
      }
      else {
        val = '';
      }

    }

    updatedFormData[fieldName] = val;
    updateValidation[fieldName] = '';
    setFormData(updatedFormData);
    setValidation(updateValidation);
  };
  console.log('formdata', formData);

  //formSubmit
  const formSubmitHandler = async () => {
    let updateValidation = { ...validation };
    let payload = { ...formData }
    let response;
    Loader(true);
    if (activeEditId) {
      response = await ConfigurationService.updateSftpConfiguration(activeEditId, payload);
    }
    else {
      response = await ConfigurationService.saveSftpConfiguration(payload);
    }
    Loader(false);
    if (response.status && response.status == "ERROR") {
      if (isObject(response.data)) {
        for (let key in response.data) {
          updateValidation = {
            ...updateValidation,
            [key]: response.data[key].join(',')
          }
        }
      } else {
        Toast.error(response.data);
      }
      if (Object.keys(updateValidation).length > 0) {
        setValidation(updateValidation);
      }
      return false;
    }
    addFormHandler();
    Toast.success("Configuration Updated Successfully");
    fetchRecord();


  };


  return (
    <>
      <Modal
        show={isOpen}
        onHide={addFormHandler}
        size="xl"
        dialogClassName="hResponsive "
        contentClassName="h-100"
        className=""
        backdrop="static"
        keyboard={false}
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className=" text-lg mb-0 font-weight-bold">{activeEditId?"Add":"Edit"} Configuration</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="card-body tab-content p-0">
              {inputFields &&
                inputFields.map((section, sectionIndex) => {
                  return (
                    <>
                      <div class="card mb-2">
                        <a
                          href={`#collapseOrgCard_${sectionIndex}`}
                          id={`collapseOrgCard_link_${sectionIndex}`}
                          class="d-block sh_accordionheader card-header"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="true"
                          aria-controls="collapseCardExample"
                          style={{
                            position: "sticky",
                            top: "-15px",
                            zIndex: "1",
                          }}
                        >
                          <h6 class="m-0 text-md">
                            {section.title}
                          </h6>
                        </a>
                        <div
                          class="collapse show"
                          id={`collapseOrgCard_${sectionIndex}`}
                          data-bs-parent="#modal111"
                        >
                          <div class="card-body">
                            <div class="row">
                              {section.data.map((data, dataInd) => {
                                return (
                                  <>
                                    <div class={"col-lg-4 col-6"}>
                                      <div class="form-group">
                                        <>
                                          <label class="text-sm text-truncate mb-0 opacity-75">
                                            {data.label}
                                          </label>
                                          <div
                                            key={dataInd}
                                          >
                                            <FormInputs
                                              {...data}
                                              className={
                                                "form-control form-control-solid"
                                              }
                                              value={data.value || formData[data.name]}
                                              changeHandler={(value, e) =>
                                                changeHandler(value, data.name, e)
                                              }
                                            />
                                            {isSet(validation[data.name], "") !==
                                              "" ? (
                                              <span className="text-danger m-1">
                                                {isSet(validation[data.name], "")}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            {/* <Timeline/> */}
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Button
              variant="primary"
              className=""
              onClick={() => formSubmitHandler()}
            >
              {activeEditId ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
  function getInputFields() {
    const inputFields = [
      {
        title: `Configuration  Basic Details`,
        data: [
          {
            type: "SelectList",
            label: "Organization",
            options: organizationListOptions,
            placeholder: "",
            name: "organization_id",
            required: true,
          },
          {
            type: "TextInput",
            label: "Name",
            name: "name",
            placeholder: "Name",
            isFirstLetterCaps: true,
            required: true,
          },
          {
            type: "TextInput",
            label: "Sender Application",
            name: "sender_application",
            placeholder: "Sender Application",
          },
          {
            type: "TextInput",
            label: "Sender Facility",
            name: "sender_facility",
            placeholder: "Sender Facility",
          },
          {
            type: "TextInput",
            label: "Receiver Application",
            name: "receiver_application",
            placeholder: "Receiver Application",
          },
          {
            type: "TextInput",
            label: "Receiver Facility",
            name: "receiver_facility",
            placeholder: "Receiver Facility",
          },
          {
            type: "TextArea",
            label: "Description",
            name: "description",
            placeholder: "Description",
          },
        ],
      },
      {
        title: `Configuration Connection Details`,
        data: [
          {
            type: "SelectList",
            label: "Protocol",
            options: [
              { label: "--Select--", value: "" },
              { label: "SFTP", value: "sftp" },
              { label: "FTP", value: "ftp" },
            ],
            placeholder: "Protocol",
            name: "protocol",
            required: true,
          },
          {
            type: "TextInput",
            label: "Host",
            placeholder: "Host",
            name: "host",
            required: true,
          },
          {
            type: "TextInput",
            label: "Port",
            placeholder: "Port",
            name: "port",
            required: true,
          },
          {
            type: "SelectList",
            options: [
              { label: "--Select--", value: "" },
              { label: "Normal", value: "normal" },
              { label: "Key File", value: "key_file" },
            ],
            label: "Login Type",
            placeholder: "Login Type",
            name: "login_type",
          },
          {
            type: "TextInput",
            label: "User",
            placeholder: "user",
            name: "user",
            required: true,
          },
          {
            type: "TextInput",
            label: "Password",
            placeholder: "Password",
            name: "password",
            required: true,
          },
          {
            type: "TextInput",
            label: "Home Path",
            placeholder: "Home Path",
            name: "home_path",
          },
          {
            type: "ImageUpload",
            label: "Key File",
            placeholder: "Password",
            name: "key_file",
            required: true,
            value: {
              original_name: 'key_file',
              base64: formData['key_file']
            },
          },
          {
            type: "TextInput",
            label: "Orders Path",
            placeholder: "",
            name: "orders_path",
          },
          {
            type: "TextInput",
            label: "Result Path",
            placeholder: "",
            name: "result_path",
          },
        ],
      },
    ];
    return inputFields;
  }
};
export default WithRoutify(withTranslation()(AddConfiguration));